import type { Project } from '@pn/core/domain/project';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isUS } from '@pn/core/utils/env';

export const defaultWorkspace = isEmbedded()
  ? []
  : isUS()
    ? ['wells_usa']
    : ['wells'];

export const defaultProject: Project = {
  id: 'default',
  name: '',
  folder: undefined,
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  createdBy: undefined,
  workspaceItemIds: ['_background', 'grids', ...defaultWorkspace],
  isFolder: false,
  isShared: false,
  isGlobal: false,
  origin: 'pn',
};

/**
 * This project is selected when a project URL is provided but all projects are
 * not loaded yet.
 */
export const nullProject: Project = {
  id: 'null',
  name: '',
  folder: undefined,
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  createdBy: undefined,
  workspaceItemIds: ['_background', 'grids'],
  isFolder: false,
  isShared: false,
  isGlobal: false,
  origin: 'pn',
};

export function isDefaultProject(project: Project): boolean {
  return project.id === defaultProject.id;
}

export function isSpecialProject(project: Project): boolean {
  return project.id === defaultProject.id || project.id === nullProject.id;
}

import { Enterprise } from '@pn/core/domain/enterprise';
import {
  BaseUser,
  InvitedUser,
  User,
  UserPlans,
} from '@pn/core/domain/user';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';

type State<T> = {
  isFetching: boolean;
  isError: boolean;
  users: T[];
  selectedUser: T | undefined;
};

const getInitialState = <T>(): State<T> => ({
  isFetching: false,
  isError: false,
  users: [],
  selectedUser: undefined,
});

export const createUsersSlice = <T extends BaseUser>(sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState: getInitialState<T>(),
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.isFetching = false;
        state.users = payload;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.users = [];
      },
      add: (state, { payload }: PayloadAction<T>) => {
        state.users.push(payload as Draft<T>); // TODO what's wrong with TS here?
      },
      // specific to enterprise users storage only, unsure how else to handle this case
      addToEnterprise: (
        state,
        {
          payload,
        }: PayloadAction<{
          user: User;
          enterprise: Enterprise;
          userPlan: UserPlans.EnterpriseAdmin | UserPlans.EnterpriseMember;
        }>
      ) => {
        state.users.push({
          ...payload.user,
          userPlan: payload.userPlan,
          enterprise: payload.enterprise,
        } as unknown as Draft<T>); // TODO what's wrong with TS here?
      },
      update: (state, { payload }: PayloadAction<T>) => {
        const user = state.users.find(({ id }) => id === payload.id);
        if (isNil(user)) throw new Error('Unable to update undefined user');

        Object.assign(user, payload);
      },
      updateAsAdmin: (state, { payload }: PayloadAction<T>) => {
        const user = state.users.find(({ id }) => id === payload.id);
        if (isNil(user)) throw new Error('Unable to update undefined user');

        Object.assign(user, payload);
      },
      updateUserPlan: (
        state,
        { payload }: PayloadAction<{ user: User; userPlan: UserPlans }>
      ) => {
        const user = state.users.find(({ id }) => id === payload.user.id);
        if (isNil(user)) throw new Error('Unable to update undefined user');
        user.userPlan = payload.userPlan;
      },
      remove: (state, { payload }: PayloadAction<T>) => {
        state.users = state.users.filter(({ id }) => id !== payload.id);
      },
      select: (state, { payload }: PayloadAction<T['id']>) => {
        const user = state.users.find(({ id }) => id === payload);
        if (isNil(user)) throw new Error('Unable to select undefined user');
        state.selectedUser = user;
      },
      unselect: (state) => {
        state.selectedUser = undefined;
      },
      _replace: (state, { payload }) => {
        return { ...payload };
      },
      _updateId: (
        state,
        {
          payload: { currentId, newId },
        }: PayloadAction<{
          currentId: User['id'];
          newId: User['id'];
        }>
      ) => {
        const user = state.users.find(({ id }) => id === currentId);
        if (isNil(user)) {
          throw new Error(
            `User with current ID ${currentId} does not exist in storage`
          );
        }
        user.id = newId;
      },
    },
  });

export const enterpriseUsersSlice = createUsersSlice<User>('enterpriseUsers');
export const invitedUsersSlice = createUsersSlice<InvitedUser>('invitedUsers');

import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { capitalize } from 'lodash-es';

export function DispositionQuickInfo(props: any) {
  const { classes, dataItem, onClose } = props;

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box display="flex">
          <Box width="50%">
            <Typography variant="body2" className={classes.fontWeight500}>
              {dataItem.disposition_number}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2" align="right" noWrap>
              <span className={classes.fontWeight500}>Status:</span>{' '}
              {capitalize(dataItem.status)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="50%">
            <Typography variant="body2" noWrap>
              {dataItem.company}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2" align="right">
              <span className={classes.fontWeight500}>Type:</span>{' '}
              {dataItem.disposition_type}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.actionWrapper}>
        <IconButton size="small" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

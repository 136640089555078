import type { MappingItem } from '@pn/core/domain/data';
import type { StreamableQuery } from '@pn/core/domain/query';
import { UnitSystem } from '@pn/core/domain/types';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiQueryMapper } from '@pn/services/api/query';

export const getMultiExport = (params: {
  exportId: string;
  exportType: string;
  unitSystem: UnitSystem;
  query: StreamableQuery;
  mapping: MappingItem[];
}): Promise<void> => {
  const apiQuery = apiQueryMapper
    .parquet(params.mapping)
    .toTargetQuery(params.query);

  return pnApiClient.request({
    method: 'POST',
    url: 'v2/data/multi_export',
    payload: {
      export: params.exportType,
      export_id: params.exportId,
      metric: params.unitSystem === UnitSystem.Metric,
      query: apiQuery,
    },
  });
};

export const apiExportsProvider = {
  getMultiExport,
};

import { dependencies } from '@pn/core/dependencies';
import { PolyFeature } from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import {
  computeMapTransformation,
  REFERENCE_PT,
  scalePoint,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isNil } from 'lodash-es';
import React from 'react';

export function useAddOrRemoveVertex() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { staticCanvasRef, drawingState, drawingMode, historyManager, redraw } =
    useDrawing();

  React.useEffect(() => {
    if (!isDrawingPanelOpen || drawingMode !== 'select') return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    const onClick = (e: mapboxgl.MapMouseEvent) => {
      const featuresSelectedArr = Object.values(drawingState.featuresSelected);
      const featureSelected = featuresSelectedArr[0];

      if (
        featuresSelectedArr.length !== 1 ||
        featureSelected.type !== 'poly' ||
        !isNil(featureSelected.subType)
      )
        return;

      /**
       * If a vertex is hovered, remove it.
       */
      if (!isNil(drawingState.vertexHovered)) {
        if (featureSelected.coordinates.length <= 2) return;

        const updatedFeature: PolyFeature = {
          ...featureSelected,
          coordinates: featureSelected.coordinates.filter(
            (_, i) => i !== drawingState.vertexHovered
          ),
        };

        drawingState.features[featureSelected.id] = updatedFeature;
        drawingState.featuresSelected[featureSelected.id] = updatedFeature;

        redraw();

        historyManager.add(drawingState);

        return;
      }

      /**
       * If an edge is hovered, insert a new vertex at mouse pointer.
       */
      if (drawingState.isEdgeHovered) {
        const point = scalePoint(e.point);

        const transformation = computeMapTransformation(REFERENCE_PT);
        const inverseTransformation = {
          dx: -transformation.dx,
          dy: -transformation.dy,
          scale: 1 / transformation.scale,
        };

        const newVertex = transformPoint(point, inverseTransformation);
        const { newPoints, insertIndex } = insertVertex(
          featureSelected.coordinates,
          newVertex
        );

        const updatedFeature: PolyFeature = {
          ...featureSelected,
          coordinates: newPoints,
        };

        drawingState.features[featureSelected.id] = updatedFeature;
        drawingState.featuresSelected[featureSelected.id] = updatedFeature;

        drawingState.vertexHovered = insertIndex;

        redraw();

        historyManager.add(drawingState);
      }
    };

    mapboxMap.on('dblclick', onClick);

    return () => {
      mapboxMap.off('dblclick', onClick);
    };
  }, [
    drawingMode,
    isDrawingPanelOpen,
    // the following never change:
    staticCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}

function insertVertex(
  points: Point[],
  newPoint: Point
): { newPoints: Point[]; insertIndex: number } {
  // If there are fewer than 2 points, just append the new point.
  if (points.length < 2) {
    return { newPoints: [...points, newPoint], insertIndex: points.length };
  }

  // Determine if we are dealing with a closed shape (polygon) or an open polyline.
  const isClosed =
    points.length > 2 && arePointsEqual(points[0], points[points.length - 1]);

  // Function to calculate squared distance between two points
  function distSq(a: Point, b: Point): number {
    const dx = b.x - a.x;
    const dy = b.y - a.y;
    return dx * dx + dy * dy;
  }

  // Function to find the closest point on a segment to a given point,
  // and return the squared distance to that closest point.
  function segmentDistSq(p: Point, a: Point, b: Point): number {
    const AP = { x: p.x - a.x, y: p.y - a.y };
    const AB = { x: b.x - a.x, y: b.y - a.y };
    const abLenSq = AB.x * AB.x + AB.y * AB.y;
    if (abLenSq === 0) return distSq(p, a);
    const t = Math.max(0, Math.min(1, (AP.x * AB.x + AP.y * AB.y) / abLenSq));
    return distSq(p, { x: a.x + AB.x * t, y: a.y + AB.y * t });
  }

  // Iterate over all segments to find the one that gives the minimal
  // distance from 'newPoint' to the line segment.
  let minDist = Infinity;
  let insertIndex = 0;

  const limit = isClosed ? points.length : points.length - 1;
  for (let i = 0; i < limit; i++) {
    const curr = points[i];
    const next = points[(i + 1) % points.length]; // works for both open and closed
    const d = segmentDistSq(newPoint, curr, next);
    if (d < minDist) {
      minDist = d;
      insertIndex = (i + 1) % points.length;
    }
  }

  // Insert the new point at the identified position
  const result = [
    ...points.slice(0, insertIndex),
    newPoint,
    ...points.slice(insertIndex),
  ];

  return { newPoints: result, insertIndex };
}

function arePointsEqual(a: Point, b: Point): boolean {
  return a.x === b.x && a.y === b.y;
}

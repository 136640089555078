import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import {
  clearGridSearchResultLayer,
  useAutoSearchByUrl,
} from '@pn/core/operations/search';
import { getSearchResults } from '@pn/core/providers/search/getSearchResults';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isCordova, isFreeCordova } from '@pn/core/utils/env';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  inputRoot: {
    height: 39,
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  circularProgressContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 40,
  },
  inputAdornment: {
    marginLeft: 0,
    marginRight: 0,
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
}));

export const searchbarParentId = 'search-results-anchor';

type Props = {
  isInfoPopupOpen: boolean;
  setIsInfoPopupOpen: (isOpen: boolean) => void;
  setIsResultsMenuOpen: (isOpen: boolean) => void;
};

export const Searchbar = ({
  isInfoPopupOpen,
  setIsInfoPopupOpen,
  setIsResultsMenuOpen,
}: Props) => {
  const { classes } = useStyles();
  const { xsScreen } = useScreenSize();

  const { user } = useCurrentUserStorage();

  const searchbarRef = React.useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  useAutoSearchByUrl(setSearchText);

  useHotkeys('ctrl+k', (e) => {
    e.preventDefault();

    searchbarRef.current?.focus();
  });

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Escape') {
      clearGridSearchResultLayer();
      return;
    }
    if (event.key !== 'Enter' || isEmpty(searchText)) return;

    event.preventDefault();

    if (isCordova() && window.Keyboard?.hide) {
      window.Keyboard.hide();
    }

    setIsLoading(true);

    // await wait(2000);
    await getSearchResults(searchText);

    setIsLoading(false);
    setIsResultsMenuOpen(true);
  };

  const handleClear = () => {
    setSearchText('');
    clearGridSearchResultLayer();
  };

  return (
    <div id={searchbarParentId}>
      <TextField
        fullWidth
        hiddenLabel
        variant="outlined"
        type="search"
        autoComplete="off"
        id="search-input"
        placeholder={`Search… ${!xsScreen && !isCordova() ? '(Ctrl + K)' : ''}`}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={handleKeyDown}
        slotProps={{
          htmlInput: {
            'aria-label': 'search',
            ref: searchbarRef,
          },
          input: {
            classes: {
              root: classes.inputRoot,
            },
            startAdornment: (
              <InputAdornment position="start">
                {isLoading ? (
                  <Box className={classes.circularProgressContainer}>
                    <CircularProgress size={20} color="primary" />
                  </Box>
                ) : (
                  <IconButton
                    aria-label="clear search value"
                    className={
                      isEmpty(searchText) ? classes.disabled : undefined
                    }
                    onClick={handleClear}
                  >
                    {isEmpty(searchText) ? <SearchIcon /> : <ClearIcon />}
                  </IconButton>
                )}
              </InputAdornment>
            ),
            endAdornment: !isFreeCordova(user) ? (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <IconButton
                  aria-label="search info"
                  onClick={() => setIsInfoPopupOpen(!isInfoPopupOpen)}
                >
                  {isInfoPopupOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ) : null,
          },
        }}
      />
    </div>
  );
};

export function getSearchbarWidth(): number {
  const elem = document.getElementById(searchbarParentId);
  return elem?.offsetWidth ?? 0;
}

import { ClassNameMap } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  MultiColumnRow,
  MultiColumnTable,
} from '@pn/core/domain/data-info';
import { DataSelectionReason } from '@pn/core/domain/query';
import { selectDataItem } from '@pn/core/operations/dataSelection';
import { useAccess } from '@pn/core/permissions/access';
import { useAppStorage } from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { findOrThrow } from '@pn/core/utils/logic';
import assert from 'assert';
import { isString } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { TableComponent } from './MultiColumnTable';

const useStyles = makeStyles()(() => ({
  divider: {
    marginBottom: 2,
  },
}));

type Props = {
  classes: ClassNameMap;
  table: MultiColumnTable;
};

export function ConnectedAssetsTableComponent({ classes, table }: Props) {
  const { classes: extraClasses } = useStyles();

  const access = useAccess();
  const { pageSize } = useAppStorage();

  const handleViewOnMap = (row: MultiColumnRow) => {
    assert(isString(row.link_type), 'link_type is not a string');
    assert(isString(row.link_id), 'link_id is not a string');

    if (access('allData', row.link_type).notify().denied()) return;

    const workspaceItem = findOrThrow(
      pnWorkspaceItems,
      ({ id }) => id === row.link_type
    );

    selectDataItem({
      id: row.link_id,
      reason: DataSelectionReason.ConnectedAssets,
      workspaceItem,
      pageSize,
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.tableTitle}>{table.title}</Typography>
      </Box>
      <Divider className={extraClasses.divider} />
      <TableComponent
        classes={classes}
        table={table}
        onButtonClick={handleViewOnMap}
      />
    </Box>
  );
}

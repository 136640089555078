import { isNil, uniq } from 'lodash-es';

const allowedTypePairs = [
  ['Point', 'MultiPoint'],
  ['LineString', 'MultiLineString'],
  ['Polygon', 'MultiPolygon'],
];

export function checkGeoJsonForMixedTypes(features: GeoJSON.Feature[]) {
  const types: string[] = features
    .filter((feature) => !isNil(feature.geometry))
    .map((feature) => feature.geometry.type);

  const indexes = new Set<number>();
  for (let i = 0; i < types.length; i++) {
    const type = types[i];
    const index = allowedTypePairs.findIndex((pair) => pair.includes(type));
    if (index !== -1) {
      indexes.add(index);
    }
    if (indexes.size > 1) {
      break;
    }
  }

  if (indexes.size > 1) {
    console.warn('GeoJSON contains mixed types', uniq(types));
    return true;
  } else {
    return false;
  }
}

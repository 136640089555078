import type { MapboxIMap } from '..';

export const moveLayer: MapboxIMap['moveLayer'] = function (
  this: MapboxIMap,
  layerId,
  beforeLayerIds
) {
  if (!this.hasLayer(layerId)) {
    throw new Error(`Layer ${layerId} does not exist`);
  }

  const existingBeforeLayerId = beforeLayerIds.find((id) => this.hasLayer(id));

  this._native.moveLayer(layerId, existingBeforeLayerId);
};

import { formatDataType } from '@pn/core/domain/data';

export const getLocaleText = (dataType: string) => ({
  noRowsLabel: `No ${formatDataType(dataType)} selected`, // used in Preview mode
  footerRowSelected: (count: number) =>
    count !== 1
      ? `${count.toLocaleString()} ${formatDataType(dataType)} selected`
      : `${count.toLocaleString()} ${formatDataType(dataType, {
          form: 'singular',
        })} selected`,
  headerFilterOperatorNotContains: 'Does not contain',
  // headerFilterOperatorContains: 'Contains',
  // headerFilterOperatorIs: 'Is',
  // headerFilterOperatorOnOrAfter: 'On/After',
  // headerFilterOperatorOnOrBefore: 'On/Before',
  // 'headerFilterOperator=': '=',
  // 'headerFilterOperator>=': '>=',
  // 'headerFilterOperator<=': '<=',
});

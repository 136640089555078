import React from 'react';

export function useMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlers = React.useMemo(
    () => ({
      openMenu: (e: any) => {
        setAnchorEl(e.currentTarget);
      },
      closeMenu: () => {
        setAnchorEl(null);
      },
    }),
    []
  );

  return { anchorEl, handlers };
}

import { Slider, type SliderProps } from '@mui/material';
import { debounce } from 'lodash-es';
import React from 'react';

const onDebouncedChange = debounce((callback: () => void) => callback(), 300);

export const DebouncedSlider = (props: SliderProps) => {
  const [value, setValue] = React.useState(props.value);

  React.useLayoutEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Slider
      {...props}
      value={value}
      onChange={(...args) => {
        setValue(args[1]);
        onDebouncedChange(() => props.onChange!(...args));
      }}
    />
  );
};

import { generateTemporaryId } from '@pn/core/utils/id';
import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { isArray, isEmpty, isNil, isObject, isString } from 'lodash-es';
import type { Enterprise, UserEnterprise } from './enterprise';
import type { ISODateTimeString } from './types';

export const enum UserPlans {
  Guest = 'guest',
  Free = 'free',
  Professional = 'professional',
  EnterpriseMember = 'enterprise_member',
  EnterpriseAdmin = 'enterprise_admin',
  SystemAdmin = 'system_admin',
}

/**
 * Enums for intel role. Do NOT change these! They are used in the database.
 */
export const enum IntelPlans {
  Starter = 'starter',
  Retail = 'market_intel_read',
  Professional = 'market_intel_export',
  Enterprise = 'market_intel_enterprise',
}

export type UserInvitationId = string | number;

export type BaseUser = {
  id: string;
  email: string;
  enterprise:
    | {
        id: Enterprise['id'];
        name: Enterprise['name'];
        stackCompanyId: Enterprise['stackCompanyId'];
      }
    | undefined;
  userPlan: UserPlans;
  intelPlan: IntelPlans;
};

export type User = {
  id: string;
  email: string;
  name: {
    firstName: string;
    lastName: string;
  };
  company: string;
  title: string;
  userPlan: UserPlans;
  acceptMail: boolean;
  authenticationType: string | undefined;
  enterprise: UserEnterprise | undefined;
  stackCompanyId: string | undefined; // selected Stack company in the Library
  isInvited: boolean;
  createdAt?: ISODateTimeString;
  intelPlan: IntelPlans;
  stripeAccount:
    | {
        createdAt: ISODateTimeString;
        currency: string;
        delinquent: boolean;
        email: string;
        id: string;
      }
    | undefined;
  isAdmin: boolean;
};

export function isUser(arg: unknown): arg is User {
  return (
    isObject(arg) &&
    hasKeyWithType(arg, 'id', isString) &&
    hasKeyWithType(arg, 'email', isString)
  );
}

export function isArrayOfUsers(arg: unknown): arg is User[] {
  return isArray(arg) && arg.every((a) => isUser(a));
}

export function isEnterpriseUser(user: User | undefined): boolean {
  if (isNil(user)) return false;
  return (
    (user.userPlan === UserPlans.EnterpriseAdmin ||
      user.userPlan === UserPlans.EnterpriseMember) &&
    !isNil(user.enterprise)
  );
}

export type UserForm = {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  title: string;
  acceptMail: boolean;
};

export type InvitedUser = BaseUser & {
  createdAt: ISODateTimeString;
};

export function isInvitedUser(arg: unknown): arg is InvitedUser {
  return (
    isObject(arg) &&
    hasKey(arg, 'id') &&
    hasKey(arg, 'email') &&
    hasKey(arg, 'userPlan')
  );
}

export type InvitedUserForm = {
  email: string;
  userPlan: UserPlans;
  enterpriseId: Enterprise['id'];
};

export type ProjectUser = Pick<User, 'id' | 'email' | 'name'>;

export function userToProjectUser(user: User): ProjectUser {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
  };
}

export function getFullName(user: Partial<User> | undefined): string {
  if (
    isNil(user) ||
    !hasKey(user, 'name') ||
    isNil(user.name) ||
    isEmpty(user.name.firstName) ||
    isEmpty(user.name.lastName)
  ) {
    return 'N/A';
  } else {
    return `${user.name.firstName} ${user.name.lastName}`;
  }
}

export function getInitials(user: Partial<User> | undefined): string {
  if (
    isNil(user) ||
    !hasKey(user, 'name') ||
    isNil(user.name) ||
    isEmpty(user.name.firstName) ||
    isEmpty(user.name.lastName)
  ) {
    return '?';
  } else {
    return `${user.name.firstName[0]}${user.name.lastName[0]}`;
  }
}

export function getStripeStatus(
  user: User | undefined
): 'current' | 'delinquent' | undefined {
  if (
    isNil(user) ||
    isEmpty(user.name.firstName) ||
    isEmpty(user.name.lastName) ||
    isNil(user.stripeAccount)
  ) {
    return undefined;
  } else if (!isEmpty(user.stripeAccount?.id)) {
    if (user.stripeAccount.delinquent) return 'delinquent';
    return 'current';
  }
}

export function getPermissionLevel(userPlan: UserPlans | undefined): number {
  switch (userPlan) {
    case undefined:
    case 'guest':
      return 0;
    case 'free':
      return 1;
    case 'professional':
      return 2;
    case 'enterprise_member':
      return 2;
    case 'enterprise_admin':
      return 3;
    case 'system_admin':
      return 4;
    default:
      throw new Error(`Invalid user plan: ${userPlan}`);
  }
}

export function getIntelPermissionLevel(
  userIntelPlan: IntelPlans | undefined
): number {
  switch (userIntelPlan) {
    case undefined:
    case IntelPlans.Starter:
      return 0;
    case IntelPlans.Retail:
      return 1;
    case IntelPlans.Professional:
      return 2;
    case IntelPlans.Enterprise:
      return 3;
    default:
      throw new Error(`Invalid user plan: ${userIntelPlan}`);
  }
}

export function createUser(
  userForm: UserForm
): Pick<User, 'id' | 'email' | 'name' | 'company' | 'title' | 'acceptMail'> {
  return {
    id: generateTemporaryId(),
    email: userForm.email,
    name: {
      firstName: userForm.firstName,
      lastName: userForm.lastName,
    },
    company: userForm.company,
    title: userForm.title,
    acceptMail: userForm.acceptMail,
  };
}

export function isArrayOfInvitedUsers(arg: unknown): arg is InvitedUser[] {
  return isArray(arg) && arg.every((a) => isInvitedUser(a));
}

export function createInvitedUser(userForm: InvitedUserForm): InvitedUser {
  return {
    id: generateTemporaryId(),
    email: userForm.email,
    userPlan: userForm.userPlan,
    enterprise: {
      id: userForm.enterpriseId,
      name: '',
      stackCompanyId: undefined,
    },
    createdAt: new Date().toISOString(),
    intelPlan: IntelPlans.Starter,
  };
}

export function updateUserProfile(user: User, userForm: UserForm): User {
  return {
    ...user,
    email: userForm.email,
    name: {
      firstName: userForm.firstName,
      lastName: userForm.lastName,
    },
    company: userForm.company,
    title: userForm.title,
    acceptMail: userForm.acceptMail,
  };
}

export function getFormattedUserPlan(userPlan: UserPlans | undefined): string {
  switch (userPlan) {
    case undefined:
    case UserPlans.Guest:
      return 'Guest';
    case UserPlans.Free:
      return 'Free Plan';
    case UserPlans.Professional:
      return 'Professional Plan';
    case UserPlans.EnterpriseMember:
      return 'Enterprise Member';
    case UserPlans.EnterpriseAdmin:
      return 'Enterprise Admin';
    case UserPlans.SystemAdmin:
      return 'System Admin';
    default:
      return 'Unknown Plan';
  }
}

export function getFormattedIntelPlan(
  intelPlan: IntelPlans | undefined
): string {
  switch (intelPlan) {
    case undefined:
    case IntelPlans.Starter:
      return 'Starter';
    case IntelPlans.Retail:
      return 'Retail';
    case IntelPlans.Professional:
      return 'Professional';
    case IntelPlans.Enterprise:
      return 'Enterprise';
    default:
      return 'Unknown Plan';
  }
}

import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

type Props = {
  color?: CircularProgressProps['color'];
  size?: number | string;
  text?: string;
};

export function FullHeightCircularLoader({
  color = 'primary',
  size = 64,
  text,
}: Props) {
  const { classes } = useStyles();

  if (!isNil(text)) {
    return (
      <Box className={classes.progressContainer}>
        <CircularProgress color={color} size={size} />
        <Box mt={4} />
        <Typography variant="body1" color="textSecondary">
          {text}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box className={classes.progressContainer}>
        <CircularProgress color={color} size={size} />
      </Box>
    );
  }
}

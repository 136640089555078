import type { Enterprise } from '@pn/core/domain/enterprise';
import type { User } from '@pn/core/domain/user';
import type { IUserProvider } from '@pn/core/providers/user/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { isEmpty } from 'lodash-es';
import { apiInvitedUserMapper, apiUserMapper } from './apiUserMapper';
import {
  isApiEnterpriseInvitedUser,
  isApiUser,
  type ApiEnterpriseInvitedUser,
  type ApiEnterpriseUser,
  type ApiUser,
} from './types';
import { webStorageService } from '@pn/services/storage/webStorageService';

const getUserEvent: string = import.meta.env.VITE_APP_MIXPANEL_GET_USER_EVENT;

export const requestCurrentUser = async () => {
  const invite = webStorageService('pn').get<string>('invite-string');

  let getUserURL = 'v2/user';
  if (!isEmpty(getUserEvent)) {
    getUserURL = `${getUserURL}?mixpanel_event=${encodeURIComponent(getUserEvent)}`;
  }

  const apiUser = await pnApiClient.request<ApiUser>({
    url: invite ? 'v2/user?invite=' + encodeURIComponent(invite) : getUserURL,
    withCredentials: true,
  });

  const user = apiUserMapper.toDomainUser(apiUser);

  return user;
};

export const requestUserById = async (userId: User['id']) => {
  // TODO revisit once there's a single user endpoint
  const apiUsers = await pnApiClient.request<ApiUser[]>({
    url: `v2/admin/users?user_id=${userId}`,
  });

  const user = apiUserMapper.toDomainUser(apiUsers[0]);

  return user;
};

export const requestUsersByEnterpriseIdAsAdmin = async (
  enterpriseId: Enterprise['id']
) => {
  const { users: apiUsers } = await pnApiClient.request<{
    users: ApiEnterpriseUser[];
  }>({
    url: `v2/admin/users?page=1&page_size=1000&enterprise_id=${enterpriseId}`, // TODO hardcoded because were re-using a paginaged endpoint
  });

  const users = apiUsers.map((user) => {
    if (isApiUser(user)) {
      return apiUserMapper.toDomainUser(user);
    } else {
      throw new Error('Invalid api user');
    }
  });

  return users;
};

export const requestInvitedUsersByEnterpriseId = async (
  enterpriseId: Enterprise['id']
) => {
  const apiUsers = await pnApiClient.request<ApiEnterpriseInvitedUser[]>({
    url: `v1/invites?enterprise_id=${enterpriseId}`,
  });

  const users = apiUsers.map((user) => {
    if (isApiEnterpriseInvitedUser(user)) {
      return apiInvitedUserMapper.toDomainInvitedUser(user);
    } else {
      throw new Error('Invalid api invited user');
    }
  });

  return users;
};

const requestPaginatedUsersByParams = async (param: string) => {
  const { users: apiUsers, total_rows: rowCount } = await pnApiClient.request<{
    users: ApiEnterpriseUser[];
    total_rows: number;
  }>({
    url: `v2/admin/users${param}`,
  });

  const users = apiUsers.map((user) => {
    if (isApiUser(user)) {
      return apiUserMapper.toDomainUser(user);
    } else {
      throw new Error('Invalid api user');
    }
  });

  return { users, rowCount };
};

export const requestUsersBySearchQuery = async (searchQuery: string) => {
  const apiUsers = await pnApiClient.request<ApiEnterpriseUser[]>({
    url: `v2/admin/users/search?search_term=${searchQuery}`,
  });

  const users = apiUsers.map((user) => {
    if (isApiUser(user)) {
      return apiUserMapper.toDomainUser(user);
    } else {
      throw new Error('Invalid api user');
    }
  });

  return users;
};

export const apiUserProvider: IUserProvider = {
  getCurrentUser: requestCurrentUser,
  getUserById: requestUserById,
  getUsersByEnterpriseIdAsAdmin: requestUsersByEnterpriseIdAsAdmin,
  getInvitedUsersByEnterpriseId: requestInvitedUsersByEnterpriseId,
  getUsersBySearchQuery: requestUsersBySearchQuery,
  getPaginatedUsersByParams: requestPaginatedUsersByParams,
};

import { hideBuiltInLayer } from '.';

export const darkMapboxLayers = [
  {
    id: 'land',
    type: 'background',
    layout: {},
    minzoom: 0,
    paint: {
      'background-color': 'hsl(0, 0%, 16%)',
    },
  },
  {
    id: 'national-park',
    type: 'fill',
    source: 'composite',
    'source-layer': 'landuse_overlay',
    minzoom: 5,
    filter: ['==', ['get', 'class'], 'national_park'],
    layout: {},
    paint: {
      'fill-color': 'hsl(0, 2%, 15%)',
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        5,
        0,
        6,
        0.6,
        12,
        0.2,
      ],
    },
  },
  {
    id: 'landuse',
    type: 'fill',
    source: 'composite',
    'source-layer': 'landuse',
    minzoom: 5,
    filter: [
      'all',
      ['>=', ['to-number', ['get', 'sizerank']], 0],
      [
        'match',
        ['get', 'class'],
        [
          'agriculture',
          'wood',
          'grass',
          'scrub',
          'park',
          'airport',
          'glacier',
          'pitch',
          'sand',
        ],
        true,
        'residential',
        ['step', ['zoom'], true, 12, false],
        false,
      ],
      [
        '<=',
        [
          '-',
          ['to-number', ['get', 'sizerank']],
          ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0, 18, 14],
        ],
        8,
      ],
    ],
    layout: {},
    paint: {
      'fill-color': 'hsl(0, 2%, 15%)',
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        8,
        ['match', ['get', 'class'], ['residential', 'airport'], 0.8, 0.2],
        12,
        ['match', ['get', 'class'], 'residential', 0, 1],
      ],
    },
  },
  {
    id: 'waterway',
    type: 'line',
    source: 'composite',
    'source-layer': 'waterway',
    minzoom: 8,
    layout: {
      'line-cap': ['step', ['zoom'], 'butt', 11, 'round'],
      'line-join': 'round',
    },
    paint: {
      'line-color': 'hsl(0, 0%, 12%)',
      'line-width': [
        'interpolate',
        ['exponential', 1.3],
        ['zoom'],
        9,
        ['match', ['get', 'class'], ['canal', 'river'], 0.1, 0],
        20,
        ['match', ['get', 'class'], ['canal', 'river'], 8, 3],
      ],
      'line-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 8.5, 1],
    },
  },
  {
    id: 'water',
    type: 'fill',
    source: 'composite',
    'source-layer': 'water',
    minzoom: 0,
    layout: {},
    paint: {
      'fill-color': 'hsl(0, 0%, 12%)',
    },
  },
  {
    id: 'land-structure-polygon',
    type: 'fill',
    source: 'composite',
    'source-layer': 'structure',
    minzoom: 13,
    filter: [
      'all',
      ['==', ['get', 'class'], 'land'],
      ['==', ['geometry-type'], 'Polygon'],
    ],
    layout: {},
    paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        9,
        'hsl(0, 0%, 16%)',
        11,
        'hsl(0, 0%, 16%)',
      ],
    },
  },
  {
    id: 'land-structure-line',
    type: 'line',
    source: 'composite',
    'source-layer': 'structure',
    minzoom: 13,
    filter: [
      'all',
      ['==', ['get', 'class'], 'land'],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': 'square',
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.99],
        ['zoom'],
        14,
        0.75,
        20,
        40,
      ],
      'line-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        9,
        'hsl(0, 0%, 16%)',
        11,
        'hsl(0, 0%, 16%)',
      ],
    },
  },
  {
    id: 'aeroway-polygon',
    type: 'fill',
    source: 'composite',
    'source-layer': 'aeroway',
    minzoom: 11,
    filter: [
      'all',
      ['match', ['get', 'type'], ['runway', 'taxiway', 'helipad'], true, false],
      ['==', ['geometry-type'], 'Polygon'],
    ],
    paint: {
      'fill-color': 'hsl(0, 0%, 25%)',
      'fill-opacity': ['interpolate', ['linear'], ['zoom'], 10, 0, 11, 1],
    },
  },
  {
    id: 'aeroway-line',
    type: 'line',
    source: 'composite',
    'source-layer': 'aeroway',
    minzoom: 9,
    filter: ['==', ['geometry-type'], 'LineString'],
    paint: {
      'line-color': 'hsl(0, 0%, 25%)',
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        9,
        ['match', ['get', 'type'], 'runway', 1, 0.5],
        18,
        ['match', ['get', 'type'], 'runway', 80, 20],
      ],
      'line-opacity': ['interpolate', ['linear'], ['zoom'], 10, 0, 11, 1],
    },
  },
  {
    id: 'building',
    type: 'fill',
    source: 'composite',
    'source-layer': 'building',
    minzoom: 15,
    filter: [
      'all',
      ['!=', ['get', 'type'], 'building:part'],
      ['==', ['get', 'underground'], 'false'],
    ],
    layout: {},
    paint: {
      'fill-color': 'hsl(0, 0%, 12%)',
      'fill-opacity': ['interpolate', ['linear'], ['zoom'], 15, 0, 16, 1],
      'fill-outline-color': 'hsl(0, 0%, 9%)',
    },
  },
  {
    id: 'tunnel-path-trail',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 14,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'tunnel'],
      ['==', ['get', 'class'], 'path'],
      [
        'match',
        ['get', 'type'],
        ['hiking', 'mountain_bike', 'trail'],
        true,
        false,
      ],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 12%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'tunnel-path-cycleway-piste',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 12,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'tunnel'],
      ['==', ['get', 'class'], 'path'],
      ['match', ['get', 'type'], ['cycleway', 'piste'], true, false],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {},
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 12%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'tunnel-path',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 14,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'tunnel'],
      ['==', ['get', 'class'], 'path'],
      ['!=', ['get', 'type'], 'steps'],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 12%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'tunnel-steps',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 14,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'tunnel'],
      ['==', ['get', 'type'], 'steps'],
      ['==', ['geometry-type'], 'LineString'],
    ],
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        16,
        1.6,
        18,
        6,
      ],
      'line-color': 'hsl(0, 0%, 12%)',
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [1, 0]],
        15,
        ['literal', [1.75, 1]],
        16,
        ['literal', [1, 0.75]],
        17,
        ['literal', [0.3, 0.3]],
      ],
    },
  },
  {
    id: 'tunnel-pedestrian',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 13,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'tunnel'],
      ['==', ['get', 'class'], 'pedestrian'],
      ['==', ['geometry-type'], 'LineString'],
    ],
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        14,
        0.5,
        18,
        12,
      ],
      'line-color': 'hsl(0, 0%, 12%)',
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [1, 0]],
        15,
        ['literal', [1.5, 0.4]],
        16,
        ['literal', [1, 0.2]],
      ],
    },
  },
  {
    id: 'tunnel-simple',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 13,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'tunnel'],
      [
        'step',
        ['zoom'],
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'secondary',
            'tertiary',
            'street',
            'street_limited',
            'primary_link',
            'track',
          ],
          true,
          false,
        ],
        14,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'primary_link',
            'secondary',
            'secondary_link',
            'tertiary',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          true,
          false,
        ],
      ],
      ['==', ['geometry-type'], 'LineString'],
    ],
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        13,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          2.4,
          ['secondary', 'tertiary'],
          1.5,
          [
            'motorway_link',
            'trunk_link',
            'street',
            'street_limited',
            'primary_link',
          ],
          0.6,
          0.5,
        ],
        18,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          19.2,
          ['secondary', 'tertiary'],
          15.6,
          [
            'motorway_link',
            'trunk_link',
            'street',
            'street_limited',
            'primary_link',
          ],
          10.799999999999999,
          7.199999999999999,
        ],
        22,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          192,
          ['secondary', 'tertiary'],
          156,
          [
            'motorway_link',
            'trunk_link',
            'primary_link',
            'street',
            'street_limited',
          ],
          108,
          72,
        ],
      ],
      'line-color': 'hsl(0, 0%, 24%)',
    },
  },
  {
    id: 'road-path-trail',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 12,
    filter: [
      'all',
      ['==', ['get', 'class'], 'path'],
      [
        'match',
        ['get', 'type'],
        ['hiking', 'mountain_bike', 'trail'],
        true,
        false,
      ],
      ['match', ['get', 'structure'], ['none', 'ford'], true, false],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'road-path-cycleway-piste',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 12,
    filter: [
      'all',
      ['==', ['get', 'class'], 'path'],
      ['match', ['get', 'type'], ['cycleway', 'piste'], true, false],
      ['match', ['get', 'structure'], ['none', 'ford'], true, false],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'road-path',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 12,
    filter: [
      'all',
      ['==', ['get', 'class'], 'path'],
      [
        'step',
        ['zoom'],
        [
          '!',
          [
            'match',
            ['get', 'type'],
            ['steps', 'sidewalk', 'crossing'],
            true,
            false,
          ],
        ],
        16,
        ['!=', ['get', 'type'], 'steps'],
      ],
      ['match', ['get', 'structure'], ['none', 'ford'], true, false],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        13,
        0.5,
        14,
        1,
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'road-steps',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 14,
    filter: [
      'all',
      ['==', ['get', 'type'], 'steps'],
      ['match', ['get', 'structure'], ['none', 'ford'], true, false],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-join': 'round',
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        16,
        1.6,
        18,
        6,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [1, 0]],
        15,
        ['literal', [1.75, 1]],
        16,
        ['literal', [1, 0.75]],
        17,
        ['literal', [0.3, 0.3]],
      ],
    },
  },
  {
    id: 'road-pedestrian',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 12,
    filter: [
      'all',
      ['==', ['get', 'class'], 'pedestrian'],
      ['match', ['get', 'structure'], ['none', 'ford'], true, false],
      ['case', ['has', 'layer'], ['>=', ['get', 'layer'], 0], true],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        14,
        0.5,
        18,
        12,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [1, 0]],
        15,
        ['literal', [1.5, 0.4]],
        16,
        ['literal', [1, 0.2]],
      ],
    },
  },
  {
    id: 'road-simple',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 5,
    filter: [
      'all',
      [
        'step',
        ['zoom'],
        ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
        6,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          true,
          false,
        ],
        8,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary', 'secondary'],
          true,
          false,
        ],
        10,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'trunk',
            'primary',
            'secondary',
            'tertiary',
            'motorway_link',
            'trunk_link',
          ],
          true,
          false,
        ],
        11,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'secondary',
            'tertiary',
            'street',
          ],
          true,
          false,
        ],
        12,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'secondary',
            'tertiary',
            'street',
            'street_limited',
            'primary_link',
          ],
          true,
          false,
        ],
        13,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'secondary',
            'tertiary',
            'street',
            'street_limited',
            'primary_link',
            'track',
          ],
          true,
          false,
        ],
        14,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'primary_link',
            'secondary',
            'secondary_link',
            'tertiary',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          true,
          false,
        ],
      ],
      ['match', ['get', 'structure'], ['none', 'ford'], true, false],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
      'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        5,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          0.44999999999999996,
          ['secondary', 'tertiary'],
          0.06,
          0,
        ],
        13,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          2.4,
          ['secondary', 'tertiary'],
          1.5,
          [
            'motorway_link',
            'trunk_link',
            'primary_link',
            'street',
            'street_limited',
          ],
          0.6,
          0.3,
        ],
        18,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          19.2,
          ['secondary', 'tertiary'],
          15.6,
          [
            'motorway_link',
            'trunk_link',
            'primary_link',
            'street',
            'street_limited',
          ],
          10.799999999999999,
          6,
        ],
        22,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          192,
          ['secondary', 'tertiary'],
          156,
          [
            'motorway_link',
            'trunk_link',
            'primary_link',
            'street',
            'street_limited',
          ],
          108,
          60,
        ],
      ],
      'line-color': 'hsl(0, 0%, 24%)',
    },
  },
  {
    id: 'road-rail',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 13,
    filter: [
      'all',
      ['match', ['get', 'class'], ['major_rail', 'minor_rail'], true, false],
      ['match', ['get', 'structure'], ['none', 'ford'], true, false],
    ],
    paint: {
      'line-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        'hsl(0, 0%, 14%)',
        17,
        'hsl(0, 0%, 12%)',
      ],
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        14,
        0.5,
        20,
        1,
      ],
    },
  },
  {
    id: 'bridge-path-trail',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 14,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'bridge'],
      ['==', ['get', 'class'], 'path'],
      [
        'match',
        ['get', 'type'],
        ['hiking', 'mountain_bike', 'trail'],
        true,
        false,
      ],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'bridge-path-cycleway-piste',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 14,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'bridge'],
      ['==', ['get', 'class'], 'path'],
      ['match', ['get', 'type'], ['cycleway', 'piste'], true, false],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {},
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'bridge-path',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 14,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'bridge'],
      ['==', ['get', 'class'], 'path'],
      ['!=', ['get', 'type'], 'steps'],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        18,
        4,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'bridge-steps',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 14,
    filter: [
      'all',
      ['==', ['get', 'type'], 'steps'],
      ['==', ['get', 'structure'], 'bridge'],
      ['==', ['geometry-type'], 'LineString'],
    ],
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        15,
        1,
        16,
        1.6,
        18,
        6,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [1, 0]],
        15,
        ['literal', [1.75, 1]],
        16,
        ['literal', [1, 0.75]],
        17,
        ['literal', [0.3, 0.3]],
      ],
    },
  },
  {
    id: 'bridge-pedestrian',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 13,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'bridge'],
      ['==', ['get', 'class'], 'pedestrian'],
      ['==', ['geometry-type'], 'LineString'],
    ],
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        14,
        0.5,
        18,
        12,
      ],
      'line-color': 'hsl(0, 0%, 24%)',
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [1, 0]],
        15,
        ['literal', [1.5, 0.4]],
        16,
        ['literal', [1, 0.2]],
      ],
    },
  },
  {
    id: 'bridge-case-simple',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 13,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'bridge'],
      [
        'step',
        ['zoom'],
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'secondary',
            'tertiary',
            'street',
            'street_limited',
            'primary_link',
            'track',
          ],
          true,
          false,
        ],
        14,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'primary_link',
            'secondary',
            'secondary_link',
            'tertiary',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          true,
          false,
        ],
      ],
      ['==', ['geometry-type'], 'LineString'],
    ],
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        13,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          3.5999999999999996,
          ['secondary', 'tertiary'],
          2.4,
          [
            'motorway_link',
            'trunk_link',
            'street',
            'street_limited',
            'primary_link',
          ],
          1.5,
          0.75,
        ],
        18,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          21.599999999999998,
          ['secondary', 'tertiary'],
          18,
          [
            'motorway_link',
            'trunk_link',
            'street',
            'street_limited',
            'primary_link',
          ],
          13.2,
          9.6,
        ],
        22,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          216,
          ['secondary', 'tertiary'],
          180,
          [
            'motorway_link',
            'trunk_link',
            'primary_link',
            'street',
            'street_limited',
          ],
          132,
          96,
        ],
      ],
      'line-color': 'hsl(0, 0%, 16%)',
    },
  },
  {
    id: 'bridge-simple',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 13,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'bridge'],
      [
        'step',
        ['zoom'],
        ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
        13,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'secondary',
            'tertiary',
            'street',
            'street_limited',
            'primary_link',
            'track',
          ],
          true,
          false,
        ],
        14,
        [
          'match',
          ['get', 'class'],
          [
            'motorway',
            'motorway_link',
            'trunk',
            'trunk_link',
            'primary',
            'primary_link',
            'secondary',
            'secondary_link',
            'tertiary',
            'tertiary_link',
            'street',
            'street_limited',
            'service',
            'track',
          ],
          true,
          false,
        ],
      ],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
    },
    paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        13,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          2.4,
          ['secondary', 'tertiary'],
          1.5,
          [
            'motorway_link',
            'trunk_link',
            'street',
            'street_limited',
            'primary_link',
          ],
          0.6,
          0.5,
        ],
        18,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          19.2,
          ['secondary', 'tertiary'],
          15.6,
          [
            'motorway_link',
            'trunk_link',
            'street',
            'street_limited',
            'primary_link',
          ],
          10.799999999999999,
          7.199999999999999,
        ],
        22,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary'],
          192,
          ['secondary', 'tertiary'],
          156,
          [
            'motorway_link',
            'trunk_link',
            'primary_link',
            'street',
            'street_limited',
          ],
          108,
          72,
        ],
      ],
      'line-color': 'hsl(0, 0%, 24%)',
    },
  },
  {
    id: 'bridge-rail',
    type: 'line',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 13,
    filter: [
      'all',
      ['==', ['get', 'structure'], 'bridge'],
      ['match', ['get', 'class'], ['major_rail', 'minor_rail'], true, false],
    ],
    paint: {
      'line-color': 'hsl(0, 0%, 12%)',
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        14,
        0.5,
        20,
        1,
      ],
    },
  },
  {
    id: 'admin-1-boundary-bg',
    type: 'line',
    source: 'composite',
    'source-layer': 'admin',
    minzoom: 7,
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 1],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    paint: {
      'line-color': 'hsl(0, 0%, 12%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 3, 3, 12, 6],
      'line-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 0.5],
      'line-dasharray': [1, 0],
      'line-blur': ['interpolate', ['linear'], ['zoom'], 3, 0, 12, 3],
    },
  },
  {
    id: 'admin-0-boundary-bg',
    type: 'line',
    source: 'composite',
    'source-layer': 'admin',
    minzoom: 1,
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 0],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    paint: {
      'line-width': ['interpolate', ['linear'], ['zoom'], 3, 5.2, 12, 10.4],
      'line-color': 'hsl(0, 0%, 12%)',
      'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 4, 0.5],
      'line-blur': ['interpolate', ['linear'], ['zoom'], 3, 0, 12, 2.6],
    },
  },
  {
    id: 'admin-1-boundary',
    type: 'line',
    source: 'composite',
    'source-layer': 'admin',
    minzoom: 2,
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 1],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    layout: {},
    paint: {
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [2, 0]],
        7,
        ['literal', [2, 2, 6, 2]],
      ],
      'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.3, 12, 1.5],
      'line-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 3, 1],
      'line-color': 'hsl(0, 0%, 38%)',
    },
  },
  {
    id: 'admin-0-boundary',
    type: 'line',
    source: 'composite',
    'source-layer': 'admin',
    minzoom: 1,
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 0],
      ['==', ['get', 'disputed'], 'false'],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    layout: {},
    paint: {
      'line-color': 'hsl(0, 0%, 41%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.65, 12, 2.6],
      'line-dasharray': [10, 0],
    },
  },
  {
    id: 'admin-0-boundary-disputed',
    type: 'line',
    source: 'composite',
    'source-layer': 'admin',
    minzoom: 1,
    filter: [
      'all',
      ['==', ['get', 'disputed'], 'true'],
      ['==', ['get', 'admin_level'], 0],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    paint: {
      'line-color': 'hsl(0, 0%, 41%)',
      'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.65, 12, 2.6],
      'line-dasharray': [
        'step',
        ['zoom'],
        ['literal', [3, 2, 5]],
        7,
        ['literal', [2, 1.5]],
      ],
    },
  },
  {
    id: 'road-label-simple',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'road',
    minzoom: 12,
    filter: [
      'all',
      ['has', 'name'],
      [
        'match',
        ['get', 'class'],
        [
          'motorway',
          'trunk',
          'primary',
          'secondary',
          'tertiary',
          'street',
          'street_limited',
        ],
        true,
        false,
      ],
    ],
    layout: {
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
          10,
          9,
        ],
        18,
        [
          'match',
          ['get', 'class'],
          ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
          16,
          14,
        ],
      ],
      'text-max-angle': 30,
      'text-font': ['DIN Pro Regular', 'Arial Unicode MS Regular'],
      'symbol-placement': 'line',
      'text-padding': 1,
      'text-rotation-alignment': 'map',
      'text-pitch-alignment': 'viewport',
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-letter-spacing': 0.01,
    },
    paint: {
      'text-color': 'hsl(0, 0%, 66%)',
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 1,
    },
  },
  {
    id: 'waterway-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'natural_label',
    minzoom: 13,
    filter: [
      'all',
      [
        'match',
        ['get', 'class'],
        [
          'canal',
          'river',
          'stream',
          'disputed_canal',
          'disputed_river',
          'disputed_stream',
        ],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        false,
      ],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'text-font': ['DIN Pro Italic', 'Arial Unicode MS Regular'],
      'text-max-angle': 30,
      'symbol-spacing': [
        'interpolate',
        ['linear', 1],
        ['zoom'],
        15,
        250,
        17,
        400,
      ],
      'text-size': ['interpolate', ['linear'], ['zoom'], 13, 12, 18, 18],
      'symbol-placement': 'line',
      'text-pitch-alignment': 'viewport',
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
    },
    paint: {
      'text-color': 'hsl(0, 0%, 35%)',
      'text-halo-color': 'hsla(0, 0%, 3%, 0.5)',
    },
  },
  {
    id: 'natural-line-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'natural_label',
    minzoom: 4,
    filter: [
      'all',
      [
        'match',
        ['get', 'class'],
        ['glacier', 'landform', 'disputed_glacier', 'disputed_landform'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        false,
      ],
      ['<=', ['get', 'filterrank'], 1],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'text-size': [
        'step',
        ['zoom'],
        ['step', ['get', 'sizerank'], 18, 5, 12],
        17,
        ['step', ['get', 'sizerank'], 18, 13, 12],
      ],
      'text-max-angle': 30,
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'symbol-placement': 'line-center',
      'text-pitch-alignment': 'viewport',
    },
    paint: {
      'text-halo-width': 0.5,
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-blur': 0.5,
      'text-color': 'hsl(0, 0%, 53%)',
    },
  },
  {
    id: 'natural-point-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'natural_label',
    minzoom: 4,
    filter: [
      'all',
      [
        'match',
        ['get', 'class'],
        [
          'dock',
          'glacier',
          'landform',
          'water_feature',
          'wetland',
          'disputed_dock',
          'disputed_glacier',
          'disputed_landform',
          'disputed_water_feature',
          'disputed_wetland',
        ],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        false,
      ],
      ['<=', ['get', 'filterrank'], 1],
      ['==', ['geometry-type'], 'Point'],
    ],
    layout: {
      'text-size': [
        'step',
        ['zoom'],
        ['step', ['get', 'sizerank'], 18, 5, 12],
        17,
        ['step', ['get', 'sizerank'], 18, 13, 12],
      ],
      'icon-image': '',
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-offset': ['literal', [0, 0]],
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
    },
    paint: {
      'icon-opacity': [
        'step',
        ['zoom'],
        ['step', ['get', 'sizerank'], 0, 5, 1],
        17,
        ['step', ['get', 'sizerank'], 0, 13, 1],
      ],
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 0.5,
      'text-halo-blur': 0.5,
      'text-color': 'hsl(0, 0%, 53%)',
    },
  },
  {
    id: 'water-line-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'natural_label',
    minzoom: 1,
    filter: [
      'all',
      [
        'match',
        ['get', 'class'],
        [
          'bay',
          'ocean',
          'reservoir',
          'sea',
          'water',
          'disputed_bay',
          'disputed_ocean',
          'disputed_reservoir',
          'disputed_sea',
          'disputed_water',
        ],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        false,
      ],
      ['==', ['geometry-type'], 'LineString'],
    ],
    layout: {
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        ['*', ['-', 16, ['sqrt', ['get', 'sizerank']]], 1],
        22,
        ['*', ['-', 22, ['sqrt', ['get', 'sizerank']]], 1],
      ],
      'text-max-angle': 30,
      'text-letter-spacing': [
        'match',
        ['get', 'class'],
        'ocean',
        0.25,
        ['sea', 'bay'],
        0.15,
        0,
      ],
      'text-font': ['DIN Pro Italic', 'Arial Unicode MS Regular'],
      'symbol-placement': 'line-center',
      'text-pitch-alignment': 'viewport',
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
    },
    paint: {
      'text-color': 'hsl(0, 0%, 35%)',
      'text-halo-color': 'hsla(0, 0%, 3%, 0.5)',
    },
  },
  {
    id: 'water-point-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'natural_label',
    minzoom: 1,
    filter: [
      'all',
      [
        'match',
        ['get', 'class'],
        [
          'bay',
          'ocean',
          'reservoir',
          'sea',
          'water',
          'disputed_bay',
          'disputed_ocean',
          'disputed_reservoir',
          'disputed_sea',
          'disputed_water',
        ],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        false,
      ],
      ['==', ['geometry-type'], 'Point'],
    ],
    layout: {
      'text-line-height': 1.3,
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        ['*', ['-', 16, ['sqrt', ['get', 'sizerank']]], 1],
        22,
        ['*', ['-', 22, ['sqrt', ['get', 'sizerank']]], 1],
      ],
      'text-font': ['DIN Pro Italic', 'Arial Unicode MS Regular'],
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-letter-spacing': [
        'match',
        ['get', 'class'],
        'ocean',
        0.25,
        ['bay', 'sea'],
        0.15,
        0.01,
      ],
      'text-max-width': [
        'match',
        ['get', 'class'],
        'ocean',
        4,
        'sea',
        5,
        ['bay', 'water'],
        7,
        10,
      ],
    },
    paint: {
      'text-color': 'hsl(0, 0%, 35%)',
      'text-halo-color': 'hsla(0, 0%, 3%, 0.5)',
    },
  },
  {
    id: 'poi-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'poi_label',
    minzoom: 6,
    filter: [
      '<=',
      ['get', 'filterrank'],
      ['+', ['step', ['zoom'], 0, 16, 1, 17, 2], 1],
    ],
    layout: {
      'text-size': [
        'step',
        ['zoom'],
        ['step', ['get', 'sizerank'], 18, 5, 12],
        17,
        ['step', ['get', 'sizerank'], 18, 13, 12],
      ],
      'icon-image': '',
      'text-font': ['DIN Pro Italic', 'Arial Unicode MS Regular'],
      'text-offset': [0, 0],
      'text-anchor': [
        'step',
        ['zoom'],
        ['step', ['get', 'sizerank'], 'center', 5, 'top'],
        17,
        ['step', ['get', 'sizerank'], 'center', 13, 'top'],
      ],
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
    },
    paint: {
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 0.5,
      'text-halo-blur': 0.5,
      'text-color': 'hsl(0, 0%, 53%)',
    },
  },
  {
    id: 'airport-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'airport_label',
    minzoom: 8,
    filter: [
      'match',
      ['get', 'class'],
      ['military', 'civil', 'disputed_military', 'disputed_civil'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      false,
    ],
    layout: {
      'text-line-height': 1.1,
      'text-size': ['step', ['get', 'sizerank'], 18, 9, 12],
      'icon-image': '',
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-offset': [0, 0],
      'text-rotation-alignment': 'viewport',
      'text-anchor': 'top',
      'text-field': [
        'step',
        ['get', 'sizerank'],
        [
          'case',
          ['has', 'ref'],
          [
            'concat',
            ['get', 'ref'],
            ' -\n',
            ['coalesce', ['get', 'name_en'], ['get', 'name']],
          ],
          ['coalesce', ['get', 'name_en'], ['get', 'name']],
        ],
        15,
        ['get', 'ref'],
      ],
      'text-letter-spacing': 0.01,
      'text-max-width': 9,
    },
    paint: {
      'text-color': 'hsl(0, 0%, 66%)',
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 1,
    },
  },
  {
    id: 'settlement-subdivision-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'place_label',
    minzoom: 10,
    maxzoom: 15,
    filter: [
      'all',
      [
        'match',
        ['get', 'class'],
        ['settlement_subdivision', 'disputed_settlement_subdivision'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        false,
      ],
      ['<=', ['get', 'filterrank'], 3],
    ],
    layout: {
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-transform': 'uppercase',
      'text-font': ['DIN Pro Regular', 'Arial Unicode MS Regular'],
      'text-letter-spacing': ['match', ['get', 'type'], 'suburb', 0.15, 0.05],
      'text-max-width': 7,
      'text-padding': 3,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.5, 0, 1, 1],
        ['zoom'],
        11,
        ['match', ['get', 'type'], 'suburb', 11, 10.5],
        15,
        ['match', ['get', 'type'], 'suburb', 15, 14],
      ],
    },
    paint: {
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 1,
      'text-color': 'hsl(0, 0%, 54%)',
      'text-halo-blur': 0.5,
    },
  },
  {
    id: 'settlement-minor-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'place_label',
    minzoom: 2,
    maxzoom: 13,
    filter: [
      'all',
      ['<=', ['get', 'filterrank'], 2],
      [
        'match',
        ['get', 'class'],
        ['settlement', 'disputed_settlement'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        false,
      ],
      [
        'step',
        ['zoom'],
        ['>', ['get', 'symbolrank'], 6],
        4,
        ['>=', ['get', 'symbolrank'], 7],
        6,
        ['>=', ['get', 'symbolrank'], 8],
        7,
        ['>=', ['get', 'symbolrank'], 10],
        10,
        ['>=', ['get', 'symbolrank'], 11],
        11,
        ['>=', ['get', 'symbolrank'], 13],
        12,
        ['>=', ['get', 'symbolrank'], 15],
      ],
    ],
    layout: {
      'symbol-sort-key': ['get', 'symbolrank'],
      'icon-image': '',
      'text-font': ['DIN Pro Regular', 'Arial Unicode MS Regular'],
      'text-radial-offset': [
        'step',
        ['zoom'],
        ['match', ['get', 'capital'], 2, 0.6, 0.55],
        8,
        0,
      ],
      'text-anchor': ['step', ['zoom'], 'center', 8, 'center'],
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-max-width': 7,
      'text-line-height': 1.1,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.2, 0, 0.9, 1],
        ['zoom'],
        3,
        ['step', ['get', 'symbolrank'], 11, 9, 10],
        6,
        ['step', ['get', 'symbolrank'], 14, 9, 12, 12, 10],
        8,
        ['step', ['get', 'symbolrank'], 16, 9, 14, 12, 12, 15, 10],
        13,
        ['step', ['get', 'symbolrank'], 22, 9, 20, 12, 16, 15, 14],
      ],
    },
    paint: {
      'text-color': [
        'step',
        ['get', 'symbolrank'],
        'hsl(0, 0%, 66%)',
        11,
        'hsl(0, 0%, 53%)',
        16,
        'hsl(0, 0%, 47%)',
      ],
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 1,
      'icon-opacity': ['step', ['zoom'], 1, 8, 0],
      'text-halo-blur': 1,
    },
  },
  {
    id: 'settlement-major-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'place_label',
    minzoom: 2,
    maxzoom: 15,
    filter: [
      'all',
      ['<=', ['get', 'filterrank'], 2],
      [
        'match',
        ['get', 'class'],
        ['settlement', 'disputed_settlement'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
        false,
      ],
      [
        'step',
        ['zoom'],
        false,
        2,
        ['<=', ['get', 'symbolrank'], 6],
        4,
        ['<', ['get', 'symbolrank'], 7],
        6,
        ['<', ['get', 'symbolrank'], 8],
        7,
        ['<', ['get', 'symbolrank'], 10],
        10,
        ['<', ['get', 'symbolrank'], 11],
        11,
        ['<', ['get', 'symbolrank'], 13],
        12,
        ['<', ['get', 'symbolrank'], 15],
        13,
        ['>=', ['get', 'symbolrank'], 11],
        14,
        ['>=', ['get', 'symbolrank'], 15],
      ],
    ],
    layout: {
      'symbol-sort-key': ['get', 'symbolrank'],
      'icon-image': '',
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-radial-offset': [
        'step',
        ['zoom'],
        ['match', ['get', 'capital'], 2, 0.6, 0.55],
        8,
        0,
      ],
      'text-anchor': ['step', ['zoom'], 'center', 8, 'center'],
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-max-width': 7,
      'text-line-height': 1.1,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.2, 0, 0.9, 1],
        ['zoom'],
        3,
        ['step', ['get', 'symbolrank'], 13, 6, 11],
        6,
        ['step', ['get', 'symbolrank'], 18, 6, 16, 7, 14],
        8,
        ['step', ['get', 'symbolrank'], 20, 9, 16, 10, 14],
        15,
        ['step', ['get', 'symbolrank'], 24, 9, 20, 12, 16, 15, 14],
      ],
    },
    paint: {
      'text-color': [
        'step',
        ['get', 'symbolrank'],
        'hsl(0, 0%, 66%)',
        11,
        'hsl(0, 0%, 53%)',
        16,
        'hsl(0, 0%, 47%)',
      ],
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 1,
      'icon-opacity': ['step', ['zoom'], 1, 8, 0],
      'text-halo-blur': 1,
    },
  },
  {
    id: 'state-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'place_label',
    minzoom: 3,
    maxzoom: 9,
    filter: [
      'match',
      ['get', 'class'],
      ['state', 'disputed_state'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      false,
    ],
    layout: {
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.85, 0.7, 0.65, 1],
        ['zoom'],
        4,
        ['step', ['get', 'symbolrank'], 9, 6, 8, 7, 7],
        9,
        ['step', ['get', 'symbolrank'], 21, 6, 16, 7, 14],
      ],
      'text-transform': 'uppercase',
      'text-font': ['DIN Pro Bold', 'Arial Unicode MS Bold'],
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-letter-spacing': 0.15,
      'text-max-width': 6,
    },
    paint: {
      'text-color': 'hsl(0, 0%, 66%)',
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 1,
      'text-opacity': 0.5,
    },
  },
  {
    id: 'country-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'place_label',
    minzoom: 1,
    maxzoom: 10,
    filter: [
      'match',
      ['get', 'class'],
      ['country', 'disputed_country'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      false,
    ],
    layout: {
      'icon-image': '',
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-line-height': 1.1,
      'text-max-width': 6,
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-radial-offset': ['step', ['zoom'], 0.6, 8, 0],
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.2, 0, 0.7, 1],
        ['zoom'],
        1,
        ['step', ['get', 'symbolrank'], 11, 4, 9, 5, 8],
        9,
        ['step', ['get', 'symbolrank'], 22, 4, 19, 5, 17],
      ],
    },
    paint: {
      'icon-opacity': [
        'step',
        ['zoom'],
        ['case', ['has', 'text_anchor'], 1, 0],
        7,
        0,
      ],
      'text-color': 'hsl(0, 0%, 40%)',
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 1.25,
    },
  },
  {
    id: 'continent-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'natural_label',
    minzoom: 0.75,
    maxzoom: 3,
    filter: ['==', ['get', 'class'], 'continent'],
    layout: {
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-line-height': 1.1,
      'text-max-width': 6,
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-size': [
        'interpolate',
        ['exponential', 0.5],
        ['zoom'],
        0,
        10,
        2.5,
        15,
      ],
      'text-transform': 'uppercase',
      'text-letter-spacing': 0.05,
    },
    paint: {
      'text-color': 'hsl(0, 0%, 40%)',
      'text-halo-color': 'hsl(0, 0%, 3%)',
      'text-halo-width': 1.5,
      'text-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.8,
        1.5,
        0.5,
        2.5,
        0,
      ],
    },
  },
].map((layer) => ({
  ...hideBuiltInLayer(layer),
  metadata: {
    'pn:built-in': true,
  },
})) as mapboxgl.AnyLayer[];

import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SyncIcon from '@mui/icons-material/Sync';
import { Alert, Box, Button, Link } from '@mui/material';
import { isEditable } from '@pn/core/permissions/editability';
import {
  projectsActions,
  useCurrentUserStorage,
  useProjectsStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { isSpecialProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import { formatDistanceToNow } from 'date-fns';
import { useTrackProjectUpdates } from 'src/ui/workspace/project-controls/useTrackProjectUpdates';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    gridArea: 'project-alert',
    padding: theme.spacing(1.5),
    paddingTop: 0,
  },
  alert: {
    padding: `0 ${theme.spacing(1.5)}`,
  },
}));

export const SelectedProjectAlert = () => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { isFetching } = useWorkspaceStorage();
  const { selectedProject } = useProjectsStorage();

  const { isInit, isSaved, nonTemporaryIds } = useTrackProjectUpdates();

  if (isFetching || isSpecialProject(selectedProject)) return null;

  if (isEditable(selectedProject, user)) {
    return (
      <Box className={classes.container}>
        <Alert
          className={classes.alert}
          severity={isInit ? 'info' : isSaved ? 'success' : 'warning'}
          sx={(theme) => ({
            transition: `all ${theme.transitions.duration.standard * 1e-3}s`,
            '& .MuiAlert-message': {
              paddingTop: '9px',
              paddingBottom: '7px',
            },
            '& .MuiAlert-action': {
              paddingTop: '3px',
              paddingBottom: '3px',
              marginRight: '-9px',
            },
          })}
          icon={
            isInit ? (
              <SyncIcon />
            ) : isSaved ? (
              <CheckIcon />
            ) : (
              <PriorityHighIcon />
            )
          }
          action={
            !isSaved && (
              <Button
                color="inherit"
                size="small"
                sx={{ height: '32px' }}
                onClick={() =>
                  projectsActions().update({
                    ...selectedProject,
                    workspaceItemIds: nonTemporaryIds,
                  })
                }
              >
                Save
              </Button>
            )
          }
        >
          {isInit
            ? `Last modified ${dateToDistance(selectedProject.updatedAt)}.`
            : isSaved
              ? 'All changes saved.'
              : 'Unsaved changes.'}
        </Alert>
      </Box>
    );
  } else {
    return (
      <Box className={classes.container}>
        <Alert
          className={classes.alert}
          severity="warning"
          icon={<LockIcon sx={{ fontSize: 22 }} />}
        >
          Read-only project. Duplicate to make your changes.{' '}
          <Link
            href="https://help.petroninja.com/projects/overview"
            target="_blank"
          >
            Learn more
          </Link>
        </Alert>
      </Box>
    );
  }
};

function dateToDistance(date: string): string {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
}

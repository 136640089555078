import InfoIcon from '@mui/icons-material/Info';
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import {
  ColumnsTreeColumn,
  ColumnsTreeItem,
} from '@pn/core/domain/data';
import { flatten } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { ColumnFolder } from './ColumnFolder';

const useStyles = makeStyles<{ leftOffset: number }>()(
  (theme, { leftOffset }) => ({
    listItemButton: {
      paddingLeft: theme.spacing(leftOffset + 1.5),
    },
    labelContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      width: 'calc(100% - 48px)',
      '& .MuiSvgIcon-root': {
        position: 'relative',
        top: -1,
      }
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })
);

type TreeItemProps = {
  disabled: boolean;
  isClickable: boolean;
  leftOffset: number;
  columnsTreeItem: ColumnsTreeItem;
  fields: string[];
  expandedFolders: string[];
  timeout: number;
  onExpandFolder: (folder: string) => () => void;
  onCheckFields: (
    updatedFields: string[]
  ) => (_e: unknown, checked: boolean) => void;
  onFieldClick: (field: string) => void;
};

export const ColumnTreeItem = (props: TreeItemProps) => {
  const {
    disabled,
    isClickable,
    leftOffset,
    columnsTreeItem,
    fields,
    expandedFolders,
    timeout,
    onExpandFolder,
    onCheckFields,
    onFieldClick,
  } = props;

  const { classes } = useStyles({ leftOffset });

  if ('folder' in columnsTreeItem) {
    const folderColumns = getAllColumns(columnsTreeItem);

    return (
      <ColumnFolder
        disabled={disabled}
        isExpanded={expandedFolders.includes(columnsTreeItem.folder)}
        leftOffset={leftOffset}
        folder={columnsTreeItem.folder}
        fields={fields}
        folderColumns={folderColumns as ColumnsTreeColumn[]}
        timeout={timeout}
        onExpand={onExpandFolder}
        onCheckFields={onCheckFields}
      >
        {columnsTreeItem.children.map((item) => (
          <ColumnTreeItem
            {...props}
            key={item.id}
            columnsTreeItem={item}
            leftOffset={leftOffset + 1}
          />
        ))}
      </ColumnFolder>
    );
  } else {
    return (
      <ListItem
        key={columnsTreeItem.field}
        disablePadding
        secondaryAction={
          <Checkbox
            disabled={disabled}
            edge="end"
            onChange={onCheckFields([columnsTreeItem.field])}
            checked={fields.includes(columnsTreeItem.field)}
          />
        }
      >
        {isClickable ? (
          <ListItemButton
            className={classes.listItemButton}
            onClick={() => onFieldClick(columnsTreeItem.field)}
          >
            <ListItemText
              primary={columnsTreeItem.label}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItemButton>
        ) : (
          <ListItem
            component="div"
            disablePadding
            disableGutters
            className={classes.listItemButton}
          >
            <ListItemText
              primary={
                <span className={classes.labelContainer}>
                  <span className={classes.label}>{columnsTreeItem.label}</span>
                  {columnsTreeItem.tooltip && (
                    <Tooltip title={columnsTreeItem.tooltip} placement="right">
                      <InfoIcon fontSize="small" color="action" />
                    </Tooltip>
                  )}
                </span>
              }
              disableTypography
            />
            {/* <ListItemText
              primary={columnsTreeItem.label}
              primaryTypographyProps={{
                noWrap: true,
              }}
            /> */}
          </ListItem>
        )}
      </ListItem>
    );
  }
};

function getAllColumns(columnsTreeItem: ColumnsTreeItem): ColumnsTreeColumn[] {
  if ('folder' in columnsTreeItem) {
    return flatten(columnsTreeItem.children.map((item) => getAllColumns(item)));
  } else {
    return [columnsTreeItem];
  }
}

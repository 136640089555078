import StraightIcon from '@mui/icons-material/Straight';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import { REFERENCE_ZOOM, useDrawing } from '@pn/services/drawing';
import { generateFeatureMeasurements } from '@pn/services/drawing/measurement';
import { ColorPicker } from '@pn/ui/color-picker';
import { NumericInputWithToggles } from '@pn/ui/inputs/NumericInputWithToggles';
import { OpacityInputWithToggles } from '@pn/ui/inputs/OpacityInputWithToggles';
import assert from 'assert';
import Color from 'color';
import { isNil, round, set } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  flip: {
    transform: 'scaleY(-1)',
  },
}));

export const DrawingPanelControls = () => {
  const { classes, theme } = useStyles();

  const {
    drawingMode,
    strokeColor,
    setStrokeColor,
    strokeWidth,
    setStrokeWidth,
    fillColor,
    setFillColor,
    textColor,
    setTextColor,
    fontSize,
    setFontSize,
    opacity,
    setOpacity,
    displayMeasurements,
    setDisplayMeasurements,
    lastRedraw,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const [featureLive, setFeatureLive] = React.useState<
    CanvasFeature | undefined
  >();
  React.useLayoutEffect(() => {
    const featuresSelectedArr = Object.values(drawingState.featuresSelected);
    if (featuresSelectedArr.length === 1) {
      setFeatureLive(featuresSelectedArr[0]);
    } else {
      setFeatureLive(undefined);
    }
  }, [drawingState, lastRedraw]);

  const controls = {
    strokeColor:
      featureLive && 'strokeColor' in featureLive
        ? featureLive.strokeColor
        : ['freehand', 'line', 'arrow', 'rectangle', 'circle'].includes(
              drawingMode
            )
          ? strokeColor
          : undefined,
    strokeWidth:
      featureLive && 'strokeWidth' in featureLive
        ? featureLive.strokeWidth * getZoom()
        : ['freehand', 'line', 'arrow', 'rectangle', 'circle'].includes(
              drawingMode
            )
          ? strokeWidth
          : undefined,
    fillColor:
      featureLive &&
      'fillColor' in featureLive &&
      (featureLive.type === 'poly' ? !featureLive.arrow : true)
        ? featureLive.fillColor
        : ['line', 'rectangle', 'circle'].includes(drawingMode)
          ? fillColor
          : undefined,
    textColor:
      featureLive && 'textColor' in featureLive
        ? featureLive.textColor
        : drawingMode === 'text'
          ? textColor
          : undefined,
    fontSize:
      featureLive && 'fontSize' in featureLive
        ? undefined // prohibit changing font size of existing features
        : drawingMode === 'text'
          ? fontSize
          : undefined,
    opacity:
      featureLive && 'opacity' in featureLive
        ? Math.round(featureLive.opacity * 100)
        : drawingMode !== 'select'
          ? Math.round(opacity * 100)
          : undefined,

    displayMeasurements:
      featureLive &&
      (featureLive.type === 'poly' || featureLive.type === 'circle')
        ? !isNil(featureLive.measurements)
        : ['line', 'arrow', 'rectangle', 'circle'].includes(drawingMode)
          ? displayMeasurements
          : undefined,
  };

  const opacityColor =
    controls.strokeColor ??
    controls.fillColor ??
    controls.textColor ??
    (theme.palette.mode === 'light' ? '#000' : '#fff');

  const opacityStroke =
    Color(opacityColor).contrast(Color(theme.palette.background.paper)) < 1.25
      ? theme.borderColor
      : undefined;

  const handleChangeStrokeColor = (color: string) => {
    setStrokeColor(color);
    drawingState.strokeColor = color;

    if (featureLive && 'strokeColor' in featureLive) {
      set(drawingState.featuresSelected[featureLive.id], 'strokeColor', color);
      set(drawingState.features[featureLive.id], 'strokeColor', color);

      redraw();

      historyManager.add(drawingState);
    }
  };

  const handleChangeStrokeWidth = (width: number) => {
    setStrokeWidth(width);
    drawingState.strokeWidth = width;

    if (featureLive && 'strokeWidth' in featureLive) {
      const zoomedWidth = width / getZoom();

      set(
        drawingState.featuresSelected[featureLive.id],
        'strokeWidth',
        zoomedWidth
      );
      set(drawingState.features[featureLive.id], 'strokeWidth', zoomedWidth);

      redraw();

      historyManager.add(drawingState);
    }
  };

  const handleChangeFillColor = (color: string) => {
    setFillColor(color);
    drawingState.fillColor = color;

    if (featureLive && 'fillColor' in featureLive) {
      set(drawingState.featuresSelected[featureLive.id], 'fillColor', color);
      set(drawingState.features[featureLive.id], 'fillColor', color);

      redraw();

      historyManager.add(drawingState);
    }
  };

  const handleChangeTextColor = (color: string) => {
    setTextColor(color);
    drawingState.textColor = color;

    if (featureLive && 'textColor' in featureLive) {
      set(drawingState.featuresSelected[featureLive.id], 'textColor', color);
      set(drawingState.features[featureLive.id], 'textColor', color);

      redraw();

      historyManager.add(drawingState);
    }
  };

  const handleChangeFontSize = (size: number) => {
    setFontSize(size);
    drawingState.fontSize = size;
  };

  const handleChangeOpacity = (rawOpacity: number) => {
    const opacity = round(rawOpacity / 100, 2);

    setOpacity(opacity);
    drawingState.opacity = opacity;

    if (featureLive) {
      drawingState.featuresSelected[featureLive.id].opacity = opacity;
      drawingState.features[featureLive.id].opacity = opacity;
      redraw();

      historyManager.add(drawingState);
    }
  };

  const handleChangeDisplayMeasurements = (display: boolean) => {
    setDisplayMeasurements(display);
    drawingState.displayMeasurements = display;

    if (
      featureLive &&
      (featureLive.type === 'poly' || featureLive.type === 'circle')
    ) {
      const measurements = display
        ? generateFeatureMeasurements(featureLive as any, { local: false }) // FIXME TS
        : undefined;

      set(
        drawingState.featuresSelected[featureLive.id],
        'measurements',
        measurements
      );
      set(drawingState.features[featureLive.id], 'measurements', measurements);
      redraw();

      historyManager.add(drawingState);
    }
  };

  const handleChangeOrder = (direction: 'top' | 'up' | 'down' | 'bottom') => {
    assert(featureLive, 'featureLive must be defined');
    const id = featureLive.id;

    switch (direction) {
      case 'top':
        drawingState.order = drawingState.order.filter((i) => i !== id);
        drawingState.order.push(id);
        break;
      case 'up': {
        const index = drawingState.order.indexOf(id);
        if (index === drawingState.order.length - 1) return;
        drawingState.order[index] = drawingState.order[index + 1];
        drawingState.order[index + 1] = id;
        break;
      }
      case 'down': {
        const index = drawingState.order.indexOf(id);
        if (index === 0) return;
        drawingState.order[index] = drawingState.order[index - 1];
        drawingState.order[index - 1] = id;
        break;
      }
      case 'bottom':
        drawingState.order = drawingState.order.filter((i) => i !== id);
        drawingState.order.unshift(id);
        break;
    }

    redraw();

    historyManager.add(drawingState);
  };

  return (
    <>
      {!isNil(controls.strokeColor) && (
        <Box>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Stroke
          </Typography>
          <ColorPicker
            value={controls.strokeColor}
            onChange={handleChangeStrokeColor}
          />
        </Box>
      )}

      {!isNil(controls.fillColor) && (
        <Box>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Fill
          </Typography>
          <ColorPicker
            showTransparent
            value={controls.fillColor}
            onChange={handleChangeFillColor}
          />
        </Box>
      )}

      {!isNil(controls.textColor) && (
        <Box>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Text
          </Typography>
          <ColorPicker
            value={controls.textColor}
            onChange={handleChangeTextColor}
          />
        </Box>
      )}

      {!isNil(controls.strokeWidth) && (
        <Box>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Stroke width
          </Typography>
          <Box display="flex" alignItems="center" gap="29px">
            <NumericInputWithToggles
              toggles={[
                { value: 2 },
                { value: 5 },
                { value: 10 },
                { value: 25 },
              ]}
              value={controls.strokeWidth}
              significantDigits={3}
              units="px"
              onChange={handleChangeStrokeWidth}
            />
            <Tooltip title="Relative to the current zoom level">
              <WarningIcon color="primary" />
            </Tooltip>
          </Box>
        </Box>
      )}

      {!isNil(controls.fontSize) && (
        <Box>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Font size
          </Typography>
          <NumericInputWithToggles
            toggles={[
              { label: 'S', value: 12 },
              { label: 'M', value: 20 },
              { label: 'L', value: 36 },
              { label: 'XL', value: 64 },
            ]}
            value={controls.fontSize}
            units="px"
            onChange={handleChangeFontSize}
          />
        </Box>
      )}

      {!isNil(controls.opacity) && (
        <Box>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Opacity
          </Typography>
          <OpacityInputWithToggles
            opacityColor={opacityColor}
            opacityStroke={opacityStroke}
            value={controls.opacity}
            onChange={handleChangeOpacity}
          />
        </Box>
      )}

      {!isNil(controls.displayMeasurements) && (
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={controls.displayMeasurements}
                onChange={(e) =>
                  handleChangeDisplayMeasurements(e.target.checked)
                }
              />
            }
            label="Display measurements"
            slotProps={{
              typography: {
                variant: 'body2',
                color: 'textSecondary',
              },
            }}
          />
        </Box>
      )}

      {!isNil(featureLive) && (
        <Box>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Actions
          </Typography>
          <Box display="flex" gap={1}>
            <IconButton onClick={() => handleChangeOrder('top')}>
              <VerticalAlignTopIcon />
            </IconButton>
            <IconButton onClick={() => handleChangeOrder('up')}>
              <StraightIcon />
            </IconButton>
            <IconButton onClick={() => handleChangeOrder('down')}>
              <StraightIcon className={classes.flip} />
            </IconButton>
            <IconButton onClick={() => handleChangeOrder('bottom')}>
              <VerticalAlignBottomIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

function getZoom(): number {
  return Math.pow(2, map._native.getZoom() - REFERENCE_ZOOM);
}

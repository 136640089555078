import React from 'react';
import { ProductionChartMode, isProductionChartMode } from '../types';

export function useChartMode(initMode?: unknown) {
  const [mode, setMode] = React.useState(getDefaultChartMode(initMode));

  const handleChangeMode = React.useCallback((newMode: ProductionChartMode) => {
    setMode(newMode);
  }, []);

  return { mode, handleChangeMode };
}

export function getDefaultChartMode(initMode?: unknown): ProductionChartMode {
  if (isProductionChartMode(initMode)) {
    return initMode;
  } else {
    return ProductionChartMode.Monthly;
  }
}

import type { IProjectsProvider } from '@pn/core/providers/project/ports';
import type { ApiAnnotation } from '@pn/services/api/annotation/types';
import type { ApiLayerItem } from '@pn/services/api/layer/types';
import type { ApiList } from '@pn/services/api/list/types';
import { pnApiClient } from '../pnApiClient';
import { apiProjectMapper } from './apiProjectMapper';
import type { ApiProject } from './types';
import { apiLayerItemMapper } from '@pn/services/api/layer/apiLayerMapper';
import { apiListMapper } from '@pn/services/api/list/apiListMapper';
import { apiAnnotationMapper } from '@pn/services/api/annotation/apiAnnotationMapper';

type CombinedResponse = ApiProject & {
  layers: ApiLayerItem[];
  lists: ApiList[];
  annotations: ApiAnnotation[];
};

const getProjectById = async (projectId: string) => {
  const apiProject = await pnApiClient.request<CombinedResponse>({
    url: `v2/projects/${projectId}`,
  });

  const project = apiProjectMapper.toDomainProject(apiProject);

  const layerWorkspaceItems = apiProject.layers.map((apiLayer) =>
    apiLayerItemMapper.toWorkspaceItem(apiLayer)
  );
  const listWorkspaceItems = apiProject.lists.map((apiList) =>
    apiListMapper.toWorkspaceItem(apiList)
  );
  const annotationWorkspaceItems = apiProject.annotations.map((apiAnnotation) =>
    apiAnnotationMapper.toWorkspaceItem(apiAnnotation)
  );

  const projectItems = [
    ...layerWorkspaceItems,
    ...listWorkspaceItems,
    ...annotationWorkspaceItems,
  ];

  return { project, projectItems };
};

const getCurrentUserProjects = async () => {
  const apiProjects = await pnApiClient.request<ApiProject[]>({
    url: 'v2/projects',
  });

  const projects = apiProjects.map(apiProjectMapper.toDomainProject);

  return projects;
};

export const apiProjectsProvider: IProjectsProvider = {
  getProjectById,
  getCurrentUserProjects,
};

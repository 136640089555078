import { Enterprise } from '@pn/core/domain/enterprise';
import { handleError } from '@pn/core/errors/handleError';
import { useAccess } from '@pn/core/permissions/access';
import {
  invitedUsersActions,
  useCurrentEnterpriseStorage,
  useEnterprisesStorage,
} from '@pn/core/storage';
import { apiUserProvider } from '@pn/services/api/user/apiUserProvider';
import { isNil } from 'lodash-es';
import React from 'react';

export async function getInvitedUsersByEnterpriseId(
  enterpriseId: Enterprise['id']
) {
  try {
    invitedUsersActions().request();

    const invitedUsers =
      await apiUserProvider.getInvitedUsersByEnterpriseId(enterpriseId);

    invitedUsersActions().receive(invitedUsers);
  } catch (error) {
    handleError({
      error,
      onError: invitedUsersActions().error,
      userFriendlyMessage: 'Failed to get invited users of the enterprise',
    });
  }
}

export function useAutoGetCurrentEnterpriseInvitedUsers() {
  const access = useAccess();
  const { enterprise } = useCurrentEnterpriseStorage();

  const shouldFetch = access('enterprise.dashboard').granted();
  const enterpriseId = enterprise?.id;

  React.useEffect(() => {
    if (!isNil(enterpriseId) && shouldFetch) {
      getInvitedUsersByEnterpriseId(enterpriseId);
    }
  }, [shouldFetch, enterpriseId]);
}

export function useAutoGetSelectedEnterpriseInvitedUsers() {
  const { selectedEnterprise } = useEnterprisesStorage();
  const enterpriseId = selectedEnterprise?.id;

  React.useEffect(() => {
    if (!isNil(enterpriseId)) {
      getInvitedUsersByEnterpriseId(enterpriseId);
    }
  }, [enterpriseId]);
}

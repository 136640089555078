import { handleError } from '@pn/core/errors/handleError';
import { useRouter } from '@pn/core/operations/router';
import {
  projectsActions,
  useProjectsStorage,
  workspaceActions,
} from '@pn/core/storage';
import { nullProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import { apiProjectsProvider } from '@pn/services/api/project/apiProjectsProvider';
import { isNil, last } from 'lodash-es';
import React from 'react';

export function useInitPNProjectFromUrl() {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const { projects } = useProjectsStorage();

  const hasRun = React.useRef(false);
  React.useEffect(() => {
    if (
      hasRun.current ||
      isNil(queryProjectId) ||
      queryProjectId.startsWith('stackdx|')
    )
      return;

    hasRun.current = true;

    projectsActions().select(nullProject.id);
    workspaceActions().setWorkspace(nullProject.workspaceItemIds);
    workspaceActions().select(undefined);

    (async () => {
      try {
        const { project, projectItems } =
          await apiProjectsProvider.getProjectById(queryProjectId);

        projectsActions().add(project);
        projectsActions().select(project.id);

        workspaceActions().add(projectItems);
        workspaceActions().setWorkspace(project.workspaceItemIds);
        workspaceActions().select(last(project.workspaceItemIds));
      } catch (error) {
        handleError({
          error,
          userFriendlyMessage: 'Project does not exist or is not accessible',
        });
      }
    })();
  }, [queryProjectId, projects]);
}

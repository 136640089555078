import {
  type Active,
  DndContext,
  type DragEndEvent,
  type DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  type Over,
  TouchSensor,
  useDndContext,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { moveWorkspaceItem } from '@pn/core/operations/workspace';
import { useWorkspaceStorage, workspaceActions } from '@pn/core/storage';
import { findOrThrow } from '@pn/core/utils/logic';
import { restrictToVerticalContainer } from '@pn/services/utils/dnd';
import { isNil } from 'lodash-es';
import React from 'react';
import { WORKSPACE_DRAWER_WIDTH } from 'src/ui/Main';
import { ProjectControls } from 'src/ui/workspace/project-controls/ProjectControls';
import { SelectedProjectAlert } from 'src/ui/workspace/project-controls/SelectedProjectAlert';
import { WorkspaceAccessAlert } from 'src/ui/workspace/WorkspaceAccessAlert';
import { WorkspaceContent } from 'src/ui/workspace/WorkspaceContent';
import { WorkspaceLibraryControls } from 'src/ui/workspace/WorkspaceLibraryControls';
import { WorkspaceQuickBrowser } from 'src/ui/workspace/WorkspaceQuickBrowser';
import {
  TRASH_BIN_ID,
  WorkspaceTrashBin,
} from 'src/ui/workspace/WorkspaceTrashBin';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'grid',
    gridTemplateAreas: `
      "access-alert"
      "workspace-controls"
      "project-alert"
      "workspace-content"
      "quick-browser"
      "button-container"
    `,
    gridTemplateRows:
      'min-content min-content min-content 1fr auto min-content',
    height: '100%',
    width: WORKSPACE_DRAWER_WIDTH, // CSS fix for 1px width difference when toggling the Library on/off
  },
}));

export const WorkspaceContainer = () => {
  const { classes } = useStyles();

  const { workspaceItems } = useWorkspaceStorage();

  const [drawingIds, setDrawingIds] = React.useState<string[]>([]);
  const [otherIds, setOtherIds] = React.useState<string[]>([]);
  React.useLayoutEffect(() => {
    const drawingItemIds: string[] = [];
    const otherItemIds: string[] = [];

    workspaceItems.forEach((item) => {
      if (item.itemType === 'drawing') {
        drawingItemIds.push(item.id);
      } else {
        otherItemIds.push(item.id);
      }
    });

    setOtherIds(otherItemIds.slice().reverse());
    setDrawingIds(drawingItemIds.slice().reverse());
  }, [workspaceItems]);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const activeContainerId = React.useRef<string | undefined>(undefined);

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    activeContainerId.current = active.data.current?.sortable?.containerId;
  }

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;

    event.activatorEvent.stopPropagation(); // prevents SwipeableDrawer from closing during the drag

    switch (activeContainerId.current) {
      case 'drawings':
        return processDragOver(over, active, drawingIds, setDrawingIds);
      case 'others':
        return processDragOver(over, active, otherIds, setOtherIds);
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    activeContainerId.current = undefined;

    const activeItem = findOrThrow(
      workspaceItems,
      (item) => item.id === active.id
    );

    const beforeItem = findNextVisibleItem({
      otherIds,
      index: otherIds.indexOf(activeItem.id),
      workspaceItems,
    });

    if (over?.id === TRASH_BIN_ID) {
      if (activeItem.isTemporary) {
        workspaceActions().remove(activeItem);
      } else {
        workspaceActions().removeFromWorkspace(activeItem.id);
      }

      return;
    }

    workspaceActions().setWorkspace(
      [...drawingIds.slice().reverse(), ...otherIds.slice().reverse()],
      true
    );

    moveWorkspaceItem(activeItem, beforeItem);
  }

  return (
    <Box className={classes.container}>
      <WorkspaceAccessAlert />
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToVerticalContainer]}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragEnd}
      >
        <WorkspaceControls />
        <SelectedProjectAlert />
        <WorkspaceContent drawingIds={drawingIds} otherIds={otherIds} />
      </DndContext>
      <WorkspaceQuickBrowser />
      <WorkspaceLibraryControls />
    </Box>
  );
};

function WorkspaceControls() {
  const { active } = useDndContext();

  return isNil(active) ? <ProjectControls /> : <WorkspaceTrashBin />;
}

function processDragOver(
  over: Over | null,
  active: Active,
  ids: string[],
  setIds: (ids: string[]) => void
): void {
  if (!over) return;

  if (over.id === TRASH_BIN_ID) {
    setIds(ids.filter((id) => id !== active.id));
    return;
  }

  if (active.id !== over.id) {
    if (!ids.includes(active.id as string)) {
      setIds([active.id as string, ...ids]);
      return;
    }

    const oldIndex = ids.indexOf(active.id as string);
    const newIndex = ids.indexOf(over.id as string);

    if (newIndex === -1) return; // can be -1 when dragging a an item over a drawing

    const newIds = arrayMove(ids, oldIndex, newIndex);

    setIds(newIds);
  }
}

function findNextVisibleItem(params: {
  otherIds: string[];
  index: number;
  workspaceItems: WorkspaceItem[];
}): WorkspaceItem | undefined {
  const { otherIds, index, workspaceItems } = params;

  const nextId = otherIds[index - 1];
  if (isNil(nextId)) return undefined;

  const nextItem = workspaceItems.find((item) => item.id === nextId);
  if (isNil(nextItem)) return undefined;

  if (nextItem.isVisible) return nextItem;

  return findNextVisibleItem({ otherIds, index: index - 1, workspaceItems });
}

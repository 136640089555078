import { MapMode } from '@pn/core/domain/map';
import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  isInitialized: boolean;
  mapMode: MapMode;
};

const initialState: InitialState = {
  isInitialized: false,
  mapMode: MapMode.Standard,
};

export const createMapSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      initialize: (state) => {
        state.isInitialized = true;
      },
      updateMode: (state, { payload }) => {
        state.mapMode = payload;
      },
      reset: (state) => {
        state.isInitialized = false;
        state.mapMode = MapMode.Standard;
      },
    },
  });

export const mapSlice = createMapSlice('map');
/* We can create more slices in future as needed */

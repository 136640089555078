import { Box, Button } from '@mui/material';
import {
  GridFooterContainer,
  GridFooterContainerProps,
  GridSelectedRowCount,
  selectedGridRowsCountSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  button: {
    textTransform: 'none',
  },
}));

export const CustomFooter = React.forwardRef<
  HTMLDivElement,
  GridFooterContainerProps
>(function GridFooter(props, ref) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const { classes } = useStyles();

  const selectedRowCount = useGridSelector(
    apiRef,
    selectedGridRowsCountSelector
  );

  const selectedRowCountElement =
    !rootProps.hideFooterSelectedRowCount && selectedRowCount > 0 ? (
      <Box display="inline-flex">
        <GridSelectedRowCount selectedRowCount={selectedRowCount} />
        <Button
          variant="text"
          className={classes.button}
          onClick={() => apiRef.current.setRowSelectionModel([])}
        >
          Unselect all
        </Button>
      </Box>
    ) : (
      <div />
    );

  const paginationElement = rootProps.pagination &&
    !rootProps.hideFooterPagination &&
    rootProps.slots.pagination && (
      <rootProps.slots.pagination {...rootProps.slotProps?.pagination} />
    );

  return (
    <GridFooterContainer ref={ref} {...props}>
      {selectedRowCountElement}
      {paginationElement}
    </GridFooterContainer>
  );
});

import type {
  ProductionItem,
  ProductionMode,
} from '@pn/core/domain/production';
import { groupedProductionItemsMapper } from '@pn/core/mappers/production/groupedProductionItemsMapper';
import type { IDownloadService } from '@pn/core/services/exports/ports';
import { downloadAsCsv } from '../csvExportService';
import { csvGroupedProductionItemMapper } from './csvGroupedProductionItemMapper';

export function downloadProductionItems(params: {
  productionItems: ProductionItem[];
  mode: ProductionMode;
}): IDownloadService {
  const { productionItems, mode } = params;

  const groupedProductionItems =
    groupedProductionItemsMapper.toGroupedProductionItems(
      productionItems,
      mode
    );

  return {
    csv: ({ fileName }) => {
      const csvGroupedProductionItems = groupedProductionItems.map((item) =>
        csvGroupedProductionItemMapper.toTargetGroupedProductionItem(item)
      );

      const allKeys = csvGroupedProductionItems.reduce<Set<string>>(
        (acc, item) => {
          Object.keys(item).forEach((key) => acc.add(key));
          return acc;
        },
        new Set()
      );

      downloadAsCsv(csvGroupedProductionItems, {
        fileName,
        fields: Array.from(allKeys)
          .filter((key) => key !== 'Hours')
          .concat('Hours'), // append hours to the end
      });
    },
    json: () => {
      throw new Error('Not implemented');
    },
    geojson: () => {
      throw new Error('Not implemented');
    },
  };
}

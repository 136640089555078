import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogContentText: {
    paddingBottom: theme.spacing(2),
  },
}));

// NOTE this dialog cannot be closed by design
export function NoInternetConnectionDialog() {
  const { classes } = useStyles();

  const [isOnline, setIsOnline] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));
  }, []);

  return (
    <Dialog fullWidth open={!isOnline}>
      <DialogTitle>Internet connection required!</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          Internet connection is required to use the app. Reconnecting...
        </DialogContentText>
      </DialogContent>

      <LinearProgress />
    </Dialog>
  );
}

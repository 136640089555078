import type { CanvasBBox, Transformation } from '@pn/core/domain/drawing';
import { drawFeature } from '@pn/services/drawing';

export const SELECT_STROKE_WIDTH = 2;
export const RESIZE_HANDLE_SIZE = 10;

/**
 * The style of box selection is identical to .mapbox-boxdraw
 */
export function drawBoxSelection(params: {
  ctx: CanvasRenderingContext2D;
  bbox: CanvasBBox;
  transformation: Transformation;
}): void {
  const { ctx, bbox, transformation } = params;

  ctx.setLineDash([6 / transformation.scale, 4 / transformation.scale]);

  drawFeature(ctx, {
    type: 'poly',
    subType: 'rectangle_selection',
    id: '',
    coordinates: [
      { x: bbox.x, y: bbox.y },
      { x: bbox.x + bbox.width, y: bbox.y },
      {
        x: bbox.x + bbox.width,
        y: bbox.y + bbox.height,
      },
      { x: bbox.x, y: bbox.y + bbox.height },
      { x: bbox.x, y: bbox.y },
    ],
    strokeColor: 'rgb(33, 150, 243)',
    strokeWidth: bbox.strokeWidth,
    fillColor: 'rgba(33, 150, 243, 0.15)',
    opacity: 1,
    itemId: '',
    isVisible: true,
  });

  ctx.setLineDash([]);
}

export function drawResizeHandle(params: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  transformation: Transformation;
}): void {
  const { ctx, x, y, transformation } = params;

  const sqSize = RESIZE_HANDLE_SIZE / transformation.scale;
  const radius = 0 / transformation.scale; // rounding

  ctx.fillStyle = '#fff';
  ctx.strokeStyle = '#000';

  /* Draw rounded rectangle */
  ctx.beginPath();
  ctx.moveTo(x - sqSize / 2 + radius, y - sqSize / 2);
  ctx.lineTo(x + sqSize / 2 - radius, y - sqSize / 2);
  ctx.quadraticCurveTo(
    x + sqSize / 2,
    y - sqSize / 2,
    x + sqSize / 2,
    y - sqSize / 2 + radius
  );
  ctx.lineTo(x + sqSize / 2, y + sqSize / 2 - radius);
  ctx.quadraticCurveTo(
    x + sqSize / 2,
    y + sqSize / 2,
    x + sqSize / 2 - radius,
    y + sqSize / 2
  );
  ctx.lineTo(x - sqSize / 2 + radius, y + sqSize / 2);
  ctx.quadraticCurveTo(
    x - sqSize / 2,
    y + sqSize / 2,
    x - sqSize / 2,
    y + sqSize / 2 - radius
  );
  ctx.lineTo(x - sqSize / 2, y - sqSize / 2 + radius);
  ctx.quadraticCurveTo(
    x - sqSize / 2,
    y - sqSize / 2,
    x - sqSize / 2 + radius,
    y - sqSize / 2
  );
  ctx.closePath();

  ctx.fill();
  ctx.stroke();
}

export const VERTEX_HANDLE_RADIUS = 5;

export function drawVertexHandle(params: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  transformation: Transformation;
}): void {
  const { ctx, x, y, transformation } = params;

  ctx.beginPath();
  ctx.arc(x, y, VERTEX_HANDLE_RADIUS / transformation.scale, 0, 2 * Math.PI);
  ctx.fillStyle = 'white';
  ctx.fill();
  ctx.strokeStyle = 'black';
  ctx.stroke();
}

import { dependencies } from '@pn/core/dependencies';
import { isExtraDrawingMode, useDrawing } from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';

/**
 * Enables panning when spacebar is held down.
 */
export function useCustomPanning() {
  const { drawingState, drawingMode } = useDrawing();
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();

  const isInExtraMode = isExtraDrawingMode(drawingMode);

  React.useEffect(() => {
    if (!isDrawingPanelOpen && !isInExtraMode) return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    let dragPanInitState = false;

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key !== ' ' || drawingState.isSpacebarDown) return;

      drawingState.isSpacebarDown = true;

      dragPanInitState = mapboxMap.dragPan.isEnabled();
      if (!dragPanInitState) mapboxMap.dragPan.enable();

      mapboxMap.getCanvas().style.cursor = '';
    };

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key !== ' ') return;

      drawingState.isSpacebarDown = false;
      if (!dragPanInitState) mapboxMap.dragPan.disable();
    };

    const onMouseDown = (e: mapboxgl.MapMouseEvent) => {
      if (!drawingState.isSpacebarDown || e.originalEvent.button !== 0) return;

      drawingState.isCustomPanning = true;
    };

    const onMouseUp = (e: MouseEvent) => {
      if (e.button !== 0) return;

      drawingState.isCustomPanning = false;
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    mapboxMap.on('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);

      mapboxMap.off('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [
    isDrawingPanelOpen,
    isInExtraMode,
    // the following never change:
    drawingState,
  ]);
}

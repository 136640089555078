import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { unstable_useId as useId } from '@mui/utils';
import {
  useGridRootProps,
  type GridFilterInputValueProps,
  type GridFilterItem,
} from '@mui/x-data-grid-pro';
import { useCustomFilterInputStyles } from '@pn/ui/data-table/components/CustomGridFilterInputValue';
import React from 'react';

export type GridFilterInputDateProps = GridFilterInputValueProps &
  TextFieldProps & {
    type?: 'date' | 'datetime-local';
    clearButton?: React.ReactNode | null;
    isFilterActive?: boolean;
  };

function convertFilterItemValueToInputValue(
  itemValue: GridFilterItem['value'],
  inputType: GridFilterInputDateProps['type']
) {
  if (itemValue == null) {
    return '';
  }
  const dateCopy = new Date(itemValue);
  if (Number.isNaN(dateCopy.getTime())) {
    return '';
  }
  if (inputType === 'date') {
    return dateCopy.toISOString().substring(0, 10);
  }
  if (inputType === 'datetime-local') {
    dateCopy.setMinutes(dateCopy.getMinutes() - dateCopy.getTimezoneOffset());
    return dateCopy.toISOString().substring(0, 19);
  }
  return dateCopy.toISOString().substring(0, 10);
}

export function CustomGridFilterInputDate(props: GridFilterInputDateProps) {
  const {
    item,
    applyValue,
    type,
    apiRef,
    focusElementRef,
    InputProps,
    clearButton,
    tabIndex,
    disabled,
    isFilterActive: _isFilterActive, // prevent spreading into the JSX
    ...other
  } = props;
  const { classes } = useCustomFilterInputStyles();
  const [filterValueState, setFilterValueState] = React.useState(() =>
    convertFilterItemValueToInputValue(item.value, type)
  );
  const [isApplied, setIsApplied] = React.useState(true);
  const id = useId();
  const rootProps = useGridRootProps();

  const onFilterChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setFilterValueState(value);
      setIsApplied(value === '');
    },
    []
  );

  const onSubmit = React.useCallback(() => {
    const date = new Date(filterValueState);
    applyValue({
      ...item,
      value: Number.isNaN(date.getTime()) ? undefined : date,
    });
    setIsApplied(true);
  }, [applyValue, filterValueState, item]);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') onSubmit();
    },
    [onSubmit]
  );

  React.useEffect(() => {
    const value = convertFilterItemValueToInputValue(item.value, type);
    setFilterValueState(value);
  }, [item.value, type]);

  return (
    <rootProps.slots.baseTextField
      fullWidth
      id={id}
      label={apiRef.current.getLocaleText('filterPanelInputLabel')}
      placeholder={apiRef.current.getLocaleText('filterPanelInputPlaceholder')}
      value={filterValueState}
      onChange={onFilterChange}
      onKeyDown={onKeyDown}
      variant="standard"
      type={type || 'text'}
      inputRef={focusElementRef}
      slotProps={{
        input: {
          endAdornment: isApplied ? (
            clearButton
          ) : (
            <IconButton
              title="Apply filter"
              className={classes.tinyIconButton}
              onClick={() => onSubmit()}
            >
              <CheckIcon />
            </IconButton>
          ),
          disabled,
          ...InputProps,
          inputProps: {
            max: type === 'datetime-local' ? '9999-12-31T23:59' : '9999-12-31',
            tabIndex,
            ...InputProps?.inputProps,
          },
        },
        inputLabel: { shrink: true },
      }}
      {...other}
      {...rootProps.slotProps?.baseTextField}
    />
  );
}

import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { workspaceActions } from '@pn/core/storage';
import { findOrThrow } from '@pn/core/utils/logic';
import { getStackProjectDetails } from '@pn/services/api/stackdx/apiStackProjectDetailsProvider';

export async function loadItem(item: WorkspaceItem): Promise<WorkspaceItem> {
  if (item.isLoaded) return item;

  switch (item.origin) {
    case 'stackdx': {
      const stackProjectDetails = await getStackProjectDetails(
        item.id.split('|')[0]
      );

      const ids = findOrThrow(
        stackProjectDetails.lists,
        (l) => l.sourceLayerId === item.sourceItem!.id
      ).ids;
      if (ids.length === 0) {
        console.warn('Stack item with no ids', item, stackProjectDetails);
      }

      workspaceActions().updateQueryProperty(item.id, {
        requestedIds: ids,
      });
      workspaceActions().markAsProcessed(item.id);
      workspaceActions().markAsLoaded(item.id);

      return {
        ...item,
        query: {
          ...item.query,
          requestedIds: ids,
        },
      };
    }
    default:
      return item;
  }
}

import { isSIUnit } from '@pn/core/domain/units';
import type { IGroupedProductionItemMapper } from '@pn/core/mappers/production';
import { startCase } from 'lodash-es';
import type { CsvGroupedProductionItem } from './types';

export const csvGroupedProductionItemMapper: IGroupedProductionItemMapper<CsvGroupedProductionItem> =
  {
    toTargetGroupedProductionItem: (groupedProductionItem) => {
      const csvGroupedProductionItem: CsvGroupedProductionItem = {
        Date: groupedProductionItem.date,
        Hours: groupedProductionItem.hours,
      };

      Object.entries(groupedProductionItem).forEach(([key, value]) => {
        if (isSIUnit(value)) {
          const newKey = `${startCase(groupedProductionItem.mode)} ${key} (${
            value.symbol
          })`;
          csvGroupedProductionItem[newKey] = value.value;
        }
      });

      return csvGroupedProductionItem;
    },
  };

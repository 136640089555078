import type { ITableTemplateMapper } from '@pn/core/mappers/table';
import type { ApiColumnsTemplate, ApiColumnsTemplatePayload } from './types';

export const apiTableTemplateMapper: ITableTemplateMapper<
  ApiColumnsTemplate,
  ApiColumnsTemplatePayload
> = {
  toDomainTemplate: (apiTemplate) => {
    return {
      id: apiTemplate.id,
      createdAt: apiTemplate.created_at,
      name: apiTemplate.name,
      sourceLayerId: apiTemplate.source_layer_id,
      fields: apiTemplate.fields,
      isGlobal: apiTemplate.global,
    };
  },
  toTargetTemplatePayload: (template) => {
    return {
      name: template.name,
      source_layer_id: template.sourceLayerId,
      fields: template.fields,
    };
  },
};

import {
  projectsActions,
  useProjectsStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { nullProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import React from 'react';

export function useTrackProjectLoading(projectId: string) {
  const { isFetching, workspaceItems } = useWorkspaceStorage();
  const { isProjectLoaded } = useProjectsStorage();

  const { totalNumberOfItems, numberOfLoadedItems } = React.useMemo(() => {
    let totalNumberOfItems = 0;
    let numberOfLoadedItems = 0;

    workspaceItems.forEach((item) => {
      if (item.module) return;

      totalNumberOfItems += 1;
      if (item.isRendered) numberOfLoadedItems += 1;
    });

    return { totalNumberOfItems, numberOfLoadedItems };
  }, [workspaceItems]);

  React.useEffect(() => {
    if (isFetching || isProjectLoaded || projectId === nullProject.id) return;

    if (numberOfLoadedItems === totalNumberOfItems) {
      projectsActions().updateProjectIdLoaded(projectId);
    }
  }, [
    isFetching,
    isProjectLoaded,
    projectId,
    totalNumberOfItems,
    numberOfLoadedItems,
  ]);

  return { numberOfLoadedItems, totalNumberOfItems };
}

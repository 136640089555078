import { dependencies } from '@pn/core/dependencies';
import { useMapSelectionProcessor } from '@pn/core/operations/mapInteractions';
import { draw } from '@pn/services/map/mapbox-gl-draw/draw';
import { isLineStringGeojsonFeature } from '@pn/services/map/mapbox-gl-draw/draw-modes/AnnotationLineDrawMode';
import { isPolygonGeojsonFeature } from '@pn/services/map/mapbox-gl-draw/draw-modes/AnnotationPolygonDrawMode';
import { toMapboxBbox } from '@pn/services/map/mapbox/mapboxUtils';
import { mapboxMapFeatureMapper } from '@pn/services/map/mapbox/mappers/mapboxMapFeatureMapper';
import * as turf from '@turf/turf';
import type {
  Feature,
  GeoJsonProperties,
  LineString,
  MultiLineString,
  MultiPoint,
  Point,
  Polygon,
} from 'geojson';
import { isEmpty, isNil } from 'lodash-es';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import React from 'react';

export function useConvertToSelection() {
  const {
    map,
    notificationService: { notify },
  } = dependencies;
  const mapboxMap = map._native;

  const { processMixedMapSelection } = useMapSelectionProcessor();

  const convertToSelection = React.useCallback(() => {
    const selectedFeatures = draw.getSelected().features;

    const areaDrawFeatures: GeoJSON.Feature[] = draw.getAll().features.filter(
      (f) =>
        (f.geometry.type === 'LineString' || f.geometry.type === 'Polygon') &&
        (isEmpty(selectedFeatures)
          ? true // use all drawn lines & polygons
          : selectedFeatures.map(({ id }) => id).includes(f.id)) // use selected lines & polygons only
    );

    if (isEmpty(areaDrawFeatures)) {
      return notify('No selectable features available');
    }

    const intersectingFeatures: mapboxgl.MapboxGeoJSONFeature[] = [];

    areaDrawFeatures.forEach((areaDrawFeature) => {
      const polygonBoundingBox = turf.bbox(areaDrawFeature);

      const southWest: [number, number] = [
        polygonBoundingBox[0],
        polygonBoundingBox[1],
      ];
      const northEast: [number, number] = [
        polygonBoundingBox[2],
        polygonBoundingBox[3],
      ];

      const northEastPointPixel = mapboxMap.project(northEast);
      const southWestPointPixel = mapboxMap.project(southWest);

      const pixelBoundingBox: [mapboxgl.Point, mapboxgl.Point] = [
        southWestPointPixel,
        northEastPointPixel,
      ];

      const matchedMapFeatures = mapboxMap.queryRenderedFeatures(
        toMapboxBbox(pixelBoundingBox)
      );
      const matchedDataFeatures = matchedMapFeatures.filter(
        (f: any) => !isNil(f.properties?.internal_id)
      );

      let polygonDrawFeature: GeoJSON.Feature<GeoJSON.Polygon>;
      try {
        if (isLineStringGeojsonFeature(areaDrawFeature)) {
          polygonDrawFeature = turf.lineToPolygon(
            areaDrawFeature
          ) as GeoJSON.Feature<GeoJSON.Polygon>;
        } else if (isPolygonGeojsonFeature(areaDrawFeature)) {
          polygonDrawFeature = areaDrawFeature;
        }
      } catch (error) {
        // console.error(error);
        return;
      }

      intersectingFeatures.push(
        ...matchedDataFeatures.filter((f: any) => {
          switch (f.geometry.type) {
            case 'Point':
              return isPointInPolygon(
                f.geometry as Point | MultiPoint,
                polygonDrawFeature
              );
            case 'LineString':
              return isLineWithinOrCrossesPolygon(
                f.geometry as LineString,
                polygonDrawFeature
              );
            case 'MultiLineString':
              return isMultiLineWithinOrCrossesPolygon(
                f.geometry as MultiLineString,
                polygonDrawFeature
              );
            case 'Polygon':
              return isPolygonNotDisjoint(f, polygonDrawFeature);
            case 'MultiPolygon':
              return isMultiPolygonNotDisjoint(f, polygonDrawFeature);
            default:
              return false;
          }
        })
      );
    });

    if (isEmpty(intersectingFeatures)) {
      return notify('No selectable features available');
    }

    processMixedMapSelection({
      features: intersectingFeatures.map((f) =>
        mapboxMapFeatureMapper.toDomainMapFeature(f)
      ),
      append: false,
    });
  }, [mapboxMap, notify, processMixedMapSelection]);

  return { convertToSelection };
}

function isPointInPolygon(
  feature: Point | MultiPoint,
  polygon: Feature<Polygon, GeoJsonProperties>
): boolean {
  if (feature.type === 'Point') {
    return turf.booleanPointInPolygon(feature.coordinates, polygon.geometry);
  } else {
    return feature.coordinates.some((point) =>
      turf.booleanPointInPolygon(point, polygon.geometry)
    );
  }
}

function isLineWithinOrCrossesPolygon(
  feature: LineString,
  polygon: Feature<Polygon, GeoJsonProperties>
): boolean {
  return (
    turf.booleanWithin(turf.lineString(feature.coordinates), polygon) ||
    turf.booleanCrosses(turf.lineString(feature.coordinates), polygon)
  );
}

function isMultiLineWithinOrCrossesPolygon(
  feature: MultiLineString,
  polygon: Feature<Polygon, GeoJsonProperties>
): boolean {
  return feature.coordinates.some(
    (line) =>
      turf.booleanWithin(turf.lineString(line), polygon) ||
      turf.booleanCrosses(turf.lineString(line), polygon)
  );
}

function isPolygonNotDisjoint(
  feature: MapboxGeoJSONFeature,
  polygon: Feature<Polygon, GeoJsonProperties>
): boolean {
  return !turf.booleanDisjoint(feature, polygon);
}

function isMultiPolygonNotDisjoint(
  feature: MapboxGeoJSONFeature,
  polygon: Feature<Polygon, GeoJsonProperties>
): boolean {
  return !turf.booleanDisjoint(
    feature,
    turf.multiPolygon([polygon.geometry.coordinates])
  );
}

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CloseIcon from '@mui/icons-material/Close';
import { Box, ButtonBase, IconButton, Paper } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import {
  Layer,
  LayerType,
  formatLayerType,
  isInteractableLayer,
} from '@pn/core/domain/layer';
import { MapMode } from '@pn/core/domain/map';
import {
  WorkspaceItem,
  getColorComponents,
} from '@pn/core/domain/workspace';
import { createBorderLayer } from '@pn/core/operations/workspace/crud/createBorderLayer';
import { createLabelLayer } from '@pn/core/operations/workspace/crud/createLabelLayer';
import { workspaceActions } from '@pn/core/storage';
import { DEFAULT_COLOR } from '@pn/services/styles';
import { Code } from '@pn/ui/custom-components/Code';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isNil } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { CombinedColorPicker } from 'src/ui/workspace/styling/CombinedColorPicker';
import { StyleControls } from 'src/ui/workspace/styling/StyleControls';
import { makeStyles } from 'tss-react/mui';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
  paddingRight: theme.spacing(1),
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
    fontSize: '0.9rem',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    // paddingRight: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const useStyles = makeStyles()((theme) => ({
  paperTitle: {
    padding: theme.spacing(2),
    borderBottom: 'none',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
  },
  paperContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  layerType: {
    position: 'relative',
    top: -1,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
  closeButton: {
    marginLeft: 'auto',
  },
  buttonsContainer: {
    display: 'flex',
    height: 48,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
  },
  addButton: {
    width: '100%',
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.main,
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  },
}));

type Props = {
  item: WorkspaceItem;
  disabled: boolean;
  mapMode: MapMode;
};

export const StyleControlsAccordion = ({ item, disabled, mapMode }: Props) => {
  const { smScreen } = useScreenSize();
  const { classes } = useStyles();

  const [expanded, setExpanded] = React.useState<string | undefined>('General');

  const primaryLayer = item.map.layers[0];
  const canAddLayers = !smScreen && isInteractableLayer(primaryLayer);

  const handleExpand =
    (value: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? value : undefined);
    };

  const handleAddLabelLayer = () => {
    const labelLayer = createLabelLayer({
      itemId: item.id,
      sourceLayerId: item.dataType,
      sourceLayerType: primaryLayer.type,
      source: primaryLayer.source,
      sourceField: primaryLayer.sourceField,
      field:
        getColorComponents(primaryLayer.style.color).field ?? 'internal_id',
      color: mapMode === MapMode.Light ? DEFAULT_COLOR : '#fff',
      textIndex: item.map.layers.filter(({ type }) => type === LayerType.Text)
        .length,
    });

    workspaceActions().addLayer(item.id, labelLayer);

    setExpanded(labelLayer.id);
  };

  const handleAddBorderLayer = () => {
    const borderLayer = createBorderLayer({
      itemId: item.id,
      sourceLayerId: item.dataType,
      source: primaryLayer.source,
      sourceField: primaryLayer.sourceField,
      color: DEFAULT_COLOR,
      index: item.map.layers.filter(({ type }) => type === LayerType.Line)
        .length,
    });

    workspaceActions().addLayer(item.id, borderLayer);

    setExpanded(borderLayer.id);
  };

  const handleRemoveLabelLayer = (layer: Layer) => {
    workspaceActions().removeLayer(item.id, layer.id);
    if (map.hasLayer(layer.id)) map.removeDataLayer(layer);
  };

  return (
    <Box>
      <Accordion
        expanded={expanded === 'General'}
        onChange={handleExpand('General')}
      >
        <AccordionSummary>
          <Typography component="div" className={classes.titleContainer}>
            <span className={classes.title}>All</span>
            <span className={classes.layerType}>
              (applies to all components)
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CombinedColorPicker
            item={item}
            layer={primaryLayer}
            disabled={disabled}
            updateAll
          />
        </AccordionDetails>
      </Accordion>

      {item.map.layers.map((layer, index) => (
        <Accordion
          key={layer.id}
          expanded={expanded === layer.id}
          onChange={handleExpand(layer.id)}
        >
          <AccordionSummary
            aria-controls={`${layer.id}-content`}
            id={`${layer.id}-header`}
          >
            <Typography component="div" className={classes.titleContainer}>
              <span className={classes.title}>
                {layer.name ?? `Layer ${index + 1}`}
              </span>
              <Code className={classes.layerType}>
                {formatLayerType(layer.type, { plural: true })}
              </Code>
              {canRemoveLayer(layer) ? (
                <IconButton
                  disabled={disabled}
                  className={classes.closeButton}
                  onClick={() => handleRemoveLabelLayer(layer)}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <Box height={40} />
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <StyleControls disabled={disabled} item={item} layer={layer} />
          </AccordionDetails>
        </Accordion>
      ))}

      {canAddLayers && (
        <Paper variant="outlined" square className={classes.buttonsContainer}>
          <ButtonBase
            className={classes.addButton}
            disabled={disabled}
            onClick={handleAddLabelLayer}
          >
            <AddIcon />
            <Box ml={1} />
            <Typography>Add labels</Typography>
          </ButtonBase>
          {primaryLayer.type === LayerType.Polygon && (
            <ButtonBase
              className={classes.addButton}
              disabled={disabled}
              onClick={handleAddBorderLayer}
            >
              <AddIcon />
              <Box ml={1} />
              <Typography>Add border</Typography>
            </ButtonBase>
          )}
        </Paper>
      )}
    </Box>
  );
};

const removablePrefixes = ['Labels ', 'Border '];
function canRemoveLayer(layer: Layer): boolean {
  const layerName = layer.name;
  return (
    !isNil(layerName) &&
    removablePrefixes.some((prefix) => layerName.startsWith(prefix))
  );
}

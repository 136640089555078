import { isNil } from 'lodash-es';
import React from 'react';
import { ChartScale } from '../types';

export function useChartScale(initScale?: unknown) {
  const [scale, setScale] = React.useState(getDefaultChartScale(initScale));

  const handleChangeScale = React.useCallback(
    (newScale: ChartScale | null) => {
      if (!isNil(newScale)) setScale(newScale);
    },
    [setScale]
  );

  return { scale, handleChangeScale };
}

export function getDefaultChartScale(initScale?: unknown): ChartScale {
  if (initScale === ChartScale.Linear) return ChartScale.Linear;
  if (initScale === ChartScale.Logarithmic) return ChartScale.Logarithmic;
  return ChartScale.Logarithmic;
}

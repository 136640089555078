import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import type { FilterProperty } from '@pn/core/domain/layer';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useMapStorage, workspaceActions } from '@pn/core/storage';
import { CustomSvgIcon } from '@pn/ui/icons/CustomSVGIcon';
import assert from 'assert';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listItemButton: {
    height: 30,
  },
  listItemIcon: {
    minWidth: 32,
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 16,
    marginRight: theme.spacing(2),
  },
  semiBold: {
    fontWeight: 500,
  },
}));

type Props = {
  item: WorkspaceItem;
  filterProperty: FilterProperty | undefined;
  isInWorkspace: boolean;
};

export const FilterPropertyComponent = ({
  item,
  filterProperty,
  isInWorkspace,
}: Props) => {
  const { classes } = useStyles();

  const { isInitialized } = useMapStorage();

  const allValues = React.useMemo(() => {
    if (isNil(filterProperty)) return [];
    return Object.values(filterProperty.options).map(({ value }) => value);
  }, [filterProperty]);

  React.useEffect(() => {
    if (!isInitialized || isNil(filterProperty)) return;

    const filter = [
      '!in',
      'symbol',
      ...Object.entries(filterProperty.options)
        .filter(([, { value }]) => !value)
        .map(([key]) => key),
    ];

    item.map.layers.forEach((layer) => {
      map.filterLayer({
        layerId: layer.id,
        key: filterProperty.field,
        filter: isEmpty(filter) ? undefined : filter,
      });
    });
  }, [isInitialized, item, filterProperty]);

  const toggleItem = (key: string) => {
    assert(!isNil(filterProperty), 'filterProperty is nil');

    const updatedFilterPropertyOptions = {
      ...filterProperty.options,
      [key]: {
        ...filterProperty.options[key],
        value: !filterProperty.options[key].value,
      },
    };
    workspaceActions().updateFilterPropertyOptions(
      item.id,
      updatedFilterPropertyOptions
    );
  };

  const toggleAll = () => {
    assert(!isNil(filterProperty), 'filterProperty is nil');

    const updatedFilterPropertyOptions = Object.entries(
      filterProperty.options
    ).reduce<FilterProperty['options']>((acc, [key, val]) => {
      acc[key] = {
        ...val,
        value: !allValues.every(Boolean),
      };
      return acc;
    }, {});
    workspaceActions().updateFilterPropertyOptions(
      item.id,
      updatedFilterPropertyOptions
    );
  };

  if (isNil(filterProperty)) return null;

  return (
    <List disablePadding>
      <ListItem disablePadding dense>
        <ListItemButton
          dense
          className={classes.listItemButton}
          disabled={!isInWorkspace}
          onClick={toggleAll}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <Checkbox
              edge="start"
              size="small"
              tabIndex={-1}
              disableRipple
              checked={allValues.every(Boolean)}
              indeterminate={
                allValues.some(Boolean) && !allValues.every(Boolean)
              }
              inputProps={{ 'aria-labelledby': 'toggle-all-filters' }}
            />
          </ListItemIcon>
          <ListItemText
            id="toggle-all-filters"
            primary="Toggle all"
            primaryTypographyProps={{
              className: classes.semiBold,
            }}
          />
        </ListItemButton>
      </ListItem>
      {Object.entries(filterProperty.options).map(
        ([key, { label, icon, value }]) => (
          <ListItem key={key} disablePadding dense>
            <ListItemButton
              dense
              className={classes.listItemButton}
              disabled={!isInWorkspace}
              onClick={() => toggleItem(key)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  edge="start"
                  size="small"
                  tabIndex={-1}
                  disableRipple
                  checked={value}
                  inputProps={{ 'aria-labelledby': key }}
                />
              </ListItemIcon>
              <ListItemText
                id={key}
                primary={
                  isNil(icon) ? (
                    label
                  ) : (
                    <>
                      <CustomSvgIcon {...icon} className={classes.icon} />
                      {label}
                    </>
                  )
                }
                primaryTypographyProps={{
                  className: classes.listItemText,
                }}
              />
            </ListItemButton>
          </ListItem>
        )
      )}
    </List>
  );
};

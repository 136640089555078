import type { ITableTemplateMapper } from '@pn/core/mappers/table';
import { generateTemporaryId } from '@pn/core/utils/id';
import { jsonToYaml, yamlToJson } from '@pn/services/utils/yaml';
import type { JsonTableTemplate } from './types';
import type { MappingItem } from '@pn/core/domain/data';

export const yamlTableTemplateMapper: ITableTemplateMapper<string, string> = {
  toDomainTemplate: (yamlTemplate) => {
    const jsonTemplate = yamlToJson<JsonTableTemplate>(yamlTemplate);

    return {
      id: generateTemporaryId(),
      createdAt: new Date().toISOString(),
      name: jsonTemplate.name,
      sourceLayerId: jsonTemplate.source_layer_id,
      fields: jsonTemplate.fields,
      isGlobal: false,
    };
  },
  toTargetTemplatePayload: (template, mapping: MappingItem[]) => {
    const jsonTemplate = {
      name: template.name,
      source_layer_id: template.sourceLayerId,
      fields: template.fields,
    };

    const yamlString = jsonToYaml(jsonTemplate);

    const fieldLabels = new Map(
      mapping.map((item) => [item.field, item.label])
    );

    const yamlStringWithComments =
      getTemplateInfoComment() +
      addFieldLabelsAsComments(yamlString, fieldLabels);

    return yamlStringWithComments;
  },
};

function getTemplateInfoComment(): string {
  return `# Generated in Petro Ninja Maps.

`;
}

function addFieldLabelsAsComments(
  yamlString: string,
  fieldLabels: Map<string, string>
): string {
  const lines = yamlString.split('\n');
  const commentedLines = lines.map((line) => {
    // Extract the field name from the line, assuming the format is `- field_name`
    const match = line.trim().match(/^-\s*(\S+)\s*$/);
    if (match) {
      const fieldName = match[1];
      const fieldLabel = fieldLabels.get(fieldName);
      if (fieldLabel) {
        return `${line} # ${fieldLabel}`;
      }
    }
    return line;
  });
  return commentedLines.join('\n');
}

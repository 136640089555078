import { colors, createTheme } from '@mui/material';
import {
  pnBrandColor,
  sharedThemeTokens,
  productionColors,
  typography,
} from './shared';

export const Default = createTheme(
  {
    palette: {
      mode: 'light',
      primary: colors.blueGrey,
      secondary: {
        main: pnBrandColor,
      },
      background: {
        default: '#fafafa',
      },
      action: {
        hover: 'rgba(0, 0, 0, 0.06)',
        hoverOpacity: 0.06,
        selected: 'rgba(0, 0, 0, 0.12)',
        selectedOpacity: 0.12,
      },
    },
    borderColor: 'rgba(0, 0, 0, 0.23)',
    productionColors,
    typography,
  },
  sharedThemeTokens
);

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  getCumulatives,
  ProductionItem,
} from '@pn/core/domain/production';
import { UnitSystem } from '@pn/core/domain/types';
import { unitToString } from '@pn/core/domain/units';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  tableRow: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  tableCell: {
    padding: theme.spacing(1),
  },
  semibold: {
    fontWeight: 500,
  },
}));

type Props = {
  productionItems: ProductionItem[];
  unitSystem: UnitSystem;
};

export const ProductionSummary = ({ productionItems, unitSystem }: Props) => {
  const { classes, cx } = useStyles();

  const cumulatives = React.useMemo(
    () => getCumulatives(productionItems, unitSystem),
    [productionItems, unitSystem]
  );

  const rows = React.useMemo(
    () => [
      {
        title: 'Cumulative Oil',
        value: unitToString(cumulatives.oil, { displaySymbol: true }),
      },
      {
        title: 'Cumulative Gas',
        value: unitToString(cumulatives.gas, { displaySymbol: true }),
      },
      {
        title: 'Cumulative Water',
        value: unitToString(cumulatives.water, { displaySymbol: true }),
      },
      {
        title: 'Cumulative Fluid',
        value: unitToString(cumulatives.fluid, { displaySymbol: true }),
      },
      {
        title: 'Cumulative Load Fluid',
        value: unitToString(cumulatives.loadFluid, { displaySymbol: true }),
      },
    ],
    [
      cumulatives.fluid,
      cumulatives.gas,
      cumulatives.loadFluid,
      cumulatives.oil,
      cumulatives.water,
    ]
  );

  return (
    <Paper variant="outlined">
      <Table aria-label="production cumulatives table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.title} className={classes.tableRow}>
              <TableCell
                component="th"
                scope="row"
                className={cx(classes.tableCell, classes.semibold)}
              >
                {row.title}
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

import type {
  DataItem,
  DataItemId,
  MappingItem,
} from '@pn/core/domain/data';
import { isNil } from 'lodash-es';
import { geoJsonDataItemMapper } from './geoJsonDataItemMapper';
import type { GeoJsonDataItem } from './types';

export function geoJsonToData(
  geoJson: GeoJSON.FeatureCollection
): GeoJsonDataItem[] {
  return geoJson.features.map((feature, index) => ({
    ...feature.properties,
    internal_id: generateIdFromProperties(feature, index),
    geometry: feature.geometry,
  }));
}

function generateIdFromProperties(
  feature: GeoJSON.Feature,
  index: number
): DataItemId {
  if (!isNil(feature.properties) && !isNil(feature.properties.internal_id)) {
    return feature.properties.internal_id;
  } else if (!isNil(feature.id)) {
    return feature.id.toString();
  } else {
    return index.toString();
  }
}

export function dataToGeoJson(
  data: DataItem[],
  mapping: MappingItem[]
): GeoJSON.FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: data
      .map((dataItem) => {
        const { geometry, ...properties } =
          geoJsonDataItemMapper(mapping).toTargetDataItem(dataItem);

        if (isNil(geometry)) return undefined;

        return {
          type: 'Feature' as const,
          geometry,
          properties,
        };
      })
      .filter((f): f is NonNullable<typeof f> => !isNil(f)),
  };
}

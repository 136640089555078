// https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md#available-custom-modes

import MapboxDraw, { type DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import { area } from '@turf/turf';
import { isNil, isObject } from 'lodash-es';

type CustomModeState = any;
type CustomModeOptions = {
  lineColor: string;
  lineWidth: number;
  fillColor: string;
  fillOpacity: number;
};

export const AnnotationPolygonDrawMode: DrawCustomMode<
  CustomModeState,
  CustomModeOptions
> = {
  ...MapboxDraw.modes.draw_polygon,
  onSetup: function (opts) {
    const props = MapboxDraw.modes.draw_polygon.onSetup!.call(this, opts);

    props.polygon.properties = {
      ...props.polygon.properties,
      ...opts,
    };

    return { ...props };
  },
  onKeyUp: function (state, event) {
    if (event.key === 'Enter')
      return this.changeMode('simple_select', {
        featureIds: [state.polygon.id],
      });
    if (event.key === 'Escape') return this.changeMode('simple_select');
  },
  toDisplayFeatures: function (state, geojson, display) {
    if (!('properties' in geojson) || isNil(geojson.properties)) return;

    const isActivePolygon = geojson.properties.id === state.polygon.id;
    geojson.properties.active = isActivePolygon.toString();
    if (!isActivePolygon) return display(geojson);

    if (!isPolygonGeojsonFeature(geojson)) return; // type narrowing

    // Don't render a polygon until it has two positions
    // (and a 3rd which is just the first repeated)
    if (geojson.geometry.coordinates.length === 0) return;

    const coordinateCount = geojson.geometry.coordinates[0].length;
    // 2 coordinates after selecting a draw type
    // 3 after creating the first point
    if (coordinateCount < 3) {
      return;
    }
    geojson.properties.meta = 'feature';
    display(
      createVertex(
        state.polygon.id,
        geojson.geometry.coordinates[0][0],
        '0.0',
        false
      )
    );
    if (coordinateCount > 3) {
      // Add a start position marker to the map, clicking on this will finish the feature
      // This should only be shown when we're in a valid spot
      const endPos = geojson.geometry.coordinates[0].length - 3;
      display(
        createVertex(
          state.polygon.id,
          geojson.geometry.coordinates[0][endPos],
          `0.${endPos}`,
          false
        )
      );
    }
    if (coordinateCount <= 4) {
      // If we've only drawn two positions (plus the closer),
      // make a LineString instead of a Polygon
      const lineCoordinates = [
        [
          geojson.geometry.coordinates[0][0][0],
          geojson.geometry.coordinates[0][0][1],
        ],
        [
          geojson.geometry.coordinates[0][1][0],
          geojson.geometry.coordinates[0][1][1],
        ],
      ];
      // create an initial vertex so that we can track the first point on mobile devices
      display({
        type: 'Feature',
        properties: geojson.properties,
        geometry: {
          coordinates: lineCoordinates,
          type: 'LineString',
        },
      });
      if (coordinateCount === 3) {
        return;
      }
    }
    // render the Polygon
    display(geojson);

    /* Create custom feature for the current pointer position */
    const displayMeasurements = getDisplayMeasurements(geojson);

    const currentVertex: GeoJSON.Feature = {
      type: 'Feature',
      properties: {
        meta: 'currentPosition',
        metric: displayMeasurements.metric,
        imperial: displayMeasurements.imperial,
        parent: state.polygon.id,
      },
      geometry: {
        type: 'Point',
        coordinates: geojson.geometry.coordinates[0].at(-2)!,
      },
    };

    display(currentVertex);
  },
};

function createVertex(
  parentId: string,
  coordinates: GeoJSON.Position,
  path: string, // stringified number
  selected: boolean
): GeoJSON.Feature {
  return {
    type: 'Feature',
    properties: {
      meta: 'vertex',
      parent: parentId,
      coord_path: path,
      active: selected ? 'true' : 'false',
    },
    geometry: {
      type: 'Point',
      coordinates,
    },
  };
}

function getDisplayMeasurements(feature: GeoJSON.Feature) {
  const drawnArea = area(feature); // in m2

  let metricMeasurement = drawnArea;
  let metricFractionDigits = 0;
  let metricUnits = 'm²';

  const imperialMeasurement = drawnArea * 0.000247105; // in acres
  const imperialFractionDigits = 0;
  const imperialUnits = 'acre';

  if (drawnArea >= 1e6) {
    // If over 1,000,000 m2, use km2
    metricMeasurement = drawnArea / 1e6;
    metricFractionDigits = 2;
    metricUnits = 'km²';
  }

  const displayMeasurements = {
    metric: `${toLocaleNumber(
      metricMeasurement,
      metricFractionDigits
    )} ${metricUnits}\n${toLocaleNumber(drawnArea / 10000, 2)} ha`,
    imperial: `${toLocaleNumber(
      imperialMeasurement,
      imperialFractionDigits
    )} ${imperialUnits}`,
  };

  return displayMeasurements;
}

export function toLocaleNumber(num: number, fractionDigits: number): string {
  return num.toLocaleString(undefined, {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
}

export function isPolygonGeojsonFeature(
  arg: unknown
): arg is GeoJSON.Feature<GeoJSON.Polygon> {
  return (
    isObject(arg) &&
    'geometry' in arg &&
    isObject(arg.geometry) &&
    'type' in arg.geometry &&
    arg.geometry.type === 'Polygon' &&
    'coordinates' in arg.geometry &&
    arg.geometry.coordinates !== null &&
    Array.isArray(arg.geometry.coordinates) &&
    arg.geometry.coordinates.length > 0 &&
    Array.isArray(arg.geometry.coordinates[0]) &&
    arg.geometry.coordinates[0].length > 0 &&
    Array.isArray(arg.geometry.coordinates[0][0]) &&
    arg.geometry.coordinates[0][0].length === 2 &&
    typeof arg.geometry.coordinates[0][0][0] === 'number' &&
    typeof arg.geometry.coordinates[0][0][1] === 'number'
  );
}

import {
  getProductionGroupings,
  type ProductionGrouping,
  type ProductionItem,
} from '@pn/core/domain/production';
import { hasKey } from '@pn/core/utils/logic';
import { isEmpty, isString, omit } from 'lodash-es';
import React from 'react';
import type {
  ChartSeries,
  ChartSeriesStyle,
  ChartSeriesStyles,
} from '../types';

export function useChartSeries(
  productionItems: ProductionItem[],
  params: {
    multiSelection: boolean; // checkboxes vs radio controls
    initSeries?: ChartSeries;
    initSeriesStyles?: ChartSeriesStyles;
  }
) {
  const [series, setSeries] = React.useState<ChartSeries>({});
  const [seriesStyles, setSeriesStyles] = React.useState<ChartSeriesStyles>(
    params.initSeriesStyles ?? {}
  );

  const { defaultGroupingLabels, nonDefaultGroupingLabels } =
    React.useMemo(() => {
      const { defaultGroupings, nonDefaultGroupings } =
        getSplitProductionGroupings(productionItems);
      return {
        defaultGroupingLabels: defaultGroupings.map(
          (grouping) => grouping.label
        ),
        nonDefaultGroupingLabels: nonDefaultGroupings.map(
          (grouping) => grouping.label
        ),
      };
    }, [productionItems]);

  const noDefaultGroupings = isEmpty(defaultGroupingLabels);

  React.useLayoutEffect(() => {
    setSeries((prevSeries) => {
      /**
       * If none of Oil/Gas/Water series are present, display all others.
       */
      if (
        params.multiSelection &&
        noDefaultGroupings &&
        !isEmpty(nonDefaultGroupingLabels)
      ) {
        return Object.fromEntries(
          nonDefaultGroupingLabels.map((label) => [
            label,
            params.initSeries?.[label] ?? true,
          ])
        );
      }

      /**
       * Reset series when switching from aggregate view to compare view and
       * more than one series is selected.
       */
      if (!params.multiSelection) {
        if (
          onlyOneSeriesIsSelected(prevSeries) &&
          seriesIncludesAll(prevSeries, nonDefaultGroupingLabels)
        ) {
          return prevSeries;
        }

        return {
          Oil: true,
          Gas: false,
          Water: false,
          ...Object.fromEntries(
            nonDefaultGroupingLabels.map((label) => [label, false])
          ),
        };
      }

      return {
        Oil: params.initSeries?.['Oil'] ?? true,
        Gas: params.initSeries?.['Gas'] ?? true,
        Water: params.initSeries?.['Water'] ?? true,
        ...Object.fromEntries(
          nonDefaultGroupingLabels.map((label) => [
            label,
            params.initSeries?.[label] ?? false,
          ])
        ),
      };
    });
  }, [
    noDefaultGroupings,
    nonDefaultGroupingLabels,
    params.initSeries,
    params.multiSelection,
  ]);

  /**
   * An alert is shown if not all default series are enabled.
   * For wells, this will be Oil, Gas, and Water.
   * For other data types, all series are considered default.
   */
  const showSeriesAlert = React.useMemo(() => {
    const defaultActiveProductionGroupings = !isEmpty(defaultGroupingLabels)
      ? defaultGroupingLabels
      : nonDefaultGroupingLabels;

    const activeSeries = Object.keys(series).filter((key) => series[key]);

    return defaultActiveProductionGroupings.some(
      (grouping) => !activeSeries.includes(grouping)
    );
  }, [defaultGroupingLabels, nonDefaultGroupingLabels, series]);

  const handleChangeSeries = React.useCallback(
    (key: string, checked: boolean) =>
      params.multiSelection
        ? setSeries((prev) => ({ ...prev, [key]: checked }))
        : setSeries((prev) => ({
            ...Object.fromEntries(Object.keys(prev).map((key) => [key, false])),
            [key]: true,
          })),
    [params.multiSelection]
  );

  const handleChangeAllSeries = React.useCallback(
    (checked: boolean) =>
      setSeries((prev) =>
        Object.fromEntries(Object.keys(prev).map((key) => [key, checked]))
      ),
    []
  );

  const handleUpsertSeriesStyle = React.useCallback(
    (grouping: string, style: ChartSeriesStyle) =>
      setSeriesStyles((prev) => ({ ...prev, [grouping]: style })),
    []
  );

  const handleRemoveSeriesStyle = React.useCallback(
    (grouping: string) => setSeriesStyles((prev) => omit(prev, [grouping])),
    []
  );

  return {
    series,
    seriesStyles,
    showSeriesAlert,
    handleChangeSeries,
    handleChangeAllSeries,
    handleUpsertSeriesStyle,
    handleRemoveSeriesStyle,
  };
}

export function getDefaultChartSeries(
  multiSelection: boolean,
  initSeries?: unknown
): ChartSeries {
  if (isString(initSeries)) {
    return JSON.parse(decodeURIComponent(initSeries));
  }

  return {
    Oil: true,
    Gas: multiSelection,
    Water: multiSelection,
  };
}

function getSplitProductionGroupings(productionItems: ProductionItem[]) {
  const productionGroupings = getProductionGroupings(
    productionItems,
    'monthly' // doesn't matter which mode we use here
  );

  return productionGroupings.reduce<{
    defaultGroupings: ProductionGrouping[];
    nonDefaultGroupings: ProductionGrouping[];
  }>(
    ({ defaultGroupings, nonDefaultGroupings }, grouping) => {
      if (['Oil', 'Gas', 'Water'].includes(grouping.label)) {
        defaultGroupings.push(grouping);
      } else {
        nonDefaultGroupings.push(grouping);
      }
      return { defaultGroupings, nonDefaultGroupings };
    },
    { defaultGroupings: [], nonDefaultGroupings: [] }
  );
}

function onlyOneSeriesIsSelected(series: ChartSeries) {
  return Object.values(series).filter(Boolean).length === 1;
}

function seriesIncludesAll(series: ChartSeries, labels: string[]) {
  return labels.every((label) => hasKey(series, label));
}

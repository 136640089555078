import type { SearchResult } from '@pn/core/domain/search';
import { handleError } from '@pn/core/errors/handleError';
import { searchActions } from '@pn/core/storage';
import { apiSearchProvider } from '@pn/services/api/search/apiSearchProvider';

export async function getSearchResults(text: string): Promise<SearchResult[]> {
  try {
    searchActions().request();

    const searchResults = await apiSearchProvider.searchByText(text);

    searchActions().receive(searchResults);

    return searchResults;
  } catch (error) {
    handleError({
      error,
      onError: searchActions().error,
      userFriendlyMessage: 'Failed to get search results',
    });
  }

  return [];
}

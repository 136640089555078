import type { WorkspaceItem } from '@pn/core/domain/workspace/workspaceItem';

export function getWorkspaceItemUrl(
  id: WorkspaceItem['id'],
  itemType: WorkspaceItem['itemType'],
  baseUrl: string
): string {
  switch (itemType) {
    case 'layer':
      return baseUrl + '/' + encodeURIComponent(id);
    case 'list':
      return baseUrl + '/lists/' + encodeURIComponent(id);
    case 'annotation':
      return baseUrl + '/annotations/' + encodeURIComponent(id);
    case 'drawing':
      return baseUrl + '/drawings/' + encodeURIComponent(id);
  }
}

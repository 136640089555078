import { SvgIcon, colors, type SvgIconProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ disabled: boolean }>()(
  (theme, { disabled }) => ({
    first: {
      fill: disabled ? theme.palette.action.disabled : colors.cyan[500],
    },
    second: {
      fill: disabled ? theme.palette.action.disabled : colors.pink[500],
    },
    third: {
      fill: disabled ? theme.palette.action.disabled : colors.orange[500],
    },
  })
);

export const ColorfulStarsIcon = (
  props: SvgIconProps & { disabled?: boolean }
) => {
  const { disabled = false } = props;

  const { classes } = useStyles({ disabled });

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        className={classes.first}
        d="M19 9L20.25 6.25L23 5L20.25 3.75L19 1L17.75 3.75L15 5L17.75 6.25Z"
      />
      <path
        className={classes.second}
        d="M11.5 9.5L9 4L6.5 9.5L1 12L6.5 14.5L9 20L11.5 14.5L17 12Z"
      />
      <path
        className={classes.third}
        d="M19 15L17.75 17.75L15 19L17.75 20.25L19 23L20.25 20.25L23 19L20.25 17.75Z"
      />
    </SvgIcon>
  );
};

import { Box } from '@mui/material';
import React from 'react';
import { CustomTooltip } from './CustomTooltip';

type Props = {
  title: string;
  duration?: number;
  placement?: 'right' | 'left';
  children: React.ReactNode;
};

export function NotificationTooltip({
  title,
  duration = 1500,
  placement = 'right',
  children,
  ...rest
}: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickChildren = () => {
    setOpen(true);
    setTimeout(() => setOpen(false), duration);
  };

  return (
    <CustomTooltip
      placement={placement}
      title={title}
      open={open}
      disableHoverListener
      {...rest}
    >
      <Box component="span" onClick={handleClickChildren}>
        {children}
      </Box>
    </CustomTooltip>
  );
}

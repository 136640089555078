import { Link as MuiLink } from '@mui/material';
import { isCordova } from '@pn/core/utils/env';
import { isNil } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';

export const CustomLink = React.forwardRef(
  ({ children, to, target, className, onClick }: any, ref: any) => {
    if (isNil(to)) {
      return (
        <MuiLink
          ref={ref}
          component={Link}
          to="#"
          target={target}
          className={className}
          onClick={onClick}
        >
          {children}
        </MuiLink>
      );
    } else if (to.startsWith('http://') || to.startsWith('https://')) {
      if (isCordova()) {
        return (
          <MuiLink
            ref={ref}
            className={className}
            onClick={() => {
              window.cordova.InAppBrowser.open(to, '_system');
              onClick();
            }}
          >
            {children}
          </MuiLink>
        );
      } else {
        return (
          <MuiLink
            ref={ref}
            component="a"
            href={to}
            target={target}
            className={className}
            onClick={onClick}
          >
            {children}
          </MuiLink>
        );
      }
    } else {
      return (
        <MuiLink
          ref={ref}
          component={Link}
          to={to}
          target={target}
          className={className}
          onClick={onClick}
        >
          {children}
        </MuiLink>
      );
    }
  }
);

CustomLink.displayName = 'CustomLink';

import Tab, { type TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  tabs: {
    minHeight: 32,
  },
  tab: {
    minHeight: 32,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'initial',
  },
}));

type DenseTabsProps = {
  children: React.ReactNode;
  tabValue: string;
  setTabValue: (value: string) => void;
};

export function DenseTabs({ children, tabValue, setTabValue }: DenseTabsProps) {
  const { classes } = useStyles();

  return (
    <Tabs
      variant="scrollable"
      scrollButtons={false}
      value={tabValue}
      onChange={(_, newValue) => setTabValue(newValue)}
      className={classes.tabs}
    >
      {children}
    </Tabs>
  );
}

export function DenseTab(props: TabProps) {
  const { classes } = useStyles();

  return <Tab {...props} className={classes.tab} />;
}

type TabPanelProps = {
  children: React.ReactNode;
  tabValue: string;
  tabKey: string;
};

export function TabPanel({ children, tabValue, tabKey }: TabPanelProps) {
  if (tabValue !== tabKey) {
    return null;
  }

  return <>{children}</>;
}

import {
  isLayerStyleProperty,
  LayerType,
  type LayerStyle,
} from '@pn/core/domain/layer';
import {
  SELECTED_LINE_WIDTH,
  SELECTED_SYMBOL_SIZE,
} from '@pn/services/styles';
import {
  generateSelectionColor,
  generateSelectionExpression,
  multiplyExpression,
} from '@pn/services/utils/color';
import assert from 'assert';
import { isArray, isNumber, isString } from 'lodash-es';
import type { WorkspaceItem } from './workspaceItem';

export function generateSelectionStyle(
  style: LayerStyle,
  layerType: LayerType
): LayerStyle {
  const selectionStyle: LayerStyle = {};

  Object.keys(style).forEach((styleKey) => {
    assert(isLayerStyleProperty(styleKey), 'Invalid style key.');

    const styleValue = style[styleKey];

    if (styleKey === 'opacity') {
      /**
       * Skip.
       */
    } else if (styleKey === 'color' || styleKey === 'outlineColor') {
      if (isString(styleValue)) {
        selectionStyle[styleKey] = generateSelectionColor(
          styleValue,
          layerType
        );
      } else if (isArray(styleValue)) {
        selectionStyle[styleKey] = generateSelectionExpression(
          styleValue,
          layerType
        );
      } else {
        selectionStyle[styleKey] = styleValue;
      }
    } else if (
      styleKey === 'size' ||
      styleKey === 'width' ||
      styleKey === 'radius'
    ) {
      if (isNumber(styleValue) || isArray(styleValue)) {
        /**
         * Generate larger values for size/width/radius.
         */
        if (styleKey === 'size' && layerType === LayerType.Text)
          selectionStyle[styleKey] = multiplyExpression(styleValue, 1.33);
        if (styleKey === 'size' && layerType !== LayerType.Text)
          selectionStyle[styleKey] = multiplyExpression(
            styleValue,
            1.67,
            SELECTED_SYMBOL_SIZE
          );
        if (styleKey === 'width')
          selectionStyle[styleKey] = multiplyExpression(styleValue, 2);
        if (styleKey === 'radius')
          selectionStyle[styleKey] = multiplyExpression(styleValue, 1.67);
      } else {
        /**
         * Assign default selection size/width for all non-number values.
         */
        if (styleKey === 'size')
          selectionStyle[styleKey] = SELECTED_SYMBOL_SIZE;
        if (styleKey === 'width')
          selectionStyle[styleKey] = SELECTED_LINE_WIDTH;
        if (styleKey === 'radius') selectionStyle[styleKey] = styleValue; // unchanged
      }
    } else {
      selectionStyle[styleKey] = styleValue; // unchanged
    }
  });

  // log.info('generated selection style', selectionStyle);

  return selectionStyle;
}

export type ColorType = 'match' | 'step';
export type ColorLegend = {
  label: string | number;
  labelPrefix?: string;
  color: string;
}[];

/**
 * Extract extra visualized fields from all layers that require extra map data
 * properties to be fetched.
 */
export function getExtraVisualizedFields(item: WorkspaceItem): string[] {
  return item.map.layers.reduce<string[]>((acc, layer) => {
    const { field, ...styleProperties } = layer.style;

    if (isArray(field) && !acc.includes(field[1])) acc.push(field[1]);

    Object.values(styleProperties).forEach((styleProperty) => {
      if (isArray(styleProperty)) {
        findPropertyKeys(styleProperty).forEach((key) => {
          if (!acc.includes(key)) acc.push(key);
        });
      }
    });

    return acc;
  }, []);
}

type ColorComponents = {
  type?: ColorType;
  field?: string;
  legend?: ColorLegend;
};

export function getColorComponents(color: unknown): ColorComponents {
  if (!isArray(color)) return {};

  switch (color[0]) {
    case 'match': {
      const field = findPropertyKeys(color)[0];
      const legend = [];

      for (let i = 2; i < color.length - 1; i += 2) {
        legend.push({ label: color[i], color: color[i + 1] });
      }

      // legend.push({ label: 'Other', color: last(color) });

      return { type: 'match', field, legend };
    }
    case 'step': {
      const field = findPropertyKeys(color)[0];
      const legend: ColorLegend = [];

      for (let i = 3; i < color.length; i += 2) {
        legend.push({ label: color[i], color: color[i + 1] });
      }

      return { type: 'step', field, legend };
    }
    default:
      return {};
  }
}

/**
 * HELPERS
 */

function findPropertyKeys(arr: unknown[]): string[] {
  return arr.reduce<string[]>((acc, item) => {
    if (isArray(item)) {
      if (item[0] === 'get' && isString(item[1]) && !acc.includes(item[1])) {
        acc.push(item[1]);
      } else {
        acc.push(...findPropertyKeys(item));
      }
    }
    return acc;
  }, []);
}

import type { GeoPoint } from '@pn/core/domain/geography';
import type { MapboxIMap } from '..';

export function moveTo(this: MapboxIMap, point: GeoPoint, zoom?: number) {
  // NOTE we can use `flyTo()` here if we tweak it to be faster
  this._native.jumpTo({
    center: [point.lon, point.lat],
    zoom: zoom ?? this._native.getZoom(),
  });
}

import { cachePolicy } from '@pn/core/cachePolicy';
import { dependencies } from '@pn/core/dependencies';
import { processByQuery, type MappingItem } from '@pn/core/domain/data';
import type { GeoJsonDataSource } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import type { IDataProvider } from '@pn/core/providers/data/ports';
import { isGeoJSONFeatureCollection } from '@pn/core/utils/geospatial';
import { isValidHttpUrl } from '@pn/core/utils/validation';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { generateMappingFromGeoJsonData } from '@pn/services/local/data/generateMappingFromGeoJsonData';
import { geoJsonDataItemMapper } from '@pn/services/local/data/geoJsonDataItemMapper';
import { geoJsonToData } from '@pn/services/local/data/geoJsonToDataConverter';
import { checkGeoJsonForMixedTypes } from '@pn/services/local/data/utils';
import { isEmpty, round } from 'lodash-es';

const cacheStorage = new Map<string, GeoJSON.Feature[]>();
const cache = {
  has: (layerId: string) => cacheStorage.has(layerId),
  get: (layerId: string) => cacheStorage.get(layerId),
  set: (layerId: string, data: GeoJSON.Feature[]) =>
    cacheStorage.set(layerId, data),
};

const mappingsCache = new Map<string, MappingItem[]>();

export const geoJsonDataProvider: IDataProvider<GeoJsonDataSource> = (
  dataSource
) => ({
  getDataMapping: async (item) => {
    if (cachePolicy.mappings && mappingsCache.has(item.dataType)) {
      return mappingsCache.get(item.dataType)!;
    }

    try {
      const features = cache.has(item.dataType)
        ? cache.get(item.dataType)!
        : await retrieveAndProcessGeoJson({
            layerId: item.dataType,
            url: dataSource.url,
          });
      const geoJsonDataItems = geoJsonToData({
        type: 'FeatureCollection',
        features,
      });
      const mapping = generateMappingFromGeoJsonData(geoJsonDataItems);

      if (cachePolicy.mappings) mappingsCache.set(item.dataType, mapping);

      return mapping;
    } catch (error) {
      handleError({
        error,
        userFriendlyMessage: 'Failed to retrieve GeoJSON data',
      });

      return [];
    }
  },

  getDataByQuery: async ({ query, mapping }) => {
    if (isEmpty(mapping)) {
      return {
        data: [],
        totalCount: 0,
        retrievalTime: 0,
        page: 0,
      };
    }

    const startTime = performance.now();

    const layerId = query.dataType;
    const features = cache.has(layerId)
      ? cache.get(layerId)!
      : await retrieveAndProcessGeoJson({ layerId, url: dataSource.url });
    const geoJsonDataItems = geoJsonToData({
      type: 'FeatureCollection',
      features,
    });
    const dataItems = geoJsonDataItems.map(
      geoJsonDataItemMapper(mapping).toDomainDataItem
    );

    const { data, totalCount, page } = processByQuery({
      localData: dataItems,
      queryType: 'scroll',
      query,
    });

    return {
      data,
      totalCount,
      retrievalTime: round(performance.now() - startTime, 1),
      page,
    };
  },

  streamDataByQuery: async ({ query, mapping, receiveChunk }) => {
    if (isEmpty(mapping)) return;

    const layerId = query.dataType;
    const features = cache.has(layerId)
      ? cache.get(layerId)!
      : await retrieveAndProcessGeoJson({ layerId, url: dataSource.url });
    const geoJsonDataItems = geoJsonToData({
      type: 'FeatureCollection',
      features,
    });
    const dataItems = geoJsonDataItems.map(
      geoJsonDataItemMapper(mapping).toDomainDataItem
    );

    const { data } = processByQuery({
      localData: dataItems,
      queryType: 'stream',
      query,
    });

    receiveChunk(data);
  },
});

async function retrieveAndProcessGeoJson(params: {
  layerId: string;
  url: string;
}): Promise<GeoJSON.Feature[]> {
  const { layerId, url } = params;

  if (isValidHttpUrl(url)) {
    const response = await fetch(url);
    const geojson = (await response.json()) as GeoJSON.FeatureCollection;
    return geojson.features;
  }

  let jsonString = '';

  await pnApiClient.stream({
    url,
    raw: true,
    onReceiveDataChunk: (stringChunk: string) => (jsonString += stringChunk),
  });

  const parsedJson = JSON.parse(jsonString);
  if (!isGeoJSONFeatureCollection(parsedJson)) {
    throw new Error(
      'Failed to download layer data. Invalid GeoJSON.FeatureCollection'
    );
  }

  cache.set(layerId, parsedJson.features);

  if (checkGeoJsonForMixedTypes(parsedJson.features)) {
    dependencies.notificationService.notify(
      'Mixed geometry types detected. Some of the layer features will not be displayed on the map',
      'warning'
    );
  }

  return parsedJson.features;
}

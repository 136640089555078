import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { apiDataProvider } from '@pn/services/api/data/apiDataProvider';
import { geoJsonDataProvider } from '@pn/services/api/data/geoJsonDataProvider';
import { noDataProvider } from '@pn/services/local/data/noDataProvider';
import type { IDataProvider } from './ports';

export const dynamicDataProvider: IDataProvider<WorkspaceItem['dataSource']> = (
  dataSource
) => {
  switch (dataSource.type) {
    case 'api':
      return apiDataProvider(dataSource);
    case 'geojson':
      return geoJsonDataProvider(dataSource);
    case 'none':
      return noDataProvider(dataSource);
  }
};

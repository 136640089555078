import type { User } from '@pn/core/domain/user';
import {
  createWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { workspaceActions } from '@pn/core/storage';
import { format } from 'date-fns';

export function createDrawing(
  allWorkspaceItems: WorkspaceItem[],
  user: User
): WorkspaceItem {
  const drawingItems = allWorkspaceItems.filter(
    (i) => i.itemType === 'drawing'
  );

  const baseName = `Drawing ${format(new Date(), 'MMM d, yyyy')}`;

  let maxCounter = 0;
  for (const item of drawingItems) {
    if (!item.name.startsWith(baseName)) continue;

    const regex = /\((\d+)\)/;
    const match = regex.exec(item.name);
    if (match) {
      const counter = Number(match[1]);
      if (counter > maxCounter) maxCounter = counter;
    } else {
      if (maxCounter === 0) maxCounter = 1;
    }
  }
  const ext = maxCounter > 0 ? ` (${maxCounter + 1})` : '';

  const newItem = createWorkspaceItem(
    {
      source: 'drawing',
      name: baseName + ext,
      data: {
        type: 'drawing',
        features: [],
      },
    },
    user
  );

  workspaceActions().create(newItem);
  workspaceActions().addToWorkspace(newItem.id);
  workspaceActions().select(newItem.id);

  return newItem;
}

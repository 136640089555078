import { useLayerHighlighting } from '@pn/core/operations/workspace/hooks/useLayerHighlighting';
import { workspaceActions } from '@pn/core/storage';
import { log } from '@pn/core/utils/debug';
import React from 'react';
import { useAutoSetCurrentRequestedIdFeatureState } from './hooks/useAutoSetCurrentRequestedIdFeatureState';
import { useAutoVisualizeWorkspaceItems } from './hooks/useAutoVisualizeWorkspaceItems';
import { useHideAllButSelected } from './hooks/useHideAllButSelected';
import { useAutoApplyCrosshair } from '@pn/core/operations/workspace/hooks/useAutoApplyCrosshair';

export { exportWorkspaceItem } from './exportWorkspaceItem';
export { useFilterWorkspaceItem } from './filterWorkspaceItem';
export { useDynamicStyling } from './hooks/useDynamicStyling';
export { useVisualizeWorkspaceItem } from './hooks/useVisualizeWorkspaceItem';
export { moveWorkspaceItem } from './moveWorkspaceItem';
export { revisualizeWorkspaceItem } from './revisualizeWorkspaceItem';

/**
 * Include in your app to use workspace logic.
 *
 * All of the flags are dynamic, so you can enable/disable features on the fly.
 * You may also need to include or reimplement MapOverlay.tsx if you visualize
 * queries with `ignoreLimit` option disabled.
 *
 * @param params.tableOnly - if true, no map visualizations will be performed
 * @param params.disableAutoFetching - if true, none of the queries will be
 * visualized automatically (including lists/layers/etc.)
 * @param params.hideCrosshair - if true, the crosshair will not be rendered
 * when hovering over data features
 */
export function useWorkspaceLogic(
  params: {
    tableOnly?: boolean;
    disableAutoFetching?: boolean;
    hideCrosshair?: boolean;
  } = {}
): void {
  const { tableOnly, disableAutoFetching, hideCrosshair } = params;

  useAutoVisualizeWorkspaceItems({ skip: disableAutoFetching, tableOnly });

  useAutoSetCurrentRequestedIdFeatureState(tableOnly);
  useLayerHighlighting(tableOnly, hideCrosshair);
  useAutoApplyCrosshair(tableOnly, hideCrosshair);

  useHideAllButSelected();

  /**
   * Force re-visualization of all workspace items when the component is
   * remounted. This usually happens whenever the Map is re-rendered.
   */
  React.useEffect(() => {
    return () => {
      log.info('resetting internal state of all workspace items');
      workspaceActions().resetInternalState();
    };
  }, []);
}

import { getProjectItemCount } from '@pn/core/domain/project';
import type { ISODateTimeString } from '@pn/core/domain/types';
import { useAccess } from '@pn/core/permissions/access';
import { isEditable } from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useProjectsStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { isFreeCordova } from '@pn/core/utils/env';
import { isCloseMatch } from '@pn/services/utils/string';
import { isEmpty, isNil, orderBy } from 'lodash-es';
import React from 'react';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import type {
  LibraryRow,
  LibraryRowProject,
  LibraryRowWorkspaceItem,
} from '../types';
import { getProjectCheckedState } from '../utils';

export function useLibraryRows(): LibraryRow[] {
  const access = useAccess();
  const { user } = useCurrentUserStorage();
  const { projects } = useProjectsStorage();
  const { allWorkspaceItems, idsInWorkspace } = useWorkspaceStorage();

  const { searchValue, libraryProjectSelected, libraryRouter } = useLibrary();

  /**
   * Projects original order is preserved.
   */
  const projectsRows = React.useMemo(() => {
    if (isEmpty(searchValue)) {
      if (isNil(libraryProjectSelected)) {
        return projects.filter(
          (project) =>
            project.isFolder && project.folder === libraryRouter.folder
        );
      } else {
        return [];
      }
    } else {
      return projects.filter(
        (project) =>
          project.isFolder &&
          project.origin === 'pn' &&
          isCloseMatch(project.name, searchValue)
      );
    }
  }, [projects, libraryRouter.folder, libraryProjectSelected, searchValue]);

  const filteredWorkspaceItems = React.useMemo(
    () =>
      orderByUpdatedAt(
        allWorkspaceItems.filter(
          (item) =>
            !item.isTemporary &&
            (isEmpty(searchValue)
              ? true
              : isCloseMatch(item.name, searchValue)) &&
            (isFreeCordova(user)
              ? ['wells', 'wells_usa', '_background', 'grids'].includes(
                  item.id
                ) || isEditable(item, user)
              : true)
        )
      ),
    [allWorkspaceItems, searchValue, user]
  );

  const isSearchValueEmpty = isEmpty(searchValue);

  const workspaceItemsRows = React.useMemo(() => {
    if (isSearchValueEmpty) {
      return filteredWorkspaceItems.filter((item) =>
        isNil(libraryProjectSelected)
          ? libraryRouter.folder === item.folder
          : libraryProjectSelected.workspaceItemIds.includes(item.id)
      );
    } else {
      return filteredWorkspaceItems;
    }
  }, [
    libraryRouter.folder,
    filteredWorkspaceItems,
    isSearchValueEmpty,
    libraryProjectSelected,
  ]);

  const combinedRows: LibraryRow[] = React.useMemo(
    () => [
      ...projectsRows.map((project): LibraryRowProject => {
        return {
          rowType: 'project',
          source: project,
          id: project.id,
          name: project.name,
          count: getProjectItemCount(project),
          origin:
            project.metadata?.dataOriginDescription ??
            toReadableOrigin(project.origin),
          updatedAt: project.updatedAt,
          _checked: getProjectCheckedState(
            project.workspaceItemIds,
            idsInWorkspace
          ),
          _disabled:
            getProjectItemCount(project) === 0 || access('allData').denied(),
        };
      }),
      ...workspaceItemsRows.map(
        (item): LibraryRowWorkspaceItem => ({
          rowType: item.itemType,
          source: item,
          id: item.id,
          name: item.name,
          count: item.numberOfElements,
          origin:
            item.metadata?.dataOriginDescription ??
            toReadableOrigin(item.origin),
          updatedAt: item.updatedAt,
          _checked: idsInWorkspace.includes(item.id) ? 'checked' : 'unchecked',
          _disabled:
            !isNil(item.module) ||
            (idsInWorkspace.includes(item.id) ? !item.isRendered : false) ||
            (access('allData', item.id).denied() && !isEditable(item, user)),
        })
      ),
    ],
    [projectsRows, workspaceItemsRows, idsInWorkspace, access, user]
  );

  return combinedRows;
}

function orderByUpdatedAt<T extends { updatedAt: ISODateTimeString }>(
  elements: T[]
): T[] {
  return orderBy(elements, 'updatedAt', 'desc');
}

function toReadableOrigin(origin: string): string {
  switch (origin) {
    case 'pn':
      return 'Petro Ninja';
    case 'stackdx':
      return 'StackDX';
    case 'boe_intel':
      return 'BOE Intel';
    default:
      return origin;
  }
}

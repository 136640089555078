import { useTheme } from '@mui/material';
import type {
  ProductionChartType,
  ProductionItem,
} from '@pn/core/domain/production';
import type { UnitSystem } from '@pn/core/domain/types';
import { productionItemsToChartJSData } from '@pn/services/charts/chartjs/production/chartData';
import { getChartJSOptions } from '@pn/services/charts/chartjs/production/chartOptions';
import { useApplyChartSeries } from '@pn/services/charts/hooks/useApplyChartSeries';
import type {
  ChartScale,
  ChartSeries,
  ChartSeriesStyles,
  ChartView,
  ProductionChartMode,
} from '@pn/services/charts/types';
import { Chart as ChartJS, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { watermarkPlugin } from './components/watermarkPlugin';

ChartJS.register(...registerables, watermarkPlugin);

type Props = {
  productionChartType: ProductionChartType;
  productionItems: ProductionItem[];
  unitSystem: UnitSystem;
  scale: ChartScale;
  mode: ProductionChartMode;
  series: ChartSeries;
  seriesStyles: ChartSeriesStyles;
  chartView: ChartView;
  maintainAspectRatio?: boolean;
  displayWatermark?: boolean;
};

export function SingleProductionChart({
  productionChartType,
  productionItems,
  unitSystem,
  scale,
  mode,
  series,
  seriesStyles,
  chartView,
  displayWatermark,
}: Props) {
  const theme = useTheme();
  const chartRef = React.useRef<any>();

  const data = React.useMemo(
    () =>
      productionItemsToChartJSData(productionItems, {
        theme,
        productionChartType,
        unitSystem,
        scale,
        mode,
        seriesStyles,
      }),
    [
      productionItems,
      theme,
      productionChartType,
      scale,
      mode,
      seriesStyles,
      unitSystem,
    ]
  );
  const options = React.useMemo(
    () =>
      getChartJSOptions(productionItems, {
        theme,
        productionChartType,
        scale,
        mode,
        unitSystem,
        series,
        chartView,
        displayWatermark,
      }),
    [
      productionItems,
      theme,
      productionChartType,
      scale,
      mode,
      unitSystem,
      series,
      chartView,
      displayWatermark,
    ]
  );

  useApplyChartSeries({ chartRef, data, series });

  return <Line ref={chartRef} data={data} options={options} />;
}

import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const mineralRightsTransfersMapping: PostgresMappingItem[] = [
  {
    field: 'id',
    label: 'ID',
    width: 175,
    sourceType: 'int',
  },
  {
    field: 'created_at',
    domainType: 'DateString',
    label: 'Transfer Date',
    isShownByDefault: true,
    width: 125,
    sourceType: 'date',
  },
  {
    field: 'agreement_count',
    label: 'Agreements',
    isShownByDefault: true,
    sourceType: 'int',
    width: 80,
  },
  {
    field: 'seller_company',
    label: 'From',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'buyer_company',
    label: 'To',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'province',
    label: 'Province',
    isShownByDefault: true,
    sourceType: 'text',
    width: 60,
  },
  {
    field: 'net_area_change_ha',
    label: 'Net Area Change',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'ha',
    },
    isShownByDefault: true,
    width: 100,
    sourceType: 'float',
  },
  {
    field: 'petroninja_land_id_list',
    label: 'Land ID List',
    sourceType: 'json',
    isNotRenderable: true,
  },
  {
    field: 'agreement_id_list',
    label: 'Agreement Id List',
    sourceType: 'json',
    isNotRenderable: true,
  },
];

import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { appSlice } from './appSlice';
import type { IAppActions, IAppStorage } from './ports';

const { actions, name, getInitialState } = appSlice;

export const useReduxAppStorage = (): IAppStorage => {
  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isAppInitialized: useSliceSelector((state) => state.isInitialized),
    unitSystem: useSliceSelector((state) => state.unitSystem),
    pageSize: useSliceSelector((state) => state.pageSize),
  };
};

export const reduxAppActions = (): IAppActions => {
  const { dispatch } = dependencies.store;

  return {
    initializeApp: () => dispatch(actions.initialize()),
    updateUnitSystem: (unitSystem) =>
      dispatch(actions.updateUnitSystem(unitSystem)),
    updatePageSize: (pageSize) => dispatch(actions.updatePageSize(pageSize)),
  };
};

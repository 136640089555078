import { WorkspaceItem } from '@pn/core/domain/workspace';
import { useWorkspaceStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import React from 'react';

export function useStackProjects() {
  const { allWorkspaceItems } = useWorkspaceStorage();

  const { stackSourceItems, stackOriginatedItems } = React.useMemo(() => {
    const stackSourceItems: WorkspaceItem[] = [];
    const stackOriginatedItems: WorkspaceItem[] = [];

    for (const item of allWorkspaceItems) {
      if (item.origin !== 'stackdx') continue;

      if (isNil(item.sourceItem)) {
        stackSourceItems.push(item);
      } else if (!isNil(item.metadata?.dataOriginDescription)) {
        stackOriginatedItems.push(item);
      }
    }

    return {
      stackSourceItems,
      stackOriginatedItems,
    };
  }, [allWorkspaceItems]);

  return {
    stackSourceItems,
    stackOriginatedItems,
  };
}

import { findOrThrow } from '@pn/core/utils/logic';
import { elasticMappings } from '@pn/resources/elastic-mappings';
import { isEmpty } from 'lodash-es';

type Preset = {
  field: string;
  label: string;
};

const pnPresetFields: Record<string, string[]> = {
  wells: ['licensee', 'spud_date', 'producing_formation'],
  pipelines: ['comp_name', 'seg_status', 'substance'],
  facilities: ['operator', 'type', 'status'],
  mineral_rights: ['designated_rep', 'bonus_per_area'],
};

const stackPresetFields: Record<string, string[]> = {
  mineralSplits: ['Interest Type', 'Lessor Type', 'Mineral Expiry Bucket'],
  contractSplits: ['Admin Name', 'ROFR', 'Contract Type', 'File Status'],
  surface: ['Payor Name', 'Document Type', 'Lessor Type', 'File Status'],
};

const pnPresets = Object.keys(pnPresetFields).reduce<Record<string, Preset[]>>(
  (acc, dataType) => {
    acc[dataType] = [];

    pnPresetFields[dataType].forEach((field) => {
      const mappingItem = findOrThrow(
        elasticMappings[dataType],
        (mappingItem) => mappingItem.field === field
      );

      acc[dataType].push({
        field: mappingItem.field,
        label: mappingItem.label,
      });
    });

    return acc;
  },
  {}
);

const stackPresets = Object.keys(stackPresetFields).reduce<
  Record<string, Preset[]>
>((acc, dataType) => {
  acc[dataType] = stackPresetFields[dataType].map((field) => ({
    field,
    label: field,
  }));

  return acc;
}, {});

export function getQuickDynamicStylingPresets(dataType: string): Preset[] {
  if (!isEmpty(pnPresets[dataType])) return pnPresets[dataType];

  for (const key in stackPresetFields) {
    if (dataType.endsWith('|' + key)) return stackPresets[key];
  }

  return [];
}

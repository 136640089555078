import { dependencies } from '@pn/core/dependencies';
import { useInjectableReducer as useInjectableReducerService } from '@pn/services/redux/useInjectableReducer';
import type { Reducer } from '@reduxjs/toolkit';

/**
 * IMPORTANT: only use when dealing with dynamic reducers that cannot be added
 * to the store at compilation time.
 */
export function useInjectableReducer(sliceName: string, reducer: Reducer) {
  return useInjectableReducerService(dependencies.store, sliceName, reducer);
}

// export function injectReducer(sliceName: string, reducer: Reducer) {
//   return injectReducerService(dependencies.store, sliceName, reducer);
// }

// export function removeInjectedReducer(sliceName: string) {
//   return removeInjectedReducerService(dependencies.store, sliceName);
// }

import download from 'downloadjs';

export const downloadAsGeoJSON = (
  json: unknown,
  params: { fileName: string }
) => {
  const { fileName } = params;

  const geojson = JSON.stringify(json);

  download(geojson, fileName);
};
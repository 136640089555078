import { ProductionItem } from '@pn/core/domain/production';
import {
  isGasProductionUnit,
  isLiquidProductionUnit,
  SIUnit,
} from '@pn/core/domain/units';
import { ProductionChartMode } from '@pn/services/charts/types';

export type ChartMaxValues = {
  liquid: number;
  gas: number;
  gor: number;
  xgr: number; // WGR and CGR
};

export function getMaxValues(
  productionItems: ProductionItem[],
  mode: ProductionChartMode
): ChartMaxValues {
  const maxValues = {
    liquid: 0,
    gas: 0,
    gor: 0,
    xgr: 0,
  };

  productionItems.forEach((item) => {
    if (isLiquidProductionUnit(item[mode])) {
      maxValues.liquid = Math.max(maxValues.liquid, item[mode].value ?? 0);
    } else if (isGasProductionUnit(item[mode])) {
      maxValues.gas = Math.max(maxValues.gas, item[mode].value ?? 0);
    } else if (
      item[mode].symbol === 'm3/m3' ||
      item[mode].symbol === 'scf/bbl'
    ) {
      maxValues.gor = Math.max(maxValues.gor, item[mode].value ?? 0);
    } else if (
      item[mode].symbol === 'm3/e3m3' ||
      item[mode].symbol === 'bbl/mmscf'
    ) {
      maxValues.xgr = Math.max(maxValues.xgr, item[mode].value ?? 0);
    } else {
      // ignore the rest
    }
  });

  /**
   * Round up to the nearest power of 10.
   */
  maxValues.liquid = Math.pow(10, Math.ceil(Math.log10(maxValues.liquid)));
  maxValues.gas = Math.pow(10, Math.ceil(Math.log10(maxValues.gas)));
  maxValues.gor = Math.pow(10, Math.ceil(Math.log10(maxValues.gor)));
  maxValues.xgr = Math.pow(10, Math.ceil(Math.log10(maxValues.xgr)));

  /**
   * If liquid or gas max values are greater than their defaults, increase both
   * to match. Other y-axes don't have to be adjusted.
   */
  const defaultMaxLiquidValue = getLiquidRange(mode).max;
  const defaultMaxGasValue = getGasRange(mode).max;

  let magnitudeOvershoot = 0;

  if (maxValues.liquid > defaultMaxLiquidValue) {
    magnitudeOvershoot = Math.max(
      magnitudeOvershoot,
      Math.log10(maxValues.liquid / defaultMaxLiquidValue)
    );
  } else {
    maxValues.liquid = defaultMaxLiquidValue;
  }

  if (maxValues.gas > defaultMaxGasValue) {
    magnitudeOvershoot = Math.max(
      magnitudeOvershoot,
      Math.log10(maxValues.gas / defaultMaxGasValue)
    );
  } else {
    maxValues.gas = defaultMaxGasValue;
  }

  if (magnitudeOvershoot > 0) {
    maxValues.liquid = Math.pow(
      10,
      Math.log10(defaultMaxLiquidValue) + magnitudeOvershoot
    );
    maxValues.gas = Math.pow(
      10,
      Math.log10(defaultMaxGasValue) + magnitudeOvershoot
    );
  }

  return maxValues;
}

export function getDefaultRange(mode: ProductionChartMode): {
  min: number;
  max: number;
} {
  switch (mode) {
    case ProductionChartMode.CalendarDaily:
    case ProductionChartMode.ProducingDaily:
      return {
        min: 0.1,
        max: Number.MAX_SAFE_INTEGER, // will not be used
      };
    default:
      return {
        min: 1,
        max: Number.MAX_SAFE_INTEGER, // will not be used
      };
  }
}

export function getLiquidRange(mode: ProductionChartMode): {
  min: number;
  max: number;
} {
  switch (mode) {
    case ProductionChartMode.CalendarDaily:
    case ProductionChartMode.ProducingDaily:
      return { min: 0.1, max: 10e3 };
    case ProductionChartMode.Monthly:
      return { min: 1, max: 100e3 };
    case ProductionChartMode.Cumulative:
      return { min: 1, max: 1e6 };
  }
}

export function getGasRange(mode: ProductionChartMode): {
  min: number;
  max: number;
} {
  switch (mode) {
    case ProductionChartMode.CalendarDaily:
    case ProductionChartMode.ProducingDaily:
      return { min: 0.1, max: 10e3 };
    case ProductionChartMode.Monthly:
      return { min: 1, max: 100e3 };
    case ProductionChartMode.Cumulative:
      return { min: 10, max: 10e6 };
  }
}

export function getRangeFn(
  unit: SIUnit
): (mode: ProductionChartMode) => { min: number; max: number } {
  if (isLiquidProductionUnit(unit)) {
    return getLiquidRange;
  } else if (isGasProductionUnit(unit)) {
    return getGasRange;
  } else {
    return getDefaultRange;
  }
}

import React from 'react';
import { DisplayMode, isDisplayMode } from '../types';

export function useDisplayMode(initDisplayMode: string | undefined) {
  const [displayMode, setDisplayMode] = React.useState(
    isDisplayMode(initDisplayMode) ? initDisplayMode : 'chart'
  );

  const handleChangeDisplayMode = React.useCallback(
    (newDisplayMode: DisplayMode) => {
      setDisplayMode(newDisplayMode);
    },
    []
  );

  return { displayMode, handleChangeDisplayMode };
}

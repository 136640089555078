import type { DataItemId } from '@pn/core/domain/data';
import { type WorkspaceItem } from '@pn/core/domain/workspace';
import { createSlice } from '@reduxjs/toolkit';
import { uniq } from 'lodash-es';
import { defaultWorkspace } from './defaultWorkspaces';
import { moduleItemIds, pnWorkspaceItems } from './pnWorkspaceItems';
import {
  _replace,
  add,
  addLayer,
  addToWorkspace,
  create,
  disableBubbleMapping,
  enableBubbleMapping,
  markAsLoaded,
  markAsProcessed,
  markAsRendered,
  markAsUnfinalized,
  overrideLimit,
  overwrite,
  remove,
  removeAreasFromWorkspace,
  removeFromWorkspace,
  removeLayer,
  removeStackOriginatedItems,
  resetInternalState,
  revisualize,
  select,
  setCheckedIds,
  setRequestedDataItemId,
  setWorkspace,
  share,
  unsetRequestedDataItemId,
  unshare,
  update,
  updateAllLayerStyles,
  updateCounts,
  updateFilterPropertyOptions,
  updateFiltering,
  updateInvalidDataItemIds,
  updateIsFetching,
  updateIsFetchingStack,
  updateLayerStyle,
  updateMapping,
  updateName,
  updateNumberOfElements,
  updatePage,
  updateQueryProperty,
  updateRequestedIds,
  updateSorts,
  updateTableDataItems,
  updateVisibility,
} from './workspaceSliceReducers';

export type State = {
  isFetching: boolean;
  isFetchingStack: boolean;
  allWorkspaceItems: WorkspaceItem[];
  stackWorkspaceItems: WorkspaceItem[];
  idsInWorkspace: WorkspaceItem['id'][];
  workspaceItemIdSelected: WorkspaceItem['id'] | undefined;
  invalidDataItemIds: DataItemId[];
};

const initialState: State = {
  isFetching: false,
  isFetchingStack: false,
  allWorkspaceItems: pnWorkspaceItems,
  stackWorkspaceItems: [],
  idsInWorkspace: uniq([...moduleItemIds, ...defaultWorkspace]),
  workspaceItemIdSelected: undefined,
  invalidDataItemIds: [],
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    add,
    create,
    update,
    overwrite,
    remove,
    removeStackOriginatedItems,
    removeAreasFromWorkspace,
    share,
    unshare,
    updateInvalidDataItemIds,
    updateIsFetching,
    updateIsFetchingStack,
    updateQueryProperty,
    updateFilterPropertyOptions,
    addLayer,
    removeLayer,
    updateLayerStyle,
    updateAllLayerStyles,
    enableBubbleMapping,
    disableBubbleMapping,
    updateNumberOfElements,
    updateName,
    addToWorkspace,
    removeFromWorkspace,
    setWorkspace,
    select,
    updateCounts,
    updateVisibility,
    updateMapping,
    updateTableDataItems,
    setRequestedDataItemId,
    unsetRequestedDataItemId,
    markAsLoaded,
    markAsProcessed,
    markAsUnfinalized,
    markAsRendered,
    revisualize,
    resetInternalState,
    updateSorts,
    updateFiltering,
    updatePage,
    setCheckedIds,
    updateRequestedIds,
    overrideLimit,
    _replace,
  },
});

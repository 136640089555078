import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { UnitSystem } from '@pn/core/domain/types';
import { appActions, useAppStorage } from '@pn/core/storage';
import { capitalize } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  formControlTypography: {
    lineHeight: 'unset',
  },
}));

const options = Object.values(UnitSystem).map((unitSystem) => ({
  value: unitSystem,
  label: capitalize(unitSystem),
}));

type Props = {
  disabled: boolean;
};

export function UnitsSwitch({ disabled }: Props) {
  const { classes } = useStyles();

  const { unitSystem } = useAppStorage();

  const handleChange = (_event: any, newUnitSystem: string) => {
    appActions().updateUnitSystem(newUnitSystem as UnitSystem);
  };

  return (
    <FormControl fullWidth disabled={disabled}>
      <FormLabel id="units-switch-label">Units</FormLabel>
      <RadioGroup
        aria-labelledby="units-switch-label"
        name="units-switch"
        value={unitSystem}
        onChange={handleChange}
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio size="small" />}
            label={label}
            slotProps={{
              typography: {
                className: classes.formControlTypography,
                variant: 'body2',
              },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

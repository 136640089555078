import robotoWoff2 from '@fontsource/roboto/files/roboto-latin-300-normal.woff2?url'; // import the font using Vite's asset handling
import React from 'react';

/**
 * Currently, only Roboto 300 is suffering from a flash of unstyled text.
 * https://fontsource.org/docs/getting-started/preload
 */
export function usePreloadFonts() {
  React.useLayoutEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'font';
    link.href = robotoWoff2;
    link.type = 'font/woff2';
    link.crossOrigin = 'anonymous';

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);
}

import LockIcon from '@mui/icons-material/Lock';
import {
  Alert,
  Avatar,
  Box,
  Collapse,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
  colors,
} from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import type { SearchResult } from '@pn/core/domain/search';
import { handleSearchResult } from '@pn/core/operations/search';
import { useAccess } from '@pn/core/permissions/access';
import { useCurrentUserStorage, useSearchStorage } from '@pn/core/storage';
import { isCordova, isFreeCordova } from '@pn/core/utils/env';
import { desaturateColor } from '@pn/services/utils/color';
import { groupBy, isEmpty, isNil, sortBy } from 'lodash-es';
import { useAuthenticationService } from 'src/application/externalDependencies';
import { getSearchbarWidth, searchbarParentId } from 'src/ui/layout/Searchbar';
import { makeStyles } from 'tss-react/mui';
import PlaceIcon from '@mui/icons-material/Place';

const useStyles = makeStyles<{ maxWidth: number }>()((theme, { maxWidth }) => ({
  paper: {
    width: '100%',
    minWidth: 255,
    maxWidth,
    backgroundImage: 'initial', // undo the pseudo-elevation in dark mode
  },
  listSubheader: {
    paddingTop: theme.spacing(1.5),
    lineHeight: '32px',
  },
  menuItem: {
    maxHeight: 48,
  },
  listItemAvatar: {
    minWidth: 48,
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.action.hover,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      lineHeight: 1.25,
    },
    '& .MuiListItemText-secondary': {
      lineHeight: 1.25,
    },
  },
  lockIcon: {
    position: 'relative',
    top: 2,
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
    color: theme.palette.text.disabled,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  link: {
    color: theme.palette.info.main,
    fontWeight: 500,
    textDecoration: 'underline',
    '&:hover, &:focus, &:active': {
      color: theme.palette.info.dark,
    },
  },
}));

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const SearchResults = ({ isOpen, setIsOpen }: Props) => {
  const { classes, cx } = useStyles({
    maxWidth: getSearchbarWidth(),
  });

  const access = useAccess();
  const { isAuthenticated } = useAuthenticationService();

  const { user } = useCurrentUserStorage();
  const { searchResults } = useSearchStorage();
  const groupedSearchResults = groupBy(searchResults, 'dataType');

  const showAccessAlert = Object.keys(groupedSearchResults)
    .filter((dataType) => dataType !== 'undefined')
    .some((dataType) => access('allData', dataType).denied());

  const anchorEl = window.document.getElementById(searchbarParentId);

  const handleClick = (searchResult: SearchResult) => {
    setIsOpen(false);
    handleSearchResult(searchResult);

    /* Hide keyboard on iOS */
    if (isCordova() && window.Keyboard) {
      setTimeout(
        () => window.document.getElementById('search-input')?.blur(),
        0
      );
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: classes.paper,
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      TransitionComponent={Collapse}
    >
      {isEmpty(groupedSearchResults) ? (
        <Box textAlign="center" px={2} py={1}>
          <Typography color="textSecondary">No results found</Typography>
        </Box>
      ) : (
        Object.keys(groupedSearchResults)
          .filter((dataType) =>
            isFreeCordova(user)
              ? ['undefined', 'wells', 'wells_usa'].includes(dataType)
              : true
          )
          .map((dataType) => {
            const disabled =
              dataType !== 'undefined'
                ? access('allData', dataType).denied()
                : false;

            return [
              <ListSubheader
                key={dataType}
                className={cx(classes.listSubheader, {
                  [classes.disabled]: disabled,
                })}
              >
                {dataType === 'undefined'
                  ? 'Locations'
                  : formatDataType(dataType, { case: 'sentence' })}
                {disabled && <LockIcon className={classes.lockIcon} />}
              </ListSubheader>,
              sortBy(groupedSearchResults[dataType], 'province').map(
                (searchResult, index) => {
                  const { province, label, secondaryLabel } =
                    getSearchResultLabel(searchResult);

                  const { color, backgroundColor } =
                    getProvinceColors(province);

                  return (
                    <MenuItem
                      key={dataType + index}
                      className={classes.menuItem}
                      disabled={disabled}
                      onClick={() => handleClick(searchResult)}
                    >
                      <ListItemAvatar className={classes.listItemAvatar}>
                        <Avatar
                          variant="rounded"
                          className={classes.avatar}
                          style={{ backgroundColor, color }}
                        >
                          {isNil(province) ? (
                            <PlaceIcon fontSize="small" />
                          ) : (
                            province
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        className={classes.listItemText}
                        primary={label}
                        secondary={secondaryLabel}
                      />
                    </MenuItem>
                  );
                }
              ),
            ];
          })
      )}
      {showAccessAlert && !isCordova() && (
        <Box px={2} py={1}>
          <Alert severity="warning">
            Please{' '}
            <a
              href="https://about.petroninja.com/plans"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {isAuthenticated ? 'upgrade' : 'sign up'}
            </a>{' '}
            {isAuthenticated ? 'to' : 'for'} a Professional or Enterprise
            account for full access.
          </Alert>
        </Box>
      )}
    </Menu>
  );
};

function getSearchResultLabel(searchResult: SearchResult) {
  if (searchResult.resultType === 'location') {
    return {
      province: undefined,
      label: searchResult.label,
      secondaryLabel: undefined,
    };
  } else {
    let secondaryLabel;
    if (searchResult.dataType === 'wells') {
      secondaryLabel = 'Licence # ' + searchResult.secondaryLabel;
    }

    return {
      province: searchResult.province,
      label: searchResult.label,
      secondaryLabel,
    };
  }
}

function getProvinceColors(province: string | undefined) {
  switch (province) {
    case 'AB':
      return {
        backgroundColor: colors.blue[50],
        color: desaturateColor(colors.blue[700], 0.5),
      };
    case 'BC':
      return {
        backgroundColor: colors.teal[50],
        color: desaturateColor(colors.teal[700], 0.5),
      };
    case 'MB':
      return {
        backgroundColor: colors.purple[50],
        color: desaturateColor(colors.purple[700], 0.5),
      };
    case 'SK':
      return {
        backgroundColor: colors.orange[50],
        color: desaturateColor(colors.orange[900], 0.5),
      };
    default:
      return {
        backgroundColor: colors.grey[200],
        color: 'rgba(0, 0, 0, 0.7)',
      };
  }
}

import { Stack } from '@datastructures-js/stack';
import { cloneDeep, isNil } from 'lodash-es';

export class HistoryManager<T> {
  private initSnapshot: T;
  private undoStack = new Stack<T>();
  private redoStack = new Stack<T>();

  constructor(snapshot: T) {
    this.initSnapshot = cloneDeep(snapshot);
  }

  reinit(snapshot: T): void {
    this.initSnapshot = cloneDeep(snapshot);
    this.undoStack.clear();
    this.redoStack.clear();
  }

  add(snapshot: T): void {
    this.undoStack.push(cloneDeep(snapshot));
    this.redoStack.clear(); // clear the redo stack whenever a new snapshot is added

    // console.trace('HistoryManager.add');
  }

  undo(): T | undefined {
    if (this.undoStack.isEmpty()) return undefined;

    const snapshot = this.undoStack.pop();
    if (!isNil(snapshot)) this.redoStack.push(snapshot);
    return cloneDeep(this.undoStack.peek() ?? this.initSnapshot);
  }

  redo(): T | undefined {
    if (this.redoStack.isEmpty()) return undefined;

    const snapshot = this.redoStack.pop();
    if (!isNil(snapshot)) this.undoStack.push(snapshot);
    return cloneDeep(snapshot);
  }

  peek(): T {
    return cloneDeep(this.undoStack.peek() ?? this.initSnapshot);
  }

  length(): number {
    return this.undoStack.size();
  }
}

import type { Enterprise } from '@pn/core/domain/enterprise';
import type { IEnterprisesProvider } from '@pn/core/providers/enterprise/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiEnterpriseMapper } from './apiEnterpriseMapper';
import type { ApiEnterprise } from './types';

export const requestEnterprises = async () => {
  const apiEnterprises = await pnApiClient.request<ApiEnterprise[]>({
    url: 'v2/admin/enterprises',
  });

  const enterprises = apiEnterprises.map(
    apiEnterpriseMapper.toDomainEnterprise
  );

  return enterprises;
};

export const requestEnterpriseById = async (enterpriseId: Enterprise['id']) => {
  const apiEnterprise = await pnApiClient.request<ApiEnterprise>({
    url: `v1/enterprises/${enterpriseId}`,
  });

  const enterprise = apiEnterpriseMapper.toDomainEnterprise(apiEnterprise);

  return enterprise;
};

export const apiEnterpriseProvider: IEnterprisesProvider = {
  getEnterpriseById: requestEnterpriseById,
  getEnterprises: requestEnterprises,
};

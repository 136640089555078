import { colors } from '@mui/material';
import { amber } from '@mui/material/colors';
import { useCliCommands } from '@pn/core/operations/cli';
import ReactTerminal from 'react-console-emulator';

export function Terminal() {
  const commands = useCliCommands();

  return (
    <ReactTerminal
      autoFocus
      commands={commands}
      welcomeMessage="Welcome to Petro Ninja Terminal! Type 'help' to get a list of commands."
      promptLabel=">"
      style={{
        minHeight: '500px',
        borderRadius: 0,
        backgroundColor: colors.grey[900],
      }}
      contentStyle={{ color: '#FFF' }}
      inputTextStyle={{ color: amber[300] }}
      promptLabelStyle={{
        color: amber[300],
        paddingTop: '0px',
        fontSize: '16px',
      }}
    />
  );
}

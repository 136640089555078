import { Box, colors, InputAdornment, Popover, TextField } from '@mui/material';
import { MapMode } from '@pn/core/domain/map';
import { useMapStorage } from '@pn/core/storage';
import { DEFAULT_COLOR_HEX } from '@pn/services/styles';
import Color from 'color';
import { isNil } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ColorBox } from './ColorBox';

export const SQ_SIZE = 28;
export const GAP = 6;

const allColors = [
  colors.purple[500],
  colors.deepPurple[500],
  colors.indigo[500],
  colors.blue[500],
  colors.lightBlue[500],
  colors.cyan[500],
  colors.teal[500],
  colors.green[500],
  colors.lightGreen[500],
  colors.lime[500],
  colors.yellow[500],
  colors.amber[500],
  colors.orange[500],
  colors.deepOrange[500],
  colors.red[500],
  colors.pink[500],
  colors.brown[500],
  colors.blueGrey[500],

  DEFAULT_COLOR_HEX.toLowerCase(),
  colors.grey[500],
  '#fff',
];

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    gap: GAP,
    alignItems: 'center',
    height: SQ_SIZE, // to sync with .colorBox height even when taller elements are present
  },
  divider: {
    width: 1,
    height: SQ_SIZE + 4,
    background: theme.borderColor,
    marginLeft: GAP,
    marginRight: GAP,
  },
}));

type Props = {
  disabled?: boolean;
  value: string;
  showTransparent?: boolean;
  onChange: (color: string) => void;
};

export const ColorPicker = ({
  disabled = false,
  value,
  showTransparent = false,
  onChange,
}: Props) => {
  const { classes } = useStyles();

  const { mapMode } = useMapStorage();

  const quickColors = [
    showTransparent
      ? 'transparent'
      : [MapMode.Dark, MapMode.Satellite].includes(mapMode)
        ? '#fff'
        : DEFAULT_COLOR_HEX.toLowerCase(),
    colors.deepPurple[500],
    colors.blue[500],
    colors.teal[500],
    colors.amber[500],
    colors.deepOrange[500],
    colors.pink[500],
  ];

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const [hex, setHex] = React.useState(Color(value).hex().replace('#', ''));
  const [hsl, setHsl] = React.useState({
    h: Math.round(Color(value).hue()),
    s: Math.round(Color(value).saturationl()),
    l: Math.round(Color(value).lightness()),
  });

  const handleClick = (color: string) => {
    setHex(Color(color).hex().replace('#', ''));
    setHsl({
      h: Math.round(Color(color).hue()),
      s: Math.round(Color(color).saturationl()),
      l: Math.round(Color(color).lightness()),
    });
    onChange(color);
  };

  const handleHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setHex(value);
    try {
      const color = Color('#' + value);
      setHsl({
        h: Math.round(color.hue()),
        s: Math.round(color.saturationl()),
        l: Math.round(color.lightness()),
      });
      onChange(color.hex());
    } catch (error) {
      // do nothing
    }
  };

  const handleHSLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = isFinite(parseInt(e.target.value))
      ? parseInt(e.target.value)
      : 0;
    setHsl((prev) => ({ ...prev, [name]: e.target.value }));
    switch (name) {
      case 'h':
        setHex(Color({ h: value, s: hsl.s, l: hsl.l }).hex().replace('#', ''));
        onChange(Color({ h: value, s: hsl.s, l: hsl.l }).hex());
        break;
      case 's':
        setHex(Color({ h: hsl.h, s: value, l: hsl.l }).hex().replace('#', ''));
        onChange(Color({ h: hsl.h, s: value, l: hsl.l }).hex());
        break;
      case 'l':
        setHex(Color({ h: hsl.h, s: hsl.s, l: value }).hex().replace('#', ''));
        onChange(Color({ h: hsl.h, s: hsl.s, l: value }).hex());
        break;
    }
  };

  return (
    <Box className={classes.container}>
      {quickColors.map((color) => (
        <ColorBox
          key={color}
          disabled={disabled}
          value={value}
          color={color}
          onClick={() => handleClick(color)}
        />
      ))}
      <Box className={classes.divider} />
      <ColorBox
        bold
        color={value}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />

      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={4}
        marginThreshold={24} // extra spacing on xs screens
        style={{ marginLeft: GAP * 2, marginTop: -14 }} // sx doesn't work here
        anchorEl={anchorEl}
        open={!isNil(anchorEl)}
        onClose={() => setAnchorEl(undefined)}
      >
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <Box
            display="flex"
            flexWrap="wrap"
            gap={GAP + 'px'}
            width={9 * SQ_SIZE + 8 * GAP}
          >
            {allColors
              .concat(showTransparent ? ['transparent'] : [])
              .map((color) => (
                <ColorBox
                  key={color}
                  disabled={disabled}
                  value={value}
                  color={color}
                  onClick={() => handleClick(color)}
                />
              ))}
          </Box>
          <Box display="flex" gap={1}>
            <TextField
              size="small"
              sx={{ width: 111, minWidth: 111 }}
              label="Hex"
              disabled={disabled}
              value={hex}
              onChange={handleHexChange}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  ),
                },
              }}
            />
            <Box display="flex" gap={1}>
              <TextField
                size="small"
                sx={{ width: 55, minWidth: 55 }}
                label="H"
                name="h"
                disabled={disabled}
                value={hsl.h}
                onChange={handleHSLChange}
              />
              <TextField
                size="small"
                sx={{ width: 55, minWidth: 55 }}
                label="S"
                name="s"
                disabled={disabled}
                value={hsl.s}
                onChange={handleHSLChange}
              />
              <TextField
                size="small"
                sx={{ width: 55, minWidth: 55 }}
                label="L"
                name="l"
                disabled={disabled}
                value={hsl.l}
                onChange={handleHSLChange}
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

import { dependencies } from '@pn/core/dependencies';
import { formatDataType } from '@pn/core/domain/data';
import type { GeoPoint } from '@pn/core/domain/geography';
import { getMapFeatureIds, MapFeature } from '@pn/core/domain/map';
import { DataSelectionReason } from '@pn/core/domain/query';
import { isNonStreamableItem } from '@pn/core/domain/workspace';
import { selectDataItem } from '@pn/core/operations/dataSelection';
import {
  useAppStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { getClosestFeatures } from '@pn/services/map/mapbox/mapboxUtils';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import {
  getSelectionItemId,
  useProcessSelection,
} from './mapSelectionProcessor';

export function useMapClicksProcessor() {
  const { pageSize } = useAppStorage();
  const { workspaceItemSelected, workspaceItems } = useWorkspaceStorage();

  const processSelection = useProcessSelection();

  return React.useCallback(
    (params: {
      features: MapFeature[];
      clickGeoPoint: GeoPoint;
      append: boolean;
    }) => {
      const { features, clickGeoPoint, append } = params;
      const { notificationService } = dependencies;

      if (isEmpty(features)) return;

      for (let i = workspaceItems.length - 1; i >= 0; i--) {
        const item = workspaceItems[i];

        const itemFeatures = features.filter((f) => f.itemId === item.id);
        const closestItemFeatures = getClosestFeatures(
          clickGeoPoint,
          itemFeatures,
          5
        );

        if (isEmpty(closestItemFeatures)) continue;

        const featureIds = getMapFeatureIds(closestItemFeatures);

        if (append) {
          if (isNil(workspaceItemSelected)) {
            return processSelection({
              featureIds,
              sourceItemId: item.dataType,
              append: true,
            });
          }

          if (item.dataType !== workspaceItemSelected.dataType) {
            notificationService.notify(
              `Only ${formatDataType(
                workspaceItemSelected.dataType
              )} can be added to current selection`
            );
            return;
          }

          const shouldExclude =
            item.id === getSelectionItemId(workspaceItemSelected.dataType);

          return processSelection({
            featureIds,
            sourceItemId: item.dataType,
            ...(shouldExclude ? { exclude: true } : { append: true }),
          });
        }

        selectDataItem({
          id: featureIds[0],
          reason: DataSelectionReason.MapClick,
          workspaceItem: item,
          pageSize,
        });

        if (isNonStreamableItem(item)) {
          workspaceActions().updateRequestedIds(item.id, {
            ids: featureIds,
            reason: DataSelectionReason.MapClick,
          });
        }

        return;
      }
    },
    [pageSize, workspaceItemSelected, workspaceItems, processSelection]
  );
}

import { LayerType } from '@pn/core/domain/layer';
import { DEFAULT_LIST_COLOR } from '@pn/services/styles/list';
import { isNil, isString } from 'lodash-es';
import { createWorkspaceItem } from './create';
import { getItemColor, type WorkspaceItem } from './workspaceItem';

export function updateSourceItem(
  item: WorkspaceItem,
  newSourceItem: WorkspaceItem
): WorkspaceItem {
  const color = getItemColor(item);

  const newItem = createWorkspaceItem(
    {
      source: 'item',
      sourceItem: newSourceItem,
      id: item.id,
      name: item.name,
      extraStyle: {
        color: isString(color) ? color : DEFAULT_LIST_COLOR,
      },
      queryOptions: {
        requestedIds: item.query.requestedIds,
        multiSelectionReason: item.query.multiSelectionReason,
        filters: item.query.filters,
        filtersLinkOperator: item.query.filtersLinkOperator,
      },
    },
    undefined
  );
  newItem.createdBy = item.createdBy;

  return newItem;
}

/**
 * Mutates the argument.
 */
export function enableBubbleMapping(
  item: WorkspaceItem,
  params: {
    radius: unknown[]; // must be an array
    color?: unknown;
  }
): void {
  const [firstLayer, ...otherLayers] = item.map.layers;
  firstLayer.type = LayerType.Circle;
  firstLayer.style = {
    radius: params.radius,
    color: params.color ? params.color : firstLayer.style.color,
    // strokeColor: '#FFF',
    // strokeWidth: 1
  };

  if (params.color) {
    otherLayers.forEach((layer) => {
      layer.style.color = params.color;
    });
  }
}

/**
 * Mutates the argument.
 */
export function disableBubbleMapping(item: WorkspaceItem): void {
  const firstLayer = item.map.layers[0];
  const firstSourceLayer = item.sourceItem?.map.layers[0];
  if (isNil(firstSourceLayer)) {
    /**
     * At this point we can assume it's a GeoJSON circle layer.
     */
    firstLayer.style = {
      color: firstLayer.style.color,
      radius: 8, // default radius
    };
  } else {
    firstLayer.type = firstSourceLayer.type;
    firstLayer.style = {
      ...firstSourceLayer.style,
      color: firstLayer.style.color,
    };
  }
}

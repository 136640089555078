import { dependencies } from '@pn/core/dependencies';
import type { PolyFeature } from '@pn/core/domain/drawing';
import { areClose, type Point } from '@pn/core/domain/point';
import { generateId } from '@pn/core/utils/id';
import {
  REFERENCE_PT,
  computeMapTransformation,
  drawFeature,
  getContext,
  scalePoint,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { generateFeatureMeasurements } from '@pn/services/drawing/measurement';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useRectangleTool(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const {
    liveCanvasRef,
    drawingMode,
    setDrawingMode,
    strokeColor,
    strokeWidth,
    fillColor,
    opacity,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const isSelecting = drawingMode === 'rectangle_select';
  const isDrawing = isDrawingPanelOpen && ['rectangle'].includes(drawingMode);

  useHotkeys(
    'esc',
    () => {
      if (isSelecting) setDrawingMode('select');
    },
    [isSelecting]
  );

  React.useEffect(() => {
    if (!isSelecting && !isDrawing) return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    const ctx = getContext(liveCanvasRef.current);

    let altKey = false;
    let startPoint = { x: 0, y: 0 };
    let point = { x: 0, y: 0 };

    const onKeyDownOrUp = (e: KeyboardEvent) => {
      if (e.key !== 'Alt' || e.repeat) return;

      /**
       * Prevents the browser from running the default Alt key behavior.
       * If this is not done, all subsequent events will be ignored.
       */
      e.preventDefault();

      altKey = e.type === 'keydown';

      if (drawingState.isCustomPanning || !drawingState.isDrawing) return;

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const feature: PolyFeature = {
        type: 'poly',
        subType: isSelecting ? 'rectangle_selection' : 'rectangle',
        id: generateId(),
        coordinates: calculateRectangleCoordinates({
          startPoint,
          point,
          centered: altKey,
        }),
        strokeColor: isSelecting ? 'hsl(200, 18%, 46%)' : strokeColor,
        strokeWidth: isSelecting ? 2 : strokeWidth,
        fillColor: isSelecting ? 'hsla(200, 18%, 46%, 0.15)' : fillColor,
        opacity,
        itemId,
        isVisible: true,
      };

      drawFeature(
        ctx,
        drawingState.displayMeasurements
          ? {
              ...feature,
              measurements: generateFeatureMeasurements(feature, {
                local: true,
              }),
            }
          : feature
      );
    };

    const onMouseDown = (e: mapboxgl.MapMouseEvent) => {
      if (drawingState.isCustomPanning) return;

      drawingState.isDrawing = true;

      startPoint = scalePoint(e.point);
      point = scalePoint(e.point);

      map.disableMovement();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (drawingState.isCustomPanning || !drawingState.isDrawing) return;

      const bbox = map._native.getContainer().getBoundingClientRect();
      point = scalePoint({
        x: e.clientX - bbox.left,
        y: e.clientY - bbox.top,
      });

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const feature: PolyFeature = {
        type: 'poly',
        subType: isSelecting ? 'rectangle_selection' : 'rectangle',
        id: generateId(),
        coordinates: calculateRectangleCoordinates({
          startPoint,
          point,
          centered: altKey,
        }),
        strokeColor: isSelecting ? 'hsl(200, 18%, 46%)' : strokeColor,
        strokeWidth: isSelecting ? 2 : strokeWidth,
        fillColor: isSelecting ? 'hsla(200, 18%, 46%, 0.15)' : fillColor,
        opacity,
        itemId,
        isVisible: true,
      };

      drawFeature(
        ctx,
        drawingState.displayMeasurements
          ? {
              ...feature,
              measurements: generateFeatureMeasurements(feature, {
                local: true,
              }),
            }
          : feature
      );
    };

    const onMouseUp = (e: MouseEvent) => {
      if (!drawingState.isDrawing) return;

      const bbox = map._native.getContainer().getBoundingClientRect();
      point = scalePoint({
        x: e.clientX - bbox.left,
        y: e.clientY - bbox.top,
      });

      if (areClose(startPoint, point, 2)) {
        drawingState.isDrawing = false;

        redraw();

        map.enableMovement();

        return;
      }

      const transformation = computeMapTransformation(REFERENCE_PT);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      drawingState.isDrawing = false;

      if (isSelecting) {
        const featureIdsDeleted: string[] = [];
        Object.values(drawingState.features).forEach((feature) => {
          if (
            feature.type === 'poly' &&
            feature.subType === 'rectangle_selection' &&
            feature.itemId === ''
          ) {
            delete drawingState.features[feature.id];
            delete drawingState.paths[feature.id];
            featureIdsDeleted.push(feature.id);
          }
        });
        drawingState.order = drawingState.order.filter(
          (id) => !featureIdsDeleted.includes(id)
        );
      }

      const id = generateId();
      const feature: PolyFeature = {
        id,
        type: 'poly',
        subType: isSelecting ? 'rectangle_selection' : 'rectangle',
        coordinates: calculateRectangleCoordinates({
          startPoint,
          point,
          centered: altKey,
        }).map((point) => transformPoint(point, inverseTransformation)),
        strokeColor: isSelecting ? 'hsl(200, 18%, 46%)' : strokeColor,
        strokeWidth: (isSelecting ? 2 : strokeWidth) / transformation.scale,
        fillColor: isSelecting ? 'hsla(200, 18%, 46%, 0.15)' : fillColor,
        opacity,
        itemId,
        isVisible: true,
      };

      drawingState.features[id] = drawingState.displayMeasurements
        ? {
            ...feature,
            measurements: generateFeatureMeasurements(feature),
          }
        : feature;
      drawingState.order.push(id);

      redraw();

      historyManager.add(drawingState);

      map.enableMovement();
    };

    mapboxMap.on('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('keydown', onKeyDownOrUp);
    document.addEventListener('keyup', onKeyDownOrUp);

    return () => {
      map.enableMovement();

      mapboxMap.off('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('keydown', onKeyDownOrUp);
      document.removeEventListener('keyup', onKeyDownOrUp);
    };
  }, [
    isDrawing,
    isSelecting,
    strokeColor,
    strokeWidth,
    fillColor,
    opacity,
    itemId,
    // the following never change:
    liveCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}

function calculateRectangleCoordinates(params: {
  startPoint: Point;
  point: Point;
  centered: boolean;
}): Point[] {
  const { startPoint, point, centered } = params;

  if (centered) {
    const dx = point.x - startPoint.x;
    const dy = point.y - startPoint.y;

    return [
      { x: startPoint.x - dx, y: startPoint.y - dy },
      { x: point.x, y: startPoint.y - dy },
      { x: point.x, y: point.y },
      { x: startPoint.x - dx, y: point.y },
      { x: startPoint.x - dx, y: startPoint.y - dy },
    ];
  } else {
    return [
      { x: startPoint.x, y: startPoint.y },
      { x: point.x, y: startPoint.y },
      { x: point.x, y: point.y },
      { x: startPoint.x, y: point.y },
      { x: startPoint.x, y: startPoint.y },
    ];
  }
}

import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  getGridBooleanOperators,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { findOrThrow } from '@pn/core/utils/logic';
import { CustomGridFilterInputDate } from '@pn/ui/data-table/components/CustomGridFilterInputDate';
import { CustomGridFilterInputValue } from '@pn/ui/data-table/components/CustomGridFilterInputValue';
import { notContainsOperator } from './customOperators';
import { isNil } from 'lodash-es';

const gridStringOperators = getGridStringOperators();
const gridDropdownOperators = getGridSingleSelectOperators();
const gridNumericOperators = getGridNumericOperators();
const gridDateOperators = getGridDateOperators();

const isNilOperator = {
  value: 'isNil',
  label: 'Is not set',
  headerLabel: 'Is not set',
  getApplyFilterFn: () => {
    return (value: unknown): boolean => {
      return isNil(value);
    };
  },
  requiresFilterValue: false,
};

export const defaultStringOperators: GridFilterOperator[] = [
  ...gridStringOperators
    .filter((operator) => ['contains'].includes(operator.value))
    .map((operator) => ({
      ...operator,
      InputComponent: CustomGridFilterInputValue,
    })),
  notContainsOperator,
  isNilOperator,
];

export const defaultDropdownOperators = gridDropdownOperators.filter(
  (operator) => ['is'].includes(operator.value)
);

export const defaultNumericOperators: GridFilterOperator[] = [
  ...['>=', '<=', '='].map((operatorId) => ({
    ...findOrThrow(gridNumericOperators, (o) => o.value === operatorId),
    InputComponent: CustomGridFilterInputValue,
  })),
  isNilOperator,
];

export const defaultDateOperators: GridFilterOperator<any, string, any>[] = [
  ...['onOrAfter', 'onOrBefore', 'is']
    .map((operatorId) =>
      findOrThrow(gridDateOperators, (o) => o.value === operatorId)
    )
    .map((operator) => ({
      ...operator,
      InputComponent: CustomGridFilterInputDate,
      /**
       * We override date filtering since we use strings rather than dates.
       * This only takes effect when the table is in client mode.
       *
       * May not work after upgrading to x-data-grid-pro v7 (not tested).
       */
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        return (value: unknown): boolean => {
          const a = Number(value);
          const b = Number(filterItem.value.replace(/-/g, ''));

          switch (value) {
            case 'is':
              return a === b;
            case 'onOrAfter':
              return a >= b;
            case 'onOrBefore':
              return a <= b;
            default:
              throw new Error(
                `Unsupported date filter operator: ${operator.value}`
              );
          }
        };
      },
    })),
  isNilOperator,
];

export const defaultBooleanOperators = [
  ...getGridBooleanOperators(),
  isNilOperator,
];

import { EXPORT_LIMIT } from '@pn/core/limits';

type IsExportDisabledProps = {
  isToolbarDisabled: boolean;
  totalCount: number;
};

export function checkIsExportDisabled({
  isToolbarDisabled,
  totalCount,
}: IsExportDisabledProps) {
  return isToolbarDisabled || totalCount === 0 || totalCount > EXPORT_LIMIT;
}

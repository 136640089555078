import { tableSlice } from '@pn/core/storage/table/tableSlice';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiTableTemplateMapper } from '@pn/services/api/table/apiTableTemplateMapper';
import type { ReducerSync } from '.';

const { actions } = tableSlice;

export const table: ReducerSync<typeof tableSlice.actions> = {
  create: async (tableTemplate, dispatch) => {
    const { id } = await pnApiClient.request<{ id: string }>({
      method: 'POST',
      url: `v1/reports`,
      payload: apiTableTemplateMapper.toTargetTemplatePayload(tableTemplate),
    });

    dispatch(actions.updateId({ currentId: tableTemplate.id, newId: id }));
  },
  remove: async (tableTemplate) => {
    return pnApiClient.request({
      method: 'DELETE',
      url: `v1/reports/${tableTemplate.id}`,
    });
  },
};

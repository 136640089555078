import { LayerType, isLayerSource } from '@pn/core/domain/layer';
import type { ILayerMapper } from '@pn/core/mappers/layer';
import assert from 'assert';
import { invert, pick } from 'lodash-es';
import { mapboxLayerStyleMapper } from './mapboxLayerStyleMapper';

export const mapboxLayerMapper: ILayerMapper<mapboxgl.Layer> = {
  toDomainLayer: (mapboxLayer, renderAsPoints = false) => {
    assert(isLayerSource(mapboxLayer.source), 'Not a valid layer source');

    return {
      id: mapboxLayer.id,
      type: toDomainLayerType(mapboxLayer as mapboxgl.AnyLayer),
      style: mapboxLayerStyleMapper.toDomainLayerStyle(
        pick(mapboxLayer, ['layout', 'paint'])
      ),
      renderAsPoints,
      source: mapboxLayer.source,
      sourceLayer: mapboxLayer['source-layer'],
      metadata: mapboxLayer.metadata,
    };
  },
  toTargetLayer: (layer) => {
    const mapboxLayer = {
      id: layer.id,
      type: layerTypeMapping[layer.type],
      source: layer.source,
      'source-layer': layer.sourceLayer,
      ...mapboxLayerStyleMapper.toTargetLayerStyle(layer.style, layer.type),
      metadata: layer.metadata,
    };

    return mapboxLayer;
  },
};

export const layerTypeMapping: Record<LayerType, mapboxgl.AnyLayer['type']> = {
  [LayerType.Line]: 'line',
  [LayerType.Polygon]: 'fill',
  [LayerType.Circle]: 'circle',
  [LayerType.Text]: 'symbol',
  [LayerType.Icon]: 'symbol',
  [LayerType.Raster]: 'raster',
  [LayerType.Mixed]: 'custom', // not used in a conventional way
};

function toDomainLayerType(mapboxLayer: mapboxgl.AnyLayer): LayerType {
  if (mapboxLayer.type === 'symbol') {
    return mapboxLayer.layout?.['text-field'] === '{symbol}' ||
      mapboxLayer.layout?.['icon-image']
      ? LayerType.Icon
      : LayerType.Text;
  }

  return invert(layerTypeMapping)[mapboxLayer.type] as LayerType;
}

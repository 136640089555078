import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { QuickInfoPanelProps } from 'src/ui/main-panel/components/QuickInfo';

export function LoadingQuickInfo(props: Omit<QuickInfoPanelProps, 'dataItem'>) {
  const { classes, onClose } = props;

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.wrapper}
      >
        <CircularProgress size={24} />
      </Box>
      <Box className={classes.actionWrapper}>
        <IconButton size="small" disabled onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

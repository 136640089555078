import type { DataItemId } from '@pn/core/domain/data';
import type { IDataInfoProvider } from '@pn/core/providers/data-info/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiDataInfoCountsMapper } from './apiDataInfoCountsMapper';
import { apiDataInfoDetailsMapper } from './apiDataInfoDetailsMapper';
import { apiDataInfoDocumentMapper } from './apiDataInfoDocumentMapper';
import type { ApiDataCounts, ApiDetails, ApiDocument } from './types';

const requestDataDetails = async (dataType: string, id: DataItemId) => {
  const apiDetails = await pnApiClient.request<ApiDetails>({
    url: `v2/${dataType}/${id}/details`,
  });

  const details = apiDataInfoDetailsMapper.toDomainDetails(apiDetails);

  return details;
};

const requestDataDocuments = async (dataType: string, id: DataItemId) => {
  const apiDocuments = await pnApiClient.request<ApiDocument[]>({
    url: `v2/${dataType}/${id}/documents`,
  });

  const documents = apiDocuments.map(
    apiDataInfoDocumentMapper.toDomainDocument
  );

  return documents;
};

const requestDataCounts = async (dataType: string, id: DataItemId) => {
  const apiCounts = await pnApiClient.request<ApiDataCounts>({
    url: `v2/${dataType}/${id}/counts`,
  });

  const counts = apiDataInfoCountsMapper.toDomainCounts(apiCounts);

  return counts;
};

const requestDataReport = async (dataType: string, id: DataItemId) => {
  let url: string;

  switch (dataType) {
    case 'wells':
      url = `v1/wells/${id}/detailed`;
      break;
    case 'wells_usa':
      url = `v1/usa/wells/${id}/detailed`;
      break;
    case 'mineral_rights':
      url = `v2/mineral_rights/${id}/details`;
      break;
    default:
      throw new Error('Unsupported report data type');
  }

  const apiReport = await pnApiClient.request({ url });

  return apiReport; // no domain mapping needed
};

export const apiDataInfoProvider: IDataInfoProvider = {
  getDataDetails: requestDataDetails,
  getDataDocuments: requestDataDocuments,
  getDataCounts: requestDataCounts,
  getDataReport: requestDataReport,
};

import { useAuth0, type LogoutOptions } from '@auth0/auth0-react';
import type { IAuthenticationService } from '@pn/core/services/authentication/ports';
import { isCordova } from '@pn/core/utils/env';
import { clearInvitationData } from '@pn/services/authentication/invitations';
import { clearPersistedStorageData } from '@pn/services/redux/persistence';

export function useLogout(): IAuthenticationService['logout'] {
  const { logout, buildLogoutUrl } = useAuth0();

  return (params) => {
    const args: LogoutOptions = {};

    if (params?.returnTo) {
      args.returnTo = params.returnTo;
    }

    clearInvitationData();
    clearPersistedStorageData();

    if (isCordova()) {
      // Remove auth0-spa-js entries (doesn't help with the logout problem)
      // for (const [key] of Object.entries(localStorage)) {
      //   if (key.includes('auth0spajs')) {
      //     localStorage.removeItem(key);
      //   }
      // }

      // Clear session storage (doesn't help with the logout problem)
      // sessionStorage.clear();

      // Clear cookies (doesn't help with the logout problem)
      // document.cookie.split(";").forEach((cookie) => {
      //   const eqPos = cookie.indexOf("=");
      //   const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
      //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      // });

      // NOTE args are currently used for returnTo prop that shouldn't be used on Cordova, so we omit them here
      const url = buildLogoutUrl();
      console.log('logout url built', url);
      const browser = window.cordova.InAppBrowser.open(
        url,
        '_blank',
        'location=no'
      );
      browser.addEventListener('loadstart', (event: any) => {
        console.log('loadstart event', event);
        if (event.url.includes(import.meta.env.VITE_APP_BASE_URL)) {
          console.log('closing the in-app browser');
          browser.close();

          console.log('window.location before logout', window.location);
          window.location.replace(window.location.href.split('#')[0]);

          // window.location.reload();
        }
      });
    } else {
      logout({ returnTo: window.location.origin, ...args });
    }
  };
}

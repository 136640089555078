import { geojsonToWKT, wktToGeoJSON } from '@terraformer/wkt';
import { isString } from 'lodash-es';
import { GeoShape, geoShapeToGeometry, geometryToGeoShape } from './geography';

export type DateString = string;
export function isDateString(arg: unknown): arg is DateString {
  return isString(arg) && /^\d{4}-\d{2}-\d{2}$/.test(arg);
}
export type DateTimeString = string;
export type ISODateTimeString = string;

export enum UnitSystem {
  Metric = 'metric',
  Imperial = 'imperial',
}

export type WKTString = string;
export function isWKTString(value: unknown): value is WKTString {
  if (!isString(value)) return false;

  return (
    value.startsWith('POINT') ||
    value.startsWith('LINESTRING') ||
    value.startsWith('POLYGON') ||
    value.startsWith('MULTIPOINT') ||
    value.startsWith('MULTILINESTRING') ||
    value.startsWith('MULTIPOLYGON')
  );
}

export function wktToGeoShape(wkt: WKTString): GeoShape {
  return geometryToGeoShape(wktToGeoJSON(wkt) as GeoJSON.Geometry);
}

export function geoShapeToWkt(geoShape: GeoShape): WKTString {
  return geojsonToWKT(geoShapeToGeometry(geoShape));
}

export const DateWindowDescriptions = [
  'last_two_days',
  'last_seven_days',
  'last_fourteen_days',
  'last_thirty_days',
  'last_three_months',
  'last_six_months',
  'last_year',
  'last_two_years',
  'last_three_years',
] as const;

export type DateWindowDescription = (typeof DateWindowDescriptions)[number];

export type DateWindowOption = {
  description: DateWindowDescription;
  startDate: DateString;
  endDate: DateString;
};

export const DateWindowLabel: Record<DateWindowDescription, string> = {
  last_two_days: 'Last 2 days',
  last_seven_days: 'Last 7 days',
  last_fourteen_days: 'Last 14 days',
  last_thirty_days: 'Last 30 days',
  last_three_months: 'Last 3 months',
  last_six_months: 'Last 6 months',
  last_year: 'Last year',
  last_two_years: 'Last 2 years',
  last_three_years: 'Last 3 years',
};

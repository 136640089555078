import { keyframes } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import stack_logo_white from '@pn/ui/images/StackDX_LOGO_White_TransparentBG.svg';
import stack_background_grid from '@pn/ui/images/stack_background_grid.png';
import { PetroNight } from '@pn/ui/theme/themes/PetroNight';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    height: 760,
    padding: theme.spacing(4),
    backgroundColor: '#03192b',
    backgroundImage: `url(${stack_background_grid})`,
    overflow: 'hidden',
  },
  stackLogo: {
    position: 'relative',
    left: -25,
    height: 100,
  },
  title: {
    maxWidth: 560,
    fontWeight: 700,
  },
  button: {
    minWidth: 155,
    height: 42,
    padding: '12px 36px',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    textTransform: 'none',
  },
  ul: {
    paddingLeft: theme.spacing(2),
  },
}));

const numberOfDots = Math.floor(Math.random() * 15) + 10; // random number between 15 and 25

export const StackDXMarketingPage = React.memo(() => {
  const { classes } = useStyles();

  return (
    <ThemeProvider theme={PetroNight}>
      <Paper variant="outlined" className={classes.container}>
        {[...Array(numberOfDots).keys()].map((number) => {
          const size = createRandomSize();
          const offset = createRandomOffset();
          const translation = createRandomTranslation();
          const duration = createRandomDuration();
          const delay = createRandomDelay();
          // const opacity = createRandomOpacity();
          const opacity = size ** 2 / 200;

          const animation = `${keyframes`
          0%, 100% {
            transform: translate(0, 0);
          }
          50% {
            transform: translate(${translation.x}px, ${translation.y}px);
          }
        `} ${duration}s ease-in-out ${delay}s infinite`;

          return (
            <Box
              key={number}
              // eslint-disable-next-line react/forbid-component-props
              sx={{
                position: 'absolute',
                width: size,
                height: size,
                backgroundColor: `rgba(255, 255, 255, ${opacity})`,
                borderRadius: '50%',
                left: `${offset.x}%`,
                top: `${offset.y}%`,
                animation,
              }}
            />
          );
        })}

        <img
          src={stack_logo_white}
          alt="StackDX logo"
          className={classes.stackLogo}
        />
        <Box maxWidth={720} mt={4}>
          <Typography variant="h3" className={classes.title}>
            The Data Platform for the Modern Energy Industry
          </Typography>

          <Box mt={4}>
            <Typography>
              StackDX eliminates repetitive back office tasks and allows your
              team to focus on higher value activities such as addressing
              exceptions and discrepancies.
            </Typography>

            <List>
              <ListItem disableGutters>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Automatic integration with Petro Ninja"
                  secondary="View your land layers and projects on the map without any manual effort"
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText
                  primary="All your data is digitized and catalogued"
                  secondary="Search, view automatically detected discrepancies, and exchange with your partners in one place"
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Access the most up-to-date public data"
                  secondary="Cross-check your land data for accuracy"
                />
              </ListItem>
            </List>
          </Box>

          <Box display="flex" gap={2} mt={2}>
            <Button
              component="a"
              href="https://www.stackdx.com"
              target="_blank"
              variant="contained"
              className={classes.button}
            >
              Learn more
            </Button>
            <Button
              component="a"
              href="mailto:hello@stackdx.com?subject=Demo request"
              variant="contained"
              className={classes.button}
            >
              Get a demo
            </Button>
          </Box>

          <Box mt={8} />
        </Box>
      </Paper>
    </ThemeProvider>
  );
});

StackDXMarketingPage.displayName = 'StackDXMarketingPage';

const createRandomSize = () => {
  const size = Math.floor(Math.random() * 4) + 6; // Random number between 6 and 10
  return size;
};

const createRandomOffset = () => {
  const x = Math.floor(Math.random() * 99) + 1; // Random number between 1 and 99
  const y = Math.floor(Math.random() * 99) + 1;
  return { x, y };
};

const createRandomTranslation = () => {
  const x =
    (Math.random() > 0.5 ? 1 : -1) * Math.floor(Math.random() * 200) + 100; // Random number between 100 and 300 (or negative)
  const y =
    (Math.random() > 0.5 ? 1 : -1) * Math.floor(Math.random() * 200) + 100; // Random number between 100 and 300 (or negative)
  return { x, y };
};

const createRandomDuration = () => {
  const duration = Math.floor(Math.random() * 25) + 75; // Random number between 75 and 100
  return duration;
};

const createRandomDelay = () => {
  const delay = -1 * Math.floor(Math.random() * 20); // Random number between 0 and -20
  return delay;
};

// const createRandomOpacity = () => {
//   const opacity = Math.floor(Math.random() * 25) + 15; // Random number between 15 and 40
//   return opacity / 100;
// };

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{
  areRowClicksEnabled: boolean;
  disableBorder: boolean;
}>()((theme, { areRowClicksEnabled, disableBorder }) => ({
  root: {
    '& .MuiDataGrid-row': {
      cursor: areRowClicksEnabled ? 'pointer' : 'initial',
    },
    '& .MuiDataGrid-cell': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-toolbarContainer': {
      padding: theme.spacing(1.5),
      paddingBottom: theme.spacing(0),
    },
    // '& .MuiDataGrid-footerContainer': {
    //   borderTop: `1px solid ${theme.palette.divider}`,
    // },
    border: disableBorder ? 'none' : `1px solid ${theme.palette.divider}`,
  },
}));

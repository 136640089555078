export function useTableControlsDisabled(props: {
  isAuthenticating: boolean;
  isFetchingCurrentUser: boolean;
  isTableLoading: boolean;
  isMappingInitialized: boolean;
}) {
  return (
    props.isAuthenticating ||
    props.isFetchingCurrentUser ||
    props.isTableLoading ||
    !props.isMappingInitialized
  );
}

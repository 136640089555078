import { CorporateQuarterlyStatistic } from '@pn/core/domain/intel/corporate-production';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';

type InitialState = {
  isFetching: boolean;
  isError: boolean;
  stats: CorporateQuarterlyStatistic[];
};

const initialState: InitialState = {
  isFetching: false,
  isError: false,
  stats: [],
};

export const createCompanyQuarterlyStatsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.stats = payload;
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.stats = [];
      },
      add: (state, { payload }: PayloadAction<CorporateQuarterlyStatistic>) => {
        state.stats = [...state.stats, payload];
      },
      update: (
        state,
        {
          payload,
        }: PayloadAction<{
          statId: CorporateQuarterlyStatistic['id'];
          stat: CorporateQuarterlyStatistic;
        }>
      ) => {
        state.stats = state.stats.map((stat) =>
          stat.id === payload.statId ? payload.stat : stat
        );
      },
      remove: (
        state,
        { payload }: PayloadAction<CorporateQuarterlyStatistic['id']>
      ) => {
        console.log('payload', payload);
        console.log('state.stats', state.stats);

        state.stats = state.stats.filter((stat) => stat.id !== payload);
      },
      _updateId: (
        state,
        {
          payload,
        }: PayloadAction<{
          currentId: string | number;
          newId: CorporateQuarterlyStatistic['id'];
        }>
      ) => {
        const stat = state.stats.find(({ id }) => id === payload.currentId);
        if (isNil(stat))
          throw new Error('Unable to update ID of an undefined stat');
        state.stats.find(({ id }) => id === payload.currentId)!.id =
          payload.newId;
      },
    },
  });

export const companyQuarterlyStatsSlice = createCompanyQuarterlyStatsSlice(
  'companyQuarterlyStats'
);

export const mapboxSatellite = {
  id: 'mapbox-satellite',
  type: 'raster',
  source: 'mapbox://mapbox.satellite',
  layout: {
    visibility: 'none',
  },
  paint: {
    'raster-fade-duration': 0,
  },
};
import type { GridMapboxStyle } from './styles';

export const gridsUnit = (style: GridMapboxStyle) => [
  {
    minzoom: 11,
    layout: {
      'text-field': ['get', 'UNIT_ID'],
      'text-size': style.textSize,
      'text-ignore-placement': false,
      'text-allow-overlap': false,
      visibility: 'visible',
    },
    type: 'symbol',
    source: 'composite',
    id: 'nts-unit-labels',
    paint: {
      'text-color': style.textColor,
      ...(style.textHaloColor &&
        style.textHaloWidth && {
          'text-halo-color': style.textHaloColor,
          'text-halo-width': style.textHaloWidth,
        }),
    },
    'source-layer': 'NTS_UNITS_CENTERS',
  },
  {
    id: 'nts-unit-grid',
    minzoom: 11,
    type: 'line',
    paint: {
      'line-color': style.lineColor,
      'line-width': style.lineWidth,
    },
    source: 'composite',
    'source-layer': 'NTS_UNITS_GRID',
    layout: {
      visibility: 'visible',
    },
  },
];

import type { DataItemId } from '@pn/core/domain/data';
import type { Column } from '@pn/core/domain/data-info';
import { hasKey } from '@pn/core/utils/logic';
import { isObject } from 'lodash-es';

export type ApiKeyValueRow = {
  key: string;
  value: unknown;
  type: string;
  units?: string;
  __url?: string;
};
type ApiMultiColumnRow = Record<string, unknown>;

export type ApiColumn = {
  field: string;
  value: string;
  type: string;
  units?: string;
  module?: Column['module']; // identical to domain
};

type ApiZonalQuery = {
  substance: string;
  upper:
    | {
        entity: string;
        boundary: 'top' | 'base';
      }
    | {
        entity: 'SURFACE';
        boundary: null;
      };
  lower:
    | {
        entity: string;
        boundary: 'top' | 'base';
      }
    | {
        entity: 'BASEMENT';
        boundary: null;
      };
  excluded: boolean;
};

type ApiFormationsViewerModule = {
  type: 'formations_viewer';
  province?: 'ab' | 'bc' | 'sk'; // TODO mark as required when API is updated
  zones: ApiZonalQuery[];
};

export type ApiTable = {
  title: string;
  tableType?: string;
  professional?: boolean;
  noExport?: boolean;
  disablePagination?: boolean;
  module?: ApiFormationsViewerModule;
};
type ApiKeyValueTable = ApiTable & {
  rows: ApiKeyValueRow[];
};
export function isApiKeyValueTable(arg: unknown): arg is ApiKeyValueTable {
  return isObject(arg) && !hasKey(arg, 'columns');
}
export type ApiMultiColumnTable = ApiTable & {
  rows: ApiMultiColumnRow[];
  columns: ApiColumn[];
  transpose?: boolean;
};
export function isApiMultiColumnTable(
  arg: unknown
): arg is ApiMultiColumnTable {
  return isObject(arg) && hasKey(arg, 'columns');
}
type ApiLinkedIdsTable = ApiTable & {
  rows: ApiMultiColumnRow[];
  columns: ApiColumn[];
  linkedCoordinates: [number, number][];
  linkedIds: DataItemId[];
  linkedIdsType: string;
};
export function isApiLinkedIdsTable(arg: unknown): arg is ApiLinkedIdsTable {
  return (
    isObject(arg) && hasKey(arg, 'linkedIds') && hasKey(arg, 'linkedIdsType')
  );
}

export type ApiDetailsTable =
  | ApiKeyValueTable
  | ApiMultiColumnTable
  | ApiLinkedIdsTable;

export type ApiDetails = {
  tabName: string;
  tables: ApiDetailsTable[];
}[];

export type ApiDocument = {
  id?: string; // may no longer be optional but there's no way to check
  fileKey: string;
  file_name: string;
  folder?: string;
  type: string;
};

export type ApiDataCounts = {
  document_count: number;
};

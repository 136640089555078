import { ApiError, ConfigurationError } from '@pn/core/errors';
import { tokenManager } from '@pn/core/services/authentication/tokenManager';
import type {
  ApiRequestParams,
  IApiClient,
} from '@pn/core/services/http/ports';
import { isEmbedded } from '@pn/core/utils/embedded';
import env from '@pn/core/utils/env';
import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { drop, isArray, isNil, isNumber, isObject } from 'lodash-es';
import 'web-streams-polyfill';
import { NDJsonParser } from './ndJsonParser';
import { isFormData } from './utils';

const { PN_API_URL, PN_API_KEY } = env;

/**
 * @throws {@link ConfigurationError} if the environment variables are misconfigured
 * @throws {@link ApiError} if API returns non-OK status
 */
export async function makeApiRequest<T>({
  domain = 'pn',
  method = 'GET',
  url,
  payload,
  responseType,
  hostname,
  withCredentials = false,
}: ApiRequestParams): Promise<T> {
  if (!PN_API_URL || !PN_API_KEY) {
    throw new ConfigurationError('API environment variables are not set');
  }

  const headers: RawAxiosRequestHeaders = {
    'Content-Type': isFormData(payload)
      ? 'multipart/form-data'
      : 'application/json',
    'X-Api-Key': PN_API_KEY,
  };

  if (!isEmbedded()) {
    try {
      const { token, domain: tokenDomain } = await tokenManager.get()();
      if (tokenDomain === domain) {
        headers['Authorization'] = `Bearer ${token}`;
      }
    } catch (error) {
      // this error is thrown when a user is not logged in
    }
  }

  const baseUrl = !isNil(hostname) ? hostname : PN_API_URL;

  const requestConfig: AxiosRequestConfig = {
    url: baseUrl + url,
    method,
    headers,
    withCredentials,
    responseType,
  };

  if (method === 'GET') {
    requestConfig.params = payload;
  }
  if (['POST', 'PUT', 'DELETE'].includes(method)) {
    requestConfig.data = payload;
  }

  try {
    const response = await axios.request<T>(requestConfig);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status ?? 0;
      throw new ApiError(
        `PN API error caught: ${status}`,
        status,
        method + ' ' + url,
        error.response?.data
      );
    } else {
      throw error;
    }
  }
}

export async function streamFromApi({
  method = 'GET',
  url,
  payload,
  raw = false,
  signal,
  onReceiveDataChunk,
  onReceiveTotalCount,
}: ApiRequestParams & { raw?: boolean }) {
  if (!PN_API_URL || !PN_API_KEY) {
    throw new ConfigurationError('Environment file is missing API values.');
  }

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-Api-Key': PN_API_KEY,
  });

  if (!isEmbedded()) {
    try {
      // curently no domain check is performed
      const { token } = await tokenManager.get()();
      tokenManager.setCached(token);
      headers.set('Authorization', `Bearer ${token}`);
    } catch (error) {
      // this error is thrown when a user is not logged in
    }
  }

  const options = {
    method,
    headers,
    body: JSON.stringify(payload),
    signal,
  };

  try {
    const response = await fetch(PN_API_URL + url, options);
    if (!response || !response.body || !response.ok) {
      if (response.status === 400) {
        const json = await response.json();
        if (!isNil(onReceiveTotalCount)) onReceiveTotalCount(json.count);
        return;
      } else {
        throw new ApiError('Streaming error', 1000, method + ' ' + url, {
          url: PN_API_URL + url,
          method,
        });
      }
    }

    const reader = response.body!.getReader();
    const ndJsonParser = new NDJsonParser();
    const textDecoder = new TextDecoder();

    let done: boolean | undefined;
    let value: Uint8Array | undefined;
    while ((({ value, done } = await reader.read()), !done)) {
      const chunk = textDecoder.decode(value);
      const chunkData = raw ? chunk : ndJsonParser.parseChunk(chunk);

      if (
        !isNil(onReceiveTotalCount) &&
        !isNil(onReceiveDataChunk) &&
        isArray(chunkData) &&
        isObject(chunkData[0]) &&
        'total_count' in chunkData[0] &&
        isNumber(chunkData[0].total_count)
      ) {
        /**
         * Extract total_count from the first JSON object in the chunk and pass
         * it along with the rest of the chunk data to relevant callbacks.
         */
        onReceiveTotalCount(chunkData[0].total_count);
        onReceiveDataChunk(drop(chunkData));
      } else if (!isNil(onReceiveDataChunk)) {
        onReceiveDataChunk(chunkData);
      }
    }
  } catch (error) {
    if (signal?.aborted) return;

    console.error(error);
    throw new ApiError('Streaming error', 1001, method + ' ' + url, {
      url: PN_API_URL + url,
      method,
      errorMessage: (error as Error).message,
    });
  }
}

export const pnApiClient: IApiClient = {
  request: makeApiRequest,
  stream: streamFromApi,
};

import { Alert, Link, Typography } from '@mui/material';
import type { User } from '@pn/core/domain/user';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { getStyleEditability } from '@pn/core/permissions/editability';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  link: {
    cursor: 'pointer',
  },
}));

type Props = {
  item: WorkspaceItem;
  user: User | undefined;
  onDuplicate: () => void;
};

export const StylingAlert = ({ item, user, onDuplicate }: Props) => {
  const { classes } = useStyles();

  const editability = getStyleEditability(item, user);

  switch (editability) {
    case 'full_editability':
      return null;
    case 'no_editability':
      return <Alert severity="warning">This layer cannot be restyled.</Alert>;
    case 'duplicate_only': {
      return (
        <Alert severity="warning">
          <Typography variant="body2">
            This layer can only be restyled by its author.
            <br />
            You can{' '}
            <Link className={classes.link} onClick={onDuplicate}>
              save a copy
            </Link>{' '}
            with your changes.
          </Typography>
        </Alert>
      );
    }
  }
};

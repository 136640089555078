import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createIntelReportData,
  IntelReport,
  IntelReportData,
  IntelReportProduction,
} from '@pn/core/domain/intel/intel-report.ts';
import { IntelActivityItem } from '@pn/core/domain/intel/intel-activity-item.ts';

export type IntelReportsState = {
  isFetching: boolean;
  isEditingReport: boolean;
  reports: IntelReport[];
  reportsData: IntelReportData[];
  selectedReportId: string;
  loadedReportIds: IntelReport['id'][];
};

const initialState: IntelReportsState = {
  isFetching: false,
  isEditingReport: false,
  reports: [],
  reportsData: [],
  selectedReportId: '',
  loadedReportIds: [],
};

export const createReportsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
      },
      receive: (state, { payload }: PayloadAction<IntelReport[]>) => {
        state.isFetching = false;
        state.reports = payload;
      },
      toggleIsEditingReport: (state) => {
        state.isEditingReport = !state.isEditingReport;
      },
      setReports: (state, { payload }: PayloadAction<IntelReport[]>) => {
        state.reports = payload;
      },
      create: (state, params) => {
        const report = params.payload;
        state.reports.push(report);
        state.selectedReportId = report.id;
        state.isEditingReport = false;
      },
      saveSharedReport: (
        state,
        {
          payload,
        }: PayloadAction<{ oldReportId: string; savedReport: IntelReport }>
      ) => {
        state.reports = state.reports.map((report) =>
          report.id === payload.oldReportId ? payload.savedReport : report
        );
      },
      update: (
        state,
        {
          payload,
        }: PayloadAction<{
          reportId: IntelReport['id'];
          updatedReport: IntelReport;
        }>
      ) => {
        const report = state.reports.find(
          (report) => report.id === payload.reportId
        );
        if (!report) return;
        state.reports = state.reports.map((report) =>
          report.id === payload.reportId ? payload.updatedReport : report
        );
        state.loadedReportIds = state.loadedReportIds.filter(
          (id) => id !== payload.reportId
        );
      },
      updateEmailSchedule: (
        state,
        {
          payload,
        }: PayloadAction<{
          reportId: IntelReport['id'];
          emailFrequency: IntelReport['emailSchedule'];
        }>
      ) => {
        const report = state.reports.find(
          (report) => report.id === payload.reportId
        );
        if (!report) return;
        report.emailSchedule = payload.emailFrequency;
      },
      clearSelectedReport: (state) => {
        state.selectedReportId = '';
        state.reports = state.reports.filter(
          (report) => report.id !== state.selectedReportId
        );
      },
      remove: (state, { payload }: PayloadAction<IntelReport['id']>) => {
        state.reports = state.reports.filter((report) => report.id !== payload);
      },
      setProduction: (
        state,
        {
          payload,
        }: PayloadAction<{
          reportId: IntelReport['id'];
          production: IntelReportData['production'];
        }>
      ) => {
        const reportData = state.reportsData.find(
          (report) => report.reportId === payload.reportId
        );
        if (!reportData) return;
        reportData.production = payload.production;
        reportData.isLoadingProduction = false;
      },
      setMonthlyProduction: (
        state,
        {
          payload,
        }: PayloadAction<{
          reportId: IntelReport['id'];
          monthlyProduction: IntelReportProduction[];
        }>
      ) => {
        const reportData = state.reportsData.find(
          (report) => report.reportId === payload.reportId
        );
        if (!reportData) return;
        reportData.monthlyProduction = payload.monthlyProduction;
        reportData.isLoadingThreeMonthsProduction = false;
      },
      setYearOverYearData: (
        state,
        {
          payload,
        }: PayloadAction<{
          reportId: IntelReport['id'];
          spudsYearOverYear: IntelActivityItem[];
          licencesYearOverYear: IntelActivityItem[];
        }>
      ) => {
        const reportData = state.reportsData.find(
          (report) => report.reportId === payload.reportId
        );
        if (!reportData) return;
        reportData.spudsYearOverYear = payload.spudsYearOverYear;
        reportData.licencesYearOverYear = payload.licencesYearOverYear;
        reportData.isLoadingYearOverYear = false;
      },
      setMonthlyLicences: (
        state,
        {
          payload,
        }: PayloadAction<{
          reportId: IntelReport['id'];
          monthlyLicences: IntelActivityItem[];
        }>
      ) => {
        const reportData = state.reportsData.find(
          (report) => report.reportId === payload.reportId
        );
        if (!reportData) return;
        reportData.monthlyLicenses = payload.monthlyLicences;
        reportData.isLoadingMonthlyLicenses = false;
      },
      setMonthlySpuddedLicenses: (
        state,
        {
          payload,
        }: PayloadAction<{
          reportId: IntelReport['id'];
          monthlySpuddedLicenses: IntelActivityItem[];
        }>
      ) => {
        const reportData = state.reportsData.find(
          (report) => report.reportId === payload.reportId
        );
        if (!reportData) return;
        reportData.monthlySpuddedLicenses = payload.monthlySpuddedLicenses;
        reportData.isLoadingMonthlySpuddedLicenses = false;
      },
      setIsLoadingProduction: (
        state,
        { payload }: PayloadAction<IntelReport['id']>
      ) => {
        const reportData = state.reportsData.find(
          (report) => report.reportId === payload
        );
        if (!reportData) return;
        reportData.isLoadingProduction = true;
        reportData.isLoadingThreeMonthsProduction = true;
      },
      setIsLoadingWells: (
        state,
        { payload }: PayloadAction<IntelReport['id']>
      ) => {
        const reportData = state.reportsData.find(
          (report) => report.reportId === payload
        );
        if (!reportData) return;
        reportData.isLoadingMonthlyLicenses = true;
        reportData.isLoadingYearOverYear = true;
        reportData.isLoadingMonthlySpuddedLicenses = true;
      },
      markReportAsLoaded: (state, action) => {
        const reportData = createIntelReportData(action.payload);
        state.reportsData.push(reportData);
        state.loadedReportIds.push(action.payload);
      },
      selectReport: (state, action) => {
        state.selectedReportId = action.payload;
      },
      unselectReport: (state) => {
        state.selectedReportId = '';
      },
      _updateId: (
        state,
        {
          payload: { currentId, newId },
        }: PayloadAction<{
          currentId: IntelReport['id'];
          newId: IntelReport['id'];
        }>
      ) => {
        const report = state.reports.find((report) => report.id === currentId);
        if (!report) return;
        report.id = newId;
      },
    },
  });

export const intelReportsSlice = createReportsSlice('intelReports');

import {
  ListItem,
  ListItemButton,
  type ListItemButtonProps,
} from '@mui/material';

type Props = {
  isButton: boolean;
} & ListItemButtonProps;

export const CustomListItemButton = ({ isButton, ...rest }: Props) => {
  return isButton ? (
    <ListItemButton {...rest} />
  ) : (
    <ListItem {...(rest as any)} />
  );
};

import { LayerType, type Layer } from '@pn/core/domain/layer';
import { getLayerId } from '@pn/core/domain/workspace';

export function createLabelLayer(params: {
  itemId: string;
  sourceLayerId: string;
  sourceLayerType: LayerType;
  color: unknown;
  field: string;
  source: Layer['source'];
  sourceField?: string;
  textIndex?: number; // for stacking labels on top of each other
}): Layer {
  const {
    itemId,
    sourceLayerId,
    sourceLayerType,
    source,
    sourceField,
    field,
    color,
    textIndex = 0,
  } = params;

  const key = `Labels ${textIndex + 1}`;
  const layerId = getLayerId(itemId, sourceLayerId, new Date().getTime());

  return {
    id: layerId,
    name: key,
    type: LayerType.Text,
    source,
    sourceLayer: undefined,
    style: {
      field: ['get', field],
      size: 14,
      offset: [0, getLabelYOffset(textIndex)],
      placement: sourceLayerType === LayerType.Line ? 'line-center' : 'point',
      lineHeight: 1,
      font: ['Open Sans Regular', 'Arial Unicode MS Regular'],
      maxWidth: 1000, // prevent line wrapping
      color,
    },
    sourceField,
  };
}

function getLabelYOffset(index: number): number {
  return -1.5 - index * 1.5;
}

export type Point = {
  x: number;
  y: number;
};

export function areClose(point1: Point, point2: Point, threshold = 0): boolean {
  return (
    Math.abs(point1.x - point2.x) <= threshold &&
    Math.abs(point1.y - point2.y) <= threshold
  );
}

import React from 'react';

type UploadDialogContextType = {
  isUploadDialogOpen: boolean;
  openUploadDialog: () => void;
  closeUploadDialog: () => void;
};

const UploadDialogContext = React.createContext<UploadDialogContextType>(
  {} as UploadDialogContextType
);

export const useUploadDialog = () => React.useContext(UploadDialogContext);

type Props = {
  children: React.ReactNode;
};

export const UploadDialogProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const openUploadDialog = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeUploadDialog = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <UploadDialogContext.Provider
      value={{
        isUploadDialogOpen: isOpen,
        openUploadDialog,
        closeUploadDialog,
      }}
    >
      {children}
    </UploadDialogContext.Provider>
  );
};

import type { PostgresMappingItem } from '@pn/services/api/data/types';
import { aerTransfersMapping } from './aer-transfers';
import { landPostingsMapping } from './land-postings';
import { landResultsMapping } from './land-results';
import { mineralRightsTransfersMapping } from './mineral-rights-transfers';
import { wellLicenceMismatches } from './well-licence-mismatches';
import { wellLicenseTransfersMapping } from './well-license-transfers';
import {
  wellLicensesMapping,
  wellOnProdsMapping,
  wellRigReleasesMapping,
  wellSpudsMapping,
} from './well-licenses';
import {
  gasWellsByInitialProduction,
  liquidWellsByInitialProduction,
} from './wells-by-initial-production.ts';
import {
  wellsByBOEProduction,
  wellsByGasProduction,
  wellsByLiquidProductionMapping,
} from './wells-by-production';

export const postgresMappings: Record<string, PostgresMappingItem[]> = {
  well_licenses: wellLicensesMapping,
  well_spuds: wellSpudsMapping,
  well_on_prods: wellOnProdsMapping,
  well_rig_releases: wellRigReleasesMapping,
  well_license_transfers: wellLicenseTransfersMapping,
  aer_transfers: aerTransfersMapping,
  land_postings: landPostingsMapping,
  land_results: landResultsMapping,
  mineral_rights_transfers: mineralRightsTransfersMapping,
  wells_by_gas_production: wellsByGasProduction,
  wells_by_liquid_production: wellsByLiquidProductionMapping,
  wells_by_production: wellsByBOEProduction,
  well_licence_mismatches: wellLicenceMismatches,
  liquid_ip3: liquidWellsByInitialProduction,
  liquid_ip12: liquidWellsByInitialProduction,
  gas_ip3: gasWellsByInitialProduction,
  gas_ip12: gasWellsByInitialProduction,
};

import {
  Box,
  Collapse,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { getSearchbarWidth, searchbarParentId } from 'src/ui/layout/Searchbar';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ maxWidth: number }>()((theme, { maxWidth }) => ({
  wrapper: {
    minWidth: 280,
    maxWidth,
    padding: theme.spacing(1),
  },
  tableRow: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
}));

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const SearchInfoPopup = ({ isOpen, setIsOpen }: Props) => {
  const { classes } = useStyles({
    maxWidth: getSearchbarWidth(),
  });

  const anchorEl = window.document.getElementById(searchbarParentId);

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      TransitionComponent={Collapse}
    >
      <Box className={classes.wrapper}>
        <Table aria-label="example search queries" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Search</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell component="th" scope="row">
                078-08W6
                <br />
                D-5-H/94-B-9
                <br />
                Drayton Valley
                <br />
                56.2884, -116.8837
              </TableCell>
              <TableCell>Locations</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell component="th" scope="row">
                102/16-2-78-8W6/00
                <br />
                200A024E094H0600
                <br />
                37-083-47160
                <br />
                12J153
                <br />
                AB 0485249
              </TableCell>
              <TableCell>Wells</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell component="th" scope="row">
                53949-1
                <br />
                BC 9425 1
              </TableCell>
              <TableCell>Pipelines</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell component="th" scope="row">
                0293819
                <br />
                SK 04H138
                <br />
                ABBT0104129
              </TableCell>
              <TableCell>Facilities</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell component="th" scope="row">
                MSL040015
                <br />
                PLA 51443
                <br />
                BC 21748
              </TableCell>
              <TableCell>Dispositions</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Box mt={1} px={2} py={1}>
          <Typography variant="body2" color="textSecondary">
            All common formats for DLS/NTS/UWI lookups are supported.
          </Typography>
        </Box>
      </Box>
    </Menu>
  );
};

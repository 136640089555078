import { isNil } from 'lodash-es';
import { pnMapboxLayers } from './pnMapboxLayers';

const compositeSources = [
  'mapbox://sbilston.91j6wu6j',
  'mapbox.mapbox-streets-v8',
  'mapbox.mapbox-terrain-v2',
  'mapbox.mapbox-bathymetry-v2',
  'sbilston.grid_master',
];

export const mapboxStyle: mapboxgl.Style = {
  layers: [...pnMapboxLayers],
  sources: {
    'mapbox://mapbox.satellite': {
      type: 'raster',
      url: 'mapbox://mapbox.satellite',
      tileSize: 256,
    },
    composite: {
      type: 'vector',
      url: compositeSources.join(','),
    },
  },
  sprite: 'mapbox://sprites/mapbox/streets-v12',
  glyphs: 'mapbox://fonts/sbilston/{fontstack}/{range}.pbf',
  version: 8,
};

export const builtInLayers = [
  {
    name: 'Hydrology',
    id: 'hydrology',
    sourceLayers: ['water', 'waterway'],
    defaultState: true,
  },
  {
    name: 'Land Use',
    id: 'land-use',
    sourceLayers: ['landuse', 'landuse_overlay'],
    defaultState: false,
  },
  {
    name: 'Roads',
    id: 'roads',
    sourceLayers: ['road'],
    defaultState: true,
  },
  {
    name: 'POI Labels',
    id: 'poi-labels',
    sourceLayers: [
      'poi_label',
      'place_label',
      'airport_label',
      'transit_stop_label',
      'housenum_label',
      'natural_label',
    ],
    defaultState: true,
  },
  {
    name: 'Elevation Contours',
    id: 'elevation-contours',
    sourceLayers: ['contour'],
    defaultState: false,
  },
];

export function hideBuiltInLayer(layer: mapboxgl.Layer): mapboxgl.Layer {
  if (isNil(layer['source-layer'])) {
    return layer;
  }

  if (
    builtInLayers
      .filter(({ defaultState }) => !defaultState)
      .some((builtInLayer) =>
        builtInLayer.sourceLayers.includes(layer['source-layer']!)
      )
  ) {
    return {
      ...layer,
      layout: {
        ...layer.layout,
        visibility: 'none',
      },
    };
  }

  return layer;
}

import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const facilitiesElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'ID',
    folder: 'Facility Header',
    isShownByDefault: true,
    width: 130,
  },
  {
    field: 'petrinex_facility_id',
    sourceType: 'keyword',
    label: 'Petrinex ID',
    folder: 'Facility Header',
    isShownByDefault: true,
    width: 140,
  },
  {
    field: 'license_number',
    sourceType: 'keyword',
    label: 'Licence Number',
    folder: 'Facility Header',
    isShownByDefault: true,
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'Province',
    folder: 'Facility Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'name',
    sourceType: 'text',
    label: 'Name',
    folder: 'Facility Header',
    isShownByDefault: true,
    width: 225,
  },
  {
    field: 'licensee',
    sourceType: 'text',
    label: 'Licensee',
    folder: 'Facility Header',
    width: 200,
  },
  {
    field: 'operator',
    sourceType: 'text',
    label: 'Operator',
    folder: 'Facility Header',
    isShownByDefault: true,
    width: 250,
  },
  {
    field: 'type',
    sourceType: 'text',
    label: 'Type',
    folder: 'Facility Header',
    isShownByDefault: true,
    width: 175,
  },
  {
    field: 'subtype',
    sourceType: 'text',
    label: 'Subtype',
    folder: 'Facility Header',
  },
  {
    field: 'status',
    sourceType: 'text',
    domainTypeAttributes: {
      options: [
        'ABANDONED',
        'ACTIVE',
        'AMENDED',
        'CANCELLED',
        'COR',
        'CONSTRUCTION COMPLETED',
        'DECOMMISSION',
        'ISSUED',
        'MINSUSPEND',
        'NEW',
        'NON-REPORTING',
        'PERMIT APPROVED',
        'RECCERTIFIED',
        'RECEXEMPT',
        'RETIRED',
        'REMOVED',
        'SUSPENDED',
        'UNDER CONSTRUCTION',
      ],
    },
    label: 'Op Status',
    folder: 'Facility Header',
    isShownByDefault: true,
  },
  {
    field: 'license_status',
    sourceType: 'text',
    domainTypeAttributes: {
      options: [
        'ABANDONED',
        'AMENDED',
        'CANCELLED',
        'ISSUED',
        'RE-ENTERED',
        'RECCERTIFIED',
        'RECEXEMPT',
        'SUSPENSION',
      ],
    },
    label: 'Licence Status',
    folder: 'Facility Header',
  },
  {
    field: 'license_date',
    sourceType: 'date',
    label: 'Licence Date',
    folder: 'Facility Header',
    width: 110,
  },
  {
    field: 'has_production',
    sourceType: 'boolean',
    label: 'Has Production',
    folder: 'Facility Header',
    isShownByDefault: true,
  },
  {
    field: 'has_produced_in_last_three_months',
    sourceType: 'boolean',
    label: 'Has Produced In Last 3 mos',
    folder: 'Facility Header',
  },
  /* Location */
  {
    field: 'surface_location',
    sourceType: 'keyword',
    label: 'Surface DLS/NTS',
    folder: 'Location',
    isShownByDefault: true,
  },
  {
    field: 'surface_latitude',
    sourceType: 'double',
    label: 'Surface Latitude',
    folder: 'Location',
  },
  {
    field: 'surface_longitude',
    sourceType: 'double',
    label: 'Surface Longitude',
    folder: 'Location',
  },
  /* SI Units */
  {
    field: 'capacity_rec_sales_gas_e3m3_d',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Sales Gas Capacity',
    folder: 'Capacity',
  },
  {
    field: 'capacity_raw_gas_e3m3_d',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Raw Gas Capacity',
    folder: 'Capacity',
  },
  {
    field: 'capacity_c2_m3_d',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'C2 Capacity',
    folder: 'Capacity',
  },
  {
    field: 'capacity_c2_mix_m3_d',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'C2 Mix Capacity',
    folder: 'Capacity',
  },
  {
    field: 'capacity_c3_m3_d',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'C3 Capacity',
    folder: 'Capacity',
  },
  {
    field: 'capacity_c4_m3_d',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'C4 Capacity',
    folder: 'Capacity',
  },
  {
    field: 'capacity_sulphur_inlet',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'tonnes/d',
    },
    label: 'Sulphur Inlet Capacity',
    folder: 'Capacity',
  },
  {
    field: 'capacity_sulphur_recovered',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'tonnes/d',
    },
    label: 'Sulphur Recovered Capacity',
    folder: 'Capacity',
  },
  {
    field: 'capacity_sulphur_emmission',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'tonnes/d',
    },
    label: 'Sulphur Emmission Capacity',
    folder: 'Capacity',
  },
  {
    field: 'recent_rec_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Gas Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_rec_litemx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Lite Mix Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_rec_water',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Water Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_rec_sulphur',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'tonnes/d',
    },
    label: 'Last Month Sulphur Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_nc5_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month NC5-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_n2_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month N2 Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_steam_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Steam Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_freshwater_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Freshwater Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_ic4_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month IC4-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c6_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C6-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c2_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C2-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c4_sp_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C4-SP Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_co2_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month CO2 Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_helium_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Helium Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_cond_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Cond Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c3_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C3-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c1_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C1-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_entgas_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Entgas Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_sprill_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'tonnes/d',
    },
    label: 'Last Month Sprill Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_nc4_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month NC4-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c4_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C4-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c5_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C5-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_water_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Water Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c5_sp_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C5-SP Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_brkwtr_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Brkwtr Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_syncrd_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Syncrd Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_waste_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Waste Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_co2_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month CO2-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_ic5_mx_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month IC5-MX Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_c3_sp_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C3-SP Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_oil_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Oil Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_acgas_rec',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Acgas Rec',
    folder: 'Production Receipt',
  },
  {
    field: 'recent_disp_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Gas Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_c1_mx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C1 Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_c2_mx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C2 Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_c3_mx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C3 Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_ic4_mx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month iC4 Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_nc4_mx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month nC4 Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_ic5_mx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month iC5 Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_nc5_mx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month nC5 Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_c6_mx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C6 Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_c2_sp',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C2 Spec Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_c3_sp',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C3 Spec Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_c4_sp',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C4 Spec Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_c5_sp',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month C5 Spec Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_cond',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Condensate Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_sulphur',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'tonnes/d',
    },
    label: 'Last Month Sulphur Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_acgas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Acid Gas Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_oil',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Oil Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_water',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Water Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_disp_litemx',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Month Lite Mix Disp',
    folder: 'Production Disposition',
  },
  {
    field: 'recent_fuel_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Fuel Gas',
    folder: 'Production Summary',
  },
  {
    field: 'recent_flare_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Flared Gas',
    folder: 'Production Summary',
  },
  {
    field: 'recent_flare_acgas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Flared Acid Gas',
    folder: 'Production Summary',
  },
  {
    field: 'recent_vent_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Vent Gas',
    folder: 'Production Summary',
  },
  {
    field: 'recent_diff_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Month Diff Gas',
    folder: 'Production Summary',
  },
  {
    field: 'recent_production_date',
    sourceType: 'date',
    label: 'Last Month Production Date',
    folder: 'Production Summary',
  },
  /* Not renderable */
  {
    field: 'facility_id',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'symbol',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'font',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

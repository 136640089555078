import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import { Details } from 'src/ui/main-panel/components/Details';
import { Production } from 'src/ui/main-panel/components/Production';
import type { MainPanelRoute } from 'src/ui/main-panel/routes';

export const facilitiesRoutes: MainPanelRoute[] = [
  {
    title: 'Details',
    Icon: ArticleOutlinedIcon,
    IconSelected: ArticleIcon,
    path: '/facilities/:id/details',
    getComponent: ({ item }) => <Details item={item} />,
  },
  {
    title: 'Production',
    Icon: WaterDropOutlinedIcon,
    IconSelected: WaterDropIcon,
    path: '/facilities/:id/production',
    getComponent: ({ item }) => <Production item={item} />,
  },
];

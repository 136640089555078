import type { DataItem, MappingItem } from '@pn/core/domain/data';
import type { Details } from '@pn/core/domain/data-info';
import type { ILocalDataInfoProvider } from '@pn/core/providers/data-info/ports';

const getDataDetails = async (dataItem: DataItem, mapping: MappingItem[]) => {
  const details: Details = [
    {
      tabName: 'Header',
      tables: [
        {
          title: 'Details',
          rows: mapping
            .filter((m) => !m.isNotRenderable)
            .map((mappingItem) => ({
              key: mappingItem.label,
              value: dataItem[mappingItem.field],
              type: mappingItem.domainType,
              isButton: false,
            })),
          professional: false,
          noExport: false,
          disablePagination: false,
        },
      ],
    },
  ];

  return details;
};

export const localDataInfoProvider: ILocalDataInfoProvider = {
  getDataDetails,
};

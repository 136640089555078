import type { PostgresMappingItem } from '@pn/services/api/data/types';

const wellLicenceDateField: PostgresMappingItem = {
  field: 'license_date',
  sourceType: 'timestamp',
  domainType: 'DateString',
  label: 'Licence Date',
  width: 110,
  isShownByDefault: true,
};

const wellLicenceFields: PostgresMappingItem[] = [
  {
    field: 'id',
    label: 'Licence Number',
    width: 100,
    sourceType: 'int',
    isShownByDefault: true,
  },
  {
    field: 'licensee',
    label: 'Licensee',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'formations',
    label: 'Formations',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'field_name',
    label: 'Field',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'surface_uwi',
    label: 'Surface Location',
    isShownByDefault: true,
    width: 140,
    sourceType: 'text',
  },
  {
    field: 'province',
    label: 'Province',
    sourceType: 'text',
    isShownByDefault: true,
    width: 60,
  },
  {
    field: 'uwis',
    label: 'UWIs',
    isShownByDefault: true,
    width: 200,
    sourceType: 'text',
  },
];

export const wellLicensesMapping: PostgresMappingItem[] = [
  wellLicenceDateField,
  ...wellLicenceFields,
];

export const wellSpudsMapping: PostgresMappingItem[] = [
  {
    field: 'last_spud_date',
    sourceType: 'timestamp',
    domainType: 'DateString',
    label: 'Spud Date',
    width: 110,
    isShownByDefault: true,
  },
  wellLicenceDateField,
  ...wellLicenceFields,
];

export const wellRigReleasesMapping: PostgresMappingItem[] = [
  {
    field: 'rig_rlse_date',
    sourceType: 'timestamp',
    domainType: 'DateString',
    label: 'Rig Release Date',
    width: 110,
    isShownByDefault: true,
  },
  wellLicenceDateField,
  ...wellLicenceFields,
];

export const wellOnProdsMapping: PostgresMappingItem[] = [
  {
    field: 'on_prod_date',
    sourceType: 'timestamp',
    domainType: 'DateString',
    label: 'On Prod Date',
    width: 110,
    isShownByDefault: true,
  },
  wellLicenceDateField,
  ...wellLicenceFields,
];

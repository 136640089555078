import BorderColorIcon from '@mui/icons-material/BorderColor';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import type {
  ChartSeries,
  ChartSeriesStyle,
  ChartSeriesStyles,
} from '@pn/services/charts/types';
import { getLineStyles } from '@pn/services/charts/utils/chartStyles';
import { isNil } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ChartSeriesStylePopover } from './ChartSeriesStylePopover';

const useStyles = makeStyles<{ disabled: boolean }>()(
  (theme, { disabled }) => ({
    container: {
      // flex: 1,
      // width: '100%',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      maxHeight: 480,
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'initial',
      },
    },
    accordion: {
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    accordionDetails: {
      maxHeight: 474,
      overflow: 'auto',
      padding: 0,
      paddingBottom: theme.spacing(1),
    },
    listItem: {
      height: 42,
      paddingLeft: 0,
      paddingRight: 0,
      '& .MuiListItemSecondaryAction-root': {
        display: 'none',
      },
      '& .MuiListItemButton-root': {
        paddingRight: theme.spacing(2),
      },
      '&:hover': disabled
        ? {}
        : {
            '& .MuiListItemSecondaryAction-root': {
              display: 'block',
            },
            '& .MuiListItemButton-root': {
              paddingRight: theme.spacing(6),
            },
          },
    },
    listItemWithIcon: {
      '& .MuiListItemSecondaryAction-root': {
        display: 'block',
      },
      '& .MuiListItemButton-root': {
        paddingRight: theme.spacing(6),
      },
    },
    listItemButton: {
      height: 42,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    listItemIcon: {
      minWidth: 32,
    },
    bold: {
      fontWeight: 500,
    },
  })
);

type Props = {
  accordion?: boolean;
  disabled: boolean;
  series: ChartSeries;
  seriesStyles: ChartSeriesStyles;
  multiSelection: boolean;
  onChange: (key: string, checked: boolean) => void;
  onChangeAll: (checked: boolean) => void;
  onUpsertSeriesStyle: (grouping: string, style: ChartSeriesStyle) => void;
  onRemoveSeriesStyle: (grouping: string) => void;
};

export function ChartSeriesSelector({
  accordion = false,
  disabled,
  series,
  seriesStyles,
  multiSelection,
  onChange,
  onChangeAll,
  onUpsertSeriesStyle,
  onRemoveSeriesStyle,
}: Props) {
  const { classes, cx } = useStyles({ disabled: disabled || !multiSelection });

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [seriesKey, setSeriesKey] = React.useState<string>('');

  const areAllChecked = Object.values(series).every((checked) => checked);

  return (
    <Layout accordion={accordion}>
      <List disablePadding>
        {multiSelection && (
          <ListItem className={classes.listItem}>
            <ListItemButton
              role={undefined}
              className={classes.listItemButton}
              disabled={disabled}
              onClick={() => onChangeAll(!areAllChecked)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  disableRipple
                  tabIndex={-1}
                  edge="start"
                  checked={areAllChecked}
                  indeterminate={
                    !areAllChecked &&
                    Object.values(series).some((checked) => checked)
                  }
                  onChange={(_event, checked) => onChangeAll(checked)}
                  inputProps={{
                    'aria-labelledby': 'chart-series-selector-label-all',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={'chart-series-selector-label-all'}
                primary="All"
                primaryTypographyProps={{
                  noWrap: true,
                  className: classes.bold,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {Object.entries(series).map(([key, checked]) => {
          const id = `chart-series-selector-item-${key}`;
          const labelId = `chart-series-selector-label-${key}`;

          return (
            <ListItem
              key={key}
              id={id}
              className={cx(
                classes.listItem,
                !isNil(seriesStyles[key]) && classes.listItemWithIcon
              )}
              secondaryAction={
                <IconButton
                  aria-label="edit line style"
                  size="small"
                  edge="end"
                  onClick={() => {
                    const el = document.getElementById(id);
                    setSeriesKey(key);
                    setAnchorEl(el!);
                  }}
                >
                  {isNil(seriesStyles[key]) ? (
                    <FormatColorFillIcon fontSize="small" />
                  ) : (
                    <BorderColorIcon fontSize="small" />
                  )}
                </IconButton>
              }
            >
              <ListItemButton
                role={undefined}
                className={classes.listItemButton}
                disabled={disabled}
                onClick={() => onChange(key, !checked)}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {multiSelection ? (
                    <Checkbox
                      disableRipple
                      tabIndex={-1}
                      edge="start"
                      checked={checked}
                      style={{
                        color: disabled
                          ? undefined
                          : getLineStyles(seriesStyles, key).borderColor,
                      }}
                      onChange={(_event, checked) => onChange(key, checked)}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  ) : (
                    <Radio
                      disableRipple
                      tabIndex={-1}
                      edge="start"
                      checked={checked}
                      style={{
                        color: disabled
                          ? undefined
                          : getLineStyles(seriesStyles, key).borderColor,
                      }}
                      onChange={(_event, checked) => onChange(key, checked)}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={key}
                  primaryTypographyProps={{ noWrap: true }}
                  title={key}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <ChartSeriesStylePopover
        anchorEl={anchorEl}
        seriesKey={seriesKey}
        seriesStyle={seriesStyles[seriesKey] ?? {}}
        onClose={() => setAnchorEl(undefined)}
        onUpsert={(styleKey: string, value: unknown) =>
          onUpsertSeriesStyle(seriesKey, {
            ...seriesStyles[seriesKey],
            [styleKey]: value,
          })
        }
        onRemove={() => onRemoveSeriesStyle(seriesKey)}
      />
    </Layout>
  );
}

type LayoutProps = {
  children: React.ReactNode;
  accordion?: boolean;
};

const Layout = ({ children, accordion }: LayoutProps) => {
  const { classes } = useStyles({ disabled: false });

  if (accordion) {
    return (
      <Paper variant="outlined">
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Production series</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {children}
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  } else {
    return (
      <Paper className={classes.container} variant="outlined">
        {children}
      </Paper>
    );
  }
};

import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const dispositionsElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'Petro Ninja ID',
    folder: 'Disposition Header',
  },
  {
    field: 'disposition_number',
    sourceType: 'keyword',
    label: 'Disposition No.',
    folder: 'Disposition Header',
    isShownByDefault: true,
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'Province',
    folder: 'Disposition Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'company',
    sourceType: 'text',
    label: 'Company',
    folder: 'Disposition Header',
    isShownByDefault: true,
    width: 250,
  },
  {
    field: 'disposition_type',
    sourceType: 'keyword',
    label: 'Type (Abbreviated)',
    folder: 'Disposition Header',
  },
  {
    field: 'disposition_type_name',
    sourceType: 'text',
    label: 'Type',
    folder: 'Disposition Header',
    isShownByDefault: true,
    width: 200,
  },
  {
    field: 'status',
    sourceType: 'text',
    label: 'Status',
    folder: 'Disposition Header',
    isShownByDefault: true,
    width: 200,
  },
  {
    field: 'application_number',
    sourceType: 'integer',
    label: 'Application No.',
    folder: 'Disposition Header',
    isShownByDefault: true,
  },
  {
    field: 'legacy_ogc_number',
    sourceType: 'integer',
    label: 'Legacy OGC No.',
    folder: 'Disposition Header',
    isShownByDefault: true,
  },
  {
    field: 'road_number',
    sourceType: 'keyword',
    label: 'Road No.',
    folder: 'Disposition Header',
    isShownByDefault: true,
    width: 120,
  },
  {
    field: 'plan_number',
    sourceType: 'keyword',
    label: 'Plan No.',
    folder: 'Disposition Header',
  },
  {
    field: 'document_type',
    sourceType: 'text',
    label: 'Document Type',
    folder: 'Disposition Header',
  },
  /* Details */
  {
    field: 'area_acres',
    sourceType: 'double',
    label: 'Area in Acres',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'acre', // should not be converted
    },
    folder: 'Details',
  },
  {
    field: 'area_hectares',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'ha', // should not be converted
    },
    label: 'Area in Hectares',
    folder: 'Details',
  },
  {
    field: 'client_code',
    sourceType: 'text',
    label: 'Client Code',
    folder: 'Details',
  },
  {
    field: 'client_count',
    sourceType: 'integer',
    label: 'Client Count',
    folder: 'Details',
  },
  {
    field: 'dimensions',
    sourceType: 'keyword',
    label: 'Dimensions',
    folder: 'Details',
  },
  {
    field: 'discrepancy_flag',
    sourceType: 'boolean',
    label: 'Has Discrepancy',
    folder: 'Details',
  },
  {
    field: 'first_name',
    sourceType: 'text',
    label: 'First Name',
    folder: 'Details',
  },
  {
    field: 'last_name',
    sourceType: 'text',
    label: 'Last Name',
    folder: 'Details',
  },
  {
    field: 'general_comments',
    sourceType: 'keyword',
    label: 'Comments',
    folder: 'Details',
  },
  {
    field: 'near_water_flag',
    sourceType: 'boolean',
    label: 'Is Near Water',
    folder: 'Details',
  },
  {
    field: 'purpose_code',
    sourceType: 'text',
    label: 'Purpose Code',
    folder: 'Details',
  },
  {
    field: 'purpose_additional_info',
    sourceType: 'text',
    label: 'Purpose Additional Info',
    folder: 'Details',
  },
  {
    field: 'restriction_code',
    sourceType: 'text',
    label: 'Restriction Code',
    folder: 'Details',
  },
  /* Dates */
  {
    field: 'amendment_loa_date',
    sourceType: 'date',
    label: 'Amendment LOA Date',
    folder: 'Dates',
  },
  {
    field: 'amendment_date',
    sourceType: 'date',
    label: 'Amendment Date',
    folder: 'Dates',
  },
  {
    field: 'application_date',
    sourceType: 'date',
    label: 'Application Date',
    folder: 'Dates',
  },
  {
    field: 'cancellation_date',
    sourceType: 'date',
    label: 'Cancellation Date',
    folder: 'Dates',
  },
  {
    field: 'edit_date',
    sourceType: 'date',
    label: 'Edit Date',
    folder: 'Dates',
  },
  {
    field: 'effective_date',
    sourceType: 'date',
    label: 'Effective Date',
    folder: 'Dates',
  },
  {
    field: 'expiry_date',
    sourceType: 'date',
    label: 'Expiry Date',
    folder: 'Dates',
  },
  {
    field: 'loa_date',
    sourceType: 'date',
    label: 'LOA Date',
    folder: 'Dates',
  },
  {
    field: 'process_date',
    sourceType: 'date',
    label: 'Process Date',
    folder: 'Dates',
  },
  {
    field: 'reinstatement_date',
    sourceType: 'date',
    label: 'Reinstatement Date',
    folder: 'Dates',
  },
  {
    field: 'renewal_date',
    sourceType: 'date',
    label: 'Renewal Date',
    folder: 'Dates',
  },
  {
    field: 'version_date',
    sourceType: 'date',
    label: 'Version Date',
    folder: 'Dates',
  },
  {
    field: 'notification_date',
    sourceType: 'date',
    label: 'Notification Date',
    folder: 'Dates',
  },
  {
    field: 'site_entry_date',
    sourceType: 'date',
    label: 'Site Entry Date',
    folder: 'Dates',
  },
  /* Not renderable */
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'disposition_type',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { formatISODateTimeStringAsDate } from '@pn/core/utils/date';

export function UnitQuickInfo(props: any) {
  const { classes, dataItem, onClose } = props;

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box display="flex">
          <Box width="100%">
            <Typography variant="body2" className={classes.fontWeight500}>
              {dataItem.agreement_id}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="50%">
            <Typography variant="body2" noWrap>
              {dataItem.unit_name}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2" align="right">
              <span className={classes.fontWeight500}>Termination:</span>{' '}
              {dataItem.unit_termination_date
                ? formatISODateTimeStringAsDate(dataItem.unit_termination_date)
                : 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.actionWrapper}>
        <IconButton size="small" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

import React from 'react';
import { BackgroundDialogModule } from './BackgroundDialogModule';
import { GridsDialogModule } from './GridsDialogModule';

type Props = {
  module: string | undefined;
};

/**
 * Dialog modules are used to handle special cases.
 */
export const DialogModule = React.memo(_DialogModule);
function _DialogModule({ module }: Props) {
  switch (module) {
    case 'background':
      return <BackgroundDialogModule />;
    case 'grids':
      return <GridsDialogModule />;
    default:
      return null;
  }
}

import { dependencies } from '@pn/core/dependencies';
import {
  isStreamableItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { unselectDataItem } from '@pn/core/operations/dataSelection';
import {
  removeLayers,
  syncVectorFeaturesState,
} from '@pn/core/operations/workspace/layersProcessing';
import {
  clearMapDataByItem,
  mapDataAbortControllers,
} from '@pn/core/operations/workspace/mapData';
import { workspaceActions } from '@pn/core/storage';
import { log } from '@pn/core/utils/debug';

/**
 * Do not call this function directly. Use `removeFromWorkspace` or
 * `setWorkspace` methods from workspace actions instead.
 */
export function clearWorkspaceItem(
  item: WorkspaceItem,
  tableOnly = false
): void {
  log.info(`%cclearWorkspaceItem [${item.id}]`, 'color: #B71C1C', item);

  if (item.itemType === 'annotation') {
    dependencies.map.removeAnnotationLayer(item);
    workspaceActions().revisualize(item.id);
    return;
  }

  mapDataAbortControllers[item.id]?.abort();

  if (!tableOnly) {
    clearMapDataByItem(item);
    removeLayers(item.map.layers);
    if (isStreamableItem(item)) {
      syncVectorFeaturesState(item); // reset all feature states
    }
  }

  workspaceActions().updatePage(item.id, 0);
  workspaceActions().revisualize(item.id);
  unselectDataItem(item);
}

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { DataItemId } from '@pn/core/domain/data';
import {
  DetailsTable,
  isKeyValueTable,
  isLinkedIdsTable,
  isMultiColumnTable,
} from '@pn/core/domain/data-info';
import { UserPlans } from '@pn/core/domain/user';
import {
  isInsufficientPlan,
  useAccess,
} from '@pn/core/permissions/access';
import {
  useAppStorage,
  useCurrentUserStorage,
} from '@pn/core/storage';
import { isCordova, isFreeCordova } from '@pn/core/utils/env';
import download from 'downloadjs';
import { isEmpty } from 'lodash-es';
import { notificationService } from 'src/application/externalDependencies';
import { detailsTableToCsv } from 'src/ui/main-panel/components/dense-components/tables/export';
import { makeStyles } from 'tss-react/mui';
import { ConnectedAssetsTableComponent } from './tables/ConnectedAssetsTable';
import { KeyValueTableComponent } from './tables/KeyValueTable';
import { LinkedIdsTableComponent } from './tables/LinkedIdsTable';
import { LockedTable } from './tables/LockedTable';
import { MultiColumnTableComponent } from './tables/MultiColumnTable';

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflowX: 'auto',
  },
  tableTitle: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  tableTitleIconPlaceholder: {
    display: 'inline-block',
    height: 25,
  },
  noDataMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
    padding: '2px 8px 2px 8px',
    border: 'none',
    fontSize: '0.825rem',
    '&:last-child': {
      paddingRight: 8,
    },
    /* Set maximum width */
    maxWidth: 200, // resuts in the actual max width at around 400px
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tablePaginationToolbar: {
    minHeight: 36,
  },
  tablePaginationCaption: {
    fontSize: '0.825rem',
  },
  lockIcon: {
    position: 'relative',
    top: 2,
    fontSize: 16,
  },
  blur: {
    color: 'transparent',
    textShadow: '0 0 8px #000',
  },
  bold: {
    fontWeight: 500,
  },
}));

type Props = {
  dataItemId: DataItemId;
  table: DetailsTable;
};

export function DenseTable({ dataItemId, table }: Props) {
  const { tableType, title, rows, professional } = table;

  const { classes } = useStyles();
  const access = useAccess();

  const { unitSystem } = useAppStorage();
  const { user } = useCurrentUserStorage();

  const handleExportCSV = () => {
    if (access('details.export').notify().denied()) return;

    if (isCordova()) {
      notificationService.notify(
        'CSV exports are available on desktop/mobile browsers only.',
        'warning'
      );
    }

    const csv = detailsTableToCsv(table, unitSystem);

    download(csv, `${dataItemId} ${title} (${unitSystem}).csv`);
  };

  if (
    professional &&
    isInsufficientPlan(user?.userPlan, UserPlans.Professional)
  ) {
    if (isFreeCordova(user)) return null;

    return <LockedTable title={title} classes={classes} />;
  }

  if (isEmpty(rows)) {
    return (
      <Box>
        <Typography className={classes.tableTitle}>
          {title}
          <Box component="span" className={classes.tableTitleIconPlaceholder} />
        </Typography>
        <Divider />
        <Typography className={classes.noDataMessage} color="textSecondary">
          No data.
        </Typography>
      </Box>
    );
  } else if (tableType === 'connected_assets' && isMultiColumnTable(table)) {
    if (isFreeCordova(user)) return null;
    return <ConnectedAssetsTableComponent classes={classes} table={table} />;
  } else if (tableType === 'linked_ids' && isLinkedIdsTable(table)) {
    return <LinkedIdsTableComponent classes={classes} table={table} />;
  } else if (isKeyValueTable(table)) {
    return (
      <KeyValueTableComponent
        classes={classes}
        table={table}
        onExportCSV={handleExportCSV}
      />
    );
  } else {
    return (
      <MultiColumnTableComponent
        classes={classes}
        table={table}
        onExportCSV={handleExportCSV}
      />
    );
  }
}

import { dependencies } from '@pn/core/dependencies';
import {
  getPointsLayerId,
  getSourceId,
} from '@pn/core/domain/workspace';
import { useWorkspaceStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import React from 'react';

export function useAutoSetCurrentRequestedIdFeatureState(tableOnly = false) {
  const { workspaceItemSelected, dataItemRequested } = useWorkspaceStorage();

  React.useEffect(() => {
    const { map } = dependencies;

    const requestedId = dataItemRequested.id;
    if (tableOnly || isNil(requestedId) || isNil(workspaceItemSelected)) return;

    workspaceItemSelected.map.layers.forEach((layer) => {
      map.setFeatureState({
        sourceId: getSourceId(layer.id),
        sourceLayer: layer.sourceLayer,
        featureId: requestedId,
        featureState: {
          isSelected: true,
        },
      });

      if (layer.renderAsPoints) {
        map.setFeatureState({
          sourceId: getSourceId(getPointsLayerId(layer.id)),
          featureId: requestedId,
          featureState: {
            isSelected: true,
          },
        });
      }
    });

    return () => {
      workspaceItemSelected.map.layers.forEach((layer) => {
        map.unsetFeatureState({
          sourceId: getSourceId(layer.id),
          sourceLayer: layer.sourceLayer,
          featureId: requestedId,
          featureStateFields: ['isSelected'],
        });

        if (layer.renderAsPoints) {
          map.unsetFeatureState({
            sourceId: getSourceId(getPointsLayerId(layer.id)),
            featureId: requestedId,
            featureStateFields: ['isSelected'],
          });
        }
      });
    };
  }, [tableOnly, dataItemRequested.id, workspaceItemSelected]);
}

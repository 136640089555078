import { dependencies } from '@pn/core/dependencies';
import {
  formatDataType,
  isPNDataType,
  type DataItemId,
  type PNDataType,
} from '@pn/core/domain/data';
import type { ProductionChartType } from '@pn/core/domain/production';
import { handleError } from '@pn/core/errors/handleError';
import { productionActions } from '@pn/core/storage';
import { apiProductionProvider } from '@pn/services/api/production/apiProductionProvider';
import { isString } from 'lodash-es';
import React from 'react';

export function useDataProduction(params: {
  productionChartType: ProductionChartType;
  id: string | number | undefined;
}) {
  const { isAuthenticated } = dependencies.useAuthenticationService();

  React.useEffect(() => {
    if (
      !isAuthenticated ||
      !isPNDataType(params.productionChartType) ||
      !isString(params.id)
    )
      return;

    getDataProduction(params.productionChartType, params.id);
  }, [isAuthenticated, params.productionChartType, params.id]);
}

let lastIdRequested: DataItemId = '';
async function getDataProduction(
  dataType: PNDataType, // have to narrow the type here
  id: DataItemId
): Promise<void> {
  try {
    lastIdRequested = id;
    productionActions(dataType).request();

    const productionItems = await apiProductionProvider.getProduction(
      dataType,
      id
    );

    if (lastIdRequested !== id) return;
    productionActions(dataType).receive(productionItems);
  } catch (error) {
    handleError({
      error,
      onError: productionActions(dataType).error,
      userFriendlyMessage: `Failed to get ${formatDataType(dataType, {
        form: 'singular',
      })} production`,
    });
  }
}

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { dependencies } from '@pn/core/dependencies';
import {
  productThemes,
  usePNThemeContext,
  type PNTheme,
} from '@pn/ui/theme/PetroNinjaThemeProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  formControlTypography: {
    lineHeight: 'unset',
  },
}));

type Props = {
  disabled: boolean;
};

export function ThemeSwitch({ disabled }: Props) {
  const { pnTheme, setPNTheme } = usePNThemeContext();
  const { classes } = useStyles();

  return (
    <FormControl fullWidth disabled={disabled}>
      <FormLabel id="theme-switch-label">Theme</FormLabel>
      <RadioGroup
        aria-labelledby="theme-switch-label"
        name="theme-switch"
        value={pnTheme}
        onChange={(_e, value) => setPNTheme(value as PNTheme)}
      >
        {productThemes[dependencies.appName].map((theme) => (
          <FormControlLabel
            key={theme}
            value={theme}
            label={theme}
            control={<Radio size="small" />}
            slotProps={{
              typography: {
                className: classes.formControlTypography,
                variant: 'body2',
                noWrap: true,
              },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

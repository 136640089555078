import { isProduction } from './utils/env';

export const PN_MAPS_URL = isProduction()
  ? 'https://petroninja.com'
  : 'https://staging.petroniche.com';

export const PN_LOG_VIEWER_URL = isProduction()
  ? 'https://logs.petroninja.com'
  : 'https://logs.petroniche.com';

export const PN_EMBEDDED_CHARTS_URL = 'https://charts.petroninja.com';

export const STACKDX_URL = isProduction()
  ? 'https://app.stackdx.com'
  : 'https://staging.stackdx.com';

export const BOE_REPORT_URL = 'https://boereport.com';
export const BOE_INTEL_URL = isProduction()
  ? 'https://intel.boereport.com'
  : 'https://intel.petroniche.com';

import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const gridsElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'ID',
    folder: 'Header',
    isShownByDefault: true,
  },
  {
    field: 'land_id_unhyphenated',
    sourceType: 'keyword',
    label: 'Land ID',
    folder: 'Header',
    isShownByDefault: true,
  },
  {
    field: 'land_id_hyphenated',
    sourceType: 'text',
    label: 'Land ID Formatted',
    folder: 'Header',
    isShownByDefault: true,
  },
  {
    field: 'land_type',
    sourceType: 'keyword',
    label: 'Land Type',
    folder: 'Header',
    isShownByDefault: true,
  },
  {
    field: 'land_system',
    sourceType: 'keyword',
    label: 'Land System',
    folder: 'Header',
    isShownByDefault: true,
  },
  /* Location */
  {
    field: 'centroid_latitude',
    sourceType: 'double',
    label: 'Centroid Latitude',
    folder: 'Location',
  },
  {
    field: 'centroid_longitude',
    sourceType: 'double',
    label: 'Centroid Longitude',
    folder: 'Location',
  },
  /* Not renderable */
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

import { nullToUndefined } from '@pn/core/utils/logic';
import { webStorageService } from '@pn/services/storage/webStorageService';
import React from 'react';
import type {
  ChartScale,
  ChartSeries,
  ChartSeriesStyles,
  DisplayMode,
  ProductionChartMode,
} from '../types';

const ls = webStorageService('pn');

export function getInitialChartControls() {
  return {
    initDisplayMode: nullToUndefined(ls.get<string>('production-display-mode')),
    initScale: nullToUndefined(ls.get<ChartScale>('charts-production-scale')),
    initMode: nullToUndefined(
      ls.get<ProductionChartMode>('charts-production-mode')
    ),
    initSeries: ls.get<ChartSeries>('charts-production-series'),
    initSeriesStyles: ls.get<ChartSeriesStyles>(
      'charts-production-series-styles'
    ),
  };
}

export function useStoreControlsToLocalStorage(controls: {
  displayMode: DisplayMode;
  scale: ChartScale;
  mode: ProductionChartMode;
  series: ChartSeries;
  seriesStyles: ChartSeriesStyles;
}) {
  React.useEffect(() => {
    if (controls.displayMode === 'chart' || controls.displayMode === 'table') {
      ls.set('production-display-mode', controls.displayMode);
    }
  }, [controls.displayMode]);

  React.useEffect(() => {
    ls.set('charts-production-scale', controls.scale);
  }, [controls.scale]);

  React.useEffect(() => {
    ls.set('charts-production-mode', controls.mode);
  }, [controls.mode]);

  React.useEffect(() => {
    const lsSeries = ls.get<ChartSeries>('charts-production-series');
    const combinedSeries = {
      ...lsSeries,
      ...controls.series,
    };

    ls.set('charts-production-series', combinedSeries);
  }, [controls.series]);

  React.useEffect(() => {
    ls.set('charts-production-series-styles', controls.seriesStyles);
  }, [controls.seriesStyles]);
}

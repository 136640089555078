import { SvgIcon, type SvgIconProps } from '@mui/material';

export const StackDXIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 754.7 779.3">
    <path d="M 262.5,316.6 C 324.6,253.5 394.4,198.8 469.6,154.4 544.8,109.9 625.3,75.6 708.2,52.4 L 708,51.7 566,0 C 516.8,25.6 469.6,54.8 425.2,87.4 349.2,143.2 281,208.5 222.5,281 164,353.5 115.3,433.1 77.9,517.2 40.5,601.2 14.3,689.6 0,779.3 c 23,-87.9 57.4,-172.1 101.8,-250 44.4,-78 98.6,-149.6 160.7,-212.7 z" />
    <path d="M 314.4,362.2 C 377.1,316.7 445.1,279.2 515.9,249.1 586.7,219 660.3,196.2 735.1,180 L 627.7,140.9 C 581.6,158.2 536.6,178 493,200.6 421.4,237.8 353.6,282.2 292.3,334.2 231,386.1 176.3,445.7 132,512.2 87.7,578.5 54.2,651.9 35.6,728.6 59.9,653.5 98.5,584 146.5,522.6 194.5,461.1 251.8,407.7 314.4,362.2 Z" />
    <path d="m 208.6,269.8 c 12.7,-15.8 26,-31.1 39.5,-46.1 L 174,109.9 H 56.6 l 125.7,195 c 8.5,-11.5 17.2,-22.9 26.3,-34.1 z" />
    <path d="m 363.6,418.4 c -28,14.8 -53.9,30.2 -78.1,46.2 l 98.7,153.6 h 117.5 l -171.1,-203.3 c -2.4,1.3 -4.9,2.4 -7.3,3.7 z" />
    <path d="m 754.7,273.6 -61.5,-22.4 c -51.3,12.7 -102.1,27.7 -151.8,45.8 -67.3,24.5 -132.9,53.9 -194.8,89.8 -61.8,36 -120.2,78.3 -170.5,129.2 -25.1,25.4 -48.1,52.9 -68,82.5 -19.7,29.7 -36,61.6 -47.9,95 13.3,-32.9 30.8,-63.9 51.5,-92.5 20.9,-28.5 44.7,-54.6 70.5,-78.6 51.6,-48 110.8,-87.2 172.9,-120 62.3,-32.7 127.8,-58.9 194.7,-80.1 67.2,-21.2 135.8,-36.8 204.9,-48.7 z" />
  </SvgIcon>
);

import { z } from 'zod';

export const UserProfileSchema = z.object({
  firstName: z.string().min(1, '').max(50, 'Too long'),
  lastName: z.string().min(1, '').max(50, 'Too long'),
  company: z.string().min(1, '').max(100, 'Too long'),
  title: z.string().min(1, '').max(100, 'Too long'),
  email: z.string().min(1, '').email('Invalid email'),
});

export function isValidEmail(email: string): boolean {
  return z.string().email().safeParse(email).success;
}

const PasswordValidationSchema = z
  .string()
  .min(10, 'Minimum of 10 characters required')
  .max(128, 'Too long')
  .refine(
    (val) => /^(?=.*[a-z])/.test(val),
    'Must contain at least one lowercase character'
  )
  .refine(
    (val) => /^(?=.*[A-Z])/.test(val),
    'Must contain at least one uppercase character'
  )
  .refine(
    (val) => /^(?=.*[0-9])/.test(val),
    'Must contain at least one number'
  );

export function isValidPassword(password: string): boolean {
  return PasswordValidationSchema.safeParse(password).success;
}

export function isValidHttpUrl(urlString: string): boolean {
  try {
    const url = new URL(urlString);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
}

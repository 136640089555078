import { Alert, Box, Typography } from '@mui/material';
import { useAccess } from '@pn/core/permissions/access';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isFreeCordova } from '@pn/core/utils/env';
import { useAuthenticationService } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.info.main,
    fontWeight: 500,
    textDecoration: 'underline',
    '&:hover, &:focus, &:active': {
      color: theme.palette.info.dark,
    },
  },
}));

export const WorkspaceAccessAlert = () => {
  const { classes } = useStyles();

  const access = useAccess();
  const { isAuthenticated } = useAuthenticationService();
  const { user } = useCurrentUserStorage();

  if (isFreeCordova(user) || access('projects').granted()) return null;

  return (
    <Box gridArea="access-alert" p={1.5} pb={0}>
      <Alert severity="warning">
        <Typography variant="body2">
          <a
            href="https://about.petroninja.com/plans"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            {isAuthenticated ? 'Upgrade' : 'Sign up'}
          </a>{' '}
          {isAuthenticated ? 'to' : 'for'} a Professional or Enterprise account
          for full access to projects and library
        </Typography>
      </Alert>
    </Box>
  );
};

import { Box, Paper, Typography } from '@mui/material';
import type { ErrorPageProps } from '@pn/services/loggers/AppSignalErrorBoundaryProvider';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { makeStyles } from 'tss-react/mui';
import { BackgroundImage } from './BackgroundImage';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    maxWidth: 1200,
  },
  semiLight: {
    fontWeight: 300,
  },
  errorMessage: {
    color: theme.palette.error.light,
  },
  errorStack: {
    color: theme.palette.error.light,
    whiteSpace: 'pre-line',
  },
}));

/**
 * The props are dependent on AppSignal's implementation of ErrorBoundary.
 * Not the best practice, but will suffice for now.
 */
export function ErrorPage({ error, reset }: ErrorPageProps) {
  const { classes } = useStyles();

  const handleReset = () => {
    reset();
  };

  return (
    <Box className={classes.container}>
      <BackgroundImage />
      <Paper variant="outlined" className={classes.paper}>
        <Box p={4} textAlign="center">
          <Typography variant="h4">An error happened!</Typography>
          <Box mt={2} />
          <Typography variant="h6" className={classes.semiLight}>
            Our team has been notified and is probably already working on it.
          </Typography>
          <Box mt={6} />
          <Typography className={classes.errorMessage}>
            <code>{error?.message ?? 'No error message'}</code>
          </Typography>
          <Box mt={1} />
          <Typography className={classes.errorStack}>
            <code>{displayStack(error?.stack)}</code>
          </Typography>
          <Box mt={6} />
          <CustomButton size="large" onClick={handleReset}>
            Go back
          </CustomButton>
        </Box>
      </Paper>
    </Box>
  );
}

function displayStack(stack?: string) {
  if (!stack) {
    return '';
  } else {
    return stack
      .split('\n')
      .slice(1, 4)
      .map((line: string) => `  ${line}`)
      .concat('...')
      .join('\n');
  }
}

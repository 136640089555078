import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';
import { IntelAlert } from '@pn/core/domain/intel/intel-alert.ts';

type InitialState = {
  isFetching: boolean;
  isError: boolean;
  alerts: IntelAlert[];
};

const initialState: InitialState = {
  isFetching: false,
  isError: false,
  alerts: [],
};

export const createIntelNotificationsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.alerts = payload;
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.alerts = [];
      },
      add: (state, { payload }: PayloadAction<IntelAlert>) => {
        state.alerts = [...state.alerts, payload];
      },
      update: (
        state,
        {
          payload,
        }: PayloadAction<{
          statId: IntelAlert['id'];
          alert: IntelAlert;
        }>
      ) => {
        state.alerts = state.alerts.map((alerts) =>
          alerts.id === payload.statId ? payload.alert : alerts
        );
      },
      toggle: (state, { payload }: PayloadAction<IntelAlert>) => {
        state.alerts = state.alerts.map((alerts) =>
          alerts.id === payload.id
            ? { ...alerts, enabled: !alerts.enabled }
            : alerts
        );
      },
      remove: (state, { payload }: PayloadAction<IntelAlert['id']>) => {
        state.alerts = state.alerts.filter((alerts) => alerts.id !== payload);
      },
      _updateId: (
        state,
        {
          payload,
        }: PayloadAction<{
          currentId: string | number;
          newId: IntelAlert['id'];
        }>
      ) => {
        const stat = state.alerts.find(({ id }) => id === payload.currentId);
        if (isNil(stat))
          throw new Error('Unable to update ID of an undefined stat');
        state.alerts.find(({ id }) => id === payload.currentId)!.id =
          payload.newId;
      },
    },
  });

export const intelAlertsSlice = createIntelNotificationsSlice('intelAlerts');

import { dependencies } from '@pn/core/dependencies';
import { formatDataType, type DataItemId } from '@pn/core/domain/data';
import {
  dataTypesWithCounts,
  type DataCounts,
} from '@pn/core/domain/data-info';
import { handleError } from '@pn/core/errors/handleError';
import { apiDataInfoProvider } from '@pn/services/api/data-info/apiDataInfoProvider';
import { isNil } from 'lodash-es';
import React from 'react';

export function useDataCounts(params: {
  dataType: string;
  id: DataItemId | undefined;
}): Record<string, number> {
  const { dataType, id } = params;

  const { isAuthenticated } = dependencies.useAuthenticationService();

  const [counts, setCounts] = React.useState<DataCounts>({ documents: 0 });

  React.useEffect(() => {
    if (
      !isAuthenticated ||
      !dataTypesWithCounts.includes(dataType) ||
      isNil(id)
    ) {
      return;
    }

    (async () => {
      try {
        const counts = await apiDataInfoProvider.getDataCounts(dataType, id);
        setCounts(counts);
      } catch (error) {
        handleError({
          error,
          userFriendlyMessage: `Failed to load ${formatDataType(dataType, {
            form: 'singular',
          })} data counts`,
        });
      }
    })();
  }, [dataType, id, isAuthenticated]);

  return counts;
}

import { Box, List, ListItem, ListItemText } from '@mui/material';
import { libraryFolders } from '@pn/core/domain/project';
import { noop } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { LibraryFolder } from './components/LibraryFolder';
import { useLibrary } from './LibraryProvider';
import { isFreeCordova } from '@pn/core/utils/env';
import { useCurrentUserStorage } from '@pn/core/storage';

const freeCordovaFolders = ['Petro Ninja', 'Personal'] as const;

const useStyles = makeStyles()((theme) => ({
  sidePanel: {
    gridArea: 'side-panel',
    width: 250,
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'auto',
  },
  list: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const LibrarySidePanel = () => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();

  const { searchValue, libraryRouter, setLibraryRouter } = useLibrary();

  if (searchValue !== '') {
    return (
      <Box className={classes.sidePanel}>
        <List className={classes.list}>
          <LibraryFolder folder="Search results" isSelected onClick={noop} />
        </List>
      </Box>
    );
  }

  if (isFreeCordova(user)) {
    return (
      <Box className={classes.sidePanel}>
        <List className={classes.list}>
          {freeCordovaFolders.map((folder) => (
            <LibraryFolder
              key={folder}
              folder={folder}
              isSelected={libraryRouter.folder === folder}
              onClick={() => setLibraryRouter({ section: 'items', folder })}
            />
          ))}
        </List>
      </Box>
    );
  }

  return (
    <Box className={classes.sidePanel}>
      <List className={classes.list}>
        <ListItem>
          <ListItemText
            primary="Projects"
            primaryTypographyProps={{ color: 'primary' }}
          />
        </ListItem>
        {libraryFolders.projects.map((folder) => (
          <LibraryFolder
            key={folder}
            folder={folder}
            isSelected={
              libraryRouter.section === 'projects' &&
              libraryRouter.folder === folder
            }
            onClick={() => setLibraryRouter({ section: 'projects', folder })}
          />
        ))}
        <ListItem>
          <ListItemText
            primary="Layers"
            primaryTypographyProps={{ color: 'primary' }}
          />
        </ListItem>
        {libraryFolders.items.map((folder) => (
          <LibraryFolder
            key={folder}
            folder={folder}
            isSelected={
              libraryRouter.section === 'items' &&
              libraryRouter.folder === folder
            }
            onClick={() => setLibraryRouter({ section: 'items', folder })}
          />
        ))}
      </List>
    </Box>
  );
};

import { UnitSystem } from '@pn/core/domain/types';
import { toSIUnit, type SIUnit } from '@pn/core/domain/units';
import { conversionTable } from '@pn/core/domain/units/conversionTable';
import { isNil } from 'lodash-es';

export function convertUnit(unit: SIUnit, targetSystem: UnitSystem): SIUnit {
  const unitConversion = conversionTable[unit.symbol];

  if (isNil(unitConversion)) return unit; /* Symbol not recognized */
  if (isNil(unitConversion[targetSystem]))
    return unit; /* Same system, no conversion needed */

  return {
    value: convertValue(unit.value, unitConversion[targetSystem].operation),
    symbol: unitConversion[targetSystem].symbol,
  };
}

function convertValue(
  value: number | undefined,
  mathOperation: (a: number) => number
): number | undefined {
  return !isNil(value) ? mathOperation(value) : undefined;
}

/**
 * Utility helper to convert unit symbols directly.
 */
export function convertSymbol(
  symbol: string,
  targetSystem: UnitSystem
): string {
  return convertUnit(toSIUnit({ value: 0, symbol }), targetSystem).symbol;
}

/**
 * Restores the original unit value after a conversion.
 */
export function undoUnitConversion(
  unit: SIUnit,
  unitSystem: UnitSystem
): SIUnit {
  const convertedUnit = convertUnit(unit, unitSystem);

  if (isNil(unit.value) || isNil(convertedUnit.value)) return unit;
  if (unit.value === convertedUnit.value) return unit;

  return {
    value: unit.value * (unit.value / convertedUnit.value),
    symbol: unit.symbol,
  };
}

import { handleError } from '@pn/core/errors/handleError';
import { useAccess } from '@pn/core/permissions/access';
import { tableActions } from '@pn/core/storage';
import { apiTableTemplatesProvider } from '@pn/services/api/table/apiTableTemplatesProvider';
import React from 'react';

export async function getTableTemplates() {
  try {
    tableActions().request();

    const templates = await apiTableTemplatesProvider.getTableTemplates();

    tableActions().receive(templates);
  } catch (error) {
    handleError({
      error,
      onError: tableActions().error,
      userFriendlyMessage: 'Failed to get your table templates',
    });
  }
}

export function useAutoGetTableTemplates() {
  const access = useAccess();

  const shouldFetch = access('dataTable.columns').granted();

  React.useEffect(() => {
    if (shouldFetch) {
      getTableTemplates();
    }
  }, [shouldFetch]);
}

import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  listItemButtonClasses,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { ColumnsTreeColumn } from '@pn/core/domain/data';
import { firstArrayIncludesAllElementsOfAnother } from '@pn/core/utils/logic';
import { some } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const FolderListItem = styled(ListItem)(({ theme }) => ({
  [`& .${listItemButtonClasses.root}`]: {
    paddingTop: theme.spacing(1) + '!important',
    paddingBottom: theme.spacing(1) + '!important',
  },
}));

const StyledListInner = styled(List)(({ theme }) => ({
  [`& .${listItemButtonClasses.root}`]: {
    width: '100%',
    paddingRight: theme.spacing(5.5),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const useStyles = makeStyles()((theme) => ({
  listItemIcon: {
    minWidth: 40,
  },
}));

type FolderProps = {
  children: React.ReactNode;
  disabled: boolean;
  leftOffset: number;
  isExpanded: boolean;
  folder: string;
  fields: string[];
  folderColumns: ColumnsTreeColumn[];
  timeout: number;
  onExpand: (folder: string) => () => void;
  onCheckFields: (
    updatedFields: string[]
  ) => (_e: any, checked: boolean) => void;
};

export const ColumnFolder = ({
  children,
  disabled,
  leftOffset,
  isExpanded,
  folder,
  fields,
  folderColumns,
  timeout,
  onExpand,
  onCheckFields,
}: FolderProps) => {
  const { classes, theme } = useStyles();

  const allFolderFields = folderColumns.map((i) => i.field);
  const areSomeToggled = some(allFolderFields, (field) =>
    fields.includes(field)
  );
  const areAllToggled = firstArrayIncludesAllElementsOfAnother(
    fields,
    allFolderFields
  );

  return (
    <>
      <FolderListItem
        disablePadding
        secondaryAction={
          <Checkbox
            disabled={disabled}
            edge="end"
            onChange={onCheckFields(allFolderFields)}
            checked={areAllToggled}
            indeterminate={!areAllToggled && areSomeToggled}
          />
        }
      >
        <ListItemButton
          style={{
            paddingLeft: theme.spacing(leftOffset + 1.5),
          }}
          onClick={onExpand(folder)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {isExpanded ? <FolderOpenIcon /> : <FolderIcon />}
          </ListItemIcon>
          <ListItemText
            primary={folder}
            primaryTypographyProps={{ noWrap: true }}
          />
        </ListItemButton>
      </FolderListItem>

      <Collapse unmountOnExit in={isExpanded} timeout={timeout}>
        <StyledListInner>{children}</StyledListInner>
      </Collapse>
    </>
  );
};

import type { CanvasFeature } from '@pn/core/domain/drawing';
import type { MappingItem } from '@pn/core/domain/data';
import {
  LayerType,
  type AnnotationFeature,
  type LayerStyle,
} from '@pn/core/domain/layer';
import { buildQuery, type Query } from '@pn/core/domain/query';
import { userToProjectUser, type User } from '@pn/core/domain/user';
import { generateTemporaryId } from '@pn/core/utils/id';
import { fitToShape } from '@pn/core/utils/logic';
import { generateGeoJSONFeatureCollection } from '@pn/services/map/mapbox/mapboxUtils';
import { mapboxLayerMapper } from '@pn/services/map/mapbox/mappers/mapboxLayerMapper';
import { getMapboxAnnotationsLayers } from '@pn/services/map/mapbox/methods/addAnnotationLayer';
import { isEmpty, isNil } from 'lodash-es';
import { decomposeLayerId, getLayerId } from './layer';
import type {
  WorkspaceItem,
  WorkspaceItemInternalState,
} from './workspaceItem';

type SharedParams = {
  isTemporary?: boolean;
  isLoaded?: boolean;
  temporarySourceItemId?: string;
  id?: string;
  name?: string;
  origin?: WorkspaceItem['origin'];
  queryOptions?: Partial<Query>;
  isVisualized?: boolean;
};

type SourceItemParams = SharedParams & {
  source: 'item';
  sourceItem: WorkspaceItem;
  extraStyle?: LayerStyle;
  data?: never;
  metadata?: never;
};
type AnnotationItemParams = SharedParams & {
  source: 'annotation';
  sourceItem?: never;
  extraStyle?: never;
  data?: never;
  metadata?: WorkspaceItem['metadata'];
};
type DrawingItemParams = SharedParams & {
  source: 'drawing';
  sourceItem?: never;
  extraStyle?: never;
  data: {
    type: 'drawing';
    features: CanvasFeature[];
  };
  metadata?: never;
};

export function createWorkspaceItem(
  params: SourceItemParams | AnnotationItemParams | DrawingItemParams,
  user: User | undefined
): WorkspaceItem {
  const {
    source,
    sourceItem,
    extraStyle = {},
    isTemporary = true,
    isLoaded = true,
    temporarySourceItemId = undefined,
    id = generateTemporaryId(),
    name = '',
    origin = 'pn',
    queryOptions = {},
    isVisualized = false,
    data,
    metadata,
  } = params;

  return {
    sourceItem,
    itemType: source === 'item' ? 'list' : source,
    folder: 'Personal',
    isTemporary,
    temporarySourceItemId,
    id,
    dataType: isNil(sourceItem) ? id : sourceItem.dataType,
    numberOfElements: !isNil(queryOptions.requestedIds)
      ? queryOptions.requestedIds.length
      : 0,
    name,
    origin,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    createdBy: !isNil(user) ? userToProjectUser(user) : undefined,
    isShared: false,
    isGlobal: false,
    map:
      source === 'item'
        ? createSourceItemMapConfig({
            mode: 'inherit',
            sourceItem,
            newItemId: id,
            newLayerStyle: extraStyle,
          })
        : source === 'drawing'
          ? createDrawingMapConfig({ id, data })
          : createAnnotationMapConfig({ id, features: [] }),
    dataSource: source === 'item' ? sourceItem.dataSource : { type: 'none' },
    detailsSource: source === 'item' ? sourceItem.detailsSource : 'local',
    query: buildQuery({
      id,
      dataType: isNil(sourceItem) ? id : sourceItem.id,
      ...queryOptions,
    }),
    metadata,
    ...createInitInternalState({
      isVisualized,
      isLoaded,
      mapping: sourceItem?.mapping,
    }),
  };
}

export function createInitInternalState(params: {
  isVisualized: boolean;
  isLoaded?: boolean;
  mapping?: MappingItem[];
}): WorkspaceItemInternalState {
  const { isVisualized, isLoaded = true, mapping = [] } = params;

  return {
    isProcessed: isVisualized,
    isRendered: isVisualized,
    isVisible: true,
    isLoaded,
    totalCount: 0,
    streamedCount: 0,
    mapping,
    isMappingInitialized: !isEmpty(mapping),
    tableDataItems: [],
    requestedDataItem: {
      id: undefined,
      reason: undefined,
    },
  };
}

export function createSourceItemMapConfig(params: {
  mode: 'duplicate' | 'inherit';
  sourceItem: WorkspaceItem;
  newItemId?: string;
  newLayerStyle?: LayerStyle;
}): WorkspaceItem['map'] {
  const { mode, sourceItem, newItemId, newLayerStyle = {} } = params;

  /**
   * Handles creation of a new list from another list (i.e. saving temporary
   * selection).
   */
  const sourceLayerId = sourceItem.sourceItem?.id ?? sourceItem.id;

  return {
    layers: sourceItem.map.layers.map((layer) => ({
      ...layer,
      id: isNil(newItemId)
        ? layer.id
        : getLayerId(sourceLayerId, newItemId, decomposeLayerId(layer.id).key),
      style: {
        ...layer.style,
        ...fitToShape(newLayerStyle, layer.style),
      },
      source:
        mode === 'duplicate'
          ? layer.source
          : {
              type: 'geojson',
              data: generateGeoJSONFeatureCollection([]),
            },
      sourceLayer: mode === 'duplicate' ? layer.sourceLayer : undefined,
    })),
    filterProperty: sourceItem.map.filterProperty,
  };
}

export function createDrawingMapConfig(params: {
  id: string;
  data: DrawingItemParams['data'];
}): WorkspaceItem['map'] {
  const { id, data } = params;

  return {
    layers: [
      {
        id: `drawing-${id}`,
        type: LayerType.Raster,
        source: { type: 'raster' },
        sourceLayer: undefined,
        style: {},
        metadata: data,
      },
    ],
  };
}

export function createAnnotationMapConfig(params: {
  id: string;
  features: AnnotationFeature[];
}): WorkspaceItem['map'] {
  const { id, features } = params;

  const layerId = `annotation-${id}`;

  return {
    layers: getMapboxAnnotationsLayers(layerId).map((layer) => ({
      ...mapboxLayerMapper.toDomainLayer({
        ...layer,
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features,
          },
        },
        'source-layer': undefined,
      }),
      style: {
        color: 'transparent', // for icon rendering
      },
    })),
  };
}

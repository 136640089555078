import queryString from 'query-string';

export type Message<T> = {
  action: string;
  payload?: T;
};

export type IncomingMessage<T> = Message<T>;
export type OutgoingMessage<T> = Message<T>;

export function isEmbedded() {
  return (
    typeof window !== 'undefined' &&
    window.location.hostname.split('.')[0] === 'embedded'
  );
}

export function isInIFrame() {
  return typeof window !== 'undefined' && window !== window.parent;
}

export function isBottomTableShown() {
  return queryString.parse(window.location.search).bottomTable === '1';
}

export function isInDebugMode() {
  return queryString.parse(window.location.search).debug === '1';
}

export const log = {
  debug: (message: string, ...args: any[]) => {
    if (isInDebugMode()) {
      console.log('%c[embedded]', 'color: #009688', message, ...args);
    }
  },
  error: (message: string, ...args: any[]) =>
    console.log('%c[embedded error]', 'color: #F44336', message, ...args),
};

import type {
  CanvasFeature,
  CircleFeature,
  PathFeature,
  PolyFeature,
  TextFeature,
  Transformation,
} from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import { generateFeatureMeasurements } from '@pn/services/drawing/measurement';
import { isNil } from 'lodash-es';

export function computeCanvasTransformation(
  ctx: CanvasRenderingContext2D
): Transformation {
  const matrix = ctx.getTransform();

  return {
    dx: matrix.e / matrix.a,
    dy: matrix.f / matrix.a,
    scale: matrix.a,
  };
}

export function rescaleFeature(
  feature: CanvasFeature,
  scale: number,
  anchor: Point
): CanvasFeature {
  switch (feature.type) {
    case 'poly':
      return rescalePolyFeature(feature, scale, anchor);
    case 'circle':
      return rescaleCircleFeature(feature, scale, anchor);
    case 'text':
      return rescaleTextFeature(feature, scale, anchor);
    case 'path':
      return rescalePathFeature(feature, scale, anchor);
  }
}

function rescalePolyFeature(
  feature: PolyFeature,
  scale: number,
  anchor: Point
): PolyFeature {
  const rescaledFeature = {
    ...feature,
    coordinates: feature.coordinates.map((point) => ({
      x: anchor.x + (point.x - anchor.x) * scale,
      y: anchor.y + (point.y - anchor.y) * scale,
    })),
    strokeWidth: feature.strokeWidth * Math.abs(scale),
  };

  if (!isNil(feature.measurements)) {
    rescaledFeature.measurements = generateFeatureMeasurements(rescaledFeature);
  }

  return rescaledFeature;
}

function rescaleCircleFeature(
  feature: CircleFeature,
  scale: number,
  anchor: Point
): CircleFeature {
  const rescaledFeature = {
    ...feature,
    center: {
      x: anchor.x + (feature.center.x - anchor.x) * scale,
      y: anchor.y + (feature.center.y - anchor.y) * scale,
    },
    radius: feature.radius * Math.abs(scale),
    strokeWidth: feature.strokeWidth * Math.abs(scale),
  };

  if (!isNil(feature.measurements)) {
    rescaledFeature.measurements = generateFeatureMeasurements(rescaledFeature);
  }

  return rescaledFeature;
}

/**
 * Rescaling text features past the origin can be visually odd, but the result
 * is invariant under cyclic transformations. This property is crucial when a
 * text feature is being resized as part of a multi-selection.
 */
function rescaleTextFeature(
  feature: TextFeature,
  scale: number,
  anchor: Point
): TextFeature {
  return {
    ...feature,
    position: {
      x: anchor.x + (feature.position.x - anchor.x) * scale,
      y: anchor.y + (feature.position.y - anchor.y) * scale,
    },
    fontSize: feature.fontSize * Math.abs(scale),
  };
}

function rescalePathFeature(
  feature: PathFeature,
  scale: number,
  anchor: Point
): PathFeature {
  const commands = feature.pathData.match(/[a-zA-Z][^a-zA-Z]*/g);
  if (!commands) return feature;

  const rescaledPathData = commands
    .map((command) => {
      const type = command[0];
      const coords = command
        .slice(1)
        .trim()
        .split(/[\s,]+/)
        .map(parseFloat);

      if (coords.length === 0) return command;

      const transformedCoords = [];
      for (let i = 0; i < coords.length; i += 2) {
        const x = anchor.x + (coords[i] - anchor.x) * scale;
        const y = anchor.y + (coords[i + 1] - anchor.y) * scale;
        transformedCoords.push(`${x} ${y}`);
      }

      return `${type}${transformedCoords.join(' ')}`;
    })
    .join(' ');

  return {
    ...feature,
    pathData: rescaledPathData,
    strokeWidth: feature.strokeWidth * Math.abs(scale),
  };
}

export function translateFeature(
  feature: CanvasFeature,
  dx: number,
  dy: number
): CanvasFeature {
  switch (feature.type) {
    case 'path': {
      return {
        ...feature,
        pathData: transformPathData(feature.pathData, { dx, dy, scale: 1 }),
      };
    }
    case 'poly': {
      const translatedFeature = {
        ...feature,
        coordinates: feature.coordinates.map((point) => ({
          x: point.x + dx,
          y: point.y + dy,
        })),
      };

      if (!isNil(feature.measurements)) {
        translatedFeature.measurements =
          generateFeatureMeasurements(translatedFeature);
      }

      return translatedFeature;
    }
    case 'circle': {
      const translatedFeature = {
        ...feature,
        center: {
          x: feature.center.x + dx,
          y: feature.center.y + dy,
        },
      };

      if (!isNil(feature.measurements)) {
        translatedFeature.measurements =
          generateFeatureMeasurements(translatedFeature);
      }

      return translatedFeature;
    }
    case 'text':
      return {
        ...feature,
        position: {
          x: feature.position.x + dx,
          y: feature.position.y + dy,
        },
      };
  }
}

export function transformPathData(
  pathData: string,
  transformation: Transformation
): string {
  const commands = pathData.match(/[a-zA-Z][^a-zA-Z]*/g);
  if (!commands) return pathData;

  const transformedCommands = commands.map((command) => {
    const type = command[0];
    const coords = command
      .slice(1)
      .trim()
      .split(/[\s,]+/)
      .map(Number);
    const transformedCoords: number[] = [];

    for (let i = 0; i < coords.length; i += 2) {
      const x = coords[i];
      const y = coords[i + 1];
      const inverseX = x * transformation.scale + transformation.dx;
      const inverseY = y * transformation.scale + transformation.dy;
      transformedCoords.push(inverseX, inverseY);
    }

    return `${type}${transformedCoords.join(' ')}`;
  });

  return transformedCommands.join(' ');
}

export function transformPoint(
  point: Point,
  transformation: Transformation
): Point {
  return {
    x: point.x * transformation.scale + transformation.dx,
    y: point.y * transformation.scale + transformation.dy,
  };
}

export function scalePoint(
  point: Point,
  multiplier = window.devicePixelRatio
): Point {
  return {
    x: point.x * multiplier,
    y: point.y * multiplier,
  };
}

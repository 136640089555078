import { getPointsApproximationFromDataItems } from '@pn/core/domain/data';
import {
  geometryToGeoShape,
  getBoundingBox,
  type GeoBoundingBox,
} from '@pn/core/domain/geography';
import { isAnnotationSource } from '@pn/core/domain/layer';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import {
  getCanvasBoundingBox,
  REFERENCE_PT,
  REFERENCE_ZOOM,
  toGeoBoundingBox,
} from '@pn/services/drawing';
import assert from 'assert';
import { isEmpty } from 'lodash-es';

export function getItemGeoBoundingBox(
  item: WorkspaceItem
): GeoBoundingBox | undefined {
  switch (item.itemType) {
    case 'annotation':
      assert(
        isAnnotationSource(item.map.layers[0].source),
        'Invalid annotation source'
      );
      return getBoundingBox(
        item.map.layers[0].source.data.features.map(({ geometry }) =>
          geometryToGeoShape(geometry)
        )
      );
    case 'drawing': {
      const layer = item.map.layers[0];
      assert(layer.metadata?.type === 'drawing', 'Invalid drawing layer');
      if (isEmpty(layer.metadata.features)) return undefined;

      const canvasBbox = getCanvasBoundingBox(layer.metadata.features);
      return toGeoBoundingBox(canvasBbox, REFERENCE_PT, REFERENCE_ZOOM);
    }
    default: {
      // const dataItemSelected = getDataItemSelected(item);
      // if (!isNil(dataItemSelected) && !isNil(dataItemSelected.geoShape)) {
      //   const points = getPointsApproximation(dataItemSelected.geoShape.shape);
      //   return getBoundingBox(points);
      // }

      const dataItems = getMapDataItems(item);
      const points = getPointsApproximationFromDataItems(dataItems);
      return isEmpty(points) ? undefined : getBoundingBox(points);
    }
  }
}

import { Box, Typography } from '@mui/material';
import type { LibraryFolder } from '@pn/core/domain/project';
import { User } from '@pn/core/domain/user';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useAccess } from '@pn/core/permissions/access';
import { isEditable } from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import {
  isDefaultProject,
  isSpecialProject,
} from '@pn/core/storage/workspace/defaultWorkspaces';
import { isFreeCordova } from '@pn/core/utils/env';
import { isCloseMatch } from '@pn/services/utils/string';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { difference, isEmpty, isNil, orderBy } from 'lodash-es';
import React from 'react';
import { notify } from 'src/application/externalDependencies';
import { WORKSPACE_DRAWER_WIDTH } from 'src/ui/Main';
import { DenseWorkspaceItem } from 'src/ui/workspace/DenseWorkspaceItem';
import { LibrarySearch } from 'src/ui/workspace/library/LibrarySearch';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    gridArea: 'quick-browser',
    width: WORKSPACE_DRAWER_WIDTH,
    height: '40vh', // % doesn't work with grid layout
    maxHeight: 288,
  },
  itemsContainer: {
    height: 'calc(100% - 39px)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    overflowY: 'scroll',
  },
}));

export const WorkspaceQuickBrowser = () => {
  const { smScreen } = useScreenSize();
  const { classes } = useStyles();

  const access = useAccess();

  const { user } = useCurrentUserStorage();
  const { idsInWorkspace, allWorkspaceItems } = useWorkspaceStorage();
  const { selectedProject } = useProjectsStorage();

  const editable =
    isDefaultProject(selectedProject) || isEditable(selectedProject, user);

  const [searchText, setSearchText] = React.useState('');
  const [searchValue, skipSearchValueDebounce] = useDebouncedValue(
    searchText,
    400,
    {
      leading: false,
      skipOnValue: '',
    }
  );

  const removedItemIds = React.useMemo(() => {
    if (isSpecialProject(selectedProject)) return [];
    return difference(selectedProject.workspaceItemIds, idsInWorkspace);
  }, [idsInWorkspace, selectedProject]);

  const showAllItems = smScreen || isFreeCordova(user);

  const quickBrowserItems = React.useMemo(
    () =>
      getFilteredQuickBrowserItems(
        allWorkspaceItems,
        idsInWorkspace,
        removedItemIds,
        searchValue,
        showAllItems,
        user
      ),
    [
      allWorkspaceItems,
      idsInWorkspace,
      removedItemIds,
      searchValue,
      showAllItems,
      user,
    ]
  );

  const handleAddItem = (itemId: WorkspaceItem['id']) => {
    if (!editable) {
      notify('Cannot add layers to read-only projects', 'warning');
      return;
    }

    workspaceActions().addToWorkspace(itemId);
    workspaceActions().select(itemId);
  };

  return (
    <Box className={classes.container}>
      <LibrarySearch
        variant="filled"
        topBorder
        squareTop
        searchText={searchText}
        setSearchText={setSearchText}
        skipDebounce={skipSearchValueDebounce}
      />
      <Box className={classes.itemsContainer}>
        {isEmpty(quickBrowserItems) ? (
          <Box mt={1}>
            <Typography align="center" variant="body2" color="textSecondary">
              No results
            </Typography>
          </Box>
        ) : (
          quickBrowserItems.map((item) => (
            <DenseWorkspaceItem
              key={item.id}
              disabled={
                !isEditable(item, user) && access('allData', item.id).denied()
              }
              item={item}
              onAddItem={handleAddItem}
            />
          ))
        )}
      </Box>
    </Box>
  );
};

const sortOrder: Record<LibraryFolder, number> = {
  'Petro Ninja': 1,
  StackDX: 2,
  'BOE Intel': 3,
  Personal: 4,
  Shared: 5,
};

function sortAllItems(items: WorkspaceItem[]): WorkspaceItem[] {
  return orderBy(items.slice(), 'updatedAt', 'desc').sort((a, b) => {
    const orderA = sortOrder[a.folder ?? 'Shared'];
    const orderB = sortOrder[b.folder ?? 'Shared'];

    return orderA - orderB;
  });
}

const getFilteredQuickBrowserItems = (
  allItems: WorkspaceItem[],
  excludedItemIds: string[],
  lastRemovedItemIds: string[],
  searchTerm: string,
  showAll: boolean,
  user: User | undefined
): WorkspaceItem[] => {
  const filteredItems = isEmpty(searchTerm)
    ? (showAll
        ? sortAllItems(allItems)
        : allItems.filter((i) => i.folder === 'Petro Ninja')
      ).filter(
        (item) => !item.isTemporary && !excludedItemIds.includes(item.id)
      )
    : sortAllItems(allItems).filter(
        (item) =>
          !item.isTemporary &&
          !excludedItemIds.includes(item.id) &&
          isCloseMatch(item.name, searchTerm)
      );

  if (isFreeCordova(user)) {
    return filteredItems.filter(
      (item) =>
        ['wells', 'wells_usa', '_background', 'grids'].includes(item.id) ||
        isEditable(item, user)
    );
  } else {
    const lastRemovedItems = allItems.filter(
      (item) =>
        isNil(filteredItems.find((i) => i.id === item.id)) &&
        lastRemovedItemIds.includes(item.id) &&
        (isEmpty(searchTerm) ? true : isCloseMatch(item.name, searchTerm))
    );

    return [...lastRemovedItems, ...filteredItems];
  }
};

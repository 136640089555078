import { getSourceId } from '@pn/core/domain/workspace';
import type { MapboxIMap } from '..';
import { getMapboxAnnotationsLayers } from './addAnnotationLayer';

export const removeAnnotationLayer: MapboxIMap['removeAnnotationLayer'] =
  function (this: MapboxIMap, workspaceItem) {
    const layerId = `annotation-${workspaceItem.id}`;
    const sourceId = getSourceId(layerId);

    if (!this._native.getSource(sourceId)) {
      return; // already removed
    }

    getMapboxAnnotationsLayers(layerId).forEach((layer) => {
      this._native.removeLayer(layer.id);
    });

    this._native.removeSource(sourceId);
  };

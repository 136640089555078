import { z } from 'zod';

export const zonalQuerySchema = z.object({
  substance: z.enum(['PNG', 'P', 'NG', 'OIL SANDS', 'COAL', 'OTHER']),
  upper: z.object({
    entity: z.string(),
    boundary: z.enum(['top', 'base']),
  }),
  lower: z.object({
    entity: z.string(),
    boundary: z.enum(['top', 'base']),
  }),
  excluded: z.boolean().optional(),
});

export type ZonalQuery = z.infer<typeof zonalQuerySchema>;

export function isSubstance(arg: unknown): arg is ZonalQuery['substance'] {
  return zonalQuerySchema.shape.substance.options.includes(
    arg as ZonalQuery['substance']
  );
}

export function createZonalQuery(): ZonalQuery {
  return {
    substance: 'PNG',
    upper: { entity: '', boundary: 'top' },
    lower: { entity: '', boundary: 'base' },
  };
}

export function minifyZonalQuery(zonalQuery: ZonalQuery) {
  return {
    a: zonalQuery.substance,
    b: zonalQuery.upper.entity,
    c: zonalQuery.upper.boundary,
    d: zonalQuery.lower.entity,
    e: zonalQuery.lower.boundary,
    f: zonalQuery.excluded,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function expandZonalQuery(zonalQuery: any): ZonalQuery {
  return {
    substance: zonalQuery.a,
    upper: { entity: zonalQuery.b, boundary: zonalQuery.c },
    lower: { entity: zonalQuery.d, boundary: zonalQuery.e },
    excluded: zonalQuery.f,
  };
}

// const sampleZonalQueries: ZonalQuery[] = [
//   {
//     substance: 'PNG',
//     upper: { entity: '', boundary: 'top' },
//     lower: { entity: 'fernie', boundary: 'base' },
//   },
// ];
// console.log(
//   encodeURLParams({
//     zonalQueries: sampleZonalQueries,
//     regionsEnabled: regions.map(() => 1),
//   }).toString()
// );

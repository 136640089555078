import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  input: {
    borderRadius: theme.shape.borderRadius,
  },
  inputAdornment: {
    marginRight: 0,
  },
  searchIcon: {
    position: 'relative',
    left: '-4px',
    marginRight: '4px',
  },
}));

type Props = {
  value: string;
  setSearchText: (searchText: string) => void;
  skipDebounce: () => void;
} & Omit<TextFieldProps, 'value' | 'onChange'>;

export function ColumnsSearchInput({
  value,
  setSearchText,
  skipDebounce,
  ...rest
}: Props) {
  const { classes } = useStyles();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      skipDebounce();
    }
  };

  return (
    <TextField
      {...rest}
      aria-label="search columns"
      variant="filled"
      size="small"
      hiddenLabel
      value={value}
      onChange={(e) => setSearchText(e.target.value)}
      onKeyDown={handleKeyDown}
      slotProps={{
        input: {
          className: classes.input,
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              {isEmpty(value) ? (
                <SearchIcon className={classes.searchIcon} />
              ) : (
                <IconButton
                  aria-label="clear search query"
                  edge="start"
                  onClick={() => setSearchText('')}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        },
      }}
    />
  );
}

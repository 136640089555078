import MuiCheckIcon from '@mui/icons-material/Check';
import MuiClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isNil } from 'lodash-es';

const NACell = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const CheckIcon = styled(MuiCheckIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 20,
}));

const ClearIcon = styled(MuiClearIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: 20,
}));

export const renderCellWithNA = (params: GridRenderCellParams) => {
  if (isNil(params.value)) {
    return <NACell>N/A</NACell>;
  } else {
    return undefined;
  }
};

export const renderBoolean = (params: GridRenderCellParams) => {
  if (params.value === true) {
    return <CheckIcon />;
  } else if (params.value === false) {
    return <ClearIcon />;
  } else {
    return <NACell>N/A</NACell>;
  }
};

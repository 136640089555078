import { alpha, Box, LinearProgress, Typography } from '@mui/material';
import { useProjectsStorage } from '@pn/core/storage';
import { useTrackProjectLoading } from 'src/ui/workspace/project-controls/useTrackProjectLoading';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    backdropFilter: 'blur(8px)',
  },
}));

export const ProjectLoadingOverlay = () => {
  const { classes } = useStyles();

  const { selectedProject, isProjectLoaded } = useProjectsStorage();

  const { numberOfLoadedItems, totalNumberOfItems } = useTrackProjectLoading(
    selectedProject.id
  );

  if (isProjectLoaded) return null;

  const isIndeterminate = totalNumberOfItems === 0; // this will happen when the project is loaded from URL

  return (
    <Box className={classes.container}>
      <Box width="100%" maxWidth={360}>
        <LinearProgress
          variant="determinate"
          value={
            isIndeterminate
              ? 0
              : (numberOfLoadedItems / totalNumberOfItems) * 100
          }
        />
        <Typography color="textSecondary" align="center" sx={{ mt: 1 }}>
          {isIndeterminate
            ? 'Loading project...'
            : `${numberOfLoadedItems}/${totalNumberOfItems} layers loaded...`}
        </Typography>
      </Box>
    </Box>
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { isEmpty } from 'lodash-es';
import React from 'react';

export const InvalidIdsDialog = React.memo(_InvalidIdsDialog);
function _InvalidIdsDialog() {
  const { invalidDataItemIds } = useWorkspaceStorage();

  /**
   * If we were to drive the `open` prop from ids directly, the dialog would
   * experience a layout shift when closed.
   */
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isEmpty(invalidDataItemIds)) {
      setIsOpen(true);
    }
  }, [invalidDataItemIds]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => workspaceActions().updateInvalidDataItemIds([]), 500); // after the fade-out dialog animation
  };

  return (
    <Dialog open={isOpen} fullWidth onClose={handleClose}>
      <DialogTitle>Invalid input data found</DialogTitle>
      <DialogContent>
        <Box>
          <DialogContentText>
            We couldn't match the following{' '}
            {invalidDataItemIds.length > 1
              ? `${invalidDataItemIds.length.toLocaleString()} items so they were`
              : 'item so it was'}{' '}
            omitted:
          </DialogContentText>
        </Box>

        <Box maxHeight="240px" overflow="auto" mt={2}>
          <Typography variant="body1">
            {invalidDataItemIds.join(', ')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

import { toGeoPoint } from '@pn/core/domain/geography';
import { debounce, some } from 'lodash-es';
import type { MapboxIMap } from '..';
import { loadImages } from '../mapImages';
import { configureMapboxMap, setLastMapPosition } from '../mapboxMapConfig';
import { mapboxStyle } from '../style';
import { updateStyleLayers } from './updateMode';

const debouncedSetLastMapPosition = debounce(setLastMapPosition, 500);

export const initialize: MapboxIMap['initialize'] = async function (
  this: MapboxIMap,
  mapContainer,
  signal,
  options
): Promise<void> {
  if (isMapAlreadyRendered(mapContainer)) {
    console.log('%cSkipping initialization of the map', 'color: #aaa');
    return;
  }

  return new Promise<void>((resolveInit, rejectInit) => {
    signal.addEventListener('abort', () => {
      // console.log('::: map initialization aborted :::');
      return rejectInit();
    });

    const mapboxStyleWithMode = updateStyleLayers(
      mapboxStyle,
      options.initMapMode
    );
    this._native = configureMapboxMap(
      mapContainer,
      mapboxStyleWithMode,
      options
    );

    const mapHasLoaded = new Promise<void>((resolve) =>
      this._native.on('load', () => {
        loadImages(this._native);

        resolve();
      })
    );
    const styleHasLoaded = new Promise<void>((resolve) =>
      this._native.on('styledata', () => {
        resolve();
      })
    );

    Promise.all([mapHasLoaded, styleHasLoaded]).then(() => {
      this._native.on('move', () => {
        const { lng, lat } = this._native.getCenter();
        debouncedSetLastMapPosition({
          center: toGeoPoint(lat, lng),
          zoom: this._native.getZoom(),
        });
      });

      setIsDone(true);
      // console.log('::: map initialized :::');
      return resolveInit();
    });
  });
};

// HACK
let isDone = false;
export function setIsDone(state: boolean): void {
  isDone = state;
}

function isMapAlreadyRendered(mapContainer: HTMLElement): boolean {
  return (
    isDone &&
    some(
      Array.from(mapContainer.children).map((childNode) => childNode.className),
      (className) => className.includes('mapboxgl')
    )
  );
}

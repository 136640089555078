import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Details } from 'src/ui/main-panel/components/Details';
import { Documents } from 'src/ui/main-panel/components/Documents';
import type { MainPanelRoute } from 'src/ui/main-panel/routes';

export const pipelinesRoutes: MainPanelRoute[] = [
  {
    title: 'Details',
    Icon: ArticleOutlinedIcon,
    IconSelected: ArticleIcon,
    path: '/pipelines/:id/details',
    getComponent: ({ item }) => <Details item={item} />,
  },
  {
    title: 'Documents',
    path: '/pipelines/:id/documents',
    Icon: InsertDriveFileOutlinedIcon,
    IconSelected: InsertDriveFileIcon,
    getComponent: ({ item }) => <Documents item={item} />,
  },
];

import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useCurrentUserStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isEmpty } from 'lodash-es';
import { useLibraryActions } from 'src/ui/workspace/library/LibraryData/useLibraryActions';
import { makeStyles } from 'tss-react/mui';
import { LibraryGridEmptyState } from '../components/LibraryGridEmptyState';
import { LibraryLoadingIndicator } from '../components/LibraryLoadingIndicator';
import { useLibrary } from '../LibraryProvider';
import { StackDXMarketingPage } from '../StackDXMarketingPage';
import { useLibraryColumns } from './useLibraryColumns';
import { useLibraryRows } from './useLibraryRows';

const useStyles = makeStyles()(() => ({
  tableContainer: {
    overflow: 'auto',
  },
  dataGrid: {
    '& .MuiDataGrid-columnHeader.checkbox-header': {
      padding: 0,
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      '&:hover': {
        '.project-icon-button': {
          display: 'inline-flex',
        },
      },
    },
    '& .MuiDataGrid-cell': {
      outline: 'none !important',
    },
  },
}));

export const ItemsTable = () => {
  const { classes } = useStyles();

  const { isStackUser } = useCurrentUserStorage();
  const { isFetching, isFetchingStack } = useWorkspaceStorage();

  const { libraryRouter } = useLibrary();
  const { handleRowClick } = useLibraryActions();

  const isLoading =
    isFetching || (libraryRouter.folder === 'StackDX' && isFetchingStack);

  const showStackMarketingPage =
    libraryRouter.folder === 'StackDX' && !isStackUser;

  const rows = useLibraryRows();
  const columns = useLibraryColumns();

  return (
    <Box className={classes.tableContainer}>
      {isEmpty(rows) ? (
        showStackMarketingPage ? (
          <StackDXMarketingPage />
        ) : isLoading ? (
          <LibraryLoadingIndicator />
        ) : (
          <LibraryGridEmptyState />
        )
      ) : (
        <DataGridPro
          className={classes.dataGrid}
          rowHeight={56}
          rows={rows}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          columnVisibilityModel={{ origin: libraryRouter.folder === 'StackDX' }}
          onRowClick={handleRowClick}
          localeText={{
            footerTotalRows: 'Total:',
            footerRowSelected: (count) =>
              `${count.toLocaleString()} layer${
                count !== 1 ? 's' : ''
              } active in the workspace`,
          }}
        />
      )}
    </Box>
  );
};

import { useLibrary } from '../LibraryProvider';
import { ItemsTable } from './ItemsTable';
import { ProjectsGrid } from './ProjectsGrid';
import { SearchResults } from './SearchResults';

export const LibraryData = () => {
  const { searchValue, libraryRouter } = useLibrary();

  if (searchValue !== '') {
    return <SearchResults />;
  }

  switch (libraryRouter.section) {
    case 'projects':
      return <ProjectsGrid />;
    case 'items':
      return <ItemsTable />;
  }
};

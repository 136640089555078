import { Store } from '@reduxjs/toolkit';
import React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';

// const storeContext = React.createContext<any>(undefined);

type Props = {
  children: React.ReactNode;
  store: Store;
};

export const ReduxStoreProvider = ({ children, store }: Props) => {
  return (
    // <Provider store={store} context={storeContext}>
    <Provider store={store}>{children}</Provider>
  );
};

// export const useStoreDispatch = createDispatchHook(storeContext);
// export const useStoreSelector = createSelectorHook(storeContext);

export const useStoreDispatch = useDispatch; // not used
export const useStoreSelector = useSelector;

export type RootState = Record<string, unknown>;

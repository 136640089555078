export function hasOnlyDigits(value: string): boolean {
  return /^-?\d+$/.test(value);
}

/**
 * Takes 2 strings and returns true if string 1 is a subset of string 2 or if
 * string 2 is a subset of string 1.
 */
export function hasPartialOverlap(string1: string, string2: string): boolean {
  const string1Lower = string1.toLowerCase();
  const string2Lower = string2.toLowerCase();

  return (
    string1Lower.includes(string2Lower) || string2Lower.includes(string1Lower)
  );
}

/**
 * The first copy will get (1) appended to the name.
 * The next copies will increment the number inside the parentheses.
 */
export function generateCopyName(name: string): string {
  const regex = /\((\d+)\)/;
  const match = regex.exec(name);
  if (match) {
    const counter = Number(match[1]);
    return name.replace(regex, `(${counter + 1})`);
  }
  return `${name} (1)`;
}

import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ReducerSync } from '.';
import { intelAlertsSlice } from '@pn/core/storage/intel/intelAlertsSlice.ts';

const { actions } = intelAlertsSlice;

export const intelAlerts: ReducerSync<typeof actions> = {
  add: async (intelAlert, dispatch) => {
    const response = await pnApiClient.request<{ id: string }>({
      method: 'POST',
      url: 'v1/market_intel/alert_subscriptions',
      payload: {
        annotation_id: intelAlert.annotationId,
      },
    });
    dispatch(
      actions._updateId({
        currentId: intelAlert.id,
        newId: response.id,
      })
    );
  },
  update: (updatePayload) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v1/market_intel/alert_subscriptions/${updatePayload.alert}`,
      payload: {
        annotation_id: updatePayload.alert.annotationId,
        enabled: updatePayload.alert.enabled,
      },
    });
  },
  toggle: (intelAlertToToggle) => {
    return pnApiClient.request({
      method: 'PUT',
      url: `v1/market_intel/alert_subscriptions/${intelAlertToToggle.id}`,
      payload: {
        enabled: !intelAlertToToggle.enabled,
      },
    });
  },
  remove: (intelAlertId) => {
    return pnApiClient.request({
      method: 'DELETE',
      url: `v1/market_intel/alert_subscriptions/${intelAlertId}`,
    });
  },
};

import { Tooltip, type TooltipProps } from '@mui/material';

export function CustomTooltip(props: TooltipProps & { disabled?: boolean }) {
  const { children, disabled = false, ...rest } = props;

  if (disabled) {
    return children;
  } else {
    return <Tooltip {...rest}>{children}</Tooltip>;
  }
}

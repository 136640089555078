import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LayersIcon from '@mui/icons-material/Layers';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import {
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { getProjectItemCount, type Project } from '@pn/core/domain/project';
import { getFullName } from '@pn/core/domain/user';
import { handleError } from '@pn/core/errors/handleError';
import { useProjectsStorage } from '@pn/core/storage';
import { formatISODateTimeStringAsDate } from '@pn/core/utils/date';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { useMenu } from '@pn/ui/hooks/useMenu';
import { useProjectState } from 'src/ui/workspace/project-controls/ProjectStateProvider';
import { useProjectActions } from 'src/ui/workspace/project-controls/useProjectActions';
import { useShareDialog } from 'src/ui/workspace/share/ShareDialogProvider';
import { makeStyles } from 'tss-react/mui';

export const PROJECT_CARD_HEIGHT = 100;

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'grid',
    gridTemplateAreas: `
    'content side'
    `,
    gridTemplateColumns: '1fr min-content',
    gridTemplateRows: '1fr min-content',
    height: PROJECT_CARD_HEIGHT,
  },
  cardButton: {
    gridArea: 'content',
    display: 'block',
    justifyContent: 'unset',
    alignItems: 'unset',
    width: '100%',
    height: '100%',
    padding: theme.spacing(1.5),
    paddingRight: 0,
    textAlign: 'left',
    overflow: 'hidden',
  },
  side: {
    gridArea: 'side',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    paddingLeft: 0,
  },
  menu: {
    width: '20ch',
  },
  projectName: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

type Props = {
  project: Project;
  isEditable: boolean;
  onClick: () => void;
};

export const ProjectCard = ({ project, isEditable, onClick }: Props) => {
  const { classes } = useStyles();

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();

  return (
    <Paper variant="outlined" className={classes.card}>
      <ButtonBase className={classes.cardButton} onClick={onClick}>
        <Typography title={project.name} noWrap>
          {project.name}
        </Typography>
        {project.createdBy && (
          <Typography variant="body2" color="textSecondary" noWrap>
            {getFullName(project.createdBy)}
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary" noWrap>
          Last modified on {formatISODateTimeStringAsDate(project.updatedAt)}
        </Typography>
      </ButtonBase>

      <Box className={classes.side}>
        <Box>
          <IconButton component="div" size="small" onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
          <QuickMenu
            project={project}
            isEditable={isEditable}
            anchorEl={anchorEl}
            onClose={closeMenu}
          />
        </Box>

        <Box display="flex" gap={0.5}>
          <LayersIcon color="action" fontSize="small" />
          <Typography variant="body2" color="textSecondary" noWrap>
            {getProjectItemCount(project)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

type MenuProps = {
  project: Project;
  isEditable: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

const QuickMenu = ({ project, isEditable, anchorEl, onClose }: MenuProps) => {
  const { classes } = useStyles();

  const { selectedProject } = useProjectsStorage();

  const { handleStartRenaming, handleResetProject } = useProjectState();
  const { inProgress, duplicateProject, deleteProject } = useProjectActions();
  const { openShareDialog } = useShareDialog();
  const { handleOpenConfirmationDialog } = useConfirmationDialog();

  const isDisabled = inProgress || !isEditable;

  const handleShare = () => {
    onClose();
    openShareDialog('project', project.id);
  };

  const handleDuplicate = async () => {
    try {
      await duplicateProject(project);

      onClose();
      handleStartRenaming();
    } catch (error) {
      handleError({
        error,
        userFriendlyMessage: 'Failed to recreate this project',
      });
    }
  };

  const handleDeleteWithConfirmation = () => {
    onClose();

    handleOpenConfirmationDialog({
      title: 'Delete project?',
      text: (
        <Typography>
          Are you sure you want to delete{' '}
          <span className={classes.projectName}>{project.name}</span>? This
          action cannot be undone.
        </Typography>
      ),
      action: () => {
        deleteProject(project);
        if (selectedProject.id === project.id) handleResetProject();
      },
    });
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{ paper: classes.menu }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem disabled={inProgress} onClick={handleShare}>
        <ListItemIcon>
          <ShareIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Share</ListItemText>
      </MenuItem>
      <MenuItem disabled={inProgress} onClick={handleDuplicate}>
        <ListItemIcon>
          <CopyAllIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Duplicate</ListItemText>
        {inProgress && <CircularProgress size={18} />}
      </MenuItem>
      <MenuItem disabled={isDisabled} onClick={handleDeleteWithConfirmation}>
        <ListItemIcon>
          <DeleteForeverIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );
};

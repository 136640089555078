import { createSlice } from '@reduxjs/toolkit';
import { CompanyDocument } from "@pn/core/domain/intel/document";

type CompanyDocumentState = {
  isFetching: boolean;
  isError: boolean;
  documents: CompanyDocument[];
};

const initialState: CompanyDocumentState = {
  isFetching: false,
  isError: false,
  documents: [],
};

export const createCompanyDocumentsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.documents = payload;
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.documents = [];
      },
    },
  });

export const companyDocumentsSlice =
  createCompanyDocumentsSlice('companyDocuments');

import { isArray, isNil, isObject } from 'lodash-es';
import murmurHash3 from 'murmurhash3js';

/**
 * @returns 52-bit integer
 */
export const murmurhash3 = function (str: string, seed = 0): number {
  const hex = murmurHash3.x64.hash128(str, seed);
  return parseInt(hex.slice(-13), 16);
};

// function testHash(input: string, expected: number) {
//   const hash = murmurhash3(input);
//   if (hash !== expected) {
//     console.error('Failed', input, expected, hash);
//   } else {
//     console.log('Passed ✔️', input);
//   }
// }

// testHash('200D016E093P1000', 1326785488606905);
// testHash('100013402503W500', 2530939982341183);
// testHash('0.5361919331715734', 128924225);
// testHash('0.48378164131146706', 871537380);
// testHash('0.9335884063048236', 2089891851);
// testHash('0.07109617646269162', 4503599148082415);
// testHash('0.18223156773643334', 4503599097664695);
// testHash('0.41726605316895893', 8608319864832);
// testHash('0.59031458465768', 4414662644793343);

type NestedValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | NestedObject
  | NestedArray;
type NestedArray = NestedValue[];
type NestedObject = { [key: string]: NestedValue };

/**
 * @note
 * This function sorts the arrays contents as well but will not process the
 * objects within those arrays.
 */
function sortObject(obj: NestedValue): NestedValue {
  if (isNil(obj) || !isObject(obj)) {
    return obj;
  }

  if (isArray(obj)) {
    const sortedArray = (obj as NestedArray).map(sortObject) as NestedArray;
    return sortedArray.sort(); // add your own comparison function here if needed
  }

  const sortedKeys = Object.keys(obj as NestedObject).sort();
  const result: NestedObject = {};
  sortedKeys.forEach((key) => {
    result[key] = sortObject((obj as NestedObject)[key]);
  });

  return result;
}

export function hashObject(obj: object): number {
  const str = JSON.stringify(obj);
  const hash = murmurhash3(str);

  return hash;
}

export function hashSortedObject(obj: NestedObject): number {
  const sortedObject = sortObject(obj);
  const str = JSON.stringify(sortedObject);
  const hash = murmurhash3(str);

  return hash;
}

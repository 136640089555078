import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { dynamicDataProvider } from '@pn/core/providers/data/dynamicDataProvider';
import { generateNonce, log } from '@pn/core/utils/debug';
import { uniq } from 'lodash-es';
import { getMapData, type MapData } from './mapData';

export async function getMapDataWithExtraFields(
  item: WorkspaceItem,
  extraFields: string[]
): Promise<MapData> {
  const mapping = await dynamicDataProvider(item.dataSource).getDataMapping(
    item
  );

  const nonce = generateNonce();
  log.time('mapData [extraFields]', nonce);
  const mapData = await getMapData({
    item: {
      ...item,
      dataSource:
        item.dataSource.type === 'api'
          ? {
              ...item.dataSource,
              requiredMapDataFields: uniq([
                ...item.dataSource.requiredMapDataFields,
                ...extraFields,
              ]),
            }
          : item.dataSource,
    },
    mapping,
    silent: true,
  });
  log.timeEnd('mapData [extraFields]', nonce);
  log.info('mapData [extraFields]', mapData);

  return mapData;
}

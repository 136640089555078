import { ExtraCompanyInfo } from '@pn/core/domain/intel/intel';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  isError: false,
  company: undefined as ExtraCompanyInfo | undefined,
};

export const createCompanySlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.company = payload;
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.company = undefined;
      },
    },
  });

export const companyExtraInfoSlice = createCompanySlice('company');

import { dependencies } from '@pn/core/dependencies';
import type { Details, Document } from '@pn/core/domain/data-info';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useInjectableReducer } from '@pn/core/storage/injectableReducers';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import type {
  IDataDetailsActions,
  IDataDetailsStorage,
  IDataDocumentsActions,
  IDataDocumentsStorage,
} from './ports';
import { createDataInfoSlice } from '@pn/core/storage/data-info/dataInfoSlice';

export const useReduxDataDetailsStorage = (
  itemId: WorkspaceItem['id']
): IDataDetailsStorage => {
  const sliceName = getSliceName(itemId, 'details');
  const { reducer, getInitialState } = createDataInfoSlice<Details>(sliceName);

  const useSliceSelector = useBuildSliceSelector(sliceName, getInitialState());

  useInjectableReducer(sliceName, reducer);

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    details: useSliceSelector((state) => state.payload),
  };
};

export const reduxDataDetailsActions = (
  dataType: string
): IDataDetailsActions => {
  const { dispatch, getState } = dependencies.store;

  const sliceName = getSliceName(dataType, 'details');
  const { actions } = createDataInfoSlice<Details>(sliceName);

  if (isNil(getState()[sliceName])) {
    throw new Error(`[${sliceName}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    error: () => dispatch(actions.error()),
    receive: (details) => dispatch(actions.receive(details)),
    reset: () => dispatch(actions.reset()),
  };
};

export const useReduxDataDocumentsStorage = (
  dataType: string
): IDataDocumentsStorage => {
  const sliceName = getSliceName(dataType, 'documents');

  const { reducer, getInitialState } =
    createDataInfoSlice<Document[]>(sliceName);

  const useSliceSelector = useBuildSliceSelector(sliceName, getInitialState());

  useInjectableReducer(sliceName, reducer);

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    documents: useSliceSelector((state) => state.payload),
  };
};

export const reduxDataDocumentsActions = (
  dataType: string
): IDataDocumentsActions => {
  const { dispatch, getState } = dependencies.store;

  const sliceName = getSliceName(dataType, 'documents');
  const { actions } = createDataInfoSlice<Document[]>(sliceName);

  if (isNil(getState()[sliceName])) {
    throw new Error(`[${sliceName}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    error: () => dispatch(actions.error()),
    receive: (documents) => dispatch(actions.receive(documents)),
    reset: () => dispatch(actions.reset()),
  };
};

function getSliceName(dataType: string, payloadType: string): string {
  return `${dataType}-${payloadType}`;
}

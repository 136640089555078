import { Paper, ToggleButton } from '@mui/material';
import { ChartScale } from '@pn/services/charts/types';
import { ExclusiveToggleButtonGroup } from '@pn/ui/custom-components/ExclusiveToggleButtonGroup';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  toggleButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    textTransform: 'none',
  },
}));

type Props = {
  disabled: boolean;
  scale: ChartScale;
  onChange: (newScale: ChartScale) => void;
  fullWidth?: boolean;
};

export function ChartScaleSelector({
  disabled,
  scale,
  onChange,
  fullWidth,
}: Props) {
  const { classes } = useStyles();

  return (
    <Paper elevation={0}>
      <ExclusiveToggleButtonGroup
        fullWidth={fullWidth}
        color="primary"
        exclusive
        disabled={disabled}
        value={scale}
        onChange={(_event, newScale) => onChange(newScale)}
        aria-label="Scale"
      >
        {Object.entries(ChartScale).map(([key, value]) => (
          <ToggleButton
            key={value}
            value={value}
            className={classes.toggleButton}
          >
            {format(key, value)}
          </ToggleButton>
        ))}
      </ExclusiveToggleButtonGroup>
    </Paper>
  );
}

function format(key: string, value: string) {
  if (value === ChartScale.Logarithmic) return 'Log';
  return key;
}

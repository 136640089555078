import { MapMode } from '@pn/core/domain/map';
import { isEmbedded } from '@pn/core/utils/embedded';
import Color from 'color';

export const DEFAULT_SYMBOL_SIZE = 16;
export const DEFAULT_LINE_WIDTH = 2;
export const DEFAULT_COLOR = Color.hsl(0, 0, 10).string(); // 90% grey (darker than grey 900)
export const DEFAULT_COLOR_HEX = Color(DEFAULT_COLOR).hex();
export const DEFAULT_POLYGON_OPACITY = isEmbedded() ? 1.0 : 0.5;

export const SELECTED_SYMBOL_SIZE = 36;
export const SELECTED_LINE_WIDTH = 4;

export const DEFAULT_SYMBOL_STYLE = {
  size: DEFAULT_SYMBOL_SIZE,
  color: DEFAULT_COLOR,
  field: '{symbol}',
  allowOverlap: true,
  ignorePlacement: true,
};

export const mapModeDefaultColors: Record<MapMode, string> = {
  [MapMode.Standard]: DEFAULT_COLOR,
  [MapMode.Light]: DEFAULT_COLOR,
  [MapMode.Dark]: Color.hsl(160, 0, 50).string(),
  [MapMode.Satellite]: Color.hsl(160, 0, 100).string(),
};

import { formatDataType, type DataItem } from '@pn/core/domain/data';
import { getExportableQuery } from '@pn/core/domain/query';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { dynamicDataProvider } from '@pn/core/providers/data/dynamicDataProvider';
import { log } from '@pn/core/utils/debug';
import { appendArrayInPlace } from '@pn/core/utils/logic';
import { isNil } from 'lodash-es';
import {
  removePersistentStreamingNotification,
  showPersistentStreamingNotification,
} from './streamingNotification';

const abortControllers: Record<WorkspaceItem['id'], AbortController> = {};

type ExportedData = {
  data: DataItem[];
  totalCount: number;
};

export async function exportWorkspaceItem(
  item: WorkspaceItem,
  fields: string[]
): Promise<ExportedData> {
  log.startGroup(`exportQuery [${item.id}]`);

  let notificationId: undefined | string = undefined;

  const exportQuery = getExportableQuery(item.query, fields);

  const mapping = await dynamicDataProvider(item.dataSource).getDataMapping(
    item
  );

  const exportedData: ExportedData = {
    data: [],
    totalCount: 0,
  };

  try {
    abortControllers[item.id] = new AbortController();

    await dynamicDataProvider(item.dataSource).streamDataByQuery({
      query: exportQuery,
      mapping,
      receiveTotalCount: (totalCount) => {
        exportedData.totalCount = totalCount;
      },
      receiveChunk: (chunkData) => {
        appendArrayInPlace(exportedData.data, chunkData);

        /**
         * Display notification if we are actually streaming data.
         */
        if (isNil(notificationId)) {
          notificationId = showPersistentStreamingNotification({
            action: 'Exporting',
            dataType: item.dataType,
            totalCount: exportedData.totalCount,
            onCancel: () => abortControllers[item.id].abort(),
          });
        }
      },
      signal: abortControllers[item.id].signal,
    });
  } catch (error) {
    handleError({
      error,
      onError: () => abortControllers[item.id].abort(),
      userFriendlyMessage: `Failed to export ${formatDataType(
        item.dataType
      )} data`,
    });
  }

  removePersistentStreamingNotification(notificationId);

  log.endGroup(`exportQuery [${item.id}]`);

  return exportedData;
}

import { createTheme, darkScrollbar } from '@mui/material';
import { productionColors, sharedThemeTokens, typography } from './shared';

export const Holiday = createTheme(
  {
    palette: {
      mode: 'dark',
      primary: {
        main: '#006400', // Evergreen green as the primary color
      },
      secondary: {
        main: '#B22222', // Classic Christmas red as the secondary color
      },
      background: {
        default: '#1A1A1A', // Dark background to let colors stand out
        paper: '#2E2E2E', // Slightly lighter for a subtle layered effect
      },
      text: {
        primary: '#FFFFFF', // White for readability
        secondary: '#FFD700', // Gold for festive accents
      },
      warning: {
        main: '#FFD700', // Gold for holiday highlights
      },
      error: {
        main: '#B22222', // Red for error or festive touches
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: darkScrollbar(),
        },
      },
    },
    borderColor: 'rgba(255, 215, 0, 0.4)', // Gold for a subtle festive outline
    productionColors,
    typography,
  },
  sharedThemeTokens
);

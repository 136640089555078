import { LayerType, type LayerStyleProperty } from '@pn/core/domain/layer';
import type { ILayerStyleMapper } from '@pn/core/mappers/layer';
import { isArray, isNil, set, toPairs } from 'lodash-es';

type KeysOfUnion<T> = T extends T ? keyof T : never; // https://stackoverflow.com/a/49402091/5309948

type MapboxStyleProperty = KeysOfUnion<mapboxgl.AnyLayout & mapboxgl.AnyPaint>;
type MapboxStyleType = 'layout' | 'paint';
type MapboxStyleValue = unknown;

export type MapboxStyle = {
  layout?: mapboxgl.AnyLayout;
  paint?: mapboxgl.AnyPaint;
};

export const mapboxLayerStyleMapper: ILayerStyleMapper<MapboxStyle> = {
  toDomainLayerStyle: (mapboxLayerStyle) => {
    if (mapboxLayerStyle.layout?.visibility) {
      delete mapboxLayerStyle.layout.visibility;
    }

    /* Merge layout and paint styles into one object */
    const mapboxStyleArray = toPairs({
      ...mapboxLayerStyle.layout,
      ...mapboxLayerStyle.paint,
    }) as [MapboxStyleProperty, MapboxStyleValue][]; // $5.00 to whoever can fix this type

    return mapboxStyleArray.reduce((acc, [styleProperty, styleValue]) => {
      return set(
        acc,
        mapboxStylePropertyToLayerStyleProperty(styleProperty),
        styleValue
      );
    }, {});
  },
  toTargetLayerStyle: (styles, layerType) => {
    return Object.entries(styles).reduce(
      (mapboxStyle, [styleProperty, styleValue]) => {
        const { mapboxStyleType, mapboxStyleProperties } = getMapboxStyle(
          layerType,
          styleProperty as LayerStyleProperty
        );

        if (isNil(mapboxStyleProperties)) {
          console.warn(
            'No mapbox style property found for',
            styleProperty,
            `(${layerType})`
          );
        } else if (isArray(mapboxStyleProperties)) {
          /* 1-to-many */
          mapboxStyleProperties.forEach((property) => {
            set(mapboxStyle, [mapboxStyleType, property], styleValue);
          });
        } else {
          /* 1-to-1 */
          set(
            mapboxStyle,
            [mapboxStyleType, mapboxStyleProperties],
            styleValue
          );
        }

        return mapboxStyle;
      },
      {
        layout: {
          visibility: 'visible',
        },
      }
    );
  },
};

function getMapboxStyle(
  layerType: LayerType,
  styleProperty: LayerStyleProperty
): {
  mapboxStyleType: MapboxStyleType;
  mapboxStyleProperties:
    | MapboxStyleProperty
    | MapboxStyleProperty[]
    | undefined;
} {
  return {
    mapboxStyleType: mapboxLayoutProps.includes(styleProperty)
      ? 'layout'
      : 'paint',
    mapboxStyleProperties: getMapboxStyleProperties(layerType)[styleProperty],
  };
}

/**
 * All domain style properties that will be mapped into Mapbox's `layout` object.
 * The rest will fall into `paint`.
 */
const mapboxLayoutProps = [
  'size',
  'padding',
  'offset',
  'allowOverlap',
  'ignorePlacement',
  'placement',
  'lineHeight',
  'field',
  'font',
  'image',
  'maxWidth',
  'lineCap',
  'lineJoin',
  'symbolSortKey',
  'symbolZOrder',
];

/**
 * Domain style properties to Mapbox style properties.
 */
export function getMapboxStyleProperties(
  layerType: LayerType
): Partial<
  Record<LayerStyleProperty, MapboxStyleProperty | MapboxStyleProperty[]>
> {
  switch (layerType) {
    case LayerType.Icon:
    case LayerType.Text:
      return {
        size: ['icon-size', 'text-size'],
        color: ['icon-color', 'text-color'],
        padding: ['icon-padding', 'text-padding'],
        haloBlur: ['icon-halo-blur', 'text-halo-blur'],
        haloColor: ['icon-halo-color', 'text-halo-color'],
        haloWidth: ['icon-halo-width', 'text-halo-width'],
        opacity: ['icon-opacity', 'text-opacity'],
        anchor: ['icon-anchor', 'text-anchor'],
        offset: ['icon-offset', 'text-offset'],
        allowOverlap: ['icon-allow-overlap', 'text-allow-overlap'],
        ignorePlacement: ['icon-ignore-placement', 'text-ignore-placement'],
        image: 'icon-image',
        field: 'text-field',
        font: 'text-font',
        maxWidth: 'text-max-width',
        lineHeight: 'text-line-height',
        placement: 'symbol-placement',
        symbolSortKey: 'symbol-sort-key',
        symbolZOrder: 'symbol-z-order',
      };
    case LayerType.Line:
      return {
        color: 'line-color',
        width: 'line-width',
        opacity: 'line-opacity',
        dashArray: 'line-dasharray',
        lineCap: 'line-cap',
        lineJoin: 'line-join',
      };
    case LayerType.Polygon:
      return {
        color: 'fill-color',
        outlineColor: 'fill-outline-color',
        opacity: 'fill-opacity',
        pattern: 'fill-pattern',
      };
    case LayerType.Circle:
      return {
        color: 'circle-color',
        radius: 'circle-radius',
        opacity: 'circle-opacity',
        strokeColor: 'circle-stroke-color',
        strokeWidth: 'circle-stroke-width',
      };
    case LayerType.Raster:
      return {
        opacity: 'raster-opacity',
      };
    default:
      return {};
  }
}

/**
 * Mapbox style properties to domain style properties.
 */
function mapboxStylePropertyToLayerStyleProperty(
  mapboxStyleProperty: MapboxStyleProperty
): LayerStyleProperty {
  switch (mapboxStyleProperty) {
    case 'text-size':
    case 'icon-size':
      return 'size';

    case 'circle-radius':
      return 'radius';

    case 'text-color':
    case 'icon-color':
    case 'circle-color':
    case 'fill-color':
    case 'line-color':
      return 'color';

    case 'line-width':
      return 'width';
    case 'line-dasharray':
      return 'dashArray';
    case 'line-cap':
      return 'lineCap';
    case 'line-join':
      return 'lineJoin';

    case 'fill-opacity':
    case 'line-opacity':
    case 'circle-opacity':
    case 'icon-opacity':
    case 'text-opacity':
    case 'raster-opacity':
      return 'opacity';

    case 'fill-outline-color':
      return 'outlineColor';
    case 'circle-stroke-color':
      return 'strokeColor';
    case 'circle-stroke-width':
      return 'strokeWidth';

    case 'fill-pattern':
      return 'pattern';

    case 'icon-halo-color':
    case 'text-halo-color':
      return 'haloColor';

    case 'icon-halo-width':
    case 'text-halo-width':
      return 'haloWidth';

    case 'icon-halo-blur':
    case 'text-halo-blur':
      return 'haloBlur';

    case 'icon-padding':
    case 'text-padding':
      return 'padding';

    case 'icon-allow-overlap':
    case 'text-allow-overlap':
      return 'allowOverlap';

    case 'icon-ignore-placement':
    case 'text-ignore-placement':
      return 'ignorePlacement';

    case 'icon-offset':
    case 'text-offset':
      return 'offset';

    case 'icon-anchor':
    case 'text-anchor':
      return 'anchor';

    case 'text-max-width':
      return 'maxWidth';
    case 'text-line-height':
      return 'lineHeight';

    case 'icon-image':
      return 'image';
    case 'text-field':
      return 'field';
    case 'text-font':
      return 'font';
    case 'symbol-placement':
      return 'placement';

    default:
      throw new Error(`Unknown mapbox style property: ${mapboxStyleProperty}`);
  }
}

import type { Theme } from '@mui/material';
import type {
  ProductionChartType,
  ProductionItem,
} from '@pn/core/domain/production';
import { UnitSystem } from '@pn/core/domain/types';
import {
  ChartScale,
  ProductionChartMode,
  toChartModeLabel,
  type ChartSeries,
  type ChartView,
} from '@pn/services/charts/types';
import {
  getMaxDate,
  getMinDate,
} from '@pn/services/charts/utils/chartDateRange';
import {
  getDefaultRange,
  getGasRange,
  getLiquidRange,
  getMaxValues,
} from '@pn/services/charts/utils/chartMaxValues';
import type {
  ChartOptions,
  ChartType,
  CoreScaleOptions,
  Scale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { isNumber, uniq } from 'lodash-es';

declare module 'chart.js' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    watermark: {
      enabled: boolean;
    };
  }
}

export function getChartJSOptions(
  productionItems: ProductionItem[],
  params: {
    theme: Theme;
    productionChartType: ProductionChartType;
    scale: ChartScale;
    mode: ProductionChartMode;
    unitSystem: UnitSystem;
    series: ChartSeries;
    chartView: ChartView;
    displayWatermark?: boolean;
  }
): ChartOptions<'line'> {
  const dates = uniq(productionItems.map((item) => item.date));
  const maxValues = getMaxValues(productionItems, params.mode);

  const isCountShown = ['list_aggregate', 'aggregate'].includes(
    params.productionChartType
  ); // FIXME duplicated in chartData.ts

  const gridLighterColor = params.theme.palette.action.hover;
  const gridDarkerColor = params.theme.palette.action.selected;

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        borderJoinStyle: 'round',
      },
    },
    scales: {
      x: {
        type: 'time',
        min: getMinDate(dates),
        max: getMaxDate(dates),
        time: {
          // unit: getTimeUnit(dates, params.range, params.chartView),
          tooltipFormat: 'MMM yyyy',
        },
        grid: {
          color: function (context) {
            if (new Date(context.tick.value).getMonth() === 0) {
              return gridDarkerColor;
            }

            return gridLighterColor;
          },
        },
        ticks: {
          color: params.theme.palette.text.secondary,
        },
      },
      y_liquid: {
        display: 'auto',
        position: 'left',
        type: params.scale,
        beginAtZero: true,
        stack: isCountShown ? 'stack-liquid' : undefined,
        stackWeight: isCountShown ? 5 : undefined,
        min:
          params.scale === ChartScale.Logarithmic
            ? getLiquidRange(params.mode).min
            : undefined,
        max:
          params.scale === ChartScale.Logarithmic
            ? maxValues.liquid
            : undefined,
        title: {
          display: true,
          color: params.theme.palette.text.secondary,
          padding: {
            y: 0,
            top: 8,
          },
          text: `Liquid ${getYAxisLabel(params.mode)} (${getLiquidUnits(
            params.mode,
            params.unitSystem
          )})`,
        },
        ticks: {
          color: params.theme.palette.text.secondary,
          autoSkip: false,
          callback: function (val) {
            if (!isNumber(val)) return '';
            if (params.scale === ChartScale.Linear)
              return this.getLabelForValue(val);

            return isTenBased(val) ? this.getLabelForValue(val) : '';
          },
          ...(params.chartView === 'main-panel' && {
            mirror: true,
            align: 'end',
            padding: 0,
          }),
        },
        border: {
          color: gridLighterColor,
        },
        grid: {
          color: function (context) {
            if (params.scale === ChartScale.Linear) return gridLighterColor;
            if (context.tick.major) return gridDarkerColor;

            return gridLighterColor;
          },
          drawTicks: params.chartView === 'fullscreen',
        },
        afterBuildTicks: skipExtraTicks,
        afterTickToLabelConversion: skipExtraTicks,
      },
      y_stack_filler_gas: {
        display: isCountShown && (params.series['Gas'] ?? false),
        stack: 'stack-gas',
        ...(getStackFillerAxisOptions() as any),
      },
      y_stack_filler_gor: {
        display: isCountShown && (params.series['GOR'] ?? false),
        stack: 'stack-gor',
        ...(getStackFillerAxisOptions() as any),
      },
      y_stack_filler_xgr: {
        display:
          isCountShown &&
          ((params.series['WGR'] || params.series['CGR']) ?? false),
        stack: 'stack-xgr',
        ...(getStackFillerAxisOptions() as any),
      },
      y_stack_filler_percent: {
        display: isCountShown && (params.series['Water Cut'] ?? false),
        stack: 'stack-percent',
        ...(getStackFillerAxisOptions() as any),
      },
      y_gas: {
        display: 'auto',
        position: 'right',
        type: params.scale as any,
        beginAtZero: true,
        stack: isCountShown ? 'stack-gas' : undefined,
        stackWeight: isCountShown ? 5 : undefined,
        min:
          params.scale === ChartScale.Logarithmic
            ? getGasRange(params.mode).min
            : undefined,
        max:
          params.scale === ChartScale.Logarithmic ? maxValues.gas : undefined,
        title: {
          display: true,
          color: params.theme.palette.text.secondary,
          padding: {
            y: 0,
            top: 8,
          },
          text: `Gas ${getYAxisLabel(params.mode)} (${getGasUnits(
            params.mode,
            params.unitSystem
          )})`,
        },
        ticks: {
          color: params.theme.palette.text.secondary,
          // @ts-expect-error bad types; count is a scriptable field
          count: (context: any) => context.chart.scales.y_liquid.ticks.length,
          autoSkip: false,
          callback: function (val) {
            if (!isNumber(val)) return '';
            if (params.scale === ChartScale.Linear)
              return this.getLabelForValue(val);

            return isTenBased(val) ? this.getLabelForValue(val) : '';
          },
          ...(params.chartView === 'main-panel' && {
            mirror: true,
            align: 'end',
            padding: 0,
          }),
        },
        border: {
          color: gridLighterColor,
        },
        grid: {
          drawOnChartArea: !params.series['Oil'] && !params.series['Water'],
          color: function (context) {
            if (params.scale === ChartScale.Linear) return gridLighterColor;
            if (context.tick.major) return gridDarkerColor;

            return gridLighterColor;
          },
          drawTicks: params.chartView === 'fullscreen',
        },
        afterBuildTicks: skipExtraTicks,
        afterTickToLabelConversion: skipExtraTicks,
      },
      y_gor: {
        display: 'auto',
        position: 'right',
        type: params.scale as any,
        beginAtZero: true,
        stack: isCountShown ? 'stack-gor' : undefined,
        stackWeight: isCountShown ? 5 : undefined,
        min:
          params.scale === ChartScale.Logarithmic
            ? getDefaultRange(params.mode).min
            : undefined,
        max:
          params.scale === ChartScale.Logarithmic ? maxValues.gor : undefined,
        title: {
          display: true,
          color: params.theme.palette.text.secondary,
          padding: {
            y: 0,
            top: 8,
          },
          text: `GOR (${getGORUnits(params.unitSystem)})`,
        },
        ticks: {
          color: params.theme.palette.text.secondary,
          callback: function (val) {
            if (!isNumber(val)) return '';
            if (params.scale === ChartScale.Linear)
              return this.getLabelForValue(val);

            return isTenBased(val) ? this.getLabelForValue(val) : '';
          },
        },
        border: {
          color: gridLighterColor,
        },
        grid: {
          drawOnChartArea: areTheOnlySeriesEnabled(params.series, ['GOR']),
          color: function (context) {
            if (params.scale === ChartScale.Linear) return gridLighterColor;
            if (context.tick.major) return gridDarkerColor;

            return gridLighterColor;
          },
        },
        afterBuildTicks: skipExtraTicks,
        afterTickToLabelConversion: skipExtraTicks,
      },
      y_xgr: {
        display: 'auto',
        position: 'right',
        type: params.scale as any,
        beginAtZero: true,
        stack: isCountShown ? 'stack-xgr' : undefined,
        stackWeight: isCountShown ? 5 : undefined,
        min:
          params.scale === ChartScale.Logarithmic
            ? getDefaultRange(params.mode).min
            : undefined,
        max:
          params.scale === ChartScale.Logarithmic ? maxValues.xgr : undefined,
        title: {
          display: true,
          color: params.theme.palette.text.secondary,
          padding: {
            y: 0,
            top: 8,
          },
          text: `WGR & CGR (${getXGRUnits(params.unitSystem)})`,
        },
        ticks: {
          color: params.theme.palette.text.secondary,
          callback: function (val) {
            if (!isNumber(val)) return '';
            if (params.scale === ChartScale.Linear)
              return this.getLabelForValue(val);

            return isTenBased(val) ? this.getLabelForValue(val) : '';
          },
        },
        border: {
          color: gridLighterColor,
        },
        grid: {
          drawOnChartArea: areTheOnlySeriesEnabled(params.series, [
            'WGR',
            'CGR',
          ]),
          color: function (context) {
            if (params.scale === ChartScale.Linear) return gridLighterColor;
            if (context.tick.major) return gridDarkerColor;

            return gridLighterColor;
          },
        },
        afterBuildTicks: skipExtraTicks,
        afterTickToLabelConversion: skipExtraTicks,
      },
      y_percent: {
        display: 'auto',
        position: 'right',
        stack: isCountShown ? 'stack-percent' : undefined,
        stackWeight: isCountShown ? 5 : undefined,
        min: 0,
        max: 100,
        title: {
          display: true,
          color: params.theme.palette.text.secondary,
          padding: 0,
          text: 'Ratio (%)',
        },
        ticks: {
          color: params.theme.palette.text.secondary,
          count: 11,
        },
        border: {
          color: gridDarkerColor,
        },
        grid: {
          color: gridDarkerColor,
          drawOnChartArea: areTheOnlySeriesEnabled(params.series, [
            'Water Cut',
          ]),
        },
      },
      y_count: {
        display: isCountShown,
        position: 'left',
        beginAtZero: true,
        stack: 'stack-liquid',
        stackWeight: 1,
        title: {
          display: true,
          color: params.theme.palette.text.secondary,
          padding: 0,
          text: 'Number of Wells',
        },
        ticks: {
          color: params.theme.palette.text.secondary,
          callback: function (val) {
            if (!isNumber(val)) return '';
            return val !== 0 ? this.getLabelForValue(val) : ''; // avoid overlap with last tick of y_liquid
          },
        },
        border: {
          color: gridLighterColor,
        },
        grid: {
          color: gridDarkerColor,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    animation: false,
    plugins: {
      title: {
        display: params.chartView === 'main-panel',
        text: toChartModeLabel(params.mode),
        color: params.theme.palette.text.secondary,
        font: {
          lineHeight: 0,
          family: 'Roboto',
          size: 12,
          weight: 'normal',
        },
      },
      // decimation: {
      //   enabled: true,
      //   algorithm: 'lttb',
      //   samples: 50,
      // },
      watermark: {
        enabled: params.displayWatermark,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }

            if (isNumber(context.parsed.y)) {
              label += context.parsed.y.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              });
              // @ts-expect-error `symbol` is a custom field
              label += ` ${context.dataset.symbol}`;
            }

            return label;
          },
        },
      },
    },
  };

  return options;
}

function getLiquidUnits(
  mode: ProductionChartMode,
  unitSystem: UnitSystem
): string {
  switch (mode) {
    case ProductionChartMode.CalendarDaily:
    case ProductionChartMode.ProducingDaily:
      return unitSystem === UnitSystem.Metric ? 'm3/d' : 'bbl/d';
    default:
      return unitSystem === UnitSystem.Metric ? 'm3' : 'bbl';
  }
}

function getGasUnits(
  mode: ProductionChartMode,
  unitSystem: UnitSystem
): string {
  switch (mode) {
    case ProductionChartMode.CalendarDaily:
    case ProductionChartMode.ProducingDaily:
      return unitSystem === UnitSystem.Metric ? 'e3m3/d' : 'mcf/d';
    default:
      return unitSystem === UnitSystem.Metric ? 'e3m3' : 'mcf';
  }
}

function getGORUnits(unitSystem: UnitSystem): string {
  return unitSystem === UnitSystem.Metric ? 'm3/m3' : 'scf/bbl';
}

function getXGRUnits(unitSystem: UnitSystem): string {
  return unitSystem === UnitSystem.Metric ? 'm3/e3m3' : 'bbl/mmscf';
}

export function isTenBased(num: number) {
  const num1 = num * 1000; // to handle small numbers up to 0.01
  const str = num1.toString();
  const first = str.slice(0, 1);
  const rest = str.slice(1, str.length);
  return first === '1' && parseFloat(rest) === 0;
}

export const yAxesIds = [
  'y_liquid',
  'y_gas',
  'y_gor',
  'y_xgr',
  'y_percent',
  'y_count',
] as const;

function getYAxisLabel(mode: ProductionChartMode): string {
  switch (mode) {
    case ProductionChartMode.CalendarDaily:
    case ProductionChartMode.ProducingDaily:
      return 'Rate';
    default:
      return 'Volume';
  }
}

function areTheOnlySeriesEnabled(
  series: ChartSeries,
  allowedKeys: string[]
): boolean {
  const allKeys = Object.keys(series);
  const disallowedKeys = allKeys.filter((key) => !allowedKeys.includes(key));
  if (disallowedKeys.some((key) => series[key])) return false; // at least one other series is enabled
  if (allowedKeys.every((key) => !series[key])) return false; // neither of the allowed series is enabled
  return true;
}

function getStackFillerAxisOptions() {
  return {
    position: 'right',
    stackWeight: 1,
    title: {
      display: false,
    },
    ticks: {
      display: false,
    },
    grid: {
      display: false,
    },
  };
}

/**
 * We might implemented this function to line up the grids of different scales.
 */
// function getYAxisLinearMax(context: any): number | undefined {
//   try {
//     const y1Dataset = data.datasets.find((dataset) => dataset.yAxisID === 'y1');
//     const y1Max = max(y1Dataset!.data) as number;
//     console.log('y1Max', y1Max);

//     const numberOfYTicks = context.chart.scales.y.ticks.length as number;
//     console.log('numberOfYTicks', numberOfYTicks);
//     // const numberOfY1Ticks = context.chart.scales.y1.ticks.length as number;
//     // console.log('numberOfY1Ticks', numberOfY1Ticks);

//     const currentStep = y1Max / (numberOfYTicks - 1);
//     console.log('currentStep', currentStep);

//     const newStep = Math.ceil(currentStep / 5) * 5;
//     console.log('newStep', newStep);

//     return (numberOfYTicks - 1) * newStep;
//   } catch (error) {
//     console.error(error);
//     return undefined;
//   }
// }

/**
 * Addresses the issue of log scale having an extra tick after major values.
 */
const skipValues = [0.15, 1.5, 15, 150, 1.5e3, 15e3, 150e3, 1.5e6];

export function skipExtraTicks(scale: Scale<CoreScaleOptions>) {
  if (scale.type !== 'logarithmic') return;

  scale.ticks = scale.ticks.filter((t) => !skipValues.includes(t.value));
}

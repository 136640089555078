import type { Enterprise } from '@pn/core/domain/enterprise';
import { handleError } from '@pn/core/errors/handleError';
import {
  currentEnterpriseActions,
  enterpriseUsersActions,
  useCurrentUserStorage,
} from '@pn/core/storage';
import { apiEnterpriseProvider } from '@pn/services/api/enterprise/apiEnterpriseProvider';
import { isNil } from 'lodash-es';
import React from 'react';

async function getCurrentEnterpriseCombined(id: Enterprise['id']) {
  try {
    currentEnterpriseActions().request();

    const enterprise = await apiEnterpriseProvider.getEnterpriseById(id);

    currentEnterpriseActions().receive(enterprise);
    enterpriseUsersActions().receive(enterprise.users);
  } catch (error) {
    handleError({
      error,
      userFriendlyMessage: 'Failed to get current enterprise',
      onError: currentEnterpriseActions().error,
    });
  }
}

/**
 * This provider replaces the following three since they all call the same
 * endpoint:
 * - useAutoGetCurrentEnterprise
 * - useAutoGetCurrentEnterpriseUsers
 */
export function useAutoGetCurrentEnterpriseCombined() {
  const { user } = useCurrentUserStorage();
  const enterpriseId = user?.enterprise?.id;

  React.useEffect(() => {
    if (!isNil(enterpriseId)) {
      getCurrentEnterpriseCombined(enterpriseId);
    }
  }, [enterpriseId]);
}

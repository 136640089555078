import { isProduction } from '@pn/core/utils/env';
import type { InjectableReduxStore } from '@pn/services/redux/configureInjectableStore';
import isEqual from 'fast-deep-equal';
import type { IAuthenticationService } from './services/authentication/ports';
import type { IApiClient } from './services/http/ports';
import type { IErrorLogger } from './services/loggers/ports';
import type { IMap } from './services/map/ports';
import type { INotificationService } from './services/notifications/ports';

export type AppName = 'maps' | 'intel' | 'formations' | 'admin';

/**
 * Do not import these dependencies directly outside of @pn/core.
 * (we use it in @pn/ui 'cause Alex couldn't figure out how to make
 * it work otherwise)
 *
 * These dependencies should be set in your product's `useInitializeApp` hook
 * via the `setCoreDependencies` function.
 *
 * Only initialize the ones your product needs.
 */
export const dependencies = {
  appName: {} as AppName,
  useAuthenticationService: {} as () => IAuthenticationService,
  apiClient: {} as IApiClient,
  errorLogger: {} as IErrorLogger,
  notificationService: {} as INotificationService,
  map: {} as IMap<mapboxgl.Map>,
  store: {} as InjectableReduxStore,
};

type DependenciesKeys = keyof typeof dependencies;
type Deps = Partial<typeof dependencies>;

export const setCoreDependencies = (deps: Deps) => {
  Object.assign(dependencies, deps);

  reportDependencies();
};

function reportDependencies() {
  if (isProduction()) return;

  const numberOfAssigned = Object.keys(dependencies).filter(
    (key) => !isObjectEmpty(dependencies[key as DependenciesKeys])
  ).length;
  const totalNumberOfDependencies = Object.keys(dependencies).length;

  console.groupCollapsed(
    `${numberOfAssigned}/${totalNumberOfDependencies} core dependencies set`
  );

  Object.keys(dependencies)
    .filter((key) => !isObjectEmpty(dependencies[key as DependenciesKeys]))
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      console.log(`${key}: ✅`);
    });
  Object.keys(dependencies)
    .filter((key) => isObjectEmpty(dependencies[key as DependenciesKeys]))
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      console.log(`${key}: ❌`);
    });

  console.groupEnd();
}

function isObjectEmpty(obj: unknown) {
  return isEqual(obj, {});
}

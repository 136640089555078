import { useMapStorage } from '@pn/core/storage';
import { DrawMode } from '@pn/services/styles/annotation';
import React from 'react';
import { dependencies } from '@pn/core/dependencies';

export function useMapKeyboardControls(params: { drawMode: DrawMode }) {
  const { drawMode } = params;

  const { isInitialized: isMapInitialized } = useMapStorage();

  /**
   * Disable map zoom and rotation keyboard interactions when typing.
   */
  React.useEffect(() => {
    if (!isMapInitialized) return;

    if (drawMode === 'draw_annotation_text') {
      (dependencies.map._native).keyboard.disable();
    } else {
      (dependencies.map._native).keyboard.enable();
    }
  }, [isMapInitialized, drawMode]);
}

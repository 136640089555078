import CropFreeIcon from '@mui/icons-material/CropFree';
import { Box, IconButton, Typography } from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import {
  getDataItemSelected,
  getFormattedItemName,
  getItemColor,
} from '@pn/core/domain/workspace';
import { centerMapOnWorkspaceItem } from '@pn/core/operations/mapInteractions';
import { useWorkspaceStorage } from '@pn/core/storage';
import { generateIconBackground } from '@pn/services/utils/color';
import { CustomTooltip } from '@pn/ui/custom-components/CustomTooltip';
import { isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  titleContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 200,
    height: 34,
    padding: `4px ${theme.spacing(2)}`,
    paddingRight: 4,
    background: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
  titleColorIndicator: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 4,
    background: color,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  iconButton: {
    /**
     * The combination of padding and icon size make the icon same height as
     * other toolbar buttons (34px).
     */
    padding: 7,
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  /**
   * REMOVE BELOW
   */
  tooltip: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius,
  },
  arrow: {
    color: theme.palette.text.secondary,
  },
  highlight: {
    fontWeight: 500,
  },
  closeLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const LayerTitle = () => {
  const { workspaceItemSelected } = useWorkspaceStorage();

  const color = getItemColor(workspaceItemSelected);
  const { classes } = useStyles({
    color: generateIconBackground(color, workspaceItemSelected?.isTemporary),
  });

  const isAnnotation =
    !isNil(workspaceItemSelected) &&
    workspaceItemSelected.itemType === 'annotation';
  const isDrawing =
    !isNil(workspaceItemSelected) &&
    workspaceItemSelected.itemType === 'drawing' &&
    !workspaceItemSelected.isTemporary;
  const isTemporaryDrawing =
    !isNil(workspaceItemSelected) &&
    workspaceItemSelected.itemType === 'drawing' &&
    workspaceItemSelected.isTemporary;

  const isStreamed =
    !isNil(workspaceItemSelected) &&
    workspaceItemSelected.streamedCount !== 0 &&
    workspaceItemSelected.isRendered;

  const isDataItemSelected =
    !isNil(workspaceItemSelected) &&
    !isNil(getDataItemSelected(workspaceItemSelected));

  const isCenterOnLayerEnabled =
    isAnnotation || isDrawing || isStreamed || isDataItemSelected;

  return (
    <Box className={classes.titleContainer}>
      <Box className={classes.titleColorIndicator} />
      <Typography
        noWrap
        variant="body2"
        color={
          isNil(workspaceItemSelected) || workspaceItemSelected.isTemporary
            ? 'textSecondary'
            : 'textPrimary'
        }
      >
        {getFormattedItemName(workspaceItemSelected)}
      </Typography>

      <CustomTooltip
        disabled={isCenterOnLayerEnabled}
        title={
          isNil(workspaceItemSelected)
            ? 'Select a layer first'
            : isTemporaryDrawing
              ? 'Cannot center on an unsaved annotation'
              : `Cannot center on all ${formatDataType(
                  workspaceItemSelected.dataType
                )}`
        }
      >
        <span>
          <IconButton
            size="small"
            color="primary"
            className={classes.iconButton}
            disabled={!isCenterOnLayerEnabled}
            onClick={() => centerMapOnWorkspaceItem(workspaceItemSelected)}
          >
            <CropFreeIcon />
          </IconButton>
        </span>
      </CustomTooltip>
    </Box>
  );
};

import { CircularProgress, Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    height: 300,
  },
}));

export const LibraryLoadingIndicator = () => {
  const { classes } = useStyles();

  return (
    <Paper variant="outlined" className={classes.container}>
      <CircularProgress size={64} />
    </Paper>
  );
};

import { LayerType, type Layer } from '@pn/core/domain/layer';
import { getLayerId } from '@pn/core/domain/workspace';

export function createBorderLayer(params: {
  itemId: string;
  sourceLayerId: string;
  source: Layer['source'];
  sourceField?: string;
  color: unknown;
  index: number;
}): Layer {
  const {
    itemId,
    sourceLayerId,
    color,
    source,
    sourceField,
    index,
  } = params;

  const key = `Border ${index + 1}`;
  const layerId = getLayerId(itemId, sourceLayerId, new Date().getTime());

  return {
    id: layerId,
    name: key,
    type: LayerType.Line,
    source,
    sourceLayer: undefined,
    style: {
      width: 1,
      color,
    },
    sourceField,
  };
}

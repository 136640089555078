import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Alert, Box } from '@mui/material';
import { libraryFolders, type Project } from '@pn/core/domain/project';
import { useAccess } from '@pn/core/permissions/access';
import { isEditable } from '@pn/core/permissions/editability';
import { useCurrentUserStorage, useProjectsStorage } from '@pn/core/storage';
import { EmptyStateWithIcon } from '@pn/ui/custom-components/EmptyStateWithIcon';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { useAuthenticationService } from 'src/application/externalDependencies';
import { ProjectCard } from 'src/ui/workspace/library/components/ProjectCard';
import { makeStyles } from 'tss-react/mui';
import { useLibrary } from '../LibraryProvider';
import { useLibraryActions } from 'src/ui/workspace/library/LibraryData/useLibraryActions';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2),
    [theme.breakpoints.down(1800)]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
    [theme.breakpoints.down(1450)]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    [theme.breakpoints.down(1150)]: {
      gridTemplateColumns: 'auto',
    },
    /* The queries below will activate on mobile screens */
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  link: {
    color: theme.palette.info.main,
    fontWeight: 500,
    textDecoration: 'underline',
    '&:hover, &:focus, &:active': {
      color: theme.palette.info.dark,
    },
  },
}));

type Props = {
  showAll?: boolean;
};

export const ProjectsGrid = ({ showAll = false }: Props) => {
  const { classes } = useStyles();

  const { isAuthenticated } = useAuthenticationService();
  const access = useAccess();
  const isAccessDenied = access('projects').denied();

  const { user } = useCurrentUserStorage();
  const { projects } = useProjectsStorage();

  const { libraryRouter } = useLibrary();
  const { handleGridClick } = useLibraryActions();

  const projectsByFolder = React.useMemo(
    () =>
      libraryFolders.projects.reduce<Record<string, Project[]>>(
        (acc, folder) => ({
          ...acc,
          [folder]: projects.filter((p) => p.folder === folder),
        }),
        {}
      ),
    [projects]
  );

  const displayProjects = React.useMemo(
    () =>
      showAll
        ? projects.filter(
            (p) => !isNil(p.folder) && p.folder !== 'StackDX' && !p.isFolder
          )
        : projectsByFolder[libraryRouter.folder],
    [projects, projectsByFolder, libraryRouter.folder, showAll]
  );

  if (isAccessDenied) {
    return (
      <Box display="flex" justifyContent="center" alignItems="flex-start">
        <Alert severity="warning">
          <a
            href="https://about.petroninja.com/plans"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            {isAuthenticated ? 'Upgrade' : 'Sign up'}
          </a>{' '}
          {isAuthenticated ? 'to' : 'for'} a Professional or Enterprise account
          to access projects
        </Alert>
      </Box>
    );
  }

  if (isEmpty(displayProjects)) {
    return (
      <Box mx="auto" py={4}>
        <EmptyStateWithIcon text="No projects" icon={FolderOpenIcon} />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {displayProjects.map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          isEditable={isEditable(project, user)}
          onClick={() => handleGridClick(project)}
        />
      ))}
    </Box>
  );
};

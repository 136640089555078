import { Box } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ width: number }>()((theme, { width }) => ({
  navRail: {
    gridArea: 'nav-rail',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    width,
    height: '100%',
    // marginLeft: -1, // keeps the items centered when the right border is present
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      borderRight: 'none',
      borderTop: `1px solid ${theme.palette.divider}`,
      overflowX: 'auto',
    },
  },
}));

type Props = {
  children: React.ReactNode;
  width: number;
};

export const NavRail = ({ children, width }: Props) => {
  const { classes } = useStyles({ width });

  return <Box className={classes.navRail}>{children}</Box>;
};

import type {
  GridColDef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import type { IQueryFieldsMapper } from '@pn/core/mappers/query';

export type IDataGridQueryFieldsMapper =
  IQueryFieldsMapper<GridColumnVisibilityModel>;

export const dataGridQueryFieldsMapper: IDataGridQueryFieldsMapper = {
  toDomainFields: (_, visibilityModel: GridColumnVisibilityModel) => {
    return Object.entries(visibilityModel).reduce<string[]>(
      (acc, [field, isVisible]) => {
        if (isVisible) {
          return [...acc, field];
        }

        return acc;
      },
      []
    );
  },
  /**
   * @returns DataGrid's visibility model rather than the fields
   */
  toTargetFields: (fields, columns: GridColDef[]) => {
    return columns.reduce((acc, column) => {
      return {
        ...acc,
        [column.field]: fields.includes(column.field),
      };
    }, {});
  },
};

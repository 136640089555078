import LockIcon from '@mui/icons-material/Lock';
import { Button, ButtonProps } from '@mui/material';
import { useAccess } from '@pn/core/permissions/access';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { isNil } from 'lodash-es';
import React from 'react';

// FIXME TS
export const PermissionButton: any = React.forwardRef<HTMLButtonElement, any>(
  (props, ref) => {
    const {
      children,
      permissionPath,
      component,
      href,
      to,
      startIcon,
      endIcon,
      onClick,
      ...rest
    } = props;

    const access = useAccess();

    const handleNoAccessClick = (_e: React.MouseEvent<HTMLButtonElement>) => {
      access(permissionPath).notify();
    };

    if (access(permissionPath).denied()) {
      const icons: Record<string, JSX.Element | null> = {
        startIcon: null,
        endIcon: null,
      };
      if (!isNil(startIcon)) {
        icons.startIcon = <LockIcon />;
      } else {
        icons.endIcon = <LockIcon />;
      }

      return (
        <ButtonComponent
          {...rest}
          {...icons}
          ref={ref}
          onClick={handleNoAccessClick}
          onAuxClick={handleNoAccessClick}
        >
          {children}
        </ButtonComponent>
      );
    }

    const { permissionPath: _, ...buttonProps } = props;

    return (
      <ButtonComponent {...buttonProps} ref={ref}>
        {children}
      </ButtonComponent>
    );
  }
);

PermissionButton.displayName = 'PermissionButton';

function ButtonComponent(props: ButtonProps & { isCustom?: boolean }) {
  const { isCustom = false, ...rest } = props;

  if (isCustom) {
    return <CustomButton v3type="filled_tonal" {...rest} />;
  }

  return <Button {...rest} />;
}

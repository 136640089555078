import { colors, createTheme, darkScrollbar } from '@mui/material';
import Color from 'color';
import { sharedThemeTokens, productionColors, typography } from './shared';

export const PetroNight = createTheme(
  {
    palette: {
      mode: 'dark',
      primary: {
        main: Color('hsl(220, 40%, 60%)').hex(), // bug with mui-x: HSL not supported
      },
      secondary: {
        main: colors.amber[700],
      },
      background: {
        default: Color('hsl(220, 10%, 17%)').hex(), // bug with mui-x: HSL not supported
        paper: Color('hsl(220, 10%, 17%)').hex(), // bug with mui-x: HSL not supported
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: darkScrollbar(),
        },
      },
    },
    borderColor: 'rgba(255, 255, 255, 0.23)',
    productionColors,
    typography,
  },
  sharedThemeTokens
);

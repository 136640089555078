import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';
import type { IProjectsActions, IProjectsStorage } from './ports';
import { projectsSlice, type State } from './projectsSlice';
import { findOrThrow } from '@pn/core/utils/logic';

export const useReduxProjectsStorage = (): IProjectsStorage => {
  const { name, getInitialState } = projectsSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    projects: useSliceSelector((state) => state.projects),
    selectedProject: useSliceSelector(selectSelectedProject),
    isProjectLoaded: useSliceSelector(
      (state) => state.projectIdLoaded === state.selectedProjectId
    ),
  };
};

export const reduxProjectsActions = (): IProjectsActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = projectsSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    add: (projectOrProjects, upsert = true) =>
      dispatch(actions.add({ projectOrProjects, upsert })),
    error: () => dispatch(actions.error()),
    create: (project) => dispatch(actions.create(project)),
    update: (project) => dispatch(actions.update(project)),
    remove: (projectId) => dispatch(actions.remove(projectId)),
    removeStackProjects: () => dispatch(actions.removeStackProjects()),
    share: (projectId) => dispatch(actions.share(projectId)),
    unshare: (projectId) => dispatch(actions.unshare(projectId)),
    select: (projectId) => dispatch(actions.select(projectId)),
    updateProjectIdLoaded: (projectId) =>
      dispatch(actions.updateProjectIdLoaded(projectId)),
  };
};

const selectSelectedProject = createSelector(
  [(state: State) => state.projects, (state: State) => state.selectedProjectId],
  (projects, selectedProjectId) =>
    findOrThrow(
      projects,
      (p) => p.id === selectedProjectId,
      'Selector failed: project not found'
    )
);

import { useDrawing } from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isEmpty } from 'lodash-es';
import { useHotkeys } from 'react-hotkeys-hook';

export function useDelete() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { drawingState, historyManager, redraw } = useDrawing();

  useHotkeys(
    'delete',
    () => {
      if (!isDrawingPanelOpen || isEmpty(drawingState.featuresSelected)) return;

      Object.values(drawingState.featuresSelected).forEach((feature) => {
        const id = feature.id;

        delete drawingState.features[id];
        delete drawingState.paths[id];
        drawingState.order = drawingState.order.filter((i) => i !== id);
      });

      drawingState.featuresSelected = {};

      redraw();

      historyManager.add(drawingState);
    },
    [isDrawingPanelOpen]
  );
}

/**
 * The maximum number of objects that can be streamed to the front-end in one go.
 */
export const STREAMING_LIMIT = 200e3;

/**
 * Applies to all Bottom Table exports.
 */
export const EXPORT_LIMIT = 60e3;

/**
 * The maximum number of objects that will be visualized on the map automatically
 * i.e. without prompting the user to press the button.
 */
export const AUTO_VISUALIZE_LIMIT = 25e3;

/**
 * The maximum number of objects in one list for professional/enterprise users.
 */
export const LIST_MAX_SIZE = 60e3;

/**
 * The maximum number of objects in one list for free users.
 */
export const LIST_MAX_SIZE_FREE = 5;

/**
 * Maximum number of data items that can be requested for list comparison.
 */
export const LIST_COMPARE_MAX_ITEMS = 20;

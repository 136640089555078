import { getSourceId } from '@pn/core/domain/workspace';
import type { MapboxIMap } from '..';

export const addAnnotationLayer: MapboxIMap['addAnnotationLayer'] = function (
  this: MapboxIMap,
  workspaceItem,
  beforeLayerIds
) {
  const layerId = `annotation-${workspaceItem.id}`;
  const sourceId = getSourceId(layerId);

  if (this._native.getSource(sourceId)) {
    return; // ignore repeated calls
  }

  this._native.addSource(sourceId, workspaceItem.map.layers[0].source);

  const existingBeforeLayerId = beforeLayerIds.find((id) => this.hasLayer(id));

  getMapboxAnnotationsLayers(layerId).forEach((layer) => {
    this._native.addLayer(
      { ...layer, source: sourceId } as mapboxgl.AnyLayer,
      existingBeforeLayerId
    );
  });
};

export const getMapboxAnnotationsLayers = (layerId: string) => [
  {
    id: `${layerId}-polygons`,
    type: 'fill',
    paint: {
      'fill-color': ['get', 'fillColor'],
      'fill-opacity': ['get', 'fillOpacity'],
    },
    filter: ['==', '$type', 'Polygon'],
  },
  {
    id: `${layerId}-polygons-stroke`,
    type: 'line',
    filter: ['==', '$type', 'Polygon'],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'lineColor'],
      'line-width': ['get', 'lineWidth'],
    },
  },
  {
    id: `${layerId}-lines`,
    type: 'line',
    filter: ['==', '$type', 'LineString'],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'lineColor'],
      'line-width': ['get', 'lineWidth'],
    },
  },
  {
    id: `${layerId}-texts`,
    type: 'symbol',
    filter: ['has', 'isTextFeature'],
    layout: {
      'text-allow-overlap': true,
      'text-field': ['get', 'textField'],
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-size': ['get', 'textSize'],
    },
    paint: {
      'text-color': ['get', 'textColor'],
    },
  },
];

import CheckIcon from '@mui/icons-material/Check';
import { ButtonBase, Theme } from '@mui/material';
import Color from 'color';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { SQ_SIZE } from '.';

const L = 'hsl(0, 0%, 87%)';
const D = 'hsl(0, 0%, 40%)';

const TRANSPARENCY_BG = {
  light: `
    linear-gradient(45deg, ${L} 25%, transparent 25%, transparent 75%, ${L} 75%, ${L}), 
    linear-gradient(45deg, ${L} 25%, transparent 25%, transparent 75%, ${L} 75%, ${L})
  `,
  dark: `
    linear-gradient(45deg, ${D} 25%, transparent 25%, transparent 75%, ${D} 75%, ${D}), 
    linear-gradient(45deg, ${D} 25%, transparent 25%, transparent 75%, ${D} 75%, ${D})
  `,
};

const useStyles = makeStyles<{
  color: string;
  bold: boolean;
}>()((theme, { color, bold }) => ({
  colorBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: bold ? SQ_SIZE + 4 : SQ_SIZE,
    height: bold ? SQ_SIZE + 4 : SQ_SIZE,
    background: color,
    border: getBorderColor(theme, color),
    borderRadius: 4,
    '&:hover': {
      boxShadow: `0 0 0 1px ${theme.palette.action.active}`,
    },
    '&:focus-visible': {
      border: `1px solid ${theme.palette.background.paper}`,
      boxShadow: `0 0 0 2px ${Color(color).contrast(Color(theme.palette.background.paper)) > 2 ? color : theme.borderColor}`,
    },
  },
  transparentBox: {
    background: TRANSPARENCY_BG[theme.palette.mode],
    backgroundSize: bold ? '16px 16px' : '14px 14px',
    backgroundPosition: bold ? '0 0, 8px 8px' : '0 0, 7px 7px',
    '&:focus-visible': {
      border: `1px solid ${theme.palette.background.paper}`,
      boxShadow: `0 0 0 2px ${theme.borderColor}`,
    },
  },
  icon: {
    fontSize: SQ_SIZE - 8,
    color: getIconColor(theme, color),
  },
}));

type Props = {
  disabled?: boolean;
  bold?: boolean;
  value?: string;
  color: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ColorBox = ({
  disabled = false,
  bold = false,
  value,
  color,
  onClick,
}: Props) => {
  const { classes, cx } = useStyles({ color, bold });

  const c = Color(color);
  const v = Color(value);

  const isSelected = c.alpha() === 0 ? v.alpha() === 0 : c.hex() === v.hex();

  return (
    <ButtonBase
      disabled={disabled}
      className={cx(classes.colorBox, {
        [classes.transparentBox]: c.alpha() === 0,
      })}
      onClick={onClick}
    >
      {isSelected && <CheckIcon className={classes.icon} />}
    </ButtonBase>
  );
};

function getBorderColor(theme: Theme, color: string): string {
  const c = Color(color);
  if (c.alpha() === 0) {
    return `1px solid ${theme.palette.mode === 'light' ? L : D}`;
  } else if (
    theme.palette.mode === 'light' ? c.lightness() > 80 : c.lightness() < 20
  ) {
    return `1px solid ${theme.borderColor}`;
  } else {
    return 'none';
  }
}

function getIconColor(theme: Theme, color: string): string {
  const c = Color(color);
  if (c.alpha() === 0) return theme.palette.text.primary;
  else if (c.contrast(Color('#fff')) > 2) return '#fff';
  else return theme.palette.getContrastText(color);
}

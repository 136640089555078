import type { User } from '@pn/core/domain/user';
import type { IErrorLogger } from '@pn/core/services/loggers/ports';
import { isNil } from 'lodash-es';
import { appsignal } from './appSignalManager';

export function stringifyUserId(userId: User['id'] | undefined): string {
  if (!isNil(userId)) {
    return userId.toString();
  } else {
    return 'N/A';
  }
}

export const appSignalErrorLogger: IErrorLogger = {
  logConfigurationError: (error, userId) => {
    appsignal.sendError(error, (span) => {
      span.setNamespace('configuration');
      span.setTags({ userId: stringifyUserId(userId) });
    });
  },
  logApiError: (error, userId) => {
    appsignal.sendError(error, (span) => {
      span.setNamespace('api');
      span.setAction(error.url);
      span.setTags({
        userId: stringifyUserId(userId),
        code: error.code.toString(),
        url: error.url,
      });
      span.setParams({
        responseData: (error.responseData as any) ?? {},
      });
    });
  },
  logGenericError: (error, userId, category) => {
    appsignal.sendError(error, (span) => {
      if (category) span.setAction(category);
      span.setTags({ userId: stringifyUserId(userId) });
    });
  },
};

import { dependencies } from '@pn/core/dependencies';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import { generateId } from '@pn/core/utils/id';
import {
  REFERENCE_PT,
  computeMapTransformation,
  getCanvasBoundingBox,
  scalePoint,
  transformPoint,
  translateFeature,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useCopyPaste(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { drawingState, historyManager, redraw } = useDrawing();

  const featuresCopied = React.useRef<CanvasFeature[]>([]);
  const mousePos = React.useRef<Point>({ x: 0, y: 0 });

  React.useEffect(() => {
    if (!isDrawingPanelOpen) return;

    const recordMousePos = (event: MouseEvent) => {
      const { map } = dependencies;

      const bbox = map._native.getContainer().getBoundingClientRect();
      mousePos.current = scalePoint({
        x: event.clientX - bbox.left,
        y: event.clientY - bbox.top,
      });
    };

    document.addEventListener('mousemove', recordMousePos);

    return () => {
      document.removeEventListener('mousemove', recordMousePos);
    };
  }, [isDrawingPanelOpen]);

  useHotkeys(
    'ctrl+c',
    () => {
      if (!isDrawingPanelOpen || isEmpty(drawingState.featuresSelected)) return;

      featuresCopied.current = Object.values(drawingState.featuresSelected);
    },
    [isDrawingPanelOpen]
  );

  useHotkeys(
    'ctrl+v',
    () => {
      if (!isDrawingPanelOpen || isEmpty(featuresCopied.current)) return;

      const transformation = computeMapTransformation(REFERENCE_PT);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      const bbox = getCanvasBoundingBox(featuresCopied.current);
      const centerPoint = {
        x: bbox.x + bbox.width / 2,
        y: bbox.y + bbox.height / 2,
      };
      const mousePosTransformed = transformPoint(
        mousePos.current,
        inverseTransformation
      );

      drawingState.featuresSelected = {};

      const time = generateId();
      featuresCopied.current.forEach((feature, index) => {
        const newFeature = translateFeature(
          {
            ...feature,
            id: time + index,
            itemId,
          },
          mousePosTransformed.x - centerPoint.x,
          mousePosTransformed.y - centerPoint.y
        );

        drawingState.featuresSelected[newFeature.id] = newFeature;
        drawingState.features[newFeature.id] = newFeature;
        drawingState.order.push(newFeature.id);
      });

      redraw();

      historyManager.add(drawingState);
    },
    [isDrawingPanelOpen, itemId]
  );
}

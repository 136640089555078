export type DrawingMode =
  | 'select'
  | 'freehand'
  | 'line'
  | 'arrow'
  | 'rectangle'
  | 'circle'
  | 'text'
  /* Extras */
  | 'rectangle_select'
  | 'distance';

export function isExtraDrawingMode(mode: DrawingMode): boolean {
  return ['rectangle_select', 'distance'].includes(mode);
}

import type { SIUnit } from '@pn/core/domain/units';
import { isNil } from 'lodash-es';

const currencySymbols = ['$', 'CA$', 'US$'];

export const unitToString = (
  unit: SIUnit,
  options: {
    displaySymbol?: boolean;
  } = {}
): string => {
  const { displaySymbol = false } = options;

  if (isNil(unit.value)) return '-';

  const isCurrency = currencySymbols.includes(unit.symbol);
  const formattedValue = unit.value.toLocaleString('en-CA', {
    maximumFractionDigits: isCurrency ? 2 : getMaxFractionDigits(unit.value),
    minimumFractionDigits: isCurrency ? 2 : 0,
  });

  let symbolString = '';
  if (displaySymbol) {
    symbolString = prettyPrintUnitSymbol(unit.symbol);
    symbolString = isCurrency ? symbolString : ' ' + symbolString;
  }

  return isCurrency
    ? `${symbolString}${formattedValue}`
    : `${formattedValue}${symbolString}`;
};

function getMaxFractionDigits(value: number): number {
  if (value < 1) {
    return 3;
  } else if (value < 10) {
    return 2;
  } else {
    return 1;
  }
}

export function prettyPrintUnitSymbol(symbol: string): string {
  switch (symbol) {
    case 'm3':
      return 'm³';
    case 'm3/d':
      return 'm³/d';
    case 'e3m3':
      return 'e³m³';
    case 'e3m3/d':
      return 'e³m³/d';
    case 'm3/m3':
      return 'm³/m³';
    case 'm3/e3m3':
      return 'm³/e³m³';
    case 'C':
      return '°C';
    case 'F':
      return '°F';
    case 'kg/m3':
      return 'kg/m³';
    case 'lb/ft3':
      return 'lb/ft³';
    default:
      return symbol;
  }
}

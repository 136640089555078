import type { Project } from '@pn/core/domain/project';
import type { ProjectUser } from '@pn/core/domain/user';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import {
  projectsActions,
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import assert from 'assert';
import { isNil } from 'lodash-es';
import React from 'react';
import { ShareDialog } from 'src/ui/workspace/share/ShareDialog';

export type Shareable = {
  id: string;
  author: ProjectUser | undefined;
  isShared: boolean;
  isGlobal: boolean;
} & (
  | {
      type: 'project';
      source: Project;
    }
  | {
      type: WorkspaceItem['itemType'];
      source: WorkspaceItem;
    }
);

const ShareDialogContext = React.createContext<{
  openShareDialog: (type: Shareable['type'], id: Shareable['id']) => void;
  closeShareDialog: () => void;
}>({} as any);

export const useShareDialog = () => React.useContext(ShareDialogContext);

type Props = {
  children: React.ReactNode;
};

export const ShareDialogProvider = ({ children }: Props) => {
  const { allWorkspaceItems } = useWorkspaceStorage();
  const { projects } = useProjectsStorage();

  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState<Shareable['type']>('project');
  const [id, setId] = React.useState<Shareable['id'] | undefined>();

  const shareable = React.useMemo(() => {
    if (isNil(id)) return undefined;

    switch (type) {
      case 'project': {
        const project = projects.find((project) => project.id === id);
        if (isNil(project)) return undefined;
        return {
          id,
          type,
          author: project.createdBy,
          isShared: project.isShared,
          isGlobal: project.isGlobal,
          source: project,
        };
      }
      default: {
        const item = allWorkspaceItems.find((item) => item.id === id);
        if (isNil(item)) return undefined;
        return {
          id,
          type,
          author: item.createdBy,
          isShared: item.isShared,
          isGlobal: item.isGlobal,
          source: item,
        };
      }
    }
  }, [allWorkspaceItems, projects, type, id]);

  const handleToggleShared = (checked: boolean) => {
    assert(shareable, 'Shareable not found');

    switch (shareable.type) {
      case 'project':
        if (checked) {
          projectsActions().share(shareable.id);
        } else {
          projectsActions().unshare(shareable.id);
        }

        break;
      default:
        if (checked) {
          workspaceActions().share(shareable.source);
        } else {
          workspaceActions().unshare(shareable.source);
        }
    }
  };

  return (
    <ShareDialogContext.Provider
      value={{
        openShareDialog: (type, id) => {
          setType(type);
          setId(id);
          setOpen(true);
        },
        closeShareDialog: () => setOpen(false),
      }}
    >
      {!isNil(shareable) && (
        <ShareDialog
          open={open}
          shareable={shareable}
          onClose={() => setOpen(false)}
          onToggleShared={handleToggleShared}
        />
      )}
      {children}
    </ShareDialogContext.Provider>
  );
};

import { isPNDataType } from '@pn/core/domain/data';
import {
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { isDefaultProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import { moduleItemIds } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { isUS } from '@pn/core/utils/env';
import { isEmpty, isNil, last } from 'lodash-es';
import React from 'react';
import { ls } from 'src/application/externalDependencies';

const key = 'workspace-item-ids';

export function usePersistWorkspace() {
  const { idsInWorkspace } = useWorkspaceStorage();
  const { selectedProject } = useProjectsStorage();

  React.useEffect(() => {
    const pnItemIds = ls.get<string[]>(key);
    if (isNil(pnItemIds) || isEmpty(pnItemIds)) {
      workspaceActions().select(isUS() ? 'wells_usa' : 'wells');
      return;
    }

    workspaceActions().setWorkspace(pnItemIds);
    workspaceActions().select(last(pnItemIds));
  }, []);

  React.useEffect(() => {
    if (!isDefaultProject(selectedProject)) return;

    const pnItemIds = idsInWorkspace.filter(
      (id) => isPNDataType(id) && !moduleItemIds.includes(id)
    );
    ls.set(key, pnItemIds);
  }, [idsInWorkspace, selectedProject]);
}

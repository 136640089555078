import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { loadItem } from '@pn/core/providers/workspace/loadWorkspaceItem';
import {
  useAppStorage,
  useProjectsStorage,
  workspaceActions,
} from '@pn/core/storage';
import React from 'react';
import { visualizeLoadedWorkspaceItem } from '../visualizeLoadedWorkspaceItem';

export function useVisualizeWorkspaceItem(tableOnly = false) {
  const { unitSystem, pageSize } = useAppStorage();
  const { isProjectLoaded } = useProjectsStorage();

  return React.useCallback(
    async (
      item: WorkspaceItem,
      beforeItems: WorkspaceItem[] = [],
      autoTrigger = false
    ) => {
      workspaceActions().markAsProcessed(item.id);
      workspaceActions().markAsUnfinalized(item.id);

      const loadedItem = await loadItem(item);

      return visualizeLoadedWorkspaceItem({
        item: loadedItem,
        beforeItems,
        pageSize,
        unitSystem,
        extraOptions: {
          autoTrigger,
          tableOnly,
          silent: !isProjectLoaded,
        },
      });
    },
    [tableOnly, unitSystem, pageSize, isProjectLoaded]
  );
}

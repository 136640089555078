import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, LinearProgress, TextField } from '@mui/material';
import type { Project } from '@pn/core/domain/project';
import React from 'react';
import {
  ProjectState,
  useProjectState,
} from 'src/ui/workspace/project-controls/ProjectStateProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  input: {
    paddingRight: theme.spacing(1),
  },
  adornmentButton: {
    width: 24,
    height: 24,
    padding: 2,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

type Props = {
  project: Project;
};

export const ProjectNameInput = ({ project }: Props) => {
  const { classes } = useStyles();

  const {
    projectState,
    handleCancel,
    handleCreateProject,
    handleUpdateProject,
  } = useProjectState();

  const [name, setName] = React.useState('');
  const [inProgress, setInProgress] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useLayoutEffect(() => {
    setName(project.name);
  }, [project.name]);

  const isInvalid = name.trim() === '' || name.length > 100;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (isInvalid) return;

      handleSubmit();
    }

    if (e.key === 'Escape') {
      handleCancel();
    }
  };

  const handleSubmit = async () => {
    setInProgress(true);

    if (projectState === ProjectState.CreateInput) {
      await handleCreateProject(name.trim());
    } else {
      await handleUpdateProject(project, name.trim());
    }

    setInProgress(false);
  };

  return (
    <Box className={classes.container}>
      <TextField
        autoFocus
        fullWidth
        size="small"
        placeholder="Enter project name"
        disabled={inProgress}
        value={name}
        error={isInvalid}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        slotProps={{
          htmlInput: { ref: inputRef },
          input: {
            className: classes.input,
            endAdornment: (
              <>
                <IconButton
                  className={classes.adornmentButton}
                  color="primary"
                  disabled={name.trim() === '' || inProgress}
                  onClick={handleSubmit}
                >
                  <CheckIcon sx={{ fontSize: 20 }} />
                </IconButton>
                {projectState === ProjectState.CreateInput && (
                  <IconButton
                    className={classes.adornmentButton}
                    disabled={inProgress}
                    onClick={handleCancel}
                  >
                    <CloseIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </>
            ),
          },
        }}
      />
      {inProgress && <LinearProgress className={classes.progress} />}
    </Box>
  );
};

import type { ISODateTimeString } from '@pn/core/domain/types';
import type { ExtendedMapboxLayer } from '@pn/services/api/layer/types';
import { isNil } from 'lodash-es';

type StackProjectShared = {
  comments: string | null;
  projectId: number;
  createDate: ISODateTimeString;
  updateDate: ISODateTimeString;
  companyId: string;
  dataOrigin: number;
  dataOriginDesc: string;
  dataType: number;
  projectKeyFromLand: string;
  projectCode: string;
  description: string;
  ownerLandUserId: string;
  projectType: string;
};

type StackList = {
  dataType: string;
  sourceLayerId: string;
  count: number | null;
  stackdxUrl: string | null;
  layers_definition: ExtendedMapboxLayer[] | null;
};

export type StackProject = StackProjectShared & {
  lists: (StackList & {
    ids: null;
  })[];
};

export type StackProjectWithListIds = StackProjectShared & {
  lists: (StackList & {
    ids: string[];
  })[];
};

export function isStackProjectWithListIds(
  project: StackProject | StackProjectWithListIds
): project is StackProjectWithListIds {
  return !isNil(project.lists[0]?.ids);
}

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { libraryFolders } from '@pn/core/domain/project';
import { isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { useLibrary } from './LibraryProvider';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    minHeight: 40,
  },
}));

export const LibraryBreadcrumbs = () => {
  const { classes } = useStyles();

  const {
    searchValue,
    libraryProjectSelected,
    libraryRouter,
    setLibraryRouter,
  } = useLibrary();

  if (searchValue !== '') {
    return null;
  }

  return (
    <Breadcrumbs
      className={classes.container}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="library breadcrumbs"
    >
      <Typography
        component={Link}
        variant="h6"
        color="inherit"
        underline="hover"
        href="#"
        onClick={(event) => {
          event.preventDefault();
          setLibraryRouter({
            section: libraryRouter.section,
            folder: libraryFolders[libraryRouter.section][0],
          });
        }}
      >
        {formatSection(libraryRouter.section)}
      </Typography>
      <Typography
        component={Link}
        variant="h6"
        color="inherit"
        underline="hover"
        href="#"
        onClick={(event) => {
          event.preventDefault();
          setLibraryRouter({
            section: libraryRouter.section,
            folder: libraryRouter.folder,
          });
        }}
      >
        {libraryRouter.folder}
      </Typography>
      {!isNil(libraryProjectSelected) && (
        <Typography variant="h6" color="text.primary">
          {libraryProjectSelected.name}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

function formatSection(section: 'projects' | 'items'): string {
  switch (section) {
    case 'projects':
      return 'Projects';
    case 'items':
      return 'Layers';
  }
}

import { useWorkspaceStorage } from '@pn/core/storage';
import { STACKDX_URL } from '@pn/core/urls';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  iframe: {
    display: 'block',
    width: '100%',
    height: '100%',
    border: 'none',
  },
}));

export function StackDX() {
  const { classes } = useStyles();

  const { dataItemRequested } = useWorkspaceStorage();

  return (
    <iframe
      title="StackDX"
      src={`${STACKDX_URL}/PetroNinja/Well/${dataItemRequested.id}`}
      className={classes.iframe}
    >
      Browser is not compatible.
    </iframe>
  );
}

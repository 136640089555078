import {
  persistence,
  type Persistence,
} from '@pn/core/storage/persistence';
import { isProduction } from '@pn/core/utils/env';
import { load, save } from 'redux-localstorage-simple';

const persistenceStates = persistence.map(generatePersistedState);

export const persistenceSaves = [
  ...persistenceStates.map((state) => save(state)),
];
export const persistenceLoad = {
  ...persistenceStates.reduce(
    (acc, state) => ({
      ...acc,
      ...load(state),
    }),
    {}
  ),
};

function generatePersistedState({ keys, slice }: Persistence) {
  const { name, getInitialState } = slice;

  return {
    states: keys.map((key) => name + '.' + key),
    preloadedState: {
      [name]: {
        ...getInitialState(),
      },
    },
    disableWarnings: isProduction(),
  };
}

export function clearPersistedStorageData() {
  Object.keys(localStorage)
    .filter((key) => key.startsWith('redux_localstorage_simple_'))
    .forEach((key) => localStorage.removeItem(key));
}

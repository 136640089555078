import type {
  INotificationService,
  NotificationId,
} from '@pn/core/services/notifications/ports';
import { generateId } from '@pn/core/utils/id';
import {
  addNotification,
  getNotification,
  gracefullyRemoveNotification,
} from './stackedNotificationStore';

export const stackedNotificationService: INotificationService = {
  notify: (message, type = 'default') => {
    const notificationId = generateNotificationId();

    addNotification({
      id: notificationId,
      message,
      type,
      isPersistent: false,
      showSpinner: false,
    });
  },
  get: (notificationId) => getNotification(notificationId),
  displayPersistentNotification: (message, options) => {
    const notificationId = generateNotificationId(options?.id);

    addNotification({
      id: notificationId,
      message,
      isPersistent: true,
      showSpinner: options?.showSpinner ?? false,
      onCancel: options?.onCancel,
    });

    return notificationId;
  },
  renderPersistentNotification: (jsx, options) => {
    const notificationId = generateNotificationId(options?.id);

    addNotification({
      id: notificationId,
      jsx,
      isPersistent: true,
      showSpinner: options?.showSpinner ?? false,
      onCancel: options?.onCancel,
    });

    return notificationId;
  },
  removePersistentNotification: (notificationId) => {
    gracefullyRemoveNotification(notificationId);
  },
};

function generateNotificationId(id?: NotificationId): NotificationId {
  return id ?? generateId();
}

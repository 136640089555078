import type { ProductionItem } from '@pn/core/domain/production';
import { hasKey } from '@pn/core/utils/logic';
import randomColor from 'randomcolor';
import type { ChartSeriesStyles } from '../types';

const staticColors: Record<string, string> = {
  Oil: '#009688', // teal 500
  Gas: '#E91E63', // pink 500
  Water: '#2196F3', // blue 500
  GOR: '#795548', // brown 500
  WGR: '#FF5722', // deep orange 500
  CGR: '#FFC107', // amber 500
  'Water Cut': '#607D8B', // blue grey 500
};

export function getLineStyles(seriesStyles: ChartSeriesStyles, key: string) {
  return {
    backgroundColor: seriesStyles[key]?.borderColor ?? getBorderColor(key),
    borderColor: seriesStyles[key]?.borderColor ?? getBorderColor(key),
    borderWidth: seriesStyles[key]?.borderWidth ?? 1.5,
    borderDash: getBorderDash(
      seriesStyles[key]?.borderStyle ?? 'solid',
      seriesStyles[key]?.borderWidth ?? 1.5
    ),
  };
}

function getBorderColor(
  productionGrouping: ProductionItem['productionGrouping']
): string {
  if (hasKey(staticColors, productionGrouping)) {
    return staticColors[productionGrouping];
  } else {
    return randomColor({
      seed: productionGrouping,
      luminosity: 'bright',
    });
  }
}

function getBorderDash(
  borderStyle: 'solid' | 'dashed' | 'dotted',
  borderWidth: number
): [number, number] | undefined {
  switch (borderStyle) {
    case 'dashed':
      return [borderWidth * 3, borderWidth * 2];
    case 'dotted':
      return [borderWidth, borderWidth];
    default:
      return undefined;
  }
}

import FolderOffIcon from '@mui/icons-material/FolderOff';
import { Paper } from '@mui/material';
import { EmptyStateWithIcon } from '@pn/ui/custom-components/EmptyStateWithIcon';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    height: 300,
  },
}));

export const LibraryGridEmptyState = () => {
  const { classes } = useStyles();

  return (
    <Paper variant="outlined" className={classes.container}>
      <EmptyStateWithIcon text="No results" icon={FolderOffIcon} />
    </Paper>
  );
};

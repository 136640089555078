import {
  abandonedGasIcon,
  abandonedInjectionIcon,
  abandonedOilIcon,
  abandonedSourceIcon,
  dryOrAbandonedIcon,
  gasIcon,
  injectionIcon,
  locationIcon,
  oilIcon,
  sourceIcon,
  suspendedGasIcon,
  suspendedInjectionIcon,
  suspendedOilIcon,
  suspendedSourceIcon,
} from '@pn/ui/icons/wellIcons';

export const wellsFilterProperty = {
  field: 'symbol',
  options: {
    i: {
      label: 'Oil',
      icon: oilIcon,
      value: true,
    },
    m: {
      label: 'Suspended Oil',
      icon: suspendedOilIcon,
      value: true,
    },
    c: {
      label: 'Abandoned Oil',
      icon: abandonedOilIcon,
      value: true,
    },
    o: {
      label: 'Gas',
      icon: gasIcon,
      value: true,
    },
    k: {
      label: 'Suspended Gas',
      icon: suspendedGasIcon,
      value: true,
    },
    a: {
      label: 'Abandoned Gas',
      icon: abandonedGasIcon,
      value: true,
    },
    g: {
      label: 'Injection',
      icon: injectionIcon,
      value: true,
    },
    l: {
      label: 'Suspended Injection',
      icon: suspendedInjectionIcon,
      value: true,
    },
    b: {
      label: 'Abandoned Injection',
      icon: abandonedInjectionIcon,
      value: true,
    },
    j: {
      label: 'Source',
      icon: sourceIcon,
      value: true,
    },
    n: {
      label: 'Suspended Source',
      icon: suspendedSourceIcon,
      value: true,
    },
    d: {
      label: 'Abandoned Source',
      icon: abandonedSourceIcon,
      value: true,
    },
    e: {
      label: 'Dry or Abandoned',
      icon: dryOrAbandonedIcon,
      value: true,
    },
    h: {
      label: 'Location',
      icon: locationIcon,
      value: true,
    },
  },
};

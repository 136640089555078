import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { isNil } from 'lodash-es';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'src/ui/App';
import 'src/ui/index.css';
import 'src/ui/markdown.css';
import 'src/ui/polyfills';

declare global {
  interface Window {
    FreshworksWidget: any;
    fwSettings: any; // freshdesk settings
    Keyboard: any; // cordova only
    BuildInfo: any; // cordova only
  }
}

const GLOBAL_STRICT_MODE = false;

function renderApp() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLDivElement
  );

  if (GLOBAL_STRICT_MODE) {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } else {
    root.render(<App />);
  }
}

if (!isNil(window.cordova)) {
  document.addEventListener('deviceready', onDeviceReady);
} else {
  renderApp();
}

function onDeviceReady() {
  window.open = window.cordova.InAppBrowser.open; // open all links in the in-app-browser
  renderApp();
}

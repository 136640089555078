import React from 'react';
import { tokenManager } from '@pn/core/services/authentication/tokenManager';

export function useAutoTriggerAuth() {
  const [isAuthTriggered, setIsAuthTriggered] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const { token } = await tokenManager.get()(); // triggers authentication flow
        tokenManager.setCached(token);
      } catch (error) {
        // this error is thrown when a user is not logged in
      }
      setIsAuthTriggered(true);
    })();
  }, []);

  return isAuthTriggered;
}

import type { Project } from '@pn/core/domain/project';
import {
  defaultProject,
  nullProject,
} from '@pn/core/storage/workspace/defaultWorkspaces';
import { findOrThrow } from '@pn/core/utils/logic';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { isArray, isNil } from 'lodash-es';

export type State = {
  isFetching: boolean;
  isError: boolean;
  projects: Project[];
  selectedProjectId: Project['id'] | undefined;
  projectIdLoaded: string;
};

const initialState: State = {
  isFetching: false,
  isError: false,
  projects: [defaultProject, nullProject],
  selectedProjectId: defaultProject.id,
  projectIdLoaded: defaultProject.id,
};

export const createProjectsSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      add: (
        state,
        {
          payload: { projectOrProjects, upsert },
        }: PayloadAction<{
          projectOrProjects: Project | Project[];
          upsert: boolean;
        }>
      ) => {
        const projects = isArray(projectOrProjects)
          ? projectOrProjects
          : [projectOrProjects];

        state.isFetching = false;
        state.isError = false;

        state.projects = smartCombineProjects(state.projects, projects, upsert);
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      create: (state, { payload: project }: PayloadAction<Project>) => {
        /**
         * Since we preserve original order, new projects are added to the
         * beginning of the list.
         */
        state.projects.unshift(project);
      },
      update: (state, { payload: project }: PayloadAction<Project>) => {
        const projectToUpdate = findOrThrow(
          state.projects,
          (p) => p.id === project.id
        );
        Object.assign(projectToUpdate, project, {
          updatedAt: new Date().toISOString(),
        });
      },
      remove: (state, { payload: projectId }: PayloadAction<Project['id']>) => {
        state.projects = state.projects.filter(
          (project) => project.id !== projectId
        );
        if (state.selectedProjectId === projectId) {
          state.selectedProjectId = defaultProject.id;
        }
      },
      removeStackProjects: (state) => {
        const selectedProject = findOrThrow(
          state.projects,
          (p) => p.id === state.selectedProjectId
        );
        if (selectedProject.origin === 'stackdx') {
          state.selectedProjectId = defaultProject.id;
        }

        state.projects = state.projects.filter(
          (project) => project.origin !== 'stackdx'
        );
      },
      share: (state, { payload: projectId }: PayloadAction<Project['id']>) => {
        const project = findOrThrow(state.projects, (p) => p.id === projectId);
        project.isShared = true;
      },
      unshare: (
        state,
        { payload: projectId }: PayloadAction<Project['id']>
      ) => {
        const project = findOrThrow(state.projects, (p) => p.id === projectId);
        project.isShared = false;
      },
      select: (
        state,
        { payload: projectId }: PayloadAction<Project['id'] | undefined>
      ) => {
        state.selectedProjectId = projectId;
        state.projectIdLoaded = defaultProject.id;
      },
      updateProjectIdLoaded: (
        state,
        { payload: projectId }: PayloadAction<Project['id']>
      ) => {
        state.projectIdLoaded = projectId;
      },
      _replace: (_state, { payload }) => {
        return { ...payload };
      },
    },
  });

export const projectsSlice = createProjectsSlice('projects');

function smartCombineProjects(
  projects: Project[],
  incomingProjects: Project[],
  upsert: boolean
): Project[] {
  const newProjects = incomingProjects.filter(
    (newProject) => !projects.find(({ id }) => id === newProject.id)
  );

  if (!upsert) return [...projects, ...newProjects];

  return [
    ...projects.map((project) => {
      const newItem = incomingProjects.find(({ id }) => id === project.id);
      return isNil(newItem) ? project : newItem;
    }),
    ...newProjects,
  ];
}

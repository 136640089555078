import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const pipelinesElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'ID',
    folder: 'Pipeline Header',
    isShownByDefault: true,
  },
  {
    field: 'license_no',
    sourceType: 'keyword',
    label: 'Licence No.',
    folder: 'Pipeline Header',
    isShownByDefault: true,
  },
  {
    field: 'line_segment_no',
    sourceType: 'integer',
    label: 'Segment Line No.',
    folder: 'Pipeline Header',
    isShownByDefault: true,
  },
  {
    field: 'lic_li_no',
    sourceType: 'keyword',
    label: 'Licence Line No.',
    folder: 'Pipeline Header',
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'Province',
    folder: 'Pipeline Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'comp_name',
    sourceType: 'text',
    label: 'Company',
    folder: 'Pipeline Header',
    isShownByDefault: true,
    width: 250,
  },
  {
    field: 'substance',
    sourceType: 'text',
    label: 'Substance',
    folder: 'Pipeline Header',
    isShownByDefault: true,
  },
  {
    field: 'seg_status',
    sourceType: 'text',
    label: 'Segment Status',
    folder: 'Pipeline Header',
    isShownByDefault: true,
  },
  {
    field: 'has_complaint',
    sourceType: 'boolean',
    label: 'Has Complaint',
    folder: 'Pipeline Header',
  },
  {
    field: 'has_spill',
    sourceType: 'boolean',
    label: 'Has Spill',
    folder: 'Pipeline Header',
  },
  {
    field: 'has_h2s',
    sourceType: 'boolean',
    label: 'Has H2S',
    folder: 'Pipeline Header',
  },
  {
    field: 'licence_approval_date',
    sourceType: 'date',
    label: 'Licence Approval Date',
    folder: 'Pipeline Header',
  },
  {
    field: 'seg_length',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'Segment Length',
    folder: 'Pipeline Specs',
    isShownByDefault: true,
  },
  {
    field: 'pipe_outer_diameter',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'mm',
    },
    label: 'Outer Diameter',
    folder: 'Pipeline Specs',
  },
  {
    field: 'pipe_material',
    sourceType: 'text',
    label: 'Pipe Material',
    folder: 'Pipeline Specs',
  },
  {
    field: 'pipe_grade',
    sourceType: 'keyword',
    label: 'Pipe Grade',
    folder: 'Pipeline Specs',
  },
  {
    field: 'pipe_maop',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'kPa',
    },
    label: 'MAOP',
    folder: 'Pipeline Specs',
  },
  {
    field: 'pipe_type',
    sourceType: 'text',
    label: 'Pipe Type',
    folder: 'Pipeline Specs',
  },
  {
    field: 'permit_approval_date',
    sourceType: 'date',
    label: 'Permit Approval Date',
    folder: 'Pipeline Header',
  },
  {
    field: 'original_id',
    sourceType: 'keyword',
    label: 'Original ID',
    folder: 'Pipeline Header',
  },
  {
    field: 'wall_thick',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'mm',
    },
    label: 'Wall Thickness',
    folder: 'Pipeline Specs',
  },
  {
    field: 'ad_number',
    sourceType: 'keyword',
    label: 'AD Number',
    folder: 'Pipeline Header',
    subFolder: 'BC Details',
  },
  {
    field: 'legacy_ogc_file',
    sourceType: 'keyword',
    label: 'Legacy OGC File',
    folder: 'Pipeline Header',
    subFolder: 'BC Details',
  },
  {
    field: 'product_code',
    sourceType: 'keyword',
    label: 'Product Code',
    folder: 'Pipeline Header',
    subFolder: 'BC Details',
  },
  {
    field: 'category',
    sourceType: 'keyword',
    label: 'Category',
    folder: 'Pipeline Header',
    subFolder: 'BC Details',
  },
  {
    field: 'line_type_code',
    sourceType: 'text',
    label: 'Line Type Code',
    folder: 'Pipeline Header',
    subFolder: 'BC Details',
  },
  {
    field: 'abandonment_date',
    sourceType: 'date',
    label: 'Abandonment Date',
    folder: 'Pipeline Header',
    subFolder: 'BC Details',
  },
  {
    field: 'operator_effective_date',
    sourceType: 'date',
    label: 'Operator Effective Date',
    folder: 'Pipeline Header',
    subFolder: 'BC Details',
  },
  {
    field: 'license_issue_date',
    sourceType: 'date',
    label: 'Licence Issue Date',
    folder: 'Pipeline Header',
  },
  {
    field: 'h2s_content',
    sourceType: 'double',
    label: 'H2S Content',
    folder: 'Pipeline Specs',
  },
  {
    field: 'pipe_joint_method',
    sourceType: 'text',
    label: 'Pipe Joint Method',
    folder: 'Pipeline Specs',
    subFolder: 'AB Specs',
  },
  {
    field: 'co2_content',
    sourceType: 'double',
    label: 'CO2 Content',
    folder: 'Pipeline Specs',
    subFolder: 'BC Specs',
  },
  {
    field: 'is_surface_segment',
    sourceType: 'boolean',
    label: 'Is Surface Segment',
    folder: 'Pipeline Specs',
  },
  {
    field: 'partial_pressure_kpa',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'kPa',
    },
    label: 'Partial Pressure',
    folder: 'Pipeline Specs',
  },
  {
    field: 'design_pressure_kpa',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'kPa',
    },
    label: 'Design Pressure',
    folder: 'Pipeline Specs',
  },
  {
    field: 'min_design_temp_c',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'C',
    },
    label: 'Min Design Temp',
    folder: 'Pipeline Specs',
  },
  {
    field: 'max_design_temp_c',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'C',
    },
    label: 'Max Design Temp',
    folder: 'Pipeline Specs',
  },
  {
    field: 'pipe_internal_coating',
    sourceType: 'text',
    label: 'Pipe Internal Coating',
    folder: 'Pipeline Specs',
  },
  {
    field: 'pipe_exterior_coating',
    sourceType: 'text',
    label: 'Pipe Exterior Coating',
    folder: 'Pipeline Specs',
    subFolder: 'BC Specs',
  },
  {
    field: 'cathodic_protection',
    sourceType: 'text',
    label: 'Cathodic Protection',
    folder: 'Pipeline Specs',
  },
  {
    field: 'cathodic_type',
    sourceType: 'text',
    label: 'Cathodic Protection Type',
    folder: 'Pipeline Specs',
    subFolder: 'BC Specs',
  },
  {
    field: 'mop_stress_%_of_smys',
    sourceType: 'double',
    label: 'MOP Stress % of SMYS',
    folder: 'Pipeline Specs',
    subFolder: 'BC Specs',
  },
  {
    field: 'pressure_test_date',
    sourceType: 'date',
    label: 'Pressure Test Date',
    folder: 'Pipeline Specs',
    subFolder: 'BC Specs',
  },
  {
    field: 'material_standard',
    sourceType: 'keyword',
    label: 'Material Standard',
    folder: 'Pipeline Specs',
  },
  {
    field: 'from_fac',
    sourceType: 'text',
    label: 'From Facility',
    folder: 'Location',
  },
  {
    field: 'from_loc',
    sourceType: 'keyword',
    label: 'From Location',
    folder: 'Location',
  },
  {
    field: 'to_fac',
    sourceType: 'text',
    label: 'To Facility',
    folder: 'Location',
  },
  {
    field: 'to_loc',
    sourceType: 'keyword',
    label: 'To Location',
    folder: 'Location',
  },
  /* Not renderable */
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'pipeline_id',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'substance_mapping',
    sourceType: 'keyword',
    label: 'Substance (simplified)',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import {
  Box,
  Button,
  MenuItem,
  Popover,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import type { ChartSeriesStyle } from '@pn/services/charts/types';
import { DebouncedSlider } from '@pn/ui/custom-components/DebouncedSlider';
import { ColorPicker } from '@pn/ui/custom-components/color-picker';
import {
  SMALL_SQ_GAP,
  SMALL_SQ_SIZE,
} from '@pn/ui/custom-components/color-picker/ColorSelector';
import { generateSliderMarks } from '@pn/ui/utils';

type Props = {
  anchorEl: HTMLElement | undefined;
  seriesKey: string;
  seriesStyle: ChartSeriesStyle;
  onClose: () => void;
  onUpsert: (styleKey: string, value: unknown) => void;
  onRemove: () => void;
};

export function ChartSeriesStylePopover({
  anchorEl,
  seriesKey,
  seriesStyle,
  onClose,
  onUpsert,
  onRemove,
}: Props) {
  const theme = useTheme();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <Box
        width={
          SMALL_SQ_SIZE * 10 + SMALL_SQ_GAP * 9 + parseInt(theme.spacing(4))
        }
        p={2}
      >
        <Typography>{seriesKey}</Typography>

        <Box mt={1}>
          <Typography variant="caption" color="textSecondary">
            Line color
          </Typography>
          <ColorPicker
            variant="compact"
            color={seriesStyle.borderColor ?? '#000'}
            defaultColor="#000"
            onChange={(color) => onUpsert('borderColor', color)}
          />
        </Box>

        <Box mt={1}>
          <Typography variant="caption" color="textSecondary">
            Line width
          </Typography>
          <DebouncedSlider
            size="small"
            marks={generateSliderMarks({
              min: 0.5,
              max: 10,
            })}
            min={0.5}
            max={10}
            step={0.5}
            valueLabelDisplay="auto"
            value={seriesStyle.borderWidth ?? 1.5}
            onChange={(_event, value) => onUpsert('borderWidth', value)}
          />
        </Box>

        <Box mt={1}>
          <TextField
            select
            label="Line style"
            size="small"
            fullWidth
            variant="outlined"
            value={seriesStyle.borderStyle ?? 'solid'}
            onChange={(event) => onUpsert('borderStyle', event.target.value)}
          >
            <MenuItem value="solid">Solid</MenuItem>
            <MenuItem value="dashed">Dashed</MenuItem>
            <MenuItem value="dotted">Dotted</MenuItem>
          </TextField>
        </Box>

        <Box mt={2}>
          <Button
            fullWidth
            color="inherit"
            startIcon={<InvertColorsOffIcon />}
            onClick={onRemove}
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

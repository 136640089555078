import { isNil } from 'lodash-es';
import { useStoreSelector } from './ReduxStoreProvider';

/**
 * This selector is designed to handle undefined reducers when they are
 * accessed by storage hooks. This happens right before the injection of
 * dynamic reducers.
 *
 * Will return selector over the initial state if the reducer has not been
 * initialized yet. Otherwise will behave like a default `useSelector`.
 *
 * @example
 * ```ts
 * const useSliceSelector = useBuildSliceSelector('wells-details', { isFetching: false });
 * const isFetching = useSliceSelector((state) => state.isFetching);
 * ```
 *
 * @TODO
 * Is there a way to curry this without a `React Hook "useSelector" cannot
 * be called inside a callback` warning?
 */
export const useBuildSliceSelector =
  <U>(sliceName: string, initialState: U) =>
  <T>(
    sliceSelector: (sliceState: U) => T,
    equalityFn?: (left: T, right: T) => boolean
  ) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStoreSelector((rootState: Record<string, U>) => {
      try {
        return sliceSelector(rootState[sliceName]);
      } catch (e) {
        if (isNil(rootState[sliceName])) {
          /* if the reducer has not been initialized yet, return the initial state */
          return sliceSelector(initialState);
        } else {
          /* otherwise rethrow the error */
          throw e;
        }
      }
    }, equalityFn);

import { DrawingState, useDrawing } from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isNil } from 'lodash-es';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

// TODO fix ctrl+z to not reset current selection

export function useHistory() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { drawingState, historyManager, redraw } = useDrawing();

  /**
   * Reset history every time drawing panel is opened.
   */
  React.useEffect(() => {
    if (!isDrawingPanelOpen) return;

    historyManager.reinit(drawingState);
  }, [isDrawingPanelOpen, historyManager, drawingState]);

  useHotkeys('ctrl+z', (e) => {
    e.preventDefault();

    const snapshot = historyManager.undo();
    if (!isNil(snapshot)) {
      Object.assign(drawingState, fromSnapshot(snapshot));
      redraw();
    }
  });

  useHotkeys(['ctrl+shift+z', 'ctrl+y'], (e) => {
    e.preventDefault();

    const snapshot = historyManager.redo();
    if (!isNil(snapshot)) {
      Object.assign(drawingState, fromSnapshot(snapshot));
      redraw();
    }
  });
}

function fromSnapshot(snapshot: DrawingState): Partial<DrawingState> {
  return {
    isSpacebarDown: false,
    isCustomPanning: false,
    isMovingFeature: false,
    isDrawing: false,

    resizeHoverDirection: undefined,
    resizeDirection: undefined,
    vertexHovered: undefined,
    isEdgeHovered: false,

    featureHovered: undefined,
    featuresHovered: {},
    featuresSelected: snapshot.featuresSelected,
    // featuresSelected: {},
    vertexDragged: undefined,

    boxSelection: undefined,

    features: snapshot.features,
    paths: snapshot.paths,
    order: snapshot.order,
  };
}

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Portal as MuiPortal } from '@mui/base';
import { CssBaseline } from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import React from 'react';
import {
  usePortals,
  type Portal,
} from 'src/ui/main-panel/portals/PortalsProvider';

/**
 * https://codesandbox.io/s/mui-portal-with-style-solution-ez35b?file=/src/App.tsx
 * https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 * We cannot use `useMemo` because it is not guranteed to never rerun.
 */
function useConst<T>(init: () => T) {
  const ref = React.useRef<T | null>(null);
  if (ref.current === null) {
    ref.current = init();
  }
  return ref.current;
}

type Props = {
  children: React.ReactNode;
  portal: Portal;
};

export function PortalPopout({ children, portal }: Props) {
  const {
    id,
    item: { dataType },
    route: { title },
  } = portal;

  const { handleClosePortal } = usePortals();

  const titleEl = useConst(() => document.createElement('title'));
  const containerEl = useConst(() => document.createElement('div'));

  const cache = useConst(() =>
    createCache({ key: 'external', container: containerEl })
  );

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    function closePortal() {
      setOpen(false);
      handleClosePortal(id);
    }

    const externalWindow = window.open(
      '',
      `${id}-portal`,
      'width=600,height=800,left=100,top=100,dependent=on,menubar=off,toolbar=off,location=off'
    );

    // if window.open fails
    if (!externalWindow) {
      closePortal();
      return;
    }

    externalWindow.addEventListener('beforeunload', closePortal);

    titleEl.innerText = `Petro Ninja | ${formatDataType(dataType, { case: 'sentence', form: 'singular' })} ${title}`;

    externalWindow.document.head.appendChild(titleEl);
    externalWindow.document.body.appendChild(containerEl);

    /* Copy all style elements from the main document to the new window */
    Array.from(document.styleSheets).forEach((styleSheet) => {
      if (styleSheet.href) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = styleSheet.href;
        externalWindow.document.head.appendChild(link);
      } else {
        const style = document.createElement('style');
        if (!styleSheet.ownerNode?.textContent) return;
        style.appendChild(
          document.createTextNode(styleSheet.ownerNode.textContent)
        );
        externalWindow.document.head.appendChild(style);
      }
    });

    /* Override index.css styles to support printing of pop-out content */
    const overrideStyles = `
      @media print {
        @page {
          size: portrait;
        }
        
        body > *:not(#root) {
          display: block;
        }
      }
    `;

    const overrideStyleElement = document.createElement('style');
    overrideStyleElement.appendChild(document.createTextNode(overrideStyles));
    externalWindow.document.head.appendChild(overrideStyleElement);

    setOpen(true);

    return () => {
      externalWindow.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!open) return null;

  return (
    <MuiPortal container={containerEl}>
      <CacheProvider value={cache}>
        <CssBaseline />
        {children}
      </CacheProvider>
    </MuiPortal>
  );
}

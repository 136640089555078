import type {
  GridColDef,
  GridInputRowSelectionModel,
} from '@mui/x-data-grid-pro';
import type { DataItem, DataItemId } from '@pn/core/domain/data';
import type {
  Filter,
  LinkOperator,
  Sort,
} from '@pn/core/domain/query';
import { dataGridQueryFieldsMapper } from '@pn/services/local/query/dataGridQueryFieldsMapper';
import { dataGridQueryFilteringMapper } from '@pn/services/local/query/dataGridQueryFilteringMapper';
import { dataGridQuerySortingMapper } from '@pn/services/local/query/dataGridQuerySortingMapper';
import React from 'react';

export function useTableModels(params: {
  columns: GridColDef[];
  sorts: Sort[];
  filters: Filter[];
  filtersLinkOperator: LinkOperator;
  fields: string[];
  checkedIds: DataItemId[];
  dataItemSelected?: DataItem;
  checkboxSelection: boolean;
}) {
  const sortModel = React.useMemo(
    () => dataGridQuerySortingMapper.toTargetSorting(params.sorts),
    [params.sorts]
  );

  const filterModel = React.useMemo(
    () =>
      dataGridQueryFilteringMapper.toTargetFiltering(
        params.filters,
        params.filtersLinkOperator,
        params.columns
      ),
    [params.filters, params.filtersLinkOperator, params.columns]
  );

  const columnVisibilityModel = React.useMemo(
    () =>
      dataGridQueryFieldsMapper.toTargetFields(params.fields, params.columns),
    [params.columns, params.fields]
  );

  const selectionModel: GridInputRowSelectionModel = React.useMemo(
    () =>
      params.checkboxSelection
        ? params.checkedIds
        : params.dataItemSelected?._id ?? [],
    [params.checkboxSelection, params.checkedIds, params.dataItemSelected]
  );

  return { sortModel, filterModel, columnVisibilityModel, selectionModel };
}

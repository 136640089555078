import { darken, lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    // padding: `2px 4px`,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    backgroundColor: `rgba(0, 0, 0, ${theme.palette.action.hoverOpacity})`,
    border: `1px solid ${
      theme.palette.mode === 'light'
        ? lighten(theme.borderColor, 0.5)
        : darken(theme.borderColor, 0.25)
    }`,
    borderRadius: theme.shape.borderRadius,
    // color:
    //   theme.palette.mode === 'light'
    //     ? theme.palette.primary.dark
    //     : theme.palette.primary.light,
  },
}));

type Props = {
  children: React.ReactNode;
  className?: string;
};

export function Code({ children, className, ...rest }: Props) {
  const { classes, cx } = useStyles();

  return (
    <kbd {...rest} className={cx(classes.root, className)}>
      {children}
    </kbd>
  );
}

import { dependencies } from '@pn/core/dependencies';
import {
  getArea,
  getDistance,
  pointToGeoPoint,
} from '@pn/core/domain/geography';
import { areClose } from '@pn/core/domain/point';
import {
  REFERENCE_PT,
  REFERENCE_ZOOM,
  computeMapTransformation,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { isNil } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import React from 'react';

export function useMoveVertex() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { drawingState, historyManager, redraw } = useDrawing();

  React.useEffect(() => {
    if (!isDrawingPanelOpen) return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    let startPoint = { x: 0, y: 0 };
    let vertexPoint = { x: 0, y: 0 };
    let wasClosed = false;
    let hasMoved = false;

    const onMouseDown = (e: mapboxgl.MapMouseEvent) => {
      if (drawingState.isCustomPanning || isNil(drawingState.vertexHovered))
        return;

      const featureSelected = Object.values(drawingState.featuresSelected)[0];
      assert(
        featureSelected?.type === 'poly',
        'Vertex manipulation is only supported for poly features'
      );

      const transformation = computeMapTransformation(REFERENCE_PT, false);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      const points = featureSelected.coordinates;

      startPoint = transformPoint(e.point, inverseTransformation);
      vertexPoint = featureSelected.coordinates[drawingState.vertexHovered];
      wasClosed = areClose(points[0], points[points.length - 1]);
      hasMoved = false;

      drawingState.vertexDragged = drawingState.vertexHovered;

      redraw();

      map.disableMovement();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (drawingState.isCustomPanning || isNil(drawingState.vertexDragged))
        return;

      const featureSelected = Object.values(drawingState.featuresSelected)[0];
      assert(
        featureSelected?.type === 'poly',
        'Vertex manipulation is only supported for poly features'
      );

      const transformation = computeMapTransformation(REFERENCE_PT, false);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      const bbox = map._native.getContainer().getBoundingClientRect();
      const point = transformPoint(
        { x: e.clientX - bbox.left, y: e.clientY - bbox.top },
        inverseTransformation
      );

      let x = vertexPoint.x + (point.x - startPoint.x);
      let y = vertexPoint.y + (point.y - startPoint.y);

      const points = featureSelected.coordinates;

      const isFirstVertex = drawingState.vertexDragged === 0;
      const isLastVertex = drawingState.vertexDragged === points.length - 1;

      if (!wasClosed && points.length > 2 && (isFirstVertex || isLastVertex)) {
        const inversePoint =
          points[points.length - 1 - drawingState.vertexDragged];

        if (areClose({ x, y }, inversePoint, 10 / transformation.scale)) {
          ({ x, y } = inversePoint);
        }
      }

      points[drawingState.vertexDragged] = { x, y };

      if (wasClosed && (isFirstVertex || isLastVertex)) {
        const inverseIndex = points.length - 1 - drawingState.vertexDragged;
        points[inverseIndex] = { x, y };
      }

      if (featureSelected.measurements) {
        const geoPoints = points.map((point) =>
          pointToGeoPoint(point, REFERENCE_PT, REFERENCE_ZOOM)
        );

        featureSelected.measurements.distance = getDistance(geoPoints);
        if (!isNil(featureSelected.measurements.area))
          featureSelected.measurements.area = getArea(geoPoints);

        featureSelected.measurements.position = points[points.length - 1];
      }

      drawingState.features[featureSelected.id] = featureSelected;

      hasMoved = true;

      redraw();
    };

    const onMouseUp = () => {
      if (isNil(drawingState.vertexDragged)) return;

      drawingState.vertexDragged = undefined;

      if (hasMoved) historyManager.add(drawingState);

      map.enableMovement();
    };

    mapboxMap.on('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      map.enableMovement();

      mapboxMap.off('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [
    isDrawingPanelOpen,
    // the following never change:
    drawingState,
    historyManager,
    redraw,
  ]);
}

import { ApiError } from '@pn/core/errors';
import type {
  ApiRequestParams,
  IApiClient,
} from '@pn/core/services/http/ports';
import { STACKDX_URL } from '@pn/core/urls';
import assert from 'assert';

async function makeApiRequest<T>({
  method = 'GET',
  url,
  payload,
}: ApiRequestParams): Promise<T> {
  const stackToken = sessionStorage.getItem('stack-api-token');
  assert(stackToken, 'Stack token missing from session storage');

  const response = await fetch(`${STACKDX_URL}/${url}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: stackToken,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    let errorMessage = '';
    let jsonData = undefined;

    try {
      jsonData = await response.json();
      errorMessage = jsonData.message || JSON.stringify(jsonData);
    } catch (jsonError) {
      errorMessage = await response.text(); // fallback to text in case the response is not JSON
    }

    throw new ApiError(
      `Stack API error caught: ${response.status}`,
      response.status,
      method + ' ' + url,
      jsonData ?? errorMessage
    );
  }

  const json = await response.json();
  return json;
}

export const stackApiClient: IApiClient = {
  request: makeApiRequest,
  stream: () => {
    throw new Error('Not implemented');
  },
};

import { isPNDataType } from '@pn/core/domain/data';
import { buildQuery, DataMultiSelectionReason } from '@pn/core/domain/query';
import {
  createInitInternalState,
  getLayerId,
  type IWorkspaceItemMapper,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { findOrThrow } from '@pn/core/utils/logic';
import { apiLayerItemMapper } from '@pn/services/api/layer/apiLayerMapper';
import { apiProjectUserMapper } from '@pn/services/api/user/apiUserMapper';
import { generateGeoJSONFeatureCollection } from '@pn/services/map/mapbox/mapboxUtils';
import { mapboxLayerMapper } from '@pn/services/map/mapbox/mappers/mapboxLayerMapper';
import { isNil } from 'lodash-es';
import type { ApiList, ApiListPayload } from './types';

export const apiListMapper: IWorkspaceItemMapper<ApiList, ApiListPayload> = {
  toWorkspaceItem: (apiList) => {
    const sourceItem = isPNDataType(apiList.source.id)
      ? findOrThrow(pnWorkspaceItems, ({ id }) => id === apiList.source.id)
      : apiLayerItemMapper.toWorkspaceItem(apiList.source);

    return {
      folder: getFolder(apiList),
      isTemporary: apiList.origin === 'stackdx', // hide Stack source lists from the Library
      id: apiList.id,
      dataType: apiList.source.id,
      name: apiList.name,
      numberOfElements: apiList.object_ids.length,
      sourceItem,
      itemType: 'list',
      origin: apiList.origin,
      createdAt: apiList.created_at,
      updatedAt: apiList.updated_at,
      createdBy: !isNil(apiList.user)
        ? apiProjectUserMapper.toDomainProjectUser(apiList.user)
        : undefined,
      isShared: apiList.shared,
      isGlobal: apiList.global,
      map: {
        layers: apiList.layers_definition.map((mapboxLayer, index) => ({
          name: mapboxLayer.name,
          ...sourceItem.map.layers[index],
          ...mapboxLayerMapper.toDomainLayer(
            {
              source: {
                type: 'geojson',
                data: generateGeoJSONFeatureCollection([]),
              },
              'source-layer': undefined,
              ...mapboxLayer, // will override the source if one is present
              id: getLayerId(sourceItem.id, apiList.id, index),
            },
            mapboxLayer.render_as_points
          ),
        })),
      },
      dataSource: sourceItem.dataSource,
      detailsSource: sourceItem.detailsSource,
      query: {
        ...buildQuery({
          id: apiList.id,
          dataType: apiList.source.id,
        }),
        requestedIds: apiList.object_ids,
        multiSelectionReason: DataMultiSelectionReason.List,
        ignoreLimit: true,
      },
      ...createInitInternalState({
        isVisualized: false,
        mapping: sourceItem.mapping,
      }),
    };
  },
  toOriginalItem: (item) => {
    return {
      name: item.name,
      layer_id: item.dataType,
      object_ids: item.query.requestedIds,
      layers_definition: item.map.layers.map((layer) => ({
        ...mapboxLayerMapper.toTargetLayer(layer),
        id: '', // not used
        name: layer.name,
        render_as_points: layer.renderAsPoints,
      })),
    };
  },
};

function getFolder(apiList: ApiList): WorkspaceItem['folder'] {
  const userId = getCurrentUserId(); // HACK

  if (apiList.global) {
    return undefined; // do not show in the Library outside of projects
  } else if (apiList.origin === 'stackdx') {
    return 'StackDX';
  } else if (apiList.origin === 'boe_intel') {
    return 'BOE Intel';
  } else if (!isNil(userId) && apiList.user?.id === userId) {
    return 'Personal';
  } else {
    return apiList.shared ? 'Shared' : undefined;
  }
}

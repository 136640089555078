import { dependencies } from '@pn/core/dependencies';
import { handleError } from '@pn/core/errors/handleError';
import { currentUserActions } from '@pn/core/storage';
import { apiUserProvider } from '@pn/services/api/user/apiUserProvider';
import React from 'react';

export async function getCurrentUser() {
  try {
    currentUserActions().request();

    const user = await apiUserProvider.getCurrentUser();

    currentUserActions().receive(user);
  } catch (error) {
    handleError({
      error,
      onError: currentUserActions().error,
      userFriendlyMessage: 'Failed to get your profile',
    });
  }
}

export function useAutoGetCurrentUser() {
  const { isAuthenticated } = dependencies.useAuthenticationService();

  React.useEffect(() => {
    if (isAuthenticated) {
      getCurrentUser();
    }
  }, [isAuthenticated]);
}

import {
  getPointsApproximation,
  type GeoPoint,
  type GeoShape,
} from '@pn/core/domain/geography';
import { isNil } from 'lodash-es';
import type { DomainValue } from './mappingItem';

export type DataItemId = string | number;
export type DataItem = {
  _id: DataItemId;
  geoShape: GeoShape | undefined;
  [key: string]: DomainValue['value'];
};

export function getPointsApproximationFromDataItems(
  dataItems: DataItem[]
): GeoPoint[] {
  return dataItems.reduce<GeoPoint[]>((geoPoints, { geoShape }) => {
    if (!isNil(geoShape) && !isNil(geoShape.shape)) {
      geoPoints.push(...getPointsApproximation(geoShape.shape));
    }
    return geoPoints;
  }, []);
}

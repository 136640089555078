import { type GeoPoint, toGeoPoint } from '@pn/core/domain/geography';
import { tokenManager } from '@pn/core/services/authentication/tokenManager';
import { isEmbedded } from '@pn/core/utils/embedded';
import env, { isUS } from '@pn/core/utils/env';
import { webStorageService } from '@pn/services/storage/webStorageService';
import { isNil } from 'lodash-es';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = env.PN_MAPBOX_ACCESS_TOKEN;

declare module 'mapbox-gl' {
  interface Map {
    geolocate: mapboxgl.GeolocateControl;
  }
}

type MapPosition = {
  center: GeoPoint | undefined;
  zoom: number | undefined;
};

const defaultMapPosition = isUS()
  ? toGeoPoint(40, -98)
  : toGeoPoint(51.25, -114.25);
const defaultZoom = isUS() ? 4 : 9.75;

const lastMapPosition: MapPosition = {
  center: undefined,
  zoom: undefined,
};

export function setLastMapPosition(params: { center: GeoPoint; zoom: number }) {
  lastMapPosition.center = params.center;
  lastMapPosition.zoom = params.zoom;

  webStorageService('pn').set('last-map-position', lastMapPosition);
}

function getMapPosition() {
  const savedMapPosition =
    webStorageService('pn').get<MapPosition>('last-map-position');

  if (!isNil(lastMapPosition.center) && !isNil(lastMapPosition.zoom)) {
    return lastMapPosition;
  } else if (!isNil(savedMapPosition)) {
    return savedMapPosition;
  } else {
    return { center: defaultMapPosition, zoom: defaultZoom };
  }
}

export const configureMapboxMap = (
  mapContainer: HTMLElement,
  mapboxStyle: mapboxgl.Style,
  options: {
    zoom?: number;
    center?: GeoPoint;
  }
) => {
  const sw = new mapboxgl.LngLat(-172, 20);
  const ne = new mapboxgl.LngLat(-20, 73);
  const maxBounds = new mapboxgl.LngLatBounds(sw, ne);

  const map = new mapboxgl.Map({
    container: mapContainer,
    style: mapboxStyle,
    center: isNil(options?.center) ? getMapPosition().center : options.center,
    zoom: isNil(options?.zoom) ? getMapPosition().zoom : options.zoom,
    maxZoom: 18,
    boxZoom: false,
    maxBounds,
    preserveDrawingBuffer: true,
    dragRotate: false,
    touchPitch: false,
    touchZoomRotate: true,
    // @ts-expect-error `useWebGL2` does exist despite what the types say
    useWebGL2: true,
    // fadeDuration: 0,

    transformRequest: (url, resourceType) => {
      if (
        resourceType === 'Tile' &&
        (url.startsWith('https://api.petroniche.com/') ||
          url.startsWith('https://api.petroninja.com/') ||
          url.includes('.cloudfront.net'))
      ) {
        const token = tokenManager.getCached();

        return {
          url,
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': env.PN_API_KEY,
          },
        };
      } else {
        return { url };
      }
    },
  });

  map.touchZoomRotate.disableRotation();
  map.doubleClickZoom.disable(); // `doubleClickZoom` option doesn't work
  map.scrollZoom.setWheelZoomRate(1 / 125); // default is 1/450

  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
    }),
    'bottom-right'
  );

  if (!isEmbedded()) {
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showAccuracyCircle: true,
    });

    geolocate.on('error', (error: any) => {
      console.error('Geolocate error', error);
    });

    map.addControl(geolocate, 'bottom-right');
    map.geolocate = geolocate;
  }

  return map;
};

import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ChartIcon } from './ChartIcon';

const SPINNER_SIZE = 104;

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  spinner: {
    position: 'absolute',
    left: `calc(50% - ${SPINNER_SIZE / 2}px)`,
    top: `calc(50% - ${SPINNER_SIZE / 2 + 35}px)`,
    opacity: 0.1,
  },
  iconContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 48,
    color: 'rgba(0, 0, 0, 0.175)',
  },
}));

export const ChartLoadingIndicator = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Skeleton variant="rounded" width="100%" height="100%" />
      <CircularProgress
        color="inherit"
        size={SPINNER_SIZE}
        className={classes.spinner}
      />
      <Box className={classes.iconContainer}>
        <ChartIcon className={classes.icon} />
        <Typography color="textSecondary" sx={{ mt: 6 }}>
          Loading production data...
        </Typography>
      </Box>
    </Box>
  );
};

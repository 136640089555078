import type { MapboxIMap } from '..';

export const enableMovement: MapboxIMap['enableMovement'] = function (
  this: MapboxIMap
) {
  /* Fixes the bug where pan & zoom stop working if disabled mid-animation. */
  this._native.panBy([0, 0], { animate: false });

  this._native.dragPan.enable();
  this._native.scrollZoom.enable();
};

import { NumericInputWithToggles } from '@pn/ui/inputs/NumericInputWithToggles';
import Color from 'color';

type Props = {
  disabled?: boolean;
  opacityColor?: string;
  opacityStroke?: string;
  value: number;
  onChange: (value: number) => void;
};

export const OpacityInputWithToggles = ({
  disabled = false,
  opacityColor = '#888',
  opacityStroke,
  value,
  onChange,
}: Props) => {
  return (
    <NumericInputWithToggles
      disabled={disabled}
      toggles={[
        {
          value: 25,
          icon: <IconOpacity25 color={opacityColor} stroke={opacityStroke} />,
        },
        {
          value: 50,
          icon: <IconOpacity50 color={opacityColor} stroke={opacityStroke} />,
        },
        {
          value: 75,
          icon: <IconOpacity75 color={opacityColor} stroke={opacityStroke} />,
        },
        {
          value: 100,
          icon: <IconOpacity100 color={opacityColor} stroke={opacityStroke} />,
        },
      ]}
      units="%"
      value={value}
      onChange={onChange}
    />
  );
};

type IconProps = {
  color: string;
  stroke: string | undefined;
};

const IconOpacity25 = ({ color, stroke }: IconProps) => (
  <svg viewBox="0 0 24 24">
    <rect
      width="24"
      height="24"
      rx="4"
      ry="4"
      fill={Color(color).alpha(0.25).toString()}
      stroke={stroke}
      strokeWidth="2"
    />
  </svg>
);

const IconOpacity50 = ({ color, stroke }: IconProps) => (
  <svg viewBox="0 0 24 24">
    <rect
      width="24"
      height="24"
      rx="4"
      ry="4"
      fill={Color(color).alpha(0.5).toString()}
      stroke={stroke}
      strokeWidth="2"
    />
  </svg>
);

const IconOpacity75 = ({ color, stroke }: IconProps) => (
  <svg viewBox="0 0 24 24">
    <rect
      width="24"
      height="24"
      rx="4"
      ry="4"
      fill={Color(color).alpha(0.75).toString()}
      stroke={stroke}
      strokeWidth="2"
    />
  </svg>
);

const IconOpacity100 = ({ color, stroke }: IconProps) => (
  <svg viewBox="0 0 24 24">
    <rect
      width="24"
      height="24"
      rx="4"
      ry="4"
      fill={Color(color).alpha(1).toString()} // 'transparent' will be normalized into #000
      stroke={stroke}
      strokeWidth="2"
    />
  </svg>
);

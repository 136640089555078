import type { GridMapboxStyle } from './styles';

export const gridsTownship = (style: GridMapboxStyle) => [
  {
    minzoom: 9,
    layout: {
      'text-field': ['to-string', ['get', 'LABEL']],
      'text-size': style.textSize,
      'text-allow-overlap': false,
      'text-ignore-placement': false,
      visibility: 'visible',
    },
    type: 'symbol',
    source: 'composite',
    id: 'ab-township-labels',
    paint: {
      'text-color': style.textColor,
      ...(style.textHaloColor &&
        style.textHaloWidth && {
          'text-halo-color': style.textHaloColor,
          'text-halo-width': style.textHaloWidth,
        }),
    },
    'source-layer': 'AB_TOWNSHIP_CENTERS',
  },
  {
    minzoom: 9,
    type: 'line',
    source: 'composite',
    id: 'ab-township-grid',
    paint: {
      'line-color': style.lineColor,
      'line-width': style.lineWidth,
    },
    'source-layer': 'AB_TOWNSHIP_GRID',
    layout: {
      visibility: 'visible',
    },
  },

  {
    minzoom: 9,
    layout: {
      'text-field': ['to-string', ['get', 'LABEL']],
      'text-size': style.textSize,
      'text-allow-overlap': false,
      'text-ignore-placement': false,
      visibility: 'visible',
    },
    type: 'symbol',
    source: 'composite',
    id: 'bc-township-labels',
    paint: {
      'text-color': style.textColor,
      ...(style.textHaloColor &&
        style.textHaloWidth && {
          'text-halo-color': style.textHaloColor,
          'text-halo-width': style.textHaloWidth,
        }),
    },
    'source-layer': 'BC_TWP_CENTERS',
  },
  {
    id: 'bc-township-grid',
    minzoom: 9,
    type: 'line',
    paint: {
      'line-color': style.lineColor,
      'line-width': style.lineWidth,
    },
    source: 'composite',
    'source-layer': 'BC_TWP_GRID',
    layout: {
      visibility: 'visible',
    },
  },

  {
    layout: {
      'text-field': ['to-string', ['get', 'label']],
      'text-size': style.textSize,
      'text-allow-overlap': false,
      'text-ignore-placement': false,
      visibility: 'visible',
    },
    type: 'symbol',
    source: 'composite',
    id: 'mb-township-labels',
    paint: {
      'text-color': style.textColor,
      ...(style.textHaloColor &&
        style.textHaloWidth && {
          'text-halo-color': style.textHaloColor,
          'text-halo-width': style.textHaloWidth,
        }),
    },
    'source-layer': 'MB_TOWNSHIP_CENTERS',
  },
  {
    id: 'mb-township-grid',
    type: 'line',
    paint: {
      'line-color': style.lineColor,
      'line-width': style.lineWidth,
    },
    source: 'composite',
    'source-layer': 'MB_TOWNSHIP_GRID',
    layout: {
      visibility: 'visible',
    },
  },

  {
    minzoom: 9,
    layout: {
      'text-field': ['to-string', ['get', 'label']],
      'text-size': style.textSize,
      'text-allow-overlap': false,
      'text-ignore-placement': false,
      visibility: 'visible',
    },
    type: 'symbol',
    source: 'composite',
    id: 'sk-township-labels',
    paint: {
      'text-color': style.textColor,
      ...(style.textHaloColor &&
        style.textHaloWidth && {
          'text-halo-color': style.textHaloColor,
          'text-halo-width': style.textHaloWidth,
        }),
    },
    'source-layer': 'SK_TOWNSHIP_CENTERS',
  },
  {
    minzoom: 9,
    type: 'line',
    source: 'composite',
    id: 'sk-township-grid',
    paint: {
      'line-color': style.lineColor,
      'line-width': style.lineWidth,
    },
    'source-layer': 'SK_TOWNSHIP_GRID',
    layout: {
      visibility: 'visible',
    },
  },

  {
    id: 'us-township-labels',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'USTownshipLabels',
    minzoom: 9,
    layout: {
      'text-field': ['to-string', ['get', 'label']],
      'text-size': style.textSize,
      'text-allow-overlap': false,
      'text-ignore-placement': false,
      visibility: 'visible',
    },
    paint: {
      'text-color': style.textColor,
      ...(style.textHaloColor &&
        style.textHaloWidth && {
          'text-halo-color': style.textHaloColor,
          'text-halo-width': style.textHaloWidth,
        }),
    },
  },
  {
    minzoom: 9,
    layout: {
      visibility: 'visible',
    },
    type: 'line',
    source: 'composite',
    id: 'us-township-grid',
    paint: {
      'line-color': style.lineColor,
      'line-width': style.lineWidth,
    },
    'source-layer': 'USTownshipgrid',
  },
];

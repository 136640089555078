// https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md#available-custom-modes

import MapboxDraw, { type DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import { isNil } from 'lodash-es';

type CustomModeState = any;
type CustomModeOptions = {
  textField: string;
  textSize: number;
  textColor: string;
  inProgress?: boolean;
};

let featureInProgress: MapboxDraw.DrawFeature | undefined;

export const AnnotationTextDrawMode: DrawCustomMode<
  CustomModeState,
  CustomModeOptions
> = {
  ...MapboxDraw.modes.draw_point,
  onSetup: function (opts) {
    if (opts.inProgress) {
      return { point: featureInProgress };
    }

    const point = this.newFeature({
      type: 'Feature',
      properties: {
        ...opts,
        isTextFeature: true,
        isNew: true,
      },
      geometry: {
        type: 'Point',
        coordinates: [],
      },
    });

    this.addFeature(point);

    this.clearSelectedFeatures();
    this.updateUIClasses({ mouse: 'add' });

    this.select(point.id.toString());

    featureInProgress = point;

    return { point };
  },
  onClick: function (state, e) {
    // this.updateUIClasses({ mouse: 'text' });
    state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat);
    this.map.fire('draw.create', {
      features: [state.point.toGeoJSON()],
    });
  },
  onStop: function (state) {
    /* This happens when a user changes text color/size while typing */
    // if (draw.getMode() === 'draw_annotation_text') return;
    // if (
    //   !state.point.getCoordinate().length ||
    //   isEmpty(state.point.properties.textField)
    // ) {
    //   console.log('onStop clear', state.point.id);
    //   this.deleteFeature(state.point.id, { silent: true });
    //   this.map.fire('draw.delete', { features: [state.point.toGeoJSON()] });
    // }
  },
  onKeyDown: function (state, event) {
    if (event.key === 'Delete') {
      state.point.properties.isNew = false;
      this.deleteFeature(state.point.id, { silent: true });
      this.changeMode('simple_select');
    } else if (event.key === 'Backspace') {
      state.point.properties.isNew = false;
      state.point.properties.textField = state.point.properties.textField.slice(
        0,
        -1
      );
      state.point.updateCoordinate(
        '',
        state.point.coordinates[0],
        state.point.coordinates[1]
      ); // trigger update on the map

      // this.map.fire('draw.update');
    } else if (isPrintableCharacter(event)) {
      state.point.properties.isNew = false;
      state.point.properties.textField += event.key;
      state.point.updateCoordinate(
        '',
        state.point.coordinates[0],
        state.point.coordinates[1]
      ); // trigger update on the map

      // this.map.fire('draw.update');
    }
  },
  onKeyUp: function (state, event) {
    if (event.key === 'Enter') {
      return this.changeMode('simple_select', { featureIds: [state.point.id] });
    }
    if (event.key === 'Escape') {
      return this.changeMode('simple_select');
    }
  },
  toDisplayFeatures: function (state, geojson, display) {
    if (!('properties' in geojson) || isNil(geojson.properties)) return;

    const isActivePoint = geojson.properties.id === state.point.id;
    geojson.properties.active = isActivePoint.toString();

    display(geojson);
  },
};

function isPrintableCharacter(event: KeyboardEvent): boolean {
  return event.key.length === 1;
}

import { dependencies } from '@pn/core/dependencies';
import { generateId } from '@pn/core/utils/id';
import {
  REFERENCE_PT,
  computeMapTransformation,
  getContext,
  pathToPoints,
  pointsToCurvedPath,
  scalePoint,
  transformPathData,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';
import simplify from 'simplify-js';

export function useFreehandTool(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const {
    liveCanvasRef,
    drawingMode,
    strokeColor,
    strokeWidth,
    opacity,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  React.useEffect(() => {
    if (!isDrawingPanelOpen || drawingMode !== 'freehand') return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    const ctx = getContext(liveCanvasRef.current);

    let currentPath = '';
    let lastPoint = { x: 0, y: 0 };

    const onMouseDown = (e: mapboxgl.MapMouseEvent) => {
      if (drawingState.isCustomPanning) return;

      drawingState.isDrawing = true;

      const point = scalePoint(e.point);
      currentPath = `M${point.x} ${point.y}`;
      lastPoint = point;

      map.disableMovement();

      ctx.canvas.style.opacity = opacity.toString();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (drawingState.isCustomPanning || !drawingState.isDrawing) return;

      const bbox = map._native.getContainer().getBoundingClientRect();
      const point = scalePoint({
        x: e.clientX - bbox.left,
        y: e.clientY - bbox.top,
      });

      currentPath += ` L${point.x} ${point.y}`;

      const path = new Path2D(
        `M${lastPoint.x} ${lastPoint.y} L${point.x} ${point.y}`
      );

      lastPoint = point;

      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = strokeWidth;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      // ctx.globalAlpha = opacity;
      ctx.stroke(path);

      // ctx.globalAlpha = 1;
    };

    const onMouseUp = () => {
      if (!drawingState.isDrawing) return;

      ctx.canvas.style.opacity = '1';

      const transformation = computeMapTransformation(REFERENCE_PT);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      const points = pathToPoints(currentPath);
      const simplifiedPoints = simplify(
        points,
        Math.ceil(window.devicePixelRatio)
      );
      const simplifiedPath = pointsToCurvedPath(simplifiedPoints);

      drawingState.isDrawing = false;

      const id = generateId();
      drawingState.features[id] = {
        id,
        type: 'path',
        pathData: transformPathData(simplifiedPath, inverseTransformation),
        strokeColor: strokeColor,
        strokeWidth: strokeWidth / transformation.scale,
        opacity,
        itemId,
        isVisible: true,
      };
      drawingState.order.push(id);

      redraw();

      historyManager.add(drawingState);

      map.enableMovement();
    };

    mapboxMap.on('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      map.enableMovement();

      mapboxMap.off('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [
    isDrawingPanelOpen,
    drawingMode,
    strokeColor,
    strokeWidth,
    opacity,
    itemId,
    // the following never change:
    liveCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}

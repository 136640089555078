import { StockInfo } from '@pn/core/domain/intel/intel';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  isError: false,
  stock: undefined as StockInfo | undefined,
};

export const createRealTimeStockSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.stock = payload;
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.stock = undefined;
      },
    },
  });

export const realTimeStockSlice = createRealTimeStockSlice('stock');

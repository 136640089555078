import { dependencies } from '@pn/core/dependencies';
import { PolyFeature } from '@pn/core/domain/drawing';
import { areClose, type Point } from '@pn/core/domain/point';
import { generateId } from '@pn/core/utils/id';
import {
  REFERENCE_PT,
  computeMapTransformation,
  drawFeature,
  getContext,
  scalePoint,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { generateFeatureMeasurements } from '@pn/services/drawing/measurement';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import mapboxgl from 'mapbox-gl';
import React from 'react';

export function useLineTool(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const {
    liveCanvasRef,
    drawingMode,
    strokeColor,
    strokeWidth,
    fillColor,
    opacity,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const isMeasuring = drawingMode === 'distance';
  const isDrawing =
    isDrawingPanelOpen && ['line', 'arrow'].includes(drawingMode);

  React.useEffect(() => {
    if (!isMeasuring && !isDrawing) return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    const liveCtx = getContext(liveCanvasRef.current);

    const points: Point[] = [];
    let closed = false;

    const onClick = (e: mapboxgl.MapMouseEvent) => {
      if (drawingState.isCustomPanning) return;
      if (closed) return onEnd(e);

      drawingState.isDrawing = true;

      const point = scalePoint(e.point);
      points.push(point);

      map.disableMovement();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!drawingState.isDrawing) return;

      const bbox = map._native.getContainer().getBoundingClientRect();
      const point = scalePoint({
        x: e.clientX - bbox.left,
        y: e.clientY - bbox.top,
      });

      if (points.length > 2 && areClose(points[0], point, 10)) {
        closed = true;
        point.x = points[0].x;
        point.y = points[0].y;
      } else {
        closed = false;
      }

      liveCtx.clearRect(0, 0, liveCtx.canvas.width, liveCtx.canvas.height);

      const feature: PolyFeature = {
        type: 'poly',
        id: generateId(),
        coordinates: [...points, point],
        strokeColor: isMeasuring ? 'black' : strokeColor,
        strokeWidth: isMeasuring ? 2 : strokeWidth,
        fillColor: ['arrow', 'distance'].includes(drawingMode)
          ? 'transparent'
          : fillColor,
        opacity: isMeasuring ? 1 : opacity,
        itemId,
        isVisible: true,
        arrow: drawingMode === 'arrow',
      };

      drawFeature(
        liveCtx,
        drawingState.displayMeasurements || isMeasuring
          ? {
              ...feature,
              measurements: generateFeatureMeasurements(feature, {
                local: true,
              }),
            }
          : feature
      );
    };

    const onEnd = (e: KeyboardEvent | mapboxgl.MapMouseEvent) => {
      const isClick = e.type === 'click';
      const isDoubleClick = e.type === 'dblclick';
      const isValidKey =
        e.type === 'keydown' && (e.key === 'Escape' || e.key === 'Enter');

      if (isClick || isDoubleClick || isValidKey) {
        drawingState.isDrawing = false;
        drawingState.isCustomPanning = false;

        const transformation = computeMapTransformation(REFERENCE_PT);
        const inverseTransformation = {
          dx: -transformation.dx,
          dy: -transformation.dy,
          scale: 1 / transformation.scale,
        };

        if (closed) points.push(points[0]);
        if (isDoubleClick) points.pop();

        if (points.length > 1) {
          const pointsTransformed = points.map((p) =>
            transformPoint(p, inverseTransformation)
          );

          const id = generateId();
          const feature: PolyFeature = {
            type: 'poly',
            id,
            coordinates: pointsTransformed,
            strokeColor: isMeasuring ? 'black' : strokeColor,
            strokeWidth: (isMeasuring ? 2 : strokeWidth) / transformation.scale,
            fillColor: ['arrow', 'distance'].includes(drawingMode)
              ? 'transparent'
              : fillColor,
            opacity: isMeasuring ? 1 : opacity,
            itemId,
            isVisible: true,
            arrow: drawingMode === 'arrow',
          };

          drawingState.features[id] =
            drawingState.displayMeasurements || isMeasuring
              ? {
                  ...feature,
                  measurements: generateFeatureMeasurements(feature),
                }
              : feature;
          drawingState.order.push(id);

          redraw();

          historyManager.add(drawingState);
        }

        points.length = 0;
        closed = false;

        map.enableMovement();
      }
    };

    mapboxMap.on('click', onClick);
    mapboxMap.on('dblclick', onEnd);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('keydown', onEnd);

    return () => {
      map.enableMovement();

      mapboxMap.off('click', onClick);
      mapboxMap.off('dblclick', onEnd);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('keydown', onEnd);
    };
  }, [
    isMeasuring,
    isDrawing,
    drawingMode,
    strokeColor,
    strokeWidth,
    fillColor,
    opacity,
    itemId,
    // the following never change:
    liveCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}

import type { User } from '@pn/core/domain/user';
import type { IErrorLogger } from '@pn/core/services/loggers/ports';
import type { INotificationService } from '@pn/core/services/notifications/ports';
import { hasKeyWithType } from '@pn/core/utils/logic';
import { isObject, isString } from 'lodash-es';
import { CustomError as TSCustomError } from 'ts-custom-error';

type HandleParams = {
  error: Error;
  errorLogger: IErrorLogger;
  notify: INotificationService['notify'];
  userFriendlyMessage?: string;
  userId?: User['id'];
  onError: () => void;
};

export abstract class CustomError extends TSCustomError {
  abstract handle(params: HandleParams): void;
}

/**
 * Used for any system errors that render the application unusable.
 * E.g. bad mapping file or missing environment variables.
 */
export class ConfigurationError extends CustomError {
  public constructor(message: string) {
    super(message);
    Object.defineProperty(this, 'name', { value: 'ConfigurationError' });
  }

  handle({ notify, error, errorLogger, userId, onError }: HandleParams) {
    console.log('>>>', this);

    notify('System error occurred, our team has been notified', 'error');
    // TODO figure out a way to actually crash the app and trigger the error page instead
    onError();
    errorLogger.logConfigurationError(this, userId);

    throw error; // rethrow to crash the app
  }
}

/**
 * Used to handle errors when making requests to the server.
 */
export class ApiError extends CustomError {
  public constructor(
    message: string,
    public code: number,
    public url: string,
    public responseData?: unknown
  ) {
    super(message);
    Object.defineProperty(this, 'name', { value: 'ApiError' });
  }

  handle({
    notify,
    errorLogger,
    userFriendlyMessage,
    userId,
    onError,
  }: HandleParams) {
    console.log('>>>', this);

    if (userFriendlyMessage) notify(userFriendlyMessage, 'error');

    onError();

    if (this.code !== 0) errorLogger.logApiError(this, userId);
  }
}

/**
 * Used for expected/recoverable errors that should only generate a warning.
 * This error will NOT trigger a onError callback.
 * E.g. trying to export data when there are over MAX_LIMIT results.
 */
export class ApplicationError extends CustomError {
  public constructor(message: string) {
    super(message);
    Object.defineProperty(this, 'name', { value: 'ApplicationError' });
  }

  handle({ notify }: HandleParams) {
    notify(this.message, 'warning');
  }
}

/**
 * An abstract wrapper around the standard Error class that adds a handle method.
 * We call handle method on it instead of extending default Error class.
 */
export abstract class GenericError extends CustomError {
  static handle({
    error,
    errorLogger,
    notify,
    userFriendlyMessage,
    userId,
    onError,
  }: HandleParams) {
    console.log('>>> Generic error caught:', error);

    if (userFriendlyMessage) notify(userFriendlyMessage, 'error');
    onError();
    errorLogger.logGenericError(error, userId);
  }
}

export function isGenericError(error: unknown): error is Error {
  return error instanceof Error;
}

export function isCustomError(error: unknown): error is CustomError {
  return error instanceof CustomError;
}

export function getApiErrorMessage(error: ApiError): string {
  const responseData = error.responseData;

  if (
    isObject(responseData) &&
    hasKeyWithType(responseData, 'error', isString)
  ) {
    return responseData.error;
  } else {
    return 'API error occurred';
  }
}

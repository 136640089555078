/**
 * Required for DataGrid v7 to work on older browsers.
 */
if (!Array.prototype.at) {
  Array.prototype.at = function <T>(index: number): T | undefined {
    index = Math.trunc(index) || 0;

    if (index < 0) {
      index += this.length;
    }

    if (index < 0 || index >= this.length) {
      return undefined;
    }

    return this[index];
  };
}

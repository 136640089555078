import checkerboard from '@pn/services/map/mapbox/assets/map-patterns/checkerboard30.png';
import crosshatch from '@pn/services/map/mapbox/assets/map-patterns/crosshatch6.png';
import stripes from '@pn/services/map/mapbox/assets/map-patterns/stripes4.png';
import marker from '@pn/services/map/mapbox/assets/marker.png';

const customMapIcons = [
  {
    id: 'custom-pin',
    label: 'Pin',
    url: 'https://petro-ninja-web.s3.amazonaws.com/custom-pin.png',
  },
  {
    id: 'custom-star',
    label: 'Star',
    url: 'https://petro-ninja-web.s3.amazonaws.com/custom-star.png',
  },
  {
    id: 'custom-house',
    label: 'House',
    url: 'https://petro-ninja-web.s3.amazonaws.com/custom-house.png',
  },
  {
    id: 'custom-heart',
    label: 'Heart',
    url: 'https://petro-ninja-web.s3.amazonaws.com/custom-heart.png',
  },
  {
    id: 'custom-triangle',
    label: 'Triangle',
    url: 'https://petro-ninja-web.s3.amazonaws.com/custom-triangle.png',
  },
  {
    id: 'custom-square',
    label: 'Square',
    url: 'https://petro-ninja-web.s3.amazonaws.com/custom-square.png',
  },
];

export function loadImages(map: mapboxgl.Map) {
  map.loadImage(marker, (error: any, image: any) => {
    if (error) {
      console.error('Error loading marker image', error);
    } else if (!map.hasImage('custom-marker')) {
      map.addImage('custom-marker', image, { sdf: true });
    }
  });

  map.loadImage(checkerboard, (error: any, image: any) => {
    if (error) {
      console.error('Error loading checkerboard pattern', error);
    } else if (!map.hasImage('checkerboard')) {
      map.addImage('checkerboard', image);
    }
  });

  map.loadImage(stripes, (error: any, image: any) => {
    if (error) {
      console.error('Error loading stripes pattern', error);
    } else if (!map.hasImage('stripes')) {
      map.addImage('stripes', image);
    }
  });

  map.loadImage(crosshatch, (error: any, image: any) => {
    if (error) {
      console.error('Error loading crosshatch pattern', error);
    } else if (!map.hasImage('crosshatch')) {
      map.addImage('crosshatch', image);
    }
  });

  customMapIcons.forEach((icon) => {
    map.loadImage(icon.url, (error: any, image: any) => {
      if (error) throw error;
      if (map.hasImage(icon.id)) map.removeImage(icon.id);

      map.addImage(icon.id, image, { sdf: true });
    });
  });
}

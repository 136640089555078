import { GridColumnMenu, type GridColumnMenuProps } from '@mui/x-data-grid-pro';

export function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
}

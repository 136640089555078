import { Alert, Box, Typography } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid-pro';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  gridOverlay: {
    background: theme.palette.background.paper,
  },
  icon: {
    fontSize: 48,
  },
  link: {
    cursor: 'pointer',
  },
}));

type Props = {
  areFiltersApplied: boolean;
  overlayText: string;
  icon: React.ComponentType<any>;
};

export function CustomNoRowsOverlay({
  areFiltersApplied,
  overlayText,
  icon: Icon,
}: Props) {
  const { classes } = useStyles();

  return (
    <GridOverlay className={classes.gridOverlay}>
      <Box textAlign="center">
        {areFiltersApplied ? (
          <Alert severity="warning">
            No results found from the filters applied
          </Alert>
        ) : (
          <Box>
            <Icon color="disabled" className={classes.icon} />
            <Typography color="textSecondary">{overlayText}</Typography>
          </Box>
        )}
      </Box>
    </GridOverlay>
  );
}

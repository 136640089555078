import { handleError } from '@pn/core/errors/handleError';
import { useAccess } from '@pn/core/permissions/access';
import { projectsActions } from '@pn/core/storage';
import { apiProjectsProvider } from '@pn/services/api/project/apiProjectsProvider';
import React from 'react';

export async function getCurrentUserProjects() {
  try {
    projectsActions().request();

    const projects = await apiProjectsProvider.getCurrentUserProjects();

    projectsActions().add(projects);
  } catch (error) {
    handleError({
      error,
      onError: projectsActions().error,
      userFriendlyMessage: 'Failed to get your projects',
    });
  }
}

export function useAutoGetCurrentUserProjects() {
  const access = useAccess();

  const shouldFetch = access('projects').granted();

  React.useEffect(() => {
    if (shouldFetch) {
      getCurrentUserProjects();
    }
  }, [shouldFetch]);
}

import {
  getPointsLayerId,
  getSelectedLayerId,
} from '@pn/core/domain/workspace';
import type { MapboxIMap } from '..';
import { isInteractableLayer } from '@pn/core/domain/layer';

export const moveDataLayer: MapboxIMap['moveDataLayer'] = function (
  this: MapboxIMap,
  layer,
  beforeLayerIds
) {
  if (!this.hasLayer(layer.id)) {
    throw new Error(`Layer ${layer.id} does not exist`);
  }

  const existingBeforeLayerId = beforeLayerIds.find((id) => this.hasLayer(id));

  this._native.moveLayer(layer.id, existingBeforeLayerId);
  if (isInteractableLayer(layer)) {
    this._native.moveLayer(getSelectedLayerId(layer.id), existingBeforeLayerId);
  }

  if (layer.renderAsPoints) {
    this._native.moveLayer(getPointsLayerId(layer.id), existingBeforeLayerId);
    if (isInteractableLayer(layer)) {
      this._native.moveLayer(
        getSelectedLayerId(getPointsLayerId(layer.id)),
        existingBeforeLayerId
      );
    }
  }
};

import {
  currentUserActions,
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';

export function useStackCompanies() {
  const { user, stackCompanyId } = useCurrentUserStorage();

  const changeCompany = (newCompanyId: string): void => {
    currentUserActions().updateStackCompanyId(newCompanyId);
    workspaceActions().updateIsFetchingStack(true);
  };

  return {
    companies: user?.enterprise?.stackCompanies ?? [],
    companyId: stackCompanyId ?? user?.stackCompanyId,
    changeCompany,
  };
}

import { dependencies } from '@pn/core/dependencies';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import { isProduction } from '@pn/core/utils/env';
import type { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { isSynced, sync } from './api-sync';

export const syncStorageMiddleware: Middleware =
  (store) => (next) => (_action) => {
    const {
      notificationService: { notify },
      errorLogger,
    } = dependencies;

    const action = _action as PayloadAction; // FIXME

    const [reducer, actionMethod] = action.type.split('/');

    if (isSynced(reducer, actionMethod)) {
      log.debug('started', reducer, actionMethod);
      const prevState = store.getState()[reducer];

      sync[reducer][actionMethod](action.payload, store.dispatch)
        .then(() => {
          log.debug('finished', reducer, actionMethod);
        })
        .catch((error: Error) => {
          store.dispatch({ type: `${reducer}/_replace`, payload: prevState });

          log.error('failed', error);
          notify(`Sync failed: ${action.type}`, 'error'); // TODO custom error messages

          errorLogger.logGenericError(
            new Error(`Sync failed: ${action.type}`),
            getCurrentUserId(),
            'ApiStorageSync'
          );
        });
    }

    const result = next(action);

    // if (isSynced(reducer, actionType)) {
    //   const nextState = store.getState()[reducer];
    //   console.log('nextState', nextState);
    // }

    return result;
  };

const DEBUG = false;

function isInDebugMode() {
  return DEBUG && !isProduction();
}

const log = {
  debug: (message: string, ...args: any[]) => {
    if (isInDebugMode()) {
      console.log('%c[api-sync]', 'color: #009688', message, ...args);
    }
  },
  error: (message: string, ...args: any[]) =>
    console.log('%c[api-sync]', 'color: #F44336', message, ...args),
};

export type Payload<
  Actions extends Record<string, (...args: any[]) => any>,
  Type extends keyof Actions,
> = Parameters<Actions[Type]>[0];

import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const landPostingsElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'ID',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 175,
  },
  {
    field: 'agreement_id',
    sourceType: 'keyword',
    label: 'Title/Agreement No.',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 150,
  },
  {
    field: 'agreement_type_id',
    sourceType: 'keyword',
    label: 'Agreement Type ID',
    folder: 'Land Posting Header',
  },
  {
    field: 'contract_no',
    sourceType: 'keyword',
    label: 'Parcel',
    folder: 'Land Posting Header',
  },
  {
    field: 'tracts',
    sourceType: 'text', // QUESTION why?
    label: 'Tract',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    isNotSortable: true,
    width: 75,
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'Province',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'land_ids',
    sourceType: 'text',
    label: 'Land IDs',
    folder: 'Land Posting Header',
    isNotSortable: true,
    isShownByDefault: true,
    width: 175,
  },
  {
    field: 'zones',
    sourceType: 'text',
    label: 'Zones',
    folder: 'Land Posting Header',
    width: 270,
    isNotSortable: true,
    isShownByDefault: true,
  },
  {
    field: 'agreement_type',
    sourceType: 'text',
    label: 'Agreement Type',
    folder: 'Land Posting Header',
    width: 200,
  },
  {
    field: 'petro_ninja_agreement_type',
    sourceType: 'text',
    domainTypeAttributes: {
      options: ['PNG', 'OS', 'Coal', 'Metal', 'Geothermal', 'CO2', 'Helium'],
    },
    label: 'Petro Ninja Agreement Type',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 125,
  },
  {
    field: 'status',
    sourceType: 'text',
    label: 'Status',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'sale_date',
    sourceType: 'date',
    label: 'Sale Date',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 125,
  },
  {
    field: 'expiry_date',
    sourceType: 'date',
    label: 'Expiry Date',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 125,
  },
  {
    field: 'area',
    sourceType: 'double',
    label: 'Area',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'ha', // should not be converted
    },
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'term_length',
    sourceType: 'integer',
    label: 'Term Length',
    folder: 'Land Posting Header',
    isShownByDefault: true,
    width: 100,
  },
  /* Not renderable */
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

import { isCordova } from '@pn/core/utils/env';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isNil } from 'lodash-es';
import React from 'react';

const freshdeskScriptId = 'freshdesk-init-script';

declare global {
  interface Window {
    FreshworksWidget: any;
    fwSettings: any; // fresh-desk settings
  }
}

const widgets = {
  'petro-ninja': 73000001261,
  'boe-intel': 73000004769,
};

export function useAutoInitializeFreshDesk(widgetId: keyof typeof widgets) {
  const { smScreen } = useScreenSize();

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const scriptAlreadyLoaded = !isNil(
      document.getElementById(freshdeskScriptId)
    );
    if (smScreen || isCordova() || scriptAlreadyLoaded) return;

    const id = widgets[widgetId];

    const script = document.createElement('script');
    script.id = freshdeskScriptId;
    script.src = `https://widget.freshworks.com/widgets/${id}.js`;
    script.defer = true;
    script.addEventListener('load', () => setLoaded(true));
    document.head.appendChild(script);

    window.fwSettings = {
      widget_id: id,
    };
  }, [smScreen, widgetId]);

  React.useEffect(() => {
    if (!loaded) return;
    if (typeof window.FreshworksWidget !== 'function') {
      const n: any = function (...args: any[]) {
        n.q.push(args);
      };
      n.q = [];
      window.FreshworksWidget = n;
      window.FreshworksWidget('hide');
    }
  }, [loaded]);
}

import type { Table } from '@pn/core/domain/data-info';
import { isSubstance, type ZonalQuery } from '@pn/core/domain/zone';
import type { ApiTable } from '@pn/services/api/data-info/types';
import { isNil } from 'lodash-es';

export const apiTableModuleMapper = {
  toDomainModule: (apiModule: ApiTable['module']): Table['module'] => {
    if (isNil(apiModule)) return undefined;

    try {
      switch (apiModule.type) {
        case 'formations_viewer': {
          return {
            type: 'formations_viewer',
            province: apiModule.province ?? 'ab',
            zonalQueries: apiModule.zones.map((apiZone) => ({
              substance: toSubstance(apiZone.substance),
              upper: {
                entity:
                  apiZone.upper.entity === 'SURFACE'
                    ? ''
                    : apiZone.upper.entity,
                boundary: apiZone.upper.boundary ?? 'top',
              },
              lower: {
                entity:
                  apiZone.lower.entity === 'BASEMENT'
                    ? ''
                    : apiZone.lower.entity,
                boundary: apiZone.lower.boundary ?? 'base',
              },
              excluded: apiZone.excluded ?? false,
            })),
            regions: [],
          };
        }
        default:
          throw new Error(`Unknown module type: ${apiModule.type}`);
      }
    } catch (error) {
      console.log('Failed to map table module', apiModule, error);
      return undefined;
    }
  },
};

function toSubstance(arg: string): ZonalQuery['substance'] {
  if (isSubstance(arg)) return arg;
  if (arg === 'PETROLEUM') return 'P';
  return 'OTHER';
}

import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

export const ExclusiveToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  return (
    <ToggleButtonGroup
      {...props}
      exclusive
      onChange={(_, value) => {
        if (value === null) {
          // do nothing
        } else {
          props.onChange!(_, value);
        }
      }}
    />
  );
};

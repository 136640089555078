import type { Reducer } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';
import React from 'react';
import type { InjectableReduxStore } from './configureInjectableStore';

export function useInjectableReducer(
  store: InjectableReduxStore,
  sliceName: string,
  reducer: Reducer
) {
  React.useLayoutEffect(() => {
    if (isNil(store.getState()[sliceName])) {
      store.injectReducer(sliceName, reducer);
    }
  }, [store, sliceName, reducer]);
}

// export function injectReducer(
//   store: InjectableReduxStore,
//   sliceName: string,
//   reducer: Reducer
// ) {
//   if (isNil(store.getState()[sliceName])) {
//     store.injectReducer(sliceName, reducer);
//   }
// }

// export function removeInjectedReducer(
//   store: InjectableReduxStore,
//   sliceName: string
// ) {
//   if (!isNil(store.getState()[sliceName])) {
//     store.removeReducer(sliceName);
//   }
// }

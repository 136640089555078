import { toMappingItem, type MappingItem } from '@pn/core/domain/data';
import { nullToUndefined } from '@pn/core/utils/logic';
import type { ParquetMappingItem, ParquetSourceType } from './types';

/**
 * @link https://github.com/ankane/polars-ruby?tab=readme-ov-file#types
 */
function parquetSourceTypeToDomainType(
  sourceType: ParquetSourceType
): MappingItem['domainType'] {
  switch (sourceType) {
    case 'Boolean':
      return 'boolean';
    case 'Float32':
    case 'Float64':
    case 'Int8':
    case 'Int16':
    case 'Int32':
    case 'Int64':
      return 'number';
    case 'String':
      return 'string';
    case 'Date':
      return 'DateString';
    case 'WKTString': // special type
      return 'WKT';
    default:
      throw new Error(`Invalid parquet source type: ${sourceType}`);
  }
}

export const parquetToDomainMapping = (
  parquetMapping: ParquetMappingItem[]
): MappingItem[] => {
  return parquetMapping
    .filter((p) => p.data_type !== 'Null')
    .map((parquetMappingItem) => {
      const domainType = parquetSourceTypeToDomainType(
        parquetMappingItem.data_type
      );

      return toMappingItem({
        field: parquetMappingItem.field,
        label: parquetMappingItem.field,
        domainType,
        domainTypeAttributes:
          domainType === 'DateString' ? { format: 'yyyy-MM-dd' } : undefined,
        isShownByDefault: true,
        isNotRenderable: nullToUndefined(parquetMappingItem.is_not_renderable),
        source: {
          type: parquetMappingItem.data_type,
        },
      });
    });
};

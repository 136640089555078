const polygonStyles = [
  // {
  //   id: 'gl-draw-polygon-fill-static',
  //   type: 'fill',
  //   filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
  //   paint: {
  //     'fill-color': ['get', 'user_fillColor'],
  //     'fill-outline-color': ['get', 'user_fillColor'],
  //     'fill-opacity': ['get', 'user_fillOpacity'],
  //   },
  // },
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': ['get', 'user_fillColor'],
      'fill-outline-color': ['get', 'user_fillColor'],
      'fill-opacity': ['get', 'user_fillOpacity'],
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': ['get', 'user_fillColor'],
      'fill-outline-color': ['get', 'user_fillColor'],
      'fill-opacity': ['get', 'user_fillOpacity'],
    },
  },
  // {
  //   id: 'gl-draw-polygon-stroke-static',
  //   type: 'line',
  //   filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
  //   layout: {
  //     'line-cap': 'round',
  //     'line-join': 'round',
  //   },
  //   paint: {
  //     'line-color': '#00F',
  //     'line-width': 2,
  //     // 'line-color': ['get', 'user_lineColor'],
  //     // 'line-width': ['get', 'user_lineWidth'],
  //   },
  // },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_lineColor'],
      'line-width': ['get', 'user_lineWidth'],
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_lineColor'],
      'line-width': ['get', 'user_lineWidth'],
      'line-dasharray': [0.2, 2],
    },
  },

  /* Vertex points */

  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#000',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', 'active', 'true'],
      ['==', '$type', 'Point'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#F00',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#FFF',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', 'active', 'true'],
      ['==', '$type', 'Point'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#FFF',
    },
  },

  /* Midpoints */

  // {
  //   id: 'gl-draw-polygon-midpoint',
  //   type: 'circle',
  //   filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
  //   paint: {
  //     'circle-radius': 3,
  //     'circle-color': '#F00',
  //   },
  // },
  // {
  //   id: 'gl-draw-point-stroke-active',
  //   type: 'circle',
  //   filter: [
  //     'all',
  //     ['==', '$type', 'Point'],
  //     ['==', 'active', 'true'],
  //     ['!=', 'meta', 'midpoint'],
  //   ],
  //   paint: {
  //     'circle-radius': 7,
  //     'circle-color': '#fff',
  //   },
  // },
];

const lineStyles = [
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_lineColor'],
      'line-width': ['get', 'user_lineWidth'],
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'user_lineColor'],
      'line-width': ['get', 'user_lineWidth'],
      'line-dasharray': [0.2, 2],
    },
  },
];

const textStyles = [
  {
    id: 'gl-draw-point-static',
    type: 'symbol',
    filter: [
      'all',
      ['has', 'user_isTextFeature'],
      ['==', 'user_isNew', false],
      ['==', 'active', 'false'],
    ],
    layout: {
      'text-allow-overlap': true,
      'text-field': ['get', 'user_textField'],
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-size': ['get', 'user_textSize'],
    },
    paint: {
      'text-color': ['get', 'user_textColor'],
    }
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'symbol',
    filter: [
      'all',
      ['has', 'user_isTextFeature'],
      ['==', 'user_isNew', false],
      ['!=', 'mode', 'draw_annotation_text'],
      ['==', 'active', 'true'],
    ],
    layout: {
      'text-allow-overlap': true,
      'text-field': ['get', 'user_textField'],
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-size': ['get', 'user_textSize'],
    },
    paint: {
      'text-color': ['get', 'user_textColor'],
      'text-halo-color': '#FFF',
      'text-halo-width': 3,
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'symbol',
    filter: [
      'all',
      ['has', 'user_isTextFeature'],
      ['==', 'user_isNew', false],
      ['==', 'mode', 'draw_annotation_text'],
      ['==', 'active', 'true'],
    ],
    layout: {
      'text-allow-overlap': true,
      'text-field': ['get', 'user_textField'],
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-size': ['get', 'user_textSize'],
    },
    paint: {
      'text-color': ['get', 'user_textColor'],
    }
  },
  {
    id: 'gl-draw-point-new',
    type: 'symbol',
    filter: ['all', ['has', 'user_isTextFeature'], ['==', 'user_isNew', true]],
    layout: {
      'text-allow-overlap': true,
      'text-field': 'Start typing...',
      'text-font': ['Open Sans Italic', 'Arial Unicode MS Regular'],
      'text-size': ['get', 'user_textSize'],
    },
    paint: {
      'text-color': ['get', 'user_textColor'],
      'text-opacity': 0.75,
    },
  },
];

export const drawStyles = [
  ...polygonStyles,
  ...lineStyles,
  ...textStyles,
  {
    id: 'gl-draw-current-position-label',
    type: 'symbol',
    filter: ['==', 'meta', 'currentPosition'],
    layout: {
      'text-field': '{metric}\n{imperial}',
      'text-anchor': 'left',
      'text-offset': [1, 0],
      'text-size': 16,
      'text-font': ['Open Sans Regular'],
    },
    paint: {
      'text-color': 'rgba(0, 0, 0, 1)',
      'text-halo-color': 'rgba(255, 255, 255, 1)',
      'text-halo-width': 2,
      'text-halo-blur': 0,
    },
  },
];

import {
  Box,
  Paper,
  Slider,
  Typography,
  type SliderProps,
} from '@mui/material';
import { DebouncedSlider } from '@pn/ui/custom-components/DebouncedSlider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    position: 'relative',
    borderColor: theme.borderColor,
  },
  label: {
    position: 'absolute',
    left: 10,
    top: -10,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4,
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  slider: {
    '& .MuiSlider-markLabel': {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
  },
}));

type Props = SliderProps & {
  title: string;
  disabled: boolean;
} & (
    | {
        debounced: true;
        value: SliderProps['value'];
        onChange: SliderProps['onChange'];
      }
    | { debounced: false }
  );

export const SliderForm = ({
  debounced = false,
  title,
  disabled,
  ...rest
}: Props) => {
  const { classes, cx } = useStyles();

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Typography
        className={cx(
          classes.label,
          disabled ? classes.disabledText : undefined
        )}
      >
        {title}
      </Typography>
      <Box pt={2} px={2}>
        {debounced ? (
          <DebouncedSlider
            {...rest}
            disabled={disabled}
            className={cx(classes.slider, rest.className)}
          />
        ) : (
          <Slider
            {...rest}
            disabled={disabled}
            className={cx(classes.slider, rest.className)}
          />
        )}
      </Box>
    </Paper>
  );
};

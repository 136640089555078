export const cachePolicy = {
  maxCacheAge: 24 * 60 * 60 * 1000, // 24 hours

  tableData: true,
  mapData: true,

  mappings: true,

  stackProjectDetails: true,
};

import { dependencies } from '@pn/core/dependencies';
import {
  GeoShapeType,
  geoShapeToGeometry,
  getBoundingBox,
  getCenterPoint,
  type GeoShape,
} from '@pn/core/domain/geography';
import { DataSelectionReason } from '@pn/core/domain/query';
import type { SearchResult } from '@pn/core/domain/search';
import { selectDataItem } from '@pn/core/operations/dataSelection';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { findOrThrow } from '@pn/core/utils/logic';
import assert from 'assert';
import { isNil } from 'lodash-es';

export async function handleSearchResult(
  searchResult: SearchResult
): Promise<void> {
  const {
    notificationService: { notify },
    map,
  } = dependencies;

  assert(map._native, 'PN Map is not initialized');

  clearGridSearchResultLayer();

  if (searchResult.resultType === 'location') {
    if (isNil(searchResult.geoShape)) return notify('No location found');

    addGridSearchResultLayer(searchResult.geoShape);

    const bbox = !isNil(searchResult.bbox)
      ? searchResult.bbox
      : getBoundingBox([searchResult.geoShape]);

    map.fitToBoundingBox(bbox, {
      padding: '20%',
      maxZoom: 13,
    });
  } else {
    const workspaceItem = findOrThrow(
      pnWorkspaceItems,
      ({ id }) => id === searchResult.dataType
    );

    selectDataItem({
      id: searchResult._id,
      reason: DataSelectionReason.Search,
      workspaceItem,
      pageSize: NaN, // never used
    });

    if (isNil(searchResult.geoShape)) return notify('No location found');

    if (searchResult.geoShape.type === GeoShapeType.Point) {
      map.setZoom(11);
      map.moveTo(getCenterPoint(searchResult.geoShape));
    } else {
      map.fitToBoundingBox(getBoundingBox([searchResult.geoShape]), {
        padding: '20%',
        maxZoom: 15,
      });
    }
  }
}

// TODO move map-related functions into the map service
export function addGridSearchResultLayer(geoShape: GeoShape) {
  const mapboxMap = dependencies.map._native;

  mapboxMap.addSource('grid-search-result', {
    type: 'geojson',
    data: geoShapeToGeometry(geoShape),
  });

  if (geoShape.type === GeoShapeType.Point) {
    mapboxMap.addLayer({
      id: 'grid-search-result-layer',
      type: 'symbol',
      source: 'grid-search-result',
      layout: {
        'icon-ignore-placement': true,
        'icon-image': 'custom-marker',
        'icon-size': 1.33,
      },
      paint: {
        'icon-color': '#E91E63',
      },
    });
  } else {
    mapboxMap.addLayer({
      id: 'grid-search-result-layer',
      type: 'line',
      source: 'grid-search-result',
      paint: {
        'line-color': '#E91E63',
        'line-width': 4,
      },
    });
  }
}

export function clearGridSearchResultLayer() {
  const mapboxMap = dependencies.map._native;

  if (mapboxMap.getLayer('grid-search-result-layer')) {
    mapboxMap.removeLayer('grid-search-result-layer');
  }
  if (mapboxMap.getSource('grid-search-result')) {
    mapboxMap.removeSource('grid-search-result');
  }
}

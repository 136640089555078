import { Parser } from '@json2csv/plainjs';
import download from 'downloadjs';

export const downloadAsCsv = <T>(
  json: Readonly<T> | readonly T[],
  params: {
    fileName: string; // should include extension
    fields?: string[];
  }
) => {
  const { fileName, fields } = params;

  const parser = new Parser({ fields });
  const csv = parser.parse(json);

  download(csv, fileName);
};

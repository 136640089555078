import { useRouter } from '@pn/core/operations/router';
import { handleSearch } from '@pn/core/operations/search';
import { useMapStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import React from 'react';

export function useAutoSearchByUrl(setSearchText: (text: string) => void) {
  const { query: urlQuery } = useRouter();
  const { isInitialized } = useMapStorage();

  React.useEffect(() => {
    if (!isInitialized || isNil(urlQuery.searchQuery)) return;

    setSearchText(urlQuery.searchQuery);
    handleSearch(urlQuery.searchQuery);
  }, [isInitialized, urlQuery, setSearchText]);
}

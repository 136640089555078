import type { MapboxIMap } from '..';

export const setZoom: MapboxIMap['setZoom'] = function (
  this: MapboxIMap,
  zoom,
  duration = 0
) {
  if (duration <= 0) {
    this._native.setZoom(zoom);
  } else {
    this._native.zoomTo(zoom, {
      duration,
    });
  }
};

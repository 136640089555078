import { colors, createTheme } from '@mui/material';
import { sharedThemeTokens, productionColors } from './shared';

export const BOELight = createTheme(
  {
    palette: {
      mode: 'light',
      primary: colors.blueGrey,
      secondary: {
        main: colors.orange[600],
      },
      background: {
        default: '#fafafa',
      },
    },
    components: {},
    borderColor: 'rgba(0, 0, 0, 0.23)',
    productionColors,
  },
  sharedThemeTokens
);

import {
  combineReducers,
  configureStore,
  ConfigureStoreOptions,
  Dispatch,
  Reducer,
  Store,
  UnknownAction,
} from '@reduxjs/toolkit';
import { debounce, DebouncedFunc } from 'lodash-es';

type Reducers = Record<string, Reducer>;

/**
 * In future, we could add a method to remove async reducers if needed.
 */
export type InjectableReduxStore = Store & {
  asyncReducers: Reducers;
  injectReducer: (key: string, reducer: Reducer) => void;
  removeReducer: (key: string) => void;
  dispatchDebounced: DebouncedFunc<Dispatch<UnknownAction>>;
};

/**
 * Options are the same as for `configureStore` from reduxjs/toolkit except for
 * the `reducer` property which is derived from `staticReducers`.
 *
 * @returns store that implements `store.inject()` method.
 */
export function configureInjectableStore(
  staticReducers: Reducers,
  options: Omit<ConfigureStoreOptions, 'reducer'>
) {
  const defaultStore = configureStore({
    ...options,
    reducer: staticReducers,
  });

  const store = defaultStore as InjectableReduxStore;

  store.asyncReducers = {};

  store.injectReducer = (key: string, asyncReducer: Reducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(
      createRootReducer(staticReducers, store.asyncReducers)
    );
  };

  store.removeReducer = (key: string) => {
    delete store.asyncReducers[key];
    store.replaceReducer(
      createRootReducer(staticReducers, store.asyncReducers)
    );
  };

  store.dispatchDebounced = debounce(store.dispatch, 500);

  return store;
}

function createRootReducer(staticReducers: Reducers, asyncReducers: Reducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { usePrevious } from '@pn/core/utils/hooks';
import { gridLayerIds } from '@pn/services/map/mapbox/style/pnMapboxLayers';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const gridLabels: Record<string, string> = {
  ca_township: 'Canada Township',
  ca_section: 'Canada Section',
  ca_quarter_section: 'Canada Quarter Section',
  ca_lsd: 'Canada LSD',
  ca_block: 'Canada NTS Block',
  ca_unit: 'Canada NTS Unit',
  us_township: 'US Township',
  us_section: 'US Section',
};

/**
 * Updated whenever the local state changes.
 */
let defaultCheckedState: Record<string, boolean> = {
  ca_township: true,
  ca_section: true,
  ca_quarter_section: false,
  ca_lsd: true,
  ca_block: true,
  ca_unit: true,
  us_township: true,
  us_section: true,
};

const useStyles = makeStyles()((theme) => ({
  listItemButton: {
    height: 30,
  },
  listItemIcon: {
    minWidth: 32,
  },
  semiBold: {
    fontWeight: 500,
  },
  infoIcon: {
    position: 'relative',
    top: 5,
    left: 1,
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    fontSize: 20,
  },
}));

export const GridsDialogModule = () => {
  const { classes } = useStyles();

  const [checked, setChecked] =
    React.useState<Record<string, boolean>>(defaultCheckedState);

  const previousChecked = usePrevious(checked, defaultCheckedState);

  React.useEffect(() => {
    defaultCheckedState = checked;
  }, [checked]);

  React.useEffect(() => {
    Object.keys(checked).forEach((key) => {
      if (checked[key] && !previousChecked[key]) {
        gridLayerIds[key].forEach((layerId) => {
          map.showLayer(layerId);
        });
      } else if (!checked[key] && previousChecked[key]) {
        gridLayerIds[key].forEach((layerId) => {
          map.hideLayer(layerId);
        });
      }
    });
  }, [checked, previousChecked]);

  const toggle = (value: string) => {
    setChecked((prev) => ({ ...prev, [value]: !prev[value] }));
  };

  const toggleAll = () => {
    setChecked((prev) => {
      const all = Object.values(prev).every(Boolean);
      return Object.fromEntries(
        Object.entries(prev).map(([key]) => [key, !all])
      );
    });
  };

  return (
    <Box>
      <List disablePadding>
        <ListItem disablePadding dense>
          <ListItemButton
            dense
            className={classes.listItemButton}
            onClick={toggleAll}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                edge="start"
                size="small"
                tabIndex={-1}
                disableRipple
                checked={Object.values(checked).every(Boolean)}
                indeterminate={
                  Object.values(checked).some(Boolean) &&
                  !Object.values(checked).every(Boolean)
                }
                inputProps={{ 'aria-labelledby': 'toggle-all-grids' }}
              />
            </ListItemIcon>
            <ListItemText
              id="toggle-all-grids"
              primary="Toggle all"
              primaryTypographyProps={{
                className: classes.semiBold,
              }}
            />
          </ListItemButton>
        </ListItem>
        {Object.keys(gridLayerIds).map((key) => (
          <ListItem key={key} disablePadding dense>
            <ListItemButton
              dense
              className={classes.listItemButton}
              onClick={() => toggle(key)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  edge="start"
                  size="small"
                  tabIndex={-1}
                  disableRipple
                  checked={checked[key]}
                  inputProps={{ 'aria-labelledby': key }}
                />
              </ListItemIcon>
              <ListItemText id={key} primary={gridLabels[key]} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

import { grey } from '@mui/material/colors';
import { LayerType } from '@pn/core/domain/layer';
import Color from 'color';
import { clamp, isArray, isNil, isNumber, isString, round } from 'lodash-es';

export function addAlpha(color: string, alpha: number): string {
  return Color(color).alpha(alpha).string();
}

export function isValidColorString(arg: string): boolean {
  return arg.startsWith('#') || arg.startsWith('rgb') || arg.startsWith('hsl');
}

export function isTransparent(color: string): boolean {
  return Color(color).alpha() === 0;
}

/**
 * Clamps the lightness of RGB/RGBa, HSL/HSLa, or HEX/HEXa color.
 */
export function getNormalizedColor(
  color: string,
  options?: {
    maxLightness?: number;
    minLightness?: number;
    maxSaturation?: number;
    minSaturation?: number;
  }
): string {
  const {
    maxLightness = 50,
    minLightness = 0,
    maxSaturation = 100,
    minSaturation = 0,
  } = options ?? {};

  return Color(color)
    .lightness(clamp(Color(color).lightness(), minLightness, maxLightness))
    .saturationl(
      clamp(Color(color).saturationl(), minSaturation, maxSaturation)
    )
    .hex();
}

export function getStepColor(params: {
  index: number;
  total: number;
  min: string;
  max: string;
}): string {
  const { index, total, min, max } = params;

  if (index < 0 || index > total || total <= 0) {
    throw new Error('Invalid index or total');
  }

  if (total === 1) {
    return Color(min).hex(); // to prevent NaN in ratio calculation
  }

  const minColor = Color(min);
  const maxColor = Color(max);

  // Calculate the ratio of index to total
  // Note: we subtract 1 from total because index is zero-based
  const ratio = index / (total - 1);

  // Mix colors based on the calculated ratio
  // The mix method mixes the two colors together, with the ratio determining the weight between them
  // ratio = 0 will result in minColor, ratio = 1 will result in maxColor
  const resultColor = minColor.mix(maxColor, ratio);

  return resultColor.hex();
}

/**
 * Generates a selection color from the base color.
 */
export function generateSelectionColor(
  baseColor: string | undefined,
  layerType: LayerType
): string {
  const color = Color(baseColor).hsl();

  const lightness = layerType === LayerType.Polygon ? 65 : 50;
  const saturation = layerType === LayerType.Polygon ? 75 : 100;

  if (isNil(baseColor) || color.lightness() <= 20 || color.lightness() >= 80) {
    return Color.hsl(340, 100, lightness).hex(); // default pink selection color
  }

  let output = color
    .hue(getDistinctHue(color.hue()))
    .lightness(lightness)
    .saturationl(saturation);

  if (layerType !== LayerType.Polygon && output.contrast(Color('white')) < 6) {
    output = output.lightness(
      50 - Math.abs(output.contrast(Color('white')) - 6) * 4
    );
  }

  return output.hex();
}

/**
 * Takes an existing color expression and generates a selection version of it.
 */
export function generateSelectionExpression(
  expression: unknown[],
  layerType: LayerType
): unknown[] {
  if (expression[0] === 'step') {
    return expression.map((value, index) => {
      if (index < 2 || index % 2 === 1) {
        return value;
      } else {
        return generateSelectionColor(value as string, layerType);
      }
    });
  } else if (expression[0] === 'match') {
    return expression.map((value, index) => {
      const isWithinFirstTwoOrEven = index < 3 || index % 2 === 0;
      const isLast = index === expression.length - 1;

      if (isWithinFirstTwoOrEven && !isLast) {
        return value;
      } else {
        return generateSelectionColor(value as string, layerType);
      }
    });
  } else {
    throw new Error(`Unsupported expression type: ${expression[0]}`);
  }
}

export function multiplyExpression(
  expression: unknown,
  factor: number,
  min = -Infinity
): unknown {
  if (isNumber(expression)) {
    return Math.max(expression * factor, min);
  }

  if (isArray(expression)) {
    return expression.map((value, index) => {
      if (index < 2 || index % 2 === 1) {
        return value;
      } else {
        return round((value as number) * factor, 2);
      }
    });
  }

  throw new Error('Invalid expression to multiply');
}

/**
 * Generates a distinct hue from the base hue.
 * We start with a 180 degrees rotation and then exclude all undesirable hues.
 */
function getDistinctHue(baseHue: number): number {
  let outputHue = (baseHue + 180) % 360;

  const avoidRange = [0, 150];
  const avoidSpan = avoidRange[1] - avoidRange[0];

  if (
    outputHue >= avoidRange[0] &&
    outputHue <= avoidRange[1] - avoidSpan / 2
  ) {
    outputHue = avoidRange[0];
  } else if (
    outputHue >= avoidRange[1] - avoidSpan / 2 &&
    outputHue <= avoidRange[1]
  ) {
    outputHue = avoidRange[1];
  }

  return outputHue;
}

export function blendColors(
  foreground: string,
  alpha: number,
  background: string
): string {
  const fg = Color(foreground);
  const bg = Color(background);

  const r = fg.red() * alpha + bg.red() * (1 - alpha);
  const g = fg.green() * alpha + bg.green() * (1 - alpha);
  const b = fg.blue() * alpha + bg.blue() * (1 - alpha);

  return Color({ r, g, b }).hex();
}

export function desaturateColor(color: string, amount: number): string {
  return Color(color).desaturate(amount).hex();
}

const getRainbowGradient = (type: string, angle: number) => `
${type}-gradient(
  ${angle}deg,
  rgba(255, 0, 0, 1) 0%,
  rgba(255, 154, 0, 1) 10%,
  rgba(208, 222, 33, 1) 20%,
  rgba(79, 220, 74, 1) 30%,
  rgba(63, 218, 216, 1) 40%,
  rgba(47, 201, 226, 1) 50%,
  rgba(28, 127, 238, 1) 60%,
  rgba(95, 21, 242, 1) 70%,
  rgba(186, 12, 248, 1) 80%,
  rgba(251, 7, 217, 1) 90%,
  rgba(255, 0, 0, 1) 100%
)
`;

const STRIPES_GRADIENT = `
repeating-linear-gradient(
  45deg,
  rgba(255, 255, 255, 0.5),
  rgba(255, 255, 255, 0.5) 2px,
  rgba(0, 0, 0, 0.25) 2px,
  rgba(0, 0, 0, 0.25) 4px
)
`;

export function generateIconBackground(
  color: unknown,
  isTemporary?: boolean
): string {
  if (isString(color) && Color(color).alpha() === 0) {
    return STRIPES_GRADIENT;
  } else if (isString(color)) {
    return Color(color)
      .alpha(isTemporary ? 0.4 : 0.8)
      .hexa();
  } else if (isArray(color)) {
    return getRainbowGradient('linear', 45);
  } else {
    return grey[500]; // should never happen
  }
}

import { useRouter } from '@pn/core/operations/router';
import { useWorkspaceStorage } from '@pn/core/storage';
import { usePrevious } from '@pn/core/utils/hooks';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { getMainPanelUri } from 'src/ui/main-panel/utils';

export function useAutoSetUrlFromCurrentItem() {
  const { pathname, search, pushUrl } = useRouter();

  const { workspaceItemSelected, dataItemRequested } = useWorkspaceStorage();

  const previousRequestedId = usePrevious(dataItemRequested.id);
  const urlId = encodeURIComponent(dataItemRequested.id ?? '');

  const urlHasToBeSet =
    !isEmpty(urlId) &&
    !urlContainsId(pathname, urlId) &&
    previousRequestedId !== dataItemRequested.id;
  const urlHasToBeUnset =
    !isNil(previousRequestedId) && isNil(dataItemRequested.id);

  React.useLayoutEffect(() => {
    if (urlHasToBeSet && workspaceItemSelected?.dataType) {
      pushUrl({
        pathname: getMainPanelUri({
          dataType: workspaceItemSelected.dataType,
          dataItemId: urlId,
          subpanel: getSubpanelUri(pathname),
        }),
        search,
      });
    } else if (urlHasToBeUnset) {
      pushUrl({
        pathname: '/',
        search,
      });
    }
  }, [
    workspaceItemSelected?.dataType,
    urlHasToBeSet,
    urlHasToBeUnset,
    pathname,
    urlId,
    search,
    pushUrl,
  ]);
}

function urlContainsId(pathname: string, id: string): boolean {
  return pathname.split('/').includes(id);
}

function getSubpanelUri(pathname: string): string {
  return pathname.split('/')[3];
}

import { Paper, ToggleButton } from '@mui/material';
import { UnitSystem } from '@pn/core/domain/types';
import { ExclusiveToggleButtonGroup } from '@pn/ui/custom-components/ExclusiveToggleButtonGroup';
import { capitalize } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const options = Object.values(UnitSystem).map((unitSystem) => ({
  value: unitSystem,
  label: capitalize(unitSystem),
}));

const useStyles = makeStyles()((theme) => ({
  toggleButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    textTransform: 'none',
  },
}));

type Props = {
  unitSystem: UnitSystem;
  onChange: (newUnitSystem: UnitSystem) => void;
  fullWidth?: boolean;
};

export function ChartUnitsSelector({ unitSystem, onChange, fullWidth }: Props) {
  const { classes } = useStyles();

  return (
    <Paper elevation={0}>
      <ExclusiveToggleButtonGroup
        fullWidth={fullWidth}
        color="primary"
        exclusive
        value={unitSystem}
        onChange={(_event, newUnitSystem) => onChange(newUnitSystem)}
        aria-label="Units"
      >
        {options.map(({ value, label }) => (
          <ToggleButton
            key={value}
            value={value}
            className={classes.toggleButton}
          >
            {label}
          </ToggleButton>
        ))}
      </ExclusiveToggleButtonGroup>
    </Paper>
  );
}

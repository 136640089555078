import type { MapboxIMap } from '..';
import * as turf from '@turf/turf';

export function resize(this: MapboxIMap) {
  const canvas = this._native.getCanvas();
  const dpr = window.devicePixelRatio ?? 1;
  const w = canvas.width / dpr;
  const h = canvas.height / dpr;
  const cUL = this._native.unproject([0, 0]).toArray();
  const cUR = this._native.unproject([w, 0]).toArray();
  const cLR = this._native.unproject([w, h]).toArray();
  const cLL = this._native.unproject([0, h]).toArray();
  const coordinates = [cUL, cUR, cLR, cLL, cUL];
  const polygon = turf.polygon([coordinates]);
  const bbox = turf.bbox(polygon);

  this._native.fitBounds(bbox as mapboxgl.LngLatBoundsLike);
  this._native.resize();
}

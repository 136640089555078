import { currentUserSlice } from '@pn/core/storage/user/userSlice';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ReducerSync } from '.';

export const currentUser: ReducerSync<typeof currentUserSlice.actions> = {
  updateProfile: (userForm) => {
    const apiPayload: Record<string, unknown> = {
      accept_mail: userForm.acceptMail,
      email: userForm.email,
      first_name: userForm.firstName,
      last_name: userForm.lastName,
      company: userForm.company,
      title: userForm.title,
    };

    return pnApiClient.request({
      method: 'PUT',
      url: 'v2/user',
      payload: apiPayload,
    });
  },
};

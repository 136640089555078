import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const openMineralRightsElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'ID',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 175,
  },
  {
    field: 'open_rights',
    sourceType: 'text',
    label: 'Zone Description',
    folder: 'Crown Land Header',
    isShownByDefault: true,
    width: 750,
  },
  /* Not renderable */
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

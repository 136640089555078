import React from 'react';
import { initLibraryRouter, type LibraryRouter } from './libraryRouter';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue';
import type { LibraryProject } from 'src/ui/workspace/library/types';
import { getProjectCheckedState } from 'src/ui/workspace/library/utils';
import { isNil } from 'lodash-es';
import { useProjectsStorage, useWorkspaceStorage } from '@pn/core/storage';

type ContextType = {
  isLibraryOpen: boolean;
  libraryRouter: LibraryRouter;
  libraryProjectSelected: LibraryProject | undefined;
  searchText: string;
  searchValue: string;
  setSearchText: (text: string) => void;
  skipSearchValueDebounce: () => void;
  setLibraryRouter: (router: LibraryRouter) => void;
  openLibrary: () => void;
  closeLibrary: () => void;
};

export const LibraryContext = React.createContext({} as ContextType);
export const useLibrary = () => React.useContext(LibraryContext);

type ContextProviderProps = {
  children: React.ReactNode;
};

export const LibraryProvider = ({ children }: ContextProviderProps) => {
  const { projects } = useProjectsStorage();
  const { idsInWorkspace } = useWorkspaceStorage();

  const [isLibraryOpen, setIsLibraryOpen] = React.useState(false);
  const [libraryRouter, setLibraryRouter] = React.useState(initLibraryRouter);

  const [searchText, setSearchText] = React.useState('');
  const [searchValue, skipSearchValueDebounce] = useDebouncedValue(
    searchText,
    400,
    { leading: false, skipOnValue: '' }
  );

  const openLibrary = () => setIsLibraryOpen(true);
  const closeLibrary = () => setIsLibraryOpen(false);

  const libraryProjectSelected = React.useMemo(() => {
    const projectSelected = !isNil(libraryRouter.projectId)
      ? projects.find((p) => p.id === libraryRouter.projectId)
      : undefined;

    return !isNil(projectSelected)
      ? {
          id: projectSelected.id,
          name: projectSelected.name,
          workspaceItemIds: projectSelected.workspaceItemIds,
          isGlobal: projectSelected.isGlobal,
          _checked: getProjectCheckedState(
            projectSelected.workspaceItemIds,
            idsInWorkspace
          ),
        }
      : undefined;
  }, [idsInWorkspace, projects, libraryRouter.projectId]);

  return (
    <LibraryContext.Provider
      value={{
        isLibraryOpen,
        libraryRouter,
        libraryProjectSelected,
        searchText,
        searchValue,
        setSearchText,
        skipSearchValueDebounce,
        setLibraryRouter,
        openLibrary,
        closeLibrary,
      }}
    >
      {children}
    </LibraryContext.Provider>
  );
};

import {
  toDomainValue,
  type DataItem,
  type MappingItem,
} from '@pn/core/domain/data';
import {
  geoShapeToGeometry,
  geometryToGeoShape,
} from '@pn/core/domain/geography';
import { geoShapeToWkt } from '@pn/core/domain/types';
import type {
  IDataItemMapper,
  IDataItemValueMapper,
} from '@pn/core/mappers/data';
import { apiDataItemValueMapper } from '@pn/services/api/data/apiDataItemMapper';
import assert from 'assert';
import { isNil, isNumber, isString, isUndefined } from 'lodash-es';
import type { GeoJsonDataItem, GeoJsonValue } from './types';

export const geoJsonDataItemMapper = (
  mapping: MappingItem[]
): IDataItemMapper<GeoJsonDataItem> => {
  return {
    toDomainDataItem: (geoJsonDataItem) => {
      assert(
        isUndefined(geoJsonDataItem.internal_id) || // not needed for exports
          isString(geoJsonDataItem.internal_id) ||
          isNumber(geoJsonDataItem.internal_id),
        'GeoJSON mapping failed: internal_id must be a (string | number)'
      );

      return mapping
        .filter((m) => m.field !== 'geometry')
        .reduce<DataItem>(
          (dataItem, mappingItem) => {
            const field = mappingItem['field'];

            const fromValue = geoJsonDataItem[field];
            const toValue = geoJsonDataItemValueMapper.toDomainValue(
              fromValue,
              mappingItem
            );

            dataItem[field] = toValue;
            return dataItem;
          },
          {
            _id: geoJsonDataItem.internal_id,
            geoShape: !isNil(geoJsonDataItem.geometry)
              ? geometryToGeoShape(geoJsonDataItem.geometry)
              : undefined,
          }
        );
    },
    toTargetDataItem: (dataItem) => {
      return mapping
        .filter((m) => !['internal_id', 'geometry'].includes(m.field))
        .reduce<GeoJsonDataItem>(
          (targetItem, mappingItem) => {
            const field = mappingItem['field'];

            const value = dataItem[field];
            const toValue = geoJsonDataItemValueMapper.toTargetValue(
              toDomainValue(value, mappingItem)
            );

            targetItem[field] = toValue;
            return targetItem;
          },
          {
            internal_id: dataItem._id,
            geometry: !isNil(dataItem.geoShape)
              ? geoShapeToGeometry(dataItem.geoShape)
              : null,
          }
        );
    },
  };
};

const geoJsonDataItemValueMapper: IDataItemValueMapper<GeoJsonValue> = {
  toDomainValue: apiDataItemValueMapper.toDomainValue,
  toTargetValue: ({ value, domainType }) => {
    if (isNil(value)) return undefined;

    switch (domainType) {
      case 'string':
        return value;
      case 'number':
        return value;
      case 'boolean':
        return value;
      case 'object':
        return JSON.stringify(value);
      case 'DateString':
        return value;
      case 'SIUnit':
        return value.value; // no unit conversion for exports
      case 'GeoShape':
        return geoShapeToGeometry(value);
      case 'WKT':
        return geoShapeToWkt(value);
      default:
        return domainType satisfies never;
    }
  },
};

import { dependencies } from '@pn/core/dependencies';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import { areClose } from '@pn/core/domain/point';
import {
  REFERENCE_PT,
  computeMapTransformation,
  transformPoint,
  translateFeature,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useSelectTool() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { staticCanvasRef, drawingMode, drawingState, historyManager, redraw } =
    useDrawing();

  React.useEffect(() => {
    if (!isDrawingPanelOpen || drawingMode === 'select') return;

    drawingState.featuresSelected = {};
    redraw();
  }, [
    isDrawingPanelOpen,
    drawingMode,
    // the following never change:
    drawingState,
    redraw,
  ]);

  useHotkeys(
    'esc',
    () => {
      if (!isDrawingPanelOpen) return;

      drawingState.featuresSelected = {};
      redraw();
    },
    [isDrawingPanelOpen]
  );

  React.useEffect(() => {
    if (!isDrawingPanelOpen || drawingMode !== 'select') return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    let startPoint = { x: 0, y: 0 };
    let unselectPoint = { x: 0, y: 0 };
    let featuresSelected: Record<string, CanvasFeature> = {};
    let hasMoved = false;

    const onMouseDown = (e: mapboxgl.MapMouseEvent) => {
      const isMultiselection =
        e.originalEvent.shiftKey || e.originalEvent.ctrlKey;

      if (
        drawingState.isCustomPanning ||
        !isNil(drawingState.resizeDirection) ||
        !isNil(drawingState.vertexHovered) ||
        isMultiselection // multi-selection is processed in useMultiSelectTool
      ) {
        return;
      }

      if (isEmpty(drawingState.featuresHovered)) {
        unselectPoint = e.point;
        return;
      }

      drawingState.featuresSelected = drawingState.featuresHovered;
      drawingState.isMovingFeature = true;

      startPoint = e.point;
      featuresSelected = cloneDeep(drawingState.featuresSelected);
      hasMoved = false;

      redraw();

      map.disableMovement();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (drawingState.isCustomPanning || !drawingState.isMovingFeature) return;

      const transformation = computeMapTransformation(REFERENCE_PT, false);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      const bbox = map._native.getContainer().getBoundingClientRect();
      const point = transformPoint(
        { x: e.clientX - bbox.left, y: e.clientY - bbox.top },
        inverseTransformation
      );
      const start = transformPoint(startPoint, inverseTransformation);

      if (areClose(point, start)) return;

      const dx = point.x - start.x;
      const dy = point.y - start.y;

      Object.values(featuresSelected).forEach((feature) => {
        const translatedFeature = translateFeature(feature, dx, dy);

        drawingState.featuresSelected[feature.id] = translatedFeature;
        drawingState.features[feature.id] = translatedFeature;
      });

      redraw();

      hasMoved = true;
    };

    const onMouseUp = (e: MouseEvent) => {
      const bbox = map._native.getContainer().getBoundingClientRect();
      const point = { x: e.clientX - bbox.left, y: e.clientY - bbox.top };

      if (areClose(point, unselectPoint, 3)) {
        drawingState.featuresSelected = {};
        redraw();
        return;
      }

      if (!drawingState.isMovingFeature) return;

      drawingState.isMovingFeature = false;

      if (hasMoved) historyManager.add(drawingState);

      map.enableMovement();
    };

    mapboxMap.on('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      map.enableMovement();

      mapboxMap.off('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [
    isDrawingPanelOpen,
    drawingMode,
    // the following never change:
    staticCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}

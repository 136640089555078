import { gridsBlock } from '@pn/services/map/mapbox/style/pn/grids/block';
import { gridsLsd } from '@pn/services/map/mapbox/style/pn/grids/lsd';
import { gridsQuarterSection } from '@pn/services/map/mapbox/style/pn/grids/quarterSection';
import { gridsSection } from '@pn/services/map/mapbox/style/pn/grids/section';
import { gridStyles } from '@pn/services/map/mapbox/style/pn/grids/styles';
import { gridsTownship } from '@pn/services/map/mapbox/style/pn/grids/township';
import { gridsUnit } from '@pn/services/map/mapbox/style/pn/grids/unit';

export const grids = (mode: 'light' | 'dark') => [
  ...gridsTownship(gridStyles[mode].township),
  ...gridsSection(gridStyles[mode].section),
  ...gridsQuarterSection(gridStyles[mode].quarterSection),
  ...gridsLsd(gridStyles[mode].lsd),
  ...gridsUnit(gridStyles[mode].unit),
  ...gridsBlock(gridStyles[mode].block),
];

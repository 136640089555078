import { moduleItemIds } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { generateTemporaryId } from '@pn/core/utils/id';
import type { ISODateTimeString } from './types';
import { userToProjectUser, type ProjectUser, type User } from './user';
import type { WorkspaceItem } from './workspace';

export const libraryFolders = {
  projects: ['Personal', 'Shared'] as const,
  items: ['Petro Ninja', 'Personal', 'Shared', 'BOE Intel', 'StackDX'] as const,
};

export type LibrarySection = keyof typeof libraryFolders;
export type LibraryFolder = (typeof libraryFolders)[LibrarySection][number];

export type Project = {
  id: string;
  name: string;
  folder: LibraryFolder | undefined;
  createdAt: ISODateTimeString;
  updatedAt: ISODateTimeString;
  createdBy: ProjectUser | undefined;
  workspaceItemIds: WorkspaceItem['id'][];
  isFolder: boolean;
  isShared: boolean;
  isGlobal: boolean;
  origin: 'pn' | 'stackdx';
  metadata?: {
    dataOriginDescription?: string;
  };
};

export type ProjectForm = Pick<Project, 'name' | 'workspaceItemIds'>;

export function createProject(projectForm: ProjectForm, user: User): Project {
  return {
    ...projectForm,
    id: generateTemporaryId(),
    folder: 'Personal',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    createdBy: userToProjectUser(user),
    isFolder: false,
    isShared: false,
    isGlobal: false,
    origin: 'pn',
  };
}

export function getProjectItemCount(project: Project): number {
  return project.workspaceItemIds.filter((id) => !moduleItemIds.includes(id))
    .length;
}

import type {
  CanvasBBox,
  CanvasFeature,
  CircleFeature,
  PathFeature,
  TextFeature,
} from '@pn/core/domain/drawing';
import {
  pointToGeoPoint,
  type GeoBoundingBox,
  type GeoPoint,
} from '@pn/core/domain/geography';
import type { Point } from '@pn/core/domain/point';
import { getContext, pathToPoints } from '@pn/services/drawing';
import { isArray } from 'lodash-es';

export function getCanvasBoundingBox(
  arg: CanvasFeature | CanvasFeature[]
): CanvasBBox {
  const canvas = document.getElementById('canvas-live') as HTMLCanvasElement;
  const ctx = getContext(canvas);

  if (isArray(arg)) {
    const bboxes = arg.map((feature) => getFeatureBoundingBox(ctx, feature));

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const bbox of bboxes) {
      const adjustedX = bbox.x - bbox.strokeWidth / 2;
      const adjustedY = bbox.y - bbox.strokeWidth / 2;
      const adjustedWidth = bbox.width + bbox.strokeWidth;
      const adjustedHeight = bbox.height + bbox.strokeWidth;

      minX = Math.min(minX, adjustedX);
      minY = Math.min(minY, adjustedY);
      maxX = Math.max(maxX, adjustedX + adjustedWidth);
      maxY = Math.max(maxY, adjustedY + adjustedHeight);
    }

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
      strokeWidth: 0,
    };
  } else {
    return getFeatureBoundingBox(ctx, arg);
  }
}

function getFeatureBoundingBox(
  ctx: CanvasRenderingContext2D,
  feature: CanvasFeature
): CanvasBBox {
  switch (feature.type) {
    case 'path':
      return getBoundingBoxPath(feature);
    case 'poly':
      return getBoundingBoxPoints(feature.coordinates, feature.strokeWidth);
    case 'circle':
      return getBoundingBoxCircle(feature);
    case 'text':
      return getBoundingBoxText(ctx, feature);
  }
}

function getBoundingBoxPath(feature: PathFeature): CanvasBBox {
  const points = pathToPoints(feature.pathData);
  return getBoundingBoxPoints(points, feature.strokeWidth);
}

function getBoundingBoxCircle(feature: CircleFeature): CanvasBBox {
  const upperLeft = {
    x: feature.center.x - feature.radius,
    y: feature.center.y - feature.radius,
  };
  const lowerRight = {
    x: feature.center.x + feature.radius,
    y: feature.center.y + feature.radius,
  };

  return {
    x: upperLeft.x,
    y: upperLeft.y,
    width: lowerRight.x - upperLeft.x,
    height: lowerRight.y - upperLeft.y,
    strokeWidth: feature.strokeWidth,
  };
}

function getBoundingBoxText(
  ctx: CanvasRenderingContext2D,
  feature: TextFeature
): CanvasBBox {
  const x = feature.position.x;
  const initialY = feature.position.y + feature.fontSize;
  // const x = feature.position.x + 0.05;
  // const initialY = feature.position.y + feature.fontSize - 1.175;

  ctx.save();

  ctx.font = `${feature.fontSize}px ${feature.fontFamily}`;

  const lines = feature.text.split('\n');
  const lineHeight = feature.fontSize * 1.2;

  const textMetrics = lines.map((line) => ctx.measureText(line));
  const first = textMetrics[0];
  const last = textMetrics[textMetrics.length - 1];

  const textHeight =
    (lines.length - 1) * lineHeight +
    first.actualBoundingBoxAscent +
    last.actualBoundingBoxDescent;
  const textWidth = Math.max(...textMetrics.map((metrics) => metrics.width));

  ctx.restore();

  return {
    x,
    y: initialY - first.actualBoundingBoxAscent,
    width: textWidth,
    height: textHeight,
    strokeWidth: 0,
  };
}

function getBoundingBoxPoints(points: Point[], strokeWidth = 0): CanvasBBox {
  const xs = points.map((point) => point.x);
  const ys = points.map((point) => point.y);

  const upperLeft = {
    x: Math.min(...xs),
    y: Math.min(...ys),
  };
  const lowerRight = {
    x: Math.max(...xs),
    y: Math.max(...ys),
  };

  return {
    x: upperLeft.x,
    y: upperLeft.y,
    width: lowerRight.x - upperLeft.x,
    height: lowerRight.y - upperLeft.y,
    strokeWidth,
  };
}

export function isIncluded(outer: CanvasBBox, candidate: CanvasBBox): boolean {
  return (
    outer.x <= candidate.x &&
    outer.y <= candidate.y &&
    outer.x + outer.width >= candidate.x + candidate.width &&
    outer.y + outer.height >= candidate.y + candidate.height
  );
}

export function toGeoBoundingBox(
  canvasBbox: CanvasBBox,
  referencePoint: GeoPoint,
  referenceZoom: number
): GeoBoundingBox {
  return {
    southWest: pointToGeoPoint(
      {
        x: canvasBbox.x,
        y: canvasBbox.y + canvasBbox.height,
      },
      referencePoint,
      referenceZoom
    ),
    northEast: pointToGeoPoint(
      {
        x: canvasBbox.x + canvasBbox.width,
        y: canvasBbox.y,
      },
      referencePoint,
      referenceZoom
    ),
  };
}

type Mark = {
  value: number;
  label: string;
};

export function generateSliderMarks(params: {
  min: number;
  max: number;
  default?: number;
  step?: number;
  labels?: { value: number; label: string }[];
}): Mark[] {
  const { min, max, default: def, step = max - min, labels = [] } = params;

  const labelMap = new Map(
    labels.map((label) => [round(label.value), label.label])
  );
  const marks: Mark[] = [];

  for (let i = min; i <= max; i = round(i + step)) {
    const roundedI = round(i);
    let label = '';

    if (def !== undefined && roundedI === round(def)) {
      label = 'Default';
    } else if (labelMap.has(roundedI)) {
      label = labelMap.get(roundedI)!;
    }

    marks.push({ value: roundedI, label });
  }

  return marks;
}

function round(value: number, precision = 1e-4): number {
  const multiplier = Math.pow(10, Math.abs(Math.log10(precision)));
  return Math.round(value * multiplier) / multiplier;
}

// https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md#available-custom-modes

import MapboxDraw, { type DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import { length } from '@turf/turf';
import { isNil } from 'lodash-es';
import { isLineStringGeojsonFeature } from './AnnotationLineDrawMode';
import {
  isPolygonGeojsonFeature,
  toLocaleNumber,
} from './AnnotationPolygonDrawMode';

type CustomModeState = any;
type CustomModeOptions = {
  lineColor: string;
  lineWidth: number;
  fillColor: string;
  fillOpacity: number;
};

export const AnnotationCircleDrawMode: DrawCustomMode<
  CustomModeState,
  CustomModeOptions
> = {
  onSetup: function (opts) {
    const props = MapboxDraw.modes.draw_line_string.onSetup!.call(this, opts);

    const circle = this.newFeature({
      type: 'Feature',
      properties: {
        meta: 'radius',
        ...opts,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
    });
    this.addFeature(circle);

    return {
      ...props,
      circle,
    };
  },
  onClick: function (state, e) {
    // Ends the drawing after the user creates a second point, triggering this.onStop
    if (state.currentVertexPosition === 1) {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
      return this.changeMode('simple_select', {
        featureIds: [state.circle.id],
      });
    }

    this.updateUIClasses({ mouse: 'add' });
    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    );
    if (state.direction === 'forward') {
      state.currentVertexPosition += 1;
      state.line.updateCoordinate(
        state.currentVertexPosition,
        e.lngLat.lng,
        e.lngLat.lat
      );
    } else {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    }

    return null;
  },
  onMouseMove: function (state, e) {
    MapboxDraw.modes.draw_line_string.onMouseMove!.call(this, state, e);
    const geojson = state.line.toGeoJSON();
    const center = geojson.geometry.coordinates[0];
    const radiusInKm = length(geojson, { units: 'kilometers' });
    const circleFeature = createGeoJSONCircle(
      center,
      radiusInKm,
      state.line.id
    );
    // circleFeature.properties.meta = 'radius';
    // circleFeature.properties.parent = state.line.id;
    state.circle.setCoordinates(circleFeature.geometry.coordinates);
  },
  onStop: function (state) {
    // Creates the final geojson point feature with a radius property

    /**
     * Check to see if we've deleted this feature. If we have, clean up the
     * state to eliminate any remnants.
     */
    if (isNil(this.getFeature(state.line.id))) {
      this.deleteFeature(state.circle.id, { silent: true });
      return;
    }

    // Remove last added coordinate
    state.line.removeCoordinate('0');
    if (state.line.isValid()) {
      this.deleteFeature(state.line.id, { silent: true });

      // Trigger draw.create
      this.map.fire('draw.create', {
        features: [state.circle.toGeoJSON()],
      });
    } else {
      this.deleteFeature(state.line.id, { silent: true });
      this.changeMode('simple_select', {}, { silent: true });
    }
  },
  onKeyUp: function (state, event) {
    if (event.key === 'Enter') {
      state.line.addCoordinate(
        0,
        state.line.coordinates[0][0],
        state.line.coordinates[0][1]
      );
      return this.changeMode('simple_select', {
        featureIds: [state.circle.id],
      });
    }
    if (event.key === 'Escape') return this.changeMode('simple_select');
  },
  toDisplayFeatures: function (state, geojson, display) {
    if (!('properties' in geojson) || isNil(geojson.properties)) return;

    const isActiveLine = geojson.properties.id === state.line.id;
    geojson.properties.active = isActiveLine.toString();
    if (!isActiveLine) return display(geojson);

    if (
      !isLineStringGeojsonFeature(geojson) &&
      !isPolygonGeojsonFeature(geojson)
    )
      return; // type narrowing

    // Only render the line if it has at least one real coordinate
    if (geojson.geometry.coordinates.length < 2) return null;

    geojson.properties.meta = 'feature';

    // displays center vertex as a point feature
    // display(
    //   createVertex(
    //     state.line.id,
    //     geojson.geometry.coordinates[
    //       state.direction === 'forward'
    //         ? geojson.geometry.coordinates.length - 2
    //         : 1
    //     ],
    //     `${
    //       state.direction === 'forward'
    //         ? geojson.geometry.coordinates.length - 2
    //         : 1
    //     }`,
    //     false
    //   )
    // );

    // Display the line as it is drawn
    display(geojson);

    if (!isLineStringGeojsonFeature(geojson)) return;

    /* Create custom feature for the current pointer position */
    const displayMeasurements = getDisplayMeasurements(geojson);

    const currentVertex: GeoJSON.Feature = {
      type: 'Feature',
      properties: {
        meta: 'currentPosition',
        metric: displayMeasurements.metric,
        imperial: displayMeasurements.imperial,
        parent: state.line.id,
      },
      geometry: {
        type: 'Point',
        coordinates: geojson.geometry.coordinates[1],
      },
    };

    display(currentVertex);

    // create custom feature for radius circle marker

    // const center = geojson.geometry.coordinates[0];
    // const radiusInKm = measureDistance(geojson, { units: 'kilometers' });
    // const circleFeature = createGeoJSONCircle(
    //   center,
    //   radiusInKm,
    //   state.line.id
    // );
    // circleFeature.properties!.meta = 'radius';

    // display(circleFeature);
  },
};

// create a circle-like polygon given a center point and radius
// https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js/39006388#39006388
function createGeoJSONCircle(
  center: GeoJSON.Position,
  radiusInKm: number,
  parentId: string,
  points = 64
): GeoJSON.Feature<GeoJSON.Polygon, { parent: string; meta: string }> {
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }

  ret.push(ret[0]);

  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [ret],
    },
    properties: {
      parent: parentId,
      meta: '',
    },
  };
}

function getDisplayMeasurements(feature: GeoJSON.Feature) {
  const drawnLength = length(feature, { units: 'meters' });

  let metricMeasurement = drawnLength;
  let metricFixed = 0;
  let metricUnits = 'm';

  let imperialMeasurement = drawnLength * 3.28084;
  let imperialFixed = 0;
  let imperialUnits = 'ft';

  if (drawnLength >= 1000) {
    // If over 1000 meters, use kilometers
    metricMeasurement = drawnLength / 1000;
    metricFixed = 2;
    metricUnits = 'km';
  }

  if (imperialMeasurement >= 5280) {
    // If over 5280 feet, use miles
    imperialMeasurement /= 5280;
    imperialFixed = 2;
    imperialUnits = 'mi';
  }

  const displayMeasurements = {
    metric: `${toLocaleNumber(metricMeasurement, metricFixed)} ${metricUnits}`,
    imperial: `${toLocaleNumber(
      imperialMeasurement,
      imperialFixed
    )} ${imperialUnits}`,
  };

  return displayMeasurements;
}

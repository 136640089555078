import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { dependencies } from '@pn/core/dependencies';
import { formatDataType } from '@pn/core/domain/data';
import { PolyFeature } from '@pn/core/domain/drawing';
import type { GeoBoundingBox } from '@pn/core/domain/geography';
import {
  DataMultiSelectionReason,
  getVisualizableQuery,
} from '@pn/core/domain/query';
import {
  createWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { ZonalQuery, createZonalQuery } from '@pn/core/domain/zone';
import { ApiError } from '@pn/core/errors';
import { handleError } from '@pn/core/errors/handleError';
import { STREAMING_LIMIT } from '@pn/core/limits';
import { useCurrentUserStorage, workspaceActions } from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { findOrThrow, hasKeyWithType } from '@pn/core/utils/logic';
import ab_formations from '@pn/resources/zones/ab_formations.json';
import { apiZoneProvider } from '@pn/services/api/zone/apiZoneProvider';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import {
  REFERENCE_PT,
  REFERENCE_ZOOM,
  formatArea,
  getCanvasBoundingBox,
  toGeoBoundingBox,
  useDrawing,
} from '@pn/services/drawing';
import { generateFeatureMeasurements } from '@pn/services/drawing/measurement';
import { murmurhash3 } from '@pn/services/utils/hash';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { CurrentModifiers } from '@pn/ui/data-table/components/zonal-query/CurrentModifiers';
import { ZoneBoundaryControls } from '@pn/ui/inputs/ZoneBoundaryControls';
import assert from 'assert';
import {
  findLast,
  isEmpty,
  isNil,
  isObject,
  isString,
  noop,
  uniq,
} from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
  success: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
  alert: {
    width: '100%',
  },
  button: {
    width: 95,
    height: 40,
  },
}));

const entityOptions = uniq([
  ...ab_formations.map((formation) => formation.name),
  // ...geologicTimeScales.map((scale) => scale.name),
]);

type Props = {
  item: WorkspaceItem;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const ZonalQueryDialog = ({ item, open, onOpen, onClose }: Props) => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { lastRedraw, drawingState, setDrawingMode, redraw } = useDrawing();

  const [zonalQuery, setZonalQuery] = React.useState(createZonalQuery());
  const [inProgress, setInProgress] = React.useState(false);
  const [isAwaitingArea, setIsAwaitingArea] = React.useState(false);

  const { areaDrawing, area, isAreaAboveLimit } = React.useMemo(() => {
    const areaDrawing = findLast(
      Object.values(drawingState.features),
      (feature) =>
        feature.type === 'poly' &&
        feature.subType === 'rectangle_selection' &&
        feature.itemId === ''
    ) as PolyFeature | undefined;
    const area = !isNil(areaDrawing)
      ? (generateFeatureMeasurements(areaDrawing).area as number)
      : undefined;
    const isAreaAboveLimit = !isNil(area) && area > 10e10; // 10,000,000 ha

    return { areaDrawing, area, isAreaAboveLimit };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRedraw]);

  const isDisabled =
    item.dataType === 'open_mineral_rights' &&
    item.totalCount > STREAMING_LIMIT &&
    isNil(areaDrawing);

  /**
   * Auto-open the dialog when an area is selected.
   */
  React.useEffect(() => {
    if (!isAwaitingArea || isNil(areaDrawing)) return;

    setIsAwaitingArea(false);
    onOpen();
  }, [isAwaitingArea, areaDrawing, onOpen]);

  /**
   * Reset drawing mode when the dialog is opened.
   */
  React.useEffect(() => {
    if (!open) return;

    setDrawingMode('select');
  }, [open, setDrawingMode]);

  const handleDataTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDataType = event.target.value;

    workspaceActions().addToWorkspace(newDataType);
    workspaceActions().select(newDataType);
  };

  const handleSelectArea = () => {
    setIsAwaitingArea(true);
    setDrawingMode('rectangle_select');
    onClose();
  };

  const handleRemoveArea = () => {
    assert(areaDrawing, 'Area should be defined to remove');

    delete drawingState.features[areaDrawing.id];
    delete drawingState.paths[areaDrawing.id];
    drawingState.order = drawingState.order.filter(
      (id) => id !== areaDrawing!.id
    );

    redraw();
  };

  const handleSubmit = async () => {
    const { notificationService } = dependencies;

    setInProgress(true);

    try {
      const internalIds = await apiZoneProvider.getByZone({
        zonalQuery,
        dataType: item.dataType,
        bbox: getBboxFromDrawing(areaDrawing),
        query: getVisualizableQuery(item),
        mapping: item.mapping,
      });

      if (isEmpty(internalIds)) {
        notificationService.notify(`No results found for your query`);
        setInProgress(false);
        return;
      }

      const colorIndex =
        murmurhash3(zonalQuery.upper.entity + zonalQuery.lower.entity) %
        muiColorPalette.colors.length;

      const sourceItem = findOrThrow(
        pnWorkspaceItems,
        (i) => i.id === item.dataType
      );

      const newItem = createWorkspaceItem(
        {
          source: 'item',
          sourceItem: sourceItem,
          temporarySourceItemId: sourceItem.id,
          name: getNewItemName(item.dataType, zonalQuery),
          queryOptions: {
            requestedIds: internalIds,
            multiSelectionReason: DataMultiSelectionReason.MultiSelection, // important to allow applying & resetting filters
          },
          extraStyle: {
            color: muiColorPalette.colors[colorIndex],
          },
        },
        user
      );

      workspaceActions().add(newItem);
      workspaceActions().addToWorkspace(newItem.id);
      workspaceActions().select(newItem.id);

      onClose();
      if (!isNil(areaDrawing)) handleRemoveArea();
    } catch (error) {
      if (isVerboseApiError(error)) {
        notificationService.notify(error.responseData.error, 'error');
      } else {
        handleError({ error, userFriendlyMessage: 'Failed to query' });
      }
    }

    setInProgress(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={inProgress ? noop : onClose}
    >
      <DialogTitle>Zone Query</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormControl>
          <RadioGroup value={item.dataType} onChange={handleDataTypeChange}>
            <FormControlLabel
              value="mineral_rights"
              control={<Radio />}
              label="Mineral Rights"
            />
            <FormControlLabel
              value="open_mineral_rights"
              control={<Radio />}
              label="Open Rights"
            />
          </RadioGroup>
        </FormControl>
        {(['upper', 'lower'] as const).map((edge) => (
          <ZoneBoundaryControls
            key={edge}
            edge={edge}
            options={entityOptions}
            zonalQuery={zonalQuery}
            setZonalQuery={setZonalQuery}
          />
        ))}
        {isDisabled && (
          <Alert severity="warning" className={classes.alert}>
            Select an area or apply filters/selection to query open rights
          </Alert>
        )}
        <Box display="flex" gap={2} width={301}>
          <TextField
            size="small"
            label="Area"
            placeholder="Not selected"
            value={!isNil(area) ? formatArea(area).imperial : ''}
            error={isAreaAboveLimit}
            helperText={isAreaAboveLimit ? 'Area must be under 10M ha' : ''}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              htmlInput: {
                readOnly: true,
              },
            }}
          />
          {isNil(areaDrawing) ? (
            <CustomButton className={classes.button} onClick={handleSelectArea}>
              Select
            </CustomButton>
          ) : (
            <CustomButton className={classes.button} onClick={handleRemoveArea}>
              Remove
            </CustomButton>
          )}
        </Box>
        <DialogContentText>
          You can apply filters/selection or select a mineral rights list to
          narrow down the search. Select the default{' '}
          {formatDataType(item.dataType, { case: 'sentence' })} layer to reset.
        </DialogContentText>
        <CurrentModifiers item={item} />
      </DialogContent>
      <DialogActions>
        <Button disabled={inProgress} onClick={onClose}>
          Close
        </Button>
        <Button
          disabled={inProgress || isDisabled || isAreaAboveLimit}
          onClick={handleSubmit}
        >
          Search
        </Button>
      </DialogActions>
      {inProgress ? <LinearProgress /> : <Box height={4} />}
    </Dialog>
  );
};

function getBboxFromDrawing(
  drawing: PolyFeature | undefined
): GeoBoundingBox | undefined {
  if (isNil(drawing)) return undefined;
  const canvasBbox = getCanvasBoundingBox(drawing);
  return toGeoBoundingBox(canvasBbox, REFERENCE_PT, REFERENCE_ZOOM);
}

function getNewItemName(dataType: string, zonalQuery: ZonalQuery): string {
  const dataTypeFormatted = formatDataType(dataType, { case: 'sentence' });
  if (zonalQuery.upper.entity === zonalQuery.lower.entity) {
    return `${dataTypeFormatted} ${zonalQuery.upper.entity}`;
  } else {
    return `${dataTypeFormatted} ${zonalQuery.upper.entity} to ${zonalQuery.lower.entity}`;
  }
}

function isVerboseApiError(
  error: unknown
): error is ApiError & { responseData: { error: string } } {
  return (
    error instanceof ApiError &&
    error.code === 400 &&
    isObject(error.responseData) &&
    hasKeyWithType(error.responseData, 'error', isString)
  );
}

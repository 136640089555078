import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useWorkspaceStorage } from '@pn/core/storage';
import { useExternalPanel } from '@pn/ui/context-components/ExternalPanel';
import { sortBy } from 'lodash-es';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    top: 64,
    width: '100%',
    maxWidth: 480,
    height: 'calc(100% - 64px)',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.default,
    zIndex: zIndex(theme).mainPanel,
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 52,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  titleDivider: {},
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minHeight: `calc(100% - 48px - ${theme.spacing(2)} - 1px)`, // (100%) - (height of header) - (padding) - (1px)
    height: 1, // HACK https://stackoverflow.com/a/21836870/5309948
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  tableRow: {
    maxWidth: '100%',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
    border: 'none',
  },
  layerId: {
    fontWeight: 500,
  },
}));

export const ExternalPanel = () => {
  const { classes, cx } = useStyles();

  const { workspaceItemSelected } = useWorkspaceStorage();

  const { open, content, handleClose } = useExternalPanel();

  const isLayerSelected = content[0]?._item.id === workspaceItemSelected?.id;

  if (!open || !isLayerSelected) return null;

  return (
    <Box className={classes.container}>
      <Box className={classes.titleBox}>
        <Typography variant="h6">Feature Details</Typography>

        <IconButton size="large" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider className={classes.titleDivider} />

      <Box className={classes.subContainer}>
        {content.map((feature: any, index: number) => {
          const mappedRows = Object.entries(feature).map(([key, value]) => {
            return {
              key,
              value,
            };
          });

          const sortedRows = sortBy(mappedRows, (i) => i.key).filter(
            (row) => row.key !== '_item'
          );

          return (
            <Paper key={index} variant="outlined">
              <Table size="small">
                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>Layer</TableCell>
                    <TableCell
                      align="right"
                      className={cx(classes.tableCell, classes.layerId)}
                    >
                      {feature._item.name}
                    </TableCell>
                  </TableRow>
                  {sortedRows.map((row) => (
                    <TableRow key={row.key} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        {row.key}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {applyFormatter(row.value)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};

// Almost entirely copied from the DenseTable component
function applyFormatter(value: any) {
  if (
    value.toString().startsWith('http://') ||
    value.toString().startsWith('https://')
  ) {
    return (
      <a href={value} target="_blank" rel="noopener noreferrer">
        Open
      </a>
    );
  } else {
    return value;
  }
}

import { isInteractableLayer } from '@pn/core/domain/layer';
import {
  getPointsLayerId,
  getSelectedLayerId,
} from '@pn/core/domain/workspace';
import { hashObject } from '@pn/services/utils/hash';
import { isNil } from 'lodash-es';
import type { MapboxIMap } from '..';
import { applyMapboxStyle } from '../mapboxUtils';
import { mapboxLayerStyleMapper } from '../mappers/mapboxLayerStyleMapper';

const cache = new Map<string, number>();

/**
 * Converts core layer styles to Mapbox styles according to the style mapping.
 */
export const updateDataLayerStyle: MapboxIMap['updateDataLayerStyle'] =
  function (this: MapboxIMap, layer, { style = {}, selectionStyle = {} }) {
    if (!this.hasLayer(layer.id)) {
      throw new Error(`Layer ${layer.id} does not exist`);
    }

    const hash = hashObject(style);
    if (cache.get(layer.id) === hash) {
      // console.log('updateDataLayerStyle cache hit', layerId);
      return;
    }

    const mapboxStyle = mapboxLayerStyleMapper.toTargetLayerStyle(
      style,
      layer.type
    );
    applyMapboxStyle(this._native, layer.id, mapboxStyle);

    if (isInteractableLayer(layer)) {
      const mapboxSelectionStyle = mapboxLayerStyleMapper.toTargetLayerStyle(
        selectionStyle,
        layer.type
      );
      applyMapboxStyle(
        this._native,
        getSelectedLayerId(layer.id),
        mapboxSelectionStyle
      );
    }

    if (layer.renderAsPoints && !isNil(style.color)) {
      this._native.setPaintProperty(
        getPointsLayerId(layer.id),
        'circle-color',
        style.color
      );
      this._native.setPaintProperty(
        getSelectedLayerId(getPointsLayerId(layer.id)),
        'circle-color',
        selectionStyle.color
      );
    }

    cache.set(layer.id, hash);
  };

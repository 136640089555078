import { colors, createTheme, darkScrollbar } from '@mui/material';
import { sharedThemeTokens, productionColors } from './shared';

export const Hacker = createTheme(
  {
    palette: {
      mode: 'dark',
      primary: {
        main: '#20C20E',
      },
      secondary: {
        main: colors.yellow[700],
      },
      background: {
        default: '#000',
        paper: '#000',
      },
    },
    typography: {
      fontFamily: ['"Fira Code"', '"Courier New"', 'Courier', 'monospace'].join(
        ','
      ),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            ...darkScrollbar(),
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // borderRadius: '12px',
            // textTransform: 'none',
            // '&:hover': {
            //   backgroundColor: '#20C20E',
            //   color: '#000',
            // },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#111',
            borderColor: 'rgba(255, 255, 255, 0.23)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: '#111',
            borderColor: '#20C20E',
            color: '#20C20E',
          },
        },
      },
    },
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195,
      },
    },
    borderColor: 'rgba(255, 255, 255, 0.23)',
    productionColors,
  },
  sharedThemeTokens
);

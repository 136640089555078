import { dependencies } from '@pn/core/dependencies';
import { formatDataType, type DataType } from '@pn/core/domain/data';
import type { NotificationId } from '@pn/core/services/notifications/ports';
import { isNil } from 'lodash-es';

export function showPersistentStreamingNotification(params: {
  action: string;
  dataType: DataType;
  totalCount: number;
  onCancel: () => void;
}): NotificationId | undefined {
  const { action, dataType, totalCount, onCancel } = params;
  const { notificationService } = dependencies;

  return notificationService.displayPersistentNotification(
    `${action} ${totalCount.toLocaleString()} ${formatDataType(dataType)}...`,
    { showSpinner: true, onCancel }
  );
}

export function removePersistentStreamingNotification(
  notificationId: NotificationId | undefined
): void {
  const { notificationService } = dependencies;

  if (!isNil(notificationId)) {
    notificationService.removePersistentNotification(notificationId);
  }
}

import { centerMapOnWorkspaceItems } from '@pn/core/operations/mapInteractions/zoomToWorkspaceItem';
import { useProjectsStorage, useWorkspaceStorage } from '@pn/core/storage';
import React from 'react';

export function useAutoZoomToProject() {
  const { workspaceItems } = useWorkspaceStorage();
  const { isProjectLoaded, selectedProject } = useProjectsStorage();

  const selectedProjectId = selectedProject.id;

  const [projectIdCentered, setProjectIdCentered] = React.useState('');

  React.useEffect(() => {
    if (!isProjectLoaded || projectIdCentered === selectedProjectId) return;

    centerMapOnWorkspaceItems(workspaceItems);

    setProjectIdCentered(selectedProjectId);
  }, [selectedProjectId, isProjectLoaded, projectIdCentered, workspaceItems]);
}

import type { LibrarySection, LibraryFolder } from '@pn/core/domain/project';

export type LibraryRouter = {
  section: LibrarySection;
  folder: LibraryFolder;
  projectId?: string;
};

export const initLibraryRouter: LibraryRouter = {
  section: 'items',
  folder: 'Petro Ninja',
};

import { colors } from '@mui/material';
import type { CanvasBBox, CanvasFeature } from '@pn/core/domain/drawing';
import {
  REFERENCE_PT,
  computeMapTransformation,
  drawLive,
  drawStatic,
  getContext,
  type DrawingMode,
} from '@pn/services/drawing';
import { HistoryManager } from '@pn/services/drawing/historyManager';
import { DEFAULT_COLOR_HEX } from '@pn/services/styles';
import { isNil } from 'lodash-es';
import React from 'react';

export type DrawingState = {
  isSpacebarDown: boolean;
  isCustomPanning: boolean;
  isMovingFeature: boolean;
  isDrawing: boolean;

  resizeHoverDirection: 'nw' | 'ne' | 'sw' | 'se' | undefined;
  resizeDirection: 'nw' | 'ne' | 'sw' | 'se' | undefined;
  vertexHovered: number | undefined;
  isEdgeHovered: boolean;

  featureHovered: CanvasFeature | undefined;
  featuresHovered: Record<string, CanvasFeature>;
  featuresSelected: Record<string, CanvasFeature>;
  vertexDragged: number | undefined;

  boxSelection: CanvasBBox | undefined;

  features: Record<string, CanvasFeature>;
  paths: Record<string, Path2D>;
  order: string[];

  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  textColor: string;
  fontSize: number;
  fontFamily: string;
  opacity: number;

  displayMeasurements: boolean;
};

const drawingState: DrawingState = {
  isSpacebarDown: false,
  isCustomPanning: false,
  isMovingFeature: false,
  isDrawing: false,

  resizeHoverDirection: undefined,
  resizeDirection: undefined,
  vertexHovered: undefined,
  isEdgeHovered: false,

  featureHovered: undefined,
  featuresHovered: {},
  featuresSelected: {},
  vertexDragged: undefined,

  boxSelection: undefined,

  features: {},
  paths: {},
  order: [],

  strokeColor: colors.blue[500],
  strokeWidth: 5,
  fillColor: 'transparent',
  textColor: DEFAULT_COLOR_HEX,
  fontSize: 20,
  fontFamily: 'Roboto',
  opacity: 1,

  displayMeasurements: false,
};

const historyManager = new HistoryManager<DrawingState>(drawingState);

type DrawingContextType = {
  staticCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  liveCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>;

  isInitialized: boolean;
  setIsInitialized: (isInitialized: boolean) => void;

  drawingMode: DrawingMode;
  setDrawingMode: (mode: DrawingMode) => void;

  strokeColor: string;
  setStrokeColor: (color: string) => void;
  strokeWidth: number;
  setStrokeWidth: (width: number) => void;
  fillColor: string;
  setFillColor: (color: string) => void;
  textColor: string;
  setTextColor: (color: string) => void;
  fontSize: number;
  setFontSize: (size: number) => void;
  fontFamily: string;
  opacity: number;
  setOpacity: (opacity: number) => void;

  displayMeasurements: boolean;
  setDisplayMeasurements: (displayMeasurements: boolean) => void;

  lastRedraw: number;

  drawingState: DrawingState;
  historyManager: HistoryManager<DrawingState>;

  redraw: () => void;
  reset: (itemId?: string) => void;
};

const DrawingContext = React.createContext<DrawingContextType>(
  {} as DrawingContextType
);
export const useDrawing = () => React.useContext(DrawingContext);

type Props = {
  children: React.ReactNode;
};

export const DrawingProvider = ({ children }: Props) => {
  const staticCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const liveCanvasRef = React.useRef<HTMLCanvasElement>(null);

  const [isInitialized, setIsInitialized] = React.useState(true);
  const [drawingMode, setDrawingMode] = React.useState<DrawingMode>('select');

  const [strokeColor, setStrokeColor] = React.useState(
    drawingState.strokeColor
  );
  const [strokeWidth, setStrokeWidth] = React.useState(
    drawingState.strokeWidth
  );
  const [fillColor, setFillColor] = React.useState(drawingState.fillColor);
  const [textColor, setTextColor] = React.useState(drawingState.textColor);
  const [fontSize, setFontSize] = React.useState(drawingState.fontSize);
  const [opacity, setOpacity] = React.useState(drawingState.opacity);

  const [displayMeasurements, setDisplayMeasurements] = React.useState(
    drawingState.displayMeasurements
  );

  const [lastRedraw, setLastRedraw] = React.useState(0);

  const redraw = React.useCallback(() => {
    const transformation = computeMapTransformation(REFERENCE_PT);

    drawStatic({
      ctx: getContext(staticCanvasRef.current),
      drawingState,
      transformation,
    });

    drawLive({
      ctx: getContext(liveCanvasRef.current),
      drawingState,
      transformation,
    });

    setLastRedraw((prev) => prev + 1);
  }, []);

  const reset = React.useCallback((itemId?: string) => {
    if (isNil(itemId)) {
      drawingState.features = {};
      drawingState.featuresSelected = {};
      drawingState.paths = {};
      drawingState.order = [];
    } else {
      const featureIds = Object.values(drawingState.features)
        .filter((feature) => feature.itemId === itemId)
        .map((feature) => feature.id);

      featureIds.forEach((id) => {
        delete drawingState.features[id];
        delete drawingState.featuresSelected[id];
      });
      drawingState.order = drawingState.order.filter(
        (id) => !featureIds.includes(id)
      );
    }

    setLastRedraw((prev) => prev + 1);
  }, []);

  return (
    <DrawingContext.Provider
      value={{
        staticCanvasRef,
        liveCanvasRef,

        isInitialized,
        setIsInitialized,

        drawingMode,
        setDrawingMode,

        strokeColor,
        setStrokeColor,
        strokeWidth,
        setStrokeWidth,
        fillColor,
        setFillColor,
        textColor,
        setTextColor,
        fontSize,
        setFontSize,
        fontFamily: 'Roboto',
        opacity,
        setOpacity,

        displayMeasurements,
        setDisplayMeasurements,

        lastRedraw,

        drawingState,
        historyManager,

        redraw,
        reset,
      }}
    >
      {children}
    </DrawingContext.Provider>
  );
};

import { SvgIcon, type SvgIconProps } from '@mui/material';

export const BOEReportIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 285.14679 350.71887">
    <path d="m 236.78266,27.964901 c 13.08631,22.824891 26.10175,45.269313 38.8823,67.846693 11.12457,19.651956 12.703,40.122176 3.44564,60.843926 -15.4317,34.54259 -58.71402,48.03274 -91.236,28.74724 C 156.96119,167.07117 146.56904,127.88369 164.49194,96.704954 181.89716,66.426704 199.45112,36.233921 216.94856,6.008687 218.01371,4.168736 219.15601,2.373425 220.60197,0 c 5.53314,9.554577 10.77051,18.598381 16.18069,27.964901 m -54.69732,96.826429 c -2.68129,27.64007 21.36404,48.65715 48.22323,42.14998 23.54135,-5.70334 36.27838,-33.50348 24.79032,-55.10869 -7.95844,-14.967096 -16.74225,-29.495806 -25.18345,-44.205376 -2.98556,-5.20258 -6.04861,-10.360684 -9.46841,-16.209752 -11.72751,20.486912 -23.08591,40.033572 -34.07661,59.784848 -2.12955,3.82701 -2.82378,8.4527 -4.28508,13.58899 z" />
    <path d="m 193.92793,321.9681 c 7.57037,0.61035 14.68884,1.42438 21.8269,1.67093 3.53442,0.12207 5.16193,0.94171 5.03629,4.78848 -0.22644,6.93189 -0.064,13.8765 -0.064,22.29135 -14.0802,-1.5379 -27.37857,-2.19116 -40.36586,-4.5541 C 135.86436,338.06869 97.475849,317.79595 65.389249,285.96584 35.359185,256.1759 14.953088,220.7289 6.058907,179.25898 2.900941,164.53464 2.035394,149.31968 0.09663095,134.33213 c -0.494389,-3.82185 0.87167,-5.47823 4.94528605,-5.27322 5.483238,0.27594 10.995636,0.21058 16.486015,0.0191 3.421425,-0.1193 4.530235,1.06727 4.6511,4.57644 1.02166,29.66269 7.522507,58.00363 21.315577,84.43874 20.13786,38.59526 49.72563,67.5319 88.910111,86.62739 18.1098,8.82529 37.17022,14.40455 57.52321,17.24747 z" />
    <path d="m 55.214499,129.64507 c 7.76266,-0.005 15.04791,-0.005 22.74114,-0.005 1.01338,39.27798 14.35687,73.07684 41.951961,100.58381 27.58429,27.49619 61.30724,41.00226 100.76438,41.71637 0,7.98535 0.0428,15.59738 -0.0193,23.20859 -0.0282,3.4501 -2.71085,2.75815 -4.75424,2.7012 C 194.36349,297.24973 173.5307,293.12922 153.8833,284.2186 98.881549,259.27387 65.930979,216.79093 54.462759,157.65491 c -1.54371,-7.9602 -1.72695,-16.1998 -2.22764,-24.32927 -0.072,-1.16951 1.62409,-2.44792 2.97934,-3.68074 z" />
    <path d="m 165.37393,232.45153 c -32.98525,-18.67848 -53.02813,-46.28561 -59.907,-83.22675 -1.17621,-6.31648 -1.34734,-12.82011 -2.02759,-19.68975 9.168,0 17.54024,0 24.83934,0 2.52377,10.91311 3.68224,21.85464 7.58046,31.71275 14.02074,35.45676 40.63646,54.93494 78.59882,58.4695 4.82357,0.44909 6.76443,1.42367 6.37439,6.58877 -0.48441,6.41428 -0.11612,12.89292 -0.11612,19.75009 -19.88828,0.24012 -37.88884,-4.77246 -55.3423,-13.60461 z" />
  </SvgIcon>
);

import { isString } from 'lodash-es';

export const displayModes = ['chart', 'table', 'settings'] as const;
export type DisplayMode = (typeof displayModes)[number];
export function isDisplayMode(arg: unknown): arg is DisplayMode {
  return isString(arg) && displayModes.includes(arg as DisplayMode);
}

export type ChartView = 'fullscreen' | 'main-panel';

export enum ChartScale {
  Linear = 'linear',
  Logarithmic = 'logarithmic',
}

export enum ProductionChartMode {
  ProducingDaily = 'producingDaily',
  CalendarDaily = 'calendarDaily',
  Monthly = 'monthly',
  Cumulative = 'cumulative',
}
export function isProductionChartMode(
  arg: unknown
): arg is ProductionChartMode {
  return Object.values(ProductionChartMode).includes(
    arg as ProductionChartMode
  );
}

export function toChartModeLabel(mode: ProductionChartMode): string {
  switch (mode) {
    case ProductionChartMode.ProducingDaily:
      return 'Producing Daily';
    case ProductionChartMode.CalendarDaily:
      return 'Calendar Daily';
    case ProductionChartMode.Monthly:
      return 'Monthly';
    case ProductionChartMode.Cumulative:
      return 'Cumulative';
  }
}

export type ChartSeries = Record<string, boolean>;
export type ChartSeriesStyle = {
  borderColor?: string;
  borderWidth?: number;
  borderStyle?: 'solid' | 'dashed' | 'dotted';
};
export type ChartSeriesStyles = Record<string, ChartSeriesStyle>;

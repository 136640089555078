import { useAuth0 } from '@auth0/auth0-react';
import type { IAuthenticationService } from '@pn/core/services/authentication/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { useLogin } from './login';
import { useLogout } from './logout';

export function useAuth0AuthenticationService(): IAuthenticationService {
  const {
    isLoading,
    isAuthenticated,
    user: auth0User,
    getAccessTokenSilently,
  } = useAuth0();

  const login = useLogin();
  const logout = useLogout();

  return {
    isAuthenticating: isLoading,
    isAuthenticated,
    isEmailVerified: auth0User?.email_verified ?? false,
    login,
    logout,
    getAccessToken: async () => {
      const token = await getAccessTokenSilently();
      return {
        token,
        domain: 'pn',
      };
    },
    resendVerificationEmail: () =>
      // QUESTION is there a way to do this via Auth0 API instead?
      pnApiClient.request({
        method: 'POST',
        url: 'v1/verify_email',
      }),
  };
}

import { Link as MuiLink } from '@mui/material';
import { isCordova } from '@pn/core/utils/env';
import { Link } from 'react-router-dom';

type Props = {
  link: string;
  target?: string;
  rel?: string;
  className?: string;
  children: React.ReactNode;
};

export const DynamicLink = ({
  link,
  target = '_self',
  rel = 'noopener noreferrer',
  className,
  children,
}: Props) => {
  if (isCordova()) {
    return <Link to={link}>{children}</Link>;
  } else {
    return (
      <MuiLink
        component="a"
        href={link}
        target={target}
        rel={rel}
        className={className}
      >
        {children}
      </MuiLink>
    );
  }
};

import { useTheme, type Theme } from '@mui/material';
import React from 'react';
import { useDropzone, type DropzoneOptions } from 'react-dropzone';

const baseStyle = (theme: Theme) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as any,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderStyle: 'dashed',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.disabled,
  outline: 'none',
  transition: 'border .24s ease-in-out',
});

const focusedStyle = {
  borderColor: '#2196f3',
} as const;

const acceptStyle = {
  borderColor: '#00e676',
} as const;

const rejectStyle = {
  borderColor: '#ff1744',
} as const;

export function useStyledReactDropzone(options?: DropzoneOptions) {
  const theme = useTheme();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    ...rest
  } = useDropzone(options);

  const style: React.CSSProperties = React.useMemo(
    () => ({
      ...baseStyle(theme),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [theme, isFocused, isDragAccept, isDragReject]
  );

  return { getRootProps, getInputProps, isDragActive, style, ...rest };
}

import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { useWorkspaceStorage } from '@pn/core/storage';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isNil } from 'lodash-es';
import { MAIN_TRAY_WIDTH } from 'src/ui/Main';
import { QuickInfo } from 'src/ui/main-panel/components/QuickInfo';
import { Tray } from 'src/ui/main-panel/components/Tray';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  mainTray: {
    position: 'absolute',
    top: 64,
    left: 0,
    width: MAIN_TRAY_WIDTH,
    height: 'calc(100% - 64px)',
    border: 'none',
    overflowX: 'hidden',
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).mainTray,
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      bottom: 0,
      width: '100%',
      height: 'auto',
      border: 'none',
    },
    '@media print': {
      display: 'none',
    },
  },
  drawerTray: {
    height: '100%',
  },
}));

type Props = {
  isMainTrayOpen: boolean;
};

export const MainTray = ({ isMainTrayOpen }: Props) => {
  const { classes } = useStyles();
  const { xsScreen } = useScreenSize();

  const { workspaceItemSelected } = useWorkspaceStorage();

  if (!isMainTrayOpen || isNil(workspaceItemSelected)) return null;

  return (
    <Paper square variant="outlined" className={classes.mainTray}>
      {xsScreen && <QuickInfo item={workspaceItemSelected} />}
      <Box className={classes.drawerTray}>
        <Tray item={workspaceItemSelected} />
      </Box>
    </Paper>
  );
};

import { dependencies } from '@pn/core/dependencies';
import type { CanvasFeature } from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import { generateId } from '@pn/core/utils/id';
import {
  REFERENCE_PT,
  computeMapTransformation,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { first, isNil } from 'lodash-es';
import React from 'react';

export function useTextTool(itemId: string) {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const {
    drawingMode,
    textColor,
    setTextColor,
    fontSize,
    setFontSize,
    fontFamily,
    opacity,
    drawingState,
    historyManager,
    redraw,
  } = useDrawing();

  const inputRef = React.useRef<HTMLTextAreaElement>();

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.color = textColor;
      inputRef.current.style.fontSize = `${fontSize}px`;
      inputRef.current.style.fontFamily = fontFamily;
      inputRef.current.style.opacity = opacity.toString();
      adjustInputSize(inputRef.current);
    }
  }, [textColor, fontSize, fontFamily, opacity]);

  React.useEffect(() => {
    if (!isDrawingPanelOpen || drawingMode !== 'text') return;

    const { map } = dependencies;
    const mapboxMap = map._native;
    const mapContainer = mapboxMap.getContainer();

    let point: Point | undefined;
    let feature: CanvasFeature | undefined;

    const endTextDrawing = (e?: KeyboardEvent | MouseEvent) => {
      if (!inputRef.current || isNil(point)) return;

      /**
       * Escape and Enter keys submit the text while other keys are passed
       * through into the textarea.
       */
      if (!isNil(e) && e instanceof KeyboardEvent) {
        const isEscape = e.key === 'Escape';
        const isUnmodifiedEnter = e.key === 'Enter' && !e.shiftKey;
        if (!isEscape && !isUnmodifiedEnter) return;
      }

      const text = inputRef.current.value;
      if (text.trim() !== '') {
        const transformation = computeMapTransformation(REFERENCE_PT, false);
        const inverseTransformation = {
          dx: -transformation.dx,
          dy: -transformation.dy,
          scale: 1 / transformation.scale,
        };

        const id = feature?.id ?? generateId();
        drawingState.features[id] = {
          id,
          type: 'text',
          text,
          position: transformPoint(point, inverseTransformation),
          textColor: drawingState.textColor,
          fontSize: drawingState.fontSize / transformation.scale,
          fontFamily: drawingState.fontFamily,
          opacity: drawingState.opacity,
          itemId,
          isVisible: true,
        };
        drawingState.order.push(id);

        redraw();

        historyManager.add(drawingState);
      }

      drawingState.isDrawing = false;

      mapContainer.removeChild(inputRef.current);
      inputRef.current = undefined;

      map.enableMovement();
    };

    const onClick = (e: mapboxgl.MapMouseEvent) => {
      if (drawingState.isCustomPanning) return;
      if (drawingState.isDrawing) return endTextDrawing(); // map clicks while entering text submit it

      const input = document.createElement('textarea');
      inputRef.current = input;

      const featureHovered = first(Object.values(drawingState.featuresHovered));

      if (featureHovered?.type === 'text') {
        feature = { ...featureHovered };

        delete drawingState.features[featureHovered.id];
        delete drawingState.paths[featureHovered.id];
        drawingState.order = drawingState.order.filter(
          (id) => id !== featureHovered.id
        );

        redraw();

        input.value = featureHovered.text;
        input.setSelectionRange(0, input.value.length);

        const transformation = computeMapTransformation(REFERENCE_PT, false);

        point = {
          x:
            featureHovered.position.x * transformation.scale +
            transformation.dx * transformation.scale,
          y:
            featureHovered.position.y * transformation.scale +
            transformation.dy * transformation.scale,
        };

        drawingState.textColor = featureHovered.textColor;
        drawingState.fontSize = featureHovered.fontSize * transformation.scale;
        drawingState.fontFamily = featureHovered.fontFamily;
        setTextColor(drawingState.textColor);
        setFontSize(drawingState.fontSize);
      } else {
        point = e.point;
      }

      drawingState.isDrawing = true;

      map.disableMovement();

      const borderWidth = 2;

      input.style.position = 'absolute';
      input.style.left = `${point.x - borderWidth}px`;
      input.style.top = `${point.y - borderWidth}px`;
      input.style.lineHeight = '1.2';
      input.style.color = drawingState.textColor;
      input.style.fontSize = `${drawingState.fontSize}px`;
      input.style.fontFamily = drawingState.fontFamily;
      // input.style.border = 'none';
      input.style.border = `${borderWidth}px dashed rgb(33, 150, 243)`;
      input.style.padding = '0';
      input.style.margin = '0';
      input.style.outline = 'none';
      input.style.background = 'transparent';
      input.style.opacity = drawingState.opacity.toString();
      input.style.zIndex = (1101).toString();
      input.style.whiteSpace = 'pre';
      input.style.overflowWrap = 'break-word';
      input.style.boxSizing = 'content-box';
      input.style.resize = 'none';
      input.style.overflow = 'hidden';

      mapContainer.appendChild(input);

      input.addEventListener('input', () => adjustInputSize(input));

      input.focus();
      adjustInputSize(input);

      input.addEventListener('keydown', endTextDrawing);
    };

    mapboxMap.on('click', onClick);

    const saveButton = document.getElementById('save-drawing-button');
    if (!isNil(saveButton)) {
      saveButton.addEventListener('click', endTextDrawing);
    }

    return () => {
      mapboxMap.off('click', onClick);
      endTextDrawing();

      const saveButton = document.getElementById('save-drawing-button');
      if (!isNil(saveButton)) {
        saveButton.removeEventListener('click', endTextDrawing);
      }
    };
  }, [
    isDrawingPanelOpen,
    drawingMode,
    itemId,
    // the following never change:
    drawingState,
    historyManager,
    redraw,
    setTextColor,
    setFontSize,
  ]);
}

function adjustInputSize(input: HTMLTextAreaElement): void {
  input.style.width = 'auto';
  input.style.height = 'auto';
  input.style.width = `${input.scrollWidth}px`;
  input.style.height = `${input.scrollHeight}px`;
}

import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type LoadingStateVariant = 'standard' | 'filled';

const useStyles = makeStyles<{ variant: LoadingStateVariant }>()(
  (theme, { variant }) => ({
    container: {
      padding: theme.spacing(4),
      background: variant === 'filled' ? theme.palette.action.hover : undefined,
      borderRadius: theme.shape.borderRadius,
      textAlign: 'center',
    },
    circularProgress: {
      color: theme.palette.action.disabled,
    },
  })
);

type Props = {
  variant?: LoadingStateVariant;
  text: string | React.ReactNode;
};

export function LoadingState({ variant = 'standard', text }: Props) {
  const { classes } = useStyles({ variant });

  return (
    <Box className={classes.container}>
      <CircularProgress size={48} className={classes.circularProgress} />
      <Typography color="textSecondary">{text}</Typography>
    </Box>
  );
}

/**
 * This implementation is strongly coupled with the react-console-emulator package.
 * We only use CLI for debugging, so it's OK to cut some corners here.
 * {@link https://github.com/linuswillner/react-console-emulator}
 */

import { dependencies } from '@pn/core/dependencies';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import {
  useAppStorage,
  useCurrentUserStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { downloadDataItems } from '@pn/services/exports/data/downloadDataItems';
import download from 'downloadjs';
import { isEmpty, isNil } from 'lodash-es';
import { useTableFields } from './dataTableInteractions';

export function useCliCommands() {
  const { map, errorLogger } = dependencies;
  const mapboxMap = map._native;

  const { unitSystem } = useAppStorage();
  const { user } = useCurrentUserStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { fields } = useTableFields(workspaceItemSelected?.dataType ?? '');

  return {
    'view-store': {
      description: 'Dump the store to the console.',
      fn: function () {
        const store = dependencies.store.getState();
        console.log(store);
        return 'Store dumped to the console.';
      },
    },
    'view-map-style': {
      description: 'Dump the map style to the console.',
      fn: function () {
        const mapStyle = mapboxMap.getStyle();
        console.log(mapStyle);
        return `Map style dumped to the console.`;
      },
    },
    'view-zoom': {
      description: 'View the current zoom level.',
      fn: function () {
        const zoom = mapboxMap.getZoom();
        return `Zoom level: ${zoom}.`;
      },
    },
    /* 'download-project': {
      description: 'Download currently selected project.',
      fn: function () {
        const store = dependencies.store.getState();
        const mapDataCache = getMapDataCache();

        const allWorkspaceItems: WorkspaceItem[] =
          store.workspace.allWorkspaceItems;

        const items = allWorkspaceItems
          .filter((item) => store.workspace.idsInWorkspace.includes(item.id))
          .map((item) => ({
            ...item,
            id: generateTemporaryId(),
          }));
        // TODO custom GIS layers
        // TODO Stack items

        const projectMapDataCache = items.reduce<Map<number, MapData>>(
          (acc, item) => {
            const mapQuery = getVisualizableQuery(item);
            const hash = getMapQueryHash(mapQuery);

            if (mapDataCache.has(hash)) {
              const cacheEntry = mapDataCache.get(hash)!;
              acc.set(hash, cacheEntry);
            }

            return acc;
          },
          new Map()
        );

        const projectExtract = {
          mapMode: store.map.mapMode,
          selectedProject: findOrThrow(
            store.projects.projects,
            (p: Project) => p.id === store.projects.selectedProjectId
          ),
          workspaceItems: items,
          mapDataCache: projectMapDataCache,
        };

        console.log(projectExtract);
        // download(
        //   JSON.stringify(projectExtract),
        //   snakeCase(projectExtract.selectedProject.name) + '.json',
        //   'application/json'
        // );
        // download(
        //   compressAndEncode(projectExtract),
        //   snakeCase(projectExtract.selectedProject.name) + '.txt',
        //   'text/plain'
        // );
        return `Project extract dumped to the console.`;
      },
    }, */
    'set-zoom': {
      description: 'Set the zoom level.',
      fn: function (zoom: number) {
        mapboxMap.setZoom(zoom);
        return `Zoom level set: ${zoom}.`;
      },
    },
    'export-geojson': {
      description: 'Export current layer/selection as GeoJSON.',
      fn: function () {
        if (isNil(workspaceItemSelected)) return 'Error: no layer selected.';

        if (workspaceItemSelected.itemType === 'annotation') {
          const geojsonString = JSON.stringify(
            (
              workspaceItemSelected.map.layers[0]
                .source as mapboxgl.GeoJSONSourceRaw
            ).data
          );
          download(geojsonString, 'annotation.geojson', 'application/json');
          return 'Annotation GeoJSON downloaded.';
        }

        const mapDataItems = getMapDataItems(workspaceItemSelected);
        if (isEmpty(mapDataItems)) return 'Error: no data items to export.';

        downloadDataItems({
          data: getMapDataItems(workspaceItemSelected),
          fields,
          mapping: workspaceItemSelected.mapping,
          unitSystem,
        }).geojson({
          fileName: (workspaceItemSelected.name || 'my_export') + '.geojson',
        });

        return 'GeoJSON downloaded.';
      },
    },
    'test-error': {
      description: 'Trigger a test error.',
      fn: function () {
        errorLogger.logGenericError(
          new Error('Test error triggered'),
          user?.id,
          'TestError'
        );
        return 'Test error triggered and logged.';
      },
    },
    'god-mode': {
      description: 'Activate god mode.',
      fn: function () {
        window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
      },
    },
  };
}

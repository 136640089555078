import { IconButton } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { unstable_useId as useId } from '@mui/utils';
import {
  useGridRootProps,
  type GridFilterInputValueProps,
  type GridFilterItem,
} from '@mui/x-data-grid-pro';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from 'tss-react/mui';

/* Reused in CustomGridFilterInputDate */
export const useCustomFilterInputStyles = makeStyles()(() => ({
  tinyIconButton: {
    padding: 2,
    '& .MuiSvgIcon-root': {
      fontSize: '1.125rem',
    },
  },
}));

export type GridTypeFilterInputValueProps = GridFilterInputValueProps &
  TextFieldProps & {
    type?: 'text' | 'number' | 'date' | 'datetime-local';
    clearButton?: React.ReactNode | null;
    isFilterActive?: boolean;
  };

type ItemPlusTag = GridFilterItem & { fromInput?: string };

export function CustomGridFilterInputValue(
  props: GridTypeFilterInputValueProps
) {
  const {
    item,
    applyValue,
    type,
    apiRef,
    focusElementRef,
    tabIndex,
    disabled,
    clearButton,
    InputProps,
    variant = 'standard',
    isFilterActive: _isFilterActive, // prevent spreading into the JSX
    ...others
  } = props;
  const { classes } = useCustomFilterInputStyles();
  const [filterValueState, setFilterValueState] = React.useState<string>(
    item.value ?? ''
  );
  const [isApplied, setIsApplied] = React.useState(true);
  const id = useId();
  const rootProps = useGridRootProps();

  const onFilterChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setFilterValueState(String(value));
      setIsApplied(value === '');
    },
    []
  );

  const onSubmit = React.useCallback(() => {
    const newItem = { ...item, value: filterValueState, fromInput: id };
    applyValue(newItem);
    setIsApplied(true);
  }, [applyValue, filterValueState, id, item]);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') onSubmit();
    },
    [onSubmit]
  );

  React.useEffect(() => {
    const itemPlusTag = item as ItemPlusTag;
    if (itemPlusTag.fromInput !== id || item.value === undefined) {
      setFilterValueState(String(item.value ?? ''));
    }
  }, [id, item]);

  return (
    <rootProps.slots.baseTextField
      id={id}
      label={apiRef.current.getLocaleText('filterPanelInputLabel')}
      placeholder={apiRef.current.getLocaleText('filterPanelInputPlaceholder')}
      value={filterValueState}
      onChange={onFilterChange}
      onKeyDown={onKeyDown}
      variant={variant}
      type={type || 'text'}
      inputRef={focusElementRef}
      slotProps={{
        input: {
          endAdornment: isApplied ? (
            clearButton
          ) : (
            <IconButton
              title="Apply filter"
              className={classes.tinyIconButton}
              onClick={() => onSubmit()}
            >
              <CheckIcon />
            </IconButton>
          ),
          disabled,
          ...InputProps,
          inputProps: {
            tabIndex,
            ...InputProps?.inputProps,
          },
        },
        inputLabel: { shrink: true },
      }}
      {...others}
      {...rootProps.slotProps?.baseTextField}
    />
  );
}

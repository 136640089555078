import { LayerType } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  getLayerId,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { wellsFilterProperty } from '@pn/core/storage/workspace/wellsFilterProperty';
import { isProduction } from '@pn/core/utils/env';
import { DEFAULT_COLOR, DEFAULT_SYMBOL_STYLE } from '@pn/services/styles';

export const wells_usa: WorkspaceItem = {
  folder: 'Petro Ninja',
  isTemporary: false,
  id: 'wells_usa',
  dataType: 'wells_usa',
  itemType: 'layer',
  numberOfElements: undefined,
  createdBy: undefined,
  name: 'Wells USA',
  description: '2.6M wells in US',
  origin: 'pn',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  isShared: false,
  isGlobal: true,
  map: {
    layers: [
      {
        id: getLayerId('wells_usa', 'wells_usa', 0),
        name: 'Wells',
        type: LayerType.Icon,
        style: {
          ...DEFAULT_SYMBOL_STYLE,
          font: ['petroninja-font font'],
          offset: [0, 0.2],
        },
        source: {
          type: 'vector',
          url: isProduction()
            ? 'mapbox://sbilston.production-wells-usa'
            : 'mapbox://sbilston.staging-wells-usa',
        },
        sourceLayer: 'bottoms-usa',
      },
      {
        id: getLayerId('wells_usa', 'wells_usa', 1),
        name: 'Well Paths',
        type: LayerType.Line,
        style: {
          color: DEFAULT_COLOR,
          width: 1,
        },
        source: {
          type: 'vector',
          url: isProduction()
            ? 'mapbox://sbilston.production-wells-usa'
            : 'mapbox://sbilston.staging-wells-usa',
        },
        sourceLayer: 'horizontals-usa',
        sourceField: 'well_path_geometry',
      },
    ],
    filterProperty: wellsFilterProperty,
  },
  dataSource: {
    type: 'api',
    source: 'parquet',
    url: 'v2/layers/wells_usa',
    requiredMapDataFields: [
      'internal_id',
      'symbol',
      'geometry',
      'well_path_geometry',
    ],
  },
  detailsSource: 'api',
  query: buildQuery({
    id: 'wells_usa',
    dataType: 'wells_usa',
  }),
  ...createInitInternalState({ isVisualized: false }),
};

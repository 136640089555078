import { getFullName } from '@pn/core/domain/user';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';

const groupIds = {
  'petro-ninja': 73000272857,
  'boe-intel': 73000489234,
};

/**
 * @note Find the group ID in the URL of the freshdesk web app when viewing them
 * @link https://developers.freshdesk.com/widget-api/#introduction
 */
export function useFreshdesk(groupId: keyof typeof groupIds) {
  const { user } = useCurrentUserStorage();

  const handleOpenHelp = () => {
    window.FreshworksWidget('open');
    window.FreshworksWidget('hide', 'launcher');
    window.FreshworksWidget('hide', 'ticketForm', ['group_id']);
    window.FreshworksWidget('prefill', 'ticketForm', {
      group_id: groupIds[groupId],
    });
    if (!isNil(user)) {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: getFullName(user),
        email: user.email,
      });
    }
  };

  return {
    handleOpenHelp,
  };
}

import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { currentEnterpriseSlice } from './enterpriseSlice';
import type { IEnterpriseActions, IEnterpriseStorage } from './ports';

export const useReduxCurrentEnterpriseStorage = (): IEnterpriseStorage => {
  const { name, getInitialState } = currentEnterpriseSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    enterprise: useSliceSelector((state) => state.enterprise),
  };
};

export const reduxCurrentEnterpriseActions = (): IEnterpriseActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = currentEnterpriseSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (enterprise) => dispatch(actions.receive(enterprise)),
    error: () => dispatch(actions.error()),
  };
};

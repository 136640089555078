import { Box } from '@mui/material';
import mapBg from '@pn/ui/images/pn_map_background.webp';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  backgroundImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${mapBg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'blur(2px)',
    zIndex: -1,
  },
}));

export function BackgroundImage() {
  const { classes } = useStyles();

  return <Box className={classes.backgroundImage} />;
}

import { isNil } from 'lodash-es';

/**
 * Lonely data points will be rendered as circles.
 */
export function getPointRadius(data: (number | null)[]): number[] {
  const pointRadius = data.map((value, index) => {
    if (value === null) return 0;

    const prevValue = data[index - 1];
    const nextValue = data[index + 1];

    if (isNil(prevValue) && isNil(nextValue)) return 2;

    return 0;
  });

  return pointRadius;
}

import { Box, ListItem, ListItemText, Paper } from '@mui/material';
import {
  WorkspaceItem,
  getFormattedItemName,
  getItemColor,
} from '@pn/core/domain/workspace';
import { generateIconBackground } from '@pn/services/utils/color';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  disabled: boolean;
  color: string;
}>()((theme, { disabled, color }) => ({
  paper: {
    borderColor: theme.borderColor,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 40,
    paddingLeft: theme.spacing(2.5),
    opacity: disabled ? 0.5 : 1,
  },
  colorIndicator: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 4,
    background: color,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
}));

type Props = {
  disabled: boolean;
  item: WorkspaceItem;
};

export const SourceItemBlock = ({ disabled = false, item }: Props) => {
  const { classes } = useStyles({
    disabled,
    color: generateIconBackground(getItemColor(item)),
  });

  return (
    <Paper variant="outlined" className={classes.paper}>
      <ListItem disablePadding className={classes.listItem}>
        <Box className={classes.colorIndicator} />
        <ListItemText
          primary={getFormattedItemName(item)}
          primaryTypographyProps={{
            noWrap: true,
          }}
        />
      </ListItem>
    </Paper>
  );
};

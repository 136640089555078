import type { Enterprise } from '@pn/core/domain/enterprise';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';

type State = {
  isFetching: boolean;
  isError: boolean;
  enterprises: Enterprise[];
  selectedEnterpriseId: Enterprise['id'] | undefined;
};

const initialState: State = {
  isFetching: false,
  isError: false,
  enterprises: [],
  selectedEnterpriseId: undefined,
};

export const createEnterprisesSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      create: (state, { payload }) => {
        state.enterprises = [...state.enterprises, payload.enterprise];
      },
      remove: (state, { payload }) => {
        state.enterprises = state.enterprises.filter(
          (enterprise) => enterprise.id !== payload
        );
      },
      select: (state, { payload }) => {
        state.selectedEnterpriseId = payload;
      },
      unselect: (state) => {
        state.selectedEnterpriseId = undefined;
      },
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.isFetching = false;
        state.enterprises = payload;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.enterprises = [];
      },
      update: (state, { payload }: PayloadAction<Enterprise>) => {
        const enterpriseToUpdate = state.enterprises.find(
          ({ id }) => id === payload.id
        );
        if (isNil(enterpriseToUpdate)) {
          throw new Error('Enterprise not found');
        }
        Object.assign(enterpriseToUpdate, payload);
      },
      _updateId: (
        state,
        {
          payload: { currentId, newId },
        }: PayloadAction<{
          currentId: Enterprise['id'];
          newId: Enterprise['id'];
        }>
      ) => {
        const enterprise = state.enterprises.find(({ id }) => id === currentId);
        if (isNil(enterprise)) {
          throw new Error(
            `Enterprise with current ID ${currentId} does not exist in storage`
          );
        }
        enterprise.id = newId;
      },
      _replace: (state, { payload }) => {
        return { ...payload };
      },
    },
  });

export const enterprisesSlice = createEnterprisesSlice('enterprises');

import { alpha, colors } from '@mui/material';

export type GridMapboxStyle = {
  lineColor: string;
  textColor: string;
  textHaloColor?: string;
  textHaloWidth?: number;
  lineWidth: unknown[];
  textSize: unknown[];
};

export const gridStyles: Record<
  'light' | 'dark',
  Record<string, GridMapboxStyle>
> = {
  light: {
    township: {
      lineColor: alpha('hsl(262, 80%, 60%)', 0.5),
      textColor: colors.deepPurple['A200'],
      lineWidth: interpolate({
        9: 1,
        14: 3,
      }),
      textSize: interpolate({
        9: 14,
        14: 22,
      }),
    },
    section: {
      lineColor: alpha('hsl(231, 54%, 50%)', 0.6),
      textColor: colors.indigo[400],
      lineWidth: interpolate({
        11: 0.5,
        14: 2,
      }),
      textSize: interpolate({
        11: 14,
        14: 21,
      }),
    },
    quarterSection: {
      lineColor: alpha('hsl(207, 89%, 55%)', 0.5),
      textColor: colors.blue[700],
      lineWidth: interpolate({
        11: 0.5,
        14: 2,
      }),
      textSize: interpolate({
        11: 12,
        14: 18,
      }),
    },
    lsd: {
      lineColor: alpha('hsl(200, 18%, 50%)', 0.5),
      textColor: colors.blueGrey[600],
      lineWidth: interpolate({
        12: 0.5,
        14: 1,
      }),
      textSize: interpolate({
        12: 12,
        14: 18,
      }),
    },
    /* NTS */
    block: {
      lineColor: alpha('hsl(174, 80%, 25%)', 0.5),
      textColor: colors.teal[500],
      lineWidth: interpolate({
        9: 1,
        14: 3,
      }),
      textSize: interpolate({
        9: 14,
        14: 20,
      }),
    },
    unit: {
      lineColor: alpha('hsl(174, 63%, 30%)', 0.4),
      textColor: colors.teal[600],
      lineWidth: interpolate({
        11: 0.5,
        14: 2,
      }),
      textSize: interpolate({
        11: 12,
        14: 18,
      }),
    },
  },

  dark: {
    township: {
      lineColor: 'rgba(255, 255, 255, 0.5)',
      textColor: 'white',
      textHaloColor: 'black',
      textHaloWidth: 1,
      lineWidth: interpolate({
        9: 1,
        14: 3,
      }),
      textSize: interpolate({
        9: 14,
        14: 22,
      }),
    },
    section: {
      lineColor: 'rgba(255, 255, 255, 0.5)',
      textColor: 'white',
      textHaloColor: 'black',
      textHaloWidth: 1,
      lineWidth: interpolate({
        11: 0.5,
        14: 2,
      }),
      textSize: interpolate({
        11: 14,
        14: 21,
      }),
    },
    quarterSection: {
      lineColor: 'rgba(255, 255, 255, 0.5)',
      textColor: 'white',
      textHaloColor: 'black',
      textHaloWidth: 1,
      lineWidth: interpolate({
        11: 0.5,
        14: 2,
      }),
      textSize: interpolate({
        11: 12,
        14: 18,
      }),
    },
    lsd: {
      lineColor: 'rgba(255, 255, 255, 0.5)',
      textColor: 'white',
      textHaloColor: 'black',
      textHaloWidth: 1,
      lineWidth: interpolate({
        12: 0.5,
        14: 1,
      }),
      textSize: interpolate({
        12: 12,
        14: 18,
      }),
    },
    /* NTS */
    block: {
      lineColor: 'rgba(255, 255, 255, 0.5)',
      textColor: 'white',
      textHaloColor: 'black',
      textHaloWidth: 1,
      lineWidth: interpolate({
        9: 1,
        14: 3,
      }),
      textSize: interpolate({
        9: 14,
        14: 20,
      }),
    },
    unit: {
      lineColor: 'rgba(255, 255, 255, 0.5)',
      textColor: 'white',
      textHaloColor: 'black',
      textHaloWidth: 1,
      lineWidth: interpolate({
        11: 0.5,
        14: 2,
      }),
      textSize: interpolate({
        11: 12,
        14: 18,
      }),
    },
  },
};

function interpolate(expression: Record<number, number>): unknown[] {
  const flattenedEntries = Object.entries(expression).flatMap(
    ([key, value]) => [Number(key), value]
  );

  return [
    'interpolate',
    ['linear'],
    ['zoom'],
    0,
    Object.values(expression)[0],
    ...flattenedEntries,
  ];
}

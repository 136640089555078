import { alpha, Box, Typography } from '@mui/material';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import { isTouchDevice } from '@pn/core/utils/env';
import { isNil, noop } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { StyledBadge } from './StyledBadge';

const useStyles = makeStyles<{ selected: boolean }>()(
  (theme, { selected }) => ({
    railItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      minWidth: 64,
      cursor: 'pointer',
      '&:hover': {
        '& .iconContainer': {
          backgroundColor: selected
            ? alpha(theme.palette.primary.main, 0.25)
            : theme.palette.action.hover,
        },
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
      },
    },
    iconContainer: {
      position: 'relative',
      width: 56,
      height: 36,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: selected
        ? alpha(theme.palette.primary.main, 0.2)
        : 'unset',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      color: selected
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
    },
    textIconContainer: {
      width: selected ? 24 : 20,
      height: selected ? 24 : 20,
      boxSizing: 'content-box',
      border: selected
        ? undefined
        : `2px solid ${theme.palette.text.secondary}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: selected ? theme.palette.text.primary : 'unset',
      lineHeight: `${selected ? 22 : 18}px`,
      textAlign: 'center',
    },
    textIcon: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      fontWeight: 500,
      color: selected
        ? theme.palette.getContrastText(theme.palette.text.secondary)
        : theme.palette.text.secondary,
    },
    label: {
      marginTop: theme.spacing(0.5),
      fontSize: 12,
      fontWeight: selected ? 500 : 400,
      color: theme.palette.text.primary,
      textAlign: 'center',
    },
  })
);

type OnClickProps = {
  to?: never;
  onClick: () => void;
};

type LinkProps = {
  to: string;
  onClick?: never;
};

type Props = {
  Icon?: React.ElementType;
  label: string;
  selected?: boolean;
  badgeActive?: boolean;
  badgeColor?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
} & (OnClickProps | LinkProps);

export const NavRailItem = ({
  Icon,
  label,
  selected = false,
  badgeActive = false,
  badgeColor = 'default',
  to,
  onClick,
}: Props) => {
  const { classes, cx } = useStyles({ selected });

  const rippleRef = React.useRef<any>();

  const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    rippleRef.current.start(event);
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    rippleRef.current.stop(event);
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    rippleRef.current.stop(event);
  };

  const renderIcon = () => {
    if (!isNil(Icon)) {
      return <Icon className={classes.icon} />;
    } else {
      return (
        <Box className={classes.textIconContainer}>
          <span className={classes.textIcon}>{label[0] ?? '?'}</span>
        </Box>
      );
    }
  };

  return (
    <Box
      className={classes.railItem}
      component={isNil(to) ? 'div' : Link}
      to={to}
      onClick={onClick}
      onMouseDown={!isTouchDevice() ? handleMouseDown : noop}
      onMouseUp={!isTouchDevice() ? handleMouseUp : noop}
      onMouseLeave={!isTouchDevice() ? handleMouseLeave : noop}
    >
      <Box className={cx(classes.iconContainer, 'iconContainer')}>
        <StyledBadge
          selected={selected}
          invisible={!badgeActive}
          overlap="circular"
          variant="dot"
          color={badgeColor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          {renderIcon()}
        </StyledBadge>
        {!isTouchDevice() && <TouchRipple ref={rippleRef} center />}
      </Box>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};

import { useAccess } from '@pn/core/permissions/access';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export function useTerminal() {
  const [isTerminalOpen, setTerminalOpen] = React.useState(false);
  const access = useAccess();

  const handleOpenTerminal = () => setTerminalOpen(true);
  const handleCloseTerminal = () => setTerminalOpen(false);

  useHotkeys(
    ['ctrl+`', 'ctrl+alt+`'],
    (e) => {
      e.preventDefault();

      if (access('terminal').notify().denied()) return;

      handleOpenTerminal();
    },
    [access]
  );

  return { isTerminalOpen, handleOpenTerminal, handleCloseTerminal };
}

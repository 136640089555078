import { Alert } from '@mui/material';
import { useAccess } from '@pn/core/permissions/access';

type Props = {
  path: string;
  className?: string;
};

export const UnauthorizedAlert = ({ path, className }: Props) => {
  const access = useAccess();
  const show = access('dataTable.export').denied();

  if (!show) return null;

  return (
    <Alert severity="warning" className={className}>
      {access(path).message}
    </Alert>
  );
};

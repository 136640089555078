import { Box, Typography } from '@mui/material';
import type { Project } from '@pn/core/domain/project';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { isEmpty } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  itemName: {
    color: theme.palette.text.primary,
  },
  semiBold: {
    fontWeight: 500,
  },
}));

type Props = {
  itemName: string;
  projectsWithItem: Project[];
  childrenItems: WorkspaceItem[];
};

export const DeleteConfirmationDialogContent = ({
  itemName,
  projectsWithItem,
  childrenItems,
}: Props) => {
  const { classes, cx } = useStyles();

  return (
    <>
      <Typography>
        Are you sure you want to delete{' '}
        <span className={cx(classes.itemName, classes.semiBold)}>
          {itemName}
        </span>
        ? This action cannot be undone.
      </Typography>
      {isEmpty(projectsWithItem) ? (
        <Typography component={Box} mt={2}>
          This layer is not used in any projects.
        </Typography>
      ) : (
        <Typography component={Box} mt={2}>
          This layer is used in the following projects:
          <ul>
            {projectsWithItem.map((project) => (
              <li key={project.id}>
                <Typography className={classes.semiBold}>
                  {project.name}
                </Typography>
              </li>
            ))}
          </ul>
        </Typography>
      )}
      {!isEmpty(childrenItems) && (
        <Typography component={Box} mt={2}>
          Deleting this layer will also delete all of its subsets:
          <ul>
            {childrenItems.map((item) => (
              <li key={item.id}>
                <Typography className={classes.semiBold}>
                  {item.name}
                </Typography>
              </li>
            ))}
          </ul>
        </Typography>
      )}
    </>
  );
};

import { dependencies } from '@pn/core/dependencies';
import { InvitedUser } from '@pn/core/domain/user';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { IUsersActions, IUsersStorage } from './ports';
import { invitedUsersSlice } from './usersSlice';

export const useReduxInvitedUsersStorage = (): IUsersStorage<InvitedUser> => {
  const { name, getInitialState } = invitedUsersSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    users: useSliceSelector((state) => state.users),
    selectedUser: useSliceSelector((state) => state.selectedUser), // not used
  };
};

export const reduxInvitedUsersActions = (): IUsersActions<InvitedUser> => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = invitedUsersSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (invitedUsers) => dispatch(actions.receive(invitedUsers)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    add: (user) => dispatch(actions.add(user)),
    remove: (userId) => dispatch(actions.remove(userId)),
    update: () => {
      throw new Error('Not implemented');
    },
    updateUserPlan: () => {
      throw new Error('Not implemented');
    },
    select: () => {
      throw new Error('Not implemented');
    },
    unselect: () => {
      throw new Error('Not implemented');
    },
  };
};

import type { Plugin } from 'chart.js';
import { isNil } from 'lodash-es';

let watermarkImage = new Image();

export const watermarkPlugin: Plugin = {
  id: 'watermark',
  beforeInit: (chart) => {
    const img = new Image();
    img.src = '/logo_text_semibold_dark_40px.png';
    img.onload = () => {
      watermarkImage = img;

      try {
        chart.update();
      } catch (error) {
        // is expected to fail in local development mode
      }
    };
    img.onerror = function () {
      // do nothing
    };
  },
  afterDraw: (chart, _args, pluginOptions) => {
    const ctx = chart.canvas.getContext('2d');
    const pixelRatio = chart.currentDevicePixelRatio;

    if (
      !pluginOptions.enabled ||
      isNil(ctx) ||
      isNil(pixelRatio) ||
      isNil(chart.canvas)
    ) {
      return;
    }

    const width = watermarkImage.width;
    const height = watermarkImage.height;

    ctx.save();
    ctx.globalAlpha = 0.75;
    ctx.drawImage(
      watermarkImage,
      chart.canvas.width / pixelRatio - (310 / pixelRatio) * pixelRatio,
      25 / pixelRatio ** 0.5,
      width,
      height
    );
    ctx.restore();
  },
};

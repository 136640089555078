import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const wellsUsaElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'API',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'state',
    sourceType: 'text',
    label: 'State',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'county',
    sourceType: 'text',
    label: 'County',
    folder: 'Well Header',
  },
  {
    field: 'lease_well_name',
    sourceType: 'text',
    label: 'Well Name',
    folder: 'Well Header',
  },
  {
    field: 'current_operator_name',
    sourceType: 'text',
    label: 'Operator',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 225,
  },
  {
    field: 'stack_well_status',
    sourceType: 'text',
    domainTypeAttributes: {
      options: [
        'Abandoned',
        'Active',
        'Confidential',
        'Licenced',
        'Location',
        'Suspended',
        'Drilled',
      ],
    },
    label: 'Petro Ninja Status',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'stack_well_type',
    sourceType: 'text',
    domainTypeAttributes: {
      options: [
        'Confidential',
        'Disposal',
        'Injection',
        'Observation',
        'Oil Sand Evaluation',
        'Other',
        'Production',
        'Source',
        'Storage',
      ],
    },
    label: 'Petro Ninja Type',
    folder: 'Well Header',
  },
  {
    field: 'stack_well_fluid',
    sourceType: 'text',
    domainTypeAttributes: {
      options: ['Confidential', 'Gas', 'None', 'Oil', 'Other', 'Water'],
    },
    label: 'Petro Ninja Fluid',
    folder: 'Well Header',
  },
  {
    field: 'well_status',
    sourceType: 'text',
    label: 'Well Status',
    folder: 'Well Header',
  },
  {
    field: 'well_type',
    sourceType: 'text',
    label: 'Well Type',
    folder: 'Well Header',
  },
  {
    field: 'trajectory',
    sourceType: 'text',
    domainTypeAttributes: {
      options: ['Horizontal', 'Vertical', 'Directional', 'Slant'],
    },
    label: 'Trajectory',
    folder: 'Well Header',
  },
  {
    field: 'formation_name',
    sourceType: 'text',
    label: 'Formation',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 200,
  },
  {
    field: 'basin',
    sourceType: 'text',
    label: 'Basin',
    folder: 'Well Header',
  },
  {
    field: 'field_name',
    sourceType: 'text',
    label: 'Field',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'permit_granted_date',
    sourceType: 'date',
    label: 'Permit Date',
    folder: 'Well Header',
    width: 110,
  },
  {
    field: 'spud_date',
    sourceType: 'date',
    label: 'Spud Date',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 110,
  },
  {
    field: 'completion_date',
    sourceType: 'date',
    label: 'Completion Date',
    folder: 'Well Header',
  },
  {
    field: 'production_first_prod_date',
    sourceType: 'date',
    label: 'First Production Date',
    folder: 'Well Header',
  },
  {
    field: 'production_last_prod_date',
    sourceType: 'date',
    label: 'Last Production Date',
    folder: 'Well Header',
  },
  {
    field: 'plug_date',
    sourceType: 'date',
    label: 'Plug Date',
    folder: 'Well Header',
  },
  {
    field: 'current_well_count_on_lease',
    sourceType: 'integer',
    label: 'Well Count on Lease',
    folder: 'Well Header',
  },
  {
    field: 'total_measured_depth_ft',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'ft',
    },
    label: 'TMD',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 120,
  },
  {
    field: 'total_vertical_depth_ft',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'ft',
    },
    label: 'TVD',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 120,
  },
  /* Production */
  {
    field: 'production_has_recent_prod',
    sourceType: 'boolean',
    label: 'Has Recent Production',
    folder: 'Production',
  },
  {
    field: 'recent_oil',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl',
    },
    label: 'Recent Oil',
    folder: 'Production',
    isShownByDefault: true,
  },
  {
    field: 'recent_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'mcf',
    },
    label: 'Recent Gas',
    folder: 'Production',
    isShownByDefault: true,
  },
  /* Not shown */
  {
    field: 'symbol',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'well_path_geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

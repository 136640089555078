import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Paper,
} from '@mui/material';
import { UserPlans } from '@pn/core/domain/user';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { submitWorkspaceItem } from '@pn/core/operations/workspace/crud/submitWorkspaceItem';
import { isEditable } from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { useDrawing } from '@pn/services/drawing';
import { drawItem } from '@pn/services/drawing/hooks/useVisualizeDrawingItems';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { useMenu } from '@pn/ui/hooks/useMenu';
import {
  EditableTypography,
  useEditableTypographyState,
} from '@pn/ui/inputs/EditableTypography';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isEmpty } from 'lodash-es';
import { notify } from 'src/application/externalDependencies';
import { DrawingPanelControls } from 'src/ui/drawing/DrawingPanelControls';
import { DrawingPanelMenu } from 'src/ui/drawing/DrawingPanelMenu';
import { ItemInformation } from 'src/ui/workspace/components/ItemInformation';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

export const DRAWING_PANEL_WIDTH = 370;

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateAreas: `
      'title'
      'divider'
      'content'
      'actions'
    `,
    gridTemplateRows: 'min-content min-content 1fr min-content',
    position: 'absolute',
    left: theme.spacing(2),
    top: `calc(64px + ${theme.spacing(2)})`,
    width: DRAWING_PANEL_WIDTH,
    background: theme.palette.background.paper,
    zIndex: zIndex(theme).workspace,
    '@media print': {
      display: 'none',
    },
  },
  dialogTitleContainer: {
    gridArea: 'title',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitle: {
    // NOTE do not set explicit height, it will break the wrapping
    display: 'inline-flex',
    alignItems: 'center',
    flex: 1, // wraps long titles
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontWeight: 300,
  },
  editableTypography: {
    width: '100%',
    minHeight: 29,
    paddingTop: 3,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  editableTextField: {
    position: 'relative',
    top: 2,
  },
  dialogContent: {
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  dialogActions: {
    gridArea: 'actions',
    height: 40,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  divider: {
    gridArea: 'divider',
  },
}));

type Props = {
  item: WorkspaceItem;
};

export const DrawingPanel = ({ item }: Props) => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { workspaceItems } = useWorkspaceStorage();

  const { closeWorkspaceItemPanel, resetCurrentItem } = useWorkspaceItemPanel();
  const { handleOpenConfirmationDialog } = useConfirmationDialog();
  const { drawingState, historyManager, redraw, reset } = useDrawing();

  const isEditingDisabled = !isEditable(item, user);

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();

  const nameState = useEditableTypographyState(item.name, item.isTemporary);

  const handleSubmit = async () => {
    const { updatedItem, errorThrown } = await submitWorkspaceItem({
      item,
      drawingState,
    });

    if (errorThrown) return;

    notify('Drawing saved!');
    closeWorkspaceItemPanel(true);

    /* Reset the canvas and redraw all active workspace drawings */
    reset();
    workspaceItems
      .filter((i) => i.id !== updatedItem.id && i.itemType === 'drawing')
      .forEach((i) => drawItem(i, drawingState));
    drawItem(updatedItem, drawingState);
    redraw();
  };

  const handleClose = () => {
    function onClose() {
      if (item.isTemporary) {
        workspaceActions().remove(item);
      } else {
        resetCurrentItem();
      }

      closeWorkspaceItemPanel();

      /* Reset the canvas and redraw all active workspace drawings */
      reset();
      workspaceItems
        .filter(({ itemType }) => itemType === 'drawing')
        .forEach((i) => drawItem(i, drawingState));
      redraw();
    }

    if (historyManager.length() > 0) {
      handleOpenConfirmationDialog({
        title: 'Exit annotation editor?',
        text: 'All unsaved changes will be lost.',
        action: () => onClose(),
      });
    } else {
      onClose();
    }
  };

  return (
    <Paper variant="outlined" className={classes.container}>
      <DrawingPanelMenu
        item={item}
        disabled={isEditingDisabled}
        anchorEl={anchorEl}
        onClose={closeMenu}
        onRename={() => {
          nameState.setIsEditing(true);
          setTimeout(() => nameState.inputRef.current?.focus(), 0);
        }}
      />

      <Box className={classes.dialogTitleContainer}>
        <DialogTitle className={classes.dialogTitle}>
          <EditableTypography
            singleClickToEdit
            variant="body1"
            classes={{
              typography: classes.editableTypography,
              textField: classes.editableTextField,
            }}
            title={
              isEditingDisabled
                ? 'Cannot rename this annotation'
                : 'Click to edit'
            }
            disabled={isEditingDisabled}
            value={item.name}
            onSave={(newValue) =>
              workspaceActions().updateName(item.id, newValue)
            }
            {...nameState}
          />
        </DialogTitle>

        <Box mr={2}>
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box px={2}>
        <Divider className={classes.divider} />
      </Box>
      <DialogContent className={classes.dialogContent}>
        <ItemInformation item={item} />

        <DrawingPanelControls />

        <Alert severity="info">
          Learn about the new drawing tools{' '}
          <Link
            component="a"
            href="https://help.petroninja.com/annotations/overview"
            target="_blank"
          >
            here
          </Link>
          .{' '}
          {user?.userPlan === UserPlans.Free && (
            <span>
              This feature will require Professional or Enterprise plan starting
              Nov 15th, 2024.
            </span>
          )}
        </Alert>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          id="save-drawing-button"
          disabled={isEditingDisabled || isEmpty(item.name)}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Paper>
  );
};

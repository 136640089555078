import { formatDataType } from '@pn/core/domain/data';
import { DataSelectionReason } from '@pn/core/domain/query';
import {
  createWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { selectDataItem } from '@pn/core/operations/dataSelection';
import {
  centerMapOnDataItems,
  centerMapOnWorkspaceItem,
} from '@pn/core/operations/mapInteractions/zoomToWorkspaceItem';
import { useRouter } from '@pn/core/operations/router';
import { useVisualizeWorkspaceItem } from '@pn/core/operations/workspace';
import { useAccess } from '@pn/core/permissions/access';
import { dynamicDataProvider } from '@pn/core/providers/data/dynamicDataProvider';
import {
  useAppStorage,
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { log } from '@pn/core/utils/debug';
import { isEmbedded } from '@pn/core/utils/embedded';
import { apiAnnotationProvider } from '@pn/services/api/annotation/apiAnnotationProvider';
import { apiLayerProvider } from '@pn/services/api/layer/apiLayerProvider';
import { apiListProvider } from '@pn/services/api/list/apiListProvider';
import { DEFAULT_LIST_COLOR } from '@pn/services/styles/list';
import { isNil } from 'lodash-es';
import React from 'react';
import { notificationService } from 'src/application/externalDependencies';

/**
 * Add and select a workspace item + ID from URL query parameters.
 */
export function useInitFromUrl() {
  const {
    pathname,
    query: {
      dataType: queryDataType,
      itemId: queryItemId,
      projectId: queryProjectId,
      id: queryId,
      f: queryF,
    },
    isInitialUrl,
  } = useRouter();

  const access = useAccess();
  const isAccessDenied =
    !isEmbedded() && access('allData', queryDataType).denied();

  const { pageSize } = useAppStorage();
  const { user } = useCurrentUserStorage();
  const visualizeWorkspaceItem = useVisualizeWorkspaceItem();

  React.useEffect(() => {
    if (!isInitialUrl) return; // only runs on page load

    /**
     * Process shared list/annotation/drawing.
     */
    if (!isNil(queryItemId)) {
      const itemType = pathname.startsWith('/lists')
        ? 'list'
        : pathname.startsWith('/annotations')
          ? 'annotation'
          : 'drawing';

      const notificationId = notificationService.displayPersistentNotification(
        `Loading shared ${itemType}...`,
        { showSpinner: true }
      );

      (async () => {
        try {
          const workspaceItem =
            itemType === 'list'
              ? await apiListProvider.getListWorkspaceItemById(queryItemId)
              : await apiAnnotationProvider.getAnnotationWorkspaceItemById(
                  queryItemId
                );

          workspaceActions().add({
            ...workspaceItem,
            isProcessed: true,
          });
          workspaceActions().addToWorkspace(workspaceItem.id);
          workspaceActions().select(workspaceItem.id);

          const { mapData } = await visualizeWorkspaceItem(workspaceItem);

          notificationService.removePersistentNotification(notificationId);

          if (itemType === 'list') {
            centerMapOnDataItems(mapData.data);
          } else {
            centerMapOnWorkspaceItem(workspaceItem);
          }
        } catch (error) {
          notificationService.removePersistentNotification(notificationId);
          notificationService.notify(
            `Failed to load shared ${itemType}`,
            'error'
          );
          return;
        }
      })();

      return;
    }

    if (isNil(queryDataType)) return;

    (async () => {
      if (isAccessDenied) {
        notificationService.notify(
          'Unauthorized URL: upgrade your account to access all data',
          'error'
        );
        return;
      }

      /**
       * Fetch workspace item and map data items.
       */
      const { workspaceItem, mapDataItems, mapping } = await (async () => {
        const pnWorkspaceItem = pnWorkspaceItems.find(
          ({ id }) => id === queryDataType
        );

        if (!isNil(pnWorkspaceItem)) {
          return {
            workspaceItem: pnWorkspaceItem,
            mapDataItems: [],
            mapping: await dynamicDataProvider(
              pnWorkspaceItem.dataSource
            ).getDataMapping(pnWorkspaceItem),
          };
        }

        const notificationId =
          notificationService.displayPersistentNotification(
            'Loading layer...',
            { showSpinner: true }
          );

        const workspaceItem = await getLayerWorkspaceItemById(queryDataType);
        if (isNil(workspaceItem)) {
          notificationService.removePersistentNotification(notificationId);

          return {
            workspaceItem: undefined,
            mapDataItems: [],
            mapping: [],
          };
        }

        workspaceActions().add(workspaceItem);

        const { mapData, mapping } =
          await visualizeWorkspaceItem(workspaceItem);

        notificationService.removePersistentNotification(notificationId);

        return {
          workspaceItem,
          mapDataItems: mapData.data,
          mapping,
        };
      })();

      if (isNil(workspaceItem)) {
        return notificationService.notify(
          'Invalid layer ID in the URL',
          'error'
        );
      }

      /**
       * Process URL filters.
       */
      if (!isNil(queryF) && !isNil(user)) {
        const { filters, filtersLinkOperator } = JSON.parse(queryF);

        const newItem = createWorkspaceItem(
          {
            source: 'item',
            sourceItem: workspaceItem,
            temporarySourceItemId: workspaceItem.id,
            name: `${formatDataType(workspaceItem.dataType, { case: 'sentence' })} from URL`,
            extraStyle: {
              color: DEFAULT_LIST_COLOR,
            },
            queryOptions: {
              filters,
              filtersLinkOperator,
            },
          },
          user
        );

        workspaceActions().add(newItem);
        workspaceActions().addToWorkspace(newItem.id);

        log.info('visualize URL filtered workspace item', newItem);
        const { mapData } = await visualizeWorkspaceItem(newItem);

        centerMapOnDataItems(mapData.data);

        workspaceActions().select(newItem.id);

        return;
      }

      /**
       * Add workspace item to the workspace and select data item if requested.
       */
      if (!isNil(queryId)) {
        const idMappingItem = mapping.find((m) => m.field === 'internal_id');
        const idDomainType = idMappingItem?.domainType ?? 'string';

        selectDataItem({
          id: idDomainType === 'string' ? queryId : parseInt(queryId, 10),
          reason: DataSelectionReason.Url,
          workspaceItem,
          pageSize,
        });
      } else {
        centerMapOnDataItems(mapDataItems);
      }

      workspaceActions().addToWorkspace(workspaceItem.id);
      workspaceActions().select(workspaceItem.id);
    })();
  }, [
    isInitialUrl,
    isAccessDenied,
    user,
    pathname,
    queryDataType,
    queryId,
    queryItemId,
    queryProjectId,
    queryF,
    pageSize,
    visualizeWorkspaceItem,
  ]);
}

async function getLayerWorkspaceItemById(
  dataType: string
): Promise<WorkspaceItem | undefined> {
  try {
    return await apiLayerProvider.getLayerWorkspaceItemById(dataType);
  } catch (error) {
    handleError({ error });
    return undefined;
  }
}

import {
  Box,
  FormControlLabel,
  Popover,
  Switch,
  Typography,
} from '@mui/material';

type Props = {
  anchorEl: HTMLElement | undefined;
  setAnchorEl: (value: HTMLElement | undefined) => void;
  showHeaderFilters: boolean;
  setShowHeaderFilters: (show: boolean) => void;
};

export const PreferencesPopover = ({
  anchorEl,
  setAnchorEl,
  showHeaderFilters,
  setShowHeaderFilters,
}: Props) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => setAnchorEl(undefined)}
    >
      <Box display="flex" flexDirection="column" gap={2} p={2} maxWidth={300}>
        <Typography color="textSecondary">
          All preferences will be stored in your browser.
        </Typography>
        <FormControlLabel
          label="Header filters"
          control={
            <Switch
              checked={showHeaderFilters}
              onChange={(event) => setShowHeaderFilters(event.target.checked)}
            />
          }
        />
      </Box>
    </Popover>
  );
};

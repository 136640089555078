import type { DataItemId } from '@pn/core/domain/data';
import { isCordova } from '@pn/core/utils/env';
import { isNil } from 'lodash-es';

export function getMainPanelUri(params: {
  dataType: string;
  dataItemId: DataItemId | undefined;
  subpanel?: string;
}): string {
  const { dataType, dataItemId, subpanel } = params;

  if (isNil(dataItemId)) {
    return '/';
  } else if (isNil(subpanel)) {
    return `/${dataType}/${dataItemId}`;
  } else {
    return `/${dataType}/${dataItemId}/${subpanel}`;
  }
}

export function openInNewTab(url: string): void {
  if (isCordova()) {
    window.cordova.InAppBrowser.open(url, '_system');
  } else {
    window.open(url, '_blank');
  }
}

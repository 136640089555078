import { dependencies } from '@pn/core/dependencies';
import type { DataItemId } from '@pn/core/domain/data';
import { getCenterPoint } from '@pn/core/domain/geography';
import { DataSelectionReason } from '@pn/core/domain/query';
import { selectDataItem } from '@pn/core/operations/dataSelection';
import { useAppStorage, useWorkspaceStorage } from '@pn/core/storage';
import assert from 'assert';

export function useTableClicksProcessor() {
  const { pageSize } = useAppStorage();
  const { workspaceItemSelected, dataItemSelected } = useWorkspaceStorage();

  const processTableClick = (dataItemId: DataItemId) => {
    const { map } = dependencies;

    assert(workspaceItemSelected, 'workspaceItemSelected is undefined');
    selectDataItem({
      id: dataItemId,
      reason: DataSelectionReason.TableClick,
      workspaceItem: workspaceItemSelected,
      pageSize,
    });

    /* Repeated clicks will move the map */
    if (dataItemId === dataItemSelected?._id && dataItemSelected?.geoShape) {
      map.moveTo(getCenterPoint(dataItemSelected.geoShape));
    }
  };

  return { processTableClick };
}

import type { IEventLogger } from '@pn/core/services/loggers/ports';
import { pnApiClient } from '@pn/services/api/pnApiClient';

export const pnApiEventLogger: IEventLogger = {
  logEvent: (event) => {
    pnApiClient.request({
      method: 'POST',
      url: 'v2/log_event',
      payload: {
        mixpanel_event: 'UI: ' + event.type,
      },
    });
  },
};

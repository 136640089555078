import { MapMode } from '@pn/core/domain/map';
import { clone, isNil, set } from 'lodash-es';
import type { MapboxIMap } from '..';
import { darkMapboxLayers } from '../style/darkMapboxLayers';
import { lightMapboxLayers } from '../style/lightMapboxLayers';
import { standardMapboxLayers } from '../style/standardMapboxLayers';
import { grids } from '@pn/services/map/mapbox/style/pn/grids';

export function updateMode(this: MapboxIMap, newMode: MapMode): void {
  const style = this._native.getStyle();

  const updatedStyle = updateStyleLayers(style, newMode);

  this._native.setStyle(updatedStyle);
}

export function updateStyleLayers(
  style: mapboxgl.Style,
  mapMode: MapMode
): mapboxgl.Style {
  let newStyleLayers: mapboxgl.AnyLayer[] = [];
  let satellite = false;

  switch (mapMode) {
    case MapMode.Standard: {
      newStyleLayers = standardMapboxLayers;
      break;
    }
    case MapMode.Light: {
      newStyleLayers = lightMapboxLayers;
      break;
    }
    case MapMode.Dark: {
      newStyleLayers = darkMapboxLayers;
      break;
    }
    case MapMode.Satellite: {
      satellite = true;
      break;
    }
    default:
      throw new Error('Invalid map mode');
  }

  const mode = [MapMode.Standard, MapMode.Light].includes(mapMode)
    ? 'light'
    : 'dark';

  const gridLayers = new Map<string, mapboxgl.Layer>(
    grids(mode).map((layer) => [layer.id, layer as mapboxgl.Layer])
  );

  const updatedStyle = clone(style);

  for (let i = updatedStyle.layers.length - 1; i >= 0; i--) {
    const layer = updatedStyle.layers[i] as mapboxgl.Layer;

    if (gridLayers.has(layer.id)) {
      set(layer, 'paint', gridLayers.get(layer.id)!.paint);
    }

    if (layer.id === 'mapbox-satellite') {
      set(layer, 'layout.visibility', satellite ? 'visible' : 'none');
    }

    if (!isNil(layer.metadata) && layer.metadata['pn:built-in']) {
      updatedStyle.layers.splice(i, 1);
    }
  }

  updatedStyle.layers.unshift(...newStyleLayers);

  return updatedStyle;
}

import { Buffer } from 'buffer';
import pako from 'pako';

export function compressAndEncode<T>(param: T): string {
  const compressed = pako.deflate(JSON.stringify(param));

  const base64 = Buffer.from(compressed).toString('base64');

  /**
   * Make the result URL-safe by replacing '+' with '-', '/' with '_',
   * and by stripping '='
   */
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export function decodeAndDecompress<T>(encodedString: string): T {
  const base64 = encodedString.replace(/-/g, '+').replace(/_/g, '/');

  const compressed = Buffer.from(base64, 'base64');
  const decompressed = pako.inflate(compressed, { to: 'string' }); // can throw a string (stupid library)

  return JSON.parse(decompressed);
}

import { TablePagination } from '@mui/material';
import {
  GridSlotProps,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

export function CustomPagination({
  onPageChange,
}: GridSlotProps['pagination']) {
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  return (
    <TablePagination
      component="div"
      showFirstButton
      count={rowCount}
      page={page}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[]}
      onPageChange={(event, page) => onPageChange!(event, page)}
      labelDisplayedRows={(paginationInfo) =>
        `${paginationInfo.from.toLocaleString()}-${paginationInfo.to.toLocaleString()} of ${paginationInfo.count.toLocaleString()}`
      }
    />
  );
}

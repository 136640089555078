import type { Project } from '@pn/core/domain/project';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { stackApiClient } from '@pn/services/api/stackApiClient';
import { isNil } from 'lodash-es';
import { apiStackProjectMapper } from './apiStackProjectMapper';
import { apiStackWorkspaceItemToWorkspaceItemMapper } from './apiStackWorkspaceItemMapper';
import type { StackProject } from './types';

export async function getStackProjects(): Promise<StackProject[]> {
  return stackApiClient.request<StackProject[]>({
    method: 'POST',
    url: 'PetroNinjaUserApi/Projects/Search',
    payload: {},
  });
}

export function mapStackProjects(params: {
  stackProjects: StackProject[];
  sourceWorkspaceItems: WorkspaceItem[];
}): { workspaceItems: WorkspaceItem[]; projects: Project[] } {
  const { stackProjects, sourceWorkspaceItems } = params;

  const workspaceItems: WorkspaceItem[] = [];
  const projects: Project[] = [];

  stackProjects.forEach((stackProject) => {
    projects.push(apiStackProjectMapper.toDomainProject(stackProject));

    stackProject.lists.forEach((list) => {
      if (list.count === 0) return;

      const sourceItem = sourceWorkspaceItems.find(
        (item) => item.id === list.sourceLayerId
      );
      if (isNil(sourceItem)) {
        console.error(
          `sourceLayerId [${list.sourceLayerId}] does not match any layers`
        );
        return;
      }

      workspaceItems.push(
        apiStackWorkspaceItemToWorkspaceItemMapper().toWorkspaceItem(
          stackProject,
          sourceItem
        )
      );
    });
  });

  return { workspaceItems, projects };
}

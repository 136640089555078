import { nanoid } from 'nanoid';

export function generateId() {
  return nanoid();
}

const TEMPORARY_ID_PREFIX = 'tmp_';

export function generateTemporaryId() {
  return TEMPORARY_ID_PREFIX + generateId();
}

export function isTemporaryId(id: string | number) {
  return id.toString().startsWith(TEMPORARY_ID_PREFIX);
}

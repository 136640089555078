import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ProductionChartMode } from '@pn/services/charts/types';
import producingDailyFormula from '@pn/ui/images/producing_daily_formula.webp';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  accordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  accordionDetails: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  inlineContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    whiteSpace: 'nowrap',
  },
}));

type Props = {
  accordion?: boolean;
  disabled: boolean;
  mode: ProductionChartMode;
  onChange: (newMode: ProductionChartMode) => void;
};

export function ChartModeSelector({
  accordion = false,
  disabled,
  mode,
  onChange,
}: Props) {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const form = (
    <FormControl disabled={disabled}>
      <RadioGroup
        name="chart-mode-selector"
        value={mode}
        onChange={(_event, newMode) => onChange(newMode as ProductionChartMode)}
      >
        <FormControlLabel
          value={ProductionChartMode.ProducingDaily}
          control={<Radio />}
          label={
            <span className={classes.inlineContainer}>
              Producing Daily{' '}
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <InfoIcon color="action" />
              </IconButton>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                <Box maxWidth={480} p={2}>
                  <Typography variant="body2" color="textSecondary">
                    Producing Daily rate is the production divided by the number
                    of days the well actually produced that month.
                  </Typography>
                  <Box mt={1} />
                  <img
                    width="100%"
                    src={producingDailyFormula}
                    alt="Producing Daily formula"
                  />
                </Box>
              </Popover>
            </span>
          }
        />
        <FormControlLabel
          value={ProductionChartMode.CalendarDaily}
          control={<Radio />}
          label="Calendar Daily"
        />
        <FormControlLabel
          value={ProductionChartMode.Monthly}
          control={<Radio />}
          label="Monthly"
        />
        <FormControlLabel
          value={ProductionChartMode.Cumulative}
          control={<Radio />}
          label="Cumulative"
        />
      </RadioGroup>
    </FormControl>
  );

  if (accordion) {
    return (
      <Paper variant="outlined">
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Production mode</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {form}
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  } else {
    return (
      <Paper component={Box} variant="outlined" px={2} py={1}>
        {form}
      </Paper>
    );
  }
}

import type { Enterprise } from '@pn/core/domain/enterprise';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  isError: false,
  enterprise: undefined as Enterprise | undefined,
};

export const createEnterpriseSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }) => {
        state.isFetching = false;
        state.enterprise = payload;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
    },
  });

export const currentEnterpriseSlice =
  createEnterpriseSlice('currentEnterprise');

import { useRouter } from '@pn/core/operations/router';
import { useAuthenticationService } from 'src/application/externalDependencies';
import { CustomLink } from 'src/ui/components/CustomLink';

type Props = {
  children: React.ReactNode;
  type: string;
};

export function AuthLink({ children, type, ...rest }: Props) {
  const { pathname } = useRouter();
  const { login } = useAuthenticationService();

  switch (type) {
    case 'login':
      return (
        <CustomLink {...rest} onClick={() => login({ returnTo: pathname })}>
          {children}
        </CustomLink>
      );
    case 'signup':
      return (
        <CustomLink
          {...rest}
          onClick={() => login({ type: 'signup', returnTo: pathname })}
        >
          {children}
        </CustomLink>
      );
    default:
      throw new Error('Invalid AuthLink type');
  }
}

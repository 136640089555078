import { colors } from '@mui/material';
import type {
  ColorPaletteGenerator,
  GradientColorPalette,
} from '@pn/core/services/color/ports';
import { drop } from 'lodash-es';

const muiColorPaletteGenerator: ColorPaletteGenerator = () => ({
  defaultColor: colors.grey[500],
  colors: [
    colors.blue[500],
    colors.lightBlue[500],
    colors.cyan[500],
    colors.teal[500],
    colors.green[500],
    colors.lightGreen[500],
    colors.lime[500],
    colors.amber[500],
    colors.orange[500],
    colors.deepOrange[500],
    colors.red[500],
    colors.pink[500],
    colors.purple[500],
    colors.deepPurple[500],
    colors.indigo[500],
  ],
  colorsStaggered: [
    colors.blue[500],
    colors.red[500],
    colors.green[500],
    colors.purple[500],
    colors.orange[500],
    colors.lightBlue[500],
    colors.pink[500],
    colors.lime[500],
    colors.deepPurple[500],
    colors.lightGreen[500],
    colors.amber[500],
    colors.indigo[500],
    colors.cyan[500],
    colors.deepOrange[500],
    colors.teal[500],
  ],
  getColorByIndex: function (index) {
    return this.colors[index] ?? this.defaultColor;
  },
  getStaggeredColorByIndex: function (index) {
    return this.colorsStaggered[index] ?? this.defaultColor;
  },
  getMultiSelectionColor: function () {
    return this.colorsStaggered[0];
  },
  getFilterColor: function (usedColors) {
    const unusedColors = drop(this.colorsStaggered, 1).filter(
      (color) => !usedColors.includes(color)
    );
    return unusedColors[0] ?? this.defaultColor;
  },
});

export const muiColorPalette = muiColorPaletteGenerator();
export const muiGradientColorPalettes: GradientColorPalette[] = [
  {
    colorLower: colors.purple[200],
    colorUpper: colors.deepPurple[900],
  },
  {
    colorLower: colors.lightBlue[200],
    colorUpper: colors.blue[900],
  },
  {
    colorLower: colors.lightGreen[200],
    colorUpper: colors.green[900],
  },
  {
    colorLower: colors.yellow[200],
    colorUpper: colors.amber[900],
  },
  {
    colorLower: colors.orange[100],
    colorUpper: colors.deepOrange[900],
  },
  {
    colorLower: colors.red[100],
    colorUpper: colors.pink[900],
  },
  {
    colorLower: colors.blueGrey[200],
    colorUpper: colors.blueGrey[900],
  },
];

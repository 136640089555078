import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import type { ISearchActions, ISearchStorage } from './ports';
import { searchSlice } from './searchSlice';

export const useReduxSearchStorage = (): ISearchStorage => {
  const { name, getInitialState } = searchSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isError: useSliceSelector((state) => state.isError),
    isFetching: useSliceSelector((state) => state.isFetching),
    searchResults: useSliceSelector((state) => state.searchResults),
  };
};

export const reduxSearchActions = (): ISearchActions => {
  const { dispatch } = dependencies.store;
  const { actions } = searchSlice;

  return {
    request: () => dispatch(actions.request()),
    receive: (results) => dispatch(actions.receive(results)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
  };
};

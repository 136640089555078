export function getMinDate(dates: string[]): string {
  // convert YYYY-MM-DD date to YYYY-01-01
  return dates[0].slice(0, 4) + '-01-01';
}

export function getMaxDate(dates: string[]): string {
  // convert YYYY-MM-DD date to (YYYY+1)-01-01
  return (
    (Number(dates[dates.length - 1].slice(0, 4)) + 1).toString() + '-01-01'
  );
}

import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

type ContextProviderProps = {
  children: React.ReactNode;
};

type ContextType = {
  open: boolean;
  content: any[];
  handleOpen: (features: any[]) => void;
  handleClose: () => void;
};

const ExternalPanelStateContext = React.createContext<ContextType>({
  open: false,
  content: [],
  handleOpen: () => {},
  handleClose: () => {},
});

export const useExternalPanel = () => {
  return React.useContext(ExternalPanelStateContext);
};

export const ExternalPanelStateProvider = ({
  children,
}: ContextProviderProps) => {
  const [isExternalPanelOpen, setIsExternalPanelOpen] = React.useState(false);
  const [content, setContent] = React.useState<any[]>([]);

  const handleOpen = (features: any[]) => {
    setContent(features);
    setIsExternalPanelOpen(true);
  };

  const handleClose = () => {
    setIsExternalPanelOpen(false);
  };

  useHotkeys('esc', handleClose);

  return (
    <ExternalPanelStateContext.Provider
      value={{
        open: isExternalPanelOpen,
        content,
        handleOpen,
        handleClose,
      }}
    >
      {children}
    </ExternalPanelStateContext.Provider>
  );
};

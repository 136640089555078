import {
  isNonStreamableItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { workspaceActions } from '@pn/core/storage';

export function unselectDataItem(item: WorkspaceItem): void {
  if (isNonStreamableItem(item)) {
    workspaceActions().updatePage(item.id, 0);
    workspaceActions().updateRequestedIds(item.id, {
      ids: [],
      reason: undefined,
    });
  }

  workspaceActions().unsetRequestedDataItemId(item.id);
}

import type { IZonalQueryMapper } from '@pn/core/mappers/zone';
import type { ApiZonalQuery } from './types';

export const apiZonalQueryMapper: IZonalQueryMapper<ApiZonalQuery> = {
  toDomainZonalQuery: (sourceZonalQuery) => {
    return {
      substance: sourceZonalQuery.substance,
      upper: {
        entity: apiEntityToEntity(sourceZonalQuery.upper.entity),
        boundary: sourceZonalQuery.upper.boundary,
      },
      lower: {
        entity: apiEntityToEntity(sourceZonalQuery.lower.entity),
        boundary: sourceZonalQuery.lower.boundary,
      },
      excluded: sourceZonalQuery.excluded,
    };
  },
  toTargetZonalQuery: (zonalQuery) => {
    return {
      substance: zonalQuery.substance,
      upper: {
        entity: entityToApiEntity(zonalQuery.upper),
        boundary: zonalQuery.upper.boundary,
      },
      lower: {
        entity: entityToApiEntity(zonalQuery.lower),
        boundary: zonalQuery.lower.boundary,
      },
      excluded: zonalQuery.excluded,
    };
  },
};

function apiEntityToEntity(apiEntity: string): string {
  switch (apiEntity) {
    case 'SURFACE':
    case 'BASEMENT':
      return '';
    default:
      return apiEntity;
  }
}

function entityToApiEntity(params: {
  entity: string;
  boundary: 'top' | 'base';
}): string {
  if (params.entity === '') {
    return params.boundary === 'top' ? 'SURFACE' : 'BASEMENT';
  }
  return params.entity;
}

import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { workspaceActions } from '@pn/core/storage';
import { isUS } from '@pn/core/utils/env';
import { useDrawing } from '@pn/services/drawing';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { useProjectState } from 'src/ui/workspace/project-controls/ProjectStateProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  button: {
    minWidth: 40,
    height: 40,
    padding: 0,
  },
}));

export const ResetButton = () => {
  const { classes } = useStyles();

  const { handleResetProject } = useProjectState();
  const { reset: resetDrawings, redraw } = useDrawing();
  const { isDrawingPanelOpen, closeWorkspaceItemPanel } =
    useWorkspaceItemPanel();
  const { handleOpenConfirmationDialog } = useConfirmationDialog();

  const handleReset = () => {
    if (isDrawingPanelOpen) {
      handleOpenConfirmationDialog({
        title: 'Reset annotation editor?',
        text: 'All unsaved changes will be lost.',
        action: () => reset(),
      });
    } else {
      reset();
    }

    function reset() {
      closeWorkspaceItemPanel();

      resetDrawings(); // reset extra selections & measurements
      redraw();

      const defaultLayerId = isUS() ? 'wells_usa' : 'wells';

      workspaceActions().resetInternalState();
      workspaceActions().updatePage(defaultLayerId, 0);
      workspaceActions().updateRequestedIds(defaultLayerId, {
        ids: [],
        reason: undefined,
      });

      handleResetProject();
    }
  };

  return (
    <Button
      variant="contained"
      color="inherit"
      className={classes.button}
      title="Reset workspace"
      onClick={handleReset}
    >
      <CloseIcon sx={{ fontSize: 20 }} />
    </Button>
  );
};

import { stackApiClient } from '@pn/services/api/stackApiClient';
import type { StackProjectWithListIds } from './types';
import { cachePolicy } from '@pn/core/cachePolicy';

const cache = new Map<string, StackProjectWithListIds>();

export async function getStackProjectDetails(
  stackProjectId: string
): Promise<StackProjectWithListIds> {
  if (cachePolicy.stackProjectDetails && cache.has(stackProjectId)) {
    return cache.get(stackProjectId)!;
  }

  const details = await stackApiClient.request<StackProjectWithListIds>({
    url: `PetroNinjaUserApi/Projects/${stackProjectId}`,
  });

  if (cachePolicy.stackProjectDetails) cache.set(stackProjectId, details);

  return details;
}

import { useProjectsStorage, useWorkspaceStorage } from '@pn/core/storage';
import { arraysAreIdentical } from '@pn/core/utils/logic';
import React from 'react';

export function useTrackProjectUpdates() {
  const { workspaceItems } = useWorkspaceStorage();
  const { selectedProject } = useProjectsStorage();

  const nonTemporaryIds = React.useMemo(
    () => workspaceItems.filter((i) => !i.isTemporary).map((i) => i.id),
    [workspaceItems]
  );

  const isSaved = arraysAreIdentical(
    nonTemporaryIds,
    selectedProject.workspaceItemIds
  );

  const [lastProjectIdUpdated, setLastProjectIdUpdated] = React.useState<
    string | undefined
  >();

  React.useLayoutEffect(() => {
    if (!isSaved) setLastProjectIdUpdated(selectedProject.id);
  }, [isSaved, selectedProject.id]);

  const isInit = lastProjectIdUpdated !== selectedProject.id; // when true, the project has not been changed or saved yet

  return { isInit, isSaved, nonTemporaryIds };
}

import { Badge, type BadgeProps } from '@mui/material';
import { blendColors } from '@pn/services/utils/color';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ selected: boolean }>()(
  (theme, { selected }) => ({
    badge: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: `0 0 0 3px ${
        selected
          ? blendColors(
              theme.palette.primary.main,
              0.2,
              theme.palette.background.paper
            )
          : theme.palette.background.paper
      }`,
    },
  })
);
export const StyledBadge = ({
  selected,
  ...props
}: BadgeProps & { selected: boolean }) => {
  const { classes } = useStyles({ selected });

  return <Badge classes={classes} {...props} />;
};

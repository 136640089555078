import { lowerCase } from 'lodash-es';
import { compareTwoStrings } from 'string-similarity';
import titlecase from 'titlecase';

const DEFAULT_TOLERANCE = 0.6;

export function isCloseMatch(
  queryString: string,
  comparisonString: string,
  tolerance = DEFAULT_TOLERANCE
) {
  const lowerCaseQueryStr = lowerCase(queryString);
  const lowerCaseCompareStr = lowerCase(comparisonString);

  const queryStringIsSubsetOfComparisonString =
    lowerCaseQueryStr.search(lowerCaseCompareStr) !== -1;
  if (queryStringIsSubsetOfComparisonString) return true;

  if (tolerance >= 0 && tolerance <= 1) {
    const queryStringIsSimilarToComparisonString =
      compareTwoStrings(lowerCaseQueryStr, lowerCaseCompareStr) > tolerance;
    if (queryStringIsSimilarToComparisonString) return true;
  } else {
    throw new Error(
      'isCloseMatch error! Tolerance value must be a decimal between 0 and 1 inclusive.'
    );
  }

  return false;
}

/**
 * Capitalizes the first letter of each word with a few important exceptions:
 * https://johnresig.com/blog/title-capitalization-in-javascript/
 */
export function smartTitlecase(str: string): string {
  return titlecase(str);
}

import ClearIcon from '@mui/icons-material/Clear';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  DataGridPro,
  useGridApiRef,
  type GridCellParams,
  type GridFilterModel,
  type GridPaginationModel,
  type GridRowParams,
  type GridRowSelectionModel,
  type GridSortModel,
} from '@mui/x-data-grid-pro';
import { dependencies } from '@pn/core/dependencies';
import { areFiltersApplied } from '@pn/core/domain/query';
import { type WorkspaceItem } from '@pn/core/domain/workspace';
import {
  useTableClicksProcessor,
  useTableFields,
  type TableFields,
} from '@pn/core/operations/dataTableInteractions';
import { useFilterWorkspaceItem } from '@pn/core/operations/workspace';
import { useAccess } from '@pn/core/permissions/access';
import {
  appActions,
  useAppStorage,
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import { useWebStorageState } from '@pn/core/utils/hooks';
import { dataGridQueryFilteringMapper } from '@pn/services/local/query/dataGridQueryFilteringMapper';
import { dataGridQuerySortingMapper } from '@pn/services/local/query/dataGridQuerySortingMapper';
import assert from 'assert';
import { isNil } from 'lodash-es';
import React from 'react';
import { useStyles } from './DataTable.styles';
import { CustomColumnMenu } from './components/CustomColumnMenu';
import { CustomFooter } from './components/CustomFooter';
import { CustomNoRowsOverlay } from './components/CustomNoRowsOverlay';
import { CustomPagination } from './components/CustomTablePagination';
import {
  CustomTableToolbar,
  ToolbarProps,
} from './components/CustomTableToolbar';
import { useColumns } from './hooks/useColumns';
import { useRows } from './hooks/useRows';
import { useTableControlsDisabled } from './hooks/useTableControlsDisabled';
import { useTableModels } from './hooks/useTableModels';
import { getLocaleText } from './localeText';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    isToolbarDisabled: boolean;
    showHeaderFilters: boolean;
    setShowHeaderFilters: (show: boolean) => void;
  }
  interface NoRowsOverlayPropsOverrides {
    areFiltersApplied: boolean;
    overlayText: string;
    icon: React.ComponentType;
  }
}

type Props = {
  checkboxSelection?: boolean;
  enableRowClicks?: boolean;
  tableOnly?: boolean;
  toolbarProps?: ToolbarProps;
  cellClassName?: (params: GridCellParams) => string;
  useFields?: (sourceLayerId: string, initFields: string[]) => TableFields;
  initFields?: string[];
};

export const DataTable = ({
  checkboxSelection = false,
  enableRowClicks = false,
  tableOnly = false,
  toolbarProps,
  cellClassName,
  useFields = useTableFields,
  initFields = [],
}: Props) => {
  const apiRef = useGridApiRef();

  const [showHeaderFilters, setShowHeaderFilters] = useWebStorageState(
    'data-table-show-header-filters',
    true
  );

  const { classes } = useStyles({ enableRowClicks, showHeaderFilters });

  const access = useAccess();
  const { unitSystem, pageSize } = useAppStorage();
  const { workspaceItemSelected, workspaceItems, dataItemSelected } =
    useWorkspaceStorage();
  const { user } = useCurrentUserStorage();

  const { useAuthenticationService } = dependencies;
  const { isAuthenticating } = useAuthenticationService();
  const { processTableClick } = useTableClicksProcessor();
  const { fields, updateFields } = useFields(
    workspaceItemSelected?.dataType ?? '',
    initFields
  );

  const isTableLoading =
    !isNil(workspaceItemSelected) &&
    workspaceItemSelected.isVisible &&
    !workspaceItemSelected.isRendered;

  const areTableControlsDisabled = useTableControlsDisabled({
    isAuthenticating,
    isFetchingCurrentUser: isNil(user),
    isTableLoading,
    isMappingInitialized: workspaceItemSelected?.isMappingInitialized ?? false,
  });

  const rows = useRows({
    dataItems: workspaceItemSelected?.tableDataItems ?? [],
  });
  const columns = useColumns({
    mapping: workspaceItemSelected?.mapping ?? [],
    fields,
    unitSystem,
    cellClassName,
  });

  const { sortModel, filterModel, columnVisibilityModel, selectionModel } =
    useTableModels({
      columns,
      sorts: workspaceItemSelected?.query.sorts ?? [],
      filters: workspaceItemSelected?.query.filters ?? [],
      filtersLinkOperator:
        workspaceItemSelected?.query.filtersLinkOperator ?? 'and',
      fields,
      checkedIds: workspaceItemSelected?.query.checkedIds ?? [],
      dataItemSelected,
      checkboxSelection,
    });

  const { applyFilters } = useFilterWorkspaceItem(tableOnly);

  const handlePageChange = (_event: unknown, newPage: number) => {
    if (!isEmbedded() && access('dataTable.pagination').notify().denied())
      return;

    assert(
      workspaceItemSelected,
      'workspaceItemSelected is required to change page'
    );

    workspaceActions().updatePage(workspaceItemSelected.id, newPage);
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (!isEmbedded() && access('dataTable.sorting').notify().denied()) return;
    if (!workspaceItemSelected?.isMappingInitialized) return;

    const newSorts = dataGridQuerySortingMapper.toDomainSorting(sortModel);
    workspaceActions().updateSorting(workspaceItemSelected?.id, newSorts);
  };

  const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
    /**
     * Allow free users to open filter panel but not use it.
     */
    const areAllFiltersEmpty = newFilterModel.items.every(
      (i) => isNil(i.value) || i.value === ''
    );
    if (
      !isEmbedded() &&
      !areAllFiltersEmpty &&
      access('dataTable.filtering').notify().denied()
    ) {
      return;
    }
    if (!workspaceItemSelected?.isMappingInitialized) return;

    const { filters: newFilters, linkOperator: newLinkOperator } =
      dataGridQueryFilteringMapper.toDomainFiltering(
        newFilterModel,
        columns,
        workspaceItemSelected.mapping,
        unitSystem
      );

    applyFilters(newFilters, newLinkOperator, workspaceItemSelected);
  };

  const handleSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    assert(
      workspaceItemSelected,
      'workspaceItemSelected is required to change selection'
    );

    workspaceActions().setCheckedIds(
      workspaceItemSelected.id,
      selectionModel as string[]
    );
  };

  const handleColumnOrderChange = () => {
    const fieldsOrdered = apiRef.current
      .getVisibleColumns()
      .map((column) => column.field);

    updateFields(fieldsOrdered, { debounced: true });
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    if (model.pageSize === 0) return;

    const normalizedPageSize = Math.max(1, model.pageSize);
    if (pageSize === normalizedPageSize) return;

    appActions().updatePageSize(normalizedPageSize);
    workspaceItems
      .filter(({ itemType }) => !['annotation', 'drawing'].includes(itemType))
      .forEach(({ id }) => workspaceActions().updatePage(id, 0));
  };

  const handleRowClick = (params: GridRowParams) => {
    if (!enableRowClicks) return;

    processTableClick(params.id as string);
  };

  return (
    <DataGridPro
      apiRef={apiRef}
      className={classes.root}
      rowHeight={38}
      headerFilterHeight={38}
      filterDebounceMs={500}
      hideFooterSelectedRowCount={!checkboxSelection}
      disableDensitySelector
      disableMultipleColumnsSorting
      disableMultipleRowSelection={!checkboxSelection}
      disableRowSelectionOnClick={!enableRowClicks}
      disableColumnFilter={isEmbedded()}
      pagination
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      headerFilters={showHeaderFilters && !isEmbedded()}
      loading={isTableLoading}
      rows={rows}
      columns={columns}
      autoPageSize
      paginationModel={{
        page: workspaceItemSelected?.query.page ?? 0,
        pageSize,
      }}
      rowCount={getRowCount(workspaceItemSelected)}
      sortModel={sortModel}
      filterModel={filterModel}
      columnVisibilityModel={columnVisibilityModel}
      checkboxSelection={checkboxSelection}
      keepNonExistentRowsSelected
      rowSelectionModel={selectionModel}
      onSortModelChange={handleSortModelChange}
      onFilterModelChange={handleFilterModelChange}
      onRowSelectionModelChange={handleSelectionModelChange}
      onColumnOrderChange={handleColumnOrderChange}
      onPaginationModelChange={handlePaginationModelChange}
      onRowClick={handleRowClick}
      localeText={getLocaleText(workspaceItemSelected?.dataType ?? '')}
      slots={{
        toolbar: CustomTableToolbar,
        pagination: CustomPagination,
        footer: CustomFooter,
        columnMenu: CustomColumnMenu,
        noRowsOverlay: CustomNoRowsOverlay,
        filterPanelRemoveAllIcon: ClearIcon,
      }}
      slotProps={{
        basePopper: {
          placement: 'auto-end', // prevents the Popper from extending the window when multiple filters are applied
        },
        toolbar: {
          isToolbarDisabled: areTableControlsDisabled,
          showHeaderFilters,
          setShowHeaderFilters,
          ...toolbarProps,
        },
        pagination: {
          onPageChange: handlePageChange,
        },
        noRowsOverlay: {
          areFiltersApplied: areFiltersApplied(
            workspaceItemSelected?.query.filters ?? []
          ),
          ...getNoRowsOverlayProps(workspaceItemSelected),
        },
        headerFilterCell: {
          InputComponentProps: {
            size: 'small',
            label: '',
          },
        },
      }}
    />
  );
};

function getRowCount(item: WorkspaceItem | undefined): number {
  if (isNil(item) || !item.isVisible) {
    return 0;
  } else {
    return item.totalCount;
  }
}

function getNoRowsOverlayProps(item: WorkspaceItem | undefined): {
  overlayText: string;
  icon: React.ComponentType;
} {
  if (isNil(item)) {
    return {
      overlayText: 'Select a layer to view its data',
      icon: LayersClearIcon,
    };
  } else if (item.dataSource.type === 'none') {
    return {
      overlayText: 'No data to display for this layer',
      icon: SearchOffIcon,
    };
  } else if (!item.isVisible) {
    return {
      overlayText: 'Selected layer is hidden',
      icon: VisibilityOffIcon,
    };
  } else {
    return {
      overlayText: 'No results found',
      icon: SearchOffIcon,
    };
  }
}

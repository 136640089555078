import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const unitsElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'Petro Ninja ID',
    folder: 'Unit Header',
    width: 200,
  },
  {
    field: 'agreement_id',
    sourceType: 'keyword',
    label: 'Agreement No.',
    folder: 'Unit Header',
    isShownByDefault: true,
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'Province',
    folder: 'Unit Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'unit_name',
    sourceType: 'text',
    label: 'Name',
    folder: 'Unit Header',
    isShownByDefault: true,
    width: 170,
  },
  {
    field: 'participants',
    sourceType: 'text',
    label: 'Participants',
    folder: 'Unit Header',
    isShownByDefault: true,
    width: 200,
  },
  {
    field: 'operator',
    sourceType: 'text',
    label: 'Operator',
    folder: 'Unit Header',
    isShownByDefault: true,
  },
  {
    field: 'unit_status',
    sourceType: 'keyword',
    label: 'Status',
    folder: 'Unit Header',
    isShownByDefault: true,
  },
  {
    field: 'unit_area',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'ha', // should not be converted
    },
    label: 'Area',
    folder: 'Unit Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'unit_effective_date',
    sourceType: 'date',
    label: 'Effective Date',
    folder: 'Unit Header',
    isShownByDefault: true,
  },
  {
    field: 'unit_execution_date',
    sourceType: 'date',
    label: 'Execution Date',
    folder: 'Unit Header',
    isShownByDefault: true,
  },
  {
    field: 'unit_termination_date',
    sourceType: 'date',
    label: 'Termination Date',
    folder: 'Unit Header',
    isShownByDefault: true,
  },
  {
    field: 'last_update_date',
    sourceType: 'date',
    label: 'Last Update Date',
    folder: 'Unit Header',
    isShownByDefault: true,
  },
  /* Not renderable */
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, IconButton, Select } from '@mui/material';
import { useCurrentUserStorage, useProjectsStorage } from '@pn/core/storage';
import { isSpecialProject } from '@pn/core/storage/workspace/defaultWorkspaces';
import { isFreeCordova } from '@pn/core/utils/env';
import { findOrThrow } from '@pn/core/utils/logic';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  select: {
    minWidth: 0, // prevent from stretching when long names are shown
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      paddingRight: '4px !important', // ???
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.borderColor,
      borderWidth: 1,
    },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary,
    },

    '&:hover': {
      '& #reset-button': {
        display: 'flex',
      },
    },
  },
  endAdornment: {
    display: 'flex',
  },
  adornmentButton: {
    width: 24,
    height: 24,
    padding: 2,
  },
  fadedText: {
    color: theme.palette.text.disabled,
  },
}));

export const ProjectSelector = () => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { projects, selectedProject, isFetching, isProjectLoaded } =
    useProjectsStorage();

  const { isLibraryOpen, openLibrary, closeLibrary, setLibraryRouter } =
    useLibrary();

  const isLoading = isFetching || !isProjectLoaded;

  const handleOpenLibrary = () => {
    setLibraryRouter({ section: 'projects', folder: 'Personal' });
    openLibrary();
  };

  return (
    <Select
      fullWidth
      size="small"
      className={classes.select}
      disabled={isLoading || isFreeCordova(user)}
      value={isSpecialProject(selectedProject) ? '' : selectedProject.id}
      displayEmpty
      renderValue={(value) => {
        if (value === '') {
          return (
            <span className={classes.fadedText}>
              {isFreeCordova(user) ? 'Your workspace' : 'Unsaved project'}
            </span>
          );
        }
        return findOrThrow(projects, (p) => p.id === value).name;
      }}
      endAdornment={
        isFreeCordova(user) ? null : isLoading ? (
          <Box className={classes.endAdornment} mr="4px">
            <CircularProgress size={18} />
          </Box>
        ) : (
          <Box className={classes.endAdornment}>
            {isLibraryOpen ? (
              <IconButton
                id="reset-button"
                className={classes.adornmentButton}
                title="Close"
                onClick={closeLibrary}
              >
                <CloseIcon sx={{ fontSize: 18 }} />
              </IconButton>
            ) : (
              <IconButton
                className={classes.adornmentButton}
                title="Open"
                onClick={handleOpenLibrary}
              >
                <ArrowDropDownIcon sx={{ fontSize: 24 }} />
              </IconButton>
            )}
          </Box>
        )
      }
      MenuProps={{
        slotProps: {
          root: {
            sx: {
              display: 'none', // HACK
            },
          },
        },
      }}
      onOpen={isLibraryOpen ? closeLibrary : handleOpenLibrary}
    />
  );
};

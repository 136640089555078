import { Box } from '@mui/material';
import { useCurrentUserStorage, useProjectsStorage } from '@pn/core/storage';
import { isFreeCordova } from '@pn/core/utils/env';
import { WORKSPACE_DRAWER_WIDTH } from 'src/ui/Main';
import { ProjectActionButton } from 'src/ui/workspace/project-controls/ProjectActionButton';
import { ProjectNameInput } from 'src/ui/workspace/project-controls/ProjectNameInput';
import { ProjectSelector } from 'src/ui/workspace/project-controls/ProjectSelector';
import {
  ProjectState,
  useProjectState,
} from 'src/ui/workspace/project-controls/ProjectStateProvider';
import { ResetButton } from 'src/ui/workspace/project-controls/ResetButton.';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    gridArea: 'workspace-controls',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: WORKSPACE_DRAWER_WIDTH,
    minHeight: 64,
    padding: `0 ${theme.spacing(1.5)}`,
  },
}));

export const ProjectControls = () => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { selectedProject } = useProjectsStorage();

  const { projectState } = useProjectState();

  if (isFreeCordova(user))
    return (
      <Box className={classes.container}>
        <ProjectSelector />
      </Box>
    );

  if (projectState === ProjectState.Selector) {
    return (
      <Box className={classes.container}>
        <ProjectSelector />
        <ProjectActionButton project={selectedProject} />
        <ResetButton />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <ProjectNameInput project={selectedProject} />
    </Box>
  );
};

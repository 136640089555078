import { createSlice } from '@reduxjs/toolkit';

type State<T> = {
  isFetching: boolean;
  isError: boolean;
  payload: T | undefined;
};

const createInitialState = <T>(): State<T> => ({
  isFetching: false,
  isError: false,
  payload: undefined,
});

export const createDataInfoSlice = <T>(sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState: createInitialState<T>(),
    reducers: {
      request: (state) => {
        state.isFetching = true;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      receive: (state, { payload }) => {
        state.isFetching = false;
        state.isError = false;
        state.payload = payload;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.payload = undefined;
      },
    },
  });

import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { isObject, isString } from 'lodash-es';

export * from './conversion';
export * from './format';
export * from './production';

export type SIUnit = {
  value: number | undefined;
  symbol: string;
};

export function toSIUnit(params: {
  value: number | undefined;
  symbol: string;
}): SIUnit {
  return params;
}

export function isSIUnit(arg: unknown): arg is SIUnit {
  return (
    isObject(arg) &&
    hasKey(arg, 'value') &&
    hasKeyWithType(arg, 'symbol', isString)
  );
}

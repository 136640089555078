import { isNil } from 'lodash-es';

type WebStorageService = {
  get: <T>(key: string) => T | undefined;
  set: (key: string, value: unknown) => void;
};

/**
 * @usage
 * ```ts
 * // In your app:
 * export const ls = webStorageService('app-name'); // put in externalDependencies.ts
 *
 * ls.set('example-key', 'some-value');
 * const value = ls.get<string>('example-key'); // not type-safe (add your own type guards if needed)
 *
 * // In non-app packages:
 * webStorageService('example').set('example-key', 'some-value');
 * const value = webStorageService('example').get<string>('example-key');
 * ```
 */
export const webStorageService = (namespace: string): WebStorageService => ({
  get: <T>(key: string) => {
    const item = localStorage.getItem(`${namespace}-${key}`);
    if (isNil(item)) return undefined;
    try {
      return JSON.parse(item) as T;
    } catch (error) {
      console.log('Error parsing JSON', error);
      return undefined;
    }
  },
  set: (key: string, value: unknown) => {
    localStorage.setItem(`${namespace}-${key}`, JSON.stringify(value));
  },
});

import type { GridValidRowModel } from '@mui/x-data-grid-pro';
import type { DataItem } from '@pn/core/domain/data';
import React from 'react';

export function useRows(params: {
  dataItems: DataItem[];
}): GridValidRowModel[] {
  return React.useMemo(() => {
    return params.dataItems.map((dataItem) => ({
      ...dataItem,
      id: dataItem._id, // DataGrid requires an `id` field
    }));
  }, [params.dataItems]);
}

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { AnnotationCircleDrawMode } from './draw-modes/AnnotationCircleDrawMode';
import { AnnotationLineDrawMode } from './draw-modes/AnnotationLineDrawMode';
import { AnnotationPolygonDrawMode } from './draw-modes/AnnotationPolygonDrawMode';
import { AnnotationTextDrawMode } from './draw-modes/AnnotationTextDrawMode';
import { drawStyles } from './drawStyles';

// TODO check out mapbox-gl-draw-circle plugin

export const draw = new MapboxDraw({
  defaultMode: 'simple_select',
  displayControlsDefault: false,
  touchEnabled: false,
  boxSelect: false,
  userProperties: true,
  modes: {
    ...MapboxDraw.modes,
    draw_annotation_line: AnnotationLineDrawMode,
    draw_annotation_polygon: AnnotationPolygonDrawMode,
    draw_annotation_circle: AnnotationCircleDrawMode,
    draw_annotation_text: AnnotationTextDrawMode,
    draw_annotation_area: AnnotationPolygonDrawMode, // different due to styling
  },
  styles: drawStyles,
});

// https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md#available-custom-modes

import MapboxDraw, { type DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import { length } from '@turf/turf';
import { isNil, isObject, last } from 'lodash-es';
import { toLocaleNumber } from './AnnotationPolygonDrawMode';

type CustomModeState = any;
type CustomModeOptions = {
  lineColor: string;
  lineWidth: number;
};

export const AnnotationLineDrawMode: DrawCustomMode<
  CustomModeState,
  CustomModeOptions
> = {
  ...MapboxDraw.modes.draw_line_string,
  onSetup: function (opts) {
    const props = MapboxDraw.modes.draw_line_string.onSetup!.call(this, opts);

    props.line.properties = {
      ...props.line.properties,
      ...opts,
    };

    return { ...props };
  },
  onKeyUp: function (state, event) {
    if (event.key === 'Enter')
      return this.changeMode('simple_select', { featureIds: [state.line.id] });
    if (event.key === 'Escape') return this.changeMode('simple_select');
  },
  toDisplayFeatures: function (state, geojson, display) {
    if (!('properties' in geojson) || isNil(geojson.properties)) return;

    const isActiveLine = geojson.properties.id === state.line.id;
    geojson.properties.active = isActiveLine.toString();
    if (!isActiveLine) return display(geojson);

    if (!isLineStringGeojsonFeature(geojson)) return; // type narrowing

    /* Only render the line if it has at least one real coordinate */
    if (geojson.geometry.coordinates.length < 2) return;
    geojson.properties.meta = 'feature';

    display(
      createVertex(
        state.line.id,
        geojson.geometry.coordinates[
          state.direction === 'forward'
            ? geojson.geometry.coordinates.length - 2
            : 1
        ],
        `${
          state.direction === 'forward'
            ? geojson.geometry.coordinates.length - 2
            : 1
        }`,
        false
      )
    );

    display(geojson);

    /* Create custom feature for the current pointer position */
    const displayMeasurements = getDisplayMeasurements(geojson);

    const currentVertex: GeoJSON.Feature = {
      type: 'Feature',
      properties: {
        meta: 'currentPosition',
        metric: displayMeasurements.metric,
        imperial: displayMeasurements.imperial,
        parent: state.line.id,
      },
      geometry: {
        type: 'Point',
        coordinates: last(geojson.geometry.coordinates)!,
      },
    };

    display(currentVertex);
  },
};

function createVertex(
  parentId: string,
  coordinates: GeoJSON.Position,
  path: string, // stringified number
  selected: boolean
): GeoJSON.Feature {
  return {
    type: 'Feature',
    properties: {
      meta: 'vertex',
      parent: parentId,
      coord_path: path,
      active: selected ? 'true' : 'false',
    },
    geometry: {
      type: 'Point',
      coordinates,
    },
  };
}

function getDisplayMeasurements(feature: GeoJSON.Feature) {
  const drawnLength = length(feature, { units: 'meters' });

  let metricMeasurement = drawnLength;
  let metricFixed = 0;
  let metricUnits = 'm';

  let imperialMeasurement = drawnLength * 3.28084;
  let imperialFixed = 0;
  let imperialUnits = 'ft';

  if (drawnLength >= 1000) {
    // If over 1000 meters, use kilometers
    metricMeasurement = drawnLength / 1000;
    metricFixed = 2;
    metricUnits = 'km';
  }

  if (imperialMeasurement >= 5280) {
    // If over 5280 feet, use miles
    imperialMeasurement /= 5280;
    imperialFixed = 2;
    imperialUnits = 'mi';
  }

  const displayMeasurements = {
    metric: `${toLocaleNumber(metricMeasurement, metricFixed)} ${metricUnits}`,
    imperial: `${toLocaleNumber(
      imperialMeasurement,
      imperialFixed
    )} ${imperialUnits}`,
  };

  return displayMeasurements;
}

export function isLineStringGeojsonFeature(
  arg: unknown
): arg is GeoJSON.Feature<GeoJSON.LineString> {
  return (
    isObject(arg) &&
    'type' in arg &&
    arg.type === 'Feature' &&
    'geometry' in arg &&
    isObject(arg.geometry) &&
    'type' in arg.geometry &&
    arg.geometry.type === 'LineString' &&
    'coordinates' in arg.geometry &&
    Array.isArray(arg.geometry.coordinates)
  );
}

import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Button,
  ClassNameMap,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { MultiColumnRow, MultiColumnTable } from '@pn/core/domain/data-info';
import { convertSymbol, prettyPrintUnitSymbol } from '@pn/core/domain/units';
import { useAppStorage } from '@pn/core/storage';
import { isCordova } from '@pn/core/utils/env';
import { isNil, isString } from 'lodash-es';
import React from 'react';
import { TableModule } from 'src/ui/main-panel/components/dense-components/TableModule';
import { FormattedTableCell } from 'src/ui/main-panel/components/dense-components/tables/FormattedTableCell';
import { TablePaginationActions } from 'src/ui/main-panel/components/dense-components/tables/TablePaginationActions';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  emptyTableCell: {
    border: 'none',
  },
  showAll: {
    position: 'absolute',
    left: 0,
    top: 6,
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'none',
    zIndex: 1,
  },
}));

type Props = {
  classes: ClassNameMap;
  table: MultiColumnTable;
  onExportCSV: () => void;
};

export function MultiColumnTableComponent({
  classes,
  table,
  onExportCSV,
}: Props) {
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.tableTitle}>{table.title}</Typography>
        {!table.noExport && !isCordova() && (
          <IconButton size="small" color="default" onClick={onExportCSV}>
            <GetAppIcon />
          </IconButton>
        )}
      </Box>
      <Divider />
      <TableComponent classes={classes} table={table} />
    </Box>
  );
}

type TableComponentProps = {
  classes: ClassNameMap;
  table: MultiColumnTable;
  onButtonClick?: (row: MultiColumnRow) => void;
};

export function TableComponent({
  classes,
  table,
  onButtonClick,
}: TableComponentProps) {
  const { rows, columns, transpose, disablePagination, module } = table;

  const { classes: extraClasses, cx } = useStyles();

  const { unitSystem } = useAppStorage();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [showAll, setShowAll] = React.useState(false);

  const isPaginated =
    !showAll && !transpose && !disablePagination && rows.length > 10;
  const rowsDisplayed = isPaginated
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : rows;
  const numberOfEmptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = React.useCallback(
    (_event: unknown, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  if (transpose) {
    return (
      <Box className={classes.tableWrapper}>
        <Table size="small">
          <TableBody>
            {columns.map((column, index) => {
              let label = column.value;
              if (!isNil(column.symbol)) {
                label += ` (${prettyPrintUnitSymbol(
                  convertSymbol(column.symbol, unitSystem)
                )})`;
              }

              return (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell
                    key={column.field}
                    className={cx(classes.tableCell, classes.bold)}
                  >
                    {label}
                  </TableCell>
                  {rows.map((row, index) => {
                    const key = `${column.field}-${index}`;
                    const url = row[`__url:${column.field}`];

                    return (
                      <FormattedTableCell
                        key={key}
                        classes={classes}
                        value={row[column.field]}
                        module={column.module}
                        isButton={column.isButton}
                        url={isString(url) ? url : undefined}
                        onClick={() => onButtonClick!(row)}
                      />
                    );
                  })}
                </TableRow>
              );
            })}

            {isPaginated && numberOfEmptyRows > 0 && (
              <TableRow style={{ height: 22 * numberOfEmptyRows }}>
                <TableCell
                  colSpan={columns.length}
                  className={extraClasses.emptyTableCell}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <TableModule module={module} />
      </Box>
    );
  } else {
    return (
      <Box className={classes.tableWrapper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                let label = column.value;
                if (!isNil(column.symbol)) {
                  label += ` (${prettyPrintUnitSymbol(
                    convertSymbol(column.symbol, unitSystem)
                  )})`;
                }

                return (
                  <TableCell key={column.field} className={classes.tableCell}>
                    {label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsDisplayed.map((row, index) => (
              <TableRow key={index} className={classes.tableRow}>
                {columns.map((column) => {
                  const url = row[`__url:${column.field}`];

                  return (
                    <FormattedTableCell
                      key={column.field}
                      classes={classes}
                      value={row[column.field]}
                      module={column.module}
                      isButton={column.isButton}
                      url={isString(url) ? url : undefined}
                      onClick={() => onButtonClick!(row)}
                    />
                  );
                })}
              </TableRow>
            ))}

            {isPaginated && numberOfEmptyRows > 0 && (
              <TableRow style={{ height: 22 * numberOfEmptyRows }}>
                <TableCell
                  colSpan={columns.length}
                  className={extraClasses.emptyTableCell}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {isPaginated && (
          <Box position="relative">
            {!showAll && (
              <Button
                color="info"
                className={extraClasses.showAll}
                onClick={() => setShowAll(true)}
              >
                Show all
              </Button>
            )}
            <TablePagination
              classes={{
                root: classes.tablePaginationRoot,
                toolbar: classes.tablePaginationToolbar,
              }}
              rowsPerPageOptions={[]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
        )}

        <TableModule module={module} />
      </Box>
    );
  }
}

import type {
  Column,
  KeyValueTable,
  LinkedIdsTable,
  MultiColumnRow,
  MultiColumnTable,
} from '@pn/core/domain/data-info';
import { toSIUnit } from '@pn/core/domain/units';
import type { IDataInfoDetailsMapper } from '@pn/core/mappers/data-info';
import { apiTableModuleMapper } from '@pn/services/api/data-info/apiTableModuleMapper';
import { isNil, isNumber, keys } from 'lodash-es';
import {
  isApiKeyValueTable,
  isApiLinkedIdsTable,
  isApiMultiColumnTable,
  type ApiDetails,
  type ApiMultiColumnTable,
} from './types';
import { dependencies } from '@pn/core/dependencies';
import { safeMap } from '@pn/core/utils/logic';

const ignoredKeys = ['link_id', 'link_type'];

export type IApiDataInfoDetailsMapper = IDataInfoDetailsMapper<ApiDetails>;

export const apiDataInfoDetailsMapper: IApiDataInfoDetailsMapper = {
  toDomainDetails: (apiDetails) => {
    return apiDetails.map((apiDetailsTab) => {
      return {
        tabName: apiDetailsTab.tabName,
        tables: safeMap(
          apiDetailsTab.tables,
          (apiDetailsTable) => {
            const table = {
              title: apiDetailsTable.title,
              tableType: apiDetailsTable.tableType,
              professional: apiDetailsTable.professional ?? false,
              noExport: apiDetailsTable.noExport ?? false,
              disablePagination: apiDetailsTable.disablePagination ?? false,
              module: apiDetailsTable.module,
            };

            if (isApiKeyValueTable(apiDetailsTable)) {
              const keyValueTable: KeyValueTable = {
                ...table,
                rows: apiDetailsTable.rows.map((apiKeyValueRow) => ({
                  key: apiKeyValueRow.key,
                  ...toDomainRow({
                    apiValue: apiKeyValueRow.value,
                    apiType: apiKeyValueRow.type,
                    apiUnits: apiKeyValueRow.units,
                    module: undefined,
                  }),
                  url: apiKeyValueRow.__url,
                })),
                module: apiTableModuleMapper.toDomainModule(
                  apiDetailsTable.module
                ),
              };

              return keyValueTable;
            }

            if (isApiLinkedIdsTable(apiDetailsTable)) {
              const linkedIdsTable: LinkedIdsTable = {
                ...table,
                rows: toDomainMultiColumnRows(
                  apiDetailsTable,
                  apiDetailsTab.tabName
                ),
                columns: toDomainColumns(apiDetailsTable),
                linkedCoordinates: apiDetailsTable.linkedCoordinates,
                linkedIds: apiDetailsTable.linkedIds,
                linkedIdsType: apiDetailsTable.linkedIdsType,
                transpose: false,
                module: apiTableModuleMapper.toDomainModule(
                  apiDetailsTable.module
                ),
              };

              return linkedIdsTable;
            }

            if (isApiMultiColumnTable(apiDetailsTable)) {
              const multiColumnTable: MultiColumnTable = {
                ...table,
                rows: toDomainMultiColumnRows(
                  apiDetailsTable,
                  apiDetailsTab.tabName
                ),
                columns: toDomainColumns(apiDetailsTable),
                transpose: apiDetailsTable.transpose ?? false,
                module: apiTableModuleMapper.toDomainModule(
                  apiDetailsTable.module
                ),
              };

              return multiColumnTable;
            }

            throw new Error(
              `Unsupported table type ${(apiDetailsTable as any).tableType}`
            );
          },
          dependencies.errorLogger
        ),
      };
    });
  },
};

function toDomainMultiColumnRows(
  apiDetailsTable: ApiMultiColumnTable,
  tabName: string
): MultiColumnRow[] {
  return apiDetailsTable.rows.map((multiColumnRow, index) =>
    keys(multiColumnRow).reduce<MultiColumnRow>((acc, key) => {
      const column = apiDetailsTable.columns.find((c) => c.field === key);

      if (isNil(column)) {
        if (index === 0 && !ignoredKeys.includes(key)) {
          // report warnings once per table
          console.warn(
            `Column ${key} not found in columns of ${tabName}/${apiDetailsTable.title} table`
          );
        }

        return {
          ...acc,
          [key]: multiColumnRow[key],
        };
      }

      return {
        ...acc,
        [key]: toDomainRow({
          apiValue: multiColumnRow[key],
          apiType: column.type,
          apiUnits: column.units,
          module: column.module,
        }).value,
      };
    }, {})
  );
}

function toDomainColumns(apiDetailsTable: ApiMultiColumnTable): Column[] {
  return apiDetailsTable.columns.map((apiColumn) => ({
    field: apiColumn.field,
    value: apiColumn.value,
    isButton: apiColumn.type === 'button',
    symbol: apiColumn.units,
    module: apiColumn.module,
  }));
}

function toDomainRow(params: {
  apiValue: unknown;
  apiType: string;
  apiUnits: string | undefined;
  module: Column['module'];
}) {
  const { apiValue, apiType, apiUnits, module } = params;

  let value = apiValue;

  if (!isNil(module)) {
    return {
      value,
      isButton: false,
    };
  }

  if (!isNil(apiUnits)) {
    if (isNumber(apiValue)) {
      value = toSIUnit({
        value: apiValue,
        symbol: apiUnits,
      });
    } else if (isNil(apiValue)) {
      value = undefined; // skip conversion to SIUnit for null values
    } else {
      console.warn(
        `Failed to convert to SIUnit (${apiUnits}) because value`,
        apiValue,
        'is not a number | undefined'
      );
    }
  }

  return {
    value,
    isButton: apiType === 'button',
  };
}

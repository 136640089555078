import { DataMultiSelectionReason } from '@pn/core/domain/query';
import {
  createWorkspaceItem,
  getItemColor,
  getLayerId,
  type IWorkspaceItemMapper,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { findOrThrow, nullToUndefined } from '@pn/core/utils/logic';
import { generateGeoJSONFeatureCollection } from '@pn/services/map/mapbox/mapboxUtils';
import { mapboxLayerMapper } from '@pn/services/map/mapbox/mappers/mapboxLayerMapper';
import assert from 'assert';
import { isNil } from 'lodash-es';
import {
  isStackProjectWithListIds,
  type StackProject,
  type StackProjectWithListIds,
} from './types';

export const apiStackWorkspaceItemToWorkspaceItemMapper =
  (): IWorkspaceItemMapper<StackProject | StackProjectWithListIds> => {
    return {
      toWorkspaceItem: (stackProject, sourceItem) => {
        assert(sourceItem, 'sourceItem is required for Stack lists');

        /* Have to use typeguards to make TypeScript happy */
        const stackList = (() => {
          if (isStackProjectWithListIds(stackProject)) {
            return findOrThrow(
              stackProject.lists,
              (list) => list.sourceLayerId === sourceItem.id
            );
          } else {
            return findOrThrow(
              stackProject.lists,
              (list) => list.sourceLayerId === sourceItem.id
            );
          }
        })();

        const isLoaded = stackList.ids !== null;

        const id = getStackItemId(stackProject.projectId, sourceItem.id);
        const result = createWorkspaceItem(
          {
            source: 'item',
            sourceItem,
            isTemporary: false,
            isLoaded,
            id,
            name: stackProject.description,
            origin: 'stackdx',
            queryOptions: {
              requestedIds: isLoaded ? stackList.ids : [],
              multiSelectionReason: DataMultiSelectionReason.List,
              ignoreLimit: true,
            },
            extraStyle: {
              color: getColor(sourceItem),
              size: getSize(sourceItem),
            },
          },
          undefined
        );
        result.folder = undefined;
        result.createdAt = stackProject.createDate;
        result.updatedAt = stackProject.updateDate;
        result.numberOfElements = nullToUndefined(stackList.count);
        result.originUrl = nullToUndefined(stackList.stackdxUrl);
        result.metadata = {
          dataOriginDescription: stackProject.dataOriginDesc,
        };

        if (!isNil(stackList.layers_definition)) {
          result.map = {
            layers: stackList.layers_definition.map((mapboxLayer, index) => ({
              name: mapboxLayer.name,
              ...sourceItem.map.layers[index],
              ...mapboxLayerMapper.toDomainLayer(
                {
                  source: {
                    type: 'geojson',
                    data: generateGeoJSONFeatureCollection([]),
                  },
                  'source-layer': undefined,
                  ...mapboxLayer, // will override the source if one is present
                  id: getLayerId(sourceItem.id, id, index),
                },
                mapboxLayer.render_as_points
              ),
            })),
          };
        }

        return result;
      },
      toOriginalItem: () => {
        throw new Error('Manipulation of Stack items is not supported');
      },
    };
  };

export function getStackItemId(
  stackProjectId: number,
  sourceItemId: string
): string {
  return stackProjectId.toString() + '|' + sourceItemId;
}

function getColor(sourceItem: WorkspaceItem): unknown {
  switch (sourceItem.id) {
    case 'wells':
    case 'wells_usa':
      return '#E91E63';
    case 'pipelines':
      return '#1A237E';
    case 'facilities':
      return '#FF9800';
    default:
      return getItemColor(sourceItem);
  }
}

function getSize(sourceItem: WorkspaceItem): number | undefined {
  switch (sourceItem.id) {
    case 'wells':
    case 'wells_usa':
      return 20;
    case 'pipelines':
      return undefined;
    case 'facilities':
      return 36;
    default:
      return undefined;
  }
}

import { SvgIcon, type SvgIconProps } from '@mui/material';
import type { CustomSVGIconData } from '@pn/core/domain/layer';

type Props = SvgIconProps & CustomSVGIconData;

export const CustomSvgIcon = ({ pathData, viewBox, ...props }: Props) => (
  <SvgIcon viewBox={viewBox} {...props}>
    {pathData.map((data, index) => (
      <path key={index} d={data} />
    ))}
  </SvgIcon>
);

import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import GestureIcon from '@mui/icons-material/Gesture';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Badge, ButtonBase, Paper, alpha } from '@mui/material';
import { useDrawing } from '@pn/services/drawing';
import { useHotkeys } from 'react-hotkeys-hook';
import { WORKSPACE_DRAWER_WIDTH } from 'src/ui/Main';
import { DRAWING_PANEL_WIDTH } from 'src/ui/drawing/DrawingPanel';
import { zIndex } from 'src/ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  panel: {
    display: 'flex',
    position: 'absolute',
    top: `calc(64px + ${theme.spacing(2)})`,
    left: `calc(50% - ${WORKSPACE_DRAWER_WIDTH / 2}px + ${
      (DRAWING_PANEL_WIDTH + 16) / 2
    }px - ${(40 + 8) / 2}px)`,
    transform: 'translate(-50%, 0)',
    // height: `calc(48px + ${theme.spacing(1 * 2)})`,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    zIndex: zIndex(theme).annotationControls, // above the bottom table
    '@media print': {
      display: 'none',
    },
  },
  button: {
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius * 2,
    '& .MuiSvgIcon-root': {
      color: theme.palette.action.active,
    },
    '& .MuiBadge-badge': {
      color: theme.palette.text.secondary,
    },
  },
  buttonSelected: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.primary.main, 0.2) // same as NavRailItem
        : theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.text.primary
          : theme.palette.primary.contrastText,
    },
    '& .MuiBadge-badge': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.text.primary
          : theme.palette.primary.contrastText,
    },
  },
  badge: {
    paddingLeft: 16,
  },
}));

const modes = {
  select: {
    icon: <NearMeOutlinedIcon style={{ transform: 'scale(-1, 1)' }} />,
    tooltip: 'Select',
    shortcut: '1',
  },
  freehand: {
    icon: <GestureIcon />,
    tooltip: 'Draw',
    shortcut: '2',
  },
  line: {
    icon: <ShowChartIcon />,
    tooltip: 'Line',
    shortcut: '3',
  },
  arrow: {
    icon: <KeyboardBackspaceIcon />,
    tooltip: 'Arrow',
    shortcut: '4',
  },
  rectangle: {
    icon: <CropLandscapeIcon />,
    tooltip: 'Rectangle',
    shortcut: '5',
  },
  circle: {
    icon: <RadioButtonUncheckedIcon />,
    tooltip: 'Circle',
    shortcut: '6',
  },
  text: {
    icon: <FontDownloadOutlinedIcon />,
    tooltip: 'Text',
    shortcut: '7',
  },
};

export const DrawingToolbar = () => {
  const { classes, cx } = useStyles();

  const { drawingMode, setDrawingMode } = useDrawing();

  useHotkeys('1', () => setDrawingMode('select'));
  useHotkeys('2', () => setDrawingMode('freehand'));
  useHotkeys('3', () => setDrawingMode('line'));
  useHotkeys('4', () => setDrawingMode('arrow'));
  useHotkeys('5', () => setDrawingMode('rectangle'));
  useHotkeys('6', () => setDrawingMode('circle'));
  useHotkeys('7', () => setDrawingMode('text'));

  return (
    <Paper variant="outlined" className={classes.panel}>
      {Object.entries(modes).map(([mode, config]) => (
        <ButtonBase
          key={mode}
          title={config.tooltip}
          className={cx(classes.button, {
            [classes.buttonSelected]: drawingMode === mode,
          })}
          onClick={() => setDrawingMode(mode as keyof typeof modes)}
        >
          <Badge
            classes={{ badge: classes.badge }}
            badgeContent={config.shortcut}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            {config.icon}
          </Badge>
        </ButtonBase>
      ))}
    </Paper>
  );
};

import { isCordova } from '@pn/core/utils/env';
import { createBrowserHistory, createHashHistory } from 'history';

const history = isCordova()
  ? createHashHistory({
      hashType: 'slash',
      getUserConfirmation: (message, callback) =>
        callback(window.confirm(message)),
    })
  : createBrowserHistory();

export default history;

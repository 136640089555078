import { dependencies } from '@pn/core/dependencies';
import { useRouter } from '@pn/core/operations/router';
import { useWorkspaceStorage } from '@pn/core/storage';
import { isNil, isString } from 'lodash-es';
import React from 'react';

/**
 * Append ?hideAllButSelected=1 to the URL that selects a single data item.
 * E.g. /wells/100093302503W500?hideAllButSelected=1
 */
export function useHideAllButSelected() {
  const { query, match, removeUrlParam } = useRouter();

  const hideAllButSelected =
    isString(query.hideAllButSelected) &&
    parseInt(query.hideAllButSelected) === 1;

  const { workspaceItems, dataItemRequested } = useWorkspaceStorage();

  const [initialMatchUrl] = React.useState(match?.url);

  React.useEffect(() => {
    if (!hideAllButSelected || isNil(dataItemRequested.id)) return;

    const { map } = dependencies;

    const filteredLayerIds: string[] = [];

    workspaceItems
      .filter(({ isRendered, module }) => isRendered && isNil(module))
      .forEach((item) => {
        item.map.layers.forEach((layer) => {
          filteredLayerIds.push(layer.id);
          map.filterLayer({
            layerId: layer.id,
            key: 'hide_all_but_selected',
            filter: ['==', 'internal_id', dataItemRequested.id],
          });
        });
      });

    return () => {
      filteredLayerIds.forEach((layerId) => {
        map.filterLayer({
          layerId,
          key: 'hide_all_but_selected',
          filter: undefined,
        });
      });
    };
  }, [dataItemRequested.id, hideAllButSelected, workspaceItems]);

  React.useEffect(() => {
    if (hideAllButSelected && match?.url !== initialMatchUrl) {
      removeUrlParam('hideAllButSelected');
    }
  }, [
    hideAllButSelected,
    match?.url,
    initialMatchUrl,
    removeUrlParam,
    workspaceItems,
  ]);
}

import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { Autocomplete, Box, TextField, ToggleButton } from '@mui/material';
import { ZonalQuery } from '@pn/core/domain/zone';
import { ExclusiveToggleButtonGroup } from '@pn/ui/custom-components/ExclusiveToggleButtonGroup';

type Props = {
  edge: 'upper' | 'lower';
  options: string[];
  zonalQuery: ZonalQuery;
  setZonalQuery: (zonalQuery: ZonalQuery) => void;
};

export const ZoneBoundaryControls = ({
  edge,
  options,
  zonalQuery,
  setZonalQuery,
}: Props) => {
  return (
    <Box display="flex" width="100%" gap={2}>
      <Autocomplete
        fullWidth
        size="small"
        options={options}
        value={zonalQuery[edge].entity}
        onChange={(_, value) =>
          setZonalQuery({
            ...zonalQuery,
            [edge]: { ...zonalQuery[edge], entity: value ?? '' },
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={edge === 'upper' ? 'From' : 'To'}
            placeholder={edge === 'upper' ? 'Surface' : 'Basement'}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
        )}
      />
      <ExclusiveToggleButtonGroup
        aria-label={`${edge} boundary`}
        size="small"
        value={zonalQuery[edge].boundary}
        onChange={(_, value) =>
          setZonalQuery({
            ...zonalQuery,
            [edge]: { ...zonalQuery[edge], boundary: value },
          })
        }
      >
        <ToggleButton value="top" title="Top">
          <VerticalAlignTopIcon />
        </ToggleButton>
        <ToggleButton value="base" title="Base">
          <VerticalAlignBottomIcon />
        </ToggleButton>
      </ExclusiveToggleButtonGroup>
    </Box>
  );
};

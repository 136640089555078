import {
  getCenterPoint,
  toGeoPoint,
  type GeoPoint,
} from '@pn/core/domain/geography';
import { isNil, isNumber } from 'lodash-es';
import type { DataItem } from './dataItem';
import type { DataType } from './dataType';

export function getDirectionsPoint(
  dataItem: DataItem,
  dataType: DataType
): GeoPoint {
  switch (dataType) {
    case 'wells':
    case 'wells_usa':
      return getWellDirectionsPoint(dataItem);
    default:
      return getDataItemDirectionsPoint(dataItem);
  }
}

/**
 * Wells are a special case because their geometry reflects their bottom location
 * while directions have to take the user to the surface location instead.
 */
function getWellDirectionsPoint(dataItem: DataItem): GeoPoint {
  if (
    !isNumber(dataItem.surface_latitude) ||
    !isNumber(dataItem.surface_longitude)
  ) {
    throw new Error(`Invalid surface coordinates for well ${dataItem._id}`);
  }

  return toGeoPoint(dataItem.surface_latitude, dataItem.surface_longitude);
}

function getDataItemDirectionsPoint(dataItem: DataItem): GeoPoint {
  if (!isNil(dataItem.geoShape)) {
    return getCenterPoint(dataItem.geoShape);
  } else {
    throw new Error(
      `Unable to get directions point for undefined geoShape in data item ${dataItem._id}`
    );
  }
}

import { useAuth0 } from '@auth0/auth0-react';
import { isCordova } from '@pn/core/utils/env';
import React from 'react';

export const Auth0CallbackListener = () => {
  const { handleRedirectCallback } = useAuth0();

  const auth0EventFired = React.useRef(false);

  React.useEffect(() => {
    if (isCordova()) {
      document.addEventListener('auth0-callback-url', async (event: any) => {
        if (auth0EventFired.current) {
          return;
        }

        auth0EventFired.current = true; // prevents double-firing

        console.log('auth0-callback-url event', event);
        const redirectCallbackUrl = event.detail.replace(
          'petroninja://callback',
          import.meta.env.VITE_APP_BASE_URL
        );
        console.log('redirectCallbackUrl generated', redirectCallbackUrl);
        await handleRedirectCallback(redirectCallbackUrl);

        auth0EventFired.current = false;
      });
    }
  }, [handleRedirectCallback]);

  return null;
};

import { dependencies } from '@pn/core/dependencies';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';
import { companiesSlice, type State } from './companiesSlice';
import type { ICompaniesActions, ICompaniesStorage } from './ports';

export const useReduxCompaniesStorage = (): ICompaniesStorage => {
  const { name, getInitialState } = companiesSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    companies: useSliceSelector((state) => state.companies),
    checkedCompanies: useSliceSelector(selectCheckedCompanies),
    includedCompanies: useSliceSelector(selectIncludedCompanies),
    selectedCompany: useSliceSelector(selectSelectedCompany),
    selectedCompanyId: useSliceSelector((state) => state.selectedCompanyId),
    companyNameToIdRecord: useSliceSelector(
      (state) => state.companyNameToIdRecord
    ),
    producers: useSliceSelector(selectProducingCompanies),
    landBrokers: useSliceSelector(selectLandBrokerCompanies),
    recentlyViewedCompanies: useSliceSelector(selectRecentlyViewedCompanies),
  };
};

export const reduxCompaniesActions = (): ICompaniesActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = companiesSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (companies) => dispatch(actions.receive(companies)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    load: (company) => dispatch(actions.load(company)),
    check: (companyIds) => dispatch(actions.check(companyIds)),
    uncheck: (companyIds) => dispatch(actions.uncheck(companyIds)),
    include: (companyIds) => dispatch(actions.include(companyIds)),
    omit: (companyIds) => dispatch(actions.omit(companyIds)),
    uncheckAll: () => dispatch(actions.uncheckAll()),
    includeAll: () => dispatch(actions.includeAll()),
    select: (companyId) => dispatch(actions.select(companyId)),
    unselect: () => dispatch(actions.unselect()),
    update: (companyId, company) =>
      dispatch(actions.update({ companyId, company })),
    markAsRecentlyViewed: (company) =>
      dispatch(actions.markAsRecentlyViewed(company)),
  };
};

const selectCheckedCompanies = createSelector(
  [
    (state: State) => state.companies,
    (state: State) => state.checkedCompanyIds,
  ],
  (companies, checkedCompanyIds) =>
    companies.filter((c) => checkedCompanyIds.includes(c.id))
);

const selectIncludedCompanies = createSelector(
  [
    (state: State) => state.companies,
    (state: State) => state.omittedCompanyIds,
  ],
  (companies, ommittedCompanyIds) =>
    companies.filter((c) => !ommittedCompanyIds.includes(c.id))
);

const selectSelectedCompany = createSelector(
  [
    (state: State) => state.companies,
    (state: State) => state.selectedCompanyId,
  ],
  (companies, selectedCompanyId) =>
    companies.find(({ id }) => id === selectedCompanyId)
);

const selectProducingCompanies = createSelector(
  [(state: State) => state.companies],
  (companies) => companies.filter(({ purpose }) => purpose === 'production')
);

const selectLandBrokerCompanies = createSelector(
  [(state: State) => state.companies],
  (companies) => companies.filter(({ purpose }) => purpose === 'land_broker')
);

const selectRecentlyViewedCompanies = createSelector(
  [
    (state: State) => state.companies,
    (state: State) => state.recentlyViewedCompanyIds,
  ],
  (companies, selectedCompanyId) =>
    companies.filter(({ id }) => selectedCompanyId.includes(id))
);

import {
  updateUserProfile,
  type User,
  type UserForm,
} from '@pn/core/domain/user';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import assert from 'assert';

type State = {
  isError: boolean;
  user: User | undefined;
  stackToken: string | undefined;
  stackCompanyId: string | undefined;
  isAdminMode: boolean;
};

const initialState: State = {
  isError: false,
  user: undefined,
  stackToken: undefined,
  stackCompanyId: undefined,
  isAdminMode: false,
};

export const createUserSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isError = false;
      },
      receive: (state, { payload: user }: PayloadAction<User>) => {
        state.user = user;
      },
      error: (state) => {
        state.isError = true;
      },
      reset: (state) => {
        state.isError = false;
        state.user = undefined;
      },
      updateProfile: (
        state,
        { payload: userForm }: PayloadAction<UserForm>
      ) => {
        assert(state.user, 'Unable to update undefined user');
        state.user = updateUserProfile(state.user, userForm);
      },
      updateStackCompanyId: (state, { payload: id }: PayloadAction<string>) => {
        state.stackCompanyId = id;
      },
      updateStackToken: (
        state,
        { payload: stackToken }: PayloadAction<string | undefined>
      ) => {
        assert(state.user, 'Unable to update undefined user');
        state.stackToken = stackToken;
      },
      toggleAdminMode: (state) => {
        state.isAdminMode = !state.isAdminMode;
      },
      _updateId: (state, { payload: id }: PayloadAction<User['id']>) => {
        assert(state.user, 'Unable to update undefined user');
        state.user.id = id;
      },
      _replace: (_state, { payload }: PayloadAction<typeof initialState>) => {
        return { ...payload };
      },
    },
  });

export const currentUserSlice = createUserSlice('currentUser');

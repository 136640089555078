import type { Project } from '@pn/core/domain/project';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import { useProjectState } from 'src/ui/workspace/project-controls/ProjectStateProvider';
import type { LibraryRow } from '../types';

export function useLibraryActions() {
  const { setSearchText, setLibraryRouter, closeLibrary } = useLibrary();
  const { handleSelectProject } = useProjectState();
  const { openWorkspaceItemPanel } = useWorkspaceItemPanel();

  const handleGridClick = (project: Project) => {
    handleSelectProject(project);
    closeLibrary();
  };

  const handleRowClick = (params: { row: LibraryRow }) => {
    const { row } = params;

    if (row.rowType === 'project') {
      setLibraryRouter({
        section: 'items',
        folder: row.source.folder ?? 'Petro Ninja',
        projectId: row.id,
      });
      setSearchText('');
    } else {
      openWorkspaceItemPanel({ item: row.source });
    }
  };

  return { handleGridClick, handleRowClick };
}

import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const wellLicenseTransfersMapping: PostgresMappingItem[] = [
  {
    field: 'id',
    label: 'ID',
    width: 175,
    sourceType: 'int',
  },
  {
    field: 'transfer_date',
    domainType: 'DateString',
    label: 'Transfer Date',
    isShownByDefault: true,
    width: 125,
    sourceType: 'date',
  },
  {
    field: 'wellbore_uwi_list',
    label: 'UWIs',
    isNotRenderable: true,
    isShownByDefault: true,
    sourceType: 'json',
  },
  {
    field: 'from_company',
    label: 'From',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'to_company',
    label: 'To',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'province',
    label: 'Province',
    isShownByDefault: true,
    sourceType: 'text',
    width: 60,
  },
  // {
  //   field: 'license_status_list',
  //   label: 'License Status List',
  //   width: 150,
  // },
  {
    field: 'wellbore_count',
    label: 'Wellbores',
    isShownByDefault: true,
    sourceType: 'int',
    domainType: 'number'
  },
  {
    field: 'license_count',
    label: 'Licence Count',
    isShownByDefault: true,
    sourceType: 'int',
    domainType: 'number'
  },
  {
    field: 'oil_vol_per_day',
    label: 'Oil',
    isShownByDefault: true,
    width: 175,
    sourceType: 'float',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
  },
  {
    field: 'gas_vol_per_day',
    label: 'Gas',
    isShownByDefault: true,
    width: 75,
    sourceType: 'float',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'boe/d',
    },
  },
  {
    field: 'condensate_bbl_per_day',
    label: 'Condensate',
    isShownByDefault: true,
    width: 175,
    sourceType: 'float',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
  },
  {
    field: 'ngl_bbl_per_day',
    label: 'NGL',
    isShownByDefault: true,
    width: 175,
    sourceType: 'float',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
  },
  {
    field: 'total_boe_production',
    label: 'Total Production',
    isShownByDefault: true,
    sourceType: 'float',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'boe/d',
    },
  },
  {
    field: 'prod_month_considered',
    label: 'Prod Month Considered',
    width: 250,
    isNotSortable: true,
    isShownByDefault: true,
    sourceType: 'timestamp',
  },
];

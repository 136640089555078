import type { SIUnit } from '@pn/core/domain/units';

const liquidProductionSymbols = ['m3', 'm3/d', 'bbl', 'bbl/d'];
const gasProductionSymbols = ['e3m3', 'e3m3/d', 'mcf', 'mcf/d'];

export function isLiquidProductionUnit(unit: SIUnit) {
  return liquidProductionSymbols.includes(unit.symbol);
}

export function isGasProductionUnit(unit: SIUnit) {
  return gasProductionSymbols.includes(unit.symbol);
}

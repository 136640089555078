import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const dispositionsHistoricalElasticMapping: ElasticMappingItem[] = [
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'Petro Ninja ID',
    folder: 'Disposition Header',
  },
  {
    field: 'disposition_number',
    sourceType: 'keyword',
    label: 'Disposition No.',
    folder: 'Disposition Header',
    isShownByDefault: true,
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'Province',
    folder: 'Disposition Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'disposition_type',
    sourceType: 'keyword',
    label: 'Type (Abbreviated)',
    folder: 'Disposition Header',
    isShownByDefault: true,
  },
  {
    field: 'document_type',
    sourceType: 'text',
    label: 'Document Type',
    folder: 'Disposition Header',
    isShownByDefault: true,
  },
  {
    field: 'discrepancy_flag',
    sourceType: 'boolean',
    label: 'Has Discrepancy',
    folder: 'Disposition Header',
  },
  /* Dates */
  {
    field: 'cancellation_date',
    sourceType: 'date',
    label: 'Cancellation Date',
    folder: 'Dates',
    isShownByDefault: true,
  },
  {
    field: 'edit_date',
    sourceType: 'date',
    label: 'Edit Date',
    folder: 'Dates',
  },
  {
    field: 'process_date',
    sourceType: 'date',
    label: 'Process Date',
    folder: 'Dates',
  },
  {
    field: 'version_date',
    sourceType: 'date',
    label: 'Version Date',
    folder: 'Dates',
  },
  {
    field: 'notification_date',
    sourceType: 'date',
    label: 'Notification Date',
    folder: 'Dates',
  },
  {
    field: 'site_entry_date',
    sourceType: 'date',
    label: 'Site Entry Date',
    folder: 'Dates',
  },
  /* Not renderable */
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'disposition_type',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];

import type { DataItemId, MappingItem } from '@pn/core/domain/data';
import type { GeoBoundingBox } from '@pn/core/domain/geography';
import type { StreamableQuery } from '@pn/core/domain/query';
import type { ZonalQuery } from '@pn/core/domain/zone';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiQueryMapper } from '@pn/services/api/query';
import { apiZonalQueryMapper } from '@pn/services/api/zone/apiZoneMapper';
import { isNil } from 'lodash-es';

type ApiResponse = {
  data_type: string;
  internal_ids: DataItemId[];
};

export const apiZoneProvider = {
  getByZone: async (params: {
    zonalQuery: ZonalQuery;
    dataType: string;
    bbox: GeoBoundingBox | undefined;
    query: StreamableQuery | undefined;
    mapping: MappingItem[];
  }) => {
    const { zonalQuery, dataType, bbox, query, mapping } = params;

    const apiQuery = !isNil(query)
      ? apiQueryMapper.parquet(mapping).toTargetQuery(query)
      : undefined;

    const { internal_ids: internalIds } =
      await pnApiClient.request<ApiResponse>({
        method: 'POST',
        url: 'v2/zone_query',
        payload: {
          data_type: dataType,
          zone: apiZonalQueryMapper.toTargetZonalQuery(zonalQuery),
          bounding_box: !isNil(bbox)
            ? {
                swlat: bbox.southWest.lat,
                swlng: bbox.southWest.lon,
                nelat: bbox.northEast.lat,
                nelng: bbox.northEast.lon,
              }
            : undefined,
          query: apiQuery,
        },
      });

    return internalIds;
  },
};

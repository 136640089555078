import { dependencies } from '@pn/core/dependencies';
import { User } from '@pn/core/domain/user';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { isNil } from 'lodash-es';
import { IEnterpriseUsersActions, IUsersStorage } from './ports';
import { enterpriseUsersSlice } from './usersSlice';

export const useReduxEnterpriseUsersStorage = (): IUsersStorage<User> => {
  const { name, getInitialState } = enterpriseUsersSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isError: useSliceSelector((state) => state.isError),
    users: useSliceSelector((state) => state.users),
    selectedUser: useSliceSelector((state) => state.selectedUser),
  };
};

export const reduxEnterpriseUsersActions = (): IEnterpriseUsersActions => {
  const { dispatch, getState } = dependencies.store;
  const { actions, name } = enterpriseUsersSlice;

  if (isNil(getState()[name])) {
    throw new Error(`[${name}] reducer has not been injected yet`);
  }

  return {
    request: () => dispatch(actions.request()),
    receive: (users) => dispatch(actions.receive(users)),
    error: () => dispatch(actions.error()),
    reset: () => dispatch(actions.reset()),
    add: (user) => dispatch(actions.add(user)),
    addToEnterprise: (user, enterprise, userPlan) =>
      dispatch(actions.addToEnterprise({ user, enterprise, userPlan })),
    update: (user) => dispatch(actions.update(user)),
    updateAsAdmin: (user) => dispatch(actions.updateAsAdmin(user)),
    updateUserPlan: (user, userPlan) =>
      dispatch(actions.updateUserPlan({ user, userPlan })),
    remove: (user) => dispatch(actions.remove(user)),
    select: (userId) => {
      dispatch(actions.select(userId));
    },
    unselect: () => {
      dispatch(actions.unselect());
    },
  };
};

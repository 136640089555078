import { isNil } from 'lodash-es';

type Guard<T> = {
  index: number;
  isRunning: boolean;
} & T;

export function createConcurrencyGuard<T>() {
  const guard: Record<string, Guard<T>> = {};

  return {
    get: (id: string): Guard<T> | undefined => guard[id],
    /**
     * Returns `true` if the current run is superseded by a newer one.
     */
    isOutdated: (id: string, index: number) => guard[id]?.index !== index,
    start: (id: string, data: T) => {
      if (isNil(guard[id])) {
        guard[id] = { index: 0, isRunning: true, ...data };
      } else {
        guard[id] = { index: guard[id].index + 1, isRunning: true, ...data };
      }
      return guard[id].index;
    },
    stop: (id: string) => {
      guard[id].isRunning = false;
    },
  };
}

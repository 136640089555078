import { apiExportJobMapper } from '@pn/services/api/export-job/apiExportJobMapper';
import { ApiExportJob } from '@pn/services/api/export-job/types';
import { pnApiClient } from '@pn/services/api/pnApiClient';

export const apiExportJobsProvider = {
  getExportJobs: async () => {
    const apiExportJobs = await pnApiClient.request<ApiExportJob[]>({
      method: 'GET',
      url: 'v2/jobs',
    });

    const exportJobs = apiExportJobs.map(apiExportJobMapper.toDomainExportJob);

    return exportJobs;
  },
};

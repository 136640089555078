import type { IAuthenticationService } from './ports';

let _getAccessToken: IAuthenticationService['getAccessToken'];
let cachedToken = '';

/**
 * We store auth token getter and setter in a globally accessible place.
 * This allows apiClient to acquire the token with no extra dependencies.
 */
export const tokenManager = {
  get: () => _getAccessToken,
  getCached: () => cachedToken,
  set: (getAccessToken: IAuthenticationService['getAccessToken']) => {
    _getAccessToken = getAccessToken;
  },
  setCached: (token: string) => {
    cachedToken = token;
  },
};

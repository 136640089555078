import { Parser, type ParserOptions } from '@json2csv/plainjs';
import {
  Details,
  DetailsTable,
  MultiColumnRow,
  isKeyValueTable,
  isMultiColumnTable,
} from '@pn/core/domain/data-info';
import { UnitSystem } from '@pn/core/domain/types';
import { formatDomainValue } from '@pn/core/utils/format';
import { isEmpty, keys } from 'lodash-es';

export function detailsToCsv(
  details: Details,
  unitSystem: UnitSystem,
  includeTableTitles = true
): string {
  let csv = '';

  details.forEach((detailsTab, detailsTabIndex) => {
    detailsTab.tables
      .filter((table) => !isEmpty(table.rows))
      .forEach((table, tableIndex) => {
        const isSeparatorRequired = detailsTabIndex > 0 || tableIndex > 0;

        const rows = isKeyValueTable(table)
          ? table.rows.map((r) => ({
              key: r.key, // TODO add symbols to units
              value: formatDomainValue({ value: r.value, unitSystem }),
            })) // `type` field is omitted
          : table.rows.map((row) =>
              keys(row).reduce<MultiColumnRow>(
                (acc, key) => ({
                  ...acc,
                  [key]: formatDomainValue({ value: row[key], unitSystem }),
                }),
                {}
              )
            );

        const parserOptions: ParserOptions = {
          header: isMultiColumnTable(table), // include header in multi-column tables
        };

        if (isMultiColumnTable(table)) {
          parserOptions.fields = table.columns.map((column) => ({
            value: column.field,
            label: column.value,
          }));
        }

        const parser = new Parser(parserOptions);

        /* Write to CSV */

        if (isSeparatorRequired) csv += '\n\n'; // add a separator between tables
        if (includeTableTitles) csv += table.title + '\n'; // add table title

        csv += parser.parse(rows);
      });
  });

  return csv;
}

export function detailsTableToCsv(
  table: DetailsTable,
  unitSystem: UnitSystem
): string {
  return detailsToCsv([{ tabName: '', tables: [table] }], unitSystem, false);
}

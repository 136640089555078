import { dependencies } from '@pn/core/dependencies';
import { safeMap } from '@pn/core/utils/logic';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { isNil } from 'lodash-es';
import { apiLayerItemMapper } from './apiLayerMapper';
import type { ApiLayerItem } from './types';

const getLayerWorkspaceItems = async () => {
  const apiLayerItems = await pnApiClient.request<ApiLayerItem[]>({
    url: 'v2/layers',
  });

  const workspaceItems = safeMap(
    apiLayerItems,
    apiLayerItemMapper.toWorkspaceItem,
    dependencies.errorLogger
  );

  return workspaceItems;
};

const getLayerWorkspaceItemById = async (id: string) => {
  const apiLayerItem = await pnApiClient.request<ApiLayerItem | undefined>({
    url: `v2/layers/${id}`,
  });
  if (isNil(apiLayerItem)) return undefined;

  const workspaceItem = apiLayerItemMapper.toWorkspaceItem(apiLayerItem);

  return workspaceItem;
};

const getNewLayerWorkspaceItem = async (file: File | Blob) => {
  const formData = new FormData();
  formData.append('layer_file', file);

  await pnApiClient.request<string>({
    method: 'POST',
    url: 'v2/layers',
    payload: formData,
  });
};

export const apiLayerProvider = {
  getLayerWorkspaceItems,
  getLayerWorkspaceItemById,
  getNewLayerWorkspaceItem,
};

import { unselectDataItem } from '@pn/core/operations/dataSelection';
import { useWorkspaceStorage } from '@pn/core/storage';
import {
  usePNThemeContext,
  type PNTheme,
} from '@pn/ui/theme/PetroNinjaThemeProvider';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import { isNil } from 'lodash-es';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';

export function usePNMapsKeyboardHotkeys() {
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { theme, setPNTheme } = usePNThemeContext();
  const {
    isWorkspaceItemPanelOpen,
    isDrawingPanelOpen,
    closeWorkspaceItemPanel,
    resetCurrentItem,
  } = useWorkspaceItemPanel();
  const { isLibraryOpen, closeLibrary } = useLibrary();

  /**
   * Toggle between the light and dark themes.
   * Extra themes can only be enabled via the UI.
   */
  useHotkeys(
    'ctrl+alt+l',
    () => {
      const newPNTheme: PNTheme =
        theme.palette.mode === 'light' ? 'Petro Night' : 'Default';
      setPNTheme(newPNTheme);
    },
    [theme.palette.mode]
  );

  /**
   * Bind all relevant actions to the escape key in order of priority.
   */
  useHotkeys('esc', () => {
    /* Drawing Panel cannot be closed with the Escape key */
    if (isWorkspaceItemPanelOpen && !isDrawingPanelOpen) {
      resetCurrentItem();
      closeWorkspaceItemPanel();
      return;
    }
    if (isLibraryOpen) return closeLibrary();
    if (!isNil(workspaceItemSelected)) unselectDataItem(workspaceItemSelected);
  });
}

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, ListItemText, Menu, MenuItem } from '@mui/material';
import { createList } from '@pn/core/operations/workspace/crud/createList';
import { useAccess } from '@pn/core/permissions/access';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isFreeCordova } from '@pn/core/utils/env';
import { DEFAULT_LIST_COLOR } from '@pn/services/styles/list';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { useMenu } from '@pn/ui/hooks/useMenu';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import { useUploadDialog } from 'src/ui/workspace/upload-dialog/UploadDialogProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    gridArea: 'button-container',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    height: 70,
    padding: theme.spacing(1.5),
    background: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  button: {
    textTransform: 'none',
  },
  importButton: {
    width: 160,
  },
}));

export const WorkspaceLibraryControls = () => {
  const { smScreen } = useScreenSize();
  const { classes } = useStyles();

  const access = useAccess();

  const { user } = useCurrentUserStorage();

  const { isLibraryOpen, openLibrary, closeLibrary } = useLibrary();
  const { openWorkspaceItemPanel } = useWorkspaceItemPanel();
  const { openUploadDialog } = useUploadDialog();

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();

  const handleCreateList = () => {
    assert(user, 'User must be defined to create a list');

    closeMenu();
    openWorkspaceItemPanel({
      item: createList({ user, color: DEFAULT_LIST_COLOR }),
    });
  };

  const handleUpload = () => {
    if (access('layers').notify().denied()) return;

    closeMenu();
    openUploadDialog();
  };

  if (smScreen) return null;

  return (
    <Box className={classes.buttonContainer}>
      {!isFreeCordova(user) && (
        <CustomButton
          className={classes.importButton}
          endIcon={<KeyboardArrowDownIcon />}
          onClick={openMenu}
        >
          Import
        </CustomButton>
      )}
      <Menu
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem dense onClick={handleCreateList}>
          <ListItemText>Import list</ListItemText>
        </MenuItem>
        <MenuItem dense onClick={handleUpload}>
          <ListItemText
            primaryTypographyProps={{
              sx: { display: 'inline-flex', gap: 1 },
            }}
          >
            Upload GIS file{' '}
            {access('layers').denied() && (
              <LockIcon color="action" sx={{ fontSize: 16 }} />
            )}
          </ListItemText>
        </MenuItem>
      </Menu>
      {isLibraryOpen ? (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={closeLibrary}
        >
          Close Library
        </Button>
      ) : (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={openLibrary}
        >
          Open Library
        </Button>
      )}
    </Box>
  );
};

import type { ISearchProvider } from '@pn/core/providers/search/ports';
import { pnApiClient } from '../pnApiClient';
import { apiSearchResultMapper } from './apiSearchResultMapper';
import type { ApiSearchResult } from './types';

export const fetchDataBySearchText = async (searchText: string) => {
  const apiSearchResults = await pnApiClient.request<ApiSearchResult[]>({
    url: 'v2/search',
    payload: {
      q: searchText,
    },
  });

  const searchResults = apiSearchResults.map(
    apiSearchResultMapper.toDomainSearchResult
  );

  return searchResults;
};

export const apiSearchProvider: ISearchProvider = {
  searchByText: fetchDataBySearchText,
};

import { dependencies } from '@pn/core/dependencies';
import type { Layer } from '@pn/core/domain/layer';
import {
  getBeforeItemsLayerIds,
  getPointsLayerId,
  getSelectedLayerId,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { getMapboxAnnotationsLayers } from '@pn/services/map/mapbox/methods/addAnnotationLayer';

export function moveWorkspaceItem(
  item: WorkspaceItem,
  beforeItem: WorkspaceItem | undefined
): void {
  const { map } = dependencies;

  if (item.itemType === 'drawing') return;

  const layerIdsToMove = getAllItemLayerIds(item);
  const beforeLayerIds = beforeItem ? getBeforeItemsLayerIds([beforeItem]) : [];

  layerIdsToMove.forEach((layerId) => {
    if (!map.hasLayer(layerId)) {
      console.error(`Failed to move item: layer ${layerId} does not exist`);
      return;
    }

    map.moveLayer(layerId, beforeLayerIds);
  });
}

function getAllItemLayerIds(item: WorkspaceItem): Layer['id'][] {
  if (item.itemType === 'annotation') {
    const layerId = `annotation-${item.id}`;
    return getMapboxAnnotationsLayers(layerId).map(({ id }) => id);
  }

  const layerIds: Layer['id'][] = [];

  item.map.layers.forEach(({ id, renderAsPoints }) => {
    layerIds.push(id);
    layerIds.push(getSelectedLayerId(id));
    if (renderAsPoints) {
      layerIds.push(getPointsLayerId(id));
      layerIds.push(getSelectedLayerId(getPointsLayerId(id)));
    }
  });

  return layerIds;
}

import { Box } from '@mui/material';
import { useDataSelection } from '@pn/core/operations/dataSelection/useDataSelection';
import { isEmbedded } from '@pn/core/utils/embedded';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import React from 'react';
import { BottomTable } from 'src/ui/bottom-table';
import { Splash } from 'src/ui/components/Splash';
import { EmbeddedEffects, MainEffects } from 'src/ui/effects';
import { PNTitle } from 'src/ui/layout/CustomAppBar';
import { Header } from 'src/ui/layout/Header';
import { MainPanel } from 'src/ui/main-panel/MainPanel';
import { MainTray } from 'src/ui/main-panel/MainTray';
import { ExternalPanel } from 'src/ui/main-panel/components/ExternalPanel';
import { Portals } from 'src/ui/main-panel/portals/Portals';
import { Map } from 'src/ui/map/Map';
import { Workspace } from 'src/ui/workspace/Workspace';
import { WorkspaceItemPanelMux } from 'src/ui/workspace/WorkspaceItemPanelMux';
import { InvalidIdsDialog } from 'src/ui/workspace/components/InvalidIdsDialog';
import { Library } from 'src/ui/workspace/library/Library';
import { UploadDialog } from 'src/ui/workspace/upload-dialog';
import { makeStyles } from 'tss-react/mui';

export const MAIN_TRAY_WIDTH = 56 + 12 * 2;
export const WORKSPACE_DRAWER_WIDTH = 300;

const useStyles = makeStyles()((theme) => ({
  main: {
    display: 'grid',
    gridTemplateAreas: `
      "header workspace"
      "map workspace"
      "bottom-table workspace"
    `,
    gridTemplateRows: 'min-content 1fr min-content',
    gridTemplateColumns: '1fr min-content',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      gridTemplateAreas: `
        "header"
        "map"
      `,
    },
  },
}));

export const Main = () => {
  const { classes } = useStyles();
  const { smScreen } = useScreenSize();

  const { isWorkspaceItemPanelOpen } = useWorkspaceItemPanel();

  const [_isWorkspaceOpen, setWorkspaceOpen] = React.useState(false);
  const isWorkspaceOpen = _isWorkspaceOpen || !smScreen;

  const isSelectable = useDataSelection().isSelectable;
  const isMainTrayOpen = isSelectable && !isWorkspaceItemPanelOpen;

  if (isEmbedded()) {
    return (
      <Box className={classes.main}>
        <PNTitle />

        <Map />
        <BottomTable />

        <EmbeddedEffects />
      </Box>
    );
  }

  return (
    <Box className={classes.main}>
      <Header
        showSearchbar
        isWorkspaceOpen={isWorkspaceOpen}
        setWorkspaceOpen={setWorkspaceOpen}
      />

      <MainTray isMainTrayOpen={isMainTrayOpen} />
      <MainPanel isMainTrayOpen={isMainTrayOpen} />
      <Map isMainTrayOpen={isMainTrayOpen} />
      <BottomTable isMainTrayOpen={isMainTrayOpen} />
      <Workspace open={isWorkspaceOpen} setWorkspaceOpen={setWorkspaceOpen} />
      <Library />

      <WorkspaceItemPanelMux />
      <ExternalPanel />
      <InvalidIdsDialog />
      <UploadDialog />
      <Portals />

      <Splash />

      <MainEffects />
    </Box>
  );
};

import type { IErrorLogger } from '@pn/core/services/loggers/ports';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import { intersection, isEmpty, isNil, xor } from 'lodash-es';

export function firstArrayIncludesAllElementsOfAnother<T>(
  array1: T[],
  array2: T[]
): boolean {
  return intersection(array1, array2).length === array2.length;
}

export function arraysShareSameValues<T>(array1: T[], array2: T[]): boolean {
  return isEmpty(xor(array1, array2));
}

export function arraysAreIdentical<T>(array1: T[], array2: T[]): boolean {
  return (
    array1.length === array2.length &&
    array1.every((element, index) => element === array2[index])
  );
}

/**
 * Iterates over an array and calls the callback function for each item.
 * If the callback throws an error, it will be logged and the item will be
 * skipped.
 */
export function safeMap<T, V>(
  array: T[],
  callback: (item: T) => V,
  errorLogger?: IErrorLogger
): V[] {
  return array
    .map((item) => {
      try {
        return callback(item);
      } catch (error) {
        if (error instanceof Error) {
          errorLogger?.logGenericError(error, getCurrentUserId());
        }
        console.error(error);
        return undefined;
      }
    })
    .filter((i): i is NonNullable<typeof i> => !isNil(i));
}

export function hasKey<T extends object, K extends string>(
  o: T,
  k: K
): o is T & Record<K, unknown> {
  return k in o;
}

export function hasKeyWithType<T extends object, K extends string, V>(
  o: T,
  k: K,
  typeCheck: (arg: unknown) => arg is V
): o is T & Record<K, V> {
  return hasKey(o, k) && typeCheck(o[k]);
}

export function removeNilFields<T extends object>(obj: T): Partial<T> {
  return Object.keys(obj).reduce<Partial<T>>((acc, key) => {
    if (!isNil(obj[key as keyof T])) {
      acc[key as keyof T] = obj[key as keyof T];
    }
    return acc;
  }, {});
}

export function fitToShape<T extends object, V extends object>(
  obj: T,
  objToMatch: V
): Partial<V> {
  return Object.keys(objToMatch).reduce<Partial<V>>((acc, key) => {
    if (hasKey(obj, key)) {
      // HACK to make TS happy
      (acc as T)[key as keyof T] = obj[key as keyof T];
    }
    return acc;
  }, {});
}

const MAX_BLOCK_SIZE = 65535; // max parameter array size for use in Webkit

/**
 * Allows to concatenate large arrays without triggering the infamous
 * "Maximum call stack size exceeded" error.
 */
export function appendArrayInPlace<T>(dest: T[], source: T[]) {
  let offset = 0;
  let itemsLeft = source.length;

  if (itemsLeft <= MAX_BLOCK_SIZE) {
    dest.push(...source);
  } else {
    while (itemsLeft > 0) {
      const pushCount = Math.min(MAX_BLOCK_SIZE, itemsLeft);
      const subSource = source.slice(offset, offset + pushCount);
      dest.push(...subSource);
      itemsLeft -= pushCount;
      offset += pushCount;
    }
  }

  return dest;
}

export function findOrThrow<T>(
  array: T[],
  predicate: (item: T) => boolean,
  errorMessage = 'Item not found'
): NonNullable<T> {
  const item = array.find(predicate);
  if (!item) {
    throw new Error(errorMessage);
  }
  return item;
}

export function nullToUndefined<T>(value: T | null): T | undefined {
  return isNil(value) ? undefined : value;
}

export function undefinedToNull<T>(value: T | undefined): T | null {
  return isNil(value) ? null : value;
}

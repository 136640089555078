import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { WorkspaceItemComponent } from './WorkspaceItem';

type Props = {
  item: WorkspaceItem;
  disabled: boolean;
};

export function SortableWorkspaceItem({ item, disabled }: Props) {
  const {
    active,
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    setActivatorNodeRef,
  } = useSortable({
    id: item.id,
    transition: {
      duration: 200,
      easing: 'ease',
    },
  });

  const style: React.CSSProperties = {
    opacity: active?.id === item.id ? 0.5 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <WorkspaceItemComponent
      ref={setNodeRef}
      item={item}
      disabled={disabled}
      isSorting={active?.id === item.id}
      style={style}
      attributes={attributes}
      listeners={listeners}
      activatorRef={setActivatorNodeRef}
    />
  );
}

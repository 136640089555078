import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import { formatDataType } from '@pn/core/domain/data';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useAccess } from '@pn/core/permissions/access';
import { useAutoGetDataDetails } from '@pn/core/providers/data-info/getDataDetails';
import {
  useAppStorage,
  useDataDetailsStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { isCordova } from '@pn/core/utils/env';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import download from 'downloadjs';
import { isNil, kebabCase } from 'lodash-es';
import { useAuthenticationService } from 'src/application/externalDependencies';
import { AuthLink } from 'src/ui/components/AuthLink';
import { FullHeightCircularLoader } from 'src/ui/components/FullHeightCircularLoader';
import { DenseTable } from 'src/ui/main-panel/components/dense-components/DenseTable';
import {
  DenseTab,
  DenseTabs,
  TabPanel,
} from 'src/ui/main-panel/components/dense-components/DenseTabs';
import { detailsToCsv } from 'src/ui/main-panel/components/dense-components/tables/export';
import { useTabSelector } from 'src/ui/main-panel/components/dense-components/useTabSelector';
import { getMainPanelUri } from 'src/ui/main-panel/utils';

const tabsWithReports = ['wells', 'wells_usa', 'mineral_rights'];

type Props = {
  item: WorkspaceItem;
};

export function Details({ item }: Props) {
  const { smScreen } = useScreenSize();

  const { unitSystem } = useAppStorage();
  const { dataItemRequested } = useWorkspaceStorage();
  const { isFetching, isError, details } = useDataDetailsStorage(item.id);

  const { isAuthenticated } = useAuthenticationService();
  const access = useAccess();

  useAutoGetDataDetails(item, dataItemRequested.id);

  const { tabValue, setTabValue, isCurrentTabUnavailable } = useTabSelector({
    featureName: formatDataType(item.dataType, { form: 'singular' }),
    details,
  });

  const handleExportCSV = () => {
    if (isNil(details) || access('details.export').notify().denied()) return;

    const csv = detailsToCsv(details, unitSystem);

    download(csv, `${dataItemRequested.id} (${unitSystem}).csv`);
  };

  const dataItemId = dataItemRequested.id;
  if (isNil(dataItemId)) return null;

  if (isError) {
    return <Box>Failed to load.</Box>;
  }

  if (
    isFetching ||
    isNil(details) ||
    isNil(tabValue) ||
    isCurrentTabUnavailable
  ) {
    return <FullHeightCircularLoader />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {!isCordova() && !smScreen && (
        <Box display="flex" gap={2}>
          <PermissionButton
            permissionPath="details.export"
            isCustom
            onClick={handleExportCSV}
            startIcon={<ArrowDownwardIcon />}
          >
            Download CSV
          </PermissionButton>
          {tabsWithReports.includes(item.dataType) && (
            <Box>
              <PermissionButton
                permissionPath="details.report"
                isCustom
                startIcon={<NorthEastIcon />}
                href={getMainPanelUri({
                  dataType: item.dataType,
                  dataItemId: dataItemId,
                  subpanel: 'report',
                })}
                target="_blank"
              >
                View{' '}
                {formatDataType(item.dataType, {
                  form: 'singular',
                  case: 'sentence',
                })}{' '}
                Report
              </PermissionButton>
            </Box>
          )}
        </Box>
      )}

      {details.length > 1 && (
        <DenseTabs tabValue={tabValue} setTabValue={setTabValue}>
          {details.map((detailsTab) => {
            const tabKey = kebabCase(detailsTab.tabName);
            return (
              <DenseTab
                key={tabKey}
                value={tabKey}
                label={detailsTab.tabName}
              />
            );
          })}
        </DenseTabs>
      )}

      {!isAuthenticated && (
        <Alert severity="warning">
          Please <AuthLink type="login">login</AuthLink> or{' '}
          <AuthLink type="signup">sign up</AuthLink> to access detailed
          information.
        </Alert>
      )}

      {details.map((detailsTab) => {
        const tabKey = kebabCase(detailsTab.tabName);
        return (
          <TabPanel key={tabKey} tabValue={tabValue} tabKey={tabKey}>
            {detailsTab.tables.map((table) => (
              <DenseTable
                key={table.title}
                dataItemId={dataItemId}
                table={table}
              />
            ))}
          </TabPanel>
        );
      })}
    </Box>
  );
}

import {
  getHighlightedLayerId,
  getPointsLayerId,
  getSelectedLayerId,
  getSourceId,
} from '@pn/core/domain/workspace';
import type { MapboxIMap } from '..';

export const removeDataLayer: MapboxIMap['removeDataLayer'] = function (
  this: MapboxIMap,
  layer
) {
  if (!this.hasLayer(layer.id)) {
    throw new Error(`Layer ${layer.id} does not exist`);
  }

  this._native.removeLayer(layer.id);
  if (this.hasLayer(getSelectedLayerId(layer.id))) {
    this._native.removeLayer(getSelectedLayerId(layer.id));
  }

  const pointsLayerId = getPointsLayerId(layer.id);
  if (this._native.getLayer(pointsLayerId)) {
    this._native.removeLayer(pointsLayerId);
    if (this.hasLayer(getSelectedLayerId(pointsLayerId))) {
      this._native.removeLayer(getSelectedLayerId(pointsLayerId));
    }
  }

  const highlightedLayerId = getHighlightedLayerId(layer.id);
  if (this._native.getLayer(highlightedLayerId)) {
    this._native.removeLayer(highlightedLayerId);
  }

  /**
   * Preserve vector tilesets to retain their feature states.
   */
  if (layer.source.type === 'vector') return;

  this._native.removeSource(getSourceId(layer.id));
  const pointsSourceId = getSourceId(getPointsLayerId(layer.id));
  if (this._native.getSource(pointsSourceId)) {
    this._native.removeSource(pointsSourceId);
  }
};

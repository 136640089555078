import { uniq } from 'lodash-es';
import { ckmeans } from 'simple-statistics';

export const calculateBreaks: Record<
  string,
  (data: number[], count: number) => number[]
> = {
  even: calculateEvenBreaks,
  natural: calculateNaturalBreaks,
};

function calculateEvenBreaks(data: number[], count: number): number[] {
  if (count === 0) {
    return []; // to match the logic of calculateNaturalBreaks
  }

  const sortedData = [...data].sort((a, b) => a - b);

  const max = sortedData[sortedData.length - 1];
  const min = sortedData[0];
  const interval = (max - min) / count;
  const breaks = Array.from({ length: count }, (_, i) => min + i * interval);

  const uniqueBreaks = uniq(breaks);

  return uniqueBreaks;
}

function calculateNaturalBreaks(data: number[], count: number): number[] {
  /**
   * Ensure the count is not greater than the data length.
   * We subtract 1 because this implementation adds an extra bin for the
   * minimum value.
   */
  const validCount = Math.min(count - 1, data.length);

  if (validCount === 0) {
    return []; // ckmeans breaks when nClusters is 0
  }

  const clusters = ckmeans(data, validCount);

  /**
   * Minimum value (first element of the first cluster) + maximum values for
   * each bin (last element of each cluster).
   */
  const breaks = clusters.reduce<number[]>(
    (acc, cluster) => {
      acc.push(cluster[cluster.length - 1]);
      return acc;
    },
    [clusters[0][0]]
  );

  const uniqueBreaks = uniq(breaks);

  return uniqueBreaks;
}

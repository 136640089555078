import { dependencies } from '@pn/core/dependencies';
import type { Transformation } from '@pn/core/domain/drawing';
import { toGeoPoint, type GeoPoint } from '@pn/core/domain/geography';
import { scalePoint } from '@pn/services/drawing';

export const REFERENCE_PT = toGeoPoint(53.5, -113.5); // Edmonton
export const REFERENCE_ZOOM = 10;

export function computeMapTransformation(
  initGeoPoint: GeoPoint, // NW corner of the map
  withDevicePixelRatio = true
): Transformation {
  const { map } = dependencies;

  const mapboxMap = map._native;

  const currentNW = mapboxMap.getBounds().getNorthWest();

  const multiplier = withDevicePixelRatio ? window.devicePixelRatio : 1;

  const initPoint = scalePoint(mapboxMap.project(initGeoPoint), multiplier);
  const currentPoint = scalePoint(mapboxMap.project(currentNW), multiplier);

  const zoom = Math.pow(2, mapboxMap.getZoom() - REFERENCE_ZOOM);
  const scaleFactor = zoom * multiplier;

  return {
    dx: (initPoint.x - currentPoint.x) / scaleFactor,
    dy: (initPoint.y - currentPoint.y) / scaleFactor,
    scale: scaleFactor,
  };
}

import dayjs from 'dayjs';
import { DateTimeString, UnitSystem } from '@pn/core/domain/types.ts';
import { IntelActivityItem } from '@pn/core/domain/intel/intel-activity-item.ts';
import { convertUnit } from '@pn/core/domain/units';
import { isEmpty, isNil, round } from 'lodash-es';
import { generateTemporaryId } from '@pn/core/utils/id.ts';
import {
  compressAndEncode,
  decodeAndDecompress,
} from '@pn/services/utils/compression.ts';

export type UWIWithMonthsProduction = {
  licensee: string;
  formation: string;
  uwi: string;
  oil_m3: number;
  gas_e3m3: number;
  cond_m3: number;
  ngl_m3: number;
};

export type ProductionByFormation = {
  formation: string;
  totalBBLPerDay: number;
  totalOilBBLPerDay: number;
  totalGasBBLPerDay: number;
  totalCondBBLPerDay: number;
  totalC2C3C4BBLPerDay: number;
};

export type ProductionByLicensee = {
  licensee: string;
  totalBBLPerDay: number;
  totalOilBBLPerDay: number;
  totalGasBBLPerDay: number;
  totalCondBBLPerDay: number;
  totalC2C3C4BBLPerDay: number;
};

const convertM3ToBBL = (value: number): number => {
  return (
    convertUnit(
      {
        value: value,
        symbol: 'm3',
      },
      UnitSystem.Imperial
    ).value ?? 0
  );
};

const convertE3M3ToMCF = (value: number): number => {
  return (
    convertUnit(
      {
        value: value,
        symbol: 'e3m3',
      },
      UnitSystem.Imperial
    ).value ?? 0
  );
};

export function aggregateByLicensee(
  data: UWIWithMonthsProduction[],
  dateConsidered: string
): ProductionByLicensee[] {
  const days = dayjs(dateConsidered).daysInMonth();
  const licenseeMap: { [key: string]: ProductionByLicensee } = {};

  data.forEach((item) => {
    const licensee = item.licensee;
    if (!licenseeMap[licensee]) {
      licenseeMap[licensee] = {
        licensee,
        totalBBLPerDay: 0,
        totalOilBBLPerDay: 0,
        totalGasBBLPerDay: 0,
        totalCondBBLPerDay: 0,
        totalC2C3C4BBLPerDay: 0,
      };
    }

    const oilBBL = round(convertM3ToBBL(item.oil_m3) / days);
    const gasBBL = round(
      convertMCFtoBBL(convertE3M3ToMCF(item.gas_e3m3)) / days
    );
    const condBBL = round(convertM3ToBBL(item.cond_m3) / days);
    const c2c3c4BBL = round(convertM3ToBBL(item.ngl_m3) / days);

    licenseeMap[licensee].totalBBLPerDay +=
      oilBBL + gasBBL + condBBL + c2c3c4BBL;
    licenseeMap[licensee].totalOilBBLPerDay += oilBBL;
    licenseeMap[licensee].totalGasBBLPerDay += gasBBL;
    licenseeMap[licensee].totalCondBBLPerDay += condBBL;
    licenseeMap[licensee].totalC2C3C4BBLPerDay += c2c3c4BBL;
  });
  return Object.values(licenseeMap);
}

export function aggregateByFormation(
  data: UWIWithMonthsProduction[],
  dateConsidered: string
): ProductionByFormation[] {
  const days = dayjs(dateConsidered).daysInMonth();
  const uniqueFormations: string[] = data.reduce<string[]>(
    (acc, { formation }) => {
      if (isEmpty(formation)) return acc;
      const wellboreFormations = formation
        .split(',')
        .map((f) => f.trimStart().trimEnd());
      wellboreFormations.forEach((formation) => {
        if (!acc.includes(formation)) {
          acc.push(formation);
        }
      });

      return acc;
    },
    []
  );

  // Aggregating data by formation
  const formationMap: { [key: string]: ProductionByFormation } = {};

  uniqueFormations.forEach((formation) => {
    if (!formationMap[formation]) {
      formationMap[formation] = {
        formation,
        totalBBLPerDay: 0,
        totalOilBBLPerDay: 0,
        totalGasBBLPerDay: 0,
        totalCondBBLPerDay: 0,
        totalC2C3C4BBLPerDay: 0,
      };
    }

    const itemsWithinFormation = data.filter(
      ({ formation: wellboreFormations }) =>
        !isEmpty(wellboreFormations) &&
        wellboreFormations
          .split(',')
          .map((frm) => frm.trimStart().trimEnd())
          .includes(formation)
    );

    itemsWithinFormation.forEach((item) => {
      const numberOfFormations = item.formation.split(',').length;

      const oilBBL = round(
        convertM3ToBBL(item.oil_m3) / days / numberOfFormations
      );
      const gasBBL = round(
        convertMCFtoBBL(convertE3M3ToMCF(item.gas_e3m3)) /
          days /
          numberOfFormations
      );
      const condBBL = round(
        convertM3ToBBL(item.cond_m3) / days / numberOfFormations
      );
      const c2c3c4BBL = round(
        convertM3ToBBL(item.ngl_m3) / days / numberOfFormations
      );

      formationMap[formation].totalBBLPerDay +=
        oilBBL + gasBBL + condBBL + c2c3c4BBL;
      formationMap[formation].totalOilBBLPerDay += oilBBL;
      formationMap[formation].totalGasBBLPerDay += gasBBL;
      formationMap[formation].totalCondBBLPerDay += condBBL;
      formationMap[formation].totalC2C3C4BBLPerDay += c2c3c4BBL;
    });
  });

  return Object.values(formationMap);
}

export function getDateRangeFromReportFilters(
  filters: IntelReportFilters
): [DateTimeString, DateTimeString] {
  const { daysAgoWindow, customStaticStartDate, customStaticEndDate } = filters;
  if (isNil(daysAgoWindow)) {
    if (customStaticStartDate && customStaticEndDate) {
      return [customStaticStartDate, customStaticEndDate];
    } else {
      console.warn(
        'Invalid date data provided for the report. Using default value'
      );
      return ['1970-01-01', '1970-01-01'];
    }
  } else {
    const yesterday = dayjs().subtract(1, 'day');
    const yesterdayFormatted = yesterday.format('YYYY-MM-DD');
    return [
      yesterday.subtract(daysAgoWindow - 1, 'days').format('YYYY-MM-DD'),
      yesterdayFormatted,
    ];
  }
}

export const daysAgoWindowOptions = [7, 30, 90, 365];

export type IntelReportFilters = {
  daysAgoWindow: number | null;
  customStaticStartDate: DateTimeString | null;
  customStaticEndDate: DateTimeString | null;
  type: IntelReportType;
  name: string;
  productionMonthToConsider: DateTimeString | null;
  geoJSONGeometry: GeoJSON.Geometry | null;
  areaId: string | null;
  formation: string | null;
  companyBrandId: string | null;
  fieldName: string | null;
};

export type ProcessedIntelReportFilters = Omit<
  IntelReportFilters,
  'timespan'
> & {
  companyBrandName?: string;
  startDate: DateTimeString;
  endDate: DateTimeString;
};

export function processIntelReportFilters(
  filters: IntelReportFilters,
  companyBrandIdAndNameRecords: Record<string, string>
): ProcessedIntelReportFilters {
  const [startDate, endDate] = getDateRangeFromReportFilters(filters);
  const reversedRecords = Object.entries(companyBrandIdAndNameRecords).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {} as Record<string, string>
  );
  return {
    ...filters,
    startDate,
    endDate,
    companyBrandName: isNil(filters.companyBrandId)
      ? undefined
      : reversedRecords[filters.companyBrandId],
  };
}

// enum of report types
export const enum IntelReportType {
  Area = 'Area',
  Company = 'Company',
  Field = 'Field',
  Formation = 'Formation',
}

export const IntelReportTypes = [
  IntelReportType.Area,
  IntelReportType.Company,
  IntelReportType.Field,
  IntelReportType.Formation,
];

export type IntelReport = {
  id: string;
  filters: IntelReportFilters;
  emailSchedule: 'weekly' | 'monthly' | null;
  encodedFilters: string; // stored here for optimization
};

export function isEmailSchedule(
  arg: unknown
): arg is IntelReport['emailSchedule'] {
  return arg === 'weekly' || arg === 'monthly' || arg === null;
}

export type IntelReportData = {
  reportId: string;
  monthlySpuddedLicenses: IntelActivityItem[] | undefined;
  monthlyLicenses: IntelActivityItem[] | undefined;
  licencesYearOverYear: IntelActivityItem[];
  spudsYearOverYear: IntelActivityItem[];
  production: UWIWithMonthsProduction[];
  monthlyProduction: IntelReportProduction[] | undefined;
  isLoading: boolean;
  isLoadingYearOverYear: boolean;
  isLoadingMonthlyLicenses: boolean;
  isLoadingMonthlySpuddedLicenses: boolean;
  isLoadingProduction: boolean;
  isLoadingSpuds: boolean;
  isLoadingThreeMonthsProduction: boolean;
};

export type IntelReportWithData = IntelReport & IntelReportData;

export function compressAndEncodeReportSettings(settings: IntelReportFilters) {
  return compressAndEncode(settings);
}

export function decodeAndDecompressReportSettings(
  encodedString: string
): IntelReportFilters {
  return decodeAndDecompress<IntelReportFilters>(encodedString);
}

export function createIntelReport(filters: IntelReportFilters): IntelReport {
  return {
    id: generateTemporaryId(),
    filters,
    encodedFilters: compressAndEncodeReportSettings(filters),
    emailSchedule: null,
  };
}

export function createIntelReportData(reportId: string): IntelReportData {
  return {
    reportId,
    monthlySpuddedLicenses: undefined,
    monthlyLicenses: undefined,
    licencesYearOverYear: [],
    spudsYearOverYear: [],
    monthlyProduction: undefined,
    production: [],
    isLoading: false,
    isLoadingMonthlyLicenses: false,
    isLoadingYearOverYear: false,
    isLoadingMonthlySpuddedLicenses: false,
    isLoadingProduction: false,
    isLoadingSpuds: false,
    isLoadingThreeMonthsProduction: false,
  };
}

export type IntelReportProduction = {
  c2c3c4_bbl: number;
  c5_cond_bbl: number;
  c5_cond_boe_per_day: number;
  days_in_month: number;
  gas_boe_per_day: number;
  gas_mcf: number;
  month: string;
  ngl_boe_per_day: number;
  oil_bbl: number;
  total_bbl_oil: number;
  total_boe: number;
  total_boe_gas: number;
  total_boe_per_day: number;
};

function convertMCFtoBBL(mcf: number): number {
  return mcf / 6;
}

import { Box, Button, Typography } from '@mui/material';
import type { GeoPoint } from '@pn/core/domain/geography';
import { round } from 'lodash-es';

type Props = {
  geoPoint: GeoPoint;
  onGetDirections: (coords: GeoPoint) => void;
};

export function MapboxPopup({ geoPoint, onGetDirections }: Props) {
  return (
    <Box>
      <Typography variant="caption" textAlign="center">
        {round(geoPoint.lat, 5)}, {round(geoPoint.lon, 5)}
      </Typography>
      <Box mt={1}>
        <Button
          fullWidth
          color="inherit"
          variant="outlined"
          size="small"
          onClick={() => onGetDirections(geoPoint)}
        >
          Get Directions to Here
        </Button>
      </Box>
      {new Date() <= new Date('2024-12-15') && (
        <Box mt={1}>
          <Typography variant="body2" sx={{ p: 1, opacity: 0.75 }}>
            Home location is no longer available. Your browser now automatically
            remembers your last map position.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

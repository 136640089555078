import { Details } from '@pn/core/domain/data-info';
import { isNil, kebabCase } from 'lodash-es';
import React from 'react';

type Props = {
  featureName: string;
  details: Details | undefined;
};

export function useTabSelector({ featureName, details }: Props) {
  const storageKey = `${featureName}-details-tab-value`;

  const [tabValue, setTabValue] = React.useState(sessionStorage.getItem(storageKey));

  const isCurrentTabUnavailable = checkCurrentTabIsUnavailable(
    details,
    tabValue ?? ''
  );

  React.useEffect(() => {
    if (isNil(details)) return;

    if (isCurrentTabUnavailable) {
      const firstTab = kebabCase(details[0].tabName);
      setTabValue(firstTab);
    }
  }, [details, isCurrentTabUnavailable]);

  React.useEffect(() => {
    if (!isNil(tabValue)) {
      // console.log(`%csetting session storage ${storageKey} to ${tabValue}`, 'color: #888');
      sessionStorage.setItem(storageKey, tabValue);
    }
  }, [storageKey, tabValue]);

  return { tabValue, setTabValue, isCurrentTabUnavailable };
}

function checkCurrentTabIsUnavailable(
  details: Details | undefined,
  tabValue: string | undefined
) {
  if (isNil(tabValue)) {
    return false;
  }

  const tabValuesAvailable = (details ?? []).map((detailsTab) =>
    kebabCase(detailsTab.tabName)
  );

  return !tabValuesAvailable.includes(tabValue);
}

import { toMappingItem, type MappingItem } from '@pn/core/domain/data';
import { isNil } from 'lodash-es';
import type { ElasticMappingItem, ElasticSourceType } from './types';

function elasticSourceTypeToDomainType(
  sourceType: ElasticSourceType
): MappingItem['domainType'] {
  switch (sourceType) {
    case 'boolean':
      return 'boolean';
    case 'integer':
    case 'double':
      return 'number';
    case 'keyword':
    case 'text':
      return 'string';
    case 'date':
      return 'DateString';
    case 'geo_shape':
      return 'GeoShape';
    default:
      throw new Error(`Invalid elastic source type: ${sourceType}`);
  }
}

export const elasticToDomainMapping = (
  elasticMapping: ElasticMappingItem[]
): MappingItem[] => {
  return elasticMapping.map(({ sourceType, ...item }) => {
    const domainType =
      item.domainType ?? elasticSourceTypeToDomainType(sourceType);

    return toMappingItem({
      ...item,
      source: {
        type: sourceType,
      },
      domainType,
      domainTypeAttributes:
        domainType === 'DateString' && isNil(item.domainTypeAttributes)
          ? { format: 'yyyy-MM-dd' }
          : item.domainTypeAttributes,
    });
  });
};

export const generateElasticTemplateMapping = (
  elasticMapping: ElasticMappingItem[]
) => {
  return {
    dynamic: 'strict',
    dynamic_templates: [],
    properties: elasticMapping.reduce(
      (acc, item) => ({
        ...acc,
        [item.field]: {
          type: item.sourceType,
          format: item.sourceType === 'date' ? 'yyyy-MM-dd' : undefined,
          fields:
            item.sourceType === 'text' && !item.isNotSortable
              ? {
                  keyword: {
                    ignore_above: 256,
                    type: 'keyword',
                  },
                }
              : undefined,
        },
      }),
      {}
    ),
  };
};

export function dumpElasticMapping(params: {
  rawMapping: ElasticMappingItem[];
  dumpRaw: boolean;
  dumpElasticTemplate: boolean;
}): void {
  const { rawMapping, dumpRaw, dumpElasticTemplate } = params;

  if (dumpRaw) {
    console.log('raw mapping', rawMapping);
  }

  if (dumpElasticTemplate) {
    const elasticTemplateMapping = generateElasticTemplateMapping(rawMapping);
    console.log(JSON.stringify(elasticTemplateMapping));
  }
}

import { _reference } from './pn/_reference';
import { elevationContours } from './pn/elevationContours';
import { grids } from './pn/grids';
import { mapboxSatellite } from './pn/mapboxSatellite';

export const pnMapboxLayers = [
  ...elevationContours,
  mapboxSatellite,
  _reference,
  ...grids('dark'),
] as mapboxgl.AnyLayer[];

export const bottomGridsLayerId = 'ab-township-labels';

export const gridLayerIds: Record<string, string[]> = {
  ca_township: [
    'ab-township-grid',
    'ab-township-labels',
    'bc-township-grid',
    'bc-township-labels',
    'mb-township-grid',
    'mb-township-labels',
    'sk-township-grid',
    'sk-township-labels',
  ],
  ca_section: [
    'ab-section-grid',
    'ab-section-labels',
    'bc-section-grid',
    'bc-section-labels',
    'mb-section-grid',
    'mb-section-labels',
    'sk-section-grid',
    'sk-section-labels',
  ],
  ca_quarter_section: [
    'ab-quarter-section-grid',
    'ab-quarter-section-labels',
    'bc-quarter-section-grid',
    'bc-quarter-section-labels',
    'sk-quarter-section-grid',
    'sk-quarter-section-labels',
  ],
  ca_lsd: [
    'ab-lsd-grid',
    'ab-lsd-labels',
    'bc-lsd-grid-v2',
    'bc-lsd-labels',
    'sk-lsd-grid',
    'sk-lsd-labels',
  ],
  ca_block: ['bc-nts-grid', 'bc-nts-labels'],
  ca_unit: ['nts-unit-grid', 'nts-unit-labels'],
  us_township: ['us-township-grid', 'us-township-labels'],
  us_section: ['us-section-grid', 'us-section-labels'],
};

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { isCordova } from '@pn/core/utils/env';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  variant: 'outlined' | 'filled';
  topBorder: boolean;
  squareTop: boolean;
}>()((theme, { variant, topBorder, squareTop }) => ({
  searchIconButton: {},
  searchIconButtonDisabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  inputRoot: {
    height: 39,
    paddingLeft: theme.spacing(1),
    backgroundColor:
      variant === 'outlined' ? theme.palette.background.paper : undefined,
    borderTop: topBorder ? `1px solid ${theme.palette.divider}` : 'none',
    '&::before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    borderRadius: variant === 'filled' ? 0 : theme.shape.borderRadius,
    borderTopLeftRadius: squareTop ? 0 : theme.shape.borderRadius,
    borderTopRightRadius: squareTop ? 0 : theme.shape.borderRadius,
  },
  inputInput: {
    padding: 0,
  },
}));

type Props = {
  variant?: 'outlined' | 'filled';
  topBorder?: boolean;
  squareTop?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  searchText: string;
  setSearchText: (searchText: string) => void;
  skipDebounce: () => void;
};

export const LibrarySearch = React.memo(_LibrarySearch);
function _LibrarySearch({
  variant = 'outlined',
  topBorder = false,
  squareTop = false,
  disabled = false,
  autoFocus = false,
  searchText,
  setSearchText,
  skipDebounce,
}: Props) {
  const { classes } = useStyles({ variant, topBorder, squareTop });
  const { xsScreen } = useScreenSize();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      skipDebounce();
    }
  };

  useHotkeys('ctrl+/', (e) => {
    e.preventDefault();

    const searchbarElem = document.querySelectorAll(
      '[data-cy="library-searchbar"]'
    )[0];

    (searchbarElem as HTMLInputElement).focus();
  });

  return (
    <TextField
      fullWidth
      hiddenLabel
      variant={variant}
      type="search"
      autoFocus={autoFocus}
      autoComplete="off"
      placeholder={`Search library… ${
        !xsScreen && !isCordova() ? '(Ctrl + /)' : ''
      }`}
      disabled={disabled}
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      onKeyDown={handleKeyDown}
      slotProps={{
        htmlInput: {
          'aria-label': 'search',
          'data-cy': 'library-searchbar',
        },
        input: {
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="clear search value"
                className={
                  isEmpty(searchText)
                    ? classes.searchIconButtonDisabled
                    : classes.searchIconButton
                }
                onClick={() => setSearchText('')}
              >
                {isEmpty(searchText) ? <SearchIcon /> : <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
}

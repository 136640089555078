import type { CustomSVGIconData } from '@pn/core/domain/layer';

export const abandonedGasIcon: CustomSVGIconData = {
  pathData: [
    'M15.401,7.406h-2.931c-0.104-0.786-0.406-1.519-0.89-2.146l2.043-2.044c0.232-0.232,0.232-0.608,0-0.84 c-0.231-0.231-0.607-0.231-0.839,0L10.741,4.42c-0.63-0.483-1.362-0.786-2.148-0.89V0.599c0-0.328-0.265-0.594-0.593-0.594 S7.407,0.271,7.407,0.599V3.53C6.621,3.634,5.888,3.937,5.26,4.42L3.216,2.376c-0.232-0.232-0.608-0.232-0.84,0 c-0.232,0.231-0.232,0.607,0,0.84l2.045,2.045c-0.492,0.643-0.789,1.38-0.89,2.146H0.599C0.271,7.406,0.005,7.672,0.005,8 s0.266,0.594,0.594,0.594h2.933c0.101,0.767,0.398,1.503,0.89,2.146l-2.046,2.046c-0.232,0.231-0.232,0.607,0,0.84 c0.115,0.115,0.268,0.174,0.42,0.174c0.151,0,0.304-0.059,0.42-0.174L5.26,11.58c0.628,0.483,1.361,0.786,2.147,0.89v2.933 c0,0.327,0.265,0.593,0.593,0.593s0.593-0.266,0.593-0.593V12.47c0.786-0.104,1.519-0.406,2.148-0.89l2.044,2.044 c0.116,0.116,0.269,0.175,0.42,0.175s0.304-0.059,0.419-0.175c0.232-0.231,0.232-0.607,0-0.839l-2.044-2.044 c0.482-0.629,0.786-1.361,0.89-2.147h2.932c0.328,0,0.594-0.266,0.594-0.594S15.729,7.406,15.401,7.406z M11.341,8 c0,0.691-0.213,1.347-0.601,1.902L8.84,8l1.901-1.901C11.128,6.654,11.341,7.311,11.341,8z M8,11.34 c-0.69,0-1.346-0.213-1.901-0.599L8,8.84l1.902,1.901C9.347,11.127,8.691,11.34,8,11.34z M5.261,6.1l1.9,1.9l-1.9,1.9 C4.47,8.76,4.47,7.24,5.261,6.1z M9.901,5.26L8,7.161L6.099,5.26C6.654,4.873,7.31,4.66,8,4.66C8.691,4.66,9.347,4.873,9.901,5.26z',
  ],
  viewBox: '0 0 16 16',
};

export const abandonedInjectionIcon: CustomSVGIconData = {
  pathData: [
    'M0.221,1.06l4.201,4.201c-0.492,0.643-0.79,1.38-0.891,2.146H2.032C1.705,7.406,1.438,7.673,1.438,8 c0,0.328,0.267,0.595,0.594,0.595h1.499c0.126,0.954,0.536,1.876,1.268,2.607c0.713,0.712,1.625,1.139,2.607,1.269v1.497 c0,0.328,0.266,0.594,0.594,0.594s0.594-0.266,0.594-0.594v-1.497c0.786-0.104,1.519-0.407,2.146-0.891l3.187,3.187h-2.503 c-0.328,0-0.593,0.266-0.593,0.594s0.265,0.594,0.593,0.594h3.937c0.077,0,0.153-0.016,0.227-0.046 c0.146-0.061,0.262-0.177,0.321-0.322c0.019-0.046,0.022-0.095,0.029-0.143c0.004-0.028,0.017-0.054,0.017-0.083v-3.936 c0-0.328-0.266-0.594-0.594-0.594s-0.594,0.266-0.594,0.594v2.503l-3.187-3.187c0.482-0.629,0.786-1.36,0.889-2.146h1.499 c0.328,0,0.594-0.267,0.594-0.595c0-0.327-0.266-0.594-0.594-0.594h-1.499c-0.129-0.982-0.555-1.895-1.268-2.607 c-0.712-0.713-1.625-1.14-2.607-1.269V2.032c0-0.327-0.266-0.594-0.594-0.594S7.406,1.705,7.406,2.032V3.53 C6.62,3.634,5.888,3.938,5.259,4.42L1.06,0.221c-0.231-0.232-0.607-0.232-0.839,0C-0.012,0.452-0.012,0.828,0.221,1.06z M10.362,10.362c-0.488,0.488-1.103,0.802-1.769,0.921V8.595h2.688C11.163,9.261,10.851,9.874,10.362,10.362z M7.406,11.283 c-0.666-0.119-1.279-0.433-1.769-0.921c-0.5-0.499-0.804-1.12-0.92-1.768h2.688V11.283z M8.594,7.406V4.718 c0.666,0.119,1.28,0.433,1.769,0.921s0.801,1.103,0.92,1.768H8.594z M7.406,7.406H4.718c0.116-0.648,0.42-1.268,0.92-1.768 C6.127,5.15,6.74,4.837,7.406,4.718V7.406z',
  ],
  viewBox: '0 0 16 16',
};

export const abandonedOilIcon: CustomSVGIconData = {
  pathData: [
    'M8,15.954c-0.328,0-0.593-0.266-0.593-0.594V0.64c0-0.328,0.265-0.594,0.593-0.594S8.593,0.312,8.593,0.64V15.36 C8.593,15.688,8.328,15.954,8,15.954z',
    'M15.36,8.594H0.64c-0.328,0-0.594-0.267-0.594-0.595c0-0.327,0.266-0.593,0.594-0.593H15.36 c0.328,0,0.594,0.266,0.594,0.593C15.954,8.327,15.688,8.594,15.36,8.594z',
    'M10.362,5.638C9.731,5.007,8.893,4.659,8,4.659c-0.892,0-1.731,0.348-2.362,0.979c-1.302,1.303-1.302,3.422,0,4.724 C6.269,10.992,7.108,11.34,8,11.34c0.893,0,1.731-0.348,2.362-0.979c0.631-0.63,0.979-1.47,0.979-2.362 C11.341,7.107,10.993,6.269,10.362,5.638z',
  ],
  viewBox: '0 0 16 16',
};

export const abandonedSourceIcon: CustomSVGIconData = {
  pathData: [
    'M15.779,0.221c-0.23-0.232-0.607-0.232-0.84,0l-3.36,3.361c-1.015-0.824-2.255-1.296-3.58-1.296 c-1.324,0-2.565,0.472-3.58,1.295l-3.36-3.36c-0.232-0.232-0.607-0.232-0.84,0c-0.231,0.231-0.231,0.607,0,0.84l3.361,3.361 c-1.691,2.088-1.691,5.07,0,7.158L0.22,14.941c-0.231,0.231-0.231,0.607,0,0.84c0.116,0.115,0.269,0.173,0.42,0.173 c0.152,0,0.305-0.058,0.42-0.173l3.36-3.36c1.015,0.823,2.255,1.294,3.579,1.294c1.325,0,2.565-0.471,3.579-1.295l3.361,3.361 c0.117,0.115,0.27,0.173,0.42,0.173c0.152,0,0.305-0.058,0.42-0.173c0.232-0.232,0.232-0.608,0-0.84l-3.361-3.361 c0.824-1.014,1.295-2.255,1.295-3.579s-0.471-2.565-1.294-3.579l3.36-3.361C16.012,0.828,16.012,0.452,15.779,0.221z M10.17,4.991 L9.316,5.845C8.922,5.604,8.473,5.469,8,5.469S7.077,5.604,6.684,5.845L5.831,4.992L5.259,4.42c0.786-0.604,1.733-0.947,2.74-0.947 c1.007,0,1.955,0.344,2.741,0.948L10.17,4.991z M4.992,5.833l0.852,0.852c-0.492,0.807-0.492,1.826,0,2.633l-0.852,0.851 L4.421,10.74c-1.238-1.616-1.238-3.862,0-5.479L4.992,5.833z M5.831,11.01l0.854-0.854C7.078,10.397,7.527,10.533,8,10.533 s0.922-0.136,1.315-0.377l0.854,0.854l0.57,0.57c-0.786,0.604-1.733,0.948-2.74,0.948s-1.954-0.344-2.74-0.947L5.831,11.01z M11.009,10.17l-0.853-0.853c0.491-0.807,0.491-1.826,0-2.633l0.854-0.854l0.57-0.57c0.604,0.786,0.945,1.732,0.945,2.74 c0,1.007-0.343,1.954-0.946,2.739L11.009,10.17z',
  ],
  viewBox: '0 0 16 16',
};

export const dryOrAbandonedIcon: CustomSVGIconData = {
  pathData: [
    'M15.401,7.407H12.47c-0.129-0.983-0.556-1.896-1.268-2.608c-0.713-0.713-1.626-1.14-2.609-1.269V0.599 c0-0.328-0.265-0.594-0.593-0.594S7.407,0.271,7.407,0.599V3.53C6.424,3.659,5.511,4.086,4.799,4.799 C4.067,5.53,3.657,6.453,3.531,7.407H0.599C0.271,7.407,0.005,7.672,0.005,8s0.266,0.594,0.594,0.594h2.933 c0.125,0.954,0.536,1.876,1.268,2.607c0.712,0.713,1.625,1.14,2.608,1.269v2.932c0,0.328,0.265,0.594,0.593,0.594 s0.593-0.266,0.593-0.594V12.47c0.983-0.129,1.896-0.556,2.609-1.269c0.712-0.712,1.138-1.625,1.267-2.607h2.933 c0.328,0,0.594-0.266,0.594-0.594S15.729,7.407,15.401,7.407z M11.341,8c0,0.893-0.348,1.731-0.979,2.362S8.893,11.34,8,11.34 c-0.892,0-1.731-0.347-2.362-0.978c-1.302-1.303-1.302-3.422,0-4.725C6.269,5.007,7.108,4.66,8,4.66 c0.893,0,1.731,0.347,2.362,0.978C10.993,6.27,11.341,7.108,11.341,8z',
  ],
  viewBox: '0 0 16 16',
};

export const gasIcon: CustomSVGIconData = {
  pathData: [
    'M15.401,7.407h-2.931c-0.103-0.786-0.407-1.519-0.89-2.147l2.043-2.044c0.232-0.232,0.232-0.608,0-0.84 c-0.231-0.232-0.607-0.232-0.839,0L10.741,4.42C10.112,3.937,9.38,3.633,8.593,3.529V0.599c0-0.328-0.265-0.594-0.593-0.594 S7.407,0.271,7.407,0.599v2.931C6.621,3.633,5.888,3.937,5.259,4.42L3.216,2.376c-0.232-0.232-0.608-0.232-0.84,0 c-0.232,0.231-0.232,0.607,0,0.84l2.045,2.046C3.93,5.904,3.632,6.641,3.532,7.407H0.599C0.271,7.407,0.005,7.673,0.005,8 s0.266,0.594,0.594,0.594h2.933c0.101,0.767,0.398,1.503,0.89,2.146l-2.046,2.046c-0.232,0.232-0.232,0.607,0,0.84 c0.115,0.115,0.268,0.174,0.42,0.174c0.151,0,0.304-0.059,0.42-0.174L5.26,11.58c0.628,0.483,1.361,0.786,2.147,0.89v2.933 c0,0.327,0.265,0.593,0.593,0.593s0.593-0.266,0.593-0.593V12.47c0.786-0.104,1.519-0.406,2.148-0.89l2.044,2.045 c0.116,0.115,0.269,0.173,0.42,0.173s0.304-0.058,0.419-0.173c0.232-0.232,0.232-0.607,0-0.84l-2.044-2.044 c0.483-0.629,0.786-1.36,0.89-2.147h2.932c0.328,0,0.594-0.267,0.594-0.594S15.729,7.407,15.401,7.407z M11.341,8 c0,0.893-0.348,1.731-0.979,2.362S8.893,11.341,8,11.341c-0.892,0-1.731-0.348-2.362-0.979c-1.302-1.303-1.302-3.422,0-4.724 C6.269,5.008,7.108,4.66,8,4.66c0.893,0,1.731,0.348,2.362,0.979S11.341,7.108,11.341,8z',
  ],
  viewBox: '0 0 16 16',
};

export const injectionIcon: CustomSVGIconData = {
  pathData: [
    'M11.202,4.799C10.347,3.943,9.209,3.473,8,3.473c-1.008,0-1.955,0.344-2.741,0.947L1.06,0.221 c-0.232-0.232-0.607-0.232-0.84,0c-0.231,0.231-0.231,0.607,0,0.839l4.201,4.202c-1.357,1.773-1.243,4.319,0.378,5.94 C5.653,12.057,6.791,12.527,8,12.527c1.007,0,1.954-0.343,2.74-0.946l3.187,3.187h-2.502c-0.328,0-0.594,0.266-0.594,0.593 c0,0.328,0.266,0.594,0.594,0.594h3.936c0.077,0,0.154-0.016,0.227-0.046c0.049-0.02,0.088-0.054,0.129-0.085 c0.021-0.016,0.045-0.023,0.064-0.042c0.021-0.021,0.029-0.047,0.046-0.07c0.03-0.039,0.062-0.076,0.082-0.122 c0.03-0.073,0.046-0.152,0.046-0.23v-3.934c0-0.328-0.266-0.594-0.594-0.594s-0.594,0.266-0.594,0.594v2.503l-3.187-3.187 C12.184,9.955,12.527,9.009,12.528,8C12.528,6.791,12.058,5.654,11.202,4.799z M10.362,10.362C9.731,10.993,8.893,11.341,8,11.341 c-0.892,0-1.731-0.348-2.362-0.979c-1.302-1.302-1.302-3.421,0-4.725C6.269,5.008,7.108,4.66,8,4.66 c0.893,0,1.731,0.348,2.362,0.978C10.993,6.27,11.341,7.108,11.341,8C11.341,8.893,10.993,9.731,10.362,10.362z',
  ],
  viewBox: '0 0 16 16',
};

export const locationIcon: CustomSVGIconData = {
  pathData: [
    'M7.999,12.527c1.209,0,2.347-0.471,3.201-1.326c0.855-0.855,1.326-1.992,1.326-3.201c0-1.21-0.471-2.347-1.326-3.202 c-0.854-0.854-1.992-1.326-3.201-1.326S5.652,3.944,4.798,4.798c-1.766,1.766-1.766,4.637,0,6.403 C5.653,12.057,6.79,12.527,7.999,12.527z M7.999,4.66c0.893,0,1.731,0.348,2.362,0.979S11.34,7.108,11.34,8 c0,0.892-0.348,1.73-0.979,2.361s-1.47,0.979-2.362,0.979s-1.73-0.348-2.362-0.979c-1.302-1.301-1.302-3.421,0-4.723 C6.269,5.007,7.106,4.66,7.999,4.66z',
  ],
  viewBox: '0 0 16 16',
};

export const oilIcon: CustomSVGIconData = {
  pathData: [
    'M10.362,5.637C9.731,5.006,8.892,4.658,7.999,4.658S6.268,5.006,5.636,5.637c-1.304,1.303-1.304,3.424,0,4.727 c0.632,0.632,1.471,0.979,2.363,0.979s1.732-0.347,2.363-0.979C10.994,9.732,11.342,8.893,11.342,8S10.994,6.268,10.362,5.637z',
  ],
  viewBox: '0 0 16 16',
};

export const sourceIcon: CustomSVGIconData = {
  pathData: [
    'M3.958,3.959c-2.229,2.229-2.229,5.854,0,8.082c1.08,1.079,2.515,1.674,4.041,1.674s2.961-0.595,4.041-1.674 c1.079-1.079,1.674-2.515,1.674-4.041s-0.595-2.962-1.674-4.041C10.96,2.88,9.525,2.285,7.999,2.285S5.038,2.88,3.958,3.959z M7.999,12.527c-1.209,0-2.347-0.471-3.201-1.326c-1.766-1.765-1.766-4.637,0-6.402C5.652,3.943,6.79,3.473,7.999,3.473 s2.346,0.471,3.201,1.326S12.526,6.79,12.526,8c0,1.209-0.471,2.346-1.326,3.201S9.208,12.527,7.999,12.527z',
    'M9.792,6.209C9.312,5.73,8.677,5.467,8,5.467S6.688,5.73,6.209,6.209c-0.987,0.988-0.987,2.594,0,3.582 C6.688,10.27,7.323,10.533,8,10.533s1.312-0.264,1.792-0.742C10.778,8.803,10.778,7.197,9.792,6.209z',
  ],
  viewBox: '0 0 16 16',
};

export const suspendedGasIcon: CustomSVGIconData = {
  pathData: [
    'M15.401,7.406h-2.933c-0.103-0.786-0.406-1.519-0.889-2.146l2.044-2.044c0.232-0.231,0.232-0.607,0-0.84 c-0.231-0.231-0.607-0.231-0.839,0L10.74,4.42C10.111,3.937,9.379,3.633,8.593,3.53V0.598c0-0.327-0.265-0.593-0.593-0.593 S7.407,0.271,7.407,0.598V3.53C6.621,3.633,5.889,3.937,5.26,4.419L3.216,2.375c-0.232-0.231-0.608-0.231-0.84,0 c-0.232,0.232-0.232,0.608,0,0.84l2.046,2.046C3.93,5.904,3.632,6.64,3.531,7.406H0.599C0.271,7.406,0.005,7.672,0.005,8 s0.266,0.594,0.594,0.594h2.933c0.101,0.767,0.398,1.503,0.891,2.146l-2.046,2.045c-0.232,0.232-0.232,0.607,0,0.84 c0.115,0.115,0.268,0.174,0.419,0.174c0.152,0,0.305-0.059,0.421-0.174L5.26,11.58c0.629,0.482,1.361,0.787,2.147,0.891v2.932 c0,0.326,0.265,0.592,0.593,0.592s0.593-0.266,0.593-0.592v-2.932c0.786-0.104,1.52-0.408,2.148-0.891l2.044,2.043 c0.116,0.117,0.268,0.176,0.42,0.176c0.151,0,0.304-0.059,0.419-0.176c0.232-0.23,0.232-0.605,0-0.838L11.58,10.74 c0.482-0.629,0.786-1.359,0.889-2.146h2.933c0.328,0,0.594-0.266,0.594-0.594S15.729,7.406,15.401,7.406z M11.341,8 c0,0.69-0.213,1.345-0.601,1.902L6.099,5.259C6.654,4.873,7.31,4.66,8,4.66c0.893,0,1.731,0.348,2.362,0.979 C10.993,6.268,11.341,7.108,11.341,8z M8,11.34c-0.892,0-1.731-0.348-2.362-0.979C4.479,9.203,4.358,7.402,5.261,6.1l4.642,4.64 C9.347,11.127,8.691,11.34,8,11.34z',
  ],
  viewBox: '0 0 16 16',
};

export const suspendedInjectionIcon: CustomSVGIconData = {
  pathData: [
    'M0.221,15.78c0.115,0.115,0.268,0.174,0.418,0.174c0.152,0,0.305-0.059,0.421-0.174l4.2-4.2 c0.786,0.604,1.732,0.947,2.74,0.947c1.007,0,1.954-0.344,2.74-0.947l3.187,3.187h-2.503c-0.328,0-0.593,0.266-0.593,0.594 c0,0.327,0.265,0.594,0.593,0.594h3.937c0.076,0,0.153-0.016,0.226-0.046c0.146-0.061,0.262-0.177,0.322-0.322 c0.021-0.05,0.025-0.103,0.031-0.155c0.003-0.024,0.015-0.046,0.015-0.07v-3.936c0-0.328-0.266-0.595-0.594-0.595 s-0.594,0.267-0.594,0.595v2.503l-3.188-3.188C12.184,9.954,12.527,9.008,12.527,8c0-1.008-0.344-1.954-0.947-2.739l4.199-4.2 c0.232-0.232,0.232-0.608,0-0.84c-0.231-0.232-0.607-0.232-0.839,0l-4.2,4.2C9.954,3.816,9.007,3.472,8,3.472 c-1.008,0-1.955,0.344-2.741,0.948L1.06,0.22c-0.231-0.232-0.607-0.232-0.839,0c-0.232,0.231-0.232,0.607,0,0.84l4.2,4.201 c-1.237,1.616-1.237,3.862,0,5.478l-4.2,4.201C-0.012,15.173-0.012,15.548,0.221,15.78z M5.638,5.638C6.27,5.007,7.107,4.66,8,4.66 c0.69,0,1.347,0.213,1.901,0.6L5.261,9.9C4.358,8.599,4.479,6.797,5.638,5.638z M10.362,10.362C9.731,10.993,8.893,11.341,8,11.341 c-0.691,0-1.347-0.213-1.901-0.6l4.643-4.642c0.387,0.555,0.6,1.21,0.6,1.9C11.341,8.892,10.993,9.731,10.362,10.362z',
  ],
  viewBox: '0 0 16 16',
};

export const suspendedOilIcon: CustomSVGIconData = {
  pathData: [
    'M15.36,15.954c-0.152,0-0.304-0.058-0.42-0.174L0.22,1.06c-0.231-0.231-0.231-0.607,0-0.84 c0.232-0.231,0.607-0.231,0.84,0L15.78,14.941c0.232,0.231,0.232,0.607,0,0.839C15.664,15.896,15.513,15.954,15.36,15.954z',
    'M10.362,5.638C9.731,5.007,8.893,4.659,8,4.659c-0.892,0-1.731,0.348-2.362,0.979c-1.302,1.303-1.302,3.422,0,4.725 C6.269,10.994,7.108,11.341,8,11.341c0.893,0,1.731-0.347,2.362-0.979c0.631-0.631,0.979-1.47,0.979-2.362 C11.341,7.108,10.993,6.27,10.362,5.638z',
  ],
  viewBox: '0 0 16 16',
};

export const suspendedSourceIcon: CustomSVGIconData = {
  pathData: [
    'M0.221,1.06l3.361,3.36c-1.816,2.243-1.708,5.536,0.376,7.621c1.079,1.08,2.515,1.674,4.041,1.674 c1.325,0,2.566-0.471,3.581-1.296l3.361,3.361c0.115,0.116,0.268,0.174,0.419,0.174c0.152,0,0.304-0.058,0.42-0.174 c0.231-0.231,0.231-0.608,0-0.84l-3.361-3.36c0.823-1.015,1.295-2.256,1.295-3.58c0-1.526-0.595-2.961-1.674-4.041 c-1.079-1.078-2.515-1.674-4.041-1.674c-1.324,0-2.565,0.472-3.58,1.295L1.061,0.221c-0.232-0.232-0.608-0.232-0.84,0 C-0.012,0.452-0.012,0.827,0.221,1.06z M5.844,6.684C5.25,7.658,5.368,8.949,6.209,9.791C6.688,10.27,7.324,10.533,8,10.533 c0.473,0,0.923-0.136,1.316-0.377l0.854,0.853l0.571,0.571c-0.787,0.604-1.734,0.947-2.742,0.947c-1.209,0-2.347-0.471-3.202-1.325 C3.176,9.58,3.063,7.033,4.421,5.26l0.572,0.572L5.844,6.684z M11.01,10.17l-0.854-0.854c0.595-0.975,0.478-2.266-0.364-3.107 C9.312,5.73,8.676,5.468,8,5.468c-0.473,0-0.922,0.136-1.316,0.376L5.831,4.99L5.259,4.419c0.786-0.604,1.733-0.946,2.74-0.946 c1.209,0,2.347,0.471,3.202,1.326S12.526,6.791,12.526,8c0,1.007-0.344,1.954-0.946,2.74L11.01,10.17z',
  ],
  viewBox: '0 0 16 16',
};

import { moduleItemIds } from '@pn/core/storage/workspace/pnWorkspaceItems';
import type { CheckedState } from './types';

export function getProjectCheckedState(
  projectItemIds: string[],
  idsInWorkspace: string[]
): CheckedState {
  const countableProjectItemIds = projectItemIds.filter(
    (id) => !moduleItemIds.includes(id)
  );
  const projectItemIdsInWorkspace = countableProjectItemIds.filter((id) =>
    idsInWorkspace.includes(id)
  );

  if (projectItemIdsInWorkspace.length === 0) {
    return 'unchecked';
  }

  if (projectItemIdsInWorkspace.length === countableProjectItemIds.length) {
    return 'checked';
  }

  return 'indeterminate';
}

import { murmurhash3 } from '@pn/services/utils/hash';
import { isString } from 'lodash-es';
import type { MapboxIMap } from '..';

export const setFeatureState: MapboxIMap['setFeatureState'] = function (
  this: MapboxIMap,
  params
) {
  const { sourceId, sourceLayer = undefined, featureId, featureState } = params;

  if (!this._native.getSource(sourceId)) {
    // console.warn('Source not found');
    return;
  }

  this._native.setFeatureState(
    {
      source: sourceId,
      sourceLayer,
      id: isString(featureId) ? murmurhash3(featureId) : featureId,
    },
    featureState
  );
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link,
  Typography,
} from '@mui/material';
import { handleError } from '@pn/core/errors/handleError';
import { tokenManager } from '@pn/core/services/authentication/tokenManager';
import { useCurrentUserStorage, workspaceActions } from '@pn/core/storage';
import env, { isProduction } from '@pn/core/utils/env';
import { apiLayerItemMapper } from '@pn/services/api/layer/apiLayerMapper';
import { apiLayerProvider } from '@pn/services/api/layer/apiLayerProvider';
import type { ApiLayerItem } from '@pn/services/api/layer/types';
import { useStyledReactDropzone } from '@pn/ui/hooks/useStyledReactDropzone';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import ActionCable from 'actioncable';
import { every, isEmpty, isNil, noop } from 'lodash-es';
import React from 'react';
import type { FileRejection } from 'react-dropzone';
import { notificationService } from 'src/application/externalDependencies';
import { useLibrary } from 'src/ui/workspace/library/LibraryProvider';
import { useUploadDialog } from 'src/ui/workspace/upload-dialog/UploadDialogProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogPaper: {
    background: theme.palette.background.paper,
  },
  faded: {
    color: theme.palette.text.secondary,
  },
  bold: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
}));

const SOURCE_TYPES = [
  {
    label: 'GeoJSON',
    value: 'geojson',
    mimeType: 'application/json',
    extensions: ['.geojson'],
    fileType: 'GeoJSON',
    documentationUrl: 'https://en.wikipedia.org/wiki/GeoJSON',
  },
  // {
  //   label: 'GeoTIFF',
  //   value: 'raster',
  //   mimeType: 'image/tiff',
  //   extensions: ['.tif', '.tiff'],
  //   fileType: 'GeoTIFF',
  //   documentationUrl: 'https://en.wikipedia.org/wiki/GeoTIFF',
  // },
  {
    label: 'Shapefile',
    value: 'shapefile',
    mimeType: 'application/zip',
    extensions: ['.zip'],
    fileType: 'Shapefile',
    documentationUrl: 'https://en.wikipedia.org/wiki/Shapefile',
  },
  {
    label: 'Petro Ninja Land',
    value: 'land',
    mimeType: 'text/csv',
    extensions: ['.csv'],
    fileType: 'CSV',
    documentationUrl: 'https://en.wikipedia.org/wiki/Comma-separated_values',
  },
];

const accept = SOURCE_TYPES.reduce<Record<string, string[]>>(
  (acc, sourceType) => {
    acc[sourceType.mimeType] = sourceType.extensions;
    return acc;
  },
  {}
);

const acceptableFileExtensions = SOURCE_TYPES.reduce<string[]>(
  (acc, source) => [...acc, ...source.extensions],
  []
);

const MAX_FILE_SIZE = isProduction() ? 50e6 : 200e6; // MB

type ActionCableEvent = {
  type: string;
  payload: ApiLayerItem;
};

export const UploadDialog = React.memo(_UploadDialog);
function _UploadDialog() {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();

  const { isUploadDialogOpen, closeUploadDialog } = useUploadDialog();

  const { openWorkspaceItemPanel } = useWorkspaceItemPanel();
  const { closeLibrary } = useLibrary();

  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [inProgress, setInProgress] = React.useState(false);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);

  const onDropRejected = React.useCallback((rejectedFiles: FileRejection[]) => {
    const error = rejectedFiles[0].errors[0];

    if (error.code === 'file-too-large') {
      notificationService.notify(
        `Maximum file size is ${MAX_FILE_SIZE / 1e6} MB`,
        'error'
      );
    } else if (error.code === 'file-invalid-type') {
      notificationService.notify(
        `Supported file types are: ${acceptableFileExtensions.join(', ')}`,
        'error'
      );
    }

    setFile(undefined);
  }, []);

  const { getRootProps, getInputProps, isDragActive, style } =
    useStyledReactDropzone({
      onDrop,
      onDropRejected,
      accept,
      multiple: false,
      maxSize: MAX_FILE_SIZE,
    });

  React.useEffect(() => {
    if (
      !isNil(file) &&
      every(
        acceptableFileExtensions,
        (extension) => !file.name.toLowerCase().endsWith(extension)
      )
    ) {
      setFile(undefined);

      notificationService.notify(
        'Only the following file extensions are allowed: ' +
          acceptableFileExtensions.join(', '),
        'error'
      );
    }
  }, [file]);

  const handleSubmit = async () => {
    if (isNil(file)) {
      notificationService.notify('Source file is required', 'error');
      return;
    }

    setInProgress(true);

    try {
      const { token } = await tokenManager.get()();
      const cable = ActionCable.createConsumer(
        `${env.PN_WS_URL}/cable?token=${token}`
      );

      const subscription: ActionCable.Channel = cable.subscriptions.create(
        { channel: 'LayerChannel', user_id: user?.id },
        {
          received: (event: ActionCableEvent) => handleEvent(event),
        }
      );

      const handleEvent = (event: ActionCableEvent) => {
        console.log('%cAction Cable event', 'color:#FF5722', event);
        if (event.type === 'file_processed') {
          const item = apiLayerItemMapper.toWorkspaceItem(event.payload);

          // item.name = `Uploaded layer ${format(new Date(), `MMM d`)}`;
          item.name = '';
          item.isTemporary = true;

          workspaceActions().create(item);
          workspaceActions().addToWorkspace(item.id);
          workspaceActions().select(item.id);

          openWorkspaceItemPanel({ item });
          closeLibrary();
          closeUploadDialog();

          subscription.unsubscribe();
          cable.disconnect();

          setInProgress(false);
          setFile(undefined);
        } else if (event.type === 'file_error') {
          notificationService.notify('Upload failed', 'error');
          setInProgress(false);
        }
      };

      await apiLayerProvider.getNewLayerWorkspaceItem(file);
    } catch (error) {
      if (error instanceof Error) {
        handleError({
          error,
          userFriendlyMessage: 'Upload error: ' + error.message,
        });
      }

      setInProgress(false);
      setFile(undefined);
    }
  };

  return (
    <Dialog
      fullWidth
      open={isUploadDialogOpen}
      classes={{ paper: classes.dialogPaper }}
      onClose={inProgress ? noop : closeUploadDialog}
    >
      <DialogTitle>Upload GIS file</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Accepted file types: .geojson, .zip (shapefile), .csv (
          <Link
            className={classes.link}
            href="https://petro-ninja-web.s3.amazonaws.com/sample_land_csv.csv"
          >
            sample land CSV
          </Link>
          )
        </DialogContentText>
        <DialogContentText>GeoJSON has to conform EPSG:4326</DialogContentText>
        <Box mt={2}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the file here...</p>
            ) : (
              <p>Drag 'n' drop your file here, or click to select manually</p>
            )}
          </div>
          <Box mt={2} />
          <Typography variant="body1">
            File selected:{' '}
            {!isNil(file) && !isEmpty(file.name) ? (
              <span className={classes.bold}>{file.name}</span>
            ) : (
              <span className={classes.faded}>none</span>
            )}
          </Typography>
          <Typography variant="body1">
            File size:{' '}
            {!isNil(file) && !isEmpty(file.name) ? (
              <span className={classes.bold}>
                {(file.size / 1e6).toLocaleString('en-US', {
                  maximumSignificantDigits: 3,
                })}{' '}
                MB
              </span>
            ) : (
              <span className={classes.faded}>none</span>
            )}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={inProgress} onClick={closeUploadDialog}>
          Close
        </Button>
        <Button
          color="primary"
          disabled={inProgress || isNil(file)}
          onClick={handleSubmit}
        >
          Upload
        </Button>
      </DialogActions>

      {inProgress && <LinearProgress />}
    </Dialog>
  );
}

import { dependencies } from '@pn/core/dependencies';
import type { CanvasBBox, CanvasFeature } from '@pn/core/domain/drawing';
import type { Point } from '@pn/core/domain/point';
import {
  REFERENCE_PT,
  computeMapTransformation,
  getCanvasBoundingBox,
  rescaleFeature,
  transformPoint,
  useDrawing,
} from '@pn/services/drawing';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { cloneDeep, isNil } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import React from 'react';

export function useResizeFeature() {
  const { isDrawingPanelOpen } = useWorkspaceItemPanel();
  const { liveCanvasRef, drawingState, historyManager, redraw } = useDrawing();

  React.useEffect(() => {
    if (!isDrawingPanelOpen) return;

    const { map } = dependencies;
    const mapboxMap = map._native;

    let altKey = false;
    let startPoint = { x: 0, y: 0 };
    let point = { x: 0, y: 0 };
    let startWidth = 0;
    let startHeight = 0;
    let featuresSelected: Record<string, CanvasFeature> = {};
    let bboxSelected: CanvasBBox = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      strokeWidth: 0,
    };

    const onKeyDownOrUp = (e: KeyboardEvent) => {
      if (e.key !== 'Alt' || e.repeat) return;

      /**
       * Prevents the browser from running the default Alt key behavior.
       * If this is not done, all subsequent events will be ignored.
       */
      e.preventDefault();

      altKey = e.type === 'keydown';

      if (drawingState.isCustomPanning || isNil(drawingState.resizeDirection))
        return;

      resize();
    };

    const onMouseDown = (e: mapboxgl.MapMouseEvent) => {
      if (
        drawingState.isCustomPanning ||
        isNil(drawingState.resizeHoverDirection)
      ) {
        return;
      }

      const bbox = getCanvasBoundingBox(
        Object.values(drawingState.featuresSelected)
      );

      const transformation = computeMapTransformation(REFERENCE_PT, false);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      startPoint = transformPoint(e.point, inverseTransformation);
      point = transformPoint(e.point, inverseTransformation);
      startWidth = bbox.width;
      startHeight = bbox.height;
      featuresSelected = cloneDeep(drawingState.featuresSelected);
      bboxSelected = getCanvasBoundingBox(Object.values(featuresSelected));

      drawingState.resizeDirection = drawingState.resizeHoverDirection;

      map.disableMovement();
    };

    const onMouseMove = (e: MouseEvent) => {
      if (drawingState.isCustomPanning || isNil(drawingState.resizeDirection)) {
        return;
      }

      const transformation = computeMapTransformation(REFERENCE_PT, false);
      const inverseTransformation = {
        dx: -transformation.dx,
        dy: -transformation.dy,
        scale: 1 / transformation.scale,
      };

      const bbox = map._native.getContainer().getBoundingClientRect();

      point = transformPoint(
        { x: e.clientX - bbox.left, y: e.clientY - bbox.top },
        inverseTransformation
      );

      resize();
    };

    function resize() {
      assert(drawingState.resizeDirection, 'resizeDirection is undefined');

      const dx =
        (startPoint.x - point.x) *
        (['ne', 'se'].includes(drawingState.resizeDirection) ? -1 : 1) *
        (altKey ? 2 : 1);
      const dy =
        (startPoint.y - point.y) *
        (['se', 'sw'].includes(drawingState.resizeDirection) ? -1 : 1) *
        (altKey ? 2 : 1);

      const scale = getScale(dx, dy, startWidth, startHeight);
      const anchor = getAnchor(
        bboxSelected,
        drawingState.resizeDirection,
        altKey
      );

      Object.values(featuresSelected).forEach((feature) => {
        const rescaledFeature = rescaleFeature(feature, scale, anchor);

        drawingState.featuresSelected[feature.id] = rescaledFeature;
        drawingState.features[feature.id] = rescaledFeature;
      });

      redraw();
    }

    const onMouseUp = () => {
      if (isNil(drawingState.resizeDirection)) return;

      drawingState.resizeDirection = undefined;

      historyManager.add(drawingState);

      map.enableMovement();
    };

    mapboxMap.on('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('keydown', onKeyDownOrUp);
    document.addEventListener('keyup', onKeyDownOrUp);

    return () => {
      map.enableMovement();

      mapboxMap.off('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('keydown', onKeyDownOrUp);
      document.removeEventListener('keyup', onKeyDownOrUp);
    };
  }, [
    isDrawingPanelOpen,
    // the following never change:
    liveCanvasRef,
    drawingState,
    historyManager,
    redraw,
  ]);
}

/**
 * 'proportional' - Felt Maps
 * 'follow-edge'  - Excalidraw
 */
const SCALE_BEHAVIOUR: 'proportional' | 'follow-edge' = 'proportional';

function getScale(
  dx: number,
  dy: number,
  width: number,
  height: number
): number {
  switch (SCALE_BEHAVIOUR) {
    case 'proportional': {
      const xScale = (width + dx) / (width + height);
      const yScale = (height + dy) / (width + height);
      return xScale + yScale;
    }
    case 'follow-edge': {
      const xScale = (width + dx) / width;
      const yScale = (height + dy) / height;
      return Math.max(xScale, yScale);
    }
    default:
      throw new Error(`Unknown scale behaviour: ${SCALE_BEHAVIOUR}`);
  }
}

function getAnchor(
  bbox: CanvasBBox,
  direction: 'nw' | 'ne' | 'sw' | 'se',
  centered: boolean
): Point {
  if (centered) {
    return { x: bbox.x + bbox.width / 2, y: bbox.y + bbox.height / 2 };
  }

  switch (direction) {
    case 'nw':
      return { x: bbox.x + bbox.width, y: bbox.y + bbox.height };
    case 'ne':
      return { x: bbox.x, y: bbox.y + bbox.height };
    case 'sw':
      return { x: bbox.x + bbox.width, y: bbox.y };
    case 'se':
      return { x: bbox.x, y: bbox.y };
  }
}

import queryString from 'query-string';
import React from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

// Technically, routing should be handled in services, but for now it'll do.
// Ideally we'd want to have a domain router that would be implemented with
// react-router-dom service.

type Router = {
  pathname: string;
  search: string;
  /**
   * Merge params and parsed query string into single "query" object
   * so that they can be used interchangeably.
   * Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
   */
  query: Record<string, string | undefined>;
  location: ReturnType<typeof useLocation>;
  history: ReturnType<typeof useHistory>;
  match: ReturnType<typeof useRouteMatch>;
  isInitialUrl: boolean;
  pushUrl: (
    location: string | { pathname: string; search: string },
    state?: unknown
  ) => void;
  replaceUrl: (
    location: string | { pathname: string; search: string },
    state?: unknown
  ) => void;
  addUrlParam: (key: string, value: string) => void;
  removeUrlParam: (key: string) => void;
};

export function useRouter(): Router {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const [isInitialUrl, setIsInitialUrl] = React.useState(true);
  React.useEffect(() => {
    setIsInitialUrl(false);
  }, []);

  const addUrlParam = React.useCallback(
    (key: string, value: string) => {
      history.push({
        pathname: location.pathname,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          [key]: value,
        }),
      });
    },
    [history, location.pathname, location.search]
  );

  const removeUrlParam = React.useCallback(
    (key: string) => {
      const query = queryString.parse(location.search);
      delete query[key];
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(query),
      });
    },
    [history, location.pathname, location.search]
  );

  return React.useMemo(() => {
    return {
      pathname: location.pathname,
      search: location.search,
      query: {
        ...queryString.parse(location.search), // convert string to object
        ...params,
      },
      location,
      history,
      match,
      isInitialUrl,
      pushUrl: history.push,
      replaceUrl: history.replace,
      addUrlParam,
      removeUrlParam,
    };
  }, [
    addUrlParam,
    history,
    match,
    location,
    params,
    isInitialUrl,
    removeUrlParam,
  ]);
}

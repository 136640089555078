import type { ISODateTimeString } from '@pn/core/domain/types';
import { IntelPlans, UserPlans } from '@pn/core/domain/user';
import { hasKey } from '@pn/core/utils/logic';
import type { ApiUserEnterprise } from '@pn/services/api/enterprise/types';
import { isObject } from 'lodash-es';

export type ApiIncompleteUser = {
  email: string;
  enterprise: ApiUserEnterprise | null;
  user_plan?: UserPlans;
};

export function isApiIncompleteUser(arg: unknown): arg is ApiIncompleteUser {
  return isObject(arg) && !hasKey(arg, 'id');
}

export type ApiUser = {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  user_plan: UserPlans;
  intel_plan: IntelPlans;
  enterprise: ApiUserEnterprise | null;
  connection: string | null;
  company: string | null;
  title: string | null;
  accept_mail: boolean | null;
  home_base_lat: number | null;
  home_base_lng: number | null;
  created_at: ISODateTimeString;
  stripe_account: {
    id: string;
    email: string;
    createdAt: ISODateTimeString;
    delinquent: boolean;
    currency: string;
  } | null;
  is_admin: boolean;
};

export function isApiUser(arg: unknown): arg is ApiUser {
  return (
    isObject(arg) &&
    hasKey(arg, 'id') &&
    hasKey(arg, 'email') &&
    hasKey(arg, 'first_name') &&
    hasKey(arg, 'last_name') &&
    hasKey(arg, 'user_plan') &&
    hasKey(arg, 'connection') &&
    hasKey(arg, 'company') &&
    hasKey(arg, 'title') &&
    hasKey(arg, 'accept_mail')
  );
}

export type ApiTargetUser = Partial<ApiUser> & {
  enterprise_id: string | number | undefined;
};

export type ApiEnterpriseUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string; // QUESTION why is this called role and not user_plan?
};

export type ApiEnterpriseInvitedUser = Pick<
  ApiUser,
  'id' | 'email' | 'user_plan' | 'enterprise' | 'created_at'
>;

export function isApiEnterpriseInvitedUser(
  arg: unknown
): arg is ApiEnterpriseInvitedUser {
  return (
    isObject(arg) &&
    hasKey(arg, 'id') &&
    hasKey(arg, 'email') &&
    hasKey(arg, 'user_plan')
  );
}

/**
 * Used in projects, lists, layers, and annotations.
 */
export type ApiProjectUser = {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
};

import { pnApiClient } from '@pn/services/api/pnApiClient';

export const getStackToken = async (stackCompanyId: string) => {
  const response = await pnApiClient.request<{ token: string }>({
    url: `v2/user/stack?stack_company_id=${stackCompanyId}`,
  });

  return response.token;
};

export const apiStackTokenProvider = {
  getStackToken,
};

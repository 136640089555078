import { LayerType } from '@pn/core/domain/layer';
import {
  getPointsLayerId,
  getSourceId,
} from '@pn/core/domain/workspace';
import { generateGeoJSONFeatureCollection } from '@pn/services/map/mapbox/mapboxUtils';
import { isNil } from 'lodash-es';
import type { MapboxIMap } from '..';

export const updateLayerData: MapboxIMap['updateLayerData'] = function (
  this: MapboxIMap,
  { layer, data, fitToTileset = false }
) {
  if (!this.hasLayer(layer.id)) {
    throw new Error(`Layer ${layer.id} does not exist`);
  }

  const source = this._native.getSource(getSourceId(layer.id));
  if (source.type !== 'geojson') {
    throw new Error(`Layer ${layer.id} is not a GeoJSON layer`);
  }

  const isPointsGeometry =
    layer.type === LayerType.Icon ||
    layer.type === LayerType.Text ||
    layer.type === LayerType.Circle;

  const featureCollection = generateGeoJSONFeatureCollection(data, {
    simplify: fitToTileset && isPointsGeometry,
  });
  source.setData(featureCollection);

  const pointsSource = this._native.getSource(
    getSourceId(getPointsLayerId(layer.id))
  );
  if (!isNil(pointsSource) && pointsSource.type === 'geojson') {
    const pointsFeatureCollection = generateGeoJSONFeatureCollection(data, {
      points: true,
    });
    pointsSource.setData(pointsFeatureCollection);
  }
};

import type { Layer } from '@pn/core/domain/layer';
import type { MapboxIMap } from '..';

export function hideLayer(this: MapboxIMap, layerId: Layer['id']) {
  if (!this.hasLayer(layerId)) {
    throw new Error(`Layer ${layerId} does not exist`);
  }

  this._native.setLayoutProperty(layerId, 'visibility', 'none');
}

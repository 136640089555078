import { useMapStorage } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import assert from 'assert';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  mapPrint: {
    display: 'none',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    '@media print': {
      display: 'block',
    },
  },
}));

export const MapPrintSnapshot = () => {
  const { classes } = useStyles();

  const { isInitialized } = useMapStorage();

  const mapPrintRef = React.useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    if (!isInitialized) return;

    const mapboxMap = map._native;

    const onPrint = () => {
      const mapCanvas = mapboxMap.getCanvas();
      const { width, height } = mapCanvas;

      /**
       * Render drawings via a canvas layer on the map.
       */
      const bounds = mapboxMap.getBounds();
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();

      mapboxMap.addSource('canvas-source', {
        type: 'canvas',
        canvas: 'canvas-static',
        coordinates: [
          [sw.lng, ne.lat],
          [ne.lng, ne.lat],
          [ne.lng, sw.lat],
          [sw.lng, sw.lat],
        ],
      });

      mapboxMap.addLayer({
        id: 'canvas-static',
        type: 'raster',
        source: 'canvas-source',
        paint: { 'raster-fade-duration': 0 },
      });

      /**
       * Set the snapshot image of the map.
       */
      setTimeout(() => {
        assert(mapPrintRef.current, 'map-print <img /> is null');
        mapPrintRef.current.src = mapCanvas.toDataURL();
        mapPrintRef.current.style.aspectRatio = `${width}/${height}`;
      }, 0);
    };

    const onAfterPrint = () => {
      mapboxMap.removeLayer('canvas-static');
      mapboxMap.removeSource('canvas-source');
    };

    window.addEventListener('beforeprint', onPrint);
    window.addEventListener('afterprint', onAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', onPrint);
      window.removeEventListener('afterprint', onAfterPrint);
    };
  }, [isInitialized]);

  if (isEmbedded()) return null;

  return <img ref={mapPrintRef} id="map-print" className={classes.mapPrint} />;
};

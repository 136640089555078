import { enterpriseUsersSlice } from '@pn/core/storage/user/usersSlice';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiUserMapper } from '@pn/services/api/user/apiUserMapper';
import { ReducerSync } from '.';

export const enterpriseUsers: ReducerSync<typeof enterpriseUsersSlice.actions> =
  {
    // should be an admin user only operation
    addToEnterprise: ({ user, enterprise, userPlan }) => {
      return pnApiClient.request({
        method: 'PUT',
        url: `v2/admin/users/${user.id}`,
        payload: {
          ...apiUserMapper.toTargetUser({
            ...user,
            enterprise: enterprise,
            userPlan: userPlan,
          }),
          enterprise_id: enterprise.id, // TODO: this should'nt be necessary, should update the API to anticipate a nested user.enterprise structure to the body
        },
      });
    },
    // should be an admin user only operation
    updateAsAdmin: (user) => {
      console.log('UPDATE AS ADMIN');
      return pnApiClient.request({
        method: 'PUT',
        url: `v2/admin/users/${user.id}`,
        payload: apiUserMapper.toTargetUser(user),
      });
    },
    update: (user) => {
      console.log('UPDATE REGULAR');

      return pnApiClient.request({
        method: 'PUT',
        url: `v1/enterprises/${user.enterprise?.id}/users/${user.id}`,
        payload: apiUserMapper.toTargetUser(user),
      });
    },
    updateUserPlan: ({ user, userPlan }) => {
      return pnApiClient.request({
        method: 'PUT',
        url: `v1/enterprises/${user.enterprise?.id}/users/${user.id}`,
        payload: apiUserMapper.toTargetUser({
          ...user,
          userPlan: userPlan,
        }),
      });
    },
    remove: (user) => {
      return pnApiClient.request({
        method: 'DELETE',
        url: `v1/enterprises/${user.enterprise?.id}/users/${user.id}`,
      });
    },
  };

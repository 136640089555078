import { areClose, type Point } from '@pn/core/domain/point';

type Shared = {
  id: string;
  itemId: string;
  isVisible: boolean;
};

export type PathFeature = Shared & {
  type: 'path';
  pathData: string;
  strokeColor: string;
  strokeWidth: number;
  opacity: number;
};

export type PolyFeature = Shared & {
  type: 'poly';
  subType?: 'rectangle' | 'rectangle_selection';
  coordinates: Point[];
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  opacity: number;
  arrow?: boolean;
  measurements?: {
    distance: number;
    area: number | undefined;
    position: Point;
    fontSize: number;
  };
};

export type CircleFeature = Shared & {
  type: 'circle';
  center: Point;
  radius: number;
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  opacity: number;
  measurements?: {
    radius: number;
    position: Point;
    fontSize: number;
  };
};

export type TextFeature = Shared & {
  type: 'text';
  text: string;
  position: Point;
  textColor: string;
  fontSize: number;
  fontFamily: string;
  opacity: number;
};

export type CanvasFeature =
  | PathFeature
  | PolyFeature
  | CircleFeature
  | TextFeature;

export function isPolyClosed(feature: PolyFeature): boolean {
  const points = feature.coordinates;
  return areClose(points[0], points[points.length - 1]);
}

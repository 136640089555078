import {
  formatDataType,
  type DataItem,
  type DataItemId,
  type MappingItem,
} from '@pn/core/domain/data';
import {
  getDataItemSelected,
  WorkspaceItem,
} from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { dataDetailsActions } from '@pn/core/storage';
import { apiDataInfoProvider } from '@pn/services/api/data-info/apiDataInfoProvider';
import { localDataInfoProvider } from '@pn/services/local/data-info/localDataInfoProvider';
import { isNil } from 'lodash-es';
import React from 'react';

let lastIdRequested: DataItemId = '';
async function getApiDataDetails(workspaceItem: WorkspaceItem, id: DataItemId) {
  try {
    lastIdRequested = id;
    dataDetailsActions(workspaceItem.id).request();

    const details = await apiDataInfoProvider.getDataDetails(
      workspaceItem.dataType,
      id
    );

    if (lastIdRequested !== id) return;
    dataDetailsActions(workspaceItem.id).receive(details);
  } catch (error) {
    handleError({
      error,
      onError: dataDetailsActions(workspaceItem.id).error,
      userFriendlyMessage: `Failed to load ${formatDataType(
        workspaceItem.dataType
      )} details`,
    });
  }
}

async function getLocalDataDetails(
  workspaceItem: WorkspaceItem,
  dataItem: DataItem | undefined,
  mapping: MappingItem[]
) {
  if (isNil(dataItem)) {
    throw new Error('Unable to fetch details when data item is undefined');
  }

  try {
    dataDetailsActions(workspaceItem.id).request();

    const details = await localDataInfoProvider.getDataDetails(
      dataItem,
      mapping
    );

    dataDetailsActions(workspaceItem.id).receive(details);
  } catch (error) {
    handleError({
      error,
      onError: dataDetailsActions(workspaceItem.id).error,
      userFriendlyMessage: `Failed to load ${formatDataType(
        workspaceItem.dataType
      )} details`,
    });
  }
}

export function useAutoGetDataDetails(
  item: WorkspaceItem,
  id: DataItemId | undefined
) {
  React.useEffect(() => {
    if (item.detailsSource === 'api' && !isNil(id)) {
      getApiDataDetails(item, id);
    }
  }, [id, item]);

  React.useEffect(() => {
    if (item.detailsSource === 'local') {
      getLocalDataDetails(item, getDataItemSelected(item), item.mapping);
    }
  }, [item]);
}

import { invitedUsersSlice } from '@pn/core/storage/user/usersSlice';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { ReducerSync } from '.';

const { actions } = invitedUsersSlice;

export const invitedUsers: ReducerSync<typeof actions> = {
  add: async (invitedUser, dispatch) => {
    const response = await pnApiClient.request<{ id: string }>({
      method: 'POST',
      url: 'v1/invites',
      payload: {
        email: invitedUser.email,
        user_plan: invitedUser.userPlan,
        enterprise_id: invitedUser.enterprise?.id,
      },
    });
    dispatch(
      actions._updateId({
        currentId: invitedUser.id,
        newId: response.id,
      })
    );
  },
  remove: (invitedUserId) => {
    return pnApiClient.request({
      method: 'DELETE',
      url: `v1/invites/${invitedUserId.id}`,
    });
  },
};

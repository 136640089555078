import { Box, Paper, SwipeableDrawer } from '@mui/material';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import React from 'react';
import { WORKSPACE_DRAWER_WIDTH } from 'src/ui/Main';
import { WorkspaceContainer } from 'src/ui/workspace/WorkspaceContainer';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    justifyContent: 'space-between',
    '@media print': {
      display: 'none',
    },
  },
  paper: {
    gridArea: 'workspace',
    width: WORKSPACE_DRAWER_WIDTH,
    height: '100%',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: 'none',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
    '@media print': {
      display: 'none',
    },
  },
  panelContainer: {
    height: '100%',
  },
}));

type Props = {
  open: boolean;
  setWorkspaceOpen: (open: boolean) => void;
};

export const Workspace = React.memo(_Workspace);
function _Workspace({ open, setWorkspaceOpen }: Props) {
  const { smScreen } = useScreenSize();
  const { classes } = useStyles();

  if (smScreen) {
    return (
      <SwipeableDrawer
        disableDiscovery
        disableSwipeToOpen
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
        onOpen={() => setWorkspaceOpen(true)}
        onClose={() => setWorkspaceOpen(false)}
      >
        <CommonWorkspaceContainer />
      </SwipeableDrawer>
    );
  }

  return <CommonWorkspaceContainer />;
}

const CommonWorkspaceContainer = () => {
  const { classes } = useStyles();

  return (
    <Paper
      square
      variant="outlined"
      className={classes.paper}
      id="workspace-container"
    >
      <Box className={classes.panelContainer}>
        <WorkspaceContainer />
      </Box>
    </Paper>
  );
};

import Dialog from '@mui/material/Dialog';
import { useTerminal } from './useTerminal';
import { Terminal } from './Terminal';

export function TerminalDialog() {
  const { isTerminalOpen, handleCloseTerminal } = useTerminal();

  return (
    <Dialog fullWidth open={isTerminalOpen} onClose={handleCloseTerminal}>
      <Terminal />
    </Dialog>
  );
}

export const _reference = {
  id: '_reference',
  type: 'background',
  layout: {
    visibility: 'none',
  },
  paint: {
    'background-color': 'transparent',
  },
};

import { isNil } from 'lodash-es';
import React from 'react';

type Options = {
  leading: boolean;
  skipOnValue?: string;
};

export function useDebouncedValue<T = any>(
  value: T,
  wait: number = 400,
  options: Options = { leading: false }
) {
  const [_value, setValue] = React.useState(value);
  const mountedRef = React.useRef(false);
  const timeoutRef = React.useRef<number | undefined>(undefined);
  const cooldownRef = React.useRef(false);

  const cancel = () => window.clearTimeout(timeoutRef.current as number);
  const skip = () => {
    cancel();
    setValue(value);
  };

  React.useEffect(() => {
    if (mountedRef.current) {
      if (!isNil(options.skipOnValue) && value === options.skipOnValue) {
        cancel();
        setValue(value);
      } else if (!cooldownRef.current && options.leading) {
        cooldownRef.current = true;
        setValue(value);
      } else {
        cancel();
        timeoutRef.current = window.setTimeout(() => {
          cooldownRef.current = false;
          setValue(value);
        }, wait);
      }
    }
  }, [value, options.leading, options.skipOnValue, wait]);

  React.useEffect(() => {
    mountedRef.current = true;
    return cancel;
  }, []);

  return [_value, skip] as const;
}

import { useMediaQuery, type Theme } from '@mui/material';

export function useScreenSize() {
  return {
    xsScreen: useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
      noSsr: true,
    }),
    smScreen: useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), {
      noSsr: true,
    }),
    mdScreen: useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'), {
      noSsr: true,
    }),
  };
}

import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import type { IAuthenticationService } from '@pn/core/services/authentication/ports';
import { isCordova } from '@pn/core/utils/env';

declare global {
  interface Window {
    SafariViewController: any;
  }
}

export function useLogin(): IAuthenticationService['login'] {
  const { loginWithRedirect, buildAuthorizeUrl } = useAuth0();

  return async (params) => {
    const args: RedirectLoginOptions = {};

    if (params?.returnTo) {
      args.appState = { returnTo: params.returnTo };
    }
    if (params?.type === 'signup') {
      args.screen_hint = 'signup';
    }
    if (params?.connection) {
      args.connection = params.connection;
    }
    if (params?.prefilledEmail) {
      args.login_hint = params.prefilledEmail;
    }

    if (isCordova()) {
      const url = await buildAuthorizeUrl({
        ...args,
        redirectUri: 'petroninja://callback',
      });
      console.log('authorize url built', url);

      window.SafariViewController.isAvailable(function (available: boolean) {
        if (available) {
          console.log('SafariViewController is available');
          window.SafariViewController.show(
            {
              url: url,
              hidden: false, // default false
              animated: true, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
              transition: 'curl', // unless animated is false you can choose from: curl, flip, fade, slide (default)
              // enterReaderModeIfAvailable: false, // default false
              // barColor: '#0000ff', // default is white (iOS 10 only)
              // tintColor: '#ffffff', // default is ios blue
            },
            function (result: any) {
              if (result.event === 'opened') {
                console.log('---> opened SafariViewController');
              } else if (result.event === 'loaded') {
                console.log('---> loaded SafariViewController');
              } else if (result.event === 'closed') {
                console.log('---> closed SafariViewController');
                // window.SafariViewController.hide();
                // window.location.reload(false);
              }
            },
            function (msg: any) {
              // TODO verify errors actually go here
              console.log('SafariViewController msg', JSON.stringify(msg));
              window.alert(
                'Your device does not support this authentication protocol. Please reach out at support@petroninja.com for assistance.'
              );
            }
          );
        } else {
          console.log('SafariViewController is NOT available');
          window.cordova.InAppBrowser.open(url, '_system');
        }
      });

      // const browser = window.cordova.InAppBrowser.open(url, '_blank');
      // browser.addEventListener('loadstart', (event) => {
      //   console.log('loadstart event', event);
      //   if (event.url.includes('petroninja://')) {
      //     console.log('closing the in-app browser');
      //     browser.close();

      //     console.log('!!! saving url to sessionStorage !!!', event.url);
      //     sessionStorage.setItem('auth0-callback-url', event.url);
      //     // console.log('calling auth0 handleRedirectCallback', event.url);
      //     // handleRedirectCallback(event.url);
      //   }
      // });

      // window.cordova.InAppBrowser.open(url, '_system');
    } else {
      loginWithRedirect(args);
    }
  };
}

import { IApiCompanyInfoMapper } from '@pn/core/mappers/intel';
import { isNil, reverse } from 'lodash-es';

export const apiCompanyInfoMapper: IApiCompanyInfoMapper = {
  toDomainCompanyKPI: (apiIntelCompanyKPI) => {
    if (isNil(apiIntelCompanyKPI)) {
      throw new Error('apiIntel is not a valid object');
    }

    return {
      id: apiIntelCompanyKPI.company_id,
      companyId: apiIntelCompanyKPI.company_id,
      wellLicensesCount: apiIntelCompanyKPI.well_licenses_count,
      producingWellsCount: apiIntelCompanyKPI.producing_wells_count,
      suspendedWellsCount: apiIntelCompanyKPI.suspended_wells_count,
      activeWellsCount: apiIntelCompanyKPI.active_wells_count,
      majorProvinces: apiIntelCompanyKPI.major_provinces,
      topFiveProjectedFormations:
        apiIntelCompanyKPI.top_five_projected_formations ?? [],
      wellLicensesIssuedLastThreeMonthsCount:
        apiIntelCompanyKPI.well_licenses_issued_last_three_months_count,
      wellLicensesIssuedLastTwelveMonthsCount:
        apiIntelCompanyKPI.well_licenses_issued_last_twelve_months_count,
      wellLicensesDrilledLastThreeMonthsCount:
        apiIntelCompanyKPI.well_licenses_spudded_last_three_months_count,
      wellLicensesDrilledLastTwelveMonthsCount:
        apiIntelCompanyKPI.well_licenses_spudded_last_twelve_months_count,
      recentMonthGrossOilBBLPerDay:
        apiIntelCompanyKPI.recent_month_gross_oil_bbl_per_day,
      recentMonthGrossGasMCFPerDay:
        apiIntelCompanyKPI.recent_month_gross_gas_mmcf_per_day,
      recentMonthGrossBOEPerDay:
        apiIntelCompanyKPI.recent_month_gross_boe_per_day,
      percentChangeSixMonthsBOEPerDay:
        apiIntelCompanyKPI.percent_change_six_months_boe_per_day,
    };
  },
  toDomainQuarterlyStat: (apiQuarterlyStat) => {
    if (isNil(apiQuarterlyStat)) {
      throw new Error('apiIntel is not a valid array');
    }

    const year = parseInt(apiQuarterlyStat.year);
    const quarter = parseInt(apiQuarterlyStat.quarter.replace(/[^0-9.]/g, '')); // strip the 'Q' character out

    return {
      id: apiQuarterlyStat.id,
      companyId: apiQuarterlyStat.company_id.toString(),
      year,
      quarter,
      oilBBLD: apiQuarterlyStat.oil_bbl_d,
      naturalGasMMCF: apiQuarterlyStat.natural_gas_mmcf_d,
      condensateBBLD: apiQuarterlyStat.condensate_bbl_d,
      naturalGasLiquidsBBLPerDay: apiQuarterlyStat.ngl_bbl_d,
      liquidProductionsBBLD: apiQuarterlyStat.liquid_productions_bbl_d,
      oilEquivalentBOED: apiQuarterlyStat.oil_equivalent_boe_d,
      gAndA: apiQuarterlyStat.g_and_a,
      operatingExpense: apiQuarterlyStat.operating_expense_boe,
      netback: apiQuarterlyStat.netback_boe,
      marketCapitalization: apiQuarterlyStat.market_capitalization,
      netDebt: apiQuarterlyStat.net_debt,
      enterprise: apiQuarterlyStat.enterprise_value_ev,
      cashFromOperations: apiQuarterlyStat.cash_from_operations,
      capitalExpenditure: apiQuarterlyStat.capex,
      transportationCostsBOED: apiQuarterlyStat.transportation_costs_boe,
      royaltyCostsBOED: apiQuarterlyStat.royalty_cost_boe,
      hedgingGainLossBOED: apiQuarterlyStat.hedging_gain_loss_boe,
      oilRealizedPriceCADPerBBL:
        apiQuarterlyStat.oil_realized_price_cad_per_bbl,
      naturalGasRealizedPriceCADPerMCF:
        apiQuarterlyStat.natural_gas_realized_price_cad_per_mcf,
      condensateRealizedPriceCADPerBBL:
        apiQuarterlyStat.condensate_realized_price_cad_per_bbl,
      nglRealizedPriceCADPerBBL:
        apiQuarterlyStat.ngl_realized_price_cad_per_bbl,
      oilEquivalentRealizedPriceCADPerBOE:
        apiQuarterlyStat.oil_equivalent_realized_price_cad_per_boe,
      shares: apiQuarterlyStat.shares,
      dividendsPaidMCAD: apiQuarterlyStat.dividends_paid_mcad,
      decommissioningObligationsMCD:
        apiQuarterlyStat.decommissioning_obligations_mcad,
      shareBuybacksMCAD: apiQuarterlyStat.share_buybacks_mcad,
      dAndDCadPerBOE: apiQuarterlyStat.d_and_d_cad_per_boe,
      financingCostsCADPerBOE: apiQuarterlyStat.financing_costs_cad_per_boe,
      shareBasedCompensationCADPerBOE:
        apiQuarterlyStat.share_based_compensation_cad_per_boe,
    };
  },
  toTargetStat: (domainQuarterlyStat) => {
    return {
      id: domainQuarterlyStat.id.toString(),
      company_id: parseInt(domainQuarterlyStat.companyId),
      year: domainQuarterlyStat.year.toString(),
      quarter: domainQuarterlyStat.quarter.toString(),
      oil_bbl_d: domainQuarterlyStat.oilBBLD,
      natural_gas_mmcf_d: domainQuarterlyStat.naturalGasMMCF,
      condensate_bbl_d: domainQuarterlyStat.condensateBBLD,
      ngl_bbl_d: domainQuarterlyStat.naturalGasLiquidsBBLPerDay,
      liquid_productions_bbl_d: domainQuarterlyStat.liquidProductionsBBLD,
      oil_equivalent_boe_d: domainQuarterlyStat.oilEquivalentBOED,
      g_and_a: domainQuarterlyStat.gAndA,
      operating_expense_boe: domainQuarterlyStat.operatingExpense,
      netback_boe: domainQuarterlyStat.netback,
      market_capitalization: domainQuarterlyStat.marketCapitalization,
      net_debt: domainQuarterlyStat.netDebt,
      enterprise_value_ev: domainQuarterlyStat.enterprise,
      cash_from_operations: domainQuarterlyStat.cashFromOperations,
      capex: domainQuarterlyStat.capitalExpenditure,
      transportation_costs_boe: domainQuarterlyStat.transportationCostsBOED,
      royalty_cost_boe: domainQuarterlyStat.royaltyCostsBOED,
      hedging_gain_loss_boe: domainQuarterlyStat.hedgingGainLossBOED,
      oil_realized_price_cad_per_bbl:
        domainQuarterlyStat.oilRealizedPriceCADPerBBL,
      natural_gas_realized_price_cad_per_mcf:
        domainQuarterlyStat.naturalGasRealizedPriceCADPerMCF,
      condensate_realized_price_cad_per_bbl:
        domainQuarterlyStat.condensateRealizedPriceCADPerBBL,
      ngl_realized_price_cad_per_bbl:
        domainQuarterlyStat.nglRealizedPriceCADPerBBL,
      oil_equivalent_realized_price_cad_per_boe:
        domainQuarterlyStat.oilEquivalentRealizedPriceCADPerBOE,
      shares: domainQuarterlyStat.shares,
      dividends_paid_mcad: domainQuarterlyStat.dividendsPaidMCAD,
      decommissioning_obligations_mcad:
        domainQuarterlyStat.decommissioningObligationsMCD,
      share_buybacks_mcad: domainQuarterlyStat.shareBuybacksMCAD,
      d_and_d_cad_per_boe: domainQuarterlyStat.dAndDCadPerBOE,
      share_based_compensation_cad_per_boe:
        domainQuarterlyStat.shareBasedCompensationCADPerBOE,
      financing_costs_cad_per_boe: domainQuarterlyStat.financingCostsCADPerBOE,
    };
  },
  toDomainCompanyInfo: (apiCompany) => {
    if (isNil(apiCompany)) {
      throw new Error('apiCompanyDetail is not a valid object');
    }

    return {
      id: apiCompany.id, // TODO: this is a hack, we need to get the company id from the api
      name: apiCompany.companyName,
      stockSymbol: apiCompany.symbol,
      image: apiCompany.image,
      website: apiCompany.website,
      description: apiCompany.description,
      address: apiCompany.address,
      city: apiCompany.city,
      province: apiCompany.state,
      zip: apiCompany.zip,
      industry: apiCompany.industry,
      ipoDate: apiCompany.ipoDate,
      exchange: apiCompany.exchange,
      fullTimeEmployees: apiCompany.fullTimeEmployees,
      executives: reverse(apiCompany.executives).map((exec) => {
        return { ...exec, id: exec.name };
      }),
      dailyHistoricalStockPrices: apiCompany.historical,
      quarterlyFinancialStatements: apiCompany.quarterlyFinancialStatements.map(
        (qfs) => {
          return {
            ...qfs,
            yearAndQuarter: parseInt(`${qfs.calendarYear}${qfs.period[1]}`),
          };
        }
      ),
    };
  },
  toDomainCompanyDocument: (apiIntelDocument) => {
    if (isNil(apiIntelDocument)) {
      throw new Error('apiDomainDocument is not valid');
    }
    const year = parseInt(apiIntelDocument.year);
    return {
      id: apiIntelDocument.id,
      name: apiIntelDocument.file_name,
      type: apiIntelDocument.document_type,
      year,
      period: apiIntelDocument.period,
      date: getDateFromYearAndPeriod(
        year,
        apiIntelDocument.period
      ).toISOString(),
      tags: [],
    };
  },
  toDomainBOEReportArticle: (apiIntelBOEReportArticle) => {
    if (isNil(apiIntelBOEReportArticle)) {
      throw new Error('apiIntelBOEReportArticle is not valid');
    }

    return {
      id: '',
      companyId: '',
      headline: apiIntelBOEReportArticle.post_title,
      date: apiIntelBOEReportArticle.post_date_utc,
      tags: [],
      link: apiIntelBOEReportArticle.url,
    };
  },
};

function getDateFromYearAndPeriod(year: number, period: string): Date {
  switch (period) {
    case 'YE': // year end
      return new Date(year, 11, 31);
    case 'Q1':
      return new Date(year, 3, 1);
    case 'Q2':
      return new Date(year, 6, 1);
    case 'Q3':
      return new Date(year, 9, 1);
    case 'Q4':
      return new Date(year, 12, 1);
    case 'Jan':
      return new Date(year, 1, 1);
    case 'Feb':
      return new Date(year, 2, 1);
    case 'Mar':
      return new Date(year, 3, 1);
    case 'Apr':
      return new Date(year, 4, 1);
    case 'May':
      return new Date(year, 5, 1);
    case 'Jun':
      return new Date(year, 6, 1);
    case 'Jul':
      return new Date(year, 7, 1);
    case 'Aug':
      return new Date(year, 8, 1);
    case 'Sept': // TODO: fix this on the backend, there should be only one 'Sep'
    case 'Sep':
      return new Date(year, 9, 1);
    case 'Oct':
      return new Date(year, 10, 1);
    case 'Nov':
      return new Date(year, 11, 1);
    case 'Dec':
      return new Date(year, 12, 1);
    default:
      return new Date();
  }
}

import { toMappingItem, type MappingItem } from '@pn/core/domain/data';
import { isNil } from 'lodash-es';
import type { PostgresMappingItem, PostgresSourceType } from './types';

function postgresSourceTypeToDomainType(
  sourceType: PostgresSourceType
): MappingItem['domainType'] {
  switch (sourceType) {
    case 'bool':
      return 'boolean';
    case 'int':
    case 'serial':
    case 'float':
      return 'number';
    case 'varchar':
    case 'text':
    case 'timestamp':
    case 'date':
      return 'string';
    case 'geometry':
      return 'GeoShape';
    case 'json':
    case 'jsonb':
      return 'object';
    default:
      throw new Error(`Invalid postgres source type: ${sourceType}`);
  }
}

export const postgresToDomainMapping = (
  postgresMapping: PostgresMappingItem[]
): MappingItem[] => {
  return postgresMapping.map(({ sourceType, ...item }) => {
    const domainType =
      item.domainType ?? postgresSourceTypeToDomainType(sourceType);

    return toMappingItem({
      ...item,
      source: {
        type: sourceType,
      },
      domainType,
      domainTypeAttributes:
        domainType === 'DateString' && isNil(item.domainTypeAttributes)
          ? { format: 'yyyy-MM-dd' }
          : item.domainTypeAttributes,
    });
  });
};

import { appSlice } from '@pn/core/storage/app/appSlice';
import { currentEnterpriseSlice } from '@pn/core/storage/enterprise/enterpriseSlice';
import { mapSlice } from '@pn/core/storage/map/mapSlice';
import { projectsSlice } from '@pn/core/storage/project/projectsSlice';
import { searchSlice } from '@pn/core/storage/search/searchSlice';
import { tableSlice } from '@pn/core/storage/table/tableSlice';
import { currentUserSlice } from '@pn/core/storage/user/userSlice';
import {
  enterpriseUsersSlice,
  invitedUsersSlice,
} from '@pn/core/storage/user/usersSlice';
import { workspaceSlice } from '@pn/core/storage/workspace/workspaceSlice';
import type { Middleware, Reducer } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { configureInjectableStore } from './configureInjectableStore';
import { persistenceLoad, persistenceSaves } from './persistence';
import { syncStorageMiddleware } from './syncStorage';

const isReduxLoggerEnabled = false; // for debugging

const reduxLogger = createLogger({
  collapsed: true,
  duration: true,
  diff: true,
});

const defaultMiddleware = [...persistenceSaves];
if (isReduxLoggerEnabled) {
  defaultMiddleware.push(reduxLogger);
}

const staticReducers = {
  [appSlice.name]: appSlice.reducer,
  [currentUserSlice.name]: currentUserSlice.reducer,
  [mapSlice.name]: mapSlice.reducer,
  [tableSlice.name]: tableSlice.reducer,
  [workspaceSlice.name]: workspaceSlice.reducer,
  [projectsSlice.name]: projectsSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [currentEnterpriseSlice.name]: currentEnterpriseSlice.reducer,
  [enterpriseUsersSlice.name]: enterpriseUsersSlice.reducer,
  [invitedUsersSlice.name]: invitedUsersSlice.reducer,
};

export const createReduxStore = (
  extraReducers: Record<string, Reducer> = {},
  extraMiddleware: Middleware[] = []
) =>
  configureInjectableStore(
    { ...staticReducers, ...extraReducers },
    {
      middleware: (getDefaultReduxToolkitMiddleware) =>
        getDefaultReduxToolkitMiddleware()
          .concat(defaultMiddleware)
          .concat(extraMiddleware)
          .concat(syncStorageMiddleware),
      preloadedState: persistenceLoad,
    }
  );

import FolderOffIcon from '@mui/icons-material/FolderOff';
import { Box, Paper } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { isEditable } from '@pn/core/permissions/editability';
import { useCurrentUserStorage, useProjectsStorage } from '@pn/core/storage';
import { isCloseMatch } from '@pn/services/utils/string';
import { EmptyStateWithIcon } from '@pn/ui/custom-components/EmptyStateWithIcon';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { ProjectCard } from 'src/ui/workspace/library/components/ProjectCard';
import { useLibraryActions } from 'src/ui/workspace/library/LibraryData/useLibraryActions';
import { makeStyles } from 'tss-react/mui';
import { useLibrary } from '../LibraryProvider';
import { useLibraryColumns } from './useLibraryColumns';
import { useLibraryRows } from './useLibraryRows';

const useStyles = makeStyles()((theme) => ({
  gridContainer: {
    display: 'flex',
    flexShrink: 0,
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    background: theme.palette.background.default,
    overflow: 'auto',
  },
  tableContainer: {
    overflow: 'auto',
  },
  dataGrid: {
    '& .MuiDataGrid-columnHeader.checkbox-header': {
      padding: 0,
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      '&:hover': {
        '.project-icon-button': {
          display: 'inline-flex',
        },
      },
    },
    '& .MuiDataGrid-cell': {
      outline: 'none !important',
    },
  },
}));

export const SearchResults = () => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { projects } = useProjectsStorage();

  const { libraryRouter, searchValue } = useLibrary();
  const { handleGridClick, handleRowClick } = useLibraryActions();

  const displayProjects = React.useMemo(
    () =>
      projects.filter(
        (project) =>
          !project.isFolder &&
          project.origin === 'pn' &&
          isCloseMatch(project.name, searchValue)
      ),
    [projects, searchValue]
  );

  const rows = useLibraryRows();
  const columns = useLibraryColumns();

  return (
    <>
      {isEmpty(displayProjects) && isEmpty(rows) && (
        <EmptyStateWithIcon text="No results found" icon={FolderOffIcon} />
      )}
      {!isEmpty(displayProjects) && (
        <Paper variant="outlined" className={classes.gridContainer}>
          {displayProjects.map((project) => (
            <Box key={project.id} sx={{ flexShrink: 0, width: 280 }}>
              <ProjectCard
                project={project}
                isEditable={isEditable(project, user)}
                onClick={() => handleGridClick(project)}
              />
            </Box>
          ))}
        </Paper>
      )}
      {!isEmpty(rows) && (
        <Box className={classes.tableContainer}>
          <DataGridPro
            className={classes.dataGrid}
            rowHeight={56}
            rows={rows}
            columns={columns}
            disableColumnSelector
            disableRowSelectionOnClick
            columnVisibilityModel={{
              origin: libraryRouter.folder === 'StackDX',
            }}
            onRowClick={handleRowClick}
            localeText={{
              footerTotalRows: 'Total:',
              footerRowSelected: (count) =>
                `${count.toLocaleString()} layer${
                  count !== 1 ? 's' : ''
                } active in the workspace`,
            }}
          />
        </Box>
      )}
    </>
  );
};

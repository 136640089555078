import { colors, createTheme, darkScrollbar } from '@mui/material';
import { sharedThemeTokens, productionColors } from './shared';

export const BOEDark = createTheme(
  {
    palette: {
      mode: 'dark',
      primary: {
        main: colors.blueGrey[200],
      },
      secondary: colors.orange,
      background: {
        paper: colors.grey[900],
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: darkScrollbar(),
        },
      },
    },
    borderColor: 'rgba(255, 255, 255, 0.23)',
    productionColors,
  },
  sharedThemeTokens
);
